import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { authResponseWrapper } from 'src/queries/helpers';
import { ErrorService } from 'src/services';
import { RelatedHearing } from './types';

export function useDeleteRelatedHearing(
  options?: UseMutationOptions<any, Error, RelatedHearing['id']>
) {
  const { mutate: deleteRelatedHearing, isLoading } = useMutation<any, Error, RelatedHearing['id']>(
    {
      mutationFn: (payload: RelatedHearing['id']) =>
        authResponseWrapper(apiClient.deleteRelatedHearing, [payload]),
      ...options,
      onError: (error) => ErrorService.handler(error),
    }
  );

  return {
    deleteRelatedHearing,
    isLoading,
  };
}
