import React from 'react';
import CurrencyFormat from 'react-currency-format';
import { connect } from 'react-redux';
import { IRootState } from 'src/redux/rootReducer';
import { Callback } from 'src/redux/types';
import { Input } from '..';
import { InputProps } from '../Input';

const customInput = ({ ...props }) => <Input {...props} />;

const MAX_INTEGER_LENGTH = 13;

const InputDecimal: React.FC<Props> = ({
  decimalPlaces = 4,
  unFixedDecimalScale = false,
  name,
  value,
  onChange,
  onBlur,
  maxIntegerLength = MAX_INTEGER_LENGTH,
  ...props
}) => {
  const handleChange = (values) => {
    const { floatValue } = values;
    const returnValue = floatValue ? floatValue : floatValue === 0 ? 0 : null;
    onChange(name, returnValue);
  };

  const onTouched = () => {
    onBlur(name, true);
  };

  return (
    <CurrencyFormat
      customInput={customInput}
      thousandSeparator
      fixedDecimalScale={!unFixedDecimalScale}
      decimalScale={decimalPlaces}
      displayType="input"
      onValueChange={handleChange}
      {...props}
      name={name}
      value={value?.toString()}
      onBlur={onTouched}
      isAllowed={({ floatValue }) => {
        return floatValue ? `${Math.trunc(floatValue)}`.length <= maxIntegerLength : true;
      }}
    />
  );
};
type Props = Omit<CurrencyFormat.Props, 'InputProps'> & { InputProps: InputProps } & {
  decimalPlaces: number;
  unFixedDecimalScale?: boolean;
  value: string | number;
  name: string;
  onChange: Callback;
  onBlur?: Callback;
  maxIntegerLength?: number;
};

const mapStateToProps = (state: IRootState) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(InputDecimal);
