import { useMutation, UseMutationOptions } from 'react-query';
import { Toastify } from 'src/services';
import apiClient from '../../apiClient';
import { responseWrapper } from '../../helpers';
import { CaseSettlementDetailRelatedInterface } from './types';

export function useUpdateCaseSettlementDetails(
  options?: UseMutationOptions<
    CaseSettlementDetailRelatedInterface,
    Error,
    CaseSettlementDetailRelatedInterface
  >
) {
  const {
    mutate: onEditCaseSettlementDetails,
    isLoading: isUpdating,
    isSuccess,
    isError,
    error,
  } = useMutation<
    CaseSettlementDetailRelatedInterface,
    Error,
    CaseSettlementDetailRelatedInterface
  >({
    mutationFn: (payload: CaseSettlementDetailRelatedInterface) =>
      responseWrapper(apiClient.updateCaseSettlementDetails, [payload]),
    onError: ({ message }) => Toastify.error(message),
    ...options,
  });

  return {
    onEditCaseSettlementDetails,
    isUpdating,
    isSuccess,
    isError,
    error,
  };
}
