export enum HearingDecisionsKey {
  Id = 'id',
  Case = 'case',
  CreatedAt = 'createdAt',
  CreateBy = 'relatedCreatedBy',
  CreatedUser = 'createdUser',
  DcDecisionInformation = 'dcDecisionInformation',
  DecisionNumber = 'decisionNumber',
  DecisionCompletedDate = 'decisionCompletedDate',
  DecisionFormat = 'decisionFormat',
  DecisionStatus = 'decisionStatus',
  DecisionStatusDate = 'decisionStatusDate',
  HearingOfficer = 'hearingOfficer',
  HearingOfficerId = 'hearingOfficerId',
  TDICaseId = 'tdiCaseId',
  TDISFCaseId = 'tdiSFCaseId',
  Referee = 'referee',
  RefereeId = 'refereeId',
  Source = 'source',
  UpdatedAt = 'updatedAt',
  UpdatedBy = 'updatedBy',
  UpdatedUser = 'updatedUser',
  LastModifiedBy = 'relatedUpdatedBy',
}
