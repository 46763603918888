export enum PotentialAssociatedKey {
  Id = 'id',
  IsAssociated = 'isAssociated',
  TDICaseId = 'tdiCaseId',
  WCCaseId = 'wcCaseId',
  Status = 'status',
  CaseNumber = 'caseNumber',
  ClaimantName = 'claimantName',
  ClaimantId = 'claimantId',
  ClaimantSSN = 'claimantSSN',
  DateOfInjury = 'dateOfInjury',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  CreatedBy = 'createdBy',
  UpdatedBy = 'updatedBy',
  UpdatedUser = 'updatedUser',
  CreatedUser = 'createdUser',
}
