import { PaginationResponseType, toRelatedAuditData } from '../helpers';
import { DecisionPartyNameAndAddressKey, DecisionPartyNameAndAddressType } from './types';

export const toRelatedDecisionPartyNamesAddressData = (
  data
): PaginationResponseType<DecisionPartyNameAndAddressType> => ({
  ...data,
  data: data.data.map((item) => ({
    ...item,
    ...toRelatedAuditData(item),
    [DecisionPartyNameAndAddressKey.AccountName]: item?.account?.accountName,
    [DecisionPartyNameAndAddressKey.ContactName]: item?.contact?.contactName,
  })),
});
