import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { createResponseWrapper } from '../helpers';
import { PPDInjuredBodyPartsType } from './types';

export function useCreatePpdInjuredBodyParts(
  options?: UseMutationOptions<PPDInjuredBodyPartsType, Error, PPDInjuredBodyPartsType>
) {
  const { mutate: createPpdInjuredBodyParts, isLoading } = useMutation<
    PPDInjuredBodyPartsType,
    Error,
    PPDInjuredBodyPartsType
  >({
    mutationFn: (payload: any) =>
      createResponseWrapper(apiClient.createPPDInjuredBodyParts, [payload]),
    onError: (error) => ErrorService.handler(error),
    ...options,
  });

  return {
    createPpdInjuredBodyParts,
    isLoading,
  };
}
