import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { createResponseWrapper } from '../helpers';
import { TrackingLog } from './types';

export function useCreateTrackingLog(
  options?: UseMutationOptions<TrackingLog, Error, TrackingLog>
) {
  const { mutate: createTrackingLog, isLoading } = useMutation<TrackingLog, Error, TrackingLog>({
    mutationFn: (payload: TrackingLog) =>
      createResponseWrapper(apiClient.createTrackingLog, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    createTrackingLog,
    isLoading,
  };
}
