import { useState } from 'react';
import { UseQueryOptions, useQuery, useQueryClient } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { PaginationResponseType, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { GetPropertiesParams } from 'src/queries/types';
import { AwardSummaries } from './types';

export function useGetAwardSummaries(
  options?: UseQueryOptions<PaginationResponseType<AwardSummaries>, Error> & {
    caseId?: string;
  }
) {
  const [params, setParams] = useState<GetPropertiesParams>({});

  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetAllAwardSummaries,
  } = useQuery<PaginationResponseType<AwardSummaries>, Error>(
    [API_QUERIES.AWARD_SUMMARIES, { ...params, caseId: options?.caseId }],
    {
      queryFn: ({ queryKey }) => {
        const params = queryKey.at(-1);

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return responseWrapper<PaginationResponseType<AwardSummaries>>(
          apiClient.getAwardSummaries,
          [params]
        );
      },
      notifyOnChangeProps: ['data', 'isFetching'],
      keepPreviousData: true,
      enabled: !!options?.caseId,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateAwardSummaries = () =>
    queryClient.invalidateQueries(API_QUERIES.AWARD_SUMMARIES);

  const { data: awardSummaries, hasNext, payloadSize, totalRecords } = data || {};

  return {
    awardSummaries,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isError,
    isFetching,
    onGetAllAwardSummaries,
    setParams,
    handleInvalidateAwardSummaries,
  };
}
