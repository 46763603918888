import { PartyNameAndAddressKey } from '../HearingRelatedPartyNamesAddress';

export enum DecisionPartyNameAndAddressKey {
  Id = 'id',
  Ids = 'ids',
  CurrentlyActive = 'isCurrentlyActive',
  Representative = 'isRepresentative',
  ActiveStartDate = 'activeStartDate',
  ActiveEndDate = 'activeEndDate',
  PartyType = 'partyType',
  PartyName = 'partyName',
  PartyRole = 'partyRole',
  DecisionId = 'decisionId',
  AccountId = 'accountId',
  AccountName = 'accountName',
  ContactName = 'contactName',
  ContactId = 'contactId',
  PartyAddress = 'partyAddress',
  RepresentativeFor = 'representativeFor',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  CreatedBy = 'createdBy',
  UpdatedBy = 'updatedBy',
  //Relations
  Account = 'account',
  Contact = 'contact',
  CreatedUser = 'createdUser',
  UpdatedUser = 'updatedUser',
  CaseHearing = 'caseHearing',
  CasePartyIds = 'casePartyIds',
}

export interface DecisionPartyNameAndAddressType {
  [DecisionPartyNameAndAddressKey.Id]?: string;
  [DecisionPartyNameAndAddressKey.CurrentlyActive]?: boolean;
  [DecisionPartyNameAndAddressKey.Representative]?: boolean;
  [DecisionPartyNameAndAddressKey.ActiveStartDate]?: string;
  [DecisionPartyNameAndAddressKey.ActiveEndDate]?: string;
  [DecisionPartyNameAndAddressKey.PartyName]?: string;
  [DecisionPartyNameAndAddressKey.PartyType]?: string;
  [DecisionPartyNameAndAddressKey.PartyAddress]?: string;
  [DecisionPartyNameAndAddressKey.PartyRole]?: string;
  [DecisionPartyNameAndAddressKey.AccountId]?: string;
  [DecisionPartyNameAndAddressKey.AccountName]?: string;
  [DecisionPartyNameAndAddressKey.ContactName]?: string;
  [DecisionPartyNameAndAddressKey.ContactId]?: string;
  [DecisionPartyNameAndAddressKey.RepresentativeFor]?: string;
  [DecisionPartyNameAndAddressKey.CreatedAt]?: string;
  [DecisionPartyNameAndAddressKey.UpdatedAt]?: string;
  [DecisionPartyNameAndAddressKey.CreatedBy]?: string;
  [DecisionPartyNameAndAddressKey.UpdatedBy]?: string;
  //Relations
  [DecisionPartyNameAndAddressKey.Account]?: string;
  [DecisionPartyNameAndAddressKey.Contact]?: string;

  [DecisionPartyNameAndAddressKey.CreatedUser]?: string;
  [DecisionPartyNameAndAddressKey.UpdatedUser]?: string;
  [DecisionPartyNameAndAddressKey.CaseHearing]?: string;
  [DecisionPartyNameAndAddressKey.DecisionId]?: string;
}

export interface ExistedPartiesDecision {
  [DecisionPartyNameAndAddressKey.DecisionId]?: string;
  [DecisionPartyNameAndAddressKey.Ids]?: string[];
}

export interface ExistedPartiesCaseDecision {
  [PartyNameAndAddressKey.CasePartyIds]?: string[];
}
