export interface RelatedAccountContactType {
  id: string;
  subType: string[] | null;
  insuranceType: string | null;
  createdAt: string;
  updatedAt: string;
  createdByName: string;
  updatedByName: string;
  contact: {
    id: string;
    salutation: string;
    middleInitial: string;
    firstName: string;
    lastName: string;
    suffix: string;
    street: string;
    city: string;
    state: string;
    zip: string;
    country: string;
    county: string;
    district: string;
    islandCode: string;
  };
}

export enum RelatedAccountContactKey {
  ContactName = 'contactName',
  ContactType = 'insuranceType',
  SubType = 'subType',
  Email = 'email',
  Phone = 'phone',
  Title = 'title',
  CreatedBy = 'createdBy',
  LastModifiedBy = 'lastModifiedBy',
  InsuranceType = 'insuranceType',
  Address = 'address',
}

type AccountType = {
  id: string;
  accountName: string;
};

type ContactType = {
  id: string;
  accountName: string;
};
export interface RelatedAccountContactPayload {
  account?: AccountType;
  contact?: ContactType;
  accountId: string;
  contactId: string;
  insuranceType: string;
  subType: string[];
}

export enum userInfoKey {
  ContactName = 'contactName',
  Salutation = 'salutation',
  MiddleInitial = 'middleInitial',
  FirstName = 'firstName',
  LastName = 'lastName',
  Suffix = 'suffix',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  CreatedByName = 'createdByName',
  UpdatedByName = 'updatedByName',
  CreatedBy = 'createdBy',
  LastModifiedBy = 'lastModifiedBy',
}
