import { RequestKey } from './keys';

export enum RequestType {
  GeneralRecords = 'General Records',
  PHCRecords = 'Prepaid Health Care (PHC) Records',
  TDIRecords = 'Temporary Disability Insurance (TDI) Records',
  WCRecords = "Worker's Compensation (WC) Records",
  Internal = 'Internal',
  EmployerPurge = 'Employer Purge',
}

export enum RequestStatus {
  NewRequest = 'New Request',
  InProgress = 'In Progress',
  Complete = 'Complete',
}

export interface Request {
  [RequestKey.RequestId]?: string;
  [RequestKey.RequestType]?: RequestType;

  // REQUEST INFORMATION
  [RequestKey.NumberOfPhotocopiesRequested]?: string;
  [RequestKey.RequestDate]?: string;
  [RequestKey.RequestedBy]?: string;
  [RequestKey.Firm]?: string;
  [RequestKey.RequestPhone]?: string;
  [RequestKey.RequestStreet]?: string;
  [RequestKey.RequestCity]?: string;
  [RequestKey.RequestState]?: string;
  [RequestKey.RequestZip]?: string;
  [RequestKey.PurposeOfRequest]?: string;
  [RequestKey.AssignedTo]?: string;
  [RequestKey.Status]?: RequestStatus;
  [RequestKey.Denied]?: boolean;
  [RequestKey.CompletedDate]?: string;

  [RequestKey.YearRangeStart]?: string;
  [RequestKey.YearRangeEnd]?: string;
  [RequestKey.IsNotApproved]?: boolean;
  [RequestKey.ApprovedByUserId]?: string;
  [RequestKey.ApprovedBy]?: string;
  [RequestKey.ApprovedDate]?: string;
  [RequestKey.Comment]?: string;
  [RequestKey.YearRangeEnd]?: string;
  [RequestKey.YearRangeStart]?: string;

  // CASE INFORMATION
  [RequestKey.CaseNumber]?: string;
  [RequestKey.CaseNumberId]?: string;
  [RequestKey.TDICaseNumber]?: string;
  [RequestKey.TDICaseNumberId]?: string;
  [RequestKey.TDISFCaseNumber]?: string;
  [RequestKey.TDISFCaseNumberId]?: string;
  [RequestKey.IIDate]?: string;
  [RequestKey.EmployerName]?: string;
  [RequestKey.EmployerId]?: string;
  [RequestKey.ClaimantName]?: string;
  [RequestKey.ClaimantId]?: string;
  [RequestKey.SSN]?: string;
  [RequestKey.ClaimantPhone]?: string;
  [RequestKey.ClaimantAddress]?: string;

  // AUDIT INFORMATION
  [RequestKey.CreatedBy]?: string;
  [RequestKey.UpdatedBy]?: string;
  [RequestKey.UpdatedAt]?: string;
  [RequestKey.CreatedAt]?: string;
}
