import { CreateContactKey } from 'src/containers/ContactsManagement/CreateEditContactManagement/CreateEditContact/helper';
import { ContactKey } from './keys';

export enum AccountRecordType {
  Organization = 'ORGANIZATION',
  Individual = 'INDIVIDUAL',
}

export interface ContactPayload {
  id?: string;
  [CreateContactKey.MasterId]: string;
  [CreateContactKey.GenericId]?: string;
  //Contact Information
  [CreateContactKey.Account]: string;
  [CreateContactKey.FirstName]: string;
  [CreateContactKey.LastName]: string;
  [CreateContactKey.Title]: string | null;
  [CreateContactKey.DCDID]: string | null;
  [CreateContactKey.ContactType]: string | null;
  [CreateContactKey.Salutation]: string | null;
  [CreateContactKey.MiddleInitial]: string | null;
  [CreateContactKey.Suffix]: string | null;
  [CreateContactKey.Phone]: string | null;
  [CreateContactKey.DOB]: string | null;
  [CreateContactKey.Email]: string | null;

  //Address Information
  [CreateContactKey.Address]: string | null;
  [CreateContactKey.City]: string | null;
  [CreateContactKey.Province]: string | null;
  [CreateContactKey.District]: string | null;
  [CreateContactKey.Island]: string | null;
  [CreateContactKey.Street]: string | null;
  [CreateContactKey.Zip]: string | null;
  [CreateContactKey.Country]: string | null;
  [CreateContactKey.County]: string | null;

  [ContactKey.ContactName]?: string;
  [ContactKey.AccountName]?: string;
  [ContactKey.AccountRecordType]: string;
  [ContactKey.AccountRecordType]: string;
  [ContactKey.CreatedAt]: string;
  [ContactKey.UpdatedAt]: string;
  [ContactKey.CreatedByName]: string;
  [ContactKey.UpdatedByName]: string;
  [ContactKey.AccountInfo]: {
    id: string;
    [ContactKey.AccountName]?: string;
  } | null;
}

export interface Contact {
  [ContactKey.Id]: string;
  [ContactKey.GenericId]: string;
  [ContactKey.ContactName]: string;
  [ContactKey.AccountName]: string;
  [ContactKey.AccountRecordType]: string;
  [ContactKey.Title]: string;
  [ContactKey.Phone]: string;
  [ContactKey.Email]: string;
  [ContactKey.ContactOwner]: string;
  [ContactKey.RecordType]: AccountRecordType;
  [ContactKey.AccountId]: string;
  [ContactKey.Address]?: string;
}

export enum SendEmailKey {
  To = 'contactIds',
  Subject = 'subject',
  Content = 'content',
  Attachments = 'attachments',
}

export interface EmailPayload {
  [SendEmailKey.To]: Array<string>;
  [SendEmailKey.Subject]: string;
  [SendEmailKey.Content]: string;
  [SendEmailKey.Attachments]: {
    name: string;
    url: string;
  }[];
}
