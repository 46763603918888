import { useMemo, useState } from 'react';
import { UseInfiniteQueryOptions, useInfiniteQuery, useQueryClient } from 'react-query';
import { useDebounce } from 'src/hooks';
import { isEmpty } from 'src/validations';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { Option, RelatedContact, RelatedContactKey } from '../types';

const pageParam = {
  take: 10,
  skip: 0,
};

export function useLazyRelatedContacts(
  options?: UseInfiniteQueryOptions<{ data: RelatedContact[] }, Error> & {
    id: string;
  }
) {
  const [inputSearch, setInputSearch] = useState<string>('');
  const debounceSearch = useDebounce(inputSearch);
  const {
    data,
    error,
    isError,
    isFetching,
    refetch: getRelatedContact,
    fetchNextPage,
  } = useInfiniteQuery<{ data: RelatedContact[] }, Error>(
    [API_QUERIES.RELATED_CONTACTS, pageParam, debounceSearch, options?.id],
    (props): Promise<{ data: RelatedContact[] }> => {
      const { pageParam, queryKey } = props;
      const search = queryKey.at(-2);
      const id = queryKey.at(-1);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      return responseWrapper<{ data: RelatedContact[] }>(apiClient.getAllRelatedContacts, [
        {
          ...pageParam,
          search,
          id,
        },
      ]);
    },
    {
      keepPreviousData: true,
      getNextPageParam(lastPage, allPages) {
        if (lastPage.data?.length < 10) return undefined;
        return {
          take: 10,
          skip: allPages.length * 10,
        };
      },
      enabled: !!options?.id,
      notifyOnChangeProps: ['data', 'isFetching'],
      ...options,
    }
  );

  const contacts: Option[] = useMemo(() => {
    if (isEmpty(data)) return [];
    return data.pages.reduce(
      (state, page, pageIdx) => [
        ...state,
        ...page.data.map(
          (account, idx): Option => ({
            label: `${account[RelatedContactKey.FirstName as string]} ${
              account[RelatedContactKey.LastName as string]
            } `,
            value: account.id,
            index: pageIdx * 10 + idx,
          })
        ),
      ],
      []
    ) as Option[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const queryClient = useQueryClient();

  const handleInvalidateLazyContacts = () => queryClient.resetQueries(API_QUERIES.RELATED_CONTACTS);

  return {
    contacts,
    error,
    isError,
    loading: isFetching,
    getRelatedContact,
    fetchNextPage,
    setInputSearch,
    handleInvalidateLazyContacts,
  };
}
