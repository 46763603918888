import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { ApiResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { GetPropertiesParams } from '../types';
import { toTDI21sAnnualReportSummaryPlanType } from './helpers';
import { TDI21sAnnualReportSummaryPlanTypeKey } from './keys';
import { SummaryPlanType } from './types';

const FIXED_TOTAL_RECORDS = 4;

export function useGetTDI21sAnnualReportSummaryPlanType(
  options?: UseQueryOptions<
    ApiResponseType<{
      [TDI21sAnnualReportSummaryPlanTypeKey.CalendarYear]: number;
      report: SummaryPlanType[];
    }>,
    Error
  >
) {
  const [params, setParams] = useState<GetPropertiesParams>({});

  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetTDI21sAnnualReportSummaryPlanType,
  } = useQuery<
    ApiResponseType<{
      [TDI21sAnnualReportSummaryPlanTypeKey.CalendarYear]: number;
      report: SummaryPlanType[];
    }>,
    Error
  >([API_QUERIES.TDI21S_ANNUAL_REPORT_SUMMARY_PLAN_TYPE, { ...params, ...options }], {
    queryFn: (query) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...params] = query.queryKey;
      return responseWrapper<
        ApiResponseType<{
          [TDI21sAnnualReportSummaryPlanTypeKey.CalendarYear]: number;
          report: SummaryPlanType[];
        }>
      >(apiClient.getTDI21sAnnualReportSummaryPlanType, params);
    },
    onError: ErrorService.handler,
    notifyOnChangeProps: ['data', 'isFetching'],
    select: (data) => toTDI21sAnnualReportSummaryPlanType(data, params),
    keepPreviousData: true,
    ...options,
  });

  const queryClient = useQueryClient();

  const handleInvalidateTDI21sAnnualReportSummaryPlanType = () =>
    queryClient.invalidateQueries(API_QUERIES.TDI21S_ANNUAL_REPORT_SUMMARY_PLAN_TYPE);

  const { report: tdi21sAnnualReportSummaryPlanType } = data?.data || {};

  return {
    tdi21sAnnualReportSummaryPlanType,
    calendarYear: data?.data?.calendarYear,
    totalRecords: FIXED_TOTAL_RECORDS,
    error,
    isError,
    isFetching,
    onGetTDI21sAnnualReportSummaryPlanType,
    setParams,
    handleInvalidateTDI21sAnnualReportSummaryPlanType,
  };
}
