import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { TDI46 } from './types';

export function useEditTDI46(options?: UseMutationOptions<TDI46, Error, TDI46>) {
  const {
    mutate: onEditTDI46,
    isLoading: isUpdating,
    isError,
    error,
  } = useMutation<TDI46, Error, TDI46>({
    mutationFn: (payload: TDI46) => responseWrapper(apiClient.updateTDI46, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    onEditTDI46,
    isUpdating,
    isError,
    error,
  };
}
