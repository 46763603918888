export enum TDI30Key {
  Id = 'id',
  TDI30Number = 'tdi30Number',
  TDISFCaseId = 'tdiSFCaseId',
  TDISFCaseNumber = 'tdiSFCaseNumber',
  TDISFCase = 'tdiSFCase',

  // ============= Claimant =============
  Claimant = 'claimant',
  ClaimantName = 'claimantName',
  ClaimantId = 'claimantId',
  ClaimantAddress = 'claimantAddress',
  ClaimantStreet = 'claimantStreet',
  ClaimantSSN = 'claimantSSN',
  ClaimantBirthDate = 'claimantDateOfBirth',
  ClaimantPhone = 'claimantPhone',
  ClaimantEmail = 'claimantEmail',
  ClaimantZip = 'claimantZip',
  ClaimantCity = 'claimantCity',
  ClaimantState = 'claimantState',
  ClaimantSex = 'claimantSex',
  ClaimantMaritalStatus = 'claimantMaritalStatus',
  ClaimantDateOfBirthFilterKey = 'claimant.dateOfBirth',
  ClaimantPhoneFilterKey = 'claimant.phone',
  ClaimantGenderFilterKey = 'claimant.gender',
  ClaimantEmailFilterKey = 'claimant.email',
  ClaimantMartialStatusFilterKey = 'claimant.maritalStatus',
  ClaimantStreetFilterKey = 'claimant.street',
  ClaimantCityFilterKey = 'claimant.city',
  ClaimantZipFilterKey = 'claimant.zip',
  ClaimantStateFilterKey = 'claimant.state',
  ClaimantSSNFilterKey = 'claimant.ssn',

  // ============= Disability Information =============
  MyDisabilityIs = 'myDisability',
  FirstDateDisabled = 'firstDateDisabled',
  IsRecoveredFromDisability = 'isRecoveredFromDisability',
  DateRecovered = 'dateRecovered',

  // ============= Employment Information =============
  Employer = 'employer',
  EmployerId = 'employerId',
  EmployerName = 'employerName',
  EmployerAddress = 'employerAddress',
  DateHired = 'dateHired',
  LastDateWorkedBeforeDisability = 'lastDateWorkedBeforeDisability',
  NumberDaysWorkedPerWeek = 'noOfDaysWorkedPerWeek',
  DaysNormallyWorked = 'daysNormallyWorked',
  EmployerStreet = 'employerStreet',
  EmployerCity = 'employerCity',
  EmployerZip = 'employerZip',
  EmployerState = 'employerState',
  EmployerStreetFilterKey = 'employer.street',
  EmployerCityFilterKey = 'employer.city',
  EmployerZipFilterKey = 'employer.zip',
  EmployerStateFilterKey = 'employer.state',

  // ============= Unemployment Insurance (UI) Claim Information =============
  HaveFiledClaimForUIBenefits = 'isFiledForUIBenefitsAfterLastJob',
  DateUIBenefitYearBegan = 'dateUIBenefitYearBegan',
  DateOnAttachedNoticeUIDecision = 'dateAttachedNoticeUIDecision',
  UIWeeklyBenefitAmount = 'uiWeeklyBenefitAmount',
  NoClaimOrNoUIBenefit = 'noClaimOrNoUIRecReason',

  // ============= Other Benefits (BEN) =============
  ClaimingOrReceivingBenefitsFrom = 'claimingOrBenefitsFrom',
  IsFiledTDIThroughEmployer = 'isFiledTDIThroughEmployer',
  TDIClaimStatus = 'statusTDIClaim',

  // ============= Representative =============
  SignatureName = 'signatureName',
  SignatureDate = 'signatureDate',
  RepresentativeName = 'representativeName',
  Relationship = 'relationship',

  // ============= Medical Certification =============
  ClaimantAge = 'claimantAge',
  Diagnosis = 'diagnosis',
  IsDisabilityDueToNormalPregnancy = 'isDisabilityDueToNormalPregnancy',
  IsDueToComplicationsOfPregnancy = 'isDueToComplicationsOfPregnancy',
  WasDisabilityCausedByEmployment = 'isDisabilityCausedByEmployment',

  IsWC2Filed = 'isWC2Filed',
  WC2FiledWith = 'wc2FiledWith',
  WasClaimantHospitalized = 'isClaimantHospitalized',
  ClaimantHospitalizedStartDate = 'claimantHospitalizedStartDate',
  ClaimantHospitalizedEndDate = 'claimantHospitalizedEndDate',
  IsSurgeryIndicated = 'isSurgeryIndicated',
  SurgeryType = 'surgeryType',
  FirstDateTreatment = 'firstTreatmentDate',
  FirstDateClaimantUnableToWork = 'firstDateClaimantUnableToWork',
  MostRecentTreatmentDate = 'mostRecentTreatmentDate',
  DateDischargedOrAbleToPerformWork = 'dateDischargedOrAbleToPerformWork',
  Remarks = 'remarks',

  // ============= Doctor/APRN Information =============
  Doctor = 'doctorAPRN',
  DoctorId = 'doctorAPRNId',
  DoctorName = 'doctorAPRNName',
  DoctorAddress = 'doctorAPRNAddress',
  DoctorPhone = 'doctorAPRNPhone',
  DoctorEmail = 'doctorAPRNEmail',
  DoctorFax = 'doctorAPRNFax',
  DoctorDate = 'doctorAPRNDate',
  DoctorCity = 'doctorAPRNCity',
  DoctorZip = 'doctorAPRNZip',
  DoctorState = 'doctorAPRNState',
  DoctorEmailFilterKey = 'doctorAPRN.email',
  DoctorPhoneFilterKey = 'doctorAPRN.phone',
  DoctorCityFilterKey = 'doctorAPRN.city',
  DoctorZipFilterKey = 'doctorAPRN.zip',
  DoctorStateFilterKey = 'doctorAPRN.state',

  // ============= Form Review =============
  IsCount = 'isCount',
  ReasonNotCounted = 'reasonNotCounted',
  BenefitYearBeginDateRequestedOn = 'benefitYearBeginDateRequestedOn',
  IsNoticeOnUIDecisionReceived = 'isNoticeOnUIDecisionReceived',

  // ============= Audit =============
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  CreatedBy = 'createdBy',
  UpdatedBy = 'updatedBy',
  CreatedUser = 'createdUser',
  UpdatedUser = 'updatedUser',
}
