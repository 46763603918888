export enum RoutingInformationKey {
  Id = 'id',
  ObjectId = 'objectId',
  RoutingInformationNumber = 'routingInformationNumber',
  RoutingObject = 'routingObject',
  Priority = 'priority',
  RecipientUserIds = 'recipientUserIds',
  RecipientProfileIds = 'recipientProfileIds',
  IsAllUser = 'isAllUser',
  IsAllProfile = 'isAllProfile',
  Comments = 'comments',
  From = 'from',
  To = 'to',

  // Audit
  CreatedBy = 'createdBy',
  CreatedAt = 'createdAt',
  UpdatedUser = 'updatedUser',
  UpdatedAt = 'updatedAt',
  UpdatedBy = 'updatedBy',
  CreatedUser = 'createdUser',
  AssignedUser = 'assignedUser',
}
