import apisauce from 'apisauce';
import axios from 'axios';
import { GetPropertiesParams, ViewObject } from 'src/queries';
import { TokenService } from 'src/services';
import { newCancelToken, stringify } from 'src/utils';
import appConfig from '../common/appConfig';
import { ReadNotificationPayload } from './Notifications';
import { CreateViewPayload, Service, UpdateViewPayload } from './View';

axios.defaults.withCredentials = true;

const create = (baseURL = appConfig.API_URL) => {
  //
  // Create and configure an apisauce-based api object.
  //

  const api = apisauce.create({
    baseURL,
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: 0,
      Accept: 'application/json',
    },
    timeout: appConfig.CONNECTION_TIMEOUT,
  });

  api.axiosInstance.interceptors.request.use((config) => {
    return TokenService.getToken()
      .then((token) => {
        config.headers.Authorization = 'Bearer ' + token;
        return Promise.resolve(config);
      })
      .catch(() => {
        return Promise.resolve(config);
      });
  });
  const getRoot = () => api.get('');

  // ====================== View ======================

  const createView = (body: CreateViewPayload) =>
    api.post(`/${body.service}/v1/views`, body, newCancelToken());

  const getViews = (params: { object: ViewObject; service: Service }) =>
    api.get(`/${params?.service}/v1/views?${stringify(params)}`, {}, newCancelToken());

  const getViewDetail = (params: { id: string; service: Service }) =>
    api.get(`${params.service}/v1/views/${params.id}`, {}, newCancelToken());

  const updateView = (body: UpdateViewPayload) =>
    api.put(`/${body.service}/v1/views/${body.id}`, body, newCancelToken());

  const deleteView = ({ id, service }) =>
    api.delete(`/${service}/v1/views/${id}`, {}, newCancelToken());

  const pinView = ({ id, service }) => {
    return api.post(`/${service}/v1/views/${id}/pin`, {}, newCancelToken());
  };

  const getAllGroup = (params: GetPropertiesParams) =>
    api.get(`account-svc/v1/groups?${stringify(params)}`, {}, newCancelToken());

  // ====================== Notifications ======================
  const getAllNotifications = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/notifications?${stringify(params)}`, {}, newCancelToken());

  const readNotification = (body: ReadNotificationPayload) => {
    const id = body.id;
    return api.put(`/case-svc/v1/notifications/${id}`, body, newCancelToken());
  };

  const markAllReadNotifications = () =>
    api.put(`/case-svc/v1/notifications/mark-read`, {}, newCancelToken());

  // Return back a collection of functions that we would consider our
  // interface.  Most of the time it'll be just the list of all the
  //
  // Notice we're not returning back the `api` created in step 1. That's
  // because it is scoped privately.  This is one way to create truly
  // private scoped goodies in JavaScript.
  //
  return {
    getRoot,
    // ====================== View ======================

    updateView,
    getViewDetail,
    getViews,
    createView,
    deleteView,
    pinView,
    getAllGroup,

    getAllNotifications,
    readNotification,
    markAllReadNotifications,
  };
};

export type Apis = ReturnType<typeof create>;

export default {
  create,
};
