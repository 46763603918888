import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { createResponseWrapper } from 'src/queries/helpers';
import { WC14 } from 'src/queries/WC14s/types';
import { Toastify } from 'src/services';

export function useCreateWC14(options?: UseMutationOptions<any, Error, WC14>) {
  const { mutate: createWC14, isLoading } = useMutation<any, Error, WC14>({
    mutationFn: (payload: WC14) => createResponseWrapper(apiClient.createWC14, [payload]),
    onError: ({ message }) => Toastify.error(message),
    ...options,
  });

  return {
    createWC14,
    isLoading,
  };
}
