import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { TDICoverage } from './types';

export function useEditTDICoverage(options?: UseMutationOptions<TDICoverage, Error, TDICoverage>) {
  const {
    mutate: onEditTDICoverage,
    isLoading: isUpdating,
    isError,
    error,
  } = useMutation<TDICoverage, Error, TDICoverage>({
    mutationFn: (payload: TDICoverage) => responseWrapper(apiClient.updateTDICoverage, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    onEditTDICoverage,
    isUpdating,
    isError,
    error,
  };
}
