export enum WCCoveragesKey {
  Id = 'id',
  EffectiveDate = 'effectiveDate',
  ExpirationDate = 'expirationDate',
  CoverageStatus = 'coverageStatus',
  PolicyNumber = 'policyNumber',
  PolicyType = 'policyType',
  PolicyStatusDate = 'policyStatusDate',
  InsuranceCarrier = 'insuranceCarrier',
  CarrierID = 'carrierId',
  IsMaster = 'isMaster',
  WrapUp = 'wrapUp',
  IsMultipleEmployers = 'isMultiEmployer',
  CreatedBy = 'createdBy',
  LastModifiedBy = 'updatedBy',
  EmployerName = 'employerName',
  DOLNumber = 'dolNumber',
  EmployerFEIN = 'fein',
  EmployerId = 'employerId',
  InsurancePolicyId = 'insurancePolicyId',
}
