import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { createResponseWrapper } from '../helpers';
import { TDIPlan } from './types';

export function useCreateTDIPlan(options?: UseMutationOptions<TDIPlan, Error, TDIPlan>) {
  const { mutate: createTDIPlan, isLoading } = useMutation<TDIPlan, Error, TDIPlan>({
    mutationFn: (payload: TDIPlan) => createResponseWrapper(apiClient.createTDIPlan, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    createTDIPlan,
    isLoading,
  };
}
