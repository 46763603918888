import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { TDI62 } from './types';

export function useUpdateTDI62(
  options?: UseMutationOptions<TDI62, Error, TDI62>
) {
  const {
    mutate: onEditTDI62,
    isLoading: isUpdating,
    isSuccess,
    isError,
    error,
  } = useMutation<TDI62, Error, TDI62>({
    mutationFn: (payload: TDI62) => responseWrapper(apiClient.editTDI62, [payload]),
    onError: (error) => ErrorService.handler(error),
    ...options,
  });

  return {
    onEditTDI62,
    isUpdating,
    isSuccess,
    isError,
    error,
  };
}
