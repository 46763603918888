export enum TDI21Key {
  Id = 'id',
  TDI21Number = 'tdi21Num',
  TDIForm = 'tdiForm',
  TDIFormFilterKey = 'tdiForm.tdiFormNumber',

  //HEADER
  CalendarYear = 'calendarYear',

  //INSURANCE INFORMATION

  EmployerNameRelated = 'employerNameRelated',
  EmployerIdRelated = 'employerIdRelated',
  InsuranceNameRelated = 'insuranceNameRelated',
  InsuranceIdRelated = 'insuranceIdRelated',

  //EMPLOYER INFORMATION
  EmployerId = 'employerId',
  EmployerName = 'employerName',
  EmployerRelationshipId = 'employerId',
  InsuranceCarrierRelationshipId = 'insuranceCarrierId',
  DOLNumber = 'employerDOLNumber',
  DBAName = 'employerDBAName',
  EmployerAddress = 'employerAddress',
  EmployerCity = 'employerCity',
  EmployerState = 'employerState',
  EmployerZipCode = 'employerZipcode',
  Employer = 'employer',
  InsuranceCarrier = 'insuranceCarrier',
  EmployerStreet = 'employerStreet',

  //TDI PLAN IDENTIFICATION
  TypeOfPlanBeingReported = 'typeOfPlan',
  ClassOfEmployeesCovered = 'classOfEmployeesCovered',

  //TDI REPORT INFORMATION
  NumberOfCoveredMaleEmployees = 'numberOfCoveredMaleEmploy',
  NumberOfCoveredFemaleEmployees = 'numberOfCoveredFemaleEmployees',
  // male
  NumberOfCoveredEmployeesTable = 'numberOfCoveredEmployeesTable', // custom key for table
  HeaderTooltip = 'headerTooltip', // custom key for table tooltip
  January = 'noOfCoveredMaleJan',
  February = 'noOfCoveredMaleFeb',
  March = 'noOfCoveredMaleMar',
  April = 'noOfCoveredMaleApr',
  May = 'noOfCoveredMaleMay',
  June = 'noOfCoveredMaleJun',
  July = 'noOfCoveredMaleJul',
  August = 'noOfCoveredMaleAug',
  September = 'noOfCoveredMaleSep',
  October = 'noOfCoveredMaleOct',
  November = 'noOfCoveredMaleNov',
  December = 'noOfCoveredMaleDec',

  // female
  FemaleJanuary = 'noOfCoveredFemaleJan',
  FemaleFebruary = 'noOfCoveredFemaleFeb',
  FemaleMarch = 'noOfCoveredFemaleMar',
  FemaleApril = 'noOfCoveredFemaleApr',
  FemaleMay = 'noOfCoveredFemaleMay',
  FemaleJune = 'noOfCoveredFemaleJun',
  FemaleJuly = 'noOfCoveredFemaleJul',
  FemaleAugust = 'noOfCoveredFemaleAug',
  FemaleSeptember = 'noOfCoveredFemaleSep',
  FemaleOctober = 'noOfCoveredFemaleOct',
  FemaleNovember = 'noOfCoveredFemaleNov',
  FemaleDecember = 'noOfCoveredFemaleDec',

  //=================
  AmountOfWagesPaidToCoveredEmployees = 'amountWagesPaid',
  TaxableWagesPaidToCoveredEmployees = 'taxableWagesPaid',
  AmountOfEmployerContributionPaid = 'amountEmployerContributionsPaid',
  AmountOfEmployeeContributionsWithheldFromCoveredEmployees = 'amountEmployeeContributionsWithheld',
  AmountOfBenefitPaidToMaleEmployees = 'amountBenefitsPaidToMale',
  NumberOfDifferentMaleEmployeesPaidDisabilityBenefits = 'noOfDiffMalePaidDisability',
  NumberOfWeeksBenefitsPaidToMaleEmployees = 'noOfWeeksBenefitsPaidToMale',
  NumberOfPeriodsBenefitsWerePaidToMaleEmployees = 'noOfPeriodsBenefitsPaidToMale',
  TotalNumberOfClaimsDeniedToMaleEmployees = 'noOfClaimsDeniedToMale',
  AmountOfBenefitsPaidToFemaleEmployees = 'amountBenefitsPaidToFemale',
  NumberOfDifferentFemaleEmployeesPaidDisabilityBenefits = 'noOfDiffFemalePaidDisability',
  NumberOfWeeksBenefitsPaidToFemaleEmployees = 'noOfWeeksBenefitsPaidToFemale',
  NumberOfPeriodsBenefitsPaidToFemaleEmployees = 'noOfPeriodsBenefitsPaidToFemale',
  NumberOfClaimsDeniedToFemaleEmployees = 'noOfClaimsDeniedToFemale',
  AmountOfSubrogationPaymentsReceived = 'amountSubrogationPaymentsReceived',

  //REPORTING AGENCY IDENTIFICATION
  ReportingAgencyName = 'reportingAgencyName',
  TDIReportingNumber = 'reportingAgencyTDIReportingNumber',
  ReportStreet = 'street',
  ReportCity = 'city',
  ReportState = 'state',
  ReportZipCode = 'zip',
  PointOfContactName = 'pointOfContactName',
  PointOfContactEmail = 'pointOfContactEmail',

  //ASSOCIATION OR UNION IDENTIFICATION
  AssociationOrUnionName = 'associationUnionName',
  AssociationTDIReportingNumber = 'associationTDIReportingName',
  CoveredByAssociationOrUnionPlan = 'coveredByAssociationOrUnionPlan',

  //AUTHORIZED SIGNATURE
  DateSigned = 'dateSigned',
  PrintNameAndTitle = 'printNameTitle',
  ContactPhoneNumber = 'contactPhoneNumber',

  // AUDIT
  CreatedBy = 'createdBy',
  CreatedAt = 'createdAt',
  UpdatedUser = 'updatedUser',
  UpdatedAt = 'updatedAt',
  UpdatedBy = 'updatedBy',
  CreatedUser = 'createdUser',
  AssignedUser = 'assignedUser',

  // Filter Key Employer
  EmployerDOLFilterKey = 'employer.laborNumber',
  EmployerDBAFilterKey = 'employer.dbaName',
  EmployerStreetFilterKey = 'employer.street',
  EmployerCityFilterKey = 'employer.city',
  EmployerStateFilterKey = 'employer.state',
  EmployerZipFilterKey = 'employer.zip',
}
