import { useMemo, useState } from 'react';
import { UseInfiniteQueryOptions, useInfiniteQuery } from 'react-query';
import { useDebounce } from 'src/hooks';
import { isEmpty } from 'src/validations';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { Option } from '../types';
import { ContactKey } from './helpers';
import { Contact } from './types';

const pageParam = {
  take: 10,
  skip: 0,
};

export function useGetLazyRecipients(
  options?: UseInfiniteQueryOptions<{ data: Contact[] }, Error>
) {
  const [inputSearch, setInputSearch] = useState<string>('');
  const debounceSearch = useDebounce(inputSearch);
  const {
    data,
    error,
    isError,
    isFetching,
    refetch: getContact,
    fetchNextPage,
  } = useInfiniteQuery<{ data: Contact[] }, Error>(
    [API_QUERIES.Contacts, pageParam, debounceSearch],
    (props): Promise<{ data: Contact[] }> => {
      const { pageParam, queryKey } = props;
      const search = queryKey.at(-1);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      return responseWrapper<{ data: Contact[] }>(apiClient.getAllContacts, [
        { ...pageParam, search },
      ]);
    },
    {
      keepPreviousData: true,
      getNextPageParam(lastPage, allPages) {
        if (lastPage.data?.length < 10) return undefined;
        return {
          take: 10,
          skip: allPages.length * 10,
        };
      },
      notifyOnChangeProps: ['data', 'isFetching'],
      ...options,
    }
  );

  const contacts: Option[] = useMemo(() => {
    if (isEmpty(data)) return [];
    return data.pages.reduce(
      (state, page, pageIdx) => [
        ...state,
        ...page.data.map(
          (contact, idx): Option => ({
            label: `${contact[ContactKey.ContactName as string]}`,
            value: contact[ContactKey.GenericId as string],
            index: pageIdx * 10 + idx,
            subLabel: contact[ContactKey.Email as string],
          })
        ),
      ],
      []
    ) as Option[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return {
    contacts,
    error,
    isError,
    loading: isFetching,
    getContact,
    fetchNextPage,
    setInputSearch,
  };
}
