import { AppName } from 'src/appConfig/constants';
import { TableColumnType } from 'src/modules/shared-main/components/Table/helpers';
import { Tag, ViewObject } from 'src/queries';

export interface Views {
  id: string;
  name: string;
  isDefault: boolean;
  isPinned: boolean;
}

export enum FilterKey {
  Name = 'name',
  Field = 'field',
  Operator = 'op',
  Value = 'value',
  FieldType = 'type',
}

export interface Filter {
  name: string;
  field: string;
  op: Operator | '';
  value: string | [];
  type?: TableColumnType;
}

export interface ViewsOption {
  value: string;
  label: string;
  isDefault: boolean;
  isPinned: boolean;
}

export enum RestrictGroup {
  Groups = 'GROUPS',
  OnlyMe = 'ONLY_ME',
  AllUsers = 'ALL_USERS',
}

export interface View {
  id: string;
  name: string;
  object: ViewObject;
  fields: Array<string>;
  visibility: RestrictGroup;
  isDefault: boolean;
  canBeUpdated?: boolean;
  canBeDeleted?: boolean;
  approvalGroups?: Array<string>;
  isPinned: boolean;
  filters?: Filter[];
  logic: object;
  groupIds: Array<number>;
  applyFilter: boolean;
  filterOwner: FilterOwner;
  appName: AppName;
  tag?: Tag;
  service?: Service;
}

export interface CreateViewPayload {
  name: string;
  visibility: RestrictGroup;
  fields: Array<string>;
  groupIds: Array<number>;
  object?: ViewObject;
  service?: Service;
  appName?: AppName;
  tags?: Tag;
}

export interface UpdateViewPayload {
  name: string;
  visibility: RestrictGroup;
  fields: Array<string>;
  groupIds: Array<number>;
  object: ViewObject;
  id: string;
  applyFilter?: boolean;
  filters?: Filter[];
  logic?: Object;
  filterOwner?: FilterOwner;
  service?: Service;
  appName: AppName;
  tags?: Tag;
}

export enum Logic {
  And = 'AND',
  Or = 'OR',
  Not = 'NOT',
}

export enum Operator {
  Equals = 'equals',
  NotEqualTo = 'not',
  LessThan = 'lt',
  GreaterThan = 'gt',
  LessOrEqual = 'lte',
  GreaterOrEqual = 'gte',
  Contains = 'contains',
  DoesNotContain = 'notContains',
  StartsWith = 'startsWith',
}

export enum FilterOwner {
  AllUsers = 'ALL_USERS',
  OnlyMe = 'ONLY_ME',
}

export enum Service {
  AccountService = 'account-svc',
  CaseSvc = 'case-svc',
}
