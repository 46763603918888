import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { PaginationResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { GetPropertiesParams } from '../types';
import { toAllDependents } from './helpers';
import { AwwDependent } from './types';

export function useGetAllDependents(
  options?: UseQueryOptions<PaginationResponseType<AwwDependent>, Error> & {
    awwId: string;
  }
) {
  const [params, setParams] = useState<GetPropertiesParams>({});
  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetAllDependents,
  } = useQuery<PaginationResponseType<AwwDependent>, Error>(
    [API_QUERIES.DEPENDENTS, { ...params, awwId: options?.awwId }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<PaginationResponseType<AwwDependent>>(
          apiClient.getAllRelatedDependents,
          params
        );
      },
      select: toAllDependents,
      notifyOnChangeProps: ['data', 'isFetching'],
      keepPreviousData: true,
      enabled: !!options?.awwId,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateAllDependents = () => queryClient.invalidateQueries(API_QUERIES.DEPENDENTS);

  const { data: dependents, hasNext, payloadSize, totalRecords } = data || {};

  return {
    dependents,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isError,
    isFetching,
    onGetAllDependents,
    handleInvalidateAllDependents,
    setParams,
  };
}
