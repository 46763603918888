import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { ReadNotificationPayload } from './types';

export function useReadNotification(
  options?: UseMutationOptions<ReadNotificationPayload, Error, ReadNotificationPayload>
) {
  const { mutate: readNotification, isLoading } = useMutation<
    ReadNotificationPayload,
    Error,
    ReadNotificationPayload
  >({
    mutationFn: (payload: ReadNotificationPayload) =>
      responseWrapper(apiClient.readNotification, [payload]),
    ...options,
  });

  return {
    readNotification,
    isLoading,
  };
}
