import { useMutation, UseMutationOptions } from 'react-query';
import { Toastify } from 'src/services';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { updateDCDStatusPayload } from './types';

export function useUpdateDCDStatus(
  options?: UseMutationOptions<any, Error, updateDCDStatusPayload>
) {
  const {
    mutate: onUpdateDCDStatus,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useMutation<any, Error, updateDCDStatusPayload>({
    mutationFn: (payload: updateDCDStatusPayload) =>
      responseWrapper(apiClient.updateDCDStatus, [payload]),
    onError: ({ message }) => Toastify.error(message),
    ...options,
  });

  return {
    onUpdateDCDStatus,
    isLoading,
    isSuccess,
    isError,
    error,
  };
}
