import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { ApiResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { toOrderDetail } from './helpers';
import { RelatedOrderType } from './types';

export function useGetOrder(
  options?: UseQueryOptions<
    ApiResponseType<{ order: RelatedOrderType }>,
    Error,
    RelatedOrderType
  > & {
    id: string;
  }
) {
  const {
    data: order,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetOrderById,
  } = useQuery<ApiResponseType<{ order: RelatedOrderType }>, Error, RelatedOrderType>(
    [API_QUERIES.ORDERS, { id: options.id }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<{ order: RelatedOrderType }>>(
          apiClient.getOrder,
          params
        );
      },
      select: ({ data }) => toOrderDetail(data.order),
      enabled: !!options.id,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateOrder = () =>
    queryClient.invalidateQueries([API_QUERIES.ORDERS, { id: options?.id }]);

  return {
    order,
    error,
    isError,
    isLoading,
    onGetOrderById,
    handleInvalidateOrder,
  };
}
