import { useMutation, UseMutationOptions } from 'react-query';
import { Toastify } from 'src/services';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { RelatedOrderType } from './types';

export function useEditRelatedOrder(
  options?: UseMutationOptions<any, Error, RelatedOrderType>
) {
  const {
    mutate: onEditRelatedOrder,
    isLoading: isUpdating,
    isSuccess,
    isError,
    error,
  } = useMutation<any, Error, RelatedOrderType>({
    mutationFn: (payload: RelatedOrderType) =>
      responseWrapper(apiClient.updateOrder, [payload]),
    onError: ({ message }) => Toastify.error(message),
    ...options,
  });

  return {
    onEditRelatedOrder,
    isUpdating,
    isSuccess,
    isError,
    error,
  };
}
