import { useMutation, UseMutationOptions } from 'react-query';
import { Toastify } from 'src/services';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { updateWC1StatusPayload } from './types';

export function useUpdateWC1Status(
  options?: UseMutationOptions<any, Error, updateWC1StatusPayload>
) {
  const {
    mutate: onUpdateWC1Status,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useMutation<any, Error, updateWC1StatusPayload>({
    mutationFn: (payload: updateWC1StatusPayload) =>
      responseWrapper(apiClient.updateWC1Status, [payload]),
    onError: ({ message }) => Toastify.error(message),
    ...options,
  });

  return {
    onUpdateWC1Status,
    isLoading,
    isSuccess,
    isError,
    error,
  };
}
