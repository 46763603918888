export enum DependentKeys {
  Id = 'id',
  AwardWorksheet = 'awardWorksheet',
  AwardWorksheetId = 'worksheetId',
  ClaimantDeathDate = 'claimantDeathDate',
  DependentName = 'dependentName',
  DependentNumber = 'dependentNumber',
  DependentId = 'dependentId',
  DependentDescription = 'dependentDescription',
  TotalAwardAmount = 'totalAwardAmount',
  PaymentTotalNumberOfAwardWeeks = 'paymentTotalNumberOfAwardWeek',
  DeathBenefitLimitMinWcr = 'deathBenefitLimitMinWcr',
  DeathDependentBenefitLimitMin = 'deathDependentBenefitLimitMin',
  DeathBenefitLimitMaxWcr = 'deathBenefitLimitMaxWcr',
  DeathDependentBenefitLimitMax = 'deathDependentBenefitLimitMax',
  DeathFuneralAllowanceAmount = 'deathFuneralAllowanceAmount',
  DeathBurialAllowanceAmount = 'deathBurialAllowanceAmount',
  DependentType = 'dependentType',
  DependentPercentage = 'dependentPercent',
  DeathAwwAmount = 'deathAwwAmount',
  TotalDeathBenefitAmount = 'totalAmount',
  WeeklyDependentAwardAmount = 'weeklyDependentAwardAmount',
  PeriodStartDateForEmployer = 'employerPeriodStartDate',
  PeriodEndDateForEmployer = 'employerPeriodEndDate',
  WeeksInPeriodPlusOneDayForEmployer = 'employerWeekInPeriodPlusOneDay',
  TotalAwardAmountInPeriodForEmployer = 'employerTotalAwardAmountInPeriod',
  PeriodStartDateForSCF = 'scfPeriodStartDate',
  PeriodEndDateForSCF = 'scfPeriodEndDate',
  WeeksInPeriodPlusOneDayForSCF = 'scfWeekInPeriodPlusOneDay',
  TotalAwardAmountInPeriodForSCF = 'scfTotalAwardAmountInPeriod',
  ChildType = 'childType',
  WeeksToDayBeforeAppropriateEndDate = 'weeksToDayBeforeAppropriateEndDate',
  DependentDateOfBirth = 'childDateOfBirth',
  DateOf18thBirthday = 'dateOf18thBirthday',
  DateOf22ndBirthday = 'dateOf22thBirthday',
  PayPeriodEndDateBasedOnChild = 'payPeriodEndDateBasedOnChild',
  CreatedUser = 'createdUser',
  UpdatedUser = 'updatedUser',
}
