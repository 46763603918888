import { formatDate } from 'src/utils';
import { TDI46ReconsiderationAppealsReportKey } from './keys';
import { TDI46ReconsiderationAppealsReportResponse } from './types';

export const toTDI46ReconsiderationAppealsReport = (
  data
): TDI46ReconsiderationAppealsReportResponse => {
  const originalReport = data?.data?.report?.data;

  const formattedReport = originalReport.map((item) => ({
    ...item,
    [TDI46ReconsiderationAppealsReportKey.DateReceived]: `${formatDate(
      item?.[TDI46ReconsiderationAppealsReportKey.StartDate]
    )} -
      ${formatDate(item?.[TDI46ReconsiderationAppealsReportKey.EndDate])}`,
  }));

  return {
    ...data,
    data: {
      ...data?.data,
      report: {
        ...data?.data?.report,
        data: formattedReport,
      },
    },
  };
};
