import { useState } from 'react';
import { UseQueryOptions, useQuery, useQueryClient } from 'react-query';
import { CasePartiesAndAddresses } from '.';
import apiClient from '../apiClient';
import { PaginationResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { GetPropertiesParams } from '../types';

export function useGetAllPartyNamesAddresses(
  options?: UseQueryOptions<PaginationResponseType<CasePartiesAndAddresses>, Error> & {
    caseId?: string;
  }
) {
  const [params, setParams] = useState<GetPropertiesParams>({});

  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetAllPartyNamesAddresses,
  } = useQuery<PaginationResponseType<CasePartiesAndAddresses>, Error>(
    [API_QUERIES.WC_PARTY_NAME_ADDRESS, { ...params, caseId: options.caseId }],
    {
      queryFn: ({ queryKey }) => {
        const params = queryKey.at(-1);

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return responseWrapper<PaginationResponseType<CasePartiesAndAddresses>>(
          apiClient.getCasePartiesAndAddresses,
          [params]
        );
      },
      notifyOnChangeProps: ['data', 'isFetching'],
      keepPreviousData: true,
      enabled: !!options?.caseId,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidatePartyNamesAndAddresses = () =>
    queryClient.invalidateQueries(API_QUERIES.WC_PARTY_NAME_ADDRESS);

  const { data: partyNamesAndAddresses, hasNext, payloadSize, totalRecords } = data || {};

  return {
    partyNamesAndAddresses,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isError,
    isFetching,
    onGetAllPartyNamesAddresses,
    setParams,
    handleInvalidatePartyNamesAndAddresses,
  };
}
