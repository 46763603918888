export enum NotificationKey {
  Title = 'title',
  Content = 'content',
  LinkTo = 'linkTo',
  IsRead = 'isRead',
  Owner = 'owner',
  Date = 'createdAt',
  FullName = 'fullName',
  FirstName = 'firstName',
  LastName = 'lastName',
}
