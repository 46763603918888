import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { createResponseWrapper } from '../helpers';
import { TDISFCase } from './types';

export function useCreateTDISFCase(options?: UseMutationOptions<TDISFCase, Error, TDISFCase>) {
  const { mutate: createTDISFCase, isLoading } = useMutation<TDISFCase, Error, TDISFCase>({
    mutationFn: (payload: TDISFCase) => createResponseWrapper(apiClient.createTDISFCase, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    createTDISFCase,
    isLoading,
  };
}
