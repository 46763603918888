export enum ApprovalHistoryDetailKey {
  //=======================  Approval Information ====================
  CaseLocationTransferApproval = 'caseLocationTransferApproval',
  ApprovalRequestSubmitted = 'approvalRequestSubmitted',
  Status = 'status',
  Requester = 'requester',
  RequestedDate = 'requestedDate',
  CaseNumber = 'caseNumber',
  StepName = 'stepName',
  AssignedToGroupName = 'assignedToGroupName',
  RequestedTransferLocation = 'requestedTransferLocation',
  CurrentServicingLocation = 'currentServicingLocation',
  CaseSettlementNumber = 'object.caseSettlementNumber',

  //======================= Audit Information ====================
  CreatedByUser = 'createdByUser',
  CreatedUser = 'createdUser',
  CreatedDate = 'createdDate',
  UpdatedUser = 'updatedUser',
  CreatedAt = 'createdAt',
  LastModifiedByUser = 'lastModifiedByUser',
  UpdateBy = 'updatedBy',
  UpdateAt = 'updatedAt',
  DecisionAt = 'decisionAt',
  LastModifiedDate = 'lastModifiedDate',
  Comment = 'comment',
  TransferReason = 'transferReason',
  ApprovalRequest = 'approvalRequest',
  Reject = 'reject',
  Approver = 'approver',
  RejectedDate = 'rejectedDate',
  ApprovedDate = 'approvedDate',
  ActualApprover = 'actualApprover',
  ApprovedRejectedDate = 'approvedRejectedDate',
  AssignedTo = 'assignedTo',
  DecisionId = 'decisionId',
  ObjectId = 'objectId',
  ObjectType = 'objectType',
  Submitter = 'submitter',
  //==================== Functionalities Key==================
  ApproveCaseLocationTransfer = 'approveCaseLocationTransfer',
  Cancel = 'cancel',
  RejectCaseLocationTransfer = 'rejectCaseLocationTransfer',
  //==================== Hearing==================
  HearingNumber = 'object.hearingNumber',
  ReassignHearingOfficer = 'reassignHearingOfficer',
  CurrentHearingOfficer = 'currentHearingOfficer',
  HearingEventStatus = 'hearingEventStatus',
  HearingDate = 'hearingDate',
  Object = 'object',
  Room = 'room',
  RoomName = 'room.roomName',
  CancellationDate = 'cancellationDate',
  Case = 'case',
}
