export enum WC2FormKey {
  Id = 'id',
  DCDCaseId = 'caseId',
  WC2Number = 'formNumber',
  DateReceived = 'rawDateReceived',
  TypeReport = 'rawReportType',
  PrintedNamePhysician = 'rawPhysicianPrintedName',
  CreatedDate = 'createdAt',
}

export interface WC2Form {
  [WC2FormKey.Id]: string;
  [WC2FormKey.DCDCaseId]: string;
  [WC2FormKey.WC2Number]: string;
  [WC2FormKey.TypeReport]: string;
  [WC2FormKey.PrintedNamePhysician]: string;
  [WC2FormKey.DateReceived]: string;
  [WC2FormKey.CreatedDate]: string;
}

export interface WC2FormPayload {
  [WC2FormKey.DCDCaseId]: string;
}
