import { useMemo, useState } from 'react';
import { useInfiniteQuery, UseInfiniteQueryOptions } from 'react-query';
import { useDebounce } from 'src/hooks';
import apiClient from 'src/queries/apiClient';
import { languageResponseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { isEmpty } from 'src/validations';
import { Option } from '../types';
import { LocaleFromZipFolder } from './types';

const defaultSearch = {
  take: 10,
};

export function usePaginationLocale(
  options?: UseInfiniteQueryOptions<LocaleFromZipFolder[], Error> & {
    url: string;
  }
) {
  const [inputSearch, setInputSearch] = useState<string>('');
  const debounceSearch = useDebounce(inputSearch);
  const {
    data,
    error,
    isError,
    isFetching,
    refetch: getLocales,
    fetchNextPage,
  } = useInfiniteQuery<LocaleFromZipFolder[], Error>(
    [API_QUERIES.LOCALES, defaultSearch, debounceSearch],
    (props): Promise<LocaleFromZipFolder[]> => {
      const { pageParam = defaultSearch, queryKey } = props;
      const search = queryKey.at(-1);
      const { url } = options;
      return languageResponseWrapper<LocaleFromZipFolder[]>(apiClient.getLocale, [
        { ...pageParam, search, url },
      ]);
    },
    {
      keepPreviousData: true,
      getNextPageParam(lastPage, allPages) {
        if (lastPage?.length < 10) return undefined;
        return {
          take: 10,
          skip: allPages.length * 10,
        };
      },
      notifyOnChangeProps: ['data', 'isFetching'],
      enabled: !!options.url,
      ...options,
    }
  );

  const locales: Option[] = useMemo(() => {
    if (isEmpty(data?.pages)) return [];
    return data.pages.reduce(
      (state, page, pageIdx) => [
        ...state,
        ...page.map(
          ({ locale }, idx): Option => ({
            label: locale,
            value: locale,
            index: pageIdx * 10 + idx,
          })
        ),
      ],
      []
    ) as Option[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return {
    locales,
    error,
    isError,
    loading: isFetching,
    setInputSearch,
    getLocales,
    fetchNextPage,
  };
}
