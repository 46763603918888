import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { authResponseWrapper } from '../helpers';

export function useDeleteHRS(options?: UseMutationOptions<string, Error, string>) {
  const { mutate: deleteHRS, isLoading } = useMutation<string, Error, string>({
    mutationFn: (payload: string) => authResponseWrapper(apiClient.deleteHRS, [payload]),
    onError: ErrorService.handler,
    ...options,
  });
  return { deleteHRS, isLoading };
}
