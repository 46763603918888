import { useMemo, useRef } from 'react';
import { IoCloseOutline } from 'react-icons/io5';
import ReactQuill, { Quill } from 'react-quill';
import { COLOR_CODE } from 'src/appConfig/constants';
import { getRandomId, kFormatter } from 'src/utils';
import CustomToolbar, { modules } from './Toolbar';
import { Element, Text, View } from 'src/modules/shared-main/components';

// configure Quill to use inline styles so the email's format properly
const directionAttribute = Quill.import('attributors/attribute/direction');
Quill.register(directionAttribute, true);

const alignClass = Quill.import('attributors/class/align');
Quill.register(alignClass, true);

const backgroundClass = Quill.import('attributors/class/background');
Quill.register(backgroundClass, true);

const colorClass = Quill.import('attributors/class/color');
Quill.register(colorClass, true);

const directionClass = Quill.import('attributors/class/direction');
Quill.register(directionClass, true);

const fontClass = Quill.import('attributors/class/font');
Quill.register(fontClass, true);

const sizeClass = Quill.import('attributors/class/size');
Quill.register(sizeClass, true);

const alignStyle = Quill.import('attributors/style/align');
Quill.register(alignStyle, true);

const backgroundStyle = Quill.import('attributors/style/background');
Quill.register(backgroundStyle, true);

const colorStyle = Quill.import('attributors/style/color');
Quill.register(colorStyle, true);

const directionStyle = Quill.import('attributors/style/direction');
Quill.register(directionStyle, true);

const fontStyle = Quill.import('attributors/style/font');
Quill.register(fontStyle, true);

const sizeStyle = Quill.import('attributors/style/size');
Quill.register(sizeStyle, true);

export const TOTAL_MAX_FILE_SIZE = 1024 * 1024 * 29;

const Editor: React.FC<Props> = ({
  name,
  onChange,
  value = '',
  label = '',
  onChangeAttachments,
  files = [],
  required,
  disableAttachmentUpload,
}) => {
  const id = useRef(`editor-${getRandomId()}`);
  const ref = useRef();

  const onHandleChange = (value: string) => {
    onChange(name, value);
  };

  const formats = [
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'color',
    'background',
    'script',
    'header',
    'blockquote',
    'code-block',
    'indent',
    'list',
    'direction',
    'align',
    'link',
    'image',
    'video',
    'formula',
  ];

  const handleChangeFile = (file: { _compressFile: File; url: string }) => {
    onChangeAttachments([...files, file]);
  };

  const onDeleteFile = (selectedFile: number) => {
    onChangeAttachments(files.filter((_, index) => index !== selectedFile));
  };

  const maxFileSize = useMemo(
    () =>
      TOTAL_MAX_FILE_SIZE -
      files.reduce((sum, { _compressFile }) => {
        return sum + _compressFile.size;
      }, 0),
    [files]
  );

  return (
    <Element id={id.current} label={label} required={required}>
      <View className="cmp-editor" justify="space-between">
        <View>
          <CustomToolbar
            forwardedRef={ref}
            setFiles={handleChangeFile}
            maxFileSize={maxFileSize}
            disableAttachmentUpload={disableAttachmentUpload}
          />
          <ReactQuill
            theme="snow"
            value={value}
            onChange={onHandleChange}
            modules={modules}
            formats={formats}
            ref={ref}
          />
        </View>
        <View>
          {files.map(({ _compressFile }, index) => {
            const { name, size } = _compressFile || {};
            return (
              <View
                key={index}
                justify="space-between"
                align="center"
                isRow
                className="cmp-editor__file p-16"
              >
                <a
                  rel="noreferrer"
                  className="fw-bold cursor-pointer has-text-primary"
                  href={URL.createObjectURL(_compressFile)}
                  download={true}
                  target="_blank"
                >
                  <Text size={14}>
                    {name}
                    <span className="text-color-grey-900"> ({kFormatter(size)})</span>
                  </Text>
                </a>
                <IoCloseOutline
                  onClick={() => onDeleteFile(index)}
                  size={24}
                  color={COLOR_CODE.GRAY_900}
                  className="cursor-pointer"
                />
              </View>
            );
          })}
        </View>
      </View>
    </Element>
  );
};

type Props = {
  onChange: (name: string, value: string) => void;
  value: string;
  onChangeAttachments?: Function;
  files?: { _compressFile: File; url: string }[];
  name: string;
  label?: string | React.ReactNode;
  required?: boolean;
  disableAttachmentUpload?: boolean;
};

export default Editor;
