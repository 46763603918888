import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { ApiResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { toComplaint } from './helpers';
import { Complaint } from './types';

export function useGetComplaint(
  options?: UseQueryOptions<ApiResponseType<{ complaint: Complaint }>, Error, Complaint> & {
    id: string;
  }
) {
  const {
    data: complaint,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetComplaintById,
  } = useQuery<ApiResponseType<{ complaint: Complaint }>, Error, Complaint>(
    [API_QUERIES.Complaints, { id: options.id }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<{ complaint: Complaint }>>(
          apiClient.getComplaint,
          params
        );
      },
      select: ({ data }) => toComplaint(data?.complaint),
      enabled: !!options.id,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateComplaint = () =>
    queryClient.invalidateQueries([API_QUERIES.Complaints, { id: options.id }]);

  return {
    complaint,
    error,
    isError,
    isLoading,
    onGetComplaintById,
    handleInvalidateComplaint,
  };
}
