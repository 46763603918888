import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { createResponseWrapper } from '../helpers';
import { RelatedHearingRequiredDocumentsType } from './types';

export function useCreateRelatedHearingRequiredDocuments(
  options?: UseMutationOptions<
    RelatedHearingRequiredDocumentsType,
    Error,
    RelatedHearingRequiredDocumentsType
  >
) {
  const { mutate: createRelatedHearingRequiredDocuments, isLoading } = useMutation<
    RelatedHearingRequiredDocumentsType,
    Error,
    RelatedHearingRequiredDocumentsType
  >({
    mutationFn: (payload: any) =>
      createResponseWrapper(apiClient.createRelatedHearingRequiredDocuments, [payload]),
    onError: (error) => ErrorService.handler(error),
    ...options,
  });

  return {
    createRelatedHearingRequiredDocuments,
    isLoading,
  };
}
