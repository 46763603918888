import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { createResponseWrapper } from '../helpers';
import { toValues } from './helpers';
import { Disbursement } from './types';

export function useCreateDisbursement(
  options?: UseMutationOptions<Disbursement, Error, Disbursement>
) {
  const { mutate: createDisbursement, isLoading } = useMutation<Disbursement, Error, Disbursement>({
    mutationFn: (payload: Disbursement) =>
      createResponseWrapper(apiClient.createDisbursement, [toValues(payload)]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    createDisbursement,
    isLoading,
  };
}
