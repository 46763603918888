import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { ApiResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { toCustomSetting } from './helpers';
import { CustomSettings } from './types';

export function useGetCustomSettings(
  options?: UseQueryOptions<ApiResponseType<{ setting: CustomSettings }>, Error, CustomSettings> & {
    [key: string]: string | number | string[] | boolean;
  }
) {
  const {
    data: customSettings,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetCustomSetting,
  } = useQuery<ApiResponseType<{ setting: CustomSettings }>, Error, CustomSettings>(
    [API_QUERIES.CUSTOM_SETTING, { tab: options?.tab }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<{ setting: CustomSettings }>>(
          apiClient.getCustomSetting,
          params
        );
      },
      select: ({ data }) => toCustomSetting(data.setting),
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateCustomSettings = () =>
    queryClient.invalidateQueries([API_QUERIES.CUSTOM_SETTING, { tab: options?.tab }]);

  return {
    customSettings,
    error,
    isError,
    isLoading,
    onGetCustomSetting,
    handleInvalidateCustomSettings,
  };
}
