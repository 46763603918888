import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { ApiResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { toTdiPlanDetail } from './helpers';
import { TDIPlan } from './types';

export function useGetTdiPlan(
  options?: UseQueryOptions<ApiResponseType<{ tdiPlanType: TDIPlan }>, Error, TDIPlan> & {
    id: TDIPlan['id'];
  }
) {
  const {
    data: tdiPlan,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetTdiPlanById,
  } = useQuery<ApiResponseType<{ tdiPlanType: TDIPlan }>, Error, TDIPlan>(
    [API_QUERIES.TDIPlans, { id: options.id }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<{ tdiPlanType: TDIPlan }>>(
          apiClient.getTDIPlan,
          params
        );
      },
      select: (data) => toTdiPlanDetail(data.data.tdiPlanType),

      enabled: !!options.id,
      onError: ErrorService.handler,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateTdiPlan = () =>
    queryClient.invalidateQueries([API_QUERIES.TDIPlans, { id: options.id }]);

  return {
    tdiPlan,
    error,
    isError,
    isLoading,
    onGetTdiPlanById,
    handleInvalidateTdiPlan,
  };
}
