import { useMutation, UseMutationOptions } from 'react-query';
import { Toastify } from 'src/services';
import apiClient from '../apiClient';
import { authResponseWrapper } from '../helpers';
export function useDeleteProfile(options?: UseMutationOptions<string, Error, string>) {
  const { mutate: deleteProfile, isLoading } = useMutation<string, Error, string>({
    mutationFn: (payload: string) => authResponseWrapper(apiClient.deleteProfile, [payload]),
    onError: ({ message }) => Toastify.error(message),
    ...options,
  });

  return {
    deleteProfile,
    isLoading,
  };
}
