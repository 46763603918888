import background from 'src/assets/images/background.svg';
import backgroundDiamond from 'src/assets/images/background_diamond.png';
import backgroundDiamondFooter from 'src/assets/images/background_diamond_footer.png';
import backgroundDiamondSmall from 'src/assets/images/background_diamond_small.png';
import backgroundLogin from 'src/assets/images/background_login.webp';
import defaultAvatar from 'src/assets/images/default-avatar.svg';
import defaultUser from 'src/assets/images/default-user.png';
import documentView from 'src/assets/images/document-view.png';
import empty from 'src/assets/images/empty.png';
import iconHawaii from 'src/assets/images/hawaiiLogo.png';
import iconHawaiiNoneColor from 'src/assets/images/iconHawaiiNoneColor.png';
import iconWarning from 'src/assets/images/iconWarning.png';
import iconDollarCircleFilled from 'src/assets/images/icons/ant-design/dollar-circle.svg';
import iconPeopleCircle from 'src/assets/images/icons/ant-design/people.svg';
import iconCircleMinus from 'src/assets/images/icons/ic-circle-minus.png';
import iconCirclePlus from 'src/assets/images/icons/ic-circle-plus.png';
import iconDelete from 'src/assets/images/icons/ic-delete.png';
import iconEdit from 'src/assets/images/icons/ic-edit.png';
import iconWC1 from 'src/assets/images/icons/ic-wc1.png';
import iconComputer from 'src/assets/images/icons/ic_computer.png';
import iconTdiPlan from 'src/assets/images/icons/icon-tdiPlan.png';
import logo from 'src/assets/images/logos/logo.svg';
import iconFileFilled from 'src/assets/related-icons/FileFilled.svg';
import iconAccountCases from 'src/assets/related-icons/account-cases.svg';
import iconAccountContact from 'src/assets/related-icons/account-contact.svg';
import iconAccountRelated from 'src/assets/related-icons/account-related.svg';
import iconAccount from 'src/assets/related-icons/account.svg';
import addressHistoryArchives from 'src/assets/related-icons/address-history-archives.svg';
import iconAddressHistory from 'src/assets/related-icons/address-history.svg';
import iconAuditHistory from 'src/assets/related-icons/audit-history.svg';
import iconAwardEmployment from 'src/assets/related-icons/award-employment.svg';
import iconAwardWorksheet from 'src/assets/related-icons/award-worksheets.svg';
import iconCaseDependents from 'src/assets/related-icons/case-dependents.svg';
import iconComplaint from 'src/assets/related-icons/complaints.svg';
import iconContact from 'src/assets/related-icons/contact.svg';
import iconCopy from 'src/assets/related-icons/copy.svg';
import iconCoverage from 'src/assets/related-icons/coverage.svg';
import iconDCDCase from 'src/assets/related-icons/dcd-case.svg';
import iconDecisionGreen from 'src/assets/related-icons/decision-green.svg';
import iconDecisionOrange from 'src/assets/related-icons/decision-orange.svg';
import iconDecisionPurple from 'src/assets/related-icons/decision-purple.svg';
import iconEmployerAudit from 'src/assets/related-icons/employer-audit.svg';
import iconEmployerAudits from 'src/assets/related-icons/employer-audits.svg';
import iconHealthCare from 'src/assets/related-icons/health-care.svg';
import iconHRS from 'src/assets/related-icons/hearing-hrs.svg';
import iconHeathCarePlans from 'src/assets/related-icons/heath-care-plans.svg';
import iconHearing from 'src/assets/related-icons/icon-hearing.svg';
import iconInjuryIllness from 'src/assets/related-icons/injury-illness.svg';
import iconNote from 'src/assets/related-icons/note.svg';
import iconPNA from 'src/assets/related-icons/party-names-addresses.svg';
import phcCoveragePink from 'src/assets/related-icons/phc-coverage-pink.svg';
import phcCoverage from 'src/assets/related-icons/phc-coverage.svg';
import phcCoverageDetail from 'src/assets/related-icons/phc-coverage-detail.svg';
import iconPHC from 'src/assets/related-icons/phc.svg';
import iconPortalUser from 'src/assets/related-icons/portal-user.svg';
import iconPotential from 'src/assets/related-icons/potentialassociated.svg';
import iconRequestReconsideration from 'src/assets/related-icons/request-reconsideration.svg';
import iconRequest from 'src/assets/related-icons/request.svg';
import reward from 'src/assets/related-icons/reward.svg';
import iconRouting from 'src/assets/related-icons/routing.svg';
import iconTDICaseDetail from 'src/assets/related-icons/tdi-case-detail.svg';
import iconTDICase from 'src/assets/related-icons/tdi-case.svg';
import iconTdiCoverage from 'src/assets/related-icons/tdi-coverages.svg';
import iconTDISFDisbursements from 'src/assets/related-icons/tdi-sf-disbursements.svg';
import iconTDIsf from 'src/assets/related-icons/tdi-sf.svg';
import iconRelatedHearingTDISFC from 'src/assets/related-icons/tdi-sfc-hearing.svg';
import iconTrackingLog from 'src/assets/related-icons/tracking-log.svg';
import iconWCInsurancePolicy from 'src/assets/related-icons/wc-insurance-policies.svg';
import iconWC1Form from 'src/assets/related-icons/wc1-form.svg';

export const IMAGES = {
  logo,
  background,
  backgroundDiamond,
  backgroundDiamondSmall,
  backgroundDiamondFooter,
  backgroundLogin,
  defaultUser,
  defaultAvatar,
  iconComputer,
  documentView,
  empty,
  iconEdit,
  iconCirclePlus,
  iconCircleMinus,
  iconDelete,
  iconAccountContact,
  iconAddressHistory,
  iconCoverage,
  iconTdiCoverage,
  iconEmployerAudits,
  iconPHC,
  phcCoverageDetail,
  iconRouting,
  iconTDICase,
  iconTDIsf,
  iconContact,
  iconTrackingLog,
  iconAccount,
  iconWC1,
  iconWC1Form,
  iconInjuryIllness,
  iconCaseDependents,
  iconDCDCase,
  iconPeopleCircle,
  iconDollarCircleFilled,
  iconHawaii,
  iconTdiPlan,
  iconRelatedHearingTDISFC,
  iconTDICaseDetail,
  iconRequest,
  iconHawaiiNoneColor,
  iconCopy,
  iconHealthCare,
  iconAwardWorksheet,
  iconFileFilled,
  iconAwardEmployment,
  iconHearing,
  iconPotential,
  iconWarning,
  iconDecisionGreen,
  iconDecisionOrange,
  iconDecisionPurple,
  iconTDISFDisbursements,
  iconHeathCarePlans,
  iconHRS,
  phcCoverage,
  phcCoveragePink,
  iconAccountRelated,
  iconPNA,
  iconEmployerAudit,
  iconComplaint,
  addressHistoryArchives,
  iconRequestReconsideration,
  iconAccountCases,
  iconPortalUser,
  iconAuditHistory,
  iconWCInsurancePolicy,
  iconNote,
  reward,
};
