import { Skeleton, Tab, Tabs } from '@mui/material';
import React from 'react';
import { COLOR_CODE } from 'src/appConfig/constants';
import { Callback } from 'src/redux/types';
import cn from 'classnames';
import './styles.scss';
import { View, Text } from '..';

interface TabList {
  label: string;
  value: string;
  count?: number;
  isHidden?: boolean;
}

const TabsBar: React.FC<Props> = ({
  value,
  onChange,
  tabsList,
  buttons,
  color = 'primary',
  containerClassName = '',
  isLoading = false,
}) => {
  return isLoading ? (
    <View fullWidth>
      <Skeleton variant="rounded" height={52} />
    </View>
  ) : (
    <View
      isRowWrap
      justify="space-between"
      align="center"
      className={cn(containerClassName, 'react-mui-tabs')}
    >
      <Tabs
        value={value}
        indicatorColor={color}
        textColor={color}
        onChange={onChange}
        variant="scrollable"
        scrollButtons="auto"
        classes={{
          indicator: `react-mui-tabs__indicator`,
        }}
      >
        {tabsList.map(({ count, label, value, isHidden }) => {
          return (
            !isHidden && (
              <Tab
                label={
                  !!count ? (
                    <View isRow>
                      <Text className={``}>{label} </Text>
                      <View style={tagStyles}>{count}</View>
                    </View>
                  ) : (
                    label
                  )
                }
                value={value}
                classes={{ root: `react-mui-tabs__tab`, selected: `react-mui-tabs__tab--selected` }}
                key={value}
              />
            )
          );
        })}
      </Tabs>
      <View className="ml-16">{buttons}</View>
    </View>
  );
};

const tagStyles = {
  borderRadius: '16px',
  color: COLOR_CODE.WHITE,
  background: COLOR_CODE.DANGER,
  fontSize: 12,
  alignSelf: 'center',
  padding: '0px 4px',
  marginLeft: '4px',
};

type Props = {
  value: string;
  onChange: Callback;
  tabsList: TabList[];
  buttons?: React.ReactNode;
  color?: 'primary' | 'secondary';
  dataLength?: number;
  containerClassName?: string;
  isLoading?: boolean;
};

export default TabsBar;
