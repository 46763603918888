import { PaginationResponseType, toAuditData } from '../helpers';
import { UploadedFileKey } from './keys';
import { UploadedFile } from './types';

export const toUploadedFiles = (data): PaginationResponseType<UploadedFile> => ({
  ...data,
  data: data.data.map((file) => ({
    ...file,
    [UploadedFileKey.DCDCaseNumber]: file?.wcCase?.caseNumber,
    [UploadedFileKey.DCDCaseId]: file?.wcCase?.id,
    [UploadedFileKey.TDICaseId]: file?.tdiCase?.id,
    [UploadedFileKey.TDICaseNumber]: file?.tdiCase?.tdiCaseNumber,
    [UploadedFileKey.InsuranceCarrier]: file?.insuranceCarrier?.accountName,
    [UploadedFileKey.EmployerName]: file?.employer?.accountName,
    ...toAuditData(file),
  })),
});
