import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { toHCPs } from 'src/queries/HealthCarePlans/helpers';
import { PaginationResponseType, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { GetPropertiesParams } from 'src/queries/types';
import { HealthCarePlan } from '../HealthCarePlans';
import { RejectedSelfInsuredHCPReportKey } from './keys';

export function useGetRejectedHCPDetail(
  options?: UseQueryOptions<PaginationResponseType<HealthCarePlan>, Error> & {
    [RejectedSelfInsuredHCPReportKey.StartDate]: string;
    [RejectedSelfInsuredHCPReportKey.EndDate]: string;
  }
) {
  const [params, setParams] = useState<GetPropertiesParams>({});

  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetRejectedHCPDetail,
  } = useQuery<PaginationResponseType<HealthCarePlan>, Error>(
    [API_QUERIES.REJECTED_PHC_SELF_INSURANCE, { ...params, ...options }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<PaginationResponseType<HealthCarePlan>>(
          apiClient.getRejectedHCPDetail,
          params
        );
      },
      notifyOnChangeProps: ['data', 'isFetching'],
      keepPreviousData: true,
      enabled: !!options?.startDate && !!options?.endDate,
      select: (data) => toHCPs(data),
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateRejectedHCPDetail = () =>
    queryClient.invalidateQueries(API_QUERIES.REJECTED_PHC_SELF_INSURANCE);

  const { data: rejectedHCPDetail, hasNext, payloadSize, totalRecords } = data || {};

  return {
    rejectedHCPDetail,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isError,
    isFetching,
    onGetRejectedHCPDetail,
    setParams,
    handleInvalidateRejectedHCPDetail,
  };
}
