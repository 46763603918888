import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { Box } from '@mui/material';
import { AnimatePresence, MotionProps, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Icon, Image, Text, View } from 'src/modules/shared-main/components';
import { RelatedType, relatedTypeList } from './helpers';
import './styles.scss';

const RelatedContainer: React.FC<Props> = ({
  totalRecords = 0,
  relatedType,
  isExpanded,
  onClickViewAll,
  onClickAddNew,
  customButton,
  showViewAll = false,
  children,
  isShowButton = true,
  customMarginTop = 16,
}) => {
  const { id } = useParams<{ id: string }>();
  const [expanded, setExpanded] = useState<boolean>(showViewAll ? showViewAll : null);

  const handleToggle = () => setExpanded(!expanded);

  useEffect(() => {
    if (!showViewAll) {
      !!totalRecords ? setExpanded(true) : setExpanded(false);
    }
  }, [totalRecords, id, showViewAll]);

  const handleClickViewAll = () => {
    if (!onClickViewAll) return;
    onClickViewAll();
  };

  const handleClickAddNew = () => {
    if (!onClickAddNew) return;
    onClickAddNew();
  };

  const { title, icon } = relatedTypeList.find((item) => item.title === relatedType);

  return (
    <View
      className="related-container"
      style={{
        marginTop: customMarginTop,
      }}
    >
      <View isRow justify="space-between" align="center">
        <View isRow>
          <Image src={icon} className="related-container__icon" />
          <Text className="fw-bold" size={18}>{`${title} (${totalRecords ?? 0})`}</Text>
        </View>
        <View isRow align="center">
          {customButton
            ? customButton
            : onClickAddNew && isShowButton && <Button onClick={handleClickAddNew}>New</Button>}
          <View renderIf={!showViewAll}>
            <motion.div initial={false} onClick={handleToggle}>
              <Box className="related-container__container-arrow">
                <Icon className="related-container__arrow">
                  {expanded ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                </Icon>
              </Box>
            </motion.div>
          </View>
        </View>
      </View>
      <AnimatePresence initial={false}>
        <motion.section
          key="content"
          initial={isExpanded ? 'open' : 'collapsed'}
          animate={expanded ? 'open' : 'collapsed'}
          variants={{
            open: { opacity: 1, height: 'auto' },
            collapsed: { overflow: 'hidden', height: 0 },
          }}
          transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
        >
          <View className="mt-16">{children}</View>
          <View renderIf={!showViewAll} isRow justify="center">
            <Text className="related-container__view" onClick={handleClickViewAll}>
              View All
            </Text>
          </View>
        </motion.section>
      </AnimatePresence>
    </View>
  );
};

type Props = MotionProps & {
  totalRecords?: number;
  isExpanded?: boolean;
  children: React.ReactNode;
  relatedType: RelatedType;
  onClickViewAll?: Function;
  onClickAddNew?: Function;
  showViewAll?: boolean;
  isShowButton?: boolean;
  customButton?: React.ReactNode | any;
  customMarginTop?: number;
};

export default RelatedContainer;
