import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { TrackingLog } from './types';

export function useUpdateTrackingLog(
  options?: UseMutationOptions<TrackingLog, Error, TrackingLog>
) {
  const {
    mutate: onEditTrackingLog,
    isLoading: isUpdating,
    isSuccess,
    isError,
    error,
  } = useMutation<TrackingLog, Error, TrackingLog>({
    mutationFn: (payload: TrackingLog) => responseWrapper(apiClient.updateTrackingLog, [payload]),
    onError: (error) => ErrorService.handler(error),
    ...options,
  });

  return {
    onEditTrackingLog,
    isUpdating,
    isSuccess,
    isError,
    error,
  };
}
