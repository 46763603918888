import { formatDateTime } from 'src/utils';
import { PaginationResponseType } from '../helpers';
import { RelatedContact, RelatedContactKey } from '../types';
import { ContactPayload } from './types';

export const toRelatedContactsData = (
  data: PaginationResponseType<RelatedContact>
): PaginationResponseType<RelatedContact> => {
  return {
    ...data,
    data: data?.data.map((relatedContact) => {
      const contactName = [
        relatedContact?.[RelatedContactKey.Salutation],
        relatedContact?.[RelatedContactKey.FirstName],
        relatedContact?.[RelatedContactKey.MiddleInitial],
        relatedContact?.[RelatedContactKey.LastName],
        relatedContact?.[RelatedContactKey.Suffix],
      ]
        .filter((item) => item)
        .join(' ');
      const relatedContactCreated = formatDateTime(relatedContact?.[RelatedContactKey.CreatedAt]);
      const relatedContactUpdated = formatDateTime(relatedContact?.[RelatedContactKey.UpdatedAt]);
      const createdBy = `${
        relatedContact?.[RelatedContactKey.CreatedByName]
      }, ${relatedContactCreated}`;
      const lastModifiedBy = relatedContact?.[RelatedContactKey.UpdatedByName]
        ? `${relatedContact?.[RelatedContactKey.UpdatedByName]}, ${relatedContactUpdated}`
        : null;

      return {
        ...relatedContact,
        [RelatedContactKey.ContactName]: contactName,
        [RelatedContactKey.CreatedBy]: createdBy,
        [RelatedContactKey.LastModifiedBy]: lastModifiedBy,
      };
    }),
  };
};

export const toContactData = (data: ContactPayload) => {
  const relatedContactCreated = formatDateTime(data?.createdAt);
  const relatedContactUpdated = formatDateTime(data?.updatedAt);
  const createdBy = `${data?.createdByName}, ${relatedContactCreated}`;
  const lastModifiedBy = data?.updatedByName
    ? `${data?.updatedByName}, ${relatedContactUpdated}`
    : null;
  return {
    ...data,
    contactName: [
      data?.[RelatedContactKey.Salutation],
      data?.[RelatedContactKey.FirstName],
      data?.[RelatedContactKey.MiddleInitial],
      data?.[RelatedContactKey.LastName],
      data?.[RelatedContactKey.Suffix],
    ]
      .filter((item) => item)
      .join(' '),
    accountName: data?.account.accountName,
    address: [
      data?.street,
      data?.district,
      data?.city,
      data?.state,
      data?.zip,
      data?.county,
      data?.islandCode,
      data?.country,
    ]
      .filter((item) => item)
      .join(', '),
    createdBy: createdBy,
    lastModifiedBy: lastModifiedBy,
  };
};

export enum ContactKey {
  ContactName = 'contactName',
  GenericId = 'genericId',
  AccountName = 'accountName',
  Id = 'id',
  Email = 'email',
}
