import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { createResponseWrapper } from '../helpers';
import { PHCCoverage } from './types';

export function useCreatePHCCoverage(
  options?: UseMutationOptions<PHCCoverage, Error, PHCCoverage>
) {
  const { mutate: createPHCCoverage, isLoading } = useMutation<PHCCoverage, Error, PHCCoverage>({
    mutationFn: (payload: PHCCoverage) =>
      createResponseWrapper(apiClient.createPHCCoverage, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    createPHCCoverage,
    isLoading,
  };
}
