import { ApiResponseType } from '../helpers';
import { GetPropertiesParams } from '../types';
import { TDI21sAnnualReportSummaryPlanTypeKey } from './keys';
import { SummaryPlanType } from './types';

export const toTDI21sAnnualReportSummaryPlanType = (
  data,
  params: GetPropertiesParams
): ApiResponseType<{
  [TDI21sAnnualReportSummaryPlanTypeKey.CalendarYear]: number;
  report: SummaryPlanType[];
}> => {
  const { report } = data?.data;
  const { order } = params;

  const typeOfPlanOrderAndMapping = {
    'Insured Plan, Statutory': { order: 1, label: '1. Insured Plan, Statutory' },
    'Self-Insured Plan': { order: 2, label: '2. Self-Insured Plan' },
    'Collective Bargaining Plan': { order: 3, label: '3. Collective Bargaining Plan' },
    'Insured Plan, Better Than Statutory': {
      order: 4,
      label: '4. Insured Plan, Better than Statutory',
    },
  };

  const formattedReport = report.map((plan) => ({
    ...plan,
    [TDI21sAnnualReportSummaryPlanTypeKey.TypeOfPlanFormatted]:
      typeOfPlanOrderAndMapping[plan.typeOfPlan].label,
  }));

  if (!order) {
    formattedReport.sort(
      (a, b) =>
        typeOfPlanOrderAndMapping[a.typeOfPlan].order -
        typeOfPlanOrderAndMapping[b.typeOfPlan].order
    );
  }

  return {
    ...data,
    data: {
      ...data?.data,
      report: formattedReport,
    },
  };
};
