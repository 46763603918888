import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { authResponseWrapper } from 'src/queries/helpers';
import { ErrorService } from 'src/services';

export function useDeleteRelatedNotes(options?: UseMutationOptions<string, Error, string>) {
  const { mutate: deleteRelatedNotes, isLoading } = useMutation<string, Error, string>({
    mutationFn: (payload: string) =>
      authResponseWrapper(apiClient.deleteHearingRelatedNote, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    deleteRelatedNotes,
    isLoading,
  };
}
