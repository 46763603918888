import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { TDI15 } from './types';

export function useUpdateTDI15(options?: UseMutationOptions<TDI15, Error, TDI15>) {
  const {
    mutate: onUpdateTDI15,
    isLoading: isUpdating,
    isSuccess,
    isError,
    error,
  } = useMutation<TDI15, Error, TDI15>({
    mutationFn: (payload: TDI15) => responseWrapper(apiClient.updateTDI15, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    onUpdateTDI15,
    isUpdating,
    isSuccess,
    isError,
    error,
  };
}
