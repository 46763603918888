import { PaginationResponseType } from '../helpers';
import { User, UserDetail } from './types';

export const toUserData = (data: PaginationResponseType<any>): PaginationResponseType<User> => ({
  ...data,
  data: data.data.map((user) => ({
    ...user,
    profiles: user.permissionGroup?.resourceName,
    ...user.profile,
    delegatedApprover: user?.delegatedApprover
      ? `${user.delegatedApprover?.firstName} ${user.delegatedApprover?.lastName}`
      : '',
    manager: user?.manager ? `${user.manager?.firstName} ${user.manager?.lastName}` : '',
    individual: user?.individual
      ? `${user.individual?.firstName} ${user.individual?.lastName}`
      : '',
    id: user?.id,
    profileId: user.profile?.id,
  })),
});

export const toUserDetail = (data: UserDetail): UserDetail => ({
  ...data,
  delegatedApprover: {
    ...data.delegatedApprover,
    fullName: data.delegatedApprover
      ? `${data.delegatedApprover?.firstName} ${data.delegatedApprover?.lastName}`
      : '',
  },
  manager: {
    ...data.manager,
    fullName: data.manager ? `${data.manager?.firstName} ${data.manager?.lastName}` : '',
  },
  individual: {
    ...data.individual,
    fullName: data.individual ? `${data.individual?.firstName} ${data.individual?.lastName}` : '',
  },
});
