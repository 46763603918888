export enum HC4Key {
  Id = 'id',
  CreatedUser = 'createdUser',
  UpdatedUser = 'updatedUser',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  CreatedBy = 'createdBy',
  UpdatedBy = 'updatedBy',
  RecordNum = 'recordNum',
  HC4Number = 'hc4Number',
  // Raw keys
  RawEmployerName = 'rawEmployerName',
  RawEmployerDOLNumber = 'rawEmployerDOLNumber',
  RawEmployerDBAName = 'rawEmployerDBAName',
  RawEmployerNatureOfBusiness = 'rawEmployerNatureOfBusiness',
  RawEmployerAddress = 'rawEmployerAddress',
  RawEmployerCity = 'rawEmployerCity',
  RawEmployerState = 'rawEmployerState',
  RawEmployerZipcode = 'rawEmployerZipcode',
  RawEmployerPlaceOfBusiness = 'rawEmployerPlaceOfBusiness',
  RawEmployerBusinessCity = 'rawEmployerBusinessCity',
  RawEmployerBusinessState = 'rawEmployerBusinessState',
  RawEmployerBusinessZipcode = 'rawEmployerBusinessZipcode',

  // Service Type
  ServiceTypeHCContractorId = 'serviceTypeHCContractorId',
  ServiceTypeHCContractorName = 'serviceTypeHCContractorName',
  ServiceTypePlanName = 'serviceTypePlanName',
  ServiceTypeGroupNumber = 'serviceTypeGroupNumber',
  ServiceTypeEffectiveDate = 'serviceTypeEffectiveDate',
  ServiceTypeNameHCIsRegisteredUnder = 'serviceTypeNameHCIsRegisteredUnder',
  ServiceTypeClassOfEECoveredByPlan = 'serviceTypeClassOfEECoveredByPlan',
  ServiceTypeNoOfEECovered = 'serviceTypeNoOfEECovered',

  // Reimburse Type
  ReimburseTypeHCContractorId = 'reimburseTypeHCContractorId',
  ReimburseTypeHCContractorName = 'reimburseTypeHCContractorName',
  ReimburseTypePlanName = 'reimburseTypePlanName',
  ReimburseTypeGroupNumber = 'reimburseTypeGroupNumber',
  ReimburseTypeEffectiveDate = 'reimburseTypeEffectiveDate',
  ReimburseTypeNameHCIsRegisteredUnder = 'reimburseTypeNameHCIsRegisteredUnder',
  ReimburseTypeClassOfEECoveredByPlan = 'reimburseTypeClassOfEECoveredByPlan',
  ReimburseTypeNoOfEECovered = 'reimburseTypeNoOfEECovered',
  // Collective Bargaining
  CollectiveBargainingTypeHCContractorId = 'collectiveBargainingTypeHCContractorId',
  CollectiveBargainingTypeHCContractorName = 'collectiveBargainingTypeHCContractorName',
  CollectiveBargainingTypeNameOfUnion = 'collectiveBargainingTypeNameOfUnion',
  CollectiveBargainingTypePlanName = 'collectiveBargainingTypePlanName',
  CollectiveBargainingTypeNoOfEECovered = 'collectiveBargainingTypeNoOfEECovered',
  // Self Insure
  SelfInsureTypeHCAdmin = 'selfInsureTypeHCAdmin',
  SelfInsureTypePlanName = 'selfInsureTypePlanName',
  SelfInsureTypeGroupNumber = 'selfInsureTypeGroupNumber',
  SelfInsureTypeEffectiveDate = 'selfInsureTypeEffectiveDate',
  SelfInsureTypeClassOfEECoveredByPlan = 'selfInsureTypeClassOfEECoveredByPlan',
  SelfInsureTypeNoOfEECovered = 'selfInsureTypeNoOfEECovered',
  // NoOfEE
  NoOfEEWorkLessThan20hAWeek = 'noOfEEWorkLessThan20hAWeek',
  NoOfEECoveredAsDepUnderQlfHCPlan = 'noOfEECoveredAsDepUnderQlfHCPlan',
  NoOfEECoveredByPrimaryEmployer = 'noOfEECoveredByPrimaryEmployer',
  NoOfEECoveredByStateOrFedHCPlan = 'noOfEECoveredByStateOrFedHCPlan',
  NoOfEECoveredByStGovernedMedAsst = 'noOfEECoveredByStGovernedMedAsst',
  NoOfEEOtherCoverageObtained = 'noOfEEOtherCoverageObtained',
  OtherCoverageObtainedFrom = 'otherCoverageObtainedFrom',
  NoOfEEOther = 'noOfEEOther',
  // Signature
  SignatureName = 'signatureName',
  SignatureTitle = 'signatureTitle',
  SignaturePhone = 'signaturePhone',
  SignatureDate = 'signatureDate',
  SignatureEmail = 'signatureEmail',
  SignatureFax = 'signatureFax',
  // Total Monthly Premiums count
  Type = 'type',
  EmployerContribution = 'employerContribution',
  EmployeeContribution = 'employeeContribution',
  TotalMonthlyCost = 'totalMonthlyCost',
  Other = 'other',
  HRSSection = 'hrsSection',
  AdditionInformation = 'additionInformation',
  ReasonCoverageIsNotRequired = 'reasonCoverageIsNotRequired',
  EmployerId = 'employerId',
  Employer = 'employer',
  ServiceTypeHCContractor = 'serviceTypeHCContractor',
  ReimburseTypeHCContractor = 'reimburseTypeHCContractor',
  CollectiveBargainingTypeHCContractor = 'collectiveBargainingTypeHCContractor',
  TotalMonthlyPremiums = 'totalMonthlyPremiums',

  EmployerName = 'employerName',
  LaborNumber = 'laborNumber',
  DBAName = 'dbaName',

  // Filter keys
  EmployerLaborNumberFilter = 'employer.laborNumber',
  EmployerDBANameFilter = 'employer.dbaName',
}
