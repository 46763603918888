export enum RelatedTypePurposesKey {
  Id = 'id',
  HearingType = 'hearingType',
  HearingPurpose = 'hearingPurpose',
  HearingId = 'hearingId',
  HearingTypeId = 'hearingTypeId',
  CreatedUser = 'createdUser',
  UpdatedUser = 'updatedUser',
  TypeAndPurposeId = 'typeAndPurposeId',
  TypeAndPurpose = 'typeAndPurpose',
  PurposeId = 'purposeId',
  Types = 'types',
  Value = 'value',
  TypePurposeCounts = 'typePurposeCounts',
  HearingTypePurposeCounts = 'hearingTypePurposeCounts',
  AllPurposesChecked = 'allPurposesChecked',
  Items = 'items',
  Purpose = 'purpose',
  IsChecked = 'isChecked',
}

export interface RelatedTypePurposesType {
  [RelatedTypePurposesKey.Id]?: string;
  [RelatedTypePurposesKey.HearingType]?: string;
  [RelatedTypePurposesKey.Purpose]?: string;
  [RelatedTypePurposesKey.HearingPurpose]?: string;
  [RelatedTypePurposesKey.IsChecked]?: boolean;
  [RelatedTypePurposesKey.TypeAndPurposeId]?: string;
  [RelatedTypePurposesKey.TypeAndPurpose]?: {
    hearingType: {
      hearingTypeId: string;
      hearingTypeName: string;
    };
    id: string;
    hearingPurpose: {
      purpose: string;
    };
  };
  [RelatedTypePurposesKey.HearingTypeId]?: string;
  [RelatedTypePurposesKey.PurposeId]?: string;
  [RelatedTypePurposesKey.CreatedUser]?: string;
  [RelatedTypePurposesKey.UpdatedUser]?: string;
}

export interface TypeAndPurposesPayload {
  [RelatedTypePurposesKey.Items]?: {
    [RelatedTypePurposesKey.HearingId]?: string;
    [RelatedTypePurposesKey.TypeAndPurposeId]?: string;
  }[];
}

export interface TypePayload {
  [RelatedTypePurposesKey.Types]?: {
    [RelatedTypePurposesKey.Value]?: string;
    [RelatedTypePurposesKey.TypePurposeCounts]?: string;
    [RelatedTypePurposesKey.HearingTypePurposeCounts]?: string;
    [RelatedTypePurposesKey.AllPurposesChecked]?: string;
  }[];
}

export interface TypeAndPurpose {
  typeAndPurpose: RelatedTypePurposesType[];
}

export interface RelatedTypeAndPurpose {
  data: TypeAndPurpose;
}
