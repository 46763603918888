import { HCPKey } from './keys';

export interface HealthCarePlan {
  [HCPKey.Id]?: string;
  [HCPKey.RecordNum]?: number;
  [HCPKey.PlanNumber]?: string;
  [HCPKey.PlanType]?: HealthCarePlanType;
  [HCPKey.PlanIdNumber]?: string;
  [HCPKey.PlanName]?: string;
  [HCPKey.Status]?: string;
  [HCPKey.StatusEffectiveDate]?: string;
  [HCPKey.HrsSection]?: string;
  [HCPKey.BenefitLevel]?: string;
  [HCPKey.FinancialStatementYearEnding]?: string;
  [HCPKey.LastAuditDate]?: string;

  [HCPKey.HCContractor]?: string;
  [HCPKey.HCContractorName]?: string;
  [HCPKey.HCContractorNumber]?: string;
  [HCPKey.HCContractorStatusDate]?: string;
  [HCPKey.HCContractorStatus]?: string;
  [HCPKey.HCContractorId]?: string;

  //Employer
  [HCPKey.EmployerName]?: string;
  [HCPKey.EmployerId]?: string;
  [HCPKey.EmployerDOL]?: string;
  [HCPKey.EmployerDCDStatus]?: string;
  [HCPKey.EmployerDCDStatusDate]?: string;

  // ============= Audit =============
  [HCPKey.CreatedAt]?: string;
  [HCPKey.UpdatedAt]?: string;
  [HCPKey.CreatedBy]?: string;
  [HCPKey.UpdatedBy]?: string;
  [HCPKey.CreatedByUser]?: string;
  [HCPKey.UpdatedUser]?: string;
}

export enum HealthCarePlanType {
  EmployerSponsored = 'EMPLOYER_SPONSORED',
  InsuredPlan = 'INSURED_PLAN',
  SelfInsured = 'SELF_INSURED',
}

export enum HealthCarePlanStatus {
  Approved = 'APPROVED',
  Canceled = 'CANCELED',
  Disapproved = 'DISAPPROVED',
  Exemption = 'EXEMPTION',
  Withdrawn = 'WITHDRAWN ',
}

export enum HRSSection {
  A7 = '7(a)',
  B7 = '7(b)',
}

export enum BenefitLevel {
  HMO = 'Health Maintenance Organization (HMO)',
  PPO = 'Preferred Provider Organization (PPO)',
  Comprehensive = 'Comprehensive',
  Basic = 'Basic',
  MajorMedical = 'Major Medical',
  Other = 'Other',
}
