import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { createResponseWrapper } from 'src/queries/helpers';
import { ErrorService } from 'src/services';
import { WCInsurancePoliciesInterface } from './types';

export function useCreateWCIP(
  options?: UseMutationOptions<any, Error, WCInsurancePoliciesInterface>
) {
  const { mutate: createWCIP, isLoading } = useMutation<any, Error, WCInsurancePoliciesInterface>({
    mutationFn: (payload: WCInsurancePoliciesInterface) =>
      createResponseWrapper(apiClient.createWCIP, [payload]),
    onError: (error) => ErrorService.handler(error),
    ...options,
  });

  return {
    createWCIP,
    isLoading,
  };
}
