import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { PaginationResponseType, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { GetPropertiesParams } from 'src/queries/types';
import { TDI15, toTDI15s } from '../TDI15s';
import { TDI15sTDISelfInsurerPlanReportKey } from './keys';

export function useGetTDI15SelfInsurerDetail(
  options?: UseQueryOptions<PaginationResponseType<TDI15>, Error> & {
    [TDI15sTDISelfInsurerPlanReportKey.StartDate]: string;
    [TDI15sTDISelfInsurerPlanReportKey.EndDate]: string;
    [TDI15sTDISelfInsurerPlanReportKey.ActionTaken]: string;
  }
) {
  const [params, setParams] = useState<GetPropertiesParams>({});

  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetTDI15SelfInsurerDetail,
  } = useQuery<PaginationResponseType<TDI15>, Error>(
    [API_QUERIES.REPORT_TDI_15S_TDI_SELF_INSURER_PLAN, { ...params, ...options }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<PaginationResponseType<TDI15>>(
          apiClient.getTDI15SelfInsurerDetail,
          params
        );
      },
      notifyOnChangeProps: ['data', 'isFetching'],
      keepPreviousData: true,
      enabled: !!options?.startDate && !!options?.endDate && !!options?.actionTaken,
      select: (data) => toTDI15s(data),
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateTDI15SelfInsurerDetail = () =>
    queryClient.invalidateQueries(API_QUERIES.REPORT_TDI_15S_TDI_SELF_INSURER_PLAN);

  const { data: tdi15SelfInsurerDetail, hasNext, payloadSize, totalRecords } = data || {};

  return {
    tdi15SelfInsurerDetail,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isError,
    isFetching,
    onGetTDI15SelfInsurerDetail,
    setParams,
    handleInvalidateTDI15SelfInsurerDetail,
  };
}
