import { toRelatedAuditData } from '../helpers';

export const toAllDependents = (data) => ({
  ...data,
  data: data.data.map((item) => ({
    ...item,
    ...toRelatedAuditData(item),
  })),
});

export const toDependent = (data) => ({
  ...data,
});
