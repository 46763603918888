import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { ApiResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { toTDI21Detail } from './helpers';
import { TDI21 } from './types';

export function useGetTDI21(
  options?: UseQueryOptions<ApiResponseType<{ tdi21: TDI21 }>, Error, TDI21> & {
    id: TDI21['id'];
  }
) {
  const {
    data: tdi21Detail,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetTDI21ById,
  } = useQuery<ApiResponseType<{ tdi21: TDI21 }>, Error, TDI21>(
    [API_QUERIES.TDI21s, { id: options.id }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<{ tdi21: TDI21 }>>(apiClient.getTDI21Detail, params);
      },
      select: (data) => toTDI21Detail(data?.data?.tdi21),
      enabled: !!options.id,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateTDI21 = () =>
    queryClient.invalidateQueries([API_QUERIES.TDI21s, { id: options.id }]);

  return {
    tdi21Detail,
    error,
    isError,
    isLoading,
    onGetTDI21ById,
    handleInvalidateTDI21,
  };
}
