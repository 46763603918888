import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { createResponseWrapper } from '../helpers';

export function useCreateTDISFByTDI30(
  options?: UseMutationOptions<any, Error, { tdi30Id: string }>
) {
  const { mutate: createTDISFCaseByTDI30, isLoading } = useMutation<
    any,
    Error,
    { tdi30Id: string }
  >({
    mutationFn: (payload: { tdi30Id: string }) =>
      createResponseWrapper(apiClient.createTDISFCaseByTDI30, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    createTDISFCaseByTDI30,
    isLoading,
  };
}
