import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { CaseProcessingLogPayload } from './types';

export function useEditCaseProcessingLog(
  options?: UseMutationOptions<CaseProcessingLogPayload, Error, CaseProcessingLogPayload>
) {
  const {
    mutate: editCaseProcessingLog,
    isLoading: isUpdating,
    isSuccess,
    isError,
    error,
  } = useMutation<CaseProcessingLogPayload, Error, CaseProcessingLogPayload>({
    mutationFn: (payload: CaseProcessingLogPayload) =>
      responseWrapper(apiClient.editCaseProcessingLog, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    editCaseProcessingLog,
    isUpdating,
    isSuccess,
    isError,
    error,
  };
}
