import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { PaginationResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { GetPropertiesParams } from '../types';
import { toRelatedPartyArchives } from './helpers';
import { PartyArchive } from './types';

export function useGetAllDecisionRelatedPartyArchives(
  options?: UseQueryOptions<PaginationResponseType<PartyArchive>, Error> & {
    id: string;
  }
) {
  const [params, setParams] = useState<GetPropertiesParams>({});
  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetAllPartyArchives,
  } = useQuery<PaginationResponseType<PartyArchive>>(
    [API_QUERIES.RELATED_PARTY_HISTORY_ARCHIVES, { ...params, decisionId: options.id }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<PaginationResponseType<PartyArchive>>(
          apiClient.getAllDecisionRelatedPartyArchives,
          params
        );
      },
      onError: ErrorService.handler,
      notifyOnChangeProps: ['data', 'isFetching'],
      keepPreviousData: true,
      enabled: !!options.id,
      select: toRelatedPartyArchives,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateAllDecisionRelatedPartyArchives = () =>
    queryClient.invalidateQueries(API_QUERIES.RELATED_PARTY_HISTORY_ARCHIVES);

  const { data: partyHistoryArchives, hasNext, payloadSize, totalRecords } = data || {};

  return {
    partyHistoryArchives,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isError,
    isFetching,
    onGetAllPartyArchives,
    setParams,
    handleInvalidateAllDecisionRelatedPartyArchives,
  };
}
