import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { Disbursement } from './types';

export function usePostDisbursement(
  options?: UseMutationOptions<Disbursement, Error, Disbursement>
) {
  const {
    mutate: postDisbursement,
    isLoading: isUpdating,
    isSuccess,
    isError,
    error,
  } = useMutation<Disbursement, Error, Disbursement>({
    mutationFn: (payload: Disbursement) => responseWrapper(apiClient.postDisbursement, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    postDisbursement,
    isUpdating,
    isSuccess,
    isError,
    error,
  };
}
