import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { ApiResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { toTDICaseDetail } from './helpers';
import { TDICase } from './types';

export function useGetTDICase(
  options?: UseQueryOptions<ApiResponseType<{ tdiCase: TDICase }>, Error, TDICase> & {
    id: TDICase['id'];
  }
) {
  const {
    data: tdiCase,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetTDICaseById,
  } = useQuery<ApiResponseType<{ tdiCase: TDICase }>, Error, TDICase>(
    [API_QUERIES.TDICases, { id: options.id }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<{ tdiCase: TDICase }>>(apiClient.getTDICase, params);
      },
      select: ({ data }) => toTDICaseDetail(data?.tdiCase),
      enabled: !!options.id,
      onError: ErrorService.handler,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateTDICase = () =>
    queryClient.invalidateQueries([API_QUERIES.TDICases, { id: options.id }]);

  return {
    tdiCase,
    error,
    isError,
    isLoading,
    onGetTDICaseById,
    handleInvalidateTDICase,
  };
}
