import { PaginationResponseType, toAuditData } from '../helpers';
import { WC77Key } from './keys';
import { WC77 } from './types';

export const toWC77s = (data): PaginationResponseType<WC77> => ({
  ...data,
  data: data.data.map((item) => ({
    ...item,
    [WC77Key.ClaimantName]: item?.claimant?.accountName,
    [WC77Key.EmployerName]: item?.employer?.accountName,
    [WC77Key.InsuranceCarrierName]: item?.insuranceCarrier?.accountName,
    [WC77Key.DCDCaseNumber]: item?.wcCase?.caseNumber,
    [WC77Key.HearingNumber]: item?.hearing?.hearingNumber,

    [WC77Key.DCDCaseNumberFilter]: item?.wcCase?.caseNumber,
    [WC77Key.PortalFormNumber]: item?.wcForm?.wcFormNumber,
    ...toAuditData(item),
  })),
});

export const getWC77 = (data) => ({
  ...data,
  [WC77Key.DCDCaseNumber]: data?.wcCase?.caseNumber,
  [WC77Key.ClaimantName]: data?.claimant?.accountName,
  [WC77Key.EmployerName]: data?.employer?.accountName,
  [WC77Key.HearingNumber]: data?.hearing?.hearingNumber,
  [WC77Key.InsuranceCarrierName]: data?.insuranceCarrier?.accountName,
  ...toAuditData(data),
});
