export enum TDI21sAnnualReportSummaryPlanTypeKey {
  InsuredPlanStatutory = 'insuredPlanStatutory',
  SelfInsuredPlan = 'selfInsuredPlan',
  CollectiveBargainingPlan = 'collectiveBargainingPlan',
  InsuredPlanBetterThanStatutory = 'insuredPlanBetterThanStatutory',
  TypeOfPlan = 'typeOfPlan',
  TypeOfPlanFormatted = 'typeOfPlanFormatted',
  TotalNumberOfReportsFiled = 'totalNumberOfReportsFiled',
  MaleAverageNumberCoveredEmployees = 'maleAverageNumberCoveredEmployees',
  MaleTotalBenefitsPaid = 'maleTotalBenefitsPaid',
  MaleNumberOfPersonsPaidBenefits = 'maleNumberOfPersonsPaidBenefits',
  MaleNumberOfBenefitWeeksPaid = 'maleNumberOfBenefitWeeksPaid',
  MaleNumberOfDisabilitySpells = 'maleNumberOfDisabilitySpells',
  MaleNumberOfClaimsDenied = 'maleNumberOfClaimsDenied',
  FemaleAverageNumberCoveredEmployees = 'femaleAverageNumberCoveredEmployees',
  FemaleTotalBenefitsPaid = 'femaleTotalBenefitsPaid',
  FemaleNumberOfPersonsPaidBenefits = 'femaleNumberOfPersonsPaidBenefits',
  FemaleNumberOfBenefitWeeksPaid = 'femaleNumberOfBenefitWeeksPaid',
  FemaleNumberOfDisabilitySpells = 'femaleNumberOfDisabilitySpells',
  FemaleNumberOfClaimsDenied = 'femaleNumberOfClaimsDenied',
  AverageNumberCoveredEmployees = 'averageNumberCoveredEmployees',
  TotalWagePaidCoveredEmployees = 'totalWagePaidCoveredEmployees',
  TotalTaxableWagePaid = 'totalTaxableWagePaid',
  TotalEmployerContributions = 'totalEmployerContributions',
  TotalEmployeeContributions = 'totalEmployeeContributions',
  TotalBenefitsPaid = 'totalBenefitsPaid',
  NumberOfPersonsPaidBenefits = 'numberOfPersonsPaidBenefits',
  NumberOfBenefitsWeekPaid = 'numberOfBenefitsWeekPaid',
  NumberOfDisabilitySpells = 'numberOfDisabilitySpells',
  NumberOfClaimsDenied = 'numberOfClaimsDenied',
  OverallEmployerCostBenefitRatio = 'overallEmployerCostBenefitRatio',
  OverallEmployeeCostBenefitRatio = 'overallEmployeeCostBenefitRatio',
  TotalSubrogationPayments = 'totalSubrogationPayments',
  AverageTotalBenefitsPaidEachPerson = 'averageTotalBenefitsPaidEachPerson',
  MaleAverageTotalBenefitsPaidEachPerson = 'maleAverageTotalBenefitsPaidEachPerson',
  FemaleAverageTotalBenefitsPaidEachPerson = 'femaleAverageTotalBenefitsPaidEachPerson',
  AverageWeeklyBenefitPaidEachPerson = 'averageWeeklyBenefitPaidEachPerson',
  MaleAverageWeeklyBenefitPaidEachPerson = 'maleAverageWeeklyBenefitPaidEachPerson',
  FemaleAverageWeeklyBenefitPaidEachPerson = 'femaleAverageWeeklyBenefitPaidEachPerson',
  AverageDurationWeeks = 'averageDurationWeeks',
  MaleAverageDurationWeeks = 'maleAverageDurationWeeks',
  FemaleAverageDurationWeeks = 'femaleAverageDurationWeeks',

  CalendarYear = 'calendarYear',
}
