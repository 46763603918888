import React from 'react';
import { QueryFunction, useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { ApiResponseType, getResponseData, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { getRoleName } from './helpers';
import { MyProfile } from './types';

export function useProfile(
  options?: UseQueryOptions<ApiResponseType<MyProfile>, Error, MyProfile>
) {
  const handleGetProfile: QueryFunction<ApiResponseType<MyProfile>, API_QUERIES> = () =>
    responseWrapper<ApiResponseType<MyProfile>>(apiClient.getMyProfile);
  const {
    data,
    error,
    isError,
    isFetching,
    refetch: getMyProfile,
  } = useQuery<ApiResponseType<MyProfile>, Error, MyProfile>([API_QUERIES.PROFILE], {
    queryFn: handleGetProfile,
    refetchOnMount: false,
    select: getResponseData,
    notifyOnChangeProps: ['data', 'isFetching'],
    ...options,
  });

  const queryClient = useQueryClient();

  const handleSetStaleProfile = () => queryClient.invalidateQueries([API_QUERIES.PROFILE]);

  return {
    profile: React.useMemo(() => {
      if (!data) return null;
      const profileResponse: MyProfile = {
        ...data,
        delegatedApprover: {
          ...data.delegatedApprover,
          fullName: data.delegatedApprover
            ? `${data.delegatedApprover?.firstName} ${data.delegatedApprover?.lastName}`
            : '',
        },
        manager: {
          ...data.manager,
          fullName: data.manager ? `${data.manager?.firstName} ${data.manager?.lastName}` : '',
        },
        individual: {
          ...data.individual,
          fullName: data.individual
            ? `${data.individual?.firstName} ${data.individual?.lastName}`
            : '',
        },
        roleName: getRoleName(data.userType),
      };
      return profileResponse;
    }, [data]),
    error,
    isError,
    loading: isFetching,
    getMyProfile,
    handleSetStaleProfile,
  };
}
