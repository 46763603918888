import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { PaginationResponseType, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { GetPropertiesParams } from 'src/queries/types';
import { toHC15s } from '../HC15s/helpers';
import { HC15 } from '../HC15s/types';
import { HC15sReportKey } from './keys';

export function useGetHC15sReportDetail(
  options?: UseQueryOptions<PaginationResponseType<HC15>, Error> & {
    [HC15sReportKey.CertifiedAction]: string;
    [HC15sReportKey.StartDate]: string;
    [HC15sReportKey.EndDate]: string;
  }
) {
  const [params, setParams] = useState<GetPropertiesParams>({});

  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetHC15sReportDetail,
  } = useQuery<PaginationResponseType<HC15>, Error>(
    [API_QUERIES.REPORT_HC_15S, { ...params, ...options }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<PaginationResponseType<HC15>>(
          apiClient.getHC15sReportDetail,
          params
        );
      },
      notifyOnChangeProps: ['data', 'isFetching'],
      keepPreviousData: true,
      enabled: !!options?.startDate && !!options?.endDate && !!options?.certifiedAction,
      select: (data) => toHC15s(data),
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateHC15sReportDetail = () =>
    queryClient.invalidateQueries(API_QUERIES.REPORT_HC_15S);

  const { data: hc15sReportDetail, hasNext, payloadSize, totalRecords } = data || {};

  return {
    hc15sReportDetail,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isError,
    isFetching,
    onGetHC15sReportDetail,
    setParams,
    handleInvalidateHC15sReportDetail,
  };
}
