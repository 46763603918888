import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { ApiResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { toTDI15Detail } from './helpers';
import { TDI15 } from './types';

export function useGetTDI15(
  options?: UseQueryOptions<ApiResponseType<{ tdi15: TDI15 }>, Error, TDI15> & {
    id: TDI15['id'];
  }
) {
  const {
    data: tdi15Detail,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetTDI15ById,
  } = useQuery<ApiResponseType<{ tdi15: TDI15 }>, Error, TDI15>(
    [API_QUERIES.TDI15s, { id: options.id }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<{ tdi15: TDI15 }>>(apiClient.getTDI15Detail, params);
      },
      select: ({ data }) => toTDI15Detail(data.tdi15),
      enabled: !!options.id,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateTDI15 = () =>
    queryClient.invalidateQueries([API_QUERIES.TDI15s, { id: options.id }]);

  return {
    tdi15Detail,
    error,
    isError,
    isLoading,
    onGetTDI15ById,
    handleInvalidateTDI15,
  };
}
