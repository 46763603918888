import { useMutation, UseMutationOptions } from 'react-query';
import { Toastify } from 'src/services';
import apiClient from '../apiClient';
import { createResponseWrapper } from '../helpers';
import { UpLoadFormValue } from './helpers';

export function useUploadFileDCDCase(
  options?: UseMutationOptions<UpLoadFormValue, Error, UpLoadFormValue>
) {
  const {
    mutate: uploadFileDCDCase,
    isLoading,
    isSuccess,
  } = useMutation<UpLoadFormValue, Error, UpLoadFormValue>({
    mutationFn: (payload: UpLoadFormValue) =>
      createResponseWrapper(apiClient.submitUploadFile, [payload]),
    onError: ({ message }) => Toastify.error(message),
    ...options,
  });

  return {
    uploadFileDCDCase,
    isLoading,
    isSuccess,
  };
}
