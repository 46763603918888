import { useState } from 'react';
import { UseQueryOptions, useQuery, useQueryClient } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { PaginationResponseType, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { GetPropertiesParams } from 'src/queries/types';
import { VRProvider } from './types';

export function useGetAllRelatedVRProviders(
  options?: UseQueryOptions<PaginationResponseType<VRProvider>, Error> & {
    vrProcessId?: string;
  }
) {
  const [params, setParams] = useState<GetPropertiesParams>({});

  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetAllVRProviders,
  } = useQuery<PaginationResponseType<VRProvider>, Error>(
    [API_QUERIES.VR_PROVIDER, { ...params, vrProcessId: options.vrProcessId }],
    {
      queryFn: ({ queryKey }) => {
        const params = queryKey.at(-1);

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return responseWrapper<PaginationResponseType<VRProvider>>(apiClient.getVRProviders, [
          params,
        ]);
      },
      notifyOnChangeProps: ['data', 'isFetching'],
      keepPreviousData: true,
      enabled: !!options?.vrProcessId,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateVRProvider = () => queryClient.invalidateQueries(API_QUERIES.VR_PROVIDER);

  const { data: vrProviders, hasNext, payloadSize, totalRecords } = data || {};

  return {
    vrProviders,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isError,
    isFetching,
    onGetAllVRProviders,
    setParams,
    handleInvalidateVRProvider,
  };
}
