import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { ApiResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';

export function useGetDisfigurementBodyParts(
  options?: UseQueryOptions<ApiResponseType<{ disfigurementBodyParts: string }>, Error, string> & {
    id: string;
  }
) {
  const {
    data: disfigurementBodyParts,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetDisfigurementBodyParts,
  } = useQuery<ApiResponseType<{ disfigurementBodyParts: string }>, Error, string>(
    [API_QUERIES.DISFIGUREMENT_BODY_PARTS, { id: options.id }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<{ disfigurementBodyParts: string }>>(
          apiClient.getDisfigurementBodyParts,
          params
        );
      },
      enabled: !!options.id,
      select: ({ data }) => data?.disfigurementBodyParts,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateDisfigurementBodyParts = () =>
    queryClient.invalidateQueries([API_QUERIES.DISFIGUREMENT_BODY_PARTS, { id: options.id }]);

  return {
    disfigurementBodyParts,
    error,
    isError,
    isLoading,
    onGetDisfigurementBodyParts,
    handleInvalidateDisfigurementBodyParts,
  };
}
