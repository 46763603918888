import { Tooltip } from '@mui/material';
import cn from 'classnames';
import React from 'react';
import { IoInformationCircle } from 'react-icons/io5';
import { COLOR_CODE } from 'src/appConfig/constants';

import './styles.scss';

const Text: React.FC<Props> = ({
  children,
  className,
  variant,
  size = 16,
  style = {},
  infoTooltipMessage,
  infoTooltipArrow = true,
  infoTooltipPlacement = 'right',
  forwardRef,
  ...props
}) => {
  const textStyle: React.CSSProperties = { ...style, fontSize: size };

  const getTitle = () => {
    if (typeof children === 'string') return children;
    return null;
  };

  return (
    <p
      style={textStyle}
      title={getTitle()}
      className={cn('cmp-text', className, {
        [`cmp-text__${variant}`]: !!variant,
      })}
      ref={forwardRef}
      {...props}
    >
      {children}
      {infoTooltipMessage && (
        <span>
          <Tooltip
            arrow={true}
            title={<span style={{ whiteSpace: 'pre-line' }}>{infoTooltipMessage}</span>}
            placement={'right'}
          >
            <i className="cursor-pointer ml-1">
              <IoInformationCircle
                size={16}
                color={COLOR_CODE.INFO}
                style={{
                  transform: 'translateY(2px)',
                }}
              />
            </i>
          </Tooltip>
        </span>
      )}
    </p>
  );
};

export type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLParagraphElement>,
  HTMLParagraphElement
> & {
  variant?: 'title' | 'titleUnderline';
  size?: number;
  children?: React.ReactNode;
  infoTooltipMessage?: string;
  infoTooltipArrow?: boolean;
  infoTooltipPlacement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
  forwardRef?: React.LegacyRef<HTMLDivElement>;
};

export default Text;
