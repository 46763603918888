import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { Report } from './types';

export function useEditReport(options?: UseMutationOptions<Report, Error, Report>) {
  const {
    mutate: onEditReport,
    isLoading: isUpdating,
    isSuccess,
    isError,
    error,
  } = useMutation<Report, Error, Report>({
    mutationFn: (payload: Report) => responseWrapper(apiClient.editReport, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    onEditReport,
    isUpdating,
    isSuccess,
    isError,
    error,
  };
}
