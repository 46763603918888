import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { ApiResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { toTDI30Detail } from './helpers';
import { TDI30 } from './types';

export function useGetTDI30(
  options?: UseQueryOptions<ApiResponseType<{ tdi30: TDI30 }>, Error, TDI30> & {
    id: string;
  }
) {
  const {
    data: tdi30,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetTDI30ById,
  } = useQuery<ApiResponseType<{ tdi30: TDI30 }>, Error, TDI30>(
    [API_QUERIES.TDI30s, { id: options.id }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<{ tdi30: TDI30 }>>(apiClient.getTDI30, params);
      },
      select: ({ data }) => toTDI30Detail(data.tdi30),
      enabled: !!options.id,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateTDI30 = () =>
    queryClient.invalidateQueries([API_QUERIES.TDI30s, { id: options.id }]);

  return {
    tdi30,
    error,
    isError,
    isLoading,
    onGetTDI30ById,
    handleInvalidateTDI30,
  };
}
