import { useState } from 'react';
import { UseQueryOptions, useQuery, useQueryClient } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { PaginationResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { GetPropertiesParams } from '../types';
import { toDisbursements } from './helpers';
import { Disbursement } from './types';

export function useGetAllDisbursements(
  options?: UseQueryOptions<PaginationResponseType<Disbursement>, Error> & {
    [key: string]: string | number | string[] | boolean;
  }
) {
  const [params, setParams] = useState<GetPropertiesParams>({});
  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetAllDisbursements,
  } = useQuery<PaginationResponseType<Disbursement>>(
    [API_QUERIES.Disbursements, { ...params, ...options }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<PaginationResponseType<Disbursement>>(
          apiClient.getAllDisbursements,
          params
        );
      },
      onError: ErrorService.handler,
      notifyOnChangeProps: ['data', 'isFetching'],
      keepPreviousData: true,
      select: toDisbursements,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateDisbursements = () =>
    queryClient.invalidateQueries(API_QUERIES.Disbursements);

  const { data: disbursements, hasNext, payloadSize, totalRecords } = data || {};

  return {
    disbursements,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isError,
    isFetching,
    onGetAllDisbursements,
    setParams,
    handleInvalidateDisbursements,
  };
}
