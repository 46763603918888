import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { PaginationResponseType, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { GetPropertiesParams } from 'src/queries/types';
import { isEmpty } from 'src/validations';
import { WC3Form } from './types';

export function useGetAllWC3Forms(
  options?: UseQueryOptions<PaginationResponseType<WC3Form>, Error>
) {
  const [params, setParams] = useState<GetPropertiesParams>({});

  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetAllWC3Forms,
  } = useQuery<PaginationResponseType<WC3Form>, Error>([API_QUERIES.WC3s, params], {
    queryFn: (query) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...params] = query.queryKey;
      return responseWrapper<PaginationResponseType<WC3Form>>(apiClient.getAllWC3, params);
    },
    notifyOnChangeProps: ['data', 'isFetching'],
    keepPreviousData: true,
    enabled: !isEmpty(params),
    ...options,
  });

  const queryClient = useQueryClient();

  const handleInvalidateAllWC3Form = () => queryClient.invalidateQueries(API_QUERIES.WC3s);

  const { data: WC3Forms, hasNext, payloadSize, totalRecords } = data || {};

  return {
    WC3Forms,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isError,
    isFetching,
    onGetAllWC3Forms,
    setParams,
    handleInvalidateAllWC3Form,
  };
}
