export enum Role {
  NoneSpecified = 'None Specified',
  AppealsBoard = 'Appeals Board',
  CSUOAIII = 'CSU OAIII',
  CSUOAIV = 'CSU OAIV',
  CSUSupervisor = 'CSU Supervisor',
  DCDGeneral = 'DCD General',
  DCDHighVolume = 'DCD High-Volume',
  DCDSystemAdmin = 'DCD System Admin',
  Excutive = 'Excutive',
  FacilitatorGeneral = 'Facilitator General',
  FacilitatorSupervisor = 'Facilitator Supervisor',
  FICOHPartnerUser = 'FICOH Partner User',
  HearingsOfficer = 'Hearings Officer',
  HearingsReviewers = 'Hearings Reviewers',
  HearingsScheduler = 'Hearings Scheduler',
  HearingsSupervisor = 'Hearings Supervisor',
  HEMICPartnerUser = 'HEMIC Partner User',
  LIRAB = 'LIRAB',
  RCGeneral = 'R&C General',
  RCOAIII = 'R&C OAIII',
  RCOAIV = 'R&C OAIV',
  RCSupervisor = 'R&C Supervisor',
  TestPartnerAccountPartnerUser = 'Test Partner Account Partner User',
  VocationalRehab = 'Vocational Rehab',
  Fiscal = 'Fiscal',
}
