import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { DecisionPayLoad } from './types';

export function useEditDecision(
  options?: UseMutationOptions<DecisionPayLoad, Error, DecisionPayLoad>
) {
  const {
    mutate: onEditDecision,
    isLoading: isUpdating,
    isError,
    error,
  } = useMutation<DecisionPayLoad, Error, DecisionPayLoad>({
    mutationFn: (payload: DecisionPayLoad) => responseWrapper(apiClient.updateDecision, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    onEditDecision,
    isUpdating,
    isError,
    error,
  };
}
