import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { createResponseWrapper } from '../helpers';
import { NotifyFiscalPayload } from './types';

export function useNotifyFiscalDisbursement(
  options?: UseMutationOptions<NotifyFiscalPayload, Error, NotifyFiscalPayload>
) {
  const { mutate: notifyFiscalDisbursement, isLoading } = useMutation<
    NotifyFiscalPayload,
    Error,
    NotifyFiscalPayload
  >({
    mutationFn: (payload: NotifyFiscalPayload) =>
      createResponseWrapper(apiClient.notifyFiscalDisbursement, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    notifyFiscalDisbursement,
    isLoading,
  };
}
