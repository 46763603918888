import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { createResponseWrapper } from '../helpers';
import { Complaint } from './types';

export function useCreateComplaint(options?: UseMutationOptions<Complaint, Error, Complaint>) {
  const { mutate: createComplaint, isLoading } = useMutation<Complaint, Error, Complaint>({
    mutationFn: (payload: Complaint) => createResponseWrapper(apiClient.createComplaint, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    createComplaint,
    isLoading,
  };
}
