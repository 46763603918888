import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { createResponseWrapper } from 'src/queries/helpers';
import { ErrorService } from 'src/services';
import { VRProvider } from './types';

export function useCreateRelatedVRProvider(
  options?: UseMutationOptions<VRProvider, Error, VRProvider>
) {
  const { mutate: createVRProvider, isLoading } = useMutation<VRProvider, Error, VRProvider>({
    mutationFn: (payload: VRProvider) =>
      createResponseWrapper(apiClient.createVRProvider, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    createVRProvider,
    isLoading,
  };
}
