import { useMutation, UseMutationOptions } from 'react-query';
import { Toastify } from 'src/services';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';

export function useMarkAllReadNotifications(options: UseMutationOptions<string, Error>) {
  const { mutate: markAllReadNotifications, isLoading } = useMutation<string, Error>({
    mutationFn: () => {
      return responseWrapper(apiClient.markAllReadNotifications);
    },
    onError: ({ message }) => Toastify.error(message),
    ...options,
  });

  return {
    markAllReadNotifications,
    isLoading,
  };
}
