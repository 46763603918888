import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { ApiResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { toWC2Detail } from './helpers';
import { WC2Detail } from './types';

export function useGetWC2(
  options?: UseQueryOptions<ApiResponseType<{ form: any }>, Error, WC2Detail> & {
    id: string;
  }
) {
  const {
    data: wc2,
    error,
    isError,
    isFetching: isLoading,
    refetch,
  } = useQuery<ApiResponseType<{ form: any }>, Error, any>([API_QUERIES.WC2s, { id: options.id }], {
    queryFn: (query) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...params] = query.queryKey;

      return responseWrapper<ApiResponseType<{ form: any }>>(apiClient.getWC2Detail, params);
    },
    enabled: !!options.id,
    select: ({ data }) => toWC2Detail(data.form),
    ...options,
  });

  const queryClient = useQueryClient();

  const handleInvalidateWC2 = () =>
    queryClient.invalidateQueries([API_QUERIES.WC2s, { id: options.id }]);

  return {
    wc2,
    error,
    isError,
    isLoading,
    refetch,
    handleInvalidateWC2,
  };
}
