import './styles.scss';
import View from '../View';
import Image from '../Image';
import Text from '../Text';
import { IMAGES } from '../../common/appConfig/images';

const EmptyNotification: React.FC = () => {
  return (
    <View flexGrow={1} justify="center" align="center" className="my-32 cpn-empty-notification">
      <Image src={IMAGES.empty} width="120" height="400" className="mb-16 img-empty" />
      <Text size={14} className="text-center cpn-empty-notification__text">
        You've <span className="fw-bold">read</span> all your notifications from the last 30 days.
      </Text>
    </View>
  );
};

export default EmptyNotification;
