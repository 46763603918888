import { useMutation, UseMutationOptions } from 'react-query';
import { Toastify } from 'src/services';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';

export function useMarkDoneUploadFile(options?: UseMutationOptions<any, Error, { id: string }>) {
  const {
    mutate: onMarkDoneUploadFile,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useMutation<any, Error, { id: string }>({
    mutationFn: (payload: { id: string }) =>
      responseWrapper(apiClient.markDoneUploadFile, [payload]),
    onError: ({ message }) => Toastify.error(message),
    ...options,
  });

  return {
    onMarkDoneUploadFile,
    isLoading,
    isSuccess,
    isError,
    error,
  };
}
