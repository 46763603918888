import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { createResponseWrapper } from '../helpers';
import { AwwDependent } from './types';

export function useCreateDependent(
  options?: UseMutationOptions<AwwDependent, Error, AwwDependent>
) {
  const { mutate: createDependent, isLoading } = useMutation<AwwDependent, Error, AwwDependent>({
    mutationFn: (payload: AwwDependent) =>
      createResponseWrapper(apiClient.createDependent, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    createDependent,
    isLoading,
  };
}
