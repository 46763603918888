import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { AwardEmployment } from './types';

export function useEditAwardEmployment(
  options?: UseMutationOptions<AwardEmployment, Error, AwardEmployment>
) {
  const {
    mutate: onEditAwardEmployment,
    isLoading: isUpdating,
    isError,
    error,
  } = useMutation<AwardEmployment, Error, AwardEmployment>({
    mutationFn: (payload: AwardEmployment) =>
      responseWrapper(apiClient.updateAwardEmployment, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    onEditAwardEmployment,
    isUpdating,
    isError,
    error,
  };
}
