import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { createResponseWrapper } from 'src/queries/helpers';
import { ErrorService } from 'src/services';
import { AwardSummaryPayload } from './types';

export function useCreateAwardSummary(
  options?: UseMutationOptions<any, Error, AwardSummaryPayload>
) {
  const { mutate: createAwardSummary, isLoading } = useMutation<any, Error, AwardSummaryPayload>({
    mutationFn: (payload: AwardSummaryPayload) =>
      createResponseWrapper(apiClient.createAwardSummary, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    createAwardSummary,
    isLoading,
  };
}
