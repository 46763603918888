import { useMemo, useState } from 'react';
import { UseInfiniteQueryOptions, useInfiniteQuery } from 'react-query';
import { useDebounce } from 'src/hooks';
import { isEmpty } from 'src/validations';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { Option } from '../types';
import { TDISFCaseKey } from './keys';
import { TDISFCase } from './types';

const pageParam = {
  take: 10,
  skip: 0,
};

export function useLazyTDISFCases(
  options?: UseInfiniteQueryOptions<{ data: TDISFCase[] }, Error> & {
    [key: string]: string | number | string[];
  }
) {
  const [inputSearch, setInputSearch] = useState<string>('');
  const debounceSearch = useDebounce(inputSearch);
  const {
    data,
    error,
    isError,
    isFetching,
    refetch: getTDISFCases,
    fetchNextPage,
  } = useInfiniteQuery<{ data: TDISFCase[] }, Error>(
    [API_QUERIES.TDISFCases, pageParam, debounceSearch, { ...options }],
    (props): Promise<{ data: TDISFCase[] }> => {
      const { pageParam, queryKey } = props;
      const search = queryKey.at(-2);
      const options = queryKey.at(-1);

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      return responseWrapper<{ data: TDISFCase[] }>(apiClient.getAllTDISFCases, [
        { ...pageParam, search, ...(options as Object) },
      ]);
    },
    {
      keepPreviousData: true,
      getNextPageParam(lastPage, allPages) {
        if (lastPage.data?.length < 10) return undefined;
        return {
          take: 10,
          skip: allPages.length * 10,
        };
      },
      notifyOnChangeProps: ['data', 'isFetching'],
      ...options,
    }
  );

  const tdiSFCases: Option[] = useMemo(() => {
    if (isEmpty(data)) return [];
    return data.pages.reduce(
      (state, page, pageIdx) => [
        ...state,
        ...page.data.map(
          (tdiSFCase, idx): Option => ({
            label: tdiSFCase[TDISFCaseKey.CaseNumber],
            value: tdiSFCase[TDISFCaseKey.Id],
            index: pageIdx * 10 + idx,
          })
        ),
      ],
      []
    ) as Option[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return {
    tdiSFCases,
    error,
    isError,
    loading: isFetching,
    getTDISFCases,
    fetchNextPage,
    setInputSearch,
  };
}
