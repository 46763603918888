import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { ApiResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';

export function useGetPaymentYears(
  options?: UseQueryOptions<
    ApiResponseType<{ paymentYears: Array<string>; reportedYears: Array<string> }>,
    Error,
    { paymentYears: Array<string>; reportedYears: Array<string> }
  > & {
    caseId: string;
  }
) {
  const { data, error, isError, isFetching } = useQuery<
    ApiResponseType<{ paymentYears: Array<string>; reportedYears: Array<string> }>,
    Error,
    { paymentYears: Array<string>; reportedYears: Array<string> }
  >([API_QUERIES.WC3PaymentYear, options?.caseId], {
    queryFn: (query) => {
      const caseId = query.queryKey.at(-1);
      return responseWrapper<
        ApiResponseType<{ paymentYears: Array<string>; reportedYears: Array<string> }>
      >(apiClient.getCasePaymentYears, [caseId]);
    },
    notifyOnChangeProps: ['data', 'isFetching'],
    keepPreviousData: true,
    select: ({ data }) => data,
    enabled: !!options?.caseId,
    ...options,
  });

  const queryClient = useQueryClient();

  const handleInvalidatePaymentYears = () =>
    queryClient.invalidateQueries([API_QUERIES.WC3PaymentYear, options?.caseId]);

  return {
    data,
    error,
    isError,
    isFetching,
    handleInvalidatePaymentYears,
  };
}
