import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from '../apiClient';
import { authResponseWrapper } from '../helpers';
import { UpdateAvatarPayload } from './types';

export function useUpdateAvatarUrl(
  options?: UseMutationOptions<UpdateAvatarPayload, Error, any> & {
    onUpdateAvatarUrlSuccess: (data?: any, variables?: any, context?: unknown) => void;
  }
) {
  const {
    data: fileUpload,
    mutate: onUpdateAvatar,
    isSuccess,
    isLoading,
  } = useMutation<UpdateAvatarPayload, Error, any>({
    mutationFn: (payload: UpdateAvatarPayload) => {
      return authResponseWrapper<any>(apiClient.updateUserAvatar, [payload]);
    },
    onSuccess: (data, variables, context) => {
      options.onUpdateAvatarUrlSuccess(data, variables, context);
    },
    ...options,
  });

  return {
    fileUpload,
    loading: isLoading,
    isSuccess,
    onUpdateAvatar,
  };
}
