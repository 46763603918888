import { formatDateTime } from 'src/utils';
import { PaginationResponseType, toAuditData } from '../helpers';
import { PrehearingForEmployerKey } from './keys';
import { PrehearingForEmployer } from './types';

export const toRelatedPrehearingsData = (data): PaginationResponseType<PrehearingForEmployer> => ({
  ...data,
  data: data.data.map((item) => ({
    ...item,
    [PrehearingForEmployerKey.CreatedBy]: `${item?.createdUser?.firstName} ${
      item?.createdUser?.lastName
    }, ${formatDateTime(item?.createdAt)}`,
    [PrehearingForEmployerKey.UpdatedBy]:
      item?.updatedUser &&
      `${item?.updatedUser?.firstName} ${item?.updatedUser?.lastName}, ${formatDateTime(
        item?.updatedAt
      )}`,
  })),
});

export const toPrehearingForEmployer = (data): PrehearingForEmployer => ({
  ...data,
  [PrehearingForEmployerKey.CaseNumber]: data?.case?.caseNumber,
  [PrehearingForEmployerKey.DateOfII]: data?.case?.dateOfInjury,
  [PrehearingForEmployerKey.EmployerName]: data?.case?.employer?.accountName,
  [PrehearingForEmployerKey.ClaimantName]: data?.case?.claimant?.accountName,
  [PrehearingForEmployerKey.EmployerId]: data?.case?.employer?.id,
  [PrehearingForEmployerKey.ClaimantId]: data?.case?.claimant?.id,
  [PrehearingForEmployerKey.EmployerAuditNumber]: data?.employerAudit?.employerAuditNumber,
  [PrehearingForEmployerKey.EmployerDOLNumber]: data?.case?.employer?.laborNumber,
  ...toAuditData(data),
});
