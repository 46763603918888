import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { HearingNoteValue } from './types';

export function useEditNote(
  options?: UseMutationOptions<any, Error, HearingNoteValue>
) {
  const {
    mutate: onEditRelatedNote,
    isLoading: isUpdating,
    isSuccess,
    isError,
    error,
  } = useMutation<any, Error, HearingNoteValue>({
    mutationFn: (payload: HearingNoteValue) =>
      responseWrapper(apiClient.updateHearingRelatedNote, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    onEditRelatedNote,
    isUpdating,
    isSuccess,
    isError,
    error,
  };
}
