import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { createResponseWrapper } from '../helpers';
import { RoutingInformationPayload } from './types';

export function useCreateRoutingInformation(
  options?: UseMutationOptions<RoutingInformationPayload, Error, RoutingInformationPayload>
) {
  const { mutate: createRoutingInformation, isLoading } = useMutation<
    RoutingInformationPayload,
    Error,
    RoutingInformationPayload
  >({
    mutationFn: (payload: RoutingInformationPayload) =>
      createResponseWrapper(apiClient.createRoutingInformation, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    createRoutingInformation,
    isLoading,
  };
}
