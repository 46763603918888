import { TDI21sAnnualExceptionReportKey } from './keys';

export const toTDI21sAnnualExceptionReport = (data) => ({
  ...data,
  data: data.data.map((tdi21sAnnualExceptionReport) => ({
    ...tdi21sAnnualExceptionReport,
    [TDI21sAnnualExceptionReportKey.EmployerName]:
      tdi21sAnnualExceptionReport?.employer?.accountName || '',
    [TDI21sAnnualExceptionReportKey.DOLNumber]:
      tdi21sAnnualExceptionReport?.employer?.laborNumber || '',
    [TDI21sAnnualExceptionReportKey.Address]: `${
      tdi21sAnnualExceptionReport?.employer?.street || ''
    }, ${tdi21sAnnualExceptionReport?.employer?.city || ''}, ${
      tdi21sAnnualExceptionReport?.employer?.state || ''
    } ${tdi21sAnnualExceptionReport?.employer?.zip || ''}`,
  })),
});
