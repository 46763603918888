import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { createResponseWrapper } from '../helpers';
import { NotifyBouncedCheck } from './types';

export function useNotifyBouncedCheckReceipt(
  options?: UseMutationOptions<NotifyBouncedCheck, Error, NotifyBouncedCheck>
) {
  const { mutate: notifyBouncedCheckReceipt, isLoading } = useMutation<
    NotifyBouncedCheck,
    Error,
    NotifyBouncedCheck
  >({
    mutationFn: (payload: NotifyBouncedCheck) =>
      createResponseWrapper(apiClient.notifyBouncedCheckReceipt, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    notifyBouncedCheckReceipt,
    isLoading,
  };
}
