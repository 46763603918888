import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { WCInsurancePoliciesInterface } from './types';

export function useUpdateWCIP(
  options?: UseMutationOptions<WCInsurancePoliciesInterface, Error, WCInsurancePoliciesInterface>
) {
  const {
    mutate: onEditWCIP,
    isLoading: isUpdating,
    isSuccess,
    isError,
    error,
  } = useMutation<WCInsurancePoliciesInterface, Error, WCInsurancePoliciesInterface>({
    mutationFn: (payload: WCInsurancePoliciesInterface) =>
      responseWrapper(apiClient.updateWCIP, [payload]),
    onError: (error) => ErrorService.handler(error),
    ...options,
  });

  return {
    onEditWCIP,
    isUpdating,
    isSuccess,
    isError,
    error,
  };
}
