import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { RelatedGLAccountPayload } from './types';

export function useUpdateGLAccountRelated(
  options?: UseMutationOptions<any, Error, RelatedGLAccountPayload>
) {
  const {
    mutate: onUpdateGLAccountRelated,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useMutation<any, Error, RelatedGLAccountPayload>({
    mutationFn: (payload: RelatedGLAccountPayload) =>
      responseWrapper(apiClient.updateGLAccountRelated, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    onUpdateGLAccountRelated,
    isLoading,
    isSuccess,
    isError,
    error,
  };
}
