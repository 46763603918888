export enum RelatedCaseDependentsKey {
  Id = 'id',
  DependentRelationshipId = 'relationDeceased',
  Dependent = 'dependent',
  DependentId = 'dependentId',
  Salutation = 'salutation',
  MiddleInitial = 'middleInitial',
  FullName = 'fullName',
  FirstName = 'firstName',
  LastName = 'lastName',
  Suffix = 'suffix',
  RelationToDeceased = 'relationDeceased',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  CreatedByName = 'createdByName',
  UpdatedByName = 'updatedByName',
  CreatedBy = 'createdUser',
  LastModifiedBy = 'updatedUser',
  DCDCaseId = 'caseId',
  FormNumber = 'formNumber',
}

export interface Dependent {
  [RelatedCaseDependentsKey.Id]?: string;
  [RelatedCaseDependentsKey.FirstName]?: string;
  [RelatedCaseDependentsKey.LastName]?: string;
  [RelatedCaseDependentsKey.FullName]?: string;
  [RelatedCaseDependentsKey.MiddleInitial]?: string;
  [RelatedCaseDependentsKey.DependentRelationshipId]?: string;
  [RelatedCaseDependentsKey.Salutation]?: string;
  [RelatedCaseDependentsKey.Suffix]?: string;
}

export interface CreatedUser {
  [RelatedCaseDependentsKey.Id]: string;
  [RelatedCaseDependentsKey.FirstName]: string;
  [RelatedCaseDependentsKey.LastName]: string;
}

export interface UpdatedUser {
  [RelatedCaseDependentsKey.Id]: string;
  [RelatedCaseDependentsKey.FirstName]: string;
  [RelatedCaseDependentsKey.LastName]: string;
}

export interface RelatedCaseDependentsType {
  [RelatedCaseDependentsKey.Id]?: string;
  [RelatedCaseDependentsKey.Dependent]?: Dependent;
  [RelatedCaseDependentsKey.CreatedBy]?: CreatedUser;
  [RelatedCaseDependentsKey.LastModifiedBy]?: UpdatedUser;
  [RelatedCaseDependentsKey.DependentId]?: string;
  [RelatedCaseDependentsKey.DCDCaseId]?: string;
  [RelatedCaseDependentsKey.CreatedAt]?: string;
  [RelatedCaseDependentsKey.UpdatedAt]?: string;
  [RelatedCaseDependentsKey.FormNumber]?: string;
}
