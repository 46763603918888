import { formatDate } from 'src/utils';
import { ApiResponseType, PaginationResponseType } from '../helpers';
import { ApprovedHealthCarePlansReportKey } from './keys';
import { ApprovedHealthCarePlansReport, ApprovedHealthCarePlansTotal } from './types';

export const toApprovedHealthCarePlansReport = (
  data
): ApiResponseType<{
  approvedHCPReport: PaginationResponseType<ApprovedHealthCarePlansReport>;
  totalPlanType: ApprovedHealthCarePlansTotal;
}> => {
  const originalReport = data?.data?.approvedHCPReport?.data || [];

  const formattedReport = originalReport.map((report) => ({
    ...report,
    [ApprovedHealthCarePlansReportKey.StatusEffectedDate]: `${formatDate(
      report?.[ApprovedHealthCarePlansReportKey.StartDate]
    )} - ${formatDate(report?.[ApprovedHealthCarePlansReportKey.EndDate])}`,
  }));

  return {
    ...data,
    data: {
      ...data?.data,
      approvedHCPReport: {
        ...data?.data?.approvedHCPReport,
        data: formattedReport,
      },
    },
  };
};
