export enum TDI15sTDISelfInsurerPlanReportKey {
  StartDate = 'startDate',
  EndDate = 'endDate',
  EffectiveDate = 'effectiveDate',
  AcceptedActionTaken = 'acceptedActionTaken',
  RejectedActionTaken = 'rejectedActionTaken',
  TotalAcceptedActionTaken = 'totalAcceptedActionTaken',
  TotalRejectedActionTaken = 'totalRejectedActionTaken',
  ActionTaken = 'actionTaken',
}
