import { useMemo, useState } from 'react';
import { UseInfiniteQueryOptions, useInfiniteQuery, useQueryClient } from 'react-query';
import { useDebounce } from 'src/hooks';
import { isEmpty } from 'src/validations';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { GLAccountKey } from './keys';
import { GLAccount } from './types';

const pageParam = {
  take: 10,
  skip: 0,
};

export function useLazyGLAccounts(
  options?: UseInfiniteQueryOptions<{ data: GLAccount[] }, Error> & {
    [key: string]: string | number | string[];
  }
) {
  const [inputSearch, setInputSearch] = useState<string>('');
  const debounceSearch = useDebounce(inputSearch);
  const { data, error, isError, isFetching, fetchNextPage } = useInfiniteQuery<
    { data: GLAccount[] },
    Error
  >(
    [API_QUERIES.GLAccounts, pageParam, debounceSearch, { ...options }],
    (props): Promise<{ data: GLAccount[] }> => {
      const { pageParam, queryKey } = props;
      const search = queryKey.at(-2);
      const options = queryKey.at(-1);
      return responseWrapper<{ data: GLAccount[] }>(apiClient.getAllGLAccounts, [
        {
          ...pageParam,
          search,
          ...(options as Object),
        },
      ]);
    },
    {
      keepPreviousData: true,
      getNextPageParam(lastPage, allPages) {
        if (lastPage?.data?.length < 10) return undefined;
        return {
          take: 10,
          skip: allPages.length * 10,
        };
      },
      notifyOnChangeProps: ['data', 'isFetching'],
      ...options,
    }
  );

  const glAccounts: any[] = useMemo(() => {
    if (isEmpty(data)) return [];
    return data.pages.reduce(
      (state, page, pageIdx) => [
        ...state,
        ...page.data.map((glAccount, idx): any => ({
          ...glAccount,
          label: glAccount[GLAccountKey.GLNumber],
          value: glAccount.id,
          subLabel: glAccount[GLAccountKey.AccountDescription],
          index: pageIdx * 10 + idx,
        })),
      ],
      []
    ) as any[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const queryClient = useQueryClient();

  const handleInvalidateLazyGLAccounts = () =>
    queryClient.resetQueries([API_QUERIES.GLAccounts, { ...options }]);

  return {
    glAccounts,
    error,
    isError,
    loading: isFetching,
    fetchNextPage,
    setInputSearch,
    handleInvalidateLazyGLAccounts,
  };
}
