export enum HC15sReportKey {
  Id = 'id',
  StartDate = 'startDate',
  EndDate = 'endDate',
  CertifiedAction = 'certifiedAction',
  DateReceived = 'dateReceived',
  NewlyEnrolledCA = 'newlyEnrolledCA',
  CanceledCA = 'canceledCA',
  IntentToCancelCA = 'intentToCanceledCA',
  TotalNewlyEnrolledCA = 'totalNewlyEnrolledCA',
  TotalCanceledCA = 'totalCanceledCA',
  TotalIntentToCancelCA = 'totalIntentToCanceledCA',
}
