import { PaginationResponseType, toRelatedAuditData } from '../helpers';
import { RelatedHearingRequiredDocumentsType } from './types';

export const toRequiredDocument = (
  data
): PaginationResponseType<RelatedHearingRequiredDocumentsType> => {
  return {
    ...data,
    data: data?.data.map((requiredDocuments) => ({
      ...requiredDocuments,
      ...toRelatedAuditData(requiredDocuments),
    })),
  };
};
