import { useMutation, UseMutationOptions } from 'react-query';
import { Toastify } from 'src/services';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { AddUserPayload } from './types';

export function useEditUser(options?: UseMutationOptions<any, Error, AddUserPayload>) {
  const {
    mutate: onEditUser,
    isLoading: isUpdating,
    isSuccess,
    isError,
    error,
  } = useMutation<any, Error, AddUserPayload>({
    mutationFn: (payload: AddUserPayload) => responseWrapper(apiClient.updateUser, [payload]),
    onError: ({ message }) => Toastify.error(message),
    ...options,
  });

  return {
    onEditUser,
    isUpdating,
    isSuccess,
    isError,
    error,
  };
}
