import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { PaginationResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { GetPropertiesParams } from '../types';
import { toTDI21s } from './helpers';
import { TDI21 } from './types';

export function useGetAllTDI21(options?: UseQueryOptions<PaginationResponseType<TDI21>, Error>) {
  const [params, setParams] = useState<GetPropertiesParams>({});

  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetAllTDI21,
  } = useQuery<PaginationResponseType<TDI21>, Error>([API_QUERIES.TDI21s, params], {
    queryFn: (query) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...params] = query.queryKey;
      return responseWrapper<PaginationResponseType<TDI21>>(apiClient.getAllTDI21, params);
    },
    notifyOnChangeProps: ['data', 'isFetching'],
    keepPreviousData: true,
    select: (data) => toTDI21s(data),
    ...options,
  });

  const queryClient = useQueryClient();

  const handleInvalidateAllTDI21 = () => queryClient.invalidateQueries(API_QUERIES.TDI21s);

  const { data: tdi21s, hasNext, payloadSize, totalRecords } = data || {};

  return {
    tdi21s,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isError,
    isFetching,
    onGetAllTDI21,
    setParams,
    handleInvalidateAllTDI21,
  };
}
