import { formatDate, formatDateTime } from 'src/utils';
import { ProfileDetail } from './types';

export const toProfileData = (data: ProfileDetail): ProfileDetail => ({
  ...data,
  updatedAt: data.updatedByUser
    ? `${data.updatedByUser.firstName} ${data.updatedByUser.lastName} , ${formatDateTime(
        data.updatedAt
      )}`
    : '',
  createdAt: `${data.createdByUser.firstName} ${data.createdByUser.lastName} , ${formatDate(
    data.createdAt
  )} `,
  isUserProfile: data.id === PROFILE_USER_ID,
});

export const PROFILE_USER_ID = 0;
