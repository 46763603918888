import cn from 'classnames';
import React, { useRef } from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';

import { emptyFunction, getRandomId } from 'src/utils';
import { isEmpty } from 'src/validations';
import Element from '../Element';
import './styles.scss';

const TimePicker: React.FC<Props> = ({
  label,
  onChange,
  errorMessage,
  containerClassName,
  classNames,
  placeholder = 'hh : mm',
  dateFormat = 'hh : mm aa',
  timeFormat = 'hh : mm aa',
  timeIntervals = 15,
  required = false,
  name,
  onBlur = emptyFunction,
  value,
  ...props
}) => {
  const id = useRef<string>(`datepicker-${getRandomId()}`);

  const handleBlur = () => {
    onBlur(name, true);
  };

  // For more information:
  // https://reactdatepicker.com/

  const hasError = !isEmpty(errorMessage);
  return (
    <Element
      id={id.current}
      errorMessage={errorMessage}
      label={label}
      className={cn('cmp-datepicker cmp-datepicker__time', containerClassName)}
      required={required}
    >
      <DatePicker
        name={name}
        id={id.current}
        onChange={onChange}
        placeholderText={placeholder}
        className={cn(
          'cmp-datepicker__input',
          { 'cmp-datepicker__input--error': hasError },
          classNames
        )}
        showPopperArrow={false}
        timeFormat={timeFormat}
        dateFormat={dateFormat}
        {...props}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={timeIntervals}
        popperProps={{
          positionFixed: true,
        }}
        portalId="root"
        onBlur={handleBlur}
        selected={value ? new Date(value) : null}
      />
    </Element>
  );
};

type BaseDatePickerProps = Pick<
  ReactDatePickerProps,
  Exclude<keyof ReactDatePickerProps, 'onChange' | 'onBlur'>
>;

type Props = BaseDatePickerProps & {
  errorMessage?: string;
  containerClassName?: string;
  classNames?: string;
  placeholder?: string;
  label?: string;
  onChange: (...args: any) => void;
  onBlur?: (...args: any) => void;
  required?: boolean;
};

export default TimePicker;
