import { formatDateTime } from 'src/utils';
import { ApprovalHistoryKey } from './keys';

export const toRelatedApprovalHistoryData = (apiResponse) => {
  const { data } = apiResponse;

  data.approvalHistories.data = data.approvalHistories.data.map((item) => ({
    ...item,
    [ApprovalHistoryKey.CreatedBy]: `${item?.createdUser?.firstName} ${
      item?.createdUser?.lastName
    }, ${formatDateTime(item?.createdAt)}`,
  }));

  return apiResponse;
};
