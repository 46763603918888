import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { createResponseWrapper } from '../helpers';
import { TDI30 } from './types';

export function useCreateTDI30(options?: UseMutationOptions<TDI30, Error, TDI30>) {
  const { mutate: createTDI30, isLoading } = useMutation<TDI30, Error, TDI30>({
    mutationFn: (payload: TDI30) => createResponseWrapper(apiClient.createTDI30, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    createTDI30,
    isLoading,
  };
}
