import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { VocationalRehabilitation } from './types';

export function useEditVRProcess(
  options?: UseMutationOptions<any, Error, VocationalRehabilitation>
) {
  const {
    mutate: editVrProcess,
    isLoading: isUpdating,
    isSuccess,
    isError,
    error,
  } = useMutation<VocationalRehabilitation, Error, VocationalRehabilitation>({
    mutationFn: (payload: VocationalRehabilitation) =>
      responseWrapper(apiClient.editVocationalRehabilitation, [payload]),
    onError: (error) => ErrorService.handler(error),
    ...options,
  });

  return {
    editVrProcess,
    isUpdating,
    isSuccess,
    isError,
    error,
  };
}
