import { getFirstLastName } from 'src/utils';
import { toAddress, toAuditData, toRelatedAuditData } from '../helpers';
import { RequestKey } from './keys';

export const toRequest = (data) => {
  const claimant = data?.wcCase?.claimant || data?.tdiCase?.claimant || data?.tdiSFCase?.claimant;
  const employer = data?.wcCase?.employer || data?.tdiCase?.employer || data?.tdiSFCase?.employer;

  return {
    ...data,
    [RequestKey.CaseNumber]: data?.wcCase?.caseNumber,
    [RequestKey.CaseNumberId]: data?.wcCase?.id,
    [RequestKey.TDICaseNumber]: data?.tdiCase?.tdiCaseNumber,
    [RequestKey.TDICaseNumberId]: data?.tdiCase?.id,
    [RequestKey.TDISFCaseNumber]: data?.tdiSFCase?.tdiSFCaseNumber,
    [RequestKey.TDISFCaseNumberId]: data?.tdiSFCase?.id,

    // Employer
    [RequestKey.EmployerName]: employer?.accountName,
    [RequestKey.EmployerId]: employer?.id,
    [RequestKey.IIDate]: data?.wcCase?.dateOfInjury,
    [RequestKey.DisabilityDate]: data?.tdiSFCase?.disabilityDate,
    [RequestKey.DateOfBirth]: claimant?.dateOfBirth,
    [RequestKey.FirstDateOfDisabilityClaimed]: data?.tdiCase?.firstDateOfDisabilityClaimed,

    // Claimant
    [RequestKey.ClaimantPhone]: claimant?.phone,
    [RequestKey.ClaimantAddress]: toAddress(claimant),
    [RequestKey.ClaimantName]: claimant?.accountName,
    [RequestKey.ClaimantId]: claimant?.id,
    [RequestKey.SSN]: claimant?.ssn,
    [RequestKey.RequestStreet]: data?.street,
    [RequestKey.RequestState]: data?.state,
    [RequestKey.RequestZip]: data?.zip,
    [RequestKey.RequestCity]: data?.city,

    [RequestKey.AssignedTo]: getFirstLastName(data?.assignedUser),
    [RequestKey.ApprovedBy]: getFirstLastName(data?.approvedUser),
    ...toAuditData(data),
  };
};

export const toRequests = (data) => ({
  ...data,
  data: data.data.map((request) => {
    const claimant =
      request?.wcCase?.claimant || request?.tdiCase?.claimant || request?.tdiSFCase?.claimant;
    const employer =
      request?.wcCase?.employer || request?.tdiCase?.employer || request?.tdiSFCase?.employer;

    return {
      ...request,
      [RequestKey.Case]: request?.wcCase?.caseNumber || '',
      [RequestKey.ClaimantPhone]: claimant?.phone,
      [RequestKey.SSN]: claimant?.ssn,
      [RequestKey.ClaimantStreet]: claimant?.street,
      [RequestKey.ClaimantState]: claimant?.state,
      [RequestKey.ClaimantCity]: claimant?.city,
      [RequestKey.ClaimantZip]: claimant?.zip,
      [RequestKey.ClaimantPhoneDetails]: request?.wcCase?.claimant?.phone,
      [RequestKey.ClaimantName]: claimant?.accountName,
      [RequestKey.ClaimantNameDetails]: request?.wcCase?.claimant?.accountName,

      [RequestKey.RequestStreet]: request?.street,
      [RequestKey.RequestState]: request?.state,
      [RequestKey.RequestCity]: request?.city,
      [RequestKey.RequestZip]: request?.zip,
      [RequestKey.DateOfBirthListView]: claimant?.dateOfBirth,
      [RequestKey.EmployerName]: employer?.accountName,
      [RequestKey.EmployerNameDetails]: request?.wcCase?.employer?.accountName,
      [RequestKey.SSNDetails]: request?.wcCase?.claimant?.ssn,
      [RequestKey.AssignedTo]: getFirstLastName(request?.assignedUser),
      [RequestKey.ApprovedBy]: getFirstLastName(request?.approvedUser),
      [RequestKey.TDICase]: request?.tdiCase?.tdiCaseNumber || '',
      [RequestKey.FilterDisabilityDate]: request?.tdiSFCase?.disabilityDate,
      [RequestKey.FilterFirstDateOfDisabilityClaimed]:
        request?.tdiCase?.firstDateOfDisabilityClaimed,
      [RequestKey.TDISFCase]: request?.tdiSFCase?.tdiSFCaseNumber || '',
      [RequestKey.CaseNumberId]: request?.wcCase?.id,
      [RequestKey.TDICaseNumberId]: request?.tdiCase?.id,
      [RequestKey.TDISFCaseNumberId]: request?.tdiSFCase?.id,
      ...toAuditData(request),
      ...toRelatedAuditData(request),
    };
  }),
});
