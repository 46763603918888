export enum ApprovedHealthCarePlansReportKey {
  Id = 'id',
  StartDate = 'startDate',
  EndDate = 'endDate',
  PlanType = 'planType',
  StatusEffectedDate = 'statusEffectedDate',
  EmployerSponsored = 'employerSponsored',
  InsuredPlan = 'insuredPlan',
  SelfInsured = 'selfInsured',
  EmployerSponsoredTotal = 'employerSponsoredTotal',
  InsuredPlanTotal = 'insuredPlanTotal',
  SelfInsuredTotal = 'selfInsuredTotal',
}
