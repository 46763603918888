export enum WCInsurancePoliciesKey {
  Id = 'id',
  PolicyNumber = 'policyNumber',
  PolicyType = 'policyType',
  PolicyStatus = 'policyStatus',
  PolicyStatusDate = 'policyStatusDate',
  PolicyEffectiveDate = 'policyEffectiveDate',
  PolicyExpirationDate = 'policyExpirationDate',
  MasterCoverageEffectiveDate = 'effectiveDate',
  MasterCoverageEffectiveDateFTK = 'masterCoverage.effectiveDate',
  AgentMasterCoverageExpirationDate = 'expirationDate',
  AgentMasterCoverageExpirationDateFTK = 'masterCoverage.expirationDate',
  InsuranceCarrierName = 'insuranceCarrierName',
  InsuranceCarrier = 'insuranceCarrier',
  InsuranceCarrierId = 'insuranceCarrierId',
  InsuranceCarrierFilterKey = 'insuranceCarrier.accountName',
  CarrierID = 'carrierId',
  CarrierIDFilterKey = 'insuranceCarrier.carrierId',
  UpdatedAt = 'updatedAt',
  UpdatedBy = 'updatedBy',
  UpdatedUser = 'updatedUser',
  CreatedUser = 'createdUser',
  CreatedAt = 'createdAt',
  CreatedBy = 'createdBy',

  //POLICY INFORMATION
  WrapUp = 'wrapUp',
  Captive = 'captive',
  CaptiveFTK = 'insuranceCarrier.isCaptiveCarrier',
  AgentId = 'agentId',
  Agent = 'agent',
  AgentFilterKey = 'agent.accountName',
  Association = 'association',
  AssociationFilterKey = 'association.accountName',
  AssociationId = 'associationId',
  Sequence = 'sequence',
  CertificateReferenceNumber = 'certificateReferenceNumber',

  //MASTER EMPLOYER INFORMATION
  Employer = 'employer',
  EmployerFilterKey = 'employer.accountName',
  EmployerId = 'employerId',
  EmployerStatus = 'employerStatus',
  EmployerFEIN = 'fein',
  EmployerFEINFilterKey = 'employer.fein',
  EmployerStatusFilterKey = 'employer.dcdStatus',
  DOLNumber = 'laborNumber',
  DOLNumberFilterKey = 'employer.laborNumber',
  ProfessionalEmployerOrganization = 'isPEO',
  ProfessionalEmployerOrganizationFilterKey = 'employer.isPEO',

  //PolicyType SI
  NumberOfEmployeesInHawaii = 'numOfEmployees',
  NumberOfEmployeesInHawaiiFilterKey = 'employer.numOfHawaiiEmployees',
  OrderNumber = 'orderNumber',
  ParentCompany = 'parentCompany',
  ParentCompanyId = 'parentCompanyId',
  ParentCompanyFilterKey = 'parentCompany.accountName',
  ParentCompanyAddress = 'parentCompanyAddress',
  ParentCompanyAddressFilterKey = 'parentCompany.street',
  ParentCompanyOwnershipPercent = 'parentCompanyStockOwnershipPercent',
  IndividualResponsibleSIProgram = 'individualResponsibleProgram',
  IndividualResponsibleSIProgramId = 'individualResponsibleProgramId',
  IndividualResponsibleSIProgramFilterKey = 'individualResponsibleForSiProgram.contactName',
  IndividualResponsibleForSiProgramId = 'individualResponsibleForSiProgramId',
  IndividualResponsibleForSiProgram = 'individualResponsibleForSiProgram',
  TitlePolicyTypeSI = 'individualResponsibleforSIProgramTitle',
  TitlePolicyTypeSIFilterKey = 'individualResponsibleForSiProgram.title',
  PhoneNumberPolicyTypeSI = 'individualResponsibleforSIProgramPhone',
  PhoneNumberPolicyTypeSIFilterKey = 'individualResponsibleForSiProgram.phone',
  AddressPolicyTypeSI = 'individualResponsibleforSIProgramAddress',
  AddressPolicyTypeSIFilterKey = 'individualResponsibleForSiProgram.street',
  EmailAddressPolicyTypeSI = 'individualResponsibleforSIProgramEmail',
  EmailAddressPolicyTypeSIFilterKey = 'individualResponsibleForSiProgram.email',

  //CLAIMS ADMINISTRATION (ADMIN)
  ContactId = 'contactId',
  MasterCoverageId = 'masterCoverageId',
  ClaimsAdministrationPerformedBy = 'claimAdministrationType',
  Other = 'other',
  Administrator = 'administrator',
  NameOfAdministrator = 'nameOfAdministrator',
  NameOfAdministratorFTK = 'administrator.contactName',
  AdministratorId = 'administratorId',
  AdministratorAddress = 'administratorAddress',
  AdministratorAddressFTK = 'administrator.street',
  AdministratorPhoneNumber = 'administratorPhone',
  AdministratorPhoneNumberFTK = 'administrator.phone',
  AdministratorEmailAddress = 'administratorEmail',
  AdministratorEmailAddressFTK = 'administrator.email',
  Name = 'name',
  NameFTK = 'contact.contactName',
  TitleAdmin = 'contactTitle',
  TitleAdminFTK = 'contact.title',
  AddressAdmin = 'contactAddress',
  AddressAdminFTK = 'contact.street',
  PhoneAdmin = 'contactPhone',
  PhoneAdminFTK = 'contact.phone',
  EmailAddressAdmin = 'contactEmail',
  EmailAddressAdminFTK = 'contact.email',
  NameOutsideOrganization = 'nameOfOutsideOrganization',

  // Coverages
  Coverages = 'coverages',
  HasMasterCoverage = 'hasMasterCoverage',
}
