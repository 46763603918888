import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { responseWrapper } from 'src/queries/helpers';
import { ErrorService } from 'src/services';
import { VRProvider } from './types';

export function useEditRelatedVRProvider(
  options?: UseMutationOptions<VRProvider, Error, VRProvider>
) {
  const {
    mutate: editVRProvider,
    isLoading: isUpdating,
    isSuccess,
    isError,
    error,
  } = useMutation<VRProvider, Error, VRProvider>({
    mutationFn: (payload: VRProvider) => responseWrapper(apiClient.editVRProvider, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    editVRProvider,
    isUpdating,
    isSuccess,
    isError,
    error,
  };
}
