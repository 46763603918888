export enum BenefitPaymentsKey {
  BenefitPayments = 'benefitPayments',
  GrandTotals = 'grandTotals',
  Id = 'id',
  WC3Id = 'wc3Id',
  PaymentType = 'paymentType',
  PaymentFor = 'paymentFor',
  Payer = 'payer',
  Days = 'days',
  DateFrom = 'dateFrom',
  DateTo = 'dateTo',
  PaymentNotPrevReport = 'paymentNotPrevReport',
  PriorPayment = 'priorPayment',
  TotalPaymentToDate = 'totalPaymentToDate',
  GrandTotalDays = 'grandTotalDays',
  GrandTotalPaymentNotPrevReport = 'grandTotalPaymentNotPrevReport',
  GrandTotalPriorPayment = 'grandTotalPriorPayment',
  GrandTotalPaymentToDate = 'grandTotalPaymentToDate',
  CreatedAt = 'createdAt',
  TTD = 'TTD',
  TPD = 'TPD',
  PPD = 'PPD',
  PTD = 'PTD',
  Death = 'DEATH',
}

export enum PaymentType {
  TTD = 'TTD',
  TPD = 'TPD',
  PPD = 'PPD',
  PTD = 'PTD',
  DEATH = 'DEATH',
}

export interface BenefitPaymentsDetailType {
  [BenefitPaymentsKey.Id]?: string;
  [BenefitPaymentsKey.WC3Id]?: number;
  [BenefitPaymentsKey.PaymentType]?: PaymentType;
  [BenefitPaymentsKey.PaymentFor]?: string;
  [BenefitPaymentsKey.Payer]?: string;
  [BenefitPaymentsKey.Days]?: string | number;
  [BenefitPaymentsKey.DateFrom]?: string;
  [BenefitPaymentsKey.DateTo]?: string;
  [BenefitPaymentsKey.PaymentNotPrevReport]?: string | number;
  [BenefitPaymentsKey.PriorPayment]?: string | number;
  [BenefitPaymentsKey.TotalPaymentToDate]?: string | number;
  [BenefitPaymentsKey.CreatedAt]?: string;
}

export interface BenefitPaymentsInfo {
  [BenefitPaymentsKey.BenefitPayments]?: BenefitPaymentsDetailType[];
  [BenefitPaymentsKey.GrandTotalDays]?: number;
  [BenefitPaymentsKey.GrandTotalPaymentNotPrevReport]?: number;
  [BenefitPaymentsKey.GrandTotalPriorPayment]?: number;
  [BenefitPaymentsKey.GrandTotalPaymentToDate]?: number;
}

export interface BenefitPaymentsType {
  [BenefitPaymentsKey.TTD]?: BenefitPaymentsInfo;
  [BenefitPaymentsKey.TPD]?: BenefitPaymentsInfo;
  [BenefitPaymentsKey.PPD]?: BenefitPaymentsInfo;
  [BenefitPaymentsKey.PTD]?: BenefitPaymentsInfo;
  [BenefitPaymentsKey.Death]?: BenefitPaymentsInfo;
}
