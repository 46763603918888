export enum TDICaseKey {
  Id = 'id',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  CreatedBy = 'createdBy',
  UpdatedBy = 'updatedBy',
  CreatedUser = 'createdUser',
  UpdatedUser = 'updatedUser',
  TDICaseNumber = 'tdiCaseNumber',
  TDICaseFormattedNumber = 'tdiCaseFormattedNumber',
  TDICaseId = 'tdiCaseId',
  Claimant = 'claimant',
  Employer = 'employer',
  AdjustingCompany = 'adjustingCompany',
  AdjustingCompanyId = 'adjustingCompanyId',
  StatusDate = 'statusDate',
  DateReceived = 'dateReceived',
  FirstDateOfDisabilityClaimed = 'firstDateOfDisabilityClaimed',
  DateClaimFiled = 'dateClaimFiled',
  ClaimOrFileNumber = 'claimOrFileNumber',
  Status = 'status',
  DenialStatus = 'denialStatus',
  DateNoticeSentDLIR = 'dateNoticeSentDLIR',
  DateNoticeSentClaimant = 'dateNoticeSentClaimant',
  ReconsiderationRequestDate = 'reconsiderationRequestDate',
  AdjustingCompanyEmail = 'adjustingCompanyEmail',

  // Recent TDI-46
  MostRecentTDI46 = 'mostRecentTDI46',
  MostRecentTDI46Id = 'mostRecentTDI46Id',

  // Claimant
  ClaimantId = 'claimantId',
  ClaimantName = 'claimantName',
  ClaimantPhone = 'claimantPhone',
  ClaimantEmail = 'claimantEmail',
  ClaimantDateOfDeath = 'claimantDateOfDeath',

  // Denial Reason
  FailureToMeetEligibility = 'isDenyFailureToMeetEligibility',
  Received26WeeksOfBenefitPayment = 'isDenyReceived26WeeksOfBenefitPayment',
  LateFillingAfter90Days = 'isDenyLateFillingAfter90Days',
  NotDisabledAccordingMedicalRecord = 'isDenyNotDisabledAccordingMedicalRecord',
  DidNotPerformRegularService = 'isDenyDidNotPerformRegularService',
  NotDisabledBeyond7Days = 'isDenyNotDisabledBeyond7Days',
  ClaimedFiledOn = 'dateClaimFiled',
  PaymentStart14DaysBeforeFileDate = 'isPaymentStart14DaysBeforeFileDate',
  MedCertDoesNotEstablishUnableToWork = 'isDenyMedCertDoesntEstablishUnableToWork',
  NotUnderCarePhysicianStartDate = 'notUnderCarePhysicianStartDate',
  NotUnderCarePhysicianEndDate = 'notUnderCarePhysicianEndDate',
  NotUnderCarePhysicianNoBenefitArePayable = 'notUnderCarePhysicianNoBenefitArePayable',
  NotUnderCarePhysicianPaymentWillStart = 'notUnderCarePhysicianPaymentWillStart',
  NotUnderCarePhysicianPaymentWillStartOn = 'notUnderCarePhysicianPaymentWillStartOn',
  BenefitEntitledUnderUnionContract = 'isDenyBenefitEntitledUnderUnionContract',
  NotInsuranceCarrierForEmployer = 'isDenyNotInsuranceCarrierForEmployer',
  IsDenyOtherReason = 'isDenyOtherReason',
  DenialOtherReasons = 'denialOtherReasons',
  IsDenyFailureToMeetEligibility = 'isDenyFailureToMeetEligibility',
  IsDenyDidNotPerformRegularService = 'isDenyDidNotPerformRegularService',
  IsDenyNotDisabledBeyond7Days = 'isDenyNotDisabledBeyond7Days',
  IsDenyReceived26WeeksOfBenefitPayment = 'isDenyReceived26WeeksOfBenefitPayment',
  IsDenyLateFillingAfter90Days = 'isDenyLateFillingAfter90Days',
  DenialLateFillingNoBenefitArePayable = 'denialLateFillingNoBenefitArePayable',
  DenialLateFillingClaimedFiledOn = 'denialLateFillingClaimedFiledOn',
  IsPaymentStart14DaysBeforeFileDate = 'isPaymentStart14DaysBeforeFileDate',
  IsDenyClaimingBenefitUnderWcLaw = 'isDenyClaimingBenefitUnderWcLaw',
  IsDenyNotDisabledAccordingMedicalRecord = 'isDenyNotDisabledAccordingMedicalRecord',
  AbleToPerformRegularWorkOnDate = 'ableToPerformRegularWorkOnDate',
  PaymentOfBenefitIsDeniedAfterDate = 'paymentOfBenefitIsDeniedAfterDate',
  IsDenyMedCertDoesntEstablishUnableToWork = 'isDenyMedCertDoesntEstablishUnableToWork',
  IsDenyNotUnderCareOfPhysician = 'isDenyNotUnderCareOfPhysician',
  NotCarrierForEmplIsClaimForwarded = 'notCarrierForEmplIsClaimForwarded',
  NotCarrierForEmplClaimForwardedTo = 'notCarrierForEmplClaimForwardedTo',
  NotCarrierForEmplIsClaimReturned = 'notCarrierForEmplIsClaimReturned',

  // Disability
  CauseOfDisability = 'causeOfDisability',
  FirstDateUnableToDoJob = 'firstDateUnableToDoJob',
  RecoveredDate = 'recoveredDate',
  ReturnToWorkDate = 'returnToWorkDate',

  // Appeal
  AppealFiledDate = 'appealFiledDate',
  DisputedItem = 'disputedItem',
  TDIApa5ASentDate = 'tdiApa5ASentDate',
  ClaimantAppeal = 'claimantAppeal',
  AppealWithdrawnDate = 'appealWithdrawnDate',
  ClaimantReceiveDenialNoticeDate = 'claimantReceiveDenialNoticeDate',
  NewspaperPublicDate = 'newspaperPublicDate',

  // Enforcement
  Disposition = 'disposition',
  DateForwardedToInsCarrier = 'dateForwardedToInsCarrier',
  EligibilityDetermination = 'eligibilityDetermination',
  IsBenefitPaid = 'isBenefitPaid',
  Resolution = 'resolution',

  //Employer
  EmployerId = 'employerId',
  EmployerDolNumber = 'employerDOL',
  EmployerStatus = 'employerStatus',
  EmployerStatusDate = 'employerStatusDate',
  EmployerPosition = 'employerPosition',
  IsDelinquentEmployer = 'isDelinquentEmployer',

  // Insurance Carrier
  InsuranceCarrier = 'insuranceCarrier',
  InsuranceCarrierId = 'insuranceCarrierId',
  InsuranceCarrierStatus = 'carrierStatus',
  InsuranceCarrierStatusDate = 'carrierStatusDate',
  InsuranceCarrierAddress = 'carrierAddress',
  InsuranceCarrierPhone = 'carrierPhone',
  InsuranceCarrierStreet = 'carrierStreet',
  InsuranceCarrierCity = 'carrierCity',
  InsuranceCarrierZip = 'carrierZip',
  InsuranceCarrierState = 'carrierState',

  // Nested keys for schema
  EmployerAccountName = 'employerAccountName',
  InsuranceCarrierAccountName = 'insuranceCarrierAccountName',
  AdjustingCompanyAccountName = 'adjustingCompanyAccountName',
  MostRecentTDI46Number = 'mostRecentTDI46Tdi46Number',
  MostRecentTDI46DateReceived = 'mostRecentTDI46DateReceived',
  ClaimantSSN = 'claimantSSN',
  ClaimantDOB = 'claimantDOB',
  ClaimantAddress = 'claimantAddress',
  ClaimantPhoneSchema = 'claimantPhone',
  ClaimantEmailSchema = 'claimantEmail',
  EmployerLaborNumber = 'employerLaborNumber',
  EmployerStatusSchema = 'employerDcdStatus',
  EmployerStatusDateSchema = 'employerDcdStatusDate',
  InsuranceCarrierCarrierId = 'carrierId',
  InsuranceCarrierStatusSchema = 'insuranceCarrierDcdStatus',
  InsuranceCarrierStatusDateSchema = 'insuranceCarrierDcdStatusDate',
  InsuranceCarrierPhoneSchema = 'insuranceCarrierPhone',
  AdjustingCompanyEmailSchema = 'adjustingCompanyEmail',
  AdjustingCompanyPhone = 'adjustingCompanyPhone',
  ResolvedInvestigator = 'resolvedInvestigator',
  ResolvedInvestigatorFullName = 'resolvedInvestigatorFullName',
  ResolvedInvestigatorId = 'resolvedInvestigatorId',

  // Filter key
  InsuranceCarrierCarrierIdFilterKey = 'insuranceCarrier.carrierId',
  InsuranceCarrierStatusFilterKey = 'insuranceCarrier.dcdStatus',
  InsuranceCarrierStatusDateFilterKey = 'insuranceCarrier.dcdStatusDate',
  InsuranceCarrierPhoneFilterKey = 'insuranceCarrier.phone',
  InsuranceCarrierStreetFilterKey = 'insuranceCarrier.street',
  InsuranceCarrierCityFilterKey = 'insuranceCarrier.city',
  InsuranceCarrierZipFilterKey = 'insuranceCarrier.zip',
  InsuranceCarrierStateFilterKey = 'insuranceCarrier.state',

  MostRecentTDI46DateReceivedFilterKey = 'mostRecentTDI46.dateReceived',
  AdjustingCompanyPhoneFilterKey = 'adjustingCompany.phone',
  AdjustingCompanyEmailFilterKey = 'adjustingCompany.email',

  ClaimantSSNFilterKey = 'claimant.ssn',
  ClaimantPhoneFilterKey = 'claimant.phone',
  ClaimantEmailFilterKey = 'claimant.email',
  ClaimantDOBFilterKey = 'claimant.dateOfBirth',

  EmployerDolNumberFilterKey = 'employer.laborNumber',
  EmployerStatusFilterKey = 'employer.dcdStatus',
  EmployerStatusDateFilterKey = 'employer.dcdStatusDate',
}
