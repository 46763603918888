export enum TDI21NumberOfEmployeesReportKey {
  EmployerId = 'employerId',
  EmployerName = 'employerName',
  TDI21Id = 'tdi21Id',
  TDI21 = 'tdi21',
  NumberOfMaleCoveredEmployeeDec = 'numberOfMaleCoveredEmployeeDec',
  NumberOfFeMaleCoveredEmployeeDec = 'numberOFeMaleCoveredEmployeeDec',
  TotalOfCoveredEmployee = 'totalOfCoveredEmployee',
  NumberOfEmployee = 'numberOfEmployee',
}
