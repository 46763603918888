import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { TDISFCaseStatusPayload } from './types';

export function useUpdateTDISFCaseStatus(
  options?: UseMutationOptions<TDISFCaseStatusPayload, Error, TDISFCaseStatusPayload>
) {
  const {
    mutate: onUpdateTDISFCaseStatus,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useMutation<TDISFCaseStatusPayload, Error, TDISFCaseStatusPayload>({
    mutationFn: (payload: TDISFCaseStatusPayload) =>
      responseWrapper(apiClient.updateTDISFCaseStatus, [payload]),
    onError: (error) => ErrorService.handler(error),
    ...options,
  });

  return {
    onUpdateTDISFCaseStatus,
    isLoading,
    isSuccess,
    isError,
    error,
  };
}
