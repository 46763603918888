import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { UpdateHistoryStatusPayload } from './types';

export function useUpdateHistoryStatusForm(
  options?: UseMutationOptions<any, Error, UpdateHistoryStatusPayload>
) {
  const {
    mutate: onUpdateHistoryStatus,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useMutation<any, Error, UpdateHistoryStatusPayload>({
    mutationFn: (payload: UpdateHistoryStatusPayload) =>
      responseWrapper(apiClient.updateHistoryStatus, [payload]),
    onError: (error) => ErrorService.handler(error),
    ...options,
  });

  return {
    onUpdateHistoryStatus,
    isLoading,
    isSuccess,
    isError,
    error,
  };
}
