import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { ApiResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { toHC4Detail } from './helpers';
import { HC4 } from './types';

export function useGetHC4(
  options?: UseQueryOptions<ApiResponseType<{ hc4: HC4 }>, Error, HC4> & {
    id: HC4['id'];
  }
) {
  const {
    data: hc4,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetHC4ById,
  } = useQuery<ApiResponseType<{ hc4: HC4 }>, Error, HC4>([API_QUERIES.HC4s, { id: options.id }], {
    queryFn: (query) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...params] = query.queryKey;
      return responseWrapper<ApiResponseType<{ hc4: HC4 }>>(apiClient.getHC4, params);
    },
    select: (data) => toHC4Detail(data?.data?.hc4),
    enabled: !!options.id,
    ...options,
  });

  const queryClient = useQueryClient();

  const handleInvalidateHC4 = () =>
    queryClient.invalidateQueries([API_QUERIES.HC4s, { id: options.id }]);

  return {
    hc4,
    error,
    isError,
    isLoading,
    onGetHC4ById,

    handleInvalidateHC4,
  };
}
