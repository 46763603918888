import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { responseNoBody } from '../helpers';
import { UploadAvatarPayload } from './types';
import { useUpdateAvatarUrl } from './useUpdateAvatarUrl';

export function useUploadFileToS3(
  options?: UseMutationOptions<UploadAvatarPayload, Error, any> & {
    onUploadFileToS3Success: (
      data?: any,
      variables?: UploadAvatarPayload,
      context?: unknown
    ) => void;
  }
) {
  const {
    mutate: uploadFile,
    isSuccess,
    isLoading,
  } = useMutation<UploadAvatarPayload, Error, any>({
    mutationFn: (payload: UploadAvatarPayload) => {
      return responseNoBody<any>(apiClient.uploadFile, [payload]);
    },
    onSuccess(_, variables) {
      onUpdateAvatar({ avatar: variables.url });
    },
    onError(error) {
      ErrorService.handler(error);
    },
    ...options,
  });

  const { onUpdateAvatar, loading: isLoadingUpdateAvatar } = useUpdateAvatarUrl({
    onUpdateAvatarUrlSuccess: (data, variables, context) => {
      options.onUploadFileToS3Success(data, variables, context);
    },
  });

  return {
    loading: isLoading || isLoadingUpdateAvatar,
    isSuccess,
    uploadFile,
  };
}
