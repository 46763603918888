import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import { Box, Collapse, List } from '@mui/material';
import cn from 'classnames';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { AppName } from 'src/appConfig/constants';
import { MenuItem } from 'src/appConfig/sidebar';
import { IRootState } from '../../redux/store';
import SidebarItem from './SidebarItem';
import { Link, View } from 'src/modules/shared-main/components';

const SidebarItemCollapse: React.FC<Props> = ({ item, activeApp }) => {
  const [open, setOpen] = useState(false);

  const { collapseSidebar, showMiniSidebar } = useSelector((state: IRootState) => state.common);

  return (
    <View className={cn({ 'cmp-sidebar__item__minisidebar': open })}>
      <Link
        href={null}
        className={cn('cmp-sidebar__item', {
          'cmp-sidebar__item--closed': collapseSidebar && !showMiniSidebar,
        })}
        onClick={() => setOpen((prev) => !prev)}
      >
        <View className={cn('cmp-sidebar__item__icon')}>{item.icon}</View>
        <View
          className={cn('cmp-sidebar__item__text', {
            'cmp-sidebar__item__text--closed': collapseSidebar && !showMiniSidebar,
          })}
        >
          {item.title}
        </View>
        <Box className={cn({ 'd-none': collapseSidebar && !showMiniSidebar })}>
          {open ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
        </Box>
      </Link>
      <Collapse in={open && !collapseSidebar} timeout="auto">
        <List
          sx={{
            padding: '0',
          }}
        >
          {item.subMenu?.map((route, index) =>
            route.subMenu ? (
              <SidebarItemCollapse activeApp={activeApp} item={route} key={index} />
            ) : (
              route.apps?.includes(activeApp) && (
                <Box pl={2} key={index}>
                  <SidebarItem activeApp={activeApp} key={index} item={route} />
                </Box>
              )
            )
          )}
        </List>
      </Collapse>
    </View>
  );
};

type Props = {
  item: MenuItem;
  activeApp: AppName;
};

export default SidebarItemCollapse;
