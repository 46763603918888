import { useMutation, UseMutationOptions } from 'react-query';
import { Toastify } from 'src/services';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { WC2Detail } from './types';

export function useUpdateWC2(options?: UseMutationOptions<WC2Detail, Error, WC2Detail>) {
  const {
    mutate: onEditWC2,
    isLoading: isUpdating,
    isSuccess,
    isError,
    error,
  } = useMutation<WC2Detail, Error, WC2Detail>({
    mutationFn: (payload: WC2Detail) => responseWrapper(apiClient.updateWC2, [payload]),
    onError: ({ message }) => Toastify.error(message),
    ...options,
  });

  return {
    onEditWC2,
    isUpdating,
    isSuccess,
    isError,
    error,
  };
}
