import { AccountDetail } from '../Accounts';
import { HC4Key } from './keys';

export enum HC4HRSSection {
  SevenA = '7(a)',
  SevenB = '7(b)',
}
export enum HC4TotalMonthlyPremium {
  EmployeeOnly = 'Employee Only',
  EmployeeSpouse = 'Employee + Spouse',
  EmployeeChild = 'Employee + Child',
  EmployeeDependents = 'Employee + Dependents',
  Family = 'Family',
}

export type HC4 = {
  [HC4Key.Id]?: string;
  [HC4Key.CreatedUser]?: AccountDetail;
  [HC4Key.UpdatedUser]?: AccountDetail;
  [HC4Key.CreatedAt]?: string;
  [HC4Key.UpdatedAt]?: string;
  [HC4Key.CreatedBy]?: string;
  [HC4Key.UpdatedBy]?: string;
  [HC4Key.RecordNum]?: number;
  [HC4Key.HC4Number]?: string;

  // Raw keys
  [HC4Key.RawEmployerName]?: string;
  [HC4Key.RawEmployerDOLNumber]?: string;
  [HC4Key.RawEmployerDBAName]?: string;
  [HC4Key.RawEmployerNatureOfBusiness]?: string;
  [HC4Key.RawEmployerAddress]?: string;
  [HC4Key.RawEmployerCity]?: string;
  [HC4Key.RawEmployerState]?: string;
  [HC4Key.RawEmployerZipcode]?: string;
  [HC4Key.RawEmployerPlaceOfBusiness]?: string;
  [HC4Key.RawEmployerBusinessCity]?: string;
  [HC4Key.RawEmployerBusinessState]?: string;
  [HC4Key.RawEmployerBusinessZipcode]?: string;

  // Service Type
  [HC4Key.ServiceTypeHCContractorId]?: string;
  [HC4Key.ServiceTypePlanName]?: string;
  [HC4Key.ServiceTypeGroupNumber]?: string;
  [HC4Key.ServiceTypeEffectiveDate]?: string;
  [HC4Key.ServiceTypeNameHCIsRegisteredUnder]?: string;
  [HC4Key.ServiceTypeClassOfEECoveredByPlan]?: string;
  [HC4Key.ServiceTypeNoOfEECovered]?: string;

  // Reimburse Type
  [HC4Key.ReimburseTypeHCContractorId]?: string;
  [HC4Key.ReimburseTypePlanName]?: string;
  [HC4Key.ReimburseTypeGroupNumber]?: string;
  [HC4Key.ReimburseTypeEffectiveDate]?: string;
  [HC4Key.ReimburseTypeNameHCIsRegisteredUnder]?: string;
  [HC4Key.ReimburseTypeClassOfEECoveredByPlan]?: string;
  [HC4Key.ReimburseTypeNoOfEECovered]?: string;

  // Collective Bargaining
  [HC4Key.CollectiveBargainingTypeHCContractorId]?: string;
  [HC4Key.CollectiveBargainingTypeHCContractorName]?: string;
  [HC4Key.CollectiveBargainingTypeNameOfUnion]?: string;
  [HC4Key.CollectiveBargainingTypePlanName]?: string;
  [HC4Key.CollectiveBargainingTypeNoOfEECovered]?: string;

  // Self Insure
  [HC4Key.SelfInsureTypeHCAdmin]?: string;
  [HC4Key.SelfInsureTypePlanName]?: string;
  [HC4Key.SelfInsureTypeGroupNumber]?: string;
  [HC4Key.SelfInsureTypeEffectiveDate]?: string;
  [HC4Key.SelfInsureTypeClassOfEECoveredByPlan]?: string;
  [HC4Key.SelfInsureTypeNoOfEECovered]?: string;

  // NoOfEE
  [HC4Key.NoOfEEWorkLessThan20hAWeek]?: number;
  [HC4Key.NoOfEECoveredAsDepUnderQlfHCPlan]?: number;
  [HC4Key.NoOfEECoveredByPrimaryEmployer]?: number;
  [HC4Key.NoOfEECoveredByStateOrFedHCPlan]?: number;
  [HC4Key.NoOfEECoveredByStGovernedMedAsst]?: number;
  [HC4Key.NoOfEEOtherCoverageObtained]?: number;
  [HC4Key.OtherCoverageObtainedFrom]?: string;
  [HC4Key.NoOfEEOther]?: string;

  // Signature
  [HC4Key.SignatureName]?: string;
  [HC4Key.SignatureDate]?: string;
  [HC4Key.SignatureTitle]?: string;
  [HC4Key.SignaturePhone]?: string;
  [HC4Key.SignatureEmail]?: string;
  [HC4Key.SignatureFax]?: string;

  // Total Monthly Premiums count
  [HC4Key.Type]?: HC4TotalMonthlyPremium;
  [HC4Key.EmployeeContribution]?: string;
  [HC4Key.EmployerContribution]?: string;
  [HC4Key.TotalMonthlyCost]?: string;
  // Other
  [HC4Key.Other]?: string;
  [HC4Key.HRSSection]?: HC4HRSSection;
  [HC4Key.AdditionInformation]?: string;
  [HC4Key.ReasonCoverageIsNotRequired]?: string;
  [HC4Key.EmployerId]?: string;
  [HC4Key.Employer]?: AccountDetail;
  [HC4Key.ServiceTypeHCContractor]?: AccountDetail;
  [HC4Key.ReimburseTypeHCContractor]?: AccountDetail;
  [HC4Key.CollectiveBargainingTypeHCContractor]?: AccountDetail;
  [HC4Key.TotalMonthlyPremiums]?: TotalMonthlyPremiums[];
};
export interface TotalMonthlyPremiums {
  [HC4Key.Type]: HC4TotalMonthlyPremium;
  [HC4Key.EmployerContribution]: string;
  [HC4Key.EmployeeContribution]: string;
  [HC4Key.TotalMonthlyCost]: string;
}
