export enum HRSHARKey {
  Id = 'id',
  HearingId = 'hearingId',
  SelectedIds = 'selectedIds',
  ShowingIds = 'showingIds',
  CustomRecords = 'customRecords',
  HrsHars = 'hrsHars',
  IsSelected = 'isSelected',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  CreatedUser = 'createdUser',
  UpdatedUser = 'updatedUser',
  HrsHarSection = 'hrsHARSection',
  Summary = 'summary',
  HrsHarCovers = 'hrsHARCovers',
  DisputeInvolvesTDI = 'disputeInvolvesTDI',
  IsTDIInvolved = 'isTDIInvolved',
  IsTDISFInvolvedUI = 'isTDISFInvolvedUI',
  IsTDISFInvolvedNonCompOrBankruptER = 'isTDISFInvolvedNonCompOrBankruptER',
  RequiredDocument = 'requiredDocument',
  HearingPotentiallyAddress = 'hearingPotentiallyAddress',
  IsDefault = 'isDefault',
}
