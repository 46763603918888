import { useMutation, UseMutationOptions } from 'react-query';
import { WC21 } from 'src/queries/WC21s/types';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';

export function useEditWC21(options?: UseMutationOptions<WC21, Error, WC21>) {
  const {
    mutate: onEditWC21,
    isLoading: isUpdating,
    isSuccess,
    isError,
    error,
  } = useMutation<WC21, Error, WC21>({
    mutationFn: (payload: WC21) => responseWrapper(apiClient.updateWC21, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    onEditWC21,
    isUpdating,
    isSuccess,
    isError,
    error,
  };
}
