import { Account } from '../Accounts';
import { Contact } from '../Contacts';

export enum RelatedPartyNameAddressHistoryKey {
  Id = 'id',
  CasePartyId = 'casePartyId',
  ActiveStartDate = 'activeStartDate',
  ActiveEndDate = 'activeEndDate',
  PartyType = 'partyType',
  PartyRole = 'partyRole',
  Account = 'account',
  AccountName = 'accountName',
  AccountId = 'accountId',
  Contact = 'contact',
  ContactName = 'contactName',
  ContactId = 'contactId',
  PartyName = 'partyName',
  PartyAddress = 'partyAddress',
}

export interface RelatedPartyNameAddressHistory {
  [RelatedPartyNameAddressHistoryKey.Id]: string;
  [RelatedPartyNameAddressHistoryKey.CasePartyId]: string;
  [RelatedPartyNameAddressHistoryKey.ActiveStartDate]?: string;
  [RelatedPartyNameAddressHistoryKey.ActiveEndDate]?: string;
  [RelatedPartyNameAddressHistoryKey.PartyType]?: string;
  [RelatedPartyNameAddressHistoryKey.PartyRole]?: string;
  [RelatedPartyNameAddressHistoryKey.Account]?: Account;
  [RelatedPartyNameAddressHistoryKey.AccountName]?: string;
  [RelatedPartyNameAddressHistoryKey.AccountId]?: string;
  [RelatedPartyNameAddressHistoryKey.Contact]?: Contact;
  [RelatedPartyNameAddressHistoryKey.ContactName]?: string;
  [RelatedPartyNameAddressHistoryKey.ContactId]?: string;
  [RelatedPartyNameAddressHistoryKey.PartyName]?: string;
  [RelatedPartyNameAddressHistoryKey.PartyAddress]?: string;
}
