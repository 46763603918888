import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { responseWrapper } from 'src/queries/helpers';
import { Toastify } from 'src/services';
import { BenefitPaymentsDetailType } from './types';

export function useEditBenefitPayment(
  options?: UseMutationOptions<any, Error, BenefitPaymentsDetailType>
) {
  const {
    mutate: editBenefitPayment,
    isLoading: isUpdating,
    isSuccess,
    isError,
    error,
  } = useMutation<any, Error, BenefitPaymentsDetailType>({
    mutationFn: (payload: BenefitPaymentsDetailType) =>
      responseWrapper(apiClient.editBenefitPayment, [payload]),
    onError: ({ message }) => Toastify.error(message),
    ...options,
  });

  return {
    editBenefitPayment,
    isUpdating,
    isSuccess,
    isError,
    error,
  };
}
