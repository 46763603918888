import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { authResponseWrapper } from 'src/queries/helpers';
import { ErrorService } from 'src/services';
import { NoteDetailRemove } from './types';

export function useRemoveRelatedNotes(
  options?: UseMutationOptions<string, Error, NoteDetailRemove>
) {
  const { mutate: removeRelatedNotes, isLoading } = useMutation<string, Error, NoteDetailRemove>({
    mutationFn: (payload: NoteDetailRemove) =>
      authResponseWrapper(apiClient.removeHearingRelatedNote, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    removeRelatedNotes,
    isLoading,
  };
}
