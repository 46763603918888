export enum HC61Key {
  Id = 'id',
  HC61Number = 'hc61Number',
  NewOrExistingPlan = 'newOrExistingPlan',
  HealthCarePlan = 'healthCarePlan',
  HealthCarePlanType = 'healthCarePlanType',
  HealthCarePlanStatus = 'healthCarePlanStatus',
  HCPlan = 'hcPlan',
  HealthCarePlanId = 'hcPlanId',
  FormActionTaken = 'formActionTaken',
  ReviewWith = 'reviewWith',

  // Employer
  Employer = 'employer',
  EmployerName = 'employerName',
  EmployerId = 'employerId',
  EmployerType = 'employerType',
  DOLNumber = 'employer.laborNumber',
  OtherEmployer = 'otherEmployerType',
  MailingAddressInHawaii = 'mailingAddressInHawaii',
  EmployerMailingState = 'employerState',
  EmployerMailingCity = 'employerCity',
  EmployerMailingZipCode = 'employerZip',
  EmployerMailingStreet = 'employerMailingStreet',
  StreetAddressInHawaii = 'streetAddressInHawaii',
  EmployerHawaiiCity = 'hawaiiCity',
  EmployerHawaiiZipCode = 'hawaiiZipCode',
  EmployerHawaiiState = 'hawaiiState',
  EmployerHawaiiPhone = 'phoneNumberInHawaii',
  FaxNumber = 'faxNumberOfEmployer',
  NatureOfBusiness = 'natureOfBusiness',
  OtherBusinessLocationsInHawaii = 'otherBusinessLocationsinHawaii',
  NumberOfCoveredHawaiiEmployees = 'numberOfCoveredHawaiiEmployees',
  TotalNumberOfEmployees = 'totalNumberOfEmployees',
  ParentCompany = 'parentCompany',
  ParentCompanyName = 'parentCompanyName',
  ParentCompanyId = 'parentCompanyId',
  ParentCompanyAddress = 'parentCompanyAddress',
  ParentCompanyStreet = 'parentCompanyStreet',
  ParentCompanyCity = 'parentCompanyCity',
  ParentCompanyState = 'parentCompanyState',
  ParentCompanyZip = 'parentCompanyZip',
  ParentCompanyStockOwnershipPercent = 'parentCompanyStockOwnershipPercent',
  ConductingBusinessUnderAnotherName = 'isBusinessConductedUnderAnotherName',
  OtherBusinessName = 'otherBusinessName',
  OtherBusinessAddress = 'otherBusinessAddress',
  OtherBusinessCity = 'otherBusinessCity',
  OtherBusinessState = 'otherBusinessState',
  OtherBusinessZip = 'otherBusinessZipCode',
  OtherBusinessNature = 'otherNatureOfBusiness',
  DateBusinessCommencedInHawaii = 'dateBusinessCommencedInHawaii',
  NetProfitOrLossAfterTaxes = 'netProfitOrLossAfterTaxes',
  Year = 'year',
  Amount = 'amount',

  // Contact
  IndividualResponsibleFinancials = 'individualResponsibleForFinancials',
  IndividualResponsibleFinancialsName = 'individualResponsibleForFinancialsName',
  IndividualResponsibleFinancialsId = 'individualResponsibleForFinancialsId',
  IndividualResponsibleForSIName = 'individualResponsibleForSIProgramName',
  AddressForFinancials = 'individualResponsibleForFinancialsAddress',
  ContactPhoneNumberForFinancials = 'individualResponsibleForFinancialsPhone',
  ContactFaxForFinancials = 'faxNumberOfIndividualResponsibleForFinancials',
  ContactTitleForFinancials = 'individualResponsibleForFinancialsTitle',
  CityForFinancials = 'individualResponsibleForFinancialsCity',
  StateForFinancials = 'individualResponsibleForFinancialsState',
  ZipForFinancials = 'individualResponsibleForFinancialsZip',
  IndividualResponsibleForSI = 'individualResponsibleForSIProgram',
  IndividualResponsibleForSIId = 'individualResponsibleForSIProgramId',
  CityForSI = 'individualResponsibleForSIProgramCity',
  StateForSI = 'individualResponsibleForSIProgramState',
  ZipForSI = 'individualResponsibleForSIProgramZip',
  AddressForSI = 'individualResponsibleForSIProgramAddress',
  ContactPhoneNumberForSI = 'individualResponsibleForSIProgramPhone',
  ContactFaxForSI = 'faxNumberOfIndividualResponsibleForSIProgram',
  ContactTitleForSI = 'individualResponsibleForSIProgramTitle',

  // Health Care Insurance
  CurrentHealthCareContractors = 'currentHealthCareContractors',
  RejectedOrPolicyCanceled = 'isRejectedOrCancelled',
  DateOfPolicy = 'dateOfPolicyRejectionOrCancellation',
  HealthCareContractorName = 'nameOfHealthCareContractor',
  HealthCareContractorId = 'healthCareContractorId',
  ReasonForRejectionOrCancellation = 'reasonForRejectionOrCancellation',

  // Claim
  OwnOrganizationAdministrator = 'ownOrganizationAdministrator',
  OwnOrganizationAdministratorId = 'ownOrganizationAdministratorId',
  OwnOrganizationAdministratorAddress = 'ownOrganizationAdministratorAddress',
  OwnOrganizationAdministratorPhoneNumber = 'ownOrganizationAdministratorPhone',
  OwnOrganizationAdministratorState = 'ownOrganizationAdministratorState',
  OwnOrganizationAdministratorCity = 'ownOrganizationAdministratorCity',
  OwnOrganizationAdministratorZip = 'ownOrganizationAdministratorZip',
  OwnOrganizationAdministratorFax = 'faxNumberOfOwnOrganization',
  OwnOrganizationAdministratorTitle = 'ownOrganizationAdministratorTitle',
  OwnOrganizationAdministratorName = 'ownOrganizationAdministratorName',
  NameOutsideOrganization = 'nameOfOutsideOrganization',
  NameOfAdministrator = 'administratorOfOutsideOrganization',
  OutsideOrganizationAddress = 'addressOfOutsideOrganization',
  OutsideOrganizationPhoneNumber = 'phoneNumberOfOutsideOrganization',
  OutsideOrganizationFax = 'faxNumberOfOutsideOrganization',
  OutsideOrganizationTitle = 'titleOfOutsideOrganization',
  OutsideOrganizationZip = 'zipCodeOfOutsideOrganization',
  OutsideOrganizationCity = 'cityOfOutsideOrganization',
  OutsideOrganizationState = 'stateOfOutsideOrganization',
  OtherOutsideOrganization = 'otherInformationOfAdministrationsOrFunctions',
  AdminHasAuthorityToProvideBenefits = 'isAdminAbleToProvideBenefits',
  ExplainLimitations = 'explainLimitations',
  AdminPerformedAtMultipleLocations = 'isAdminPerformedAtMultipleLocations',
  SupplementedByStopLossPolicy = 'isSupplementedByAStopLossPolicy',
  LitigProceedingPendingThreatened = 'isLitigationsProceedingPendingOrThreatened',
  ExplainLitigationProceeding = 'explainLitigationOrProceeding',
  CompanyUsedToDetermineFinSolvency = 'companyUsedToDetermineFinancialSolvency',
  RelationshipOfCompanyToApplicant = 'relationshipOfCompanyToApplicant',
  PercentCompanyOwnership = 'percentOfCompanyOwnership',

  // Signature
  SignatureDate = 'signatureDate',
  SignatureName = 'signatureName',
  SignatureTitle = 'signatureTitle',
  SignaturePhone = 'signaturePhoneNumber',
  SignatureFax = 'signatureFaxNumber',
  SignatureEmail = 'signatureEmailAddress',

  //PlanReview
  HC4 = 'hc4',
  HC4Name = 'hc4Name',
  HC4Id = 'hc4Id',
  HealthCarePlanName = 'healthCarePlanName',
  Funding = 'funding',
  IsFinancialsReceived = 'isFinancialsReceived',
  IsStopLossRequested = 'isStopLossRequested',
  IsPlanDocumentReceived = 'isPlanDocumentReceived',
  DCDReviewForm = 'dcdReviewForm',
  DateAllDocumentsReceived = 'dateAllDocumentsReceived',
  DateConfirmationLetterSentToER = 'dateConfirmationLetterSentToER',
  HRSSection = 'hrsSection',
  PlanEffectiveDate = 'planEffectiveDate',
  DateSentToAudit = 'dateSentToAudit',
  IsAuditApproved = 'isAuditApproved',
  IsLOGReceived = 'isLOGReceived',
  IsStopLossObtained = 'isStopLossObtained',
  DateOfCNXOrWByEmployer = 'dateOfCNXOrWByEmployer',
  DateOfDenialLetter = 'dateOfDenialLetter',
  PlanReviewer = 'planReviewer',
  PlanReviewerName = 'planReviewerName',
  PlanReviewerId = 'planReviewerId',
  DatePlanReviewed = 'datePlanReviewed',
  DateOfCouncilMeeting = 'dateOfCouncilMeeting',
  AdvisoryCouncilREC = 'advisoryCouncilREC',
  IsRECSignedByCouncilChair = 'isRECSignedByCouncilChair',
  IsRECSignedByDirector = 'isRECSignedByDirector',
  IsAmendmentSentToEmployer = 'isAmendmentSentToEmployer',
  IsAmendmentReceivedFromEmployer = 'isAmendmentReceivedFromEmployer',
  IsAmendmentReviewedAndAccepted = 'isAmendmentReviewedAndAccepted',
  IsDisapprovalSentToEmployer = 'isDisapprovalSentToEmployer',

  //Activate
  DCDStatus = 'dcdStatus',
  DCDStatusDate = 'dcdStatusDate',
  HealthCarePlanNumber = 'healthCarePlanNumber',
  PlanType = 'planType',
  PlanStatus = 'planStatus',
  PlanStatusEffectiveDate = 'planEffectiveDate',
  BenefitLevel = 'benefitLevel',
  FinancialStatementYearEnding = 'financialStatementYearEnding',
  LastAuditDate = 'dateSentToAudit',

  // Audit
  CreatedUser = 'createdUser',
  CreatedAt = 'createdAt',
  CreatedBy = 'createdBy',
  UpdatedBy = 'updatedBy',
  UpdatedUser = 'updatedUser',
  UpdatedAt = 'updatedAt',

  // Filter Key
  DOLNumberFilterKey = 'employer.laborNumber',
  EmployerMailingStateFilterKey = 'employer.state',
  EmployerMailingCityFilterKey = 'employer.city',
  EmployerMailingZipCodeFilterKey = 'employer.zip',
  ParentCompanyStateFilterKey = 'parentCompany.state',
  ParentCompanyCityFilterKey = 'parentCompany.city',
  ParentCompanyZipCodeFilterKey = 'parentCompany.zip',

  CityForSIFilterKey = 'individualResponsibleForSIProgram.city',
  StateForSIFilterKey = 'individualResponsibleForSIProgram.state',
  ZipForSIFilterKey = 'individualResponsibleForSIProgram.zip',
  ContactPhoneNumberForSIFilterKey = 'individualResponsibleForSIProgram.phone',
  ContactTitleForSIFilterKey = 'individualResponsibleForSIProgram.title',

  CityForFinancialsFilterKey = 'individualResponsibleForFinancials.city',
  StateForFinancialsFilterKey = 'individualResponsibleForFinancials.state',
  ZipForFinancialsFilterKey = 'individualResponsibleForFinancials.zip',
  ContactPhoneNumberForFinancialsFilterKey = 'individualResponsibleForFinancials.phone',
  ContactTitleForFinancialsFilterKey = 'individualResponsibleForFinancials.title',

  OwnOrganizationAdministratorCityFilterKey = 'ownOrganizationAdministrator.city',
  OwnOrganizationAdministratorStateFilterKey = 'ownOrganizationAdministrator.state',
  OwnOrganizationAdministratorZipFilterKey = 'ownOrganizationAdministrator.zip',
  OwnOrganizationAdministratorPhoneNumberFilterKey = 'ownOrganizationAdministrator.phone',
  OwnOrganizationAdministratorTitleFilterKey = 'ownOrganizationAdministrator.title',
}

export enum NetProfitKey {
  Year = 'year',
  Amount = 'amount',
}
