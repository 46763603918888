export enum AddressHistoryKey {
  FromDate = 'fromDate',
  ToDate = 'toDate',
  Street = 'street',
  City = 'city',
  State = 'state',
  ZipCode = 'zip',
  Country = 'country',
  District = 'district',
  IslandCode = 'islandCode',
  County = 'county',
  Contact = 'contact',
  CreatedBy = 'createdBy',
  LastModifiedBy = 'lastModifiedBy',
  ContactId = 'contactId',
  AccountId = 'accountId',
  Owner = 'owner',
}

export interface AddressHistoryType {
  accountId: string;
  city: string;
  contact: AddressHistoryContact;
  contactId: string;
  country: string;
  county: string;
  createdAt: string;
  createdBy: string;
  createdByName: string;
  district: string;
  fromDate: string;
  id: string;
  islandCode: string;
  ownerFullName: string;
  ownerUserId: string;
  state: string;
  street: string;
  toDate: string;
  updatedAt: string;
  updatedBy: string;
  updatedByName: string;
  zip: string;
}

export interface AddressHistoryContact {
  lastName: string;
  firstName: string;
  middleInitial: string;
  salutation: string;
  suffix: string;
}

export interface AddressHistory {
  id?: string | null;
  [AddressHistoryKey.AccountId]: string;
  [AddressHistoryKey.ContactId]: string | null;
  [AddressHistoryKey.Street]: string | null;
  [AddressHistoryKey.City]: string | null;
  [AddressHistoryKey.State]: string | null;
  [AddressHistoryKey.ZipCode]: string | null;
  [AddressHistoryKey.District]: string | null;
  [AddressHistoryKey.County]: string | null;
  [AddressHistoryKey.IslandCode]: string | null;
  [AddressHistoryKey.Country]: string | null;
  [AddressHistoryKey.Owner]: string | null;
}
