import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { PaginationResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { GetPropertiesParams } from '../types';
import { toUserData } from './helpers';
import { User } from './types';

export function useGetAllUsers(options?: UseQueryOptions<PaginationResponseType<User>, Error>) {
  const [params, setParams] = useState<GetPropertiesParams>({});
  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetAllUsers,
  } = useQuery<PaginationResponseType<User>, Error>([API_QUERIES.Users, params], {
    queryFn: (query) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...params] = query.queryKey;
      return responseWrapper<PaginationResponseType<User>>(apiClient.getAllUsers, params);
    },
    select: (data) => toUserData(data),
    enabled: !!params?.viewId,
    notifyOnChangeProps: ['data', 'isFetching'],
    keepPreviousData: true,
    ...options,
  });

  const queryClient = useQueryClient();

  const handleInvalidateAllUser = () => queryClient.invalidateQueries(API_QUERIES.Users);

  const { data: users, hasNext, payloadSize, totalRecords } = data || {};

  return {
    users,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isError,
    isFetching,
    onGetAllUsers,
    setParams,
    handleInvalidateAllUser,
  };
}
