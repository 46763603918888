import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { PaginationResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { GetPropertiesParams } from '../types';
import { getWC21s } from './helpers';
import { WC21 } from './types';

export function useGetAllWC21s(
  options?: UseQueryOptions<PaginationResponseType<WC21>, Error> & {
    [key: string]: string | number | string[] | boolean;
  }
) {
  const [params, setParams] = useState<GetPropertiesParams>({});

  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetWC21s,
  } = useQuery<PaginationResponseType<WC21>, Error>(
    [API_QUERIES.WC21s, { ...options, ...params }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<PaginationResponseType<WC21>>(apiClient.getAllWC21s, params);
      },
      notifyOnChangeProps: ['data', 'isFetching'],
      keepPreviousData: true,
      select: getWC21s,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateAllWC21s = () => queryClient.invalidateQueries(API_QUERIES.WC21s);

  const { data: wc21s, hasNext, payloadSize, totalRecords } = data || {};

  return {
    wc21s,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isError,
    isFetching,
    onGetWC21s,
    setParams,
    handleInvalidateAllWC21s,
  };
}
