import { useMemo, useState } from 'react';
import { UseInfiniteQueryOptions, useInfiniteQuery, useQueryClient } from 'react-query';
import { useDebounce } from 'src/hooks';
import { isEmpty } from 'src/validations';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { AddUsersToGroupResponse } from './types';

const pageParam = {
  take: 10,
  skip: 0,
};

export function useLazyUsersToAddToGroup(
  options?: UseInfiniteQueryOptions<{ data: AddUsersToGroupResponse }, Error> & {
    id: string;
    notIn?: boolean;
  }
) {
  const [inputSearch, setInputSearch] = useState<string>('');
  const debounceSearch = useDebounce(inputSearch);
  const {
    data,
    error,
    isError,
    isFetching,
    refetch: getUsers,
    fetchNextPage,
  } = useInfiniteQuery<{ data: AddUsersToGroupResponse }, Error>(
    [API_QUERIES.GROUPS, pageParam, debounceSearch, options.id, options.notIn],
    (props): Promise<{ data: AddUsersToGroupResponse }> => {
      const { pageParam = props.queryKey.at(-4), queryKey } = props;
      const notIn = queryKey.at(-1);
      const id = queryKey.at(-2);
      const search = queryKey.at(-3);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      return responseWrapper<{ data: AddUsersToGroupResponse }>(apiClient.getUsersFromGroup, [
        { ...pageParam, id, notIn, search },
      ]);
    },
    {
      keepPreviousData: true,
      getNextPageParam(lastPage, allPages) {
        if (lastPage.data?.groupUsers.data?.length < 10) return undefined;
        return {
          take: 10,
          skip: allPages.length * 10,
        };
      },
      notifyOnChangeProps: ['data', 'isFetching'],
      ...options,
    }
  );

  const users: any[] = useMemo(() => {
    if (isEmpty(data)) return [];
    return data.pages.reduce(
      (state, page, pageIdx) => [
        ...state,
        ...page.data.groupUsers.data.map((user, idx): any => ({
          ...user,
          label: `${user.firstName} ${user.lastName}`,
          value: user.id,
          subLabel: user.email,
          index: pageIdx * 10 + idx,
        })),
      ],
      []
    ) as any[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const queryClient = useQueryClient();

  const handleInvalidateLazyGroupUsers = () =>
    queryClient.invalidateQueries([
      API_QUERIES.GROUPS,
      pageParam,
      debounceSearch,
      options.id,
      options.notIn,
    ]);

  return {
    users,
    error,
    isError,
    loading: isFetching,
    getUsers,
    fetchNextPage,
    setInputSearch,
    handleInvalidateLazyGroupUsers,
  };
}
