import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { createResponseWrapper } from '../helpers';

export function useReleaseDisbursement(options?: UseMutationOptions<string, Error, string>) {
  const { mutate: releaseDisbursement, isLoading } = useMutation<string, Error, string>({
    mutationFn: (payload: string) =>
      createResponseWrapper(apiClient.releaseDisbursement, [payload]),
    onError: (error) => ErrorService.handler(error),
    ...options,
  });

  return {
    releaseDisbursement,
    isLoading,
  };
}
