import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { ApiResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { toAccountData } from './helpers';
import { AccountDetail } from './types';

export function useGetAccount(
  options?: UseQueryOptions<ApiResponseType<{ account: AccountDetail }>, Error, AccountDetail> & {
    id?: AccountDetail['id'];
  }
) {
  const {
    data: account,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetAccountById,
  } = useQuery<ApiResponseType<{ account: AccountDetail }>, Error, AccountDetail>(
    [API_QUERIES.Accounts, { id: options?.id }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<{ account: AccountDetail }>>(
          apiClient.getAccount,
          params
        );
      },
      select: ({ data }) => toAccountData(data.account),
      enabled: !!options?.id,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateAccount = () => {
    queryClient.invalidateQueries([API_QUERIES.Accounts, { id: options.id }]);
    queryClient.invalidateQueries(API_QUERIES.Contacts);
  };

  return {
    account,
    error,
    isError,
    isLoading,
    onGetAccountById,
    handleInvalidateAccount,
  };
}
