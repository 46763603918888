import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { ApiResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { toHearing } from './helpers';
import { Hearing } from './types';

export function useGetHearingDetail(
  options?: UseQueryOptions<ApiResponseType<{ caseHearing: Hearing }>, Error, Hearing> & {
    id: Hearing['id'];
  }
) {
  const {
    data: hearing,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetHearingDetail,
  } = useQuery<ApiResponseType<{ caseHearing: Hearing }>, Error, Hearing>(
    [API_QUERIES.Hearings, { id: options?.id }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<{ caseHearing: Hearing }>>(
          apiClient.getHearingDetail,
          params
        );
      },
      select: ({ data }) => toHearing(data.caseHearing),
      enabled: !!options?.id,
      onError: ErrorService.handler,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateHearingDetail = () =>
    queryClient.invalidateQueries([API_QUERIES.Hearings, { id: options.id }]);

  return {
    hearing,
    error,
    isError,
    isLoading,
    onGetHearingDetail,
    handleInvalidateHearingDetail,
  };
}
