export enum CreateContactKey {
  MasterId = 'masterId',
  //Contact Information
  GenericId = 'genericId',
  AccountName = 'accountName',
  Account = 'accountId',
  ContactName = 'contactName',
  FirstName = 'firstName',
  LastName = 'lastName',
  Title = 'title',
  DCDID = 'dcdId',
  ContactType = 'type',
  Salutation = 'salutation',
  MiddleInitial = 'middleInitial',
  Suffix = 'suffix',
  Phone = 'phone',
  DOB = 'dateOfBirth',
  Email = 'email',

  //Address Information
  Address = 'address',
  City = 'city',
  Province = 'state',
  District = 'district',
  Island = 'islandCode',
  Street = 'street',
  Zip = 'zip',
  Country = 'country',
  County = 'county',
}

export type ContactFormValue = {
  //Contact Information
  [CreateContactKey.Account]: string;
  [CreateContactKey.FirstName]: string;
  [CreateContactKey.LastName]: string;
  [CreateContactKey.Title]: string;
  [CreateContactKey.DCDID]: string;
  [CreateContactKey.ContactType]: string;
  [CreateContactKey.Salutation]: string;
  [CreateContactKey.MiddleInitial]: string;
  [CreateContactKey.Suffix]: string;
  [CreateContactKey.Phone]: string;
  [CreateContactKey.DOB]: string;
  [CreateContactKey.Email]: string;

  //Address Information
  [CreateContactKey.Address]: string;
  [CreateContactKey.City]: string;
  [CreateContactKey.Province]: string;
  [CreateContactKey.District]: string;
  [CreateContactKey.Island]: string;
  [CreateContactKey.Street]: string;
  [CreateContactKey.Zip]: string;
  [CreateContactKey.Country]: string;
  [CreateContactKey.County]: string;
};

export const initialValues = (data?: {}) => ({
  //Contact Information
  [CreateContactKey.Account]: data?.[CreateContactKey.Account] || '',
  [CreateContactKey.FirstName]: data?.[CreateContactKey.FirstName] || '',
  [CreateContactKey.LastName]: data?.[CreateContactKey.LastName] || '',
  [CreateContactKey.Title]: data?.[CreateContactKey.Title] || '',
  [CreateContactKey.DCDID]: data?.[CreateContactKey.DCDID] || '',
  [CreateContactKey.ContactType]: data?.[CreateContactKey.ContactType] || '',
  [CreateContactKey.Salutation]: data?.[CreateContactKey.Salutation] || '',
  [CreateContactKey.MiddleInitial]: data?.[CreateContactKey.MiddleInitial] || '',
  [CreateContactKey.Suffix]: data?.[CreateContactKey.Suffix] || '',
  [CreateContactKey.Phone]: data?.[CreateContactKey.Phone] || '',
  [CreateContactKey.DOB]: data?.[CreateContactKey.DOB] || '',
  [CreateContactKey.Email]: data?.[CreateContactKey.Email] || '',

  //Address Information
  [CreateContactKey.Address]: data?.[CreateContactKey.Address] || '',
  [CreateContactKey.City]: data?.[CreateContactKey.City] || '',
  [CreateContactKey.Province]: data?.[CreateContactKey.Province] || '',
  [CreateContactKey.District]: data?.[CreateContactKey.District] || '',
  [CreateContactKey.Island]: data?.[CreateContactKey.Island] || '',
  [CreateContactKey.Street]: data?.[CreateContactKey.Street] || '',
  [CreateContactKey.Zip]: data?.[CreateContactKey.Zip] || '',
  [CreateContactKey.Country]: data?.[CreateContactKey.Country] || '',
  [CreateContactKey.County]: data?.[CreateContactKey.County] || '',
});

export enum SubmitMode {
  Submit = 'submit',
  SubmitAndNew = 'submitAndNew',
}
