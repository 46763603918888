export enum WC5RawKey {
  RawNewAmend = 'rawNewAmend',
  RawCaseNumber = 'rawHeaderCaseNumber',
  RawDateReceived = 'rawDateReceived',

  RawPrintedNameofEmployee = 'rawEmployeePrinterName',
  RawEmployeeSignatureType = 'rawEmployeeSignatureType',
  RawEmployeeDate = 'rawEmployeeDate',
  RawEmployeeRepresentedBy = 'rawEmployeeRepresentedBy',
  RawEmployeePhone = 'rawEmployeePhone',
  RawEmployeeEmail = 'rawEmployeeEmail',
  RawEmployeeAddress = 'rawEmployeeAddress',
  RawEmployeeCity = 'rawEmployeeCity',
  RawEmployeeState = 'rawEmployeeState',
  RawEmployeeZipcode = 'rawEmployeeZipcode',

  RawInjuredPersonLastname = 'rawInjuredPersonLastname',
  RawInjuredPersonFirstname = 'rawInjuredPersonFirstname',
  RawInjuredPersonMiddleInitial = 'rawInjuredPersonMiddleInitial',
  RawInjuredPersonSuffix = 'rawInjuredPersonSuffix',
  RawInjuredPersonPassport = 'rawInjuredPersonPassport',
  RawInjuredPersonSsn = 'rawInjuredPersonSsn',
  RawInjuredPersonAddress = 'rawInjuredPersonAddress',
  RawInjuredPersonAdditionalAddress = 'rawInjuredPersonAdditionalAddress',
  RawInjuredPersonCity = 'rawInjuredPersonCity',
  RawInjuredPersonState = 'rawInjuredPersonState',
  RawInjuredPersonZipcode = 'rawInjuredPersonZipcode',
  RawInjuredPersonEmail = 'rawInjuredPersonEmail',
  RawInjuredPersonPhone = 'rawInjuredPersonPhone',
  RawInjuredPersonOccupation = 'rawInjuredPersonOccupation',
  RawInjuredPersonBirthday = 'rawInjuredPersonBirthday',
  RawInjuredPersonGender = 'rawInjuredPersonGender',
  RawInjuredPersonIsATranslatorRequiredForHearing = 'rawInjuredPersonIsATranslatorRequiredForHearing',
  RawInjuredPersonTranslatorLanguage = 'rawInjuredPersonTranslatorLanguage',

  RawInjuredEmployerName = 'rawInjuredEmployerName',
  RawInjuredEmployerDolNumber = 'rawInjuredEmployerDolNumber',
  RawInjuredEmployerNatureOfBusiness = 'rawInjuredEmployerNatureOfBusiness',
  RawInjuredEmployerAddress = 'rawInjuredEmployerAddress',
  RawInjuredEmployerAdditionalAddress = 'rawInjuredEmployerAdditionalAddress',
  RawInjuredEmployerCity = 'rawInjuredEmployerCity',
  RawInjuredEmployerState = 'rawInjuredEmployerState',
  RawInjuredEmployerZipcode = 'rawInjuredEmployerZipcode',
  RawInjuredEmployerPoc = 'rawInjuredEmployerPoc',
  RawInjuredEmployerJobTitle = 'rawInjuredEmployerJobTitle',
  RawInjuredEmployerPhone = 'rawInjuredEmployerPhone',
  RawInjuredEmployerEmail = 'rawInjuredEmployerEmail',
  RawInjuredEmployerDateHired = 'rawInjuredEmployerDateHired',
  RawInjuredEmployerWasJob = 'rawInjuredEmployerWasJob',
  RawInjuredEmployerGrossPay = 'rawInjuredEmployerGrossPay',
  RawInjuredEmployerHowOftenPaid = 'rawInjuredEmployerHowOftenPaid',
  RawInjuredEmployerEmployeeFurnished = 'rawInjuredEmployerEmployeeFurnished',
  RawInjuredEmployerLostTimeOffAtOtherEmployments = 'rawInjuredEmployerLostTimeOffAtOtherEmployments',

  RawIiDate = 'rawIiDate',
  RawIiTime = 'rawIiTime',
  RawIiTimeOfDay = 'rawIiTimeOfDay',
  RawIiDisabilityBeganDay = 'rawIiDisabilityBeganDay',
  RawIiOnEmployerPremise = 'rawIiOnEmployerPremise',
  RawIiNotOnPremiseWhere = 'rawIiNotOnPremiseWhere',
  RawIiCity = 'rawIiCity',
  RawIiState = 'rawIiState',
  RawIiZipcode = 'rawIiZipcode',
  RawIiDescribeHowIiOccurred = 'rawIiDescribeHowIiOccurred',
  RawIiDescribeIi = 'rawIiDescribeIi',
  RawIiNatureOfIi = 'rawIiNatureOfIi',
  RawIiMultipleBodyPart = 'rawIiMultipleBodyPart',

  RawInjury1LeftRightSide = 'rawInjury1LeftRightSide',
  RawInjury1FrontBackSide = 'rawInjury1FrontBackSide',
  RawInjury1PartOfBody = 'rawInjury1PartOfBody',
  RawInjury1Disfigurement = 'rawInjury1Disfigurement',
  RawInjury1Burn = 'rawInjury1Burn',

  RawInjury2LeftRightSide = 'rawInjury2LeftRightSide',
  RawInjury2FrontBackSide = 'rawInjury2FrontBackSide',
  RawInjury2PartOfBody = 'rawInjury2PartOfBody',
  RawInjury2Disfigurement = 'rawInjury2Disfigurement',
  RawInjury2Burn = 'rawInjury2Burn',

  RawInjury3LeftRightSide = 'rawInjury3LeftRightSide',
  RawInjury3FrontBackSide = 'rawInjury3FrontBackSide',
  RawInjury3PartOfBody = 'rawInjury3PartOfBody',
  RawInjury3Disfigurement = 'rawInjury3Disfigurement',
  RawInjury3Burn = 'rawInjury3Burn',

  RawInjuryReasonForFiling = 'rawInjuryReasonForFiling',
  RawInjuryOtherFilingReason = 'rawInjuryOtherFilingReason',
  RawInjuryStopWorking = 'rawInjuryStopWorking',
  RawInjuryStopWorkingDate = 'rawInjuryStopWorkingDate',
  RawInjuryReturnToWork = 'rawInjuryReturnToWork',
  RawInjuryTypeOfWorkDuty = 'rawInjuryTypeOfWorkDuty',

  RawWitnessIsMoreThanOne = 'rawWitnessIsMoreThanOne',
  RawWitness1Lastname = 'rawWitness1Lastname',
  RawWitness1Firstname = 'rawWitness1Firstname',
  RawWitness1MiddleInitial = 'rawWitness1MiddleInitial',
  RawWitness1Suffix = 'rawWitness1Suffix',
  RawWitness1Address = 'rawWitness1Address',
  RawWitness1City = 'rawWitness1City',
  RawWitness1State = 'rawWitness1State',
  RawWitness1Zipcode = 'rawWitness1Zipcode',
  RawWitness1Email = 'rawWitness1Email',
  RawWitness1WorkPhone = 'rawWitness1WorkPhone',
  RawWitness1HomePhone = 'rawWitness1HomePhone',

  RawWitness2Lastname = 'rawWitness2Lastname',
  RawWitness2Firstname = 'rawWitness2Firstname',
  RawWitness2MiddleInitial = 'rawWitness2MiddleInitial',
  RawWitness2Suffix = 'rawWitness2Suffix',
  RawWitness2Address = 'rawWitness2Address',
  RawWitness2City = 'rawWitness2City',
  RawWitness2State = 'rawWitness2State',
  RawWitness2Zipcode = 'rawWitness2Zipcode',
  RawWitness2Email = 'rawWitness2Email',
  RawWitness2WorkPhone = 'rawWitness2WorkPhone',
  RawWitness2HomePhone = 'rawWitness2HomePhone',

  RawNoticeDidYouNotifyEmployerOfIi = 'rawNoticeDidYouNotifyEmployerOfIi',
  RawNoticeNotifyEmployerHow = 'rawNoticeNotifyEmployerHow',
  RawNoticeDate = 'rawNoticeDate',
  RawNoticeTimeOfNotification = 'rawNoticeTimeOfNotification',
  RawNoticeTimeOfDay = 'rawNoticeTimeOfDay',
  RawNoticeToWhom = 'rawNoticeToWhom',
  RawNoticePhone = 'rawNoticePhone',

  RawInsuranceCarrierName = 'rawInsuranceCarrierName',
  RawInsuranceCarrierPoc = 'rawInsuranceCarrierPoc',
  RawInsuranceCarrierPhone = 'rawInsuranceCarrierPhone',
  RawInsuranceCarrierEmail = 'rawInsuranceCarrierEmail',
  RawInsuranceCarrierAddress = 'rawInsuranceCarrierAddress',
  RawInsuranceCarrierAdditionalAddress = 'rawInsuranceCarrierAdditionalAddress',
  RawInsuranceCarrierCity = 'rawInsuranceCarrierCity',
  RawInsuranceCarrierState = 'rawInsuranceCarrierState',
  RawInsuranceCarrierZipcode = 'rawInsuranceCarrierZipcode',

  RawAttendingPhysicianName = 'rawAttendingPhysicianName',
  RawAttendingPhysicianPhone = 'rawAttendingPhysicianPhone',
  RawAttendingPhysicianEmail = 'rawAttendingPhysicianEmail',
  RawAttendingPhysicianAddress = 'rawAttendingPhysicianAddress',
  RawAttendingPhysicianCity = 'rawAttendingPhysicianCity',
  RawAttendingPhysicianState = 'rawAttendingPhysicianState',
  RawAttendingPhysicianZipcode = 'rawAttendingPhysicianZipcode',
  RawMedicalFacilityName = 'rawMedicalFacilityName',
  RawMedicalFacilityAddress = 'rawMedicalFacilityAddress',
  RawMedicalFacilityCity = 'rawMedicalFacilityCity',
  RawMedicalFacilityState = 'rawMedicalFacilityState',
  RawMedicalFacilityZipcode = 'rawMedicalFacilityZipcode',
  RawTreatmentFirstDay = 'rawTreatmentFirstDay',
  RawTreatmentFirstPlace = 'rawTreatmentFirstPlace',
  RawTreatmentStillBeing = 'rawTreatmentStillBeing',

  id = 'id',
  FormNumber = 'formNumber',
  CaseId = 'caseId',
  CaseNumber = 'caseNumber',
  ClaimantName = 'claimantName',
  ClaimantId = 'claimantId',
  EmployerName = 'employerName',
  EmployerId = 'employerId',

  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  CreatedBy = 'createdBy',
  UpdatedBy = 'updatedBy',
  UpdatedUser = 'updatedUser',
  CreateUser = 'createdUser',
  LastModifiedAt = 'lastModifiedAt',
}

export enum WC5RelationshipsKey {
  CaseId = 'caseId',
  Case = 'case',
  ClaimantId = 'claimantId',
  Claimant = 'claimant',
  EmployerId = 'employerId',
  Employer = 'employer',
  PhysicianId = 'physicianId',
  Physician = 'physician',
  InsuranceCarrierId = 'insuranceCarrierId',
  InsuranceCarrier = 'insuranceCarrier',
  AdjustingCompanyId = 'adjustingCompanyId',
  AdjustingCompany = 'adjustingCompany',
  AdjusterId = 'adjusterId',
  Adjuster = 'adjuster',
  EmployerPointOfContactId = 'employerPointOfContactId',
  EmployerPointOfContact = 'employerPointOfContact',
  CaseNumber = 'caseNumber',
}

export const WC5Key = {
  ...WC5RawKey,
  ...WC5RelationshipsKey,
};

export interface UpdatedUsers {
  id: string;
  createdAt: string;
  updatedAt: string;
  firstName: string;
  lastName: string;
  middleName: string;
  fullName: string;
  username: string;
  email: string;
  userType: string;
  status: string;
}

export interface WC5RawInterface {
  [WC5RawKey.id]?: string;
  [WC5RawKey.RawNewAmend]?: string;
  [WC5RawKey.RawCaseNumber]?: string;
  [WC5RawKey.RawDateReceived]?: string;

  [WC5RawKey.RawPrintedNameofEmployee]?: string;
  [WC5RawKey.RawEmployeeSignatureType]?: string;
  [WC5RawKey.RawEmployeeDate]?: string;
  [WC5RawKey.RawEmployeeRepresentedBy]?: string;
  [WC5RawKey.RawEmployeePhone]?: string;
  [WC5RawKey.RawEmployeeAddress]?: string;
  [WC5RawKey.RawEmployeeEmail]?: string;
  [WC5RawKey.RawEmployeeCity]?: string;
  [WC5RawKey.RawEmployeeState]?: string;
  [WC5RawKey.RawEmployeeZipcode]?: string;

  [WC5RawKey.RawInjuredPersonLastname]?: string;
  [WC5RawKey.RawInjuredPersonFirstname]?: string;
  [WC5RawKey.RawInjuredPersonMiddleInitial]?: string;
  [WC5RawKey.RawInjuredPersonSuffix]?: string;
  [WC5RawKey.RawInjuredPersonPassport]?: string;
  [WC5RawKey.RawInjuredPersonSsn]?: string;
  [WC5RawKey.RawInjuredPersonAddress]?: string;
  [WC5RawKey.RawInjuredPersonAdditionalAddress]?: string;
  [WC5RawKey.RawInjuredPersonCity]?: string;
  [WC5RawKey.RawInjuredPersonState]?: string;
  [WC5RawKey.RawInjuredPersonZipcode]?: string;
  [WC5RawKey.RawInjuredPersonEmail]?: string;
  [WC5RawKey.RawInjuredPersonPhone]?: string;
  [WC5RawKey.RawInjuredPersonOccupation]?: string;
  [WC5RawKey.RawInjuredPersonBirthday]?: string;
  [WC5RawKey.RawInjuredPersonGender]?: string;
  [WC5RawKey.RawInjuredPersonIsATranslatorRequiredForHearing]?: string;
  [WC5RawKey.RawInjuredPersonTranslatorLanguage]?: string;

  [WC5RawKey.RawInjuredEmployerName]?: string;
  [WC5RawKey.RawInjuredEmployerDolNumber]?: string;
  [WC5RawKey.RawInjuredEmployerNatureOfBusiness]?: string;
  [WC5RawKey.RawInjuredEmployerAddress]?: string;
  [WC5RawKey.RawInjuredEmployerAdditionalAddress]?: string;
  [WC5RawKey.RawInjuredEmployerCity]?: string;
  [WC5RawKey.RawInjuredEmployerState]?: string;
  [WC5RawKey.RawInjuredEmployerZipcode]?: string;
  [WC5RawKey.RawInjuredEmployerPoc]?: string;
  [WC5RawKey.RawInjuredEmployerJobTitle]?: string;
  [WC5RawKey.RawInjuredEmployerPhone]?: string;
  [WC5RawKey.RawInjuredEmployerEmail]?: string;
  [WC5RawKey.RawInjuredEmployerDateHired]?: string;
  [WC5RawKey.RawInjuredEmployerWasJob]?: string;
  [WC5RawKey.RawInjuredEmployerGrossPay]?: string;
  [WC5RawKey.RawInjuredEmployerHowOftenPaid]?: string;
  [WC5RawKey.RawInjuredEmployerEmployeeFurnished]?: string;
  [WC5RawKey.RawInjuredEmployerLostTimeOffAtOtherEmployments]?: string;

  [WC5RawKey.RawIiDate]?: string;
  [WC5RawKey.RawIiTime]?: string;
  [WC5RawKey.RawIiTimeOfDay]?: string;
  [WC5RawKey.RawIiDisabilityBeganDay]?: string;
  [WC5RawKey.RawIiOnEmployerPremise]?: string;
  [WC5RawKey.RawIiNotOnPremiseWhere]?: string;
  [WC5RawKey.RawIiCity]?: string;
  [WC5RawKey.RawIiState]?: string;
  [WC5RawKey.RawIiZipcode]?: string;
  [WC5RawKey.RawIiDescribeHowIiOccurred]?: string;
  [WC5RawKey.RawIiDescribeIi]?: string;
  [WC5RawKey.RawIiNatureOfIi]?: string;
  [WC5RawKey.RawIiMultipleBodyPart]?: string;

  [WC5RawKey.RawInjury1LeftRightSide]?: string;
  [WC5RawKey.RawInjury1FrontBackSide]?: string;
  [WC5RawKey.RawInjury1PartOfBody]?: string;
  [WC5RawKey.RawInjury1Disfigurement]?: string;
  [WC5RawKey.RawInjury1Burn]?: string;

  [WC5RawKey.RawInjury2LeftRightSide]?: string;
  [WC5RawKey.RawInjury2FrontBackSide]?: string;
  [WC5RawKey.RawInjury2PartOfBody]?: string;
  [WC5RawKey.RawInjury2Disfigurement]?: string;
  [WC5RawKey.RawInjury2Burn]?: string;

  [WC5RawKey.RawInjury3LeftRightSide]?: string;
  [WC5RawKey.RawInjury3FrontBackSide]?: string;
  [WC5RawKey.RawInjury3PartOfBody]?: string;
  [WC5RawKey.RawInjury3Disfigurement]?: string;
  [WC5RawKey.RawInjury3Burn]?: string;

  [WC5RawKey.RawInjuryReasonForFiling]?: string;
  [WC5RawKey.RawInjuryOtherFilingReason]?: string;
  [WC5RawKey.RawInjuryStopWorking]?: string;
  [WC5RawKey.RawInjuryStopWorkingDate]?: string;
  [WC5RawKey.RawInjuryReturnToWork]?: string;
  [WC5RawKey.RawInjuryTypeOfWorkDuty]?: string;

  [WC5RawKey.RawWitnessIsMoreThanOne]?: string;
  [WC5RawKey.RawWitness1Lastname]?: string;
  [WC5RawKey.RawWitness1Firstname]?: string;
  [WC5RawKey.RawWitness1MiddleInitial]?: string;
  [WC5RawKey.RawWitness1Suffix]?: string;
  [WC5RawKey.RawWitness1Address]?: string;
  [WC5RawKey.RawWitness1City]?: string;
  [WC5RawKey.RawWitness1State]?: string;
  [WC5RawKey.RawWitness1Zipcode]?: string;
  [WC5RawKey.RawWitness1Email]?: string;
  [WC5RawKey.RawWitness1WorkPhone]?: string;
  [WC5RawKey.RawWitness1HomePhone]?: string;

  [WC5RawKey.RawWitness2Lastname]?: string;
  [WC5RawKey.RawWitness2Firstname]?: string;
  [WC5RawKey.RawWitness2MiddleInitial]?: string;
  [WC5RawKey.RawWitness2Suffix]?: string;
  [WC5RawKey.RawWitness2Address]?: string;
  [WC5RawKey.RawWitness2City]?: string;
  [WC5RawKey.RawWitness2State]?: string;
  [WC5RawKey.RawWitness2Zipcode]?: string;
  [WC5RawKey.RawWitness2Email]?: string;
  [WC5RawKey.RawWitness2WorkPhone]?: string;
  [WC5RawKey.RawWitness2HomePhone]?: string;

  [WC5RawKey.RawNoticeDidYouNotifyEmployerOfIi]?: string;
  [WC5RawKey.RawNoticeNotifyEmployerHow]?: string;
  [WC5RawKey.RawNoticeDate]?: string;
  [WC5RawKey.RawNoticeTimeOfNotification]?: string;
  [WC5RawKey.RawNoticeTimeOfDay]?: string;
  [WC5RawKey.RawNoticeToWhom]?: string;
  [WC5RawKey.RawNoticePhone]?: string;

  [WC5RawKey.RawInsuranceCarrierName]?: string;
  [WC5RawKey.RawInsuranceCarrierPoc]?: string;
  [WC5RawKey.RawInsuranceCarrierPhone]?: string;
  [WC5RawKey.RawInsuranceCarrierEmail]?: string;
  [WC5RawKey.RawInsuranceCarrierAddress]?: string;
  [WC5RawKey.RawInsuranceCarrierAdditionalAddress]?: string;
  [WC5RawKey.RawInsuranceCarrierCity]?: string;
  [WC5RawKey.RawInsuranceCarrierState]?: string;
  [WC5RawKey.RawInsuranceCarrierZipcode]?: string;

  [WC5RawKey.RawAttendingPhysicianName]?: string;
  [WC5RawKey.RawAttendingPhysicianPhone]?: string;
  [WC5RawKey.RawAttendingPhysicianEmail]?: string;
  [WC5RawKey.RawAttendingPhysicianAddress]?: string;
  [WC5RawKey.RawAttendingPhysicianCity]?: string;
  [WC5RawKey.RawAttendingPhysicianState]?: string;
  [WC5RawKey.RawAttendingPhysicianZipcode]?: string;
  [WC5RawKey.RawMedicalFacilityName]?: string;
  [WC5RawKey.RawMedicalFacilityAddress]?: string;
  [WC5RawKey.RawMedicalFacilityCity]?: string;
  [WC5RawKey.RawMedicalFacilityState]?: string;
  [WC5RawKey.RawMedicalFacilityZipcode]?: string;
  [WC5RawKey.RawTreatmentFirstDay]?: string;
  [WC5RawKey.RawTreatmentFirstPlace]?: string;
  [WC5RawKey.RawTreatmentStillBeing]?: string;

  [WC5RawKey.FormNumber]?: string;
  [WC5RawKey.CaseId]?: string;
  [WC5RawKey.ClaimantId]?: string;
  [WC5RawKey.ClaimantName]?: string;
  [WC5RawKey.EmployerId]?: string;
  [WC5RawKey.EmployerName]?: string;

  [WC5RawKey.CreatedAt]?: string;
  [WC5RawKey.UpdatedAt]?: string;
  [WC5RawKey.CreatedBy]?: string;
  [WC5RawKey.UpdatedBy]?: string;

  [WC5RawKey.CreatedAt]?: string;
  [WC5RawKey.UpdatedAt]?: string;
  [WC5RawKey.CreateUser]?: string;
  [WC5RawKey.LastModifiedAt]?: string;
  [WC5RawKey.UpdatedUser]: UpdatedUsers;
}

export interface WC5RelationshipsInterface {
  [WC5RelationshipsKey.CaseId]?: string;
  [WC5RelationshipsKey.Case]?: any;
  [WC5RelationshipsKey.CaseNumber]?: string;
  [WC5RelationshipsKey.ClaimantId]?: string;
  [WC5RelationshipsKey.Claimant]?: any;
  [WC5RelationshipsKey.EmployerId]?: string;
  [WC5RelationshipsKey.Employer]?: any;
  [WC5RelationshipsKey.PhysicianId]?: string;
  [WC5RelationshipsKey.Physician]?: any;
  [WC5RelationshipsKey.InsuranceCarrierId]?: string;
  [WC5RelationshipsKey.InsuranceCarrier]?: any;
  [WC5RelationshipsKey.AdjustingCompanyId]?: string;
  [WC5RelationshipsKey.AdjustingCompany]?: any;
  [WC5RelationshipsKey.AdjusterId]?: string;
  [WC5RelationshipsKey.Adjuster]?: any;
  [WC5RelationshipsKey.EmployerPointOfContactId]?: string;
  [WC5RelationshipsKey.EmployerPointOfContact]?: any;
}

export type WC5Interface = WC5RawInterface & WC5RelationshipsInterface;
