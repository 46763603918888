import { useMemo, useState } from 'react';
import { UseInfiniteQueryOptions, useInfiniteQuery } from 'react-query';
import { useDebounce } from 'src/hooks';
import { isEmpty } from 'src/validations';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { Option } from '../types';
import { HC4Key } from './keys';
import { HC4 } from './types';

const pageParam = {
  take: 10,
  skip: 0,
};

export function useLazyHC4s(
  options?: UseInfiniteQueryOptions<{ data: HC4[] }, Error> & {
    [key: string]: string | number | string[];
  }
) {
  const [inputSearch, setInputSearch] = useState<string>('');
  const debounceSearch = useDebounce(inputSearch);
  const {
    data,
    error,
    isError,
    isFetching,
    refetch: getHC4,
    fetchNextPage,
  } = useInfiniteQuery<{ data: HC4[] }, Error>(
    [API_QUERIES.HC4s, pageParam, debounceSearch, { ...options }],
    (props): Promise<{ data: HC4[] }> => {
      const { pageParam, queryKey } = props;
      const search = queryKey.at(-2);
      const options = queryKey.at(-1);

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      return responseWrapper<{ data: HC4[] }>(apiClient.getAllHC4, [
        { ...pageParam, search, ...(options as Object) },
      ]);
    },
    {
      keepPreviousData: true,
      getNextPageParam(lastPage, allPages) {
        if (lastPage.data?.length < 10) return undefined;
        return {
          take: 10,
          skip: allPages.length * 10,
        };
      },
      notifyOnChangeProps: ['data', 'isFetching'],
      ...options,
    }
  );

  const hc4s: Option[] = useMemo(() => {
    if (isEmpty(data)) return [];
    return data.pages.reduce(
      (state, page, pageIdx) => [
        ...state,
        ...page.data.map(
          (hc4, idx): Option => ({
            label: hc4[HC4Key.HC4Number],
            value: hc4[HC4Key.Id],
            index: pageIdx * 10 + idx,
          })
        ),
      ],
      []
    ) as Option[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return {
    hc4s,
    error,
    isError,
    loading: isFetching,
    getHC4,
    fetchNextPage,
    setInputSearch,
  };
}
