const formats = [
  [
    {
      className: 'ql-header',
      options: ['1', '2', '3', '4', '5'],
    },
    {
      className: 'ql-color',
      options: [],
    },
    {
      className: 'ql-background',
      options: [],
    },
    { className: 'ql-bold' },
    { className: 'ql-italic' },
    { className: 'ql-underline' },
    { className: 'ql-strike' },
  ],
  [
    {
      className: 'ql-list',
      value: 'ordered',
    },
    {
      className: 'ql-list',
      value: 'bullet',
    },
  ],
  [
    {
      className: 'ql-align',
      value: '',
    },
    {
      className: 'ql-align',
      value: 'center',
    },
    {
      className: 'ql-align',
      value: 'right',
    },
    {
      className: 'ql-align',
      value: 'justify',
    },
    {
      className: 'ql-indent',
      value: '-1',
    },
    {
      className: 'ql-indent',
      value: '+1',
    },
  ],
  [{ className: 'ql-link' }, { className: 'ql-attachments' }],
];

export default formats;
