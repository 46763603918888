import { NewAmend, YesNo } from 'src/appConfig/constants';
import { AuditKey } from '../keys';
import { PayrollInformationKey, WC14Key } from './keys';

export enum IdentificationType {
  SSN = 'SSN',
  Passport = 'PASSPORT',
}

export interface WC14 {
  id: string;
  [WC14Key.FormNumber]: string;
  [WC14Key.PortalWCFormNumber]: string;

  [WC14Key.RawNewAmend]: NewAmend;
  [WC14Key.RawHeaderCaseNumber]: string;
  [WC14Key.RawDateReceived]: Date;
  [WC14Key.RawDateOfInjury]: Date;
  [WC14Key.CaseNumber]: string;

  [AuditKey.CreatedAt]: string;
  [AuditKey.CreatedBy]: string;
  [AuditKey.UpdatedAt]: string;
  [AuditKey.UpdatedBy]: string;
  [WC14Key.WCCaseId]: string;
  [WC14Key.RawEmployerLiableForInjury]: YesNo;
  [WC14Key.RawEmployeeName]: string;
  [WC14Key.RawEmployeeIdType]: IdentificationType;
  [WC14Key.RawEmployeeIdNumber]: string;
  [WC14Key.RawEmployeeOccupation]: null;
  [WC14Key.RawHourlyRate]: null;
  [WC14Key.RawDateHired]: null;
  [WC14Key.RawPresentlyEmployed]: YesNo;
  [WC14Key.RawTerminationDate]: Date;
  [WC14Key.RawDateDisabilityBegan]: Date;
  [WC14Key.RawDateDisabilityEnded]: Date;
  [WC14Key.RawDateReturnedToWork]: Date;
  [WC14Key.RawWorkSunday]: string;
  [WC14Key.RawWorkMonday]: string;
  [WC14Key.RawWorkTuesday]: string;
  [WC14Key.RawWorkWednesday]: string;
  [WC14Key.RawWorkThursday]: string;
  [WC14Key.RawWorkFriday]: string;
  [WC14Key.RawWorkSaturday]: string;
  [WC14Key.RawIndicateOther]: string;
  [WC14Key.RawEmployerName]: string;
  [WC14Key.RawEmployerPhone]: string;
  [WC14Key.RawEmployerAddress]: string;
  [WC14Key.RawEmployerAddressLine2]: string;
  [WC14Key.RawEmployerCity]: string;
  [WC14Key.RawEmployerState]: string;
  [WC14Key.RawEmployerZip]: string;
  [WC14Key.RawSignatureDate]: Date;
  [WC14Key.RawSignatureTitle]: string;
  [WC14Key.ClaimantId]: string;
  [WC14Key.ClaimantName]: string;
  [WC14Key.EmployerId]: string;
  [WC14Key.EmployerName]: string;
  [WC14Key.RawPayrollInformation]: PayrollInformation[];
}

export interface PayrollInformation {
  [PayrollInformationKey.PeriodStart]: string;
  [PayrollInformationKey.PeriodEnd]: string;
  [PayrollInformationKey.DaysInPeriod]: number;
  [PayrollInformationKey.TotalRegularHoursWorked]: number;
  [PayrollInformationKey.TotalOvertimeHoursWorked]: number;
  [PayrollInformationKey.AmountPaidRegularHours]: number;
  [PayrollInformationKey.AmountPaidOvertimeHours]: number;
  [PayrollInformationKey.TotalAmountPaid]: number;
}
