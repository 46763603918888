import { YesNo } from 'src/appConfig/constants';
import { RelatedInjuryIllnessKey } from 'src/queries';

export enum SubmitMode {
  Submit = 'Submit',
  SubmitAndNew = 'SubmitAndNew',
}

const convertYesNoToBoolean = (value: YesNo) => {
  if (!value) return null;

  return value === YesNo.Yes ? true : false;
};

export const convertBooleanToYesNo = (value) => {
  if (typeof value !== 'boolean') return null;
  return value ? 'Yes' : 'No';
};

export const initialValues = (data) => {
  return {
    [RelatedInjuryIllnessKey.Type]: data?.[RelatedInjuryIllnessKey.Type] || '',
    [RelatedInjuryIllnessKey.Side]: data?.[RelatedInjuryIllnessKey.Side] || '',
    [RelatedInjuryIllnessKey.SideFrontBack]: data?.[RelatedInjuryIllnessKey.SideFrontBack] || '',
    [RelatedInjuryIllnessKey.BodyPart]: data?.[RelatedInjuryIllnessKey.BodyPart] || '',
    [RelatedInjuryIllnessKey.Other]: data?.[RelatedInjuryIllnessKey.Other] || '',
    [RelatedInjuryIllnessKey.PartOfBodyCode]: data?.[RelatedInjuryIllnessKey.PartOfBodyCode] || '',

    [RelatedInjuryIllnessKey.Disfigurement]: convertBooleanToYesNo(
      data?.[RelatedInjuryIllnessKey.Disfigurement]
    ),
    [RelatedInjuryIllnessKey.Burn]: convertBooleanToYesNo(data?.[RelatedInjuryIllnessKey.Burn]),
    [RelatedInjuryIllnessKey.DCDCase]: data?.[RelatedInjuryIllnessKey.DCDCase] || '',
    [RelatedInjuryIllnessKey.FormNumber]: data?.[RelatedInjuryIllnessKey.FormNumber] || '',
  };
};

export const toData = (data) => ({
  ...data,
  [RelatedInjuryIllnessKey.Disfigurement]: convertYesNoToBoolean(
    data?.[RelatedInjuryIllnessKey.Disfigurement]
  ),
  [RelatedInjuryIllnessKey.Burn]: convertYesNoToBoolean(data?.[RelatedInjuryIllnessKey.Burn]),
});

export enum SideLeftRightKey {
  Left = 'Left',
  Right = 'Right',
  Middle = 'Middle',
  NA = 'N/A',
}

export enum FrontBackKey {
  Front = 'Front',
  Back = 'Back',
}

export enum BodyPartKey {
  Shoulder = 'Shoulder',
  UpperArm = 'Upper Arm',
  Forearm = 'Forearm',
  Elbow = 'Elbow',
  Hand = 'Hand',
  Wrist = 'Wrist',
  Finger = 'Finger',
  Hip = 'Hip',
  UpperLeg = 'Upper Leg',
  Knee = 'Knee',
  LowerLeg = 'Lower Leg',
  Ankle = 'Ankle',
  Foot = 'Foot',
  Toe = 'Toe',
  Neck = 'Neck',
  UpperBack = 'Upper Back',
  LowerBack = 'Lower Back',
  Chest = 'Chest',
  Rib = 'Rib',
  Abdominal = 'Abdominal',
  Head = 'Head',
  Face = 'Face',
  Eye = 'Eye',
  Nose = 'Nose',
  Ear = 'Ear',
  Mouth = 'Mouth',
  Stress = 'Stress',
  StressMental = 'Stress - Mental',
  DifficultyBreathing = 'Difficulty Breathing',
  Fainting = 'Fainting',
  Headache = 'Headache',
  Anxiety = 'Anxiety',
  HeartAttack = 'Heart Attack',
  Stroke = 'Stroke',
  Depression = 'Depression',
  DepressionSuicide = 'Depression - Suicide',
  Vomiting = 'Vomiting',
  BloodClots = 'Blood Clots',
  SkinIssue = 'Skin Issue',
  Burn = 'Burn',
  Disaster = 'Disaster',
  PTSD = 'PTSD',
  Disease = 'Disease',
  DiseaseCancer = 'Disease - Cancer',
  CRPS = 'CRPS',
  Vertigo = 'Vertigo',
  Seizures = 'Seizures',
  Other = 'Other',
}

export enum InjuryIllnessType {
  Injury = 'Injury',
  Illness = 'Illness',
}

export const sideLeftRightTypeOptions = [
  {
    value: SideLeftRightKey.Left,
    label: 'Left',
  },
  {
    value: SideLeftRightKey.Right,
    label: 'Right',
  },
  {
    value: SideLeftRightKey.Middle,
    label: 'Middle',
  },
  {
    value: SideLeftRightKey.NA,
    label: 'N/A',
  },
];

export const sideFrontBackTypeOptions = Object.keys(FrontBackKey).map((key) => ({
  value: FrontBackKey[key as string],
  label: FrontBackKey[key as string],
}));

export const partOfBodyInjuryOptions = [
  {
    value: BodyPartKey.Shoulder,
    label: 'Shoulder',
  },
  {
    value: BodyPartKey.UpperArm,
    label: 'Upper Arm',
  },
  {
    value: BodyPartKey.Forearm,
    label: 'Forearm',
  },
  {
    value: BodyPartKey.Elbow,
    label: 'Elbow',
  },
  {
    value: BodyPartKey.Hand,
    label: 'Hand',
  },
  {
    value: BodyPartKey.Wrist,
    label: 'Wrist',
  },
  {
    value: BodyPartKey.Finger,
    label: 'Finger',
  },
  {
    value: BodyPartKey.Hip,
    label: 'Hip',
  },
  {
    value: BodyPartKey.UpperLeg,
    label: 'Upper Leg',
  },
  {
    value: BodyPartKey.Knee,
    label: 'Knee',
  },
  {
    value: BodyPartKey.LowerLeg,
    label: 'Lower Leg',
  },
  {
    value: BodyPartKey.Ankle,
    label: 'Ankle',
  },
  {
    value: BodyPartKey.Foot,
    label: 'Foot',
  },
  {
    value: BodyPartKey.Toe,
    label: 'Toe',
  },
  {
    value: BodyPartKey.Neck,
    label: 'Neck',
  },
  {
    value: BodyPartKey.UpperBack,
    label: 'Upper Back',
  },
  {
    value: BodyPartKey.LowerBack,
    label: 'Lower Back',
  },
  {
    value: BodyPartKey.Chest,
    label: 'Chest',
  },
  {
    value: BodyPartKey.Rib,
    label: 'Rib',
  },
  {
    value: BodyPartKey.Abdominal,
    label: 'Abdominal',
  },
  {
    value: BodyPartKey.Head,
    label: 'Head',
  },
  {
    value: BodyPartKey.Face,
    label: 'Face',
  },
  {
    value: BodyPartKey.Eye,
    label: 'Eye',
  },
  {
    value: BodyPartKey.Nose,
    label: 'Nose',
  },
  {
    value: BodyPartKey.Ear,
    label: 'Ear',
  },
  {
    value: BodyPartKey.Mouth,
    label: 'Mouth',
  },
  {
    value: BodyPartKey.Other,
    label: 'Other',
  },
];

export const partOfBodyIllnessOptions = [
  {
    value: BodyPartKey.Stress,
    label: 'Stress',
  },
  {
    value: BodyPartKey.StressMental,
    label: 'Stress - Mental',
  },
  {
    value: BodyPartKey.DifficultyBreathing,
    label: 'Difficulty Breathing',
  },
  {
    value: BodyPartKey.Fainting,
    label: 'Fainting',
  },
  {
    value: BodyPartKey.Headache,
    label: 'Headache',
  },
  {
    value: BodyPartKey.Anxiety,
    label: 'Anxiety',
  },
  {
    value: BodyPartKey.HeartAttack,
    label: 'Heart Attack',
  },
  {
    value: BodyPartKey.Stroke,
    label: 'Stroke',
  },
  {
    value: BodyPartKey.Depression,
    label: 'Depression',
  },
  {
    value: BodyPartKey.DepressionSuicide,
    label: 'Depression - Suicide',
  },
  {
    value: BodyPartKey.Vomiting,
    label: 'Vomiting',
  },
  {
    value: BodyPartKey.BloodClots,
    label: 'Blood Clots',
  },
  {
    value: BodyPartKey.SkinIssue,
    label: 'Skin Issue',
  },
  {
    value: BodyPartKey.Burn,
    label: 'Burn',
  },
  {
    value: BodyPartKey.Disaster,
    label: 'Disaster',
  },
  {
    value: BodyPartKey.PTSD,
    label: 'PTSD',
  },
  {
    value: BodyPartKey.Disease,
    label: 'Disease',
  },
  {
    value: BodyPartKey.DiseaseCancer,
    label: 'Disease - Cancer',
  },
  {
    value: BodyPartKey.CRPS,
    label: 'CRPS',
  },
  {
    value: BodyPartKey.Vertigo,
    label: 'Vertigo',
  },
  {
    value: BodyPartKey.Seizures,
    label: 'Seizures',
  },
  {
    value: BodyPartKey.Other,
    label: 'Other',
  },
];

export const injuryIllnessTypeOptions = [
  {
    value: InjuryIllnessType.Injury,
    label: 'Injury',
  },
  {
    value: InjuryIllnessType.Illness,
    label: 'Illness/Condition',
  },
];
