import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { createResponseWrapper } from '../helpers';
import { ActivatePlanHC61Payload } from './types';

export function useActivateUpdatePlanHC61(
  options?: UseMutationOptions<{ hcPlanId: string }, Error, ActivatePlanHC61Payload>
) {
  const { mutate: updateActivateHC61, isLoading: isUpdating } = useMutation<
    { hcPlanId: string },
    Error,
    ActivatePlanHC61Payload
  >({
    mutationFn: (payload: ActivatePlanHC61Payload) =>
      createResponseWrapper(apiClient.activateUpdatePlanHC61, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    updateActivateHC61,
    isUpdating,
  };
}
