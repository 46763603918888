import { PaginationResponseType, toAuditData } from '../helpers';
import { WC2Key } from './keys';
import { WC2Detail } from './types';

export const toWC2Detail = (data) => ({
  ...data,
  [WC2Key.PhysicianAccountName]: data?.physician?.accountName,
  [WC2Key.CaseNumber]: data?.case?.caseNumber,
  [WC2Key.CaseId]: data?.case?.id,
  ...toAuditData(data),
});

export const toWC2s = (data: PaginationResponseType<any>): PaginationResponseType<WC2Detail> => ({
  ...data,
  data: data.data.map((wc2) => ({
    ...wc2,
    [WC2Key.Physician]: wc2?.physician?.accountName,
    [WC2Key.Employer]: wc2?.employer?.accountName,
    [WC2Key.EmployerPointOfContact]: wc2?.employerPointOfContact?.contactName,
    [WC2Key.Carrier]: wc2?.carrier?.accountName,
    [WC2Key.AdjustingCompany]: wc2?.adjustingCompany?.accountName,
    [WC2Key.Adjuster]: wc2?.adjuster?.contactName,
    [WC2Key.Patient]: wc2?.patient?.accountName,
    [WC2Key.CaseNumber]: wc2?.case?.caseNumber,
    [WC2Key.CaseId]: wc2?.case?.id,
    ...toAuditData(wc2),
  })),
});
