import { useMutation, UseMutationOptions } from 'react-query';
import { Toastify } from 'src/services';
import apiClient from '../apiClient';
import { createResponseWrapper } from '../helpers';
import { ContactPayload } from './types';

export function useCreateContact(
  options?: UseMutationOptions<ContactPayload, Error, ContactPayload>
) {
  const { mutate: createContact, isLoading } = useMutation<ContactPayload, Error, ContactPayload>({
    mutationFn: (payload: ContactPayload) =>
      createResponseWrapper(apiClient.createContact, [payload]),
    onError: ({ message }) => Toastify.error(message),
    ...options,
  });

  return {
    createContact,
    isLoading,
  };
}
