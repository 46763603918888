import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { toWC21Detail } from 'src/queries/WC21s/helpers';
import apiClient from '../apiClient';
import { ApiResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { WC21 } from './types';

export function useGetWC21(
  options?: UseQueryOptions<ApiResponseType<{ form: WC21 }>, Error, WC21> & {
    id: WC21['id'];
  }
) {
  const {
    data: wc21,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetWC21ById,
  } = useQuery<ApiResponseType<{ form: WC21 }>, Error, WC21>([API_QUERIES.WC21s, { ...options }], {
    queryFn: (query) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...params] = query.queryKey;
      return responseWrapper<ApiResponseType<{ form: WC21 }>>(apiClient.getWC21Detail, params);
    },
    select: ({ data }) => toWC21Detail(data.form),
    enabled: !!options.id,
    ...options,
  });

  const queryClient = useQueryClient();

  const handleInvalidateWC21 = () =>
    queryClient.invalidateQueries([API_QUERIES.WC21s, { ...options }]);

  return {
    wc21,
    error,
    isError,
    isLoading,
    onGetWC21ById,
    handleInvalidateWC21,
  };
}
