import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { ApiResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { toDisbursement } from './helpers';
import { Disbursement } from './types';

export function useGetDisbursement(
  options?: UseQueryOptions<
    ApiResponseType<{ disbursement: Disbursement }>,
    Error,
    Disbursement
  > & {
    id: string;
  }
) {
  const {
    data: disbursement,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetDisbursement,
  } = useQuery<ApiResponseType<{ disbursement: Disbursement }>, Error, Disbursement>(
    [API_QUERIES.Disbursements, { id: options?.id }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<{ disbursement: Disbursement }>>(
          apiClient.getDisbursement,
          params
        );
      },
      select: ({ data }) => toDisbursement(data.disbursement),
      enabled: !!options?.id,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateDisbursement = () =>
    queryClient.invalidateQueries([API_QUERIES.Disbursements, { id: options?.id }]);

  return {
    disbursement,
    error,
    isError,
    isLoading,
    onGetDisbursement,
    handleInvalidateDisbursement,
  };
}
