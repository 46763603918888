import { useMutation, UseMutationOptions } from 'react-query';
import { ViewObject } from 'src/queries';
import { Toastify } from 'src/services';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { Service } from './types';

export function usePinView(
  options?: UseMutationOptions<
    { id: string; object: ViewObject; service: Service },
    Error,
    { id: string; object: ViewObject; service: Service }
  >
) {
  const { mutate: pinView, isLoading } = useMutation<
    { id: string; object: ViewObject; service: Service },
    Error,
    { id: string; object: ViewObject; service: Service }
  >({
    mutationFn: (payload: { id: string; object: ViewObject; service: Service }) => {
      const pinViewPayload = { id: payload.id, service: payload?.service || Service.CaseSvc };
      return responseWrapper(apiClient.pinView, [pinViewPayload]);
    },
    onError: ({ message }) => Toastify.error(message),
    ...options,
  });

  return {
    pinView,
    isLoading,
  };
}
