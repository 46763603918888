import { AccountDetail } from '../Accounts';
import { ApprovalHistoryKey, ApprovalObjectType } from '../RelatedApprovalHistory';
import { CreatedUser } from '../RelatedCaseDependents/types';
import { UpdatedUser } from '../WC3A';

export enum RelatedOrderKey {
  Id = 'id',
  Case = 'case',
  OrderNumber = 'caseOrderNumber',
  OrderType = 'orderType',
  CaseNumber = 'caseNumber',
  CaseId = 'caseId',
  OrderStatus = 'status',
  HearingOfficerId = 'hearingOfficerId',
  HearingOfficer = 'hearingOfficer',
  GrantedDenied = 'grantStatus',
  FindingsOfFactFont = 'findingOfFact',
  DecisionText = 'decision',
  RequestedDate = 'requestedDate',
  OrderMailedDate = 'mailedDate',
  CreatedBy = 'createdBy',
  CreatedUser = 'createdUser',
  CreatedAt = 'createdAt',
  LastModifiedBy = 'updatedBy',
  LastModifiedUser = 'updatedUser',
  LastModifiedAt = 'updatedAt',
  ApprovalStatus = 'approvalStatus',
  DateOfII = 'dateOfInjury',
  Claimant = 'claimant',
}

export interface RelatedOrderType {
  [RelatedOrderKey.Id]?: string;
  [RelatedOrderKey.OrderNumber]?: string;
  [RelatedOrderKey.OrderType]?: string;
  [RelatedOrderKey.CaseId]?: string;
  [RelatedOrderKey.OrderStatus]?: string;
  [RelatedOrderKey.HearingOfficerId]?: string;
  [RelatedOrderKey.HearingOfficer]?: any;
  [RelatedOrderKey.GrantedDenied]?: string;
  [RelatedOrderKey.FindingsOfFactFont]?: string;
  [RelatedOrderKey.DecisionText]?: string;
  [RelatedOrderKey.RequestedDate]?: string;
  [RelatedOrderKey.OrderMailedDate]?: string;
  [RelatedOrderKey.DateOfII]?: string;
  [RelatedOrderKey.Claimant]?: AccountDetail;
  [RelatedOrderKey.CreatedBy]?: string;
  [RelatedOrderKey.LastModifiedBy]?: string;
  [RelatedOrderKey.CreatedUser]?: CreatedUser;
  [RelatedOrderKey.LastModifiedUser]?: UpdatedUser;
  [RelatedOrderKey.CreatedAt]?: string;
  [RelatedOrderKey.LastModifiedAt]?: string;
}

export type OrderSubmitApprovalPayload = {
  [ApprovalHistoryKey.ObjectId]?: string;
  [ApprovalHistoryKey.Comments]?: string;
  [ApprovalHistoryKey.ObjectType]?: ApprovalObjectType;
  [ApprovalHistoryKey.AssignedToId]?: number;
  [ApprovalHistoryKey.AssignedTo]?: string;
};
