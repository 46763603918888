import cn from 'classnames';
import { useRef, useCallback } from 'react';
import { FiSearch } from 'react-icons/fi';
import { IoCaretDownOutline } from 'react-icons/io5';
import Select, { components } from 'react-select';
import { COLOR_CODE } from 'src/appConfig/constants';
import { getRandomId } from 'src/utils';
import { isEmpty } from 'src/validations';
import Element from '../Element';
import View from '../View';
import './styles.scss';
import { isString } from 'lodash';
import { Divider } from '@mui/material';

const MAX_MENU_HEIGHT = 200;

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <IoCaretDownOutline size={12} />
    </components.DropdownIndicator>
  );
};

const Control = ({ children, ...props }) => (
  <components.Control {...props}>
    <FiSearch className="ml-8" />
    {children}
  </components.Control>
);

const ControlWithLabel = ({ children, ...props }) => {
  const { labelControl } = props.selectProps;
  return (
    <components.Control {...props}>
      <p className="px-12 text-color-grey-900">{labelControl}</p>
      <span className="cmp-select__indicator-separator" />
      {children}
    </components.Control>
  );
};

const ControlNoSearchIcon = ({ children, ...props }) => (
  <components.Control {...props}>{children}</components.Control>
);

const IndicatorSeparator = (props) => <components.IndicatorSeparator {...props} />;

export const ALL_OPTIONS_VALUE = 'ALL_OPTIONS';

const allValueOptions = [
  {
    label: 'All Values',
    value: ALL_OPTIONS_VALUE,
    isShowDivider: true,
  },
];

const SelectCmp = ({
  options = [],
  onChange,
  label,
  className = '',
  value,
  errorMessage = '',
  placeholder = '',
  containerClassName = '',
  onBlur,
  name = '',
  required = false,
  infoTooltipMessage = '',
  infoTooltipPlacement = 'right',
  infoToolTipWithArrow = true,
  hideSearchIcon = true,
  isDisabled = false,
  isMulti = false,
  labelControl = '',
  menuPosition = 'fixed',
  onInputChange = (value) => {},
  allowSelectAllOption = isMulti,
  ...props
}) => {
  const id = useRef(`select-${getRandomId()}`);
  const handleChange = (selectedOption) => {
    if (isMulti) {
      handleMultiSelectChange(selectedOption);
    } else onChange(name, selectedOption?.value || null);
  };

  const handleMultiSelectChange = (selectedOption) => {
    if (selectedOption?.[selectedOption.length - 1]?.value === ALL_OPTIONS_VALUE) {
      const filteredSelectedOption = selectedOption.filter(
        ({ value }) => value === ALL_OPTIONS_VALUE
      );
      onChange(name, selectedOption ? filteredSelectedOption.map((item) => item?.value) : null);
    } else {
      const filteredSelectedOption = selectedOption.filter(
        ({ value }) => value !== ALL_OPTIONS_VALUE
      );
      onChange(name, selectedOption ? filteredSelectedOption.map((item) => item?.value) : null);
    }
  };

  const handleSelectBlur = (event) => {
    onBlur && onBlur(name, true);
  };

  const selectOptions = [...(allowSelectAllOption ? allValueOptions : []), ...options].filter(
    ({ isHide }) => !isHide
  );

  const hasError = !isEmpty(errorMessage);

  const selectedOption = isMulti
    ? selectOptions?.filter((option) => value?.includes(option.value)) || null
    : selectOptions?.find((option) => option.value === value) || null;
  // For custom select, follow this link:
  // https://react-select.com/styles#using-classnames

  const defaultPlaceholder = isDisabled
    ? ''
    : isString(label)
    ? `Select ${label.toLowerCase()}`
    : null;

  const Option = useCallback((props) => {
    const { data } = props;
    const children = (
      <>
        <View isRowWrap align="center">
          <View>
            <span>
              {data.label} {data.postfixLabel}
            </span>
            <span className="text-color-grey-600 text-is-12 sub-label">{data.subLabel}</span>
          </View>
        </View>
      </>
    );
    if (data.isShowDivider)
      return (
        <>
          <components.Option {...props} children={children} />
          <Divider
            style={{
              margin: 8,
            }}
          />
        </>
      );

    if (data.isHide) return null;
    return <components.Option {...props} children={children} />;
  }, []);

  return (
    <Element
      id={id.current}
      errorMessage={errorMessage}
      label={label}
      className={containerClassName}
      required={required}
      infoTooltipMessage={infoTooltipMessage}
      infoTooltipPlacement={infoTooltipPlacement}
      infoToolTipWithArrow={infoToolTipWithArrow}
    >
      <View>
        <Select
          id={id.current}
          maxMenuHeight={MAX_MENU_HEIGHT}
          isClearable={!required}
          isDisabled={isDisabled}
          labelControl={labelControl}
          value={selectedOption}
          placeholder={!isDisabled ? (placeholder ? placeholder : defaultPlaceholder) : ''}
          onChange={handleChange}
          options={selectOptions}
          className={cn('cmp-select', className, {
            'cmp-select--error': hasError,
            'cmp-select--is-disabled': isDisabled,
          })}
          isMulti={isMulti}
          classNamePrefix="cmp-select"
          menuPlacement="auto"
          closeMenuOnSelect={!isMulti}
          menuPosition={menuPosition}
          onBlur={handleSelectBlur}
          name={name}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: COLOR_CODE.PRIMARY,
              neutral20: COLOR_CODE.DISABLED,
              primary50: COLOR_CODE.PRIMARY_100,
            },
          })}
          styles={{
            singleValue: (provided) => ({
              ...provided,
              color: COLOR_CODE.BLACK_500,
            }),
          }}
          components={{
            DropdownIndicator: isDisabled ? () => null : DropdownIndicator,
            IndicatorSeparator: isDisabled ? () => null : IndicatorSeparator,
            Control: labelControl
              ? ControlWithLabel
              : hideSearchIcon
              ? ControlNoSearchIcon
              : Control,
            Option,
          }}
          onInputChange={onInputChange}
          menuPortalTarget={document.querySelector('#root')}
          {...props}
        />
      </View>
    </Element>
  );
};

export default SelectCmp;
