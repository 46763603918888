import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { createResponseWrapper } from '../helpers';
import { ExpenditureVoucherInformation, PreparePayload } from './types';

export function usePrepareExpenditureVouchers(
  options?: UseMutationOptions<any, Error, ExpenditureVoucherInformation>
) {
  const { mutate: prepareExpenditureVouchers, isLoading } = useMutation<any, Error, any>({
    mutationFn: (payload: PreparePayload) =>
      createResponseWrapper(apiClient.prepareExpenditureVouchers, [payload]),
    onError: (error) => ErrorService.handler(error),
    ...options,
  });

  return {
    prepareExpenditureVouchers,
    isLoading,
  };
}
