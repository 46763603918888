import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { createResponseWrapper } from '../helpers';
import { TDI15 } from './types';

export function useCreateTDI15(options?: UseMutationOptions<TDI15, Error, TDI15>) {
  const { mutate: createTDI15, isLoading } = useMutation<TDI15, Error, TDI15>({
    mutationFn: (payload: TDI15) => createResponseWrapper(apiClient.createTDI15, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    createTDI15,
    isLoading,
  };
}
