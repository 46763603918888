import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { ApiResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { toTDI62RelationshipData } from './helpers';
import { TDI62RelatedRelationship } from './types';

export function useGetTDI62RelatedRelationship(
  options?: UseQueryOptions<
    ApiResponseType<{ tdi62: TDI62RelatedRelationship }>,
    Error,
    TDI62RelatedRelationship
  > & {
    id: TDI62RelatedRelationship['id'];
  }
) {
  const {
    data: tdi62Relationships,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetTDI62RelationById,
  } = useQuery<
    ApiResponseType<{ tdi62: TDI62RelatedRelationship }>,
    Error,
    TDI62RelatedRelationship
  >([API_QUERIES.TDI_62_RELATIONSHIP, { id: options.id }], {
    queryFn: (query) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...params] = query.queryKey;
      return responseWrapper<ApiResponseType<{ tdi62: TDI62RelatedRelationship }>>(
        apiClient.getTDI62RelatedRelationships,
        params
      );
    },
    select: ({ data }) => toTDI62RelationshipData(data.tdi62),
    enabled: !!options.id,
    ...options,
  });

  const queryClient = useQueryClient();

  const handleInvalidateTDI62Relationship = () =>
    queryClient.invalidateQueries([API_QUERIES.TDI_62_RELATIONSHIP, { id: options.id }]);

  return {
    tdi62Relationships,
    error,
    isError,
    isLoading,
    onGetTDI62RelationById,
    handleInvalidateTDI62Relationship,
  };
}
