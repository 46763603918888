import { Toastify } from 'src/services';
import { formatDateTime } from 'src/utils';
import { userInfoKey } from './RelatedAccountContact/types';
import { AuditKey } from './keys';
import { AddressInfoKey } from './types';

type ApiCall = (...args: any[]) => Promise<any>;

export async function responseNoBody<T>(func: ApiCall, [...args]: any[] = []): Promise<T> {
  return new Promise(async (res, rej) => {
    try {
      const response = (await func(...args)) || {};
      if (response?.originalError?.message === 'CONNECTION_TIMEOUT') {
        Toastify.error('Connection timeout. Please check your network and try again.');
      } else if (199 < response.status && response.status < 300) {
        res(response);
      } else {
        rej(response);
      }
    } catch (err) {
      rej(err);
    }
  });
}

export async function responseWrapper<T>(func: ApiCall, [...args]: any[] = []): Promise<T> {
  return new Promise(async (res, rej) => {
    try {
      const response = (await func(...args)) || {};
      if (response.ok) res(response.data);
      if (response?.originalError?.message === 'CONNECTION_TIMEOUT') {
        Toastify.error('Connection timeout. Please check your network and try again.');
      }
      rej(response.data);
    } catch (err) {
      rej(err);
    }
  });
}

export async function apiResponseWrapper<T>(func: ApiCall, [...args]: any[] = []): Promise<T> {
  return new Promise(async (res, rej) => {
    try {
      const response = (await func(...args)) || {};
      if (response.ok) res(response.user);
      if (response?.originalError?.message === 'CONNECTION_TIMEOUT') {
        Toastify.error('Connection timeout. Please check your network and try again.');
      }
      rej(response.user);
    } catch (err) {
      rej(err);
    }
  });
}

export async function createResponseWrapper<T>(func: ApiCall, [...args]: any[] = []): Promise<T> {
  return new Promise(async (res, rej) => {
    try {
      const response = (await func(...args)) || {};
      if (response?.ok) {
        res(response?.data?.data);
      } else {
        const message = response?.data?.message?.map
          ? response?.data?.message?.map((item: string) => item)?.join(', ')
          : response?.data?.message;
        rej({ message });
      }
    } catch (err) {
      rej(err);
    }
  });
}

export async function authResponseWrapper<T>(func: ApiCall, [...args]: any[] = []): Promise<T> {
  return new Promise(async (res, rej) => {
    try {
      const response = (await func(...args)) || {};
      if (!response?.ok) {
        rej(response?.data);
      } else {
        res(response);
      }
    } catch (err) {
      rej(err);
    }
  });
}

export async function languageResponseWrapper<T>(func: ApiCall, [...args]: any[] = []): Promise<T> {
  return new Promise(async (res, rej) => {
    try {
      const response = (await func(...args)) || {};
      res(response);
    } catch (err) {
      rej(err);
    }
  });
}

export const getResponseData = (data: { data: any }) => data.data;

export interface ApiResponseType<T> {
  data: T;
  code: number;
  success: boolean;
  timestamp: string;
}

export interface PaginationResponseType<T> {
  data: T[];
  payloadSize?: number;
  hasNext?: boolean;
  totalRecords?: number;
}

export interface ApiPaginationResponseType<T> {
  data: PaginationResponseType<T>;
  code?: number;
  success?: boolean;
  timestamp?: string;
  query?: Object;
}

export const formatAddress = (data, addressKeys) => {
  if (!data) return;
  const address = addressKeys
    // eslint-disable-next-line security/detect-object-injection
    .map((key) => data[key] && `${data[key]}`)
    .filter(Boolean)
    .join(', ');
  return address;
};

const FULL_ADDRESS_KEYS = [
  AddressInfoKey.Street,
  AddressInfoKey.District,
  AddressInfoKey.City,
  AddressInfoKey.State,
  AddressInfoKey.Zip,
  AddressInfoKey.County,
  AddressInfoKey.Country,
];

const SHORT_ADDRESS_KEYS = [
  AddressInfoKey.Street,
  AddressInfoKey.City,
  AddressInfoKey.State,
  AddressInfoKey.Zip,
];

export const toAddress = (data) => formatAddress(data, FULL_ADDRESS_KEYS);

export const toShortAddress = (data) => formatAddress(data, SHORT_ADDRESS_KEYS);

export const toContactName = (data) => {
  return [
    data[userInfoKey.Salutation],
    data[userInfoKey.FirstName],
    data[userInfoKey.MiddleInitial],
    data[userInfoKey.LastName],
    data[userInfoKey.Suffix],
  ]
    .filter((item) => item)
    .join(' ');
};

export const toAuditData = (data) => ({
  [AuditKey.CreatedBy]: `${data?.createdUser?.firstName} ${data?.createdUser?.lastName}`,
  [AuditKey.UpdatedBy]: data?.updatedUser
    ? `${data?.updatedUser?.firstName} ${data?.updatedUser?.lastName}`
    : '',
});

export const toRelatedAuditData = (data) => ({
  [AuditKey.CreatedUser]: `${data?.createdUser?.firstName} ${
    data?.createdUser?.lastName
  }, ${formatDateTime(data?.createdAt)}`,
  [AuditKey.UpdatedUser]: data?.updatedUser
    ? `${data?.updatedUser?.firstName} ${data?.updatedUser?.lastName}, ${formatDateTime(
        data?.updatedAt
      )}`
    : '',
});
