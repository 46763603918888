import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { GLAccount } from './types';

export function useUpdateGLAccount(options?: UseMutationOptions<GLAccount, Error, GLAccount>) {
  const {
    mutate: onUpdateGLAccount,
    isLoading: isUpdating,
    isSuccess,
    isError,
    error,
  } = useMutation<GLAccount, Error, GLAccount>({
    mutationFn: (payload: GLAccount) => responseWrapper(apiClient.updateGLAccount, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    onUpdateGLAccount,
    isUpdating,
    isSuccess,
    isError,
    error,
  };
}
