import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { GetPropertiesParams } from 'src/queries/types';
import { toBenefitPaymentsTableData } from './helpers';
import { BenefitPaymentsType } from './types';

export function useGetAllBenefitPayments(
  options?: UseQueryOptions<ApiResponseType<BenefitPaymentsType>, Error> & {
    wc3Id?: string;
  }
) {
  const [params, setParams] = useState<GetPropertiesParams>({});

  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetAllBenefitPayments,
  } = useQuery<ApiResponseType<BenefitPaymentsType>, Error>(
    [API_QUERIES.BENEFIT_PAYMENTS, { ...params, wc3Id: options?.wc3Id }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<BenefitPaymentsType>>(
          apiClient.getAllBenefitPayments,
          params
        );
      },
      select: (data) => toBenefitPaymentsTableData(data),
      notifyOnChangeProps: ['data', 'isFetching'],
      enabled: true,
      keepPreviousData: true,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateAllBenefitPayments = () =>
    queryClient.invalidateQueries(API_QUERIES.BENEFIT_PAYMENTS);

  const { data: bPData } = data || {};

  return {
    bPData,
    error,
    isError,
    isFetching,
    onGetAllBenefitPayments,
    setParams,
    handleInvalidateAllBenefitPayments,
  };
}
