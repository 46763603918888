import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { getAwardSummary } from './helpers';
import { AwardSummary } from './types';

export function useGetAwardSummary(
  options?: UseQueryOptions<ApiResponseType<{ awardSummary: any }>, Error, AwardSummary> & {
    id: string;
  }
) {
  const {
    data: awardSummary,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetAwardSummary,
  } = useQuery<ApiResponseType<{ awardSummary: any }>, Error, AwardSummary>(
    [API_QUERIES.AWARD_SUMMARIES, { ...options }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<{ awardSummary: any }>>(
          apiClient.getAwardSummary,
          params
        );
      },
      enabled: !!options.id,
      select: ({ data }) => getAwardSummary(data.awardSummary),
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateAwardSummary = () =>
    queryClient.invalidateQueries([API_QUERIES.AWARD_SUMMARIES, { ...options }]);

  return {
    awardSummary,
    error,
    isError,
    isLoading,
    onGetAwardSummary,
    handleInvalidateAwardSummary,
  };
}
