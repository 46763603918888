export enum CaseSettlementDetailRelatedKey {
  CaseNumber = 'caseNumber',
  CreatedUser = 'createdUser',
  AssignTo = 'assignTo',
  AssignId = 'assignId',
  CreatedAt = 'createdAt',
  Status = 'status',
  AssignedToGroupName = 'assignedToGroupName',
  ApprovalReviewedBy = 'approvalReviewedBy',
  CaseId = 'caseId',
  ReasonForRejection = 'reasonForRejection',
  ApprovalDecision = 'approvalDecision',
  Type = 'type',
  InvolvesSCF = 'isInvolvesSCF',
  TimeTaken = 'timeTaken',
  UpdatedAt = 'updatedAt',
  ApprovedDate = 'approvedDate',
  ReceiveDate = 'receivedDate',
  CaseSettlementID = 'caseSettlementID',
  CaseSettlementNumber = 'caseSettlementNumber',
  LastModifiedDate = 'lastModifiedDate',
  CreatedDate = 'createdDate',
  LastModifiedByUser = 'lastModifiedByUser',
  CreatedByUser = 'createdByUser',
  UpdateBy = 'updateBy',
  DecisionId = 'decisionId',
  Id = 'id',
  UpdatedUser = 'updatedUser',
}
