import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { ApiResponseType, PaginationResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { GetPropertiesParams } from '../types';
import { toApprovedHealthCarePlansReport } from './helpers';
import { ApprovedHealthCarePlansReport, ApprovedHealthCarePlansTotal } from './types';

export function useGetApprovedHealthCarePlansReport(
  options?: UseQueryOptions<
    ApiResponseType<{
      approvedHCPReport: PaginationResponseType<ApprovedHealthCarePlansReport>;
      totalPlanType: ApprovedHealthCarePlansTotal;
    }>,
    Error
  >
) {
  const [params, setParams] = useState<GetPropertiesParams>({});

  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetApprovedHealthCarePlansReport,
  } = useQuery<
    ApiResponseType<{
      approvedHCPReport: PaginationResponseType<ApprovedHealthCarePlansReport>;
      totalPlanType: ApprovedHealthCarePlansTotal;
    }>,
    Error
  >([API_QUERIES.APPROVED_HEALTH_CARE_PLANS, { ...params, ...options }], {
    queryFn: (query) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...params] = query.queryKey;
      return responseWrapper<
        ApiResponseType<{
          approvedHCPReport: PaginationResponseType<ApprovedHealthCarePlansReport>;
          totalPlanType: ApprovedHealthCarePlansTotal;
        }>
      >(apiClient.getApprovedHealthCarePlansReport, params);
    },
    onError: ErrorService.handler,
    notifyOnChangeProps: ['data', 'isFetching'],
    keepPreviousData: true,
    select: (data) => toApprovedHealthCarePlansReport(data),
    ...options,
  });

  const queryClient = useQueryClient();

  const handleInvalidateApprovedHealthCarePlansReport = () =>
    queryClient.invalidateQueries(API_QUERIES.APPROVED_HEALTH_CARE_PLANS);

  const {
    data: approvedHealthCarePlansReport,
    hasNext,
    payloadSize,
    totalRecords,
  } = data?.data?.approvedHCPReport || {};

  return {
    approvedHealthCarePlansReport,
    total: data?.data?.totalPlanType || {},
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isError,
    isFetching,
    onGetApprovedHealthCarePlansReport,
    setParams,
    handleInvalidateApprovedHealthCarePlansReport,
  };
}
