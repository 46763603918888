import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { ApiResponseType, getResponseData, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { GetPropertiesParams } from '../types';
import { PROFILE_USER_ID } from './helpers';
import { AssignedUsersResponse } from './types';

export function useGetProfileAssignedUsers(
  options?: UseQueryOptions<
    ApiResponseType<AssignedUsersResponse>,
    Error,
    AssignedUsersResponse
  > & {
    id: string;
  }
) {
  const [params, setParams] = useState<GetPropertiesParams>({});
  const {
    data,
    error,
    isError,
    isFetching,
    isLoading,
    refetch: onGetAssignedUsers,
  } = useQuery<ApiResponseType<AssignedUsersResponse>, Error, AssignedUsersResponse>(
    [API_QUERIES.ASSIGNED_USERS, { ...params, id: options.id }],
    {
      queryFn: ({ queryKey }) => {
        const params = queryKey.at(-1);
        return responseWrapper<ApiResponseType<AssignedUsersResponse>>(apiClient.getAssignedUsers, [
          params,
        ]);
      },
      select: (data) => getResponseData(data),
      enabled: !!options.id,
      keepPreviousData: true,
      notifyOnChangeProps: ['data', 'isFetching'],
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateAssignedUsers = () =>
    queryClient.invalidateQueries([API_QUERIES.ASSIGNED_USERS, { id: options.id }]);

  const { profile, assignedUsers } = data || {};

  const { totalRecords, data: assignedUsersData } = assignedUsers || {};
  const { name, id: profileId } = profile || {};

  const isUserProfile = profileId === PROFILE_USER_ID;

  return {
    totalRecords,
    name,
    isUserProfile,
    assignedUsersData,
    error,
    isError,
    isLoading,
    isFetching,
    onGetAssignedUsers,
    setParams,
    handleInvalidateAssignedUsers,
  };
}
