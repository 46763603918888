import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { authResponseWrapper } from '../helpers';

export function useDeleteComplaint(options?: UseMutationOptions<string, Error, string>) {
  const { mutate: deleteComplaint, isLoading } = useMutation<string, Error, string>({
    mutationFn: (payload: string) => authResponseWrapper(apiClient.deleteComplaint, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    deleteComplaint,
    isLoading,
  };
}
