import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { GetPropertiesParams } from 'src/queries/types';
import { toPeriodsOfTPDData } from './helpers';
import { PeriodsOfTPDResponse } from './types';

export function useGetAllPeriodsOfTPD(
  options?: UseQueryOptions<ApiResponseType<PeriodsOfTPDResponse>, Error, PeriodsOfTPDResponse> & {
    id: string;
  }
) {
  const [params, setParams] = useState<GetPropertiesParams>({});
  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetAllPeriodsOfTPD,
  } = useQuery<ApiResponseType<PeriodsOfTPDResponse>, Error, PeriodsOfTPDResponse>(
    [API_QUERIES.AWARD_WORKSHEET_PERIODS_OF_TPD, { ...params, id: options.id }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<PeriodsOfTPDResponse>>(
          apiClient.getAllPeriodsOfTpd,
          params
        );
      },
      select: (data) => toPeriodsOfTPDData(data?.data),
      notifyOnChangeProps: ['data', 'isFetching'],
      keepPreviousData: true,
      enabled: !!options.id,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidatePeriodsOfTpd = () =>
    queryClient.invalidateQueries(API_QUERIES.AWARD_WORKSHEET_PERIODS_OF_TPD);

  const { periodsOfTpdPagination, totalPeriodAmount, totalNumberOfWeeks } = data || {};

  const { data: periodsOfTPDs, hasNext, payloadSize, totalRecords } = periodsOfTpdPagination || {};

  return {
    periodsOfTPDs,
    hasNext,
    payloadSize,
    totalRecords,
    totalPeriodAmount,
    totalNumberOfWeeks,
    error,
    isError,
    isFetching,
    onGetAllPeriodsOfTPD,
    setParams,
    handleInvalidatePeriodsOfTpd,
  };
}
