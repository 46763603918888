import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { createResponseWrapper } from '../helpers';
import { UpLoadFormValue } from './types';

export function useSubmitFileUploadForm(
  options?: UseMutationOptions<UpLoadFormValue, Error, UpLoadFormValue>
) {
  const {
    mutate: submitFileUploadForm,
    isLoading,
    isSuccess,
  } = useMutation<UpLoadFormValue, Error, UpLoadFormValue>({
    mutationFn: (payload: UpLoadFormValue) =>
      createResponseWrapper(apiClient.submitUploadFile, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    submitFileUploadForm,
    isLoading,
    isSuccess,
  };
}
