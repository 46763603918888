import { useMemo, useState } from 'react';
import { UseInfiniteQueryOptions, useInfiniteQuery, useQueryClient } from 'react-query';
import { useDebounce } from 'src/hooks';
import { isEmpty } from 'src/validations';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { HRSHAR } from './types';

const pageParam = {
  take: 10,
  skip: 0,
};

export function useLazyHRSs(
  options?: UseInfiniteQueryOptions<{ data: HRSHAR[] }, Error> & {
    hearingId: string;
  }
) {
  const [inputSearch, setInputSearch] = useState<string>('');
  const debounceSearch = useDebounce(inputSearch);

  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetAllHRSs,
    fetchNextPage,
  } = useInfiniteQuery<{ data: HRSHAR[] }, Error>(
    [API_QUERIES.HRS_HAR, pageParam, debounceSearch, { hearingId: options?.hearingId }],
    (props): Promise<{ data: HRSHAR[] }> => {
      const { pageParam, queryKey } = props;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const search = queryKey.at(-2);
      const options = queryKey.at(-1);

      return responseWrapper<{ data: HRSHAR[] }>(apiClient.getAllHRSs, [
        { ...pageParam, search, ...(options as Object) },
      ]);
    },
    {
      keepPreviousData: true,
      getNextPageParam(lastPage, allPages) {
        if (lastPage?.data?.length < 10) return undefined;
        return {
          take: 10,
          skip: allPages?.length * 10,
        };
      },
      notifyOnChangeProps: ['data', 'isFetching'],
      ...options,
    }
  );

  const hrsHars = useMemo(() => {
    if (isEmpty(data)) return [];
    return data.pages.reduce((state, page) => [...state, ...page.data.map((hrsHar) => hrsHar)], []);
  }, [data]);

  const queryClient = useQueryClient();

  const handleInvalidateLazyHRSs = () =>
    queryClient.resetQueries([
      API_QUERIES.HRS_HAR,
      pageParam,
      debounceSearch,
      { hearingId: options?.hearingId },
    ]);

  return {
    hrsHars,
    error,
    isError,
    isFetching,
    onGetAllHRSs,
    fetchNextPage,
    setInputSearch,
    handleInvalidateLazyHRSs,
  };
}
