import { Skeleton } from '@mui/material';
import React, { Suspense } from 'react';
import { SearchableSelectType } from 'src/components/common/Table/helpers';

const SearchableValueAccount = React.lazy(() => import('./SearchableValueAccount'));
const SearchableValueTDI21 = React.lazy(() => import('./SearchableValueTDI21'));
const SearchableValueDecision = React.lazy(() => import('./SearchableValueDecision'));
const SearchableValueHearing = React.lazy(() => import('./SearchableValueHearing'));
const SearchableValueAwardWorksheet = React.lazy(() => import('./SearchableValueAwardWorksheet'));
const SearchableValueProfile = React.lazy(() => import('./SearchableValueProfile'));
const SearchableValueUser = React.lazy(() => import('./SearchableValueUser'));
const SearchableValueDCDCase = React.lazy(() => import('./SearchableValueDCDCase'));
const SearchableValueRoom = React.lazy(() => import('./SearchableValueRoom'));
const SearchableValueTDI62 = React.lazy(() => import('./SearchableValueTDI62'));
const SearchableValueTDI15 = React.lazy(() => import('./SearchableValueTDI15'));
const SearchableValueTdiPlan = React.lazy(() => import('./SearchableValueTdiPlan'));
const SearchableValueTDI30 = React.lazy(() => import('./SearchableValueTDI30'));
const SearchableValueTDI46 = React.lazy(() => import('./SearchableValueTDI46'));
const SearchableValueTDICase = React.lazy(() => import('./SearchableValueTDICase'));
const SearchableValueTDISFCase = React.lazy(() => import('./SearchableValueTDISFCase'));
const SearchableValueContact = React.lazy(() => import('./SearchableValueContact'));
const SearchableValueWC1 = React.lazy(() => import('./SearchableValueWC1'));
const SearchableValueGLAccount = React.lazy(() => import('./SearchableValueGLAccount'));
const SearchableValueHC4 = React.lazy(() => import('./SearchableValueHC4'));
const SearchableValueHealthCarePlan = React.lazy(() => import('./SearchableValueHealthCarePlan'));
const SearchableValueHC15 = React.lazy(() => import('./SearchableValueHC15'));
const SearchableValueHC61 = React.lazy(() => import('./SearchableValueHC61'));
const SearchableValueRequest = React.lazy(() => import('./SearchableValueRequest'));
const SearchableValueDisbursement = React.lazy(() => import('./SearchableValueDisbursement'));
const SearchableValueComplaint = React.lazy(() => import('./SearchableValueComplaint'));
const SearchableSelectWC77 = React.lazy(() => import('./SearchableValueWC77'));

const SearchableValue: React.FC<Props> = ({ values, type }) => {
  const renderByType = () => {
    switch (type) {
      case SearchableSelectType.User:
        return <SearchableValueUser values={values} />;
      case SearchableSelectType.Profile:
        return <SearchableValueProfile values={values} />;
      case SearchableSelectType.Account:
      case SearchableSelectType.Carrier:
      case SearchableSelectType.AdjustingCompany:
      case SearchableSelectType.Employer:
      case SearchableSelectType.Physician:
      case SearchableSelectType.Claimant:
      case SearchableSelectType.Vendor:
      case SearchableSelectType.ComplainantAccount:
        return <SearchableValueAccount values={values} />;
      case SearchableSelectType.DCDCase:
        return <SearchableValueDCDCase values={values} />;
      case SearchableSelectType.WC1:
        return <SearchableValueWC1 values={values} />;
      case SearchableSelectType.Contact:
      case SearchableSelectType.ContactOfEmployer:
      case SearchableSelectType.ContactOfAdjustingCompany:
        return <SearchableValueContact values={values} />;
      case SearchableSelectType.Room:
        return <SearchableValueRoom values={values} />;
      case SearchableSelectType.TDI62:
        return <SearchableValueTDI62 values={values} />;
      case SearchableSelectType.TDI15:
        return <SearchableValueTDI15 values={values} />;
      case SearchableSelectType.TDI21:
        return <SearchableValueTDI21 values={values} />;
      case SearchableSelectType.TDIPlan:
        return <SearchableValueTdiPlan values={values} />;
      case SearchableSelectType.TDI46:
        return <SearchableValueTDI46 values={values} />;
      case SearchableSelectType.TDI30:
        return <SearchableValueTDI30 values={values} />;
      case SearchableSelectType.TDICase:
        return <SearchableValueTDICase values={values} />;
      case SearchableSelectType.AwardWorksheet:
        return <SearchableValueAwardWorksheet values={values} />;
      case SearchableSelectType.Decision:
        return <SearchableValueDecision values={values} />;
      case SearchableSelectType.TDIDecision:
        return <SearchableValueDecision values={values} />;
      case SearchableSelectType.Hearing:
        return <SearchableValueHearing values={values} />;
      case SearchableSelectType.HC4:
        return <SearchableValueHC4 values={values} />;
      case SearchableSelectType.HCP:
        return <SearchableValueHealthCarePlan values={values} />;
      case SearchableSelectType.HC15:
        return <SearchableValueHC15 values={values} />;
      case SearchableSelectType.HC61:
        return <SearchableValueHC61 values={values} />;
      case SearchableSelectType.Request:
        return <SearchableValueRequest values={values} />;
      case SearchableSelectType.TDISFCase:
        return <SearchableValueTDISFCase values={values} />;
      case SearchableSelectType.GLAccount:
        return <SearchableValueGLAccount values={values} />;
      case SearchableSelectType.Disbursement:
        return <SearchableValueDisbursement values={values} />;
      case SearchableSelectType.Complaint:
        return <SearchableValueComplaint values={values} />;
      case SearchableSelectType.WC77:
        return <SearchableSelectWC77 values={values} />;
    }
  };

  return <Suspense fallback={<Skeleton />}>{renderByType()}</Suspense>;
};

type Props = {
  values: Array<string>;
  type: SearchableSelectType;
};

export default SearchableValue;
