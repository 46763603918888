import { Breadcrumbs } from '@mui/material';
import React from 'react';
import { Navigator } from 'src/services';
import { Link, Text } from '..';
import './styles.scss';

const Breadcrumb: React.FC<Props> = ({ items }) => {
  return (
    <Breadcrumbs aria-label="breadcrumb">
      {items.map((item, idx) =>
        item.href ? (
          <Link
            color="inherit"
            onClick={() => Navigator.navigate(item.href)}
            className="cmp-breadcrumbs__link"
            key={idx}
          >
            {item.title}
          </Link>
        ) : (
          <Text className="cmp-breadcrumbs__text" key={idx}>
            {item.title}
          </Text>
        )
      )}
    </Breadcrumbs>
  );
};

type Props = {
  items: {
    title: string;
    href?: string;
  }[];
};

export default Breadcrumb;
