import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { createResponseWrapper } from 'src/queries/helpers';
import { Toastify } from 'src/services';
import { WC3AFormPayload } from './types';

export function useCreateWC3AForm(options?: UseMutationOptions<any, Error, WC3AFormPayload>) {
  const { mutate: createWC3AForm, isLoading } = useMutation<any, Error, WC3AFormPayload>({
    mutationFn: (payload: WC3AFormPayload) =>
      createResponseWrapper(apiClient.createWC3AForm, [payload]),
    onError: ({ message }) => Toastify.error(message),
    ...options,
  });

  return {
    createWC3AForm,
    isLoading,
  };
}
