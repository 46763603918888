export enum VocationalRehabilitationKey {
  Id = 'id',
  CaseId = 'caseId',
  CaseNumber = 'caseNumber',
  Comment = 'comments',
  ClaimantName = 'claimantName',
  ClaimantPhoneNumber = 'claimantPhoneNumber',
  ClaimantAddress = 'claimantAddress',
  DateOfInjury = 'dateOfInjury',
  ProcessNumber = 'processNumber',
  ReferralDate = 'referralDate',
  StatusDate = 'statusDate',
  Status = 'status',
  IsActiveObjection = 'isActiveObjection',
  WCRP23Received = 'wcrp23ReceivedDate',
  WCRP24Received = 'wcrp24ReceivedDate',
  WCRP3AReceived = 'wcrp3aReceivedDate',
  WCRP14Received = 'wcrp14ReceivedDate',
  Case = 'case',
  CreatedUser = 'createdUser',
  UpdatedUser = 'updatedUser',
  CreatedBy = 'createdBy',
  UpdatedBy = 'updatedBy',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  CreatedByName = 'createdByName',
  UpdatedByName = 'updatedByName',
  IsActiveReferralObjection = 'isActiveReferralObjection',
  ReferralObjectionReceivedDate = 'referralObjectionReceivedDate',
  ReferralDeterminationSentDate = 'referralDeterminationSentDate',
  Comments = 'comments',
  PlanEstimatedStartDate = 'planEstimatedStartDate',
  PlanEstimatedEndDate = 'planEstimatedEndDate',
  PlanDeterminationSentDate = 'planDeterminationSentDate',
  IsActivePlanObjection = 'isActivePlanObjection',
  VrClosedDate = 'vrClosedDate',
  IsActiveClosureObjection = 'isActiveClosureObjection',
  ClosureObjectionReceivedDate = 'closureObjectionReceivedDate',
  ClosureDeterminationSentDate = 'closureDeterminationSentDate',
}
