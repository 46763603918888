export enum FieldType {
  Available = 'Available',
  Selected = 'Selected',
}

export const columns = [
  {
    id: FieldType.Available,
    name: 'Available Fields',
  },
  {
    id: FieldType.Selected,
    name: 'Selected Fields',
  },
];
