import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { ApiResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { ExistedPartiesHearing, PartyNameAndAddressKey } from './types';

export function useGetExistedParties(
  options?: UseQueryOptions<
    ApiResponseType<ExistedPartiesHearing>,
    Error,
    ExistedPartiesHearing
  > & { id: string }
) {
  const {
    data: existedParties,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetExistedParties,
  } = useQuery<ApiResponseType<ExistedPartiesHearing>, Error, ExistedPartiesHearing>(
    [API_QUERIES.RELATED_PARTY_NAMES_ADDRESS, { id: options.id }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<ExistedPartiesHearing>>(
          apiClient.getExistedParties,
          params
        );
      },
      select: (data) => data.data,
      enabled: !!options.id,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidatePartyNamesAddress = () =>
    queryClient.invalidateQueries([API_QUERIES.RELATED_PARTY_NAMES_ADDRESS, { id: options?.id }]);

  return {
    existedParties: existedParties?.[PartyNameAndAddressKey.CasePartyIds],
    error,
    isError,
    isLoading,
    onGetExistedParties,
    handleInvalidatePartyNamesAddress,
  };
}
