import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { toWC14Detail } from 'src/queries/WC14s/helpers';
import apiClient from '../apiClient';
import { ApiResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { WC14 } from './types';

export function useGetWC14(
  options?: UseQueryOptions<ApiResponseType<{ form: WC14 }>, Error, WC14> & {
    id: WC14['id'];
  }
) {
  const {
    data: wc14,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetWC14ById,
  } = useQuery<ApiResponseType<{ form: WC14 }>, Error, WC14>([API_QUERIES.WC14s, { ...options }], {
    queryFn: (query) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...params] = query.queryKey;
      return responseWrapper<ApiResponseType<{ form: WC14 }>>(apiClient.getWC14Detail, params);
    },
    select: ({ data }) => toWC14Detail(data.form),
    enabled: !!options.id,
    ...options,
  });

  const queryClient = useQueryClient();

  const handleInvalidateWC14 = () =>
    queryClient.invalidateQueries([API_QUERIES.WC14s, { ...options }]);

  return {
    wc14,
    error,
    isError,
    isLoading,
    onGetWC14ById,
    handleInvalidateWC14,
  };
}
