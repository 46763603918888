import { useMutation, UseMutationOptions } from 'react-query';
import { Toastify } from 'src/services';
import apiClient from '../apiClient';
import { createResponseWrapper } from '../helpers';
import { RelatedAccountContactPayload } from './types';

export function useCreateRelatedAccountContact(
  options?: UseMutationOptions<RelatedAccountContactPayload, Error, RelatedAccountContactPayload>
) {
  const { mutate: createRelatedAccountContact, isLoading } = useMutation<
    RelatedAccountContactPayload,
    Error,
    RelatedAccountContactPayload
  >({
    mutationFn: (payload: RelatedAccountContactPayload) =>
      createResponseWrapper(apiClient.createRelatedAccountContact, [payload]),
    onError: ({ message }) => Toastify.error(message),
    ...options,
  });

  return {
    createRelatedAccountContact,
    isLoading,
  };
}
