import { formatTDICaseNumber, getFirstLastName } from 'src/utils';
import { isEmpty } from 'src/validations';
import { HearingKey } from '../Hearings';
import { PaginationResponseType, toRelatedAuditData } from '../helpers';
import { RelatedHearing } from './types';

export const getClaimantInfo = (relatedHearing, infoType) => {
  if (isEmpty(relatedHearing)) return '';

  if (relatedHearing?.caseId) {
    const claimant = relatedHearing?.case?.claimant;
    return infoType === HearingKey.ClaimantName ? claimant?.accountName : claimant?.ssn || '';
  }

  if (relatedHearing?.tdiCaseId) {
    const claimant = relatedHearing?.tdiCase?.claimant;
    return infoType === HearingKey.ClaimantName ? claimant?.accountName || '' : claimant?.ssn || '';
  }

  if (relatedHearing?.tdiSFCaseId) {
    const claimant = relatedHearing?.tdiSFCase?.claimant;
    return infoType === HearingKey.ClaimantName ? claimant?.accountName || '' : claimant?.ssn || '';
  }
};

export const getClaimantId = (relatedHearing) => {
  if (isEmpty(relatedHearing)) return '';

  const caseId = relatedHearing?.case?.claimant?.id;
  const tdiCaseId = relatedHearing?.tdiCase?.claimant?.id;
  const tdiSFCaseId = relatedHearing?.tdiSFCase?.claimant?.id;

  return caseId || tdiCaseId || tdiSFCaseId;
};

export const toRelatedHearings = (
  data: PaginationResponseType<any>
): PaginationResponseType<RelatedHearing> => ({
  ...data,
  data: data.data.map((relatedHearing) => {
    return {
      ...relatedHearing,
      [HearingKey.DateOfII]: relatedHearing?.dateOfInjury ? relatedHearing?.dateOfInjury : '',
      [HearingKey.CaseNumber]: relatedHearing?.caseId ? relatedHearing?.case?.caseNumber : '',
      [HearingKey.EmployerId]: relatedHearing?.caseId ? relatedHearing?.case?.employerId : '',
      [HearingKey.OriginalLocation]: relatedHearing?.caseId
        ? relatedHearing?.case?.originalLocation
        : '',
      [HearingKey.CurrentServicingLocation]: relatedHearing?.caseId
        ? relatedHearing?.case?.currentServicingLocation
        : '',
      [HearingKey.Room]: relatedHearing?.roomId ? relatedHearing?.room?.roomName : '',
      [HearingKey.ClaimantId]: getClaimantId(relatedHearing),
      [HearingKey.HearingOfficer]: relatedHearing?.hearingOfficerId
        ? getFirstLastName(relatedHearing?.hearingOfficer)
        : '',
      [HearingKey.HearingReviewer]: relatedHearing?.hearingReviewerId
        ? getFirstLastName(relatedHearing?.hearingReviewer)
        : '',
      [HearingKey.HearingScheduler]: relatedHearing?.hearingSchedulerId
        ? getFirstLastName(relatedHearing?.hearingScheduler)
        : '',
      [HearingKey.HearingFacilitator]: relatedHearing?.hearingFacilitatorId
        ? getFirstLastName(relatedHearing?.hearingFacilitator)
        : '',
      [HearingKey.ClericalService]: relatedHearing?.clericalService
        ? getFirstLastName(relatedHearing?.clericalService)
        : '',
      [HearingKey.RefereeManager]: relatedHearing?.refereeManagerId
        ? getFirstLastName(relatedHearing?.refereeManager)
        : '',
      [HearingKey.CreatedUser]: relatedHearing?.createdUser
        ? getFirstLastName(relatedHearing?.createdUser)
        : '',
      [HearingKey.UpdatedUser]: relatedHearing?.updatedUser
        ? getFirstLastName(relatedHearing?.updatedUser)
        : '',
      [HearingKey.ClaimantName]: getClaimantInfo(relatedHearing, HearingKey.ClaimantName),
      [HearingKey.ClaimantSSN]: getClaimantInfo(relatedHearing, HearingKey.ClaimantSSN),

      // =============TDI Cases===============
      [HearingKey.TDICaseNumber]: relatedHearing?.tdiCase?.tdiCaseNumber,
      [HearingKey.FirstDateOfDisabilityClaimed]:
        relatedHearing?.tdiCase?.firstDateOfDisabilityClaimed,
      [HearingKey.IsInvolvesSpecialFund]: relatedHearing?.isInvolvesSpecialFund,
      [HearingKey.ReasonHearingIsScheduled]: relatedHearing?.reasonHearingIsScheduled,
      [HearingKey.StateInPlainLanguageTheIssues]: relatedHearing?.stateInPlainLanguageTheIssues,
      [HearingKey.RefereeUser]: relatedHearing?.referee
        ? getFirstLastName(relatedHearing?.referee)
        : '',
      [HearingKey.RefereeUserId]: relatedHearing?.refereeId,

      // =============TDI SFCases===============
      [HearingKey.TDISFCaseId]: relatedHearing?.[HearingKey.TDISFCaseId],
      [HearingKey.TDISFCaseNumber]: formatTDICaseNumber(
        relatedHearing?.[HearingKey.TDISFCase]?.[HearingKey.TDISFCaseNumber]
      ),
      [HearingKey.DisabilityDate]:
        relatedHearing?.[HearingKey.TDISFCase]?.[HearingKey.DisabilityDate],
      ...toRelatedAuditData(relatedHearing),
    };
  }),
});
