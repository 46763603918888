import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { responseNoBody } from '../helpers';
import { FileUploadPayload } from './types';

export function useUploadFileToS3(
  options?: UseMutationOptions<FileUploadPayload, Error, any> & {
    onUploadFileToS3Success: (data?: any, variables?: FileUploadPayload, context?: unknown) => void;
  }
) {
  const {
    mutate: uploadFile,
    isSuccess,
    isLoading,
  } = useMutation<FileUploadPayload, Error, any>({
    mutationFn: (payload: FileUploadPayload) => {
      return responseNoBody<any>(apiClient.uploadFile, [payload]);
    },
    onError(error, variables, context) {
      ErrorService.handler(error);
    },
    ...options,
  });

  return {
    loading: isLoading,
    isSuccess,
    uploadFile,
  };
}
