import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { PaginationResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { GetPropertiesParams } from '../types';
import { toWC3 } from './helpers';
import { WC3Interface } from './WC3Interface';

export function useGetAllWC3(
  options?: UseQueryOptions<PaginationResponseType<WC3Interface>, Error>
) {
  const [params, setParams] = useState<GetPropertiesParams>({});

  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetAllWC3,
  } = useQuery<PaginationResponseType<WC3Interface>, Error>([API_QUERIES.WC3s, params], {
    queryFn: (query) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...params] = query.queryKey;
      return responseWrapper<PaginationResponseType<WC3Interface>>(apiClient.getAllWC3, params);
    },
    notifyOnChangeProps: ['data', 'isFetching'],
    keepPreviousData: true,
    select: toWC3,
    ...options,
  });

  const queryClient = useQueryClient();

  const handleInvalidateAllWC3 = () => queryClient.invalidateQueries(API_QUERIES.WC3s);

  const { data: wc3, hasNext, payloadSize, totalRecords } = data || {};
  return {
    wc3,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isError,
    isFetching,
    onGetAllWC3,
    setParams,
    handleInvalidateAllWC3,
  };
}
