import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { Complaint } from './types';

export function useUpdateComplaint(options?: UseMutationOptions<Complaint, Error, Complaint>) {
  const {
    mutate: onUpdateComplaint,
    isLoading: isUpdating,
    isSuccess,
    isError,
    error,
  } = useMutation<Complaint, Error, Complaint>({
    mutationFn: (payload: Complaint) => responseWrapper(apiClient.updateComplaint, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    onUpdateComplaint,
    isUpdating,
    isSuccess,
    isError,
    error,
  };
}
