export enum WC1FormKey {
  Id = 'id',
  DCDCaseId = 'caseId',
  WC1Number = 'formNumber',
  NewAmend = 'rawNewAmend',
  DateReceived = 'rawDateReceived',
  CreatedDate = 'createdAt',
}

export interface WC1Form {
  [WC1FormKey.Id]: string;
  [WC1FormKey.DCDCaseId]: string;
  [WC1FormKey.WC1Number]: string;
  [WC1FormKey.NewAmend]: string;
  [WC1FormKey.DateReceived]: string;
  [WC1FormKey.CreatedDate]: string;
}
