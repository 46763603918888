export enum ViewObject {
  User = 'User',
  Account = 'Account',
  Contact = 'Contact',
  DCDCase = 'WCCase',
  WC1 = 'Wc_1',
  WC3a = 'Wc_3a',
  WC2 = 'Wc_2',
  WC5 = 'Wc_5',
  WC5a = 'Wc_5a',
  WC3 = 'Wc_3',
  WCInsurancePolicies = 'InsurancePolicy',
  Decision = 'Decision',
  Disbursements = 'Disbursement',
  Receipt = 'Receipt',
  GLAccount = 'GLAccount',
  AwardWorksheet = 'AwardWorksheet',
  Hearing = 'CaseHearing',
  TDI62 = 'TDI62',
  TDI46 = 'TDI46',
  TDI21 = 'TDI21',
  TDI15 = 'TDI15',
  TDI30 = 'TDI30',
  TDICase = 'TDICase',
  TDISFCase = 'TDISFCase',
  TDIPlan = 'TDIPlanType',
  HC4 = 'HC4',
  HCP = 'HealthCarePlan',
  HC15 = 'HC15',
  Complaint = 'Complaint',
  HC61 = 'HC61',
  Request = 'Request',
  EmployerAudits = 'EmployerAudit',
  FileUpload = 'FileUpload',
  Report = 'Report',
  PortalUser = 'PortalUser',
  WC14s = 'Wc_14',
  WC77s = 'Wc_77',
  WC21s = 'Wc_21',
}

export enum Tag {
  FraudComplaints = 'FRAUD_COMPLAINTS',
  PHCTDIComplaints = 'PHC_TDI_COMPLAINTS',
  WCNonEmployee = 'WC_NON_EMPLOYEE',
  WCEmployee = 'WC_EMPLOYEE',
  TDI = 'TDI',
  PHC = 'PHC',
  AllReports = 'ALL_REPORTS',
  TDI21sAnnualReportsNotFiled = 'TDI21S_ANNUAL_REPORTS_NOT_FILED',
  TDI21sAnnualExceptionReport = 'REPORT_TDI_21S_ANNUAL_EXCEPTION',
  TDI21sAnnualReportSummaryPlanTypeKey = 'REPORT_TDI_21S_ANNUAL_REPORT_SUMMARY_BY_PLAN_TYPE',
  NonCompliantEmployerInsuranceReport = 'NON_COMPLIANT_EMPLOYER_INSURANCE',
  NonCompliantWCEmployerInsurance = 'NON_COMPLIANT_WC_EMPLOYER_INSURANCE',
  RejectedSelfInsuredHealthCarePlansReport = 'REJECTED_PHC_SELF_INSURANCE',
  TDIAndPHCPhysicalFilePurgeReport = 'TDI_AND_PHC_PHYSICAL_FILE_PURGE',
  ApprovedHealthCarePlansReport = 'APPROVED_HEALTH_CARE_PLANS',
  HC15sReport = 'REPORT_HC_15S',
  TDI15sTDISelfInsurerPlanReport = 'REPORT_TDI_15S_TDI_SELF_INSURER_PLAN',
  EmployerRegistrationActivities = 'REPORT_EMPLOYER_REGISTRATION_ACTIVITIES',
  TDI46ReconsiderationsAppeals = 'REPORT_TDI_46_RECONSIDERATION_APPEAL',
  TDI62sReport = 'REPORT_TDI_62S',
  TDI30sReport = 'REPORT_TDI_30S',
}
