import { useMutation, UseMutationOptions } from 'react-query';
import { Toastify } from 'src/services';
import apiClient from '../apiClient';
import { createResponseWrapper } from '../helpers';
import { EmailPayload } from './types';

export function useSendEmail(options?: UseMutationOptions<EmailPayload, Error, EmailPayload>) {
  const { mutate: sendEmail, isLoading } = useMutation<EmailPayload, Error, EmailPayload>({
    mutationFn: (payload: EmailPayload) => createResponseWrapper(apiClient.sendEmail, [payload]),
    onError: ({ message }) => Toastify.error(message),
    ...options,
  });

  return {
    sendEmail,
    isLoading,
  };
}
