import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { ScheduleOfDisbursementPayload } from './types';

export function useEditScheduleOfDisbursement(
  options?: UseMutationOptions<ScheduleOfDisbursementPayload, Error, ScheduleOfDisbursementPayload>
) {
  const {
    mutate: editScheduleOfDisbursement,
    isLoading: isUpdating,
    isSuccess,
    isError,
    error,
  } = useMutation<ScheduleOfDisbursementPayload, Error, ScheduleOfDisbursementPayload>({
    mutationFn: (payload: ScheduleOfDisbursementPayload) =>
      responseWrapper(apiClient.editScheduleOfDisbursement, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    editScheduleOfDisbursement,
    isUpdating,
    isSuccess,
    isError,
    error,
  };
}
