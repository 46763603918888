import { PaginationResponseType, toAuditData, toRelatedAuditData } from '../helpers';
import { TrackingLogKey } from './keys';
import { TrackingLog } from './types';

export const toTrackingLogs = (data): PaginationResponseType<TrackingLog> => ({
  ...data,
  data: data.data.map((trackingLog) => ({
    ...trackingLog,
    [TrackingLogKey.UserProfile]: trackingLog?.userProfileName || '',
    ...toRelatedAuditData(trackingLog),
  })),
});

export const toTrackingLog = (data) => {
  return {
    ...data,
    ...toAuditData(data),
  };
};
