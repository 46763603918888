import { TableParams } from 'src/redux/types';

export enum RelatedContactKey {
  Account = 'account',
  ContactName = 'contactName',
  Contact = 'contact',
  Salutation = 'salutation',
  MiddleInitial = 'middleInitial',
  FirstName = 'firstName',
  LastName = 'lastName',
  Suffix = 'suffix',
  Type = 'type',
  Email = 'email',
  Phone = 'phone',
  Title = 'title',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  CreatedByName = 'createdByName',
  UpdatedByName = 'updatedByName',
  CreatedBy = 'createdBy',
  LastModifiedBy = 'lastModifiedBy',
  Address = 'address',
}

export interface Option {
  label: string;
  subLabel?: string;
  index?: number;
  value: string;
  additionalOptions?: any;
}

export interface RelatedContact {
  id?: string;
  [RelatedContactKey.ContactName]?: string;
  [RelatedContactKey.Salutation]?: string;
  [RelatedContactKey.MiddleInitial]?: string;
  [RelatedContactKey.FirstName]?: string;
  [RelatedContactKey.LastName]?: string;
  [RelatedContactKey.Suffix]?: string;
  [RelatedContactKey.Type]?: string;
  [RelatedContactKey.Email]?: string;
  [RelatedContactKey.Phone]?: string;
  [RelatedContactKey.Title]?: string;
  [RelatedContactKey.UpdatedAt]?: string;
  [RelatedContactKey.CreatedAt]?: string;
  [RelatedContactKey.CreatedByName]?: string;
  [RelatedContactKey.UpdatedByName]?: string;
}

export type GetPropertiesParams = TableParams & {
  [key: string]: string | number | string[];
};

export enum AddressInfoKey {
  Street = 'street',
  District = 'district',
  City = 'city',
  State = 'state',
  Zip = 'zip',
  County = 'county',
  IsLandCode = 'islandCode',
  Country = 'country',
}

export enum CaseType {
  Case = 'case',
  TDICase = 'tdiCase',
  TDISFCase = 'tdiSFCase',
  TDI = 'tdiCase, tdiSFCase',
}
