export enum UploadedFileKey {
  DocumentName = 'documentName',
  DocumentType = 'documentType',
  DocumentCategory = 'documentCategory',
  IIDate = 'dateOfInjury',
  DocumentDateReceived = 'dateDocumentReceived',
  DocumentDate = 'documentDate',
  TDICaseNumber = 'tdiCase',
  DCDCaseNumber = 'wcCase',
  FillingParty = 'fillingParty',
  DCDFillingDate = 'dcdFillingDate',
  DocumentDescription = 'documentDescription',
  CreatedBy = 'createdBy',
  CreatedAt = 'createdAt',
  EmployerName = 'employer',
  InsuranceCarrier = 'insuranceCarrier',
  FinancialYear = 'financialYear',
  AttachmentURL = 'attachmentURL',
  DCDCaseId = 'wcCaseId',
  TDICaseId = 'tdiCaseId',
}
