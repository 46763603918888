export enum CaseSettlementsKey {
  CaseNumber = 'caseNumber',
  CaseId = 'caseId',
  Id = 'id',
  AssignTo = 'assignTo',
  AssignId = 'assignId',
  Status = 'status',
  ReceivedDate = 'receivedDate',
  ApprovedDate = 'approvedDate',
  TimeTaken = 'timeTaken',
  InvolvesSCF = 'isInvolvesSCF',
  Type = 'type',
  ReasonForRejection = 'reasonForRejection',
  ApprovalReviewedBy = 'approvalReviewedBy',
  ApprovalDecision = 'approvalDecision',
  CreatedBy = 'createdBy',
  LastModifiedBy = 'lastModifiedBy',
  CaseSettlementNumber = 'caseSettlementNumber',
}
