import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { PotentialAssociated } from './types';

export function useEditPotentialAssociated(
  options?: UseMutationOptions<PotentialAssociated, Error, PotentialAssociated>
) {
  const {
    mutate: onEditPotentialAssociated,
    isLoading: isUpdating,
    isSuccess,
    isError,
    error,
  } = useMutation<PotentialAssociated, Error, PotentialAssociated>({
    mutationFn: (payload: PotentialAssociated) =>
      responseWrapper(apiClient.updatePotentialAssociated, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    onEditPotentialAssociated,
    isUpdating,
    isSuccess,
    isError,
    error,
  };
}
