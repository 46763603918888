export enum WC3AFormKey {
  Id = 'id',
  DCDCaseId = 'caseId',
  WC3ANumber = 'formNumber',
  DateReceived = 'rawDateReceived',
  NameWCInsuranceCarrier = 'rawInsuranceCarrierName',
  CarrierID = 'rawInsuranceCarrierId',
  CreatedDate = 'createdAt',
}

export interface WC3AForm {
  [WC3AFormKey.Id]: string;
  [WC3AFormKey.DCDCaseId]: string;
  [WC3AFormKey.WC3ANumber]: string;
  [WC3AFormKey.NameWCInsuranceCarrier]: string;
  [WC3AFormKey.CarrierID]: string;
  [WC3AFormKey.DateReceived]: string;
  [WC3AFormKey.CreatedDate]: string;
}

export interface WC3AFormPayload {
  [WC3AFormKey.DCDCaseId]: string;
}
