import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { PaginationResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { GetPropertiesParams } from '../types';
import { toAddressHistories } from './helpers';
import { AddressHistoryType } from './types';

export function useGetAddressHistories(
  options?: UseQueryOptions<PaginationResponseType<AddressHistoryType>, Error> & {
    accountId?: string;
    contactId?: string;
  }
) {
  const [params, setParams] = useState<GetPropertiesParams>({});

  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetAddressHistories,
  } = useQuery<PaginationResponseType<AddressHistoryType>, Error>(
    [
      API_QUERIES.ADDRESS_HISTORIES,
      { ...params, accountId: options.accountId, contactId: options.contactId },
    ],
    {
      queryFn: ({ queryKey }) => {
        const params = queryKey.at(-1);
        return responseWrapper<PaginationResponseType<AddressHistoryType>>(
          apiClient.getAddressHistories,
          [params]
        );
      },
      select: (data) => toAddressHistories(data),
      notifyOnChangeProps: ['data', 'isFetching'],
      keepPreviousData: true,
      enabled: !!(options?.accountId || options?.contactId),
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateAddressHistories = () =>
    queryClient.invalidateQueries(API_QUERIES.ADDRESS_HISTORIES);

  const { data: addressHistories, hasNext, payloadSize, totalRecords } = data || {};

  return {
    addressHistories,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isError,
    isFetching,
    onGetAddressHistories,
    setParams,
    handleInvalidateAddressHistories,
  };
}
