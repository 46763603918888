export enum ContactKey {
  Id = 'id',
  ContactName = 'contactName',
  AccountName = 'accountName',
  AccountRecordType = 'accountRecordType',
  RecordType = 'recordType',
  Title = 'title',
  Phone = 'phone',
  Email = 'email',
  ContactOwner = 'contactOwner',
  Salutation = 'salutation',
  Suffix = 'suffix',
  DCDId = 'dcdId',
  FirstName = 'firstName',
  LastName = 'lastName',
  MiddleInitial = 'middleInitial',
  DateOfBirth = 'dateOfBirth',
  ContactType = 'accountType',
  Address = 'address',
  Type = 'type',
  Street = 'street',
  City = 'city',
  Zip = 'zip',
  State = 'state',
  Country = 'country',
  District = 'district',
  County = 'county',
  IslandCode = 'islandCode',
  GenericId = 'genericId',
  AccountInfo = 'account',
  AccountId = 'accountId',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  CreatedByName = 'createdByName',
  UpdatedByName = 'updatedByName',
  // Account field
  SSN = 'ssn',
  Passport = 'passport',
  Gender = 'gender',
  IsDeceased = 'isDeceased',
  IsQualifyStudent = 'isQualifyStudent',
  IsNonSelfSufficient = 'isNonSelfSufficient',
  IsWhollyDependent = 'isWholyDependent',
  DateOfDeath = 'dateOfDeath',
  MaritalStatus = 'maritalStatus',
  DCISDbaName = 'dcisDbaName',
  EffectiveDate = 'effectiveDate',
  Occupation = 'occupation',
  RelationDeceased = 'relationDeceased',
  VendorNumber = 'vendorNumber',
  VendorType = 'vendorType',
  VendorDepartmentCode = 'vendorDepartmentCode',
  VendorMaintenanceDate = 'vendorMaintenanceDate',
  IsConcurrentEmployer = 'isConcurrentEmployer',
  IsRedTag = 'isRedTag',
  IsDelinquentEmployer = 'isDelinquentEmployer',
  IsVendorConversation = 'isVendorConversation',
  DCDStatus = 'dcdStatus',
}
