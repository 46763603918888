import { useMutation, UseMutationOptions } from 'react-query';
import { HealthCarePlan } from 'src/queries/HealthCarePlans/types';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { createResponseWrapper } from '../helpers';

export function useCreateHCP(options?: UseMutationOptions<HealthCarePlan, Error, HealthCarePlan>) {
  const { mutate: createHCP, isLoading } = useMutation<HealthCarePlan, Error, HealthCarePlan>({
    mutationFn: (payload: HealthCarePlan) => createResponseWrapper(apiClient.createHCP, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    createHCP,
    isLoading,
  };
}
