import { UseQueryOptions } from 'react-query';

export enum API_QUERIES {
  PROFILE = '/me',
  CONTENT = '/content',
  ZIP_CODE = '/zip-code',
  USER_ID = '/user-id',
  PERMISSIONS = '/permissions',

  // Users Management
  Users = 'User',
  SEARCH_PROJECTS = 'projects/search',
  LANGUAGES = '/languages',
  LOCALES = '/locales',

  // Permissions
  Profiles = '/profiles',
  ASSIGNED_USERS = '/assigned-users',
  SYSTEM_PERMISSIONS = '/system-permissions',

  // Accounts
  Accounts = 'Account',
  ACCOUNT_HIERARCHY = 'accounts/:id/hierarchy',
  RELATED_CONTACTS = '/accounts/related/contact',
  RELATED_ACCOUNT_CONTACT = 'accounts/:id/contact',
  RELATED_ACCOUNT_CONTACT_DETAIL = 'accounts/related-account-contacts/:id',
  COMMENTS = 'comments',

  // Contacts
  Contacts = 'Contact',

  // Groups
  GROUPS = '/groups',

  // ====================== Custom Setting ======================
  CUSTOM_SETTING = '/custom-settings',

  // File
  PRESIGNED_DOWNLOAD_URL = '/files/presigned-download-url',
  PRESIGNED_UPLOAD_URL = '/files/presigned-upload-url',

  // Permission
  OBJECT_PERMISSION = '/profile/object-permission',
  APP_PERMISSION = '/profiles/app-permissions',
  FIELD_PERMISSION = '/permissions',

  ADDRESS_HISTORIES = '/address-histories',

  //Address
  ADDRESS = '/address',

  // DCD Cases
  DCDCases = 'WCCase',
  LEDGERS = '/ledgers',
  RELATED_DISBURSEMENTS = '/dcd-cases/:id/disbursement',
  SCHEDULE_OF_DISBURSEMENT = '/schedule-disbursements',
  RELATED_ORDERS = '/orders',
  RELATED_CASE_AWARDS = '/dcd-cases/:id/case-awards',
  CASE_AWARD = '/case-awards/:id',
  VR_PROVIDER = '/vr-providers',
  EMPLOYMENT_AT_TIME_OF_INJURY_ILLNESS = '/wc-case-employments',
  RELATED_WC_CASE_PARTY_NAMES_ADDRESS = '/wc-case-party-names',
  WC_CASE_PARTY_NAMES_ADDRESS = '/wc-case-party-names/:id',
  WC_PARTY_NAME_ADDRESS = '/wc-party-names-addresses',
  AWARD_SUMMARIES = '/award-summaries',
  CASE_AWARD_PAYERS = '/case_award_payers',
  DISFIGUREMENT_BODY_PARTS = '/disfigurement-body-parts',
  RELATED_CASE_PARTY_ACTIVE_HISTORY = '/case-party-histories',

  // Notifications
  NOTIFICATIONS = '/notifications',

  // WC-1
  WC1s = 'Wc_1',

  WC14s = 'Wc_14',

  // Injury/Illness
  RELATED_INJURY_ILLNESS = 'injury-illness',
  INJURY_ILLNESS_PART_BODY_CODE = 'injury-illness/part-body-code',

  // WC-2
  WC2s = 'Wc_2',

  // WC3A
  WC3As = 'Wc_3a',

  //WC-5
  WC5s = 'Wc_5',

  // WC-5as
  WC5As = 'Wc_5a',

  //WC-Insurance Policies
  WCInsurancePolicies = 'InsurancePolicy',

  //Orders
  ORDERS = '/orders',

  //Approval History Detail
  APPROVAL_HISTORY_DETAIL = '/approval-history-details',

  // Case Dependents
  RELATED_CASE_DEPENDENTS = 'case-dependents',

  // Related Approval History
  RELATED_APPROVAL_HISTORY = 'approval-history',

  // WC-3
  WC3s = 'Wc_3',
  WC3PaymentYear = '/wc-3/payment-years',
  WC3HistoryPriorPayment = '/wc-3/history-prior-payment',

  // Benefit Payments
  BENEFIT_PAYMENTS = '/benefit-payments',
  BENEFIT_PAYMENT_SUMMARIES = '/benefit-payment-summaries',
  PRIOR_PAYMENT = '/prior-payments',

  //Case Status History
  RELATED_STATUS_HISTORIES = '/case-status-histories',

  //Associated Cases
  RELATED_ASSOCIATED_CASES = '/wc-associated-cases',

  // WC Coverages
  WC_COVERAGES = '/coverages',

  // Case Settlements
  CASE_SETTLEMENTS = '/case-settlements',
  CASE_SETTLEMENTS_DETAILS = '/case-settlements-details',

  // ====================== Receipts ======================
  Receipts = 'Receipt',

  // ====================== PREHEARING FOR EMPLOYER ======================
  RELATED_PREHEARING_FOR_EMPLOYER = '/related-prehearing-for-employer',
  RELATED_VENDORS = '/related-vendor',
  RELATED_VOCATIONAL_REHABILITATION = '/related-vocational-rehabilitation',
  VR_PROCESS = '/vocational-rehabilitation-process',

  // ====================== RELATED HEARINGS ======================
  RELATED_HEARINGS = '/case-hearings',

  // ====================== Disbursement ======================
  Disbursements = 'Disbursement',

  // ====================== PHC COVERAGES ======================
  PHC_COVERAGES = '/phc-coverages',

  // ====================== TRACKING LOG ======================
  TRACKING_LOG = '/tracking-log',

  // ====================== Hearings ======================
  Hearings = 'CaseHearing',
  HEARING_DECISION = '/hearings/:id/decision',
  HEARING_SCHEDULES = '/case-hearings/:id/schedules',
  SCHEDULE_AVAILABILITY = '/case-hearings/:id/schedule-availability',
  ROOMS = '/rooms',
  HRS_HAR = '/hrs-har',

  // ====================== Related Hearings ======================
  RELATED_HEARING_REQUIRED_DOCUMENTS = '/related-hearing-required-documents',
  RELATED_TYPE_PURPOSES = '/related-type-purposes',
  TYPE_PURPOSES = '/type-purposes',
  PURPOSES = '/purposes',
  RELATED_PARTY_HISTORY_ARCHIVES = '/related-party-history-archives',
  RELATED_NOTES = '/related-notes',

  // ====================== GL Account ======================
  GLAccounts = 'GLAccount',
  RELATED_GL_ACCOUNTS = '/related-gl-accounts',

  // ====================== TDI-62s ======================
  TDI62s = 'TDI62',
  TDI_62_RELATIONSHIP = '/tdi-62s/:id/relationship',

  // ====================== TDI-46s ======================
  TDI46s = 'TDI46',

  // ====================== TDI-21s ======================
  TDI21s = 'TDI21',

  // ====================== TDI-30s ======================
  TDI30s = 'TDI30',

  // ====================== TDI-30s ======================
  HealthCarePlans = 'HealthCarePlan',

  // ====================== TDI-15s ======================
  TDI15s = 'TDI15',

  // ====================== Award Worksheets ======================
  AwardWorksheets = 'AwardWorksheet',
  AWARD_WORKSHEET_PERIODS_OF_TPD = '/award-worksheets/:id/periods-of-tpd',
  AWARD_WORKSHEETS_LAZY = '/award-worksheets-lazy',
  AWARD_WORKSHEET_PERIODS_OF_TTD = '/award-worksheets/:id/periods-of-ttd',
  PPD_INJURED_BODY_PARTS = '/ppd-injured-body-parts',
  RELATED_AWARD_EMPLOYMENTS = '/award-worksheets/:id/award-employments',

  // ====================== Dependents ======================
  DEPENDENTS = '/dependents',

  // ====================== Case Processing Log ======================
  CASE_PROCESSING_LOGS = '/case-processing-logs',

  // ====================== Decisions ======================
  Decisions = 'Decision',
  DECISIONS_REQUEST_RECONSIDERATION = '/decisions/:id/request-reconsideration',
  DECISIONS_REQUEST_RECONSIDERATION_DETAIL = '/decisions/:id/request-reconsideration/:requestId',

  //====================== TDI Coverages ======================
  TDI_COVERAGES = '/tdi-coverages',

  // ====================== TDI Plans ======================
  TDIPlans = 'TDIPlanType',
  CALCULATE_EQUIVALENCY = '/calculate-equivalency',

  //====================== Hearing Related Party Names Address  ======================
  RELATED_PARTY_NAMES_ADDRESS = '/related-party-names-address',

  // ====================== Coverage Equivalency ======================
  COVERAGE_EQUIVALENCY = '/coverage-equivalency',

  // ====================== TDI Cases ======================
  TDICases = 'TDICase',
  RELATED_POTENTIAL_ASSOCIATED = '/tdi-cases/:id/potential-associated-wc-cases',

  // ====================== TDI SF Cases ======================
  TDISFCases = 'TDISFCase',

  // ====================== HC-4s ======================
  HC4s = 'HC4',

  // ====================== HC-15s ======================
  HC15s = 'HC15',

  // ====================== HC-61s ======================
  HC61s = 'HC61',

  // ====================== Request ======================
  Requests = 'Request',

  // ====================== Complaints ======================
  Complaints = 'Complaint',

  // ====================== Employer Audits ======================
  EMPLOYER_AUDITS = 'EmployerAudit',

  // ====================== File Upload ======================
  Files = 'FileUpload',

  // ====================== TDI Cases ======================
  RoutingInformation = 'Routing-information',

  // ====================== Reports ======================
  REPORTS = 'Report',
  TDI21S_ANNUAL_REPORTS_NOT_FILED = 'TDI21S_ANNUAL_REPORTS_NOT_FILED',
  TDI21S_ANNUAL_EXCEPTION_REPORT = 'REPORT_TDI_21S_ANNUAL_EXCEPTION',
  TDI21S_ANNUAL_REPORT_SUMMARY_PLAN_TYPE = 'REPORT_TDI_21S_ANNUAL_REPORT_SUMMARY_BY_PLAN_TYPE',
  REPORT_TDI_46_RECONSIDERATION_APPEAL = 'REPORT_TDI_46_RECONSIDERATION_APPEAL',
  NON_COMPLIANT_EMPLOYER_INSURANCE = 'NON_COMPLIANT_EMPLOYER_INSURANCE',
  NON_COMPLIANT_WC_EMPLOYER_INSURANCE = 'NON_COMPLIANT_WC_EMPLOYER_INSURANCE',
  REJECTED_PHC_SELF_INSURANCE = 'REJECTED_PHC_SELF_INSURANCE',
  TDI_AND_PHC_PHYSICAL_FILE_PURGE = 'TDI_AND_PHC_PHYSICAL_FILE_PURGE',
  APPROVED_HEALTH_CARE_PLANS = 'APPROVED_HEALTH_CARE_PLANS',
  REPORT_HC_15S = 'REPORT_HC_15S',
  REPORT_TDI_15S_TDI_SELF_INSURER_PLAN = 'REPORT_TDI_15S_TDI_SELF_INSURER_PLAN',
  REPORT_EMPLOYER_REGISTRATION_ACTIVITIES = 'REPORT_EMPLOYER_REGISTRATION_ACTIVITIES',
  REPORT_TDI_62S = 'REPORT_TDI_62S',
  REPORT_TDI_30S = 'REPORT_TDI_30S',
  REPORT_TDI_21_NUMBER_OF_EMPLOYEES = 'REPORT_TDI_21_NUMBER_OF_EMPLOYEES',

  // ====================== Portal User ======================
  PORTAL_USERS = 'User',
  PORTAL_USERS_AUDIT_HISTORY = '/portal-users/audit-history',
  ACCOUNT_PORTAL_USERS = 'accounts/portal-users',

  // ====================== TDI-15s ======================
  WC77s = 'Wc_77',

  WC21s = 'Wc_21',
}

export type QueryOptions<T> = Omit<UseQueryOptions, 'QueryKey'> & { QueryKey: T };

export enum AuditKey {
  CreatedBy = 'createdBy',
  UpdatedBy = 'updatedBy',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  CreatedUser = 'createdUser',
  UpdatedUser = 'updatedUser',
  Avatar = 'avatar',
}

export enum HoldDisbursementsKey {
  CaseId = 'caseId',
  HoldDate = 'scfHoldDate',
  RecipientUsers = 'recipientUserIds',
  RecipientProfiles = 'recipientProfileIds',
}

export enum TransferCaseLocationKey {
  CaseId = 'caseId',
  AssignedToGroupId = 'assignedToGroupId',
  CurrentLocation = 'currentLocation',
  AssignedToGroupName = 'assignedToGroupName',
  TransferReason = 'transferReason',
}

export enum ChangeControlCaseKey {
  CaseId = 'caseId',
  Mode = 'mode',
  AppealReceivedDate = 'appealReceivedDate',
  AppealNumber = 'appealNumber',
  RemandReason = 'remandReason',
}
