import { createTheme, ThemeOptions, ThemeProvider } from '@mui/material/styles';
import cn from 'classnames';
import MUIDataTable, {
  MUIDataTableOptions,
  MUIDataTableProps,
  MUIDataTableState,
} from 'mui-datatables';
import React, { memo, useMemo } from 'react';
import { COLOR_CODE } from 'src/appConfig/constants';
import { EmptyTable, LoadingCommon, View } from 'src/modules/shared-main/components';
import { useTableProvider } from '../TableProvider';
import CustomFooterRender from './customFooterRender';
import CustomSearchRender from './customSearchRender';
import './styles.scss';

const TableBasic: React.FC<Props> = ({
  isLoading,
  containerClassName,
  onTableChange,
  currentPage,
  options,
  total,
  data,
  emptyComponent,
  customFilterRender,
  ...props
}) => {
  const { appName, object, view, wrapClipColumns, views } = useTableProvider();

  const tableOptions: MUIDataTableOptions = {
    serverSide: true,
    searchOpen: true,
    searchAlwaysOpen: true,
    search: true,
    download: false,
    filter: true,
    print: false,
    viewColumns: false,
    textLabels: {
      body: {
        noMatch: isLoading ? <LoadingCommon /> : emptyComponent ?? <EmptyTable />,
      },
    },
    selectToolbarPlacement: 'above',
    jumpToPage: false,
    rowHover: true,
    onTableChange,
    customToolbar: () => <CustomSearchRender customFilterRender={customFilterRender} />,
    customFooter: (
      count: number,
      page: number,
      rowsPerPage: number,
      changeRowsPerPage: (page: string | number) => void,
      changePage: (newPage: number) => void
    ) => {
      return (
        <CustomFooterRender
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          changeRowsPerPage={changeRowsPerPage}
          changePage={changePage}
        />
      );
    },
    ...options,
  };

  const hasRowClickAction = !!options?.onRowClick;
  const getMuiTheme = () =>
    createTheme({
      primary: {
        main: COLOR_CODE.PRIMARY,
        dark: COLOR_CODE.PRIMARY_DARK,
        light: COLOR_CODE.PRIMARY_LIGHT,
      },
      secondary: {
        main: COLOR_CODE.SECONDARY,
      },
      typography: {
        fontFamily: ['Roboto flex'].join(','),
      },
      components: {
        MuiPaper: {
          styleOverrides: {
            root: {},
            elevation4: {
              boxShadow: 'none',
            },
          },
        },
        MuiToolbar: {
          styleOverrides: {
            root: {
              paddingLeft: '4px !important',
              paddingRight: '0px !important',
              marginTop: 24,
            },
          },
        },
        MUIDataTableToolbar: {
          styleOverrides: {
            titleText: {
              fontSize: '16px',
              fontWeight: 650,
            },
          },
        },
        MUIDataTableToolbarSelect: {
          styleOverrides: {
            root: {
              display: 'none',
            },
          },
        },
        MuiTableRow: {
          styleOverrides: {
            hover: {
              cursor: hasRowClickAction ? 'pointer' : 'default',
            },
            root: {
              height: '42px',
              '&:hover': {
                background: 'unset !important',
                '.MuiTableCell-root, .sticky-right-table__cell, .sticky-left-table__cell': {
                  background: `${COLOR_CODE.WHITE_SMOKE} !important`,
                },
              },
              '.sticky-right-table': {
                position: 'sticky',
                right: 0,
              },
              '.sticky-left-table': {
                position: 'sticky',
                left: 0,
              },
            },
            footer: {
              padding: '100px 0',
              borderBottom: '0px !important',
            },
          },
        },
        MuiButtonBase: {
          styleOverrides: {
            root: {
              minWidth: 'max-content',
            },
          },
        },
        MuiTableCell: {
          styleOverrides: {
            root: {
              padding: '0px 16px',
            },
            body: {
              cursor: hasRowClickAction ? 'pointer' : 'default',
            },
            head: {
              '&.MuiTableCell-root': {
                position: 'sticky',
                top: 0,
                backgroundColor: COLOR_CODE.GRAY_50,
                color: COLOR_CODE.GRAY_600,
                fontWeight: 450,
                minWidth: 'max-content',
                borderBottom: 'none !important',
                ' div': {
                  minWidth: 'max-content',
                },
              },
              '&.MuiTableCell-root span button': {
                color: COLOR_CODE.GRAY_600,
                fontWeight: 450,
                padding: '12px 16px',
                textTransform: 'unset',
              },
              '&.MuiTableCell-root span button div, &.MuiTableCell-root span button div span svg': {
                color: `${COLOR_CODE.GRAY_600} !important`,
                fontWeight: 450,
                textTransform: 'unset',
              },
              '&.table-header--center span': {
                justifyContent: 'center',
              },
            },
          },
        },
        MuiTableBody: {
          styleOverrides: {
            root: {
              '.MuiTableCell-paddingCheckbox, .sticky-right-table__cell, .sticky-left-table__cell':
                {
                  backgroundColor: `${COLOR_CODE.WHITE} !important`,
                },
              '.bg-gray92': { backgroundColor: `${COLOR_CODE.GRAY_92} !important` },
              '.Mui-selected ': {
                '&:hover': {
                  '.MuiTableCell-paddingCheckbox, .sticky-right-table__cell, .sticky-left-table__cell':
                    {
                      backgroundColor: `${COLOR_CODE.WHITE_SMOKE} !important`,
                    },
                },
                backgroundColor: `${COLOR_CODE.GRAY_92} !important`,
                '.MuiTableCell-paddingCheckbox, .sticky-right-table__cell, .sticky-left-table__cell':
                  {
                    backgroundColor: `${COLOR_CODE.GRAY_92} !important`,
                  },
              },
              opacity: isLoading ? 0.3 : 1,
            },
          },
        },
        MuiTablePagination: {
          styleOverrides: {
            root: {
              borderBottom: 'unset',
            },
          },
        },
        MuiChip: {
          styleOverrides: {
            root: {
              // Hide Chip filter
              '&.MuiChip-filled.MuiChip-colorDefault.MuiChip-deletable': {
                display: 'none !important',
              },
            },
          },
        },
      },
    } as ThemeOptions);

  const muiDataTable = useMemo(
    () => {
      return <MUIDataTable data={data} options={tableOptions} {...props} />;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data, view, views, wrapClipColumns, object, isLoading, appName, tableOptions.rowsSelected]
  );

  return (
    <View className={cn('cmp-table-basic', containerClassName)} flexGrow={1}>
      <ThemeProvider theme={getMuiTheme()}>{muiDataTable}</ThemeProvider>
    </View>
  );
};

type Props = MUIDataTableProps & {
  containerClassName?: string;
  currentPage?: number;
  total?: number;
  onTableChange: (action: string, tableState: MUIDataTableState) => void;
  isLoading?: boolean;
  emptyComponent?: React.ReactNode;
  data?: any;
  customFilterRender?: React.ReactNode;
};

export default memo(TableBasic);
