import { useMutation, UseMutationOptions } from 'react-query';
import { compressFile } from 'src/utils';
import apiClient from '../apiClient';
import { FileUploadType, GetPresignedPayload } from '../File';
import { useUploadFileToS3 } from '../File/useUploadFileToS3';
import { responseWrapper } from '../helpers';

export function useUploadAttachments(
  options?: UseMutationOptions<any, Error, File> & {
    onUploadSuccess: Function;
  }
) {
  let _compressFile: File = null;
  const {
    data: fileUrl,
    mutate: getPresignedUploadUrl,
    isSuccess,
    isLoading,
  } = useMutation<any, Error, File>({
    mutationFn: async (payload: File) => {
      _compressFile = await compressFile(payload);
      const formattedPayload: GetPresignedPayload = {
        fileName: _compressFile?.name,
        contentType: _compressFile?.type,
        type: FileUploadType.Attachments,
      };
      return responseWrapper<any>(apiClient.getPresignedUrlCaseService, [formattedPayload]);
    },
    onSuccess: ({ data }) => {
      uploadFile({ url: data.url, fileData: _compressFile });
      options?.onUploadSuccess({ _compressFile, url: data.url });
    },
    ...options,
  });

  const { uploadFile, loading } = useUploadFileToS3();
  return {
    fileUrl,
    loading: isLoading || loading,
    isSuccess,
    getPresignedUploadUrl,
  };
}
