import { getFirstLastName } from 'src/utils';
import { toRelatedAuditData } from '../helpers';
import { RoutingInformationKey } from './keys';

export const toRoutingInformation = (data) => ({
  ...data,
  data: data?.data?.map((item) => ({
    ...item,
    [RoutingInformationKey.From]: getFirstLastName(item.createdUser),
    [RoutingInformationKey.To]: item?.routingOnUser?.join(', '),
    ...toRelatedAuditData(item),
  })),
});
