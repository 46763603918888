import { View, Views, ViewsOption } from './types';

export const toViewOptions = (views: Views[]): ViewsOption[] =>
  views.map((view) => ({
    value: view.id,
    label: view.name,
    ...view,
  }));

export const toViewDetail = (view): View => ({
  ...view,
  approvalGroups: view?.approvalGroups?.map(({ id }) => id.toString()),
});
