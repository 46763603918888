import { getFirstLastName } from 'src/utils';
import { PaginationResponseType, toAuditData } from '../helpers';
import { toRelatedAuditData } from './../helpers';
import { AwardEmploymentKey, AwardWorksheetKey } from './keys';
import {
  AwardEmployment,
  AwardWorksheet,
  PeriodsOfTPDResponse,
  PeriodsOfTTDResponse,
} from './types';

export const toAwardWorksheets = (data): PaginationResponseType<AwardWorksheet> => {
  return {
    ...data,
    data: data?.data?.map((item) => ({
      ...item,
      [AwardWorksheetKey.DecisionNumber]: item?.decisionId ? item?.decision?.decisionNumber : '',
      [AwardWorksheetKey.CaseNumber]: item?.decisionId ? item?.decision?.case?.caseNumber : '',
      [AwardWorksheetKey.HearingOfficer]: item?.decisionId
        ? getFirstLastName(item?.decision?.hearingOfficer)
        : '',
      [AwardWorksheetKey.HearingOfficerId]: item?.decision?.hearingOfficer?.id,
      [AwardWorksheetKey.DateOfInjury]: item?.decisionId ? item?.decision?.case?.dateOfInjury : '',
      [AwardWorksheetKey.Claimant]: item?.decisionId
        ? item?.decision?.case?.claimant?.accountName
        : '',
      [AwardWorksheetKey.ClaimantId]: item?.decisionId ? item?.decision?.case?.claimant?.id : '',
      [AwardWorksheetKey.CaseId]: item?.decisionId ? item?.decision?.case?.id : '',
      [AwardWorksheetKey.AverageWeeklyWage]: item?.decisionId
        ? item?.decision?.case?.recentWc1?.avgWeeklyWage
        : '',
      [AwardWorksheetKey.CaseAdministrator]: item?.decisionId
        ? getFirstLastName(item?.decision?.decisionAdministrator)
        : '',
      [AwardWorksheetKey.CaseAdministratorId]: item?.decision?.decisionAdministrator?.id,
      [AwardWorksheetKey.DecisionReviewerId]: item?.decision?.decisionReviewer?.id,
      [AwardWorksheetKey.DecisionReviewer]: item?.decisionId
        ? getFirstLastName(item?.decision?.decisionReviewer)
        : '',
      [AwardWorksheetKey.ClaimantDeathDate]: item?.decisionId
        ? item?.decision?.case?.claimant?.dateOfDeath
        : '',
      ...toAuditData(item),
    })),
  };
};

export const toAwardWorksheetDetail = (data) => ({
  ...data,
  [AwardWorksheetKey.DecisionNumber]: data?.decision?.decisionNumber,
  [AwardWorksheetKey.DateOfInjury]: data?.decision?.case?.dateOfInjury,
  [AwardWorksheetKey.ClaimantDeathDate]: data?.decision?.case?.claimant?.dateOfDeath || '',
  [AwardWorksheetKey.ClaimantName]: data?.decision?.case?.claimant?.accountName || '',
  [AwardWorksheetKey.ClaimantId]: data?.decision?.case?.claimant?.id || '',
  [AwardWorksheetKey.CaseNumber]: data?.decision?.case?.caseNumber || '',
  [AwardWorksheetKey.CaseId]: data?.decision?.case?.id || '',
  [AwardWorksheetKey.AverageWeeklyWage]: data?.decision?.case?.recentWc1?.avgWeeklyWage || '',
  [AwardWorksheetKey.CaseAdministratorName]:
    data?.decision?.decisionAdministrator &&
    getFirstLastName(data?.decision?.decisionAdministrator),

  [AwardWorksheetKey.DecisionReviewerName]:
    data?.decision?.decisionReviewer && getFirstLastName(data?.decision?.decisionReviewer),

  [AwardWorksheetKey.HearingOfficerName]:
    data?.decision?.hearingOfficer && getFirstLastName(data?.decision?.hearingOfficer),

  ...toAuditData(data),
});

export const toPeriodsOfTPDData = (data): PeriodsOfTPDResponse => {
  return {
    ...data,
    periodsOfTpdPagination: {
      ...data?.periodsOfTpdPagination,
      data: data?.periodsOfTpdPagination?.data?.map((item) => {
        return {
          ...item,
          ...toRelatedAuditData(item),
        };
      }),
    },
  };
};

export const toPeriodsOfTTDData = (data): PeriodsOfTTDResponse => {
  return {
    ...data,
    periodsOfTtdPagination: {
      ...data?.periodsOfTtdPagination,
      data: data?.periodsOfTtdPagination?.data?.map((item) => {
        return {
          ...item,
          ...toRelatedAuditData(item),
        };
      }),
    },
  };
};

export const toRelatedAwardEmployments = (data): PaginationResponseType<AwardEmployment> => ({
  ...data,
  data: data?.data?.map((item) => ({
    ...item,
    [AwardEmploymentKey.EmployerName]: item?.employer?.accountName,
    ...toRelatedAuditData(item),
  })),
});
