import { AccountDetail } from '../Accounts';
import { User } from '../Users';
import { HearingRelatedNotesKey } from './keys';

export interface RelatedHearingNotes {
  [HearingRelatedNotesKey.Id]?: string;
  [HearingRelatedNotesKey.Subject]?: string;
  [HearingRelatedNotesKey.Body]?: string;
  [HearingRelatedNotesKey.CreatedAt]?: string;
  [HearingRelatedNotesKey.UpdatedAt]?: string;
  [HearingRelatedNotesKey.CreatedUser]?: AccountDetail;
}

export type HearingNoteValue = {
  [HearingRelatedNotesKey.Subject]: string;
  [HearingRelatedNotesKey.Body]?: string;
  [HearingRelatedNotesKey.CreatedUser]?: string;
};

export interface NoteDetail {
  [HearingRelatedNotesKey.Id]?: string;
  [HearingRelatedNotesKey.Subject]?: string;
  [HearingRelatedNotesKey.Body]?: string;
  [HearingRelatedNotesKey.Permission]?: string;
  [HearingRelatedNotesKey.RelatedHearings]?: string[];
  [HearingRelatedNotesKey.UserGroup]?: UserGroup[];
}

export interface NoteDetailRemove {
  [HearingRelatedNotesKey.NoteId]?: string;
  [HearingRelatedNotesKey.HearingId]?: string;
}

export enum NotePermission {
  EDITOR = 'Editor',
  VIEWER = 'Viewer',
  OWNER = 'Owner',
  REMOVE = 'Remove',
}

export interface UserGroup {
  user?: User;
  userId?: string;
  permission: string;
}
