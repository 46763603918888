import { formatDateTime, getLabel } from 'src/utils';
import { AccountRecordType } from '../Contacts';
import { PaginationResponseType, toAddress, toAuditData, toShortAddress } from '../helpers';
import {
  Account,
  AccountDetail,
  AccountKey,
  AccountType,
  accountTypeOptions,
  Comment,
  CommentKey,
} from './types';

export const toAccountData = (account: AccountDetail): AccountDetail => {
  const accountUserFullName = [
    account?.salutation,
    account?.firstName,
    account?.middleInitial,
    account?.lastName,
    account?.suffix,
  ]
    .filter((item) => item)
    .join(' ');

  const contacts = account?.contacts.map((relatedContact) => {
    const contactName = [
      relatedContact?.salutation,
      relatedContact?.firstName,
      relatedContact?.middleInitial,
      relatedContact?.lastName,
      relatedContact?.suffix,
    ]
      .filter((item) => item)
      .join(' ');

    const relatedContactCreated = formatDateTime(relatedContact?.createdAt);
    const relatedContactUpdated = formatDateTime(relatedContact?.updatedAt);
    const createdBy = `${relatedContact?.createdByName}, ${relatedContactCreated}`;
    const lastModifiedBy = relatedContact?.updatedByName
      ? `${relatedContact?.updatedByName}, ${relatedContactUpdated}`
      : null;

    const address = toAddress(account);

    return {
      ...relatedContact,
      contactName: contactName,
      createdBy: createdBy,
      lastModifiedBy: lastModifiedBy,
      address: address,
    };
  });

  const address = toAddress(account);
  const shortAddress = toShortAddress(account);

  return {
    ...account,
    accountUserName: accountUserFullName,
    contacts: contacts,
    address: address,
    shortAddress: shortAddress,

    [AccountKey.ParentName]: account?.parentAccount?.accountName,
  };
};

export const subtileSearchAccount = (account: Account): string => {
  const accountTemp = {
    ...account,
    accountType: getLabel(accountTypeOptions, account.accountType),
  };

  if (accountTemp.recordType === AccountRecordType.Organization) {
    switch (account.accountType) {
      case AccountType.InsuranceCarrier:
        return account.carrierId
          ? `${accountTemp.accountType} - ${account.carrierId}`
          : accountTemp.accountType;
      case AccountType.Vendor:
        return account.vendorNumber
          ? `${accountTemp.accountType} - ${account.vendorNumber}`
          : accountTemp.accountType;
      default:
        return account.laborNumber
          ? `${accountTemp.accountType} - ${account.laborNumber}`
          : accountTemp.accountType;
    }
  } else {
    switch (account.accountType) {
      case AccountType.Claimant:
      case AccountType.Complainant:
        return account.ssn
          ? `${accountTemp.accountType} - ${account.ssn}`
          : accountTemp.accountType;
      case AccountType.Dependent:
        return accountTemp.accountType;
      case AccountType.Vendor:
        return account.vendorNumber
          ? `${accountTemp.accountType} - ${accountTemp.vendorNumber}`
          : accountTemp.accountType;
      default:
        return account.dcdId
          ? `${accountTemp.accountType} - ${account.dcdId}`
          : accountTemp.accountType;
    }
  }
};

export const toEmployerComments = (
  data: PaginationResponseType<any>
): PaginationResponseType<Comment> => ({
  ...data,
  data: data?.data?.map((item) => ({
    ...item,
    [CommentKey.Avatar]: item?.createdUser?.avatar,
    ...toAuditData(item),
  })),
});
