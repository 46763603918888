import cn from 'classnames';
import React, { HTMLProps, useRef } from 'react';
import shortid from 'shortid';
import { BOOLEAN } from 'src/appConfig/constants';
import { View } from '..';
import Element from '../Element';
import './styles.scss';

export const RadioButton: React.FC<RadioProps> = ({
  label,
  labelClassName,
  containerClassName,
  style,
  disabled,
  ...props
}) => {
  const id = useRef(shortid.generate());
  return (
    <View isRow className={cn('cmp-radio', containerClassName)} style={style}>
      <input
        disabled={disabled}
        id={id.current}
        className={cn('cmp-radio__input')}
        type="radio"
        {...props}
      />
      <label htmlFor={id.current} className={cn('cmp-radio__label', labelClassName)}>
        {label}
      </label>
    </View>
  );
};

type BaseInputProps = Pick<
  HTMLProps<HTMLInputElement>,
  Exclude<keyof HTMLProps<HTMLInputElement>, 'label'>
>;

export type RadioProps = BaseInputProps & {
  label?: React.ReactElement | string;
  labelClassName?: string;
  containerClassName?: string;
};

const castTrueFalseStringToBoolean = (value: string) => {
  return value === BOOLEAN.true ? true : value === BOOLEAN.false ? false : null;
};

const Group: React.FC<RadioGroupProps> = ({
  options,
  value,
  containerClassName,
  onChange = () => {},
  label,
  errorMessage,
  name,
  onBlur,
  columns = 3,
  required,
  isTrueFalseOptions = false,
  disabled = false,
}) => {
  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    const value = target.value;
    onChange(name, isTrueFalseOptions ? castTrueFalseStringToBoolean(value) : value);
  };

  const handleRadioBlur = () => {
    onBlur && onBlur(name, true);
  };

  return (
    <Element
      errorMessage={errorMessage}
      label={label}
      className={containerClassName}
      required={required}
    >
      <View isRow align="center" className="py-8">
        {options?.map(
          (option, index) =>
            !option?.isHide && (
              <RadioButton
                key={`radio-${name}-${index}`}
                name={name}
                disabled={option?.disabled || disabled}
                value={option.value}
                checked={value === option.value}
                label={option.label}
                onChange={handleValueChange}
                containerClassName={cn(columns && 'cmp-radio-groups__column')}
                style={{ width: `${100 / columns}%` }}
                onBlur={handleRadioBlur}
              />
            )
        )}
      </View>
    </Element>
  );
};

export type RadioGroupProps = {
  label?: React.ReactElement | string;
  options?: {
    value: any;
    label: React.ReactElement | string;
    isHide?: boolean;
    disabled?: boolean;
  }[];
  value?: any;
  name?: string;
  onChange?: (name: string, value: any) => void;
  errorMessage?: string;
  containerClassName?: string;
  labelClassName?: string;
  description?: string;
  columns?: number;
  disabled?: boolean;
  onBlur?: (name: string, touched: boolean) => void;
  required?: boolean;
  isTrueFalseOptions?: boolean;
};

export default Group;
