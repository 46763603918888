import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { ApiResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { toTDISFCaseDetail } from './helpers';
import { TDISFCase } from './types';

export function useGetTDISFCase(
  options?: UseQueryOptions<ApiResponseType<{ tdiSFCase: TDISFCase }>, Error, TDISFCase> & {
    id: string;
  }
) {
  const {
    data: tdiSFCase,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetTDISFCase,
  } = useQuery<ApiResponseType<{ tdiSFCase: TDISFCase }>, Error, TDISFCase>(
    [API_QUERIES.TDISFCases, { id: options.id }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<{ tdiSFCase: TDISFCase }>>(
          apiClient.getTDISFCase,
          params
        );
      },
      select: ({ data }) => toTDISFCaseDetail(data?.tdiSFCase),
      enabled: !!options.id,
      ...options,
    }
  );
  const queryClient = useQueryClient();

  const handleInvalidateTDISFCase = () =>
    queryClient.invalidateQueries([API_QUERIES.TDISFCases, { id: options.id }]);

  return {
    tdiSFCase,
    error,
    isError,
    isLoading,
    onGetTDISFCase,
    handleInvalidateTDISFCase,
  };
}
