import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { createResponseWrapper } from '../helpers';
import { TDISFCase } from './types';

export function useEditTDISFCase(options?: UseMutationOptions<TDISFCase, Error, TDISFCase>) {
  const { mutate: editTDISFCase, isLoading: isUpdating } = useMutation<TDISFCase, Error, TDISFCase>(
    {
      mutationFn: (payload: TDISFCase) => createResponseWrapper(apiClient.editTDISFCase, [payload]),
      onError: ErrorService.handler,
      ...options,
    }
  );

  return {
    editTDISFCase,
    isUpdating,
  };
}
