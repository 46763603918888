export enum NonCompliantWCEmployerInsuranceReportKeys {
  Id = 'id',
  DOLNumber = 'laborNumber',
  EmployerId = 'employerId',
  EmployerName = 'employerName',
  EmployerAddress = 'employerAddress',
  CoverageStatus = 'coverageStatus',
  EffectiveDate = 'effectiveDate',
  ExpirationDate = 'expirationDate',
}
