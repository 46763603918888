import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { createResponseWrapper } from 'src/queries/helpers';
import { ErrorService } from 'src/services';
import { CaseSettlementsTypes } from './types';

export function useCreateCaseSettlement(
  options?: UseMutationOptions<CaseSettlementsTypes, Error, CaseSettlementsTypes>
) {
  const { mutate: createCaseSettlement, isLoading } = useMutation<
    CaseSettlementsTypes,
    Error,
    CaseSettlementsTypes
  >({
    mutationFn: (payload: CaseSettlementsTypes) =>
      createResponseWrapper(apiClient.createCaseSettlement, [payload]),
    onError: (error) => ErrorService.handler(error),
    ...options,
  });

  return {
    createCaseSettlement,
    isLoading,
  };
}
