import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { createResponseWrapper } from '../helpers';
import { DecisionSubmitApprovalPayload } from './types';

export function useDecisionSubmitApproval(
  options?: UseMutationOptions<DecisionSubmitApprovalPayload, Error, DecisionSubmitApprovalPayload>
) {
  const { mutate: decisionSubmitApproval, isLoading } = useMutation<
    DecisionSubmitApprovalPayload,
    Error,
    DecisionSubmitApprovalPayload
  >({
    mutationFn: (payload: DecisionSubmitApprovalPayload) =>
      createResponseWrapper(apiClient.transferCaseLocation, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    decisionSubmitApproval,
    isLoading,
  };
}
