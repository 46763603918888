import { useMemo, useState } from 'react';
import { UseInfiniteQueryOptions, useInfiniteQuery, useQueryClient } from 'react-query';
import { useDebounce } from 'src/hooks';
import { isEmpty } from 'src/validations';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { Option } from '../types';
import { subtileSearchAccount } from './helpers';
import { Account, AccountKey } from './types';

const pageParam = {
  take: 10,
  skip: 0,
};

export function useLazyAccount(
  options?: UseInfiniteQueryOptions<{ data: Account[] }, Error> & {
    [key: string]: string | number | string[] | boolean;
  }
) {
  const [inputSearch, setInputSearch] = useState<string>('');
  const debounceSearch = useDebounce(inputSearch);
  const {
    data,
    error,
    isError,
    isFetching,
    fetchNextPage,
    refetch: getAccount,
  } = useInfiniteQuery<{ data: Account[] }, Error>(
    [API_QUERIES.Accounts, pageParam, debounceSearch, { ...options }],
    (props): Promise<{ data: Account[] }> => {
      const { pageParam, queryKey } = props;
      const search = queryKey.at(-2);
      const options = queryKey.at(-1);

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      return responseWrapper<{ data: Account[] }>(apiClient.getAllAccounts, [
        { ...pageParam, search, ...(options as Object) },
      ]);
    },
    {
      keepPreviousData: true,
      getNextPageParam(lastPage, allPages) {
        if (lastPage?.data?.length < 10) return undefined;
        return {
          take: 10,
          skip: allPages?.length * 10,
        };
      },
      notifyOnChangeProps: ['data', 'isFetching'],
      ...options,
    }
  );

  const accounts: Option[] = useMemo(() => {
    if (isEmpty(data)) return [];
    return data?.pages?.reduce(
      (state, page, pageIdx) => [
        ...state,
        ...page.data.map(
          (account, idx): Option => ({
            ...account,
            label: `${account[AccountKey.Account as string]}`,
            value: account?.id,
            index: pageIdx * 10 + idx,
            subLabel: subtileSearchAccount(account),
          })
        ),
      ],
      []
    ) as Option[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const queryClient = useQueryClient();

  const handleInvalidateLazyAccount = () => queryClient.resetQueries(API_QUERIES.Accounts);

  return {
    accounts,
    error,
    isError,
    loading: isFetching,
    getAccount,
    fetchNextPage,
    setInputSearch,
    handleInvalidateLazyAccount,
  };
}
