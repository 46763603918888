import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { ApiResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { toTDI62Detail } from './helpers';
import { TDI62 } from './types';

export function useGetTDI62(
  options?: UseQueryOptions<ApiResponseType<{ tdi62: TDI62 }>, Error, TDI62> & {
    id: TDI62['id'];
  }
) {
  const {
    data: tdi62Detail,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetTDI62ById,
  } = useQuery<ApiResponseType<{ tdi62: TDI62 }>, Error, TDI62>(
    [API_QUERIES.TDI62s, { id: options.id }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<{ tdi62: TDI62 }>>(apiClient.getTDI62Detail, params);
      },
      select: (data) => toTDI62Detail(data?.data?.tdi62),
      enabled: !!options.id,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateTDI62 = () =>
    queryClient.invalidateQueries([API_QUERIES.TDI62s, { id: options.id }]);

  return {
    tdi62Detail,
    error,
    isError,
    isLoading,
    onGetTDI62ById,
    handleInvalidateTDI62,
  };
}
