import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { EmploymentAtTimeOfII } from './types';

export function useEditEmploymentAtTimeOfII(
  options?: UseMutationOptions<EmploymentAtTimeOfII, Error, EmploymentAtTimeOfII>
) {
  const {
    mutate: editEmploymentAtTimeOfII,
    isLoading: isUpdating,
    isSuccess,
    isError,
    error,
  } = useMutation<EmploymentAtTimeOfII, Error, EmploymentAtTimeOfII>({
    mutationFn: (payload: EmploymentAtTimeOfII) =>
      responseWrapper(apiClient.editEmploymentAtTimeOfII, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    editEmploymentAtTimeOfII,
    isUpdating,
    isSuccess,
    isError,
    error,
  };
}
