import { MenuItem, MenuList } from '@mui/material';
import cn from 'classnames';
import React, { useState } from 'react';
import { AiFillSetting } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import { COLOR_CODE } from 'src/appConfig/constants';
import MuiPopOverFilter from 'src/components/MuiPopOverFilter';
import { Text, View } from 'src/modules/shared-main/components';
import { showDialog } from 'src/redux/dialog/dialogSlice';
import { DIALOG_TYPES } from 'src/redux/dialog/type';
import CustomTableView from '../CustomTableView';
import { useTableProvider } from '../TableProvider';
import DeleteViewDialog from './DeleteViewDialog';
import './styles.scss';

const ListViewControl: React.FC = () => {
  const { view, allFields, appName, object, tag, service, customTableViewProps } =
    useTableProvider();

  const { canBeUpdated, canBeDeleted } = view || {};
  const dispatch = useDispatch();

  const [isShow, setIsShow] = useState<boolean>(false);

  const handleShowAddFilter = (isEdit = false) => {
    setIsShow(false);
    dispatch(
      showDialog({
        type: DIALOG_TYPES.CONTENT_DIALOG,
        data: {
          content: (
            <CustomTableView
              service={service}
              view={isEdit && view}
              object={object}
              tag={tag}
              allFields={allFields}
              appName={appName}
              customTableViewProps={customTableViewProps}
            />
          ),
          title: isEdit ? 'Edit List View' : 'New List View',
        },
      })
    );
  };

  const handleDeleteView = () => {
    dispatch(
      showDialog({
        type: DIALOG_TYPES.CONTENT_DIALOG,
        data: {
          title: 'Delete List View',
          content: <DeleteViewDialog service={service} view={view} />,
        },
      })
    );
  };

  return (
    <MuiPopOverFilter
      isShow={isShow}
      onShow={setIsShow}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      label={
        <View
          isRowWrap
          align="center"
          className={cn('cmp-filter-container__label', { 'is-focus': isShow })}
        >
          <AiFillSetting className="cmp-filter-icon__icon" size={24} color={COLOR_CODE.GRAY_600} />
        </View>
      }
      body={
        <View className="text-color-grey-900">
          <Text className="pt-8 px-16">LIST VIEW CONTROLS</Text>
          <MenuList>
            <MenuItem onClick={() => handleShowAddFilter()}>New</MenuItem>
            {canBeUpdated && <MenuItem onClick={() => handleShowAddFilter(true)}>Edit</MenuItem>}
            {canBeDeleted && <MenuItem onClick={handleDeleteView}>Delete</MenuItem>}
          </MenuList>
        </View>
      }
    />
  );
};

export default ListViewControl;
