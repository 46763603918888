import cn from 'classnames';
import { FaFilter } from 'react-icons/fa';
import React, { useEffect, useState } from 'react';
import { Grid } from 'src/modules/shared-main/components';
import MuiPopOverFilter from 'src/components/MuiPopOverFilter';
import { TDI21sAnnualReportsNotFiledKey, Tag } from 'src/queries';
import {
  Operator,
  View as ViewType,
  useGetViewDetail,
  useUpdateView,
} from 'src/modules/shared-main/queries';
import { View, Text, DatePicker, Button } from 'src/modules/shared-main/components';
import { COLOR_CODE } from 'src/appConfig/constants';

const ReportCalendarYearFilter: React.FC<Props> = ({ view, tags }) => {
  const { id, canBeUpdated, object, filters: viewFilters } = view || {};

  const [isShow, setIsShow] = useState<boolean>(false);
  const [year, setYear] = useState<string>('');

  useEffect(() => {
    const calendarYearFilter = viewFilters?.find(
      (filter) => filter.field === TDI21sAnnualReportsNotFiledKey.CalendarYear
    );
    setYear(calendarYearFilter?.value as string);
  }, [viewFilters]);

  const { handleInvalidateAllViewDetail } = useGetViewDetail({
    id: id,
    object: object,
    tags: tags,
    enabled: false,
  });

  const { updateView, isLoading } = useUpdateView({
    onSuccess: () => {
      handleInvalidateAllViewDetail();
    },
  });

  const handleApplyFilter = () => {
    const updatedView = {
      ...view,
      filters: viewFilters?.map((filter) =>
        filter.field === TDI21sAnnualReportsNotFiledKey.CalendarYear
          ? { ...filter, op: Operator.Equals, value: year }
          : filter
      ),
      applyFilter: true,
      logic: {
        AND: viewFilters?.map(({ name }) => name),
      },
    };

    updateView(updatedView);
  };

  return (
    <MuiPopOverFilter
      isShow={isShow}
      onShow={setIsShow}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      disabled={!view || !canBeUpdated}
      label={
        <View
          isRowWrap
          align="center"
          className={cn('cmp-filter-container__label', { 'is-focus': isShow })}
        >
          <FaFilter className="cmp-filter-icon__icon" color={COLOR_CODE.GRAY_600} />
        </View>
      }
      body={
        <Grid.Wrap className="cmp-filter-container__form pb-8">
          <Grid.Item variant="is-full">
            <Text size={20} className="fw-bold text-color-grey-900">
              Filter
            </Text>
          </Grid.Item>
          <Grid.Item variant="is-full">
            <DatePicker
              label="Calendar Year"
              required
              showYearPicker
              dateFormat="yyyy"
              value={year}
              onChange={(_, value) => {
                setYear(new Date(value).toISOString());
              }}
            />
          </Grid.Item>
          <Grid.Item variant="is-full">
            <View isRowWrap>
              <Button
                onClick={() => {
                  setIsShow(false);
                }}
                variant="outline"
                isFull
                className="mr-12"
              >
                Cancel
              </Button>
              <Button isLoading={isLoading} onClick={handleApplyFilter} isFull>
                Apply
              </Button>
            </View>
          </Grid.Item>
        </Grid.Wrap>
      }
    />
  );
};

type Props = {
  view: ViewType;
  tags?: Tag;
};

export default ReportCalendarYearFilter;
