import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { HC15 } from './types';

export function useUpdateHC15(options?: UseMutationOptions<HC15, Error, HC15>) {
  const {
    mutate: onUpdateHC15,
    isLoading: isUpdating,
    isError,
    error,
  } = useMutation<HC15, Error, HC15>({
    mutationFn: (payload: HC15) => responseWrapper(apiClient.updateHC15, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    onUpdateHC15,
    isUpdating,
    isError,
    error,
  };
}
