import { TableColumn } from 'src/modules/shared-main/components/Table/helpers';

import { Yup } from 'src/services';
import { FieldType } from './DragDrop/helpers';
import { RestrictGroup, View } from 'src/modules/shared-main/queries';
import { arraySortByAnotherArray, sortByName } from 'src/modules/shared-main/common';

export const initialValues = (view: View, defaultVisibilityRestrict: RestrictGroup): ViewForm => ({
  name: view?.name ?? '',
  visibility: view?.visibility ?? defaultVisibilityRestrict,
  fields: view?.fields ?? [],
  groupIds: view?.approvalGroups ?? [],
});

export interface ViewForm {
  name: string;
  visibility: RestrictGroup;
  fields: Array<string>;
  groupIds: Array<string>;
}

export enum ViewKey {
  Name = 'name',
  Visibility = 'visibility',
  Fields = 'fields',
  GroupIds = 'groupIds',
}

export const viewSchema = Yup.object().shape({
  [ViewKey.Name]: Yup.string().nullable().required(),
  [ViewKey.Visibility]: Yup.string().nullable().required(),
  [ViewKey.Fields]: Yup.array()
    .nullable()
    .min(1, 'This field is required.')
    .max(15, 'Only select a maximum of 15 fields.'),
  [ViewKey.GroupIds]: Yup.array()
    .nullable()
    .when(ViewKey.Visibility, {
      is: RestrictGroup.Groups,
      then: Yup.array().min(1, 'At least one group must be selected.'),
    }),
});

export const toListFields = (allFields: TableColumn[], selectedFields: Array<string>) =>
  arraySortByAnotherArray(sortByName(allFields, 'label'), [], 'name').map((fields) => ({
    ...fields,
    category: selectedFields.includes(fields.name) ? FieldType.Selected : FieldType.Available,
    renderIf: fields.renderIf ?? true,
  }));
