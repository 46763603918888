import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { PaginationResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { GetPropertiesParams } from '../types';
import { TDIAndPHCPhysicalFilePurgeReport } from './types';

export function useGetTDIAndPHCPhysicalFilePurgeReport(
  options?: UseQueryOptions<PaginationResponseType<TDIAndPHCPhysicalFilePurgeReport>, Error>
) {
  const [params, setParams] = useState<GetPropertiesParams>({});

  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetTDIAndPHCPhysicalFilePurgeReport,
  } = useQuery<PaginationResponseType<TDIAndPHCPhysicalFilePurgeReport>, Error>(
    [API_QUERIES.TDI_AND_PHC_PHYSICAL_FILE_PURGE, { ...params, ...options }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<PaginationResponseType<TDIAndPHCPhysicalFilePurgeReport>>(
          apiClient.getTDIAndPHCPhysicalFilePurgeReport,
          params
        );
      },
      onError: ErrorService.handler,
      notifyOnChangeProps: ['data', 'isFetching'],
      keepPreviousData: true,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateTDIAndPHCPhysicalFilePurgeReport = () =>
    queryClient.invalidateQueries(API_QUERIES.TDI_AND_PHC_PHYSICAL_FILE_PURGE);

  const { data: tdiAndPHCPhysicalFilePurgeReport, hasNext, payloadSize, totalRecords } = data || {};

  return {
    tdiAndPHCPhysicalFilePurgeReport,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isError,
    isFetching,
    onGetTDIAndPHCPhysicalFilePurgeReport,
    setParams,
    handleInvalidateTDIAndPHCPhysicalFilePurgeReport,
  };
}
