import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { ApiResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { GetPropertiesParams } from '../types';
import { TypePayload } from './types';

export function useGetTypePurpose(
  options?: UseQueryOptions<ApiResponseType<TypePayload>, Error> & {
    hearingId?: string;
  }
) {
  const [params, setParams] = useState<GetPropertiesParams>({});
  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetTypePurpose,
  } = useQuery<ApiResponseType<TypePayload>, Error>(
    [API_QUERIES.TYPE_PURPOSES, { ...params, hearingId: options?.hearingId }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<TypePayload>>(apiClient.getTypePurposes, params);
      },
      notifyOnChangeProps: ['data', 'isFetching'],
      keepPreviousData: true,
      enabled: !!options.hearingId,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateTypePurpose = () => {
    queryClient.invalidateQueries([API_QUERIES.TYPE_PURPOSES, { hearingId: options?.hearingId }]);
  };

  const { types: typePurpose } = data?.data || {};

  return {
    typePurpose,
    error,
    isError,
    isFetching,
    onGetTypePurpose,
    handleInvalidateTypePurpose,
    setParams,
  };
}
