import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { createResponseWrapper } from '../helpers';
import { TransferCaseLocationPayload } from './types';

export function useTransferCaseLocation(
  options?: UseMutationOptions<TransferCaseLocationPayload, Error, TransferCaseLocationPayload>
) {
  const { mutate: transferCaseLocation, isLoading } = useMutation<
    TransferCaseLocationPayload,
    Error,
    TransferCaseLocationPayload
  >({
    mutationFn: (payload: TransferCaseLocationPayload) =>
      createResponseWrapper(apiClient.transferCaseLocation, [payload]),
    onError: (error) => ErrorService.handler(error),
    ...options,
  });

  return {
    transferCaseLocation,
    isLoading,
  };
}
