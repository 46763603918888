export enum RequestReconsiderationKey {
  Id = 'id',
  DecisionId = 'decisionId',
  RequestNumber = 'requestNumber',
  RequestDate = 'requestDate',
  RequestStatus = 'requestStatus',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  CreatedUser = 'createdUser',
  UpdatedUser = 'updatedUser',
}
