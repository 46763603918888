import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { createResponseWrapper } from '../helpers';
import { VocationalRehabilitation } from './types';

export function useCreateVRProcess(
  options?: UseMutationOptions<VocationalRehabilitation, Error, VocationalRehabilitation>
) {
  const { mutate: createVrProcess, isLoading } = useMutation<
    VocationalRehabilitation,
    Error,
    VocationalRehabilitation
  >({
    mutationFn: (payload: VocationalRehabilitation) =>
      createResponseWrapper(apiClient.createVocationalRehabilitation, [payload]),
    onError: (error) => ErrorService.handler(error),
    ...options,
  });

  return {
    createVrProcess,
    isLoading,
  };
}
