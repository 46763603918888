export enum WC3SubmittalKey {
  Id = 'id',
  Historical = 'isHistorical',
  FormNumber = 'formNumber',
  CaseId = 'caseId',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  CreatedBy = 'createdBy',
  UpdatedBy = 'updatedBy',
  NewAmend = 'newAmend',
  DateReceived = 'dateReceived',
  SignaturePrintedName = 'signaturePrintedName',
  SignaturePhone = 'signaturePhone',
  CarrierID = 'carrierId',
  SignatureEmail = 'signatureEmail',
  SignatureDate = 'signatureDate',
  OnBehalfOf = 'onBehalfOf',
  DateOfInjury = 'dateOfInjury',
  WC3Type = 'wc3Type',
  WC3TypeValue = 'wc3TypeValue',
  WC3TypeAdditionInformation = 'wc3TypeAdditionInformation',
  ReturnToWorkDate = 'returnToWorkDate',
  WeeklyCompensationRate = 'weeklyCompensationRate',
  BenefitAdjustment = 'benefitAdjustment',
  BenefitReimbursement = 'benefitReimbursement',
  CarrierComment = 'carrierComment',
  MedicalDeductible = 'medicalDeductible',
  FormType = 'formType',
  ClaimantId = 'claimantId',
  EmployerId = 'employerId',
  InsuranceCarrierId = 'insuranceCarrierId',
  AdjusterId = 'adjusterId',
  EmployerPointOfContactId = 'employerPointOfContactId',
  Case = 'case',
  Claimant = 'claimant',
  Employer = 'employer',
  InsuranceCarrier = 'insuranceCarrier',
  EmployerPointOfContact = 'employerPointOfContact',
  Adjuster = 'adjuster',
  CreatedUser = 'createdUser',
  UpdatedUser = 'updatedUser',
  CaseNumber = 'caseNumber',
  CaseNumberFilterKey = 'case.caseNumber',
  ClaimantSSN = 'claimantSSN',
  ClaimantPassportNumber = 'claimantPassportNumber',
  ClaimantAddress = 'claimantAddress',
  ClaimantCity = 'claimantCity',
  ClaimantState = 'claimantState',
  ClaimantZipCode = 'claimantZipcode',
  ClaimantEmail = 'claimantEmail',
  ClaimantPhone = 'claimantPhone',
  ClaimantDateOfInjury = 'claimantDateOfInjury',
  EmployerDbaName = 'employerDbaName',
  EmployerAddress = 'employerAddress',
  EmployerCity = 'employerCity',
  EmployerState = 'employerState',
  EmployerZipCode = 'employerZipcode',
  EmployerPOCPhone = 'employerPOCPhone',
  EmployerPOCEmail = 'employerPOCEmail',
  EmployerDOL = 'employerLaborNumber',
  CarrierNumber = 'carrierNumber',
  AdjusterPhone = 'adjusterPhone',
  AdjusterAddress = 'adjusterAddress',
  AdjusterIdNumber = 'adjusterIdNumber',
  AdjusterEmail = 'adjusterEmail',
  PaymentYear = 'paymentYear',
  InsuranceCarrierClaimNumber = 'carrierClaimNumber',
  InsuranceCarrierPolicyNumber = 'carrierPolicyNumber',
  InsuranceCarrierPhoneNumber = 'insuranceCarrierPhoneNumber',
  InsuranceCarrierEmailAddress = 'insuranceCarrierEmailAddress',
  InsuranceCarrierAddress = 'insuranceCarrierAddress',
  InsuranceCarrierPeriodFrom = 'carrierPolicyFrom',
  InsuranceCarrierPeriodTo = 'carrierPolicyTo',
  InsuranceCarrierMedicalDeducible = 'carrierMedicalDeductible',

  // benefit payments summary
  BpsTtdDays = 'bpsTtdDays',
  BpsTtdDateFrom = 'bpsTtdDateFrom',
  BpsTtdDateTo = 'bpsTtdDateTo',
  BpsTtdPriorPayment = 'bpsTtdPriorPayment',
  BpsTtdTotalPaymentToDate = 'bpsTtdTotalPaymentToDate',
  BpsTtdPaymentNotPrevReport = 'bpsTtdPaymentNotPrevReport',
  BpsTpdDays = 'bpsTpdDays',
  BpsTpdDateFrom = 'bpsTpdDateFrom',
  BpsTpdDateTo = 'bpsTpdDateTo',
  BpsTpdPriorPayment = 'bpsTpdPriorPayment',
  BpsTpdTotalPaymentToDate = 'bpsTpdTotalPaymentToDate',
  BpsTpdPaymentNotPrevReport = 'bpsTpdPaymentNotPrevReport',
  BpsPtdDays = 'bpsPtdDays',
  BpsPtdDateFrom = 'bpsPtdDateFrom',
  BpsPtdDateTo = 'bpsPtdDateTo',
  BpsPtdPriorPayment = 'bpsPtdPriorPayment',
  BpsPtdTotalPaymentToDate = 'bpsPtdTotalPaymentToDate',
  BpsPtdPaymentNotPrevReport = 'bpsPtdPaymentNotPrevReport',
  BpsPpdDays = 'bpsPpdDays',
  BpsPpdDateFrom = 'bpsPpdDateFrom',
  BpsPpdDateTo = 'bpsPpdDateTo',
  BpsPpdPriorPayment = 'bpsPpdPriorPayment',
  BpsPpdTotalPaymentToDate = 'bpsPpdTotalPaymentToDate',
  BpsPpdPaymentNotPrevReport = 'bpsPpdPaymentNotPrevReport',
  BpsDeathDays = 'bpsDeathDays',
  BpsDeathDateFrom = 'bpsDeathDateFrom',
  BpsDeathDateTo = 'bpsDeathDateTo',
  BpsDeathPriorPayment = 'bpsDeathPriorPayment',
  BpsDeathTotalPaymentToDate = 'bpsDeathTotalPaymentToDate',
  BpsDeathPaymentNotPrevReport = 'bpsDeathPaymentNotPrevReport',
  BpsCityCountyDays = 'bpsCityCountyDays',
  BpsCityCountyDateFrom = 'bpsCityCountyDateFrom',
  BpsCityCountyDateTo = 'bpsCityCountyDateTo',
  BpsCityCountyPriorPayment = 'bpsCityCountyPriorPayment',
  BpsCityCountyTotalPaymentToDate = 'bpsCityCountyTotalPaymentToDate',
  BpsCityCountyPaymentNotPrevReport = 'bpsCityCountyPaymentNotPrevReport',
  BpsDisfigurementDays = 'bpsDisfigurementDays',
  BpsDisfigurementDateFrom = 'bpsDisfigurementDateFrom',
  BpsDisfigurementDateTo = 'bpsDisfigurementDateTo',
  BpsDisfigurementPriorPayment = 'bpsDisfigurementPriorPayment',
  BpsDisfigurementTotalPaymentToDate = 'bpsDisfigurementTotalPaymentToDate',
  BpsDisfigurementPaymentNotPrevReport = 'bpsDisfigurementPaymentNotPrevReport',
  BpsMedicalDays = 'bpsMedicalDays',
  BpsMedicalDateFrom = 'bpsMedicalDateFrom',
  BpsMedicalDateTo = 'bpsMedicalDateTo',
  BpsMedicalPriorPayment = 'bpsMedicalPriorPayment',
  BpsMedicalTotalPaymentToDate = 'bpsMedicalTotalPaymentToDate',
  BpsMedicalPaymentNotPrevReport = 'bpsMedicalPaymentNotPrevReport',
  BpsMaterialDays = 'bpsMaterialDays',
  BpsMaterialDateFrom = 'bpsMaterialDateFrom',
  BpsMaterialDateTo = 'bpsMaterialDateTo',
  BpsMaterialPriorPayment = 'bpsMaterialPriorPayment',
  BpsMaterialTotalPaymentToDate = 'bpsMaterialTotalPaymentToDate',
  BpsMaterialPaymentNotPrevReport = 'bpsMaterialPaymentNotPrevReport',
  BpsSvcAttendantDays = 'bpsSvcAttendantDays',
  BpsSvcAttendantDateFrom = 'bpsSvcAttendantDateFrom',
  BpsSvcAttendantDateTo = 'bpsSvcAttendantDateTo',
  BpsSvcAttendantPriorPayment = 'bpsSvcAttendantPriorPayment',
  BpsSvcAttendantTotalPaymentToDate = 'bpsSvcAttendantTotalPaymentToDate',
  BpsSvcAttendantPaymentNotPrevReport = 'bpsSvcAttendantPaymentNotPrevReport',
  BpsRehabDays = 'bpsRehabDays',
  BpsRehabDateFrom = 'bpsRehabDateFrom',
  BpsRehabDateTo = 'bpsRehabDateTo',
  BpsRehabPriorPayment = 'bpsRehabPriorPayment',
  BpsRehabTotalPaymentToDate = 'bpsRehabTotalPaymentToDate',
  BpsRehabPaymentNotPrevReport = 'bpsRehabPaymentNotPrevReport',
  BpsReimbursementDays = 'bpsReimbursementDays',
  BpsReimbursementDateFrom = 'bpsReimbursementDateFrom',
  BpsReimbursementDateTo = 'bpsReimbursementDateTo',
  BpsReimbursementPriorPayment = 'bpsReimbursementPriorPayment',
  BpsReimbursementTotalPaymentToDate = 'bpsReimbursementTotalPaymentToDate',
  BpsReimbursementPaymentNotPrevReport = 'bpsReimbursementPaymentNotPrevReport',
  BpsAdjustmentDays = 'bpsAdjustmentDays',
  BpsAdjustmentDateFrom = 'bpsAdjustmentDateFrom',
  BpsAdjustmentDateTo = 'bpsAdjustmentDateTo',
  BpsAdjustmentPriorPayment = 'bpsAdjustmentPriorPayment',
  BpsAdjustmentTotalPaymentToDate = 'bpsAdjustmentTotalPaymentToDate',
  BpsAdjustmentPaymentNotPrevReport = 'bpsAdjustmentPaymentNotPrevReport',
  BpsOther1Days = 'bpsOther1Days',
  BpsOther1DateFrom = 'bpsOther1DateFrom',
  BpsOther1DateTo = 'bpsOther1DateTo',
  BpsOther1PriorPayment = 'bpsOther1PriorPayment',
  BpsOther1TotalPaymentToDate = 'bpsOther1TotalPaymentToDate',
  BpsOther1PaymentNotPrevReport = 'bpsOther1PaymentNotPrevReport',
  BpsOther2Days = 'bpsOther2Days',
  BpsOther2DateFrom = 'bpsOther2DateFrom',
  BpsOther2DateTo = 'bpsOther2DateTo',
  BpsOther2PriorPayment = 'bpsOther2PriorPayment',
  BpsOther2TotalPaymentToDate = 'bpsOther2TotalPaymentToDate',
  BpsOther2PaymentNotPrevReport = 'bpsOther2PaymentNotPrevReport',

  //grand
  BpsGrandTotalDays = 'bpsGrandTotalDays',
  BpsGrandTotalPriorPayment = 'bpsGrandTotalPriorPayment',
  BpsGrandTotalTotalPaymentToDate = 'bpsGrandTotalTotalPaymentToDate',
  BpsGrandTotalPaymentNotPrevReport = 'bpsGrandTotalPaymentNotPrevReport',
  //grand filter
  BpsGrandTotalDaysFilter = 'bpsGrandTotal.days',
  BpsGrandTotalPriorPaymentFilter = 'bpsGrandTotal.priorPayment',
  BpsGrandTotalTotalPaymentToDateFilter = 'bpsGrandTotal.totalPaymentToDate',
  BpsGrandTotalPaymentNotPrevReportFilter = 'bpsGrandTotal.paymentNotPrevReport',
}
