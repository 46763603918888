import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { authResponseWrapper } from 'src/queries/helpers';
import { Toastify } from 'src/services';
import { BenefitPaymentsDetailType } from './types';

export function useDeleteBenefitPayment(
  options?: UseMutationOptions<any, Error, BenefitPaymentsDetailType['id']>
) {
  const { mutate: deleteBenefitPayment, isLoading } = useMutation<
    any,
    Error,
    BenefitPaymentsDetailType['id']
  >({
    mutationFn: (payload: BenefitPaymentsDetailType['id']) =>
      authResponseWrapper(apiClient.deleteBenefitPayment, [payload]),
    ...options,
    onError: (err) => Toastify.error(err.message),
  });

  return {
    deleteBenefitPayment,
    isLoading,
  };
}
