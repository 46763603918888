import React from 'react';
import ReportCalendarYearFilter from 'src/containers/ReportManagement/CustomReportFilter/ReportCalendarYearFilter';
import ReportGroupRangeDateFilter from 'src/containers/ReportManagement/CustomReportFilter/ReportGroupRangeDateFilter';
import { ReportId } from 'src/containers/ReportManagement/helpers';
import { Table as TableCommon } from 'src/modules/shared-main/components';
import { TableWrapperProps } from 'src/modules/shared-main/components/Table';

const customReportFilter = {
  [ReportId.TDI21sAnnualReportsNotFiled]: ReportCalendarYearFilter,
  [ReportId.TDI21sAnnualException]: ReportCalendarYearFilter,
  [ReportId.TDI21sAnnualReportSummaryByPlanType]: ReportCalendarYearFilter,
  [ReportId.RejectedPHCSelfInsurance]: ReportGroupRangeDateFilter,
  [ReportId.ApprovedHealthCarePlans]: ReportGroupRangeDateFilter,
  [ReportId.TDI15sTDISelfInsurerPlanReport]: ReportGroupRangeDateFilter,
  [ReportId.HC15s]: ReportGroupRangeDateFilter,
  [ReportId.EmployerRegistrationActivities]: ReportGroupRangeDateFilter,
  [ReportId.TDI46ReconsiderationsAppeals]: ReportGroupRangeDateFilter,
  [ReportId.TDI62s]: ReportGroupRangeDateFilter,
  [ReportId.TDI30s]: ReportGroupRangeDateFilter,
};

const Table: React.FC<TableWrapperProps> = (props) => {
  const { filterByObject } = props || {};

  // eslint-disable-next-line security/detect-object-injection
  const CustomReportFilter = customReportFilter[filterByObject];

  return <TableCommon {...props} customFilterRender={CustomReportFilter} />;
};

export default Table;
