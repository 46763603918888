import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { authResponseWrapper } from 'src/queries/helpers';
import { ErrorService } from 'src/services';

export function useDeleteHC4(options?: UseMutationOptions<string, Error, string>) {
  const { mutate: deleteHC4, isLoading } = useMutation<string, Error, string>({
    mutationFn: (payload: string) => authResponseWrapper(apiClient.deleteHC4, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    deleteHC4,
    isLoading,
  };
}
