import { Form, FormikProvider, useFormik } from 'formik';
import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AppName } from 'src/appConfig/constants';
import {
  Accordion,
  Button,
  Input,
  RadioButton,
  SearchableSelect,
  View,
} from 'src/modules/shared-main/components';
import { TableColumn } from 'src/modules/shared-main/components/Table/helpers';
import {
  RestrictGroup,
  Service,
  View as ViewType,
  useCreateView,
  useGetViewDetail,
  useGetViews,
  useUpdateView,
} from 'src/modules/shared-main/queries';
import { useGetLazyGroup } from 'src/modules/shared-main/queries/Groups';
import { Tag, ViewObject } from 'src/queries';
import { hideAllDialog, hideDialog } from 'src/redux/dialog/dialogSlice';
import { Toastify } from 'src/services';
import { isEmpty } from 'src/validations';
import Text from '../../Text';
import DragDrop from './DragDrop';
import { ViewForm, ViewKey, initialValues, toListFields, viewSchema } from './helpers';
import { ListViewControlProps } from '../TableProvider';

const VISIBILITY_OPTION = [
  {
    label: (
      <Text>
        Visible only to <b>me</b>
      </Text>
    ),
    value: RestrictGroup.OnlyMe,
  },
  {
    label: (
      <Text>
        Visible to <b>all users</b>
      </Text>
    ),
    value: RestrictGroup.AllUsers,
  },
  {
    label: (
      <Text>
        Visible to certain <b>groups of users</b>
      </Text>
    ),
    value: RestrictGroup.Groups,
  },
];

const CustomTableView: React.FC<Props> = ({
  object,
  view,
  tag,
  service,
  appName,
  allFields,
  customTableViewProps,
}) => {
  const dispatch = useDispatch();

  const { id, fields: viewFields } = view || {};
  const { hideVisibilityRestrict, defaultVisibilityRestrict } = customTableViewProps || {};

  const [errorMessage, setErrorMessage] = useState<string>('');

  const { setInputSearch, fetchNextPage, groups } = useGetLazyGroup();

  const { handleInvalidateViews } = useGetViews({ object, enabled: false });

  const { createView, isLoading } = useCreateView({
    onSuccess: () => {
      Toastify.success(`${values.name} created successfully.`);
      dispatch(hideDialog());
      handleInvalidateViews();
    },
    onError: ({ message }) => setErrorMessage(message),
  });

  const { handleInvalidateAllViewDetail } = useGetViewDetail({ id, object, service });

  const { updateView, isLoading: isUpdating } = useUpdateView({
    onSuccess: () => {
      Toastify.success('List view updated successfully.');
      dispatch(hideAllDialog());
      handleInvalidateViews();
      handleInvalidateAllViewDetail();
    },
    onError: ({ message }) => setErrorMessage(message),
  });

  const handleSubmit = (values: ViewForm) => {
    if (view) {
      updateView({
        ...view,
        ...values,
        service,
        groupIds: isEmpty(values?.groupIds) ? null : values?.groupIds.map((id) => +id),
      });
    } else {
      createView({
        ...values,
        object,
        appName,
        tags: tag,
        service,
        groupIds: isEmpty(values?.groupIds) ? null : values?.groupIds.map((id) => +id),
      });
    }
  };

  const fields = useMemo(
    () =>
      toListFields(
        allFields,
        !view
          ? allFields
              ?.filter(({ isDefaultSelected }) => !!isDefaultSelected)
              .map(({ name }) => name)
          : viewFields
      ),
    [allFields, viewFields, view]
  );

  const initialViews = useMemo(
    () => initialValues(view, defaultVisibilityRestrict),
    [view, defaultVisibilityRestrict]
  );

  const formik = useFormik<ViewForm>({
    enableReinitialize: true,
    initialValues: initialViews,
    onSubmit: handleSubmit,
    validationSchema: viewSchema,
  });

  const {
    getFieldProps,
    handleSubmit: handleFormSubmit,
    touched,
    errors,
    setFieldValue,
    values,
    setFieldTouched,
  } = formik || {};

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
    setErrorMessage('');
  };

  return (
    <FormikProvider value={formik}>
      <Form onSubmit={handleFormSubmit} autoComplete="off">
        <View>
          <Accordion collapsible={false} title="Step 1. Enter View Name">
            <Input
              label="View Name"
              required
              {...getFieldProps(ViewKey.Name)}
              errorMessage={
                touched[ViewKey.Name] ? (errors[ViewKey.Name] as string) || errorMessage : ''
              }
              onChange={handleChangeName}
            />
          </Accordion>
          <Accordion collapsible={false} title="Step 2. Select Fields to Display">
            <DragDrop fields={fields.filter(({ renderIf }) => !!renderIf)} />
          </Accordion>
          {!hideVisibilityRestrict && (
            <Accordion collapsible={false} title="Step 3. Restrict Visibility">
              <RadioButton
                columns={1}
                {...getFieldProps(ViewKey.Visibility)}
                options={VISIBILITY_OPTION}
                onChange={setFieldValue}
              />
              {values[ViewKey.Visibility] === RestrictGroup.Groups && (
                <SearchableSelect
                  filterOption={null}
                  options={groups}
                  label="Search"
                  required
                  isMulti
                  {...getFieldProps(ViewKey.GroupIds)}
                  onChange={setFieldValue}
                  onInputChange={setInputSearch}
                  onBlur={setFieldTouched}
                  fetchNextPage={fetchNextPage}
                  errorMessage={
                    touched[ViewKey.GroupIds] ? (errors[ViewKey.GroupIds] as string) : ''
                  }
                />
              )}
            </Accordion>
          )}
          <View isRowWrap justify="flex-end" className="pt-16">
            <Button
              disabled={isLoading || isUpdating}
              onClick={() => dispatch(hideAllDialog())}
              variant="outline"
            >
              Cancel
            </Button>
            <Button isLoading={isLoading || isUpdating} type="submit" className="ml-8">
              Save
            </Button>
          </View>
        </View>
      </Form>
    </FormikProvider>
  );
};

type Props = {
  object: ViewObject;
  view: ViewType;
  tag: Tag;
  service?: Service;
  appName: AppName;
  allFields: TableColumn[];
  customTableViewProps: ListViewControlProps;
};

export default CustomTableView;
