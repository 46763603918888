/* eslint-disable security/detect-object-injection */
import cn from 'classnames';
import { flatten } from 'lodash';
import {
  Display,
  MUIDataTableCustomHeadRenderer,
  MUIDataTableOptions,
  MUIDataTableState,
  MUISortOptions,
} from 'mui-datatables';
import React, { memo, useEffect, useMemo, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import appConfig from 'src/appConfig';
import { emptyFunction } from 'src/utils';
import { isEmpty } from 'src/validations';
import { TableProps } from '..';
import TableBasic from '../TableBasic';
import TableHead from '../TableHead';
import TableTitle from '../TableTitle';
import { useTableProvider } from '../TableProvider';
import { arraySortByAnotherArray } from 'src/modules/shared-main/common';

enum TableQueryParams {
  SEARCH = 'search',
  ROWS_PER_PAGE = 'rowsPerPage',
  PAGE = 'page',
  SORT = 'sort',
  FILTER = 'filter',
}

export enum TableKey {
  Action = 'action',
}

const TableWrapper: React.FC<TableProps> = ({
  isLoading,
  title,
  data,
  tableOptions,
  defaultSortOrder,
  emptyComponent,
  onAction = emptyFunction,
  containerClassName = '',
  additionalFilterParams = ['searchText'],
  isNoQueryAction = false,
  customFilterRender,
}) => {
  const {
    object,
    columns,
    noWrapText,
    view,
    wrapClipColumns,
    isRelatedList,
    isLoadingView,
    currentViewId,
  } = useTableProvider();
  const { fields = [] } = view || {};

  const allViewColumns = useMemo(() => [...fields, TableKey.Action], [fields]);
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const tableStateRef = useRef<MUIDataTableState>();

  useEffect(() => {
    handleTriggerAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, currentViewId]);

  const getInitialTableState = (queryParams: URLSearchParams): Partial<MUIDataTableOptions> =>
    isNoQueryAction ? getTableInitialStateRef() : getTableInitialStateQuery(queryParams);

  const getTableInitialStateQuery = (
    queryParams: URLSearchParams
  ): Partial<MUIDataTableOptions> => {
    let sortOrder;
    if (queryParams?.get('sort')?.includes(':')) {
      const sortOrderSplit = queryParams?.get('sort')?.split(':');
      if (sortOrderSplit.length === 2 && ['asc', 'desc'].includes(sortOrderSplit[1])) {
        sortOrder = {
          name: sortOrderSplit[0],
          direction: sortOrderSplit[1],
        };
      }
    } else {
      sortOrder = defaultSortOrder;
    }

    return {
      searchText: queryParams?.get('search'),
      sortOrder,
      rowsPerPageOptions: appConfig.ROWS_PER_PAGE_OPTIONS,
      rowsPerPage: queryParams?.has('rowsPerPage')
        ? Number(queryParams.get('rowsPerPage'))
        : isRelatedList
        ? appConfig.RELATED_LIST_ROWS_PER_PAGE
        : appConfig.ROWS_PER_PAGE,
      page: queryParams?.has('page') ? Number(queryParams.get('page')) : 0,
    };
  };

  const getTableInitialStateRef = (): Partial<MUIDataTableOptions> => ({
    rowsPerPage: tableStateRef?.current?.rowsPerPage ?? appConfig.ROWS_PER_PAGE,
    rowsPerPageOptions: appConfig.ROWS_PER_PAGE_OPTIONS,
    page: tableStateRef?.current?.page || 0,
  });

  const currentState = getInitialTableState(query);

  const currentFilterList = query?.getAll('filter')?.map((f) => (f ? f.split(',') : []));

  const getFilterParams = (filterList?: string[][]) => {
    if (!filterList) return {};
    const params: any = {};

    filterList.forEach((filter: string[], idx: number) => {
      if (filter.length > 0) {
        const column = columns[idx];
        const name = column?.name;
        params[name] = filter;
      }
    });

    return params;
  };

  const getAdditionalParams = (filterList: string[]) => {
    if (isEmpty(filterList)) return {};

    return filterList.reduce((state, key) => {
      const value = query.get(key);
      if (value)
        return {
          ...state,
          [key]: value,
        };
      return state;
    }, {});
  };

  const getActionParams = () => {
    const rowsPerPage = currentState?.rowsPerPage;
    const page = currentState?.page;

    const filterTableParams = getFilterParams(currentFilterList);
    const additionalParams: any = getAdditionalParams(additionalFilterParams);

    let orderParam = null;
    if (!isEmpty(currentState?.sortOrder?.name) && !isEmpty(currentState?.sortOrder?.direction)) {
      orderParam = `${currentState?.sortOrder?.name}:${currentState?.sortOrder?.direction}`;
    }

    const hasPagination = tableOptions?.pagination ?? true;

    const params = {
      take: hasPagination ? rowsPerPage : null,
      skip: page * rowsPerPage,
      order: orderParam,
      search: additionalParams?.searchText ?? '',
      ...filterTableParams,
      ...additionalParams,
    };

    return params;
  };

  const setQueryParams = () => {
    const tableState = tableStateRef.current;

    if (tableState?.searchText) {
      query.set(TableQueryParams.SEARCH, tableState.searchText);
    } else {
      query.delete(TableQueryParams.SEARCH);
    }

    if (tableState?.rowsPerPage) {
      const rowsPerPage = tableState.rowsPerPage.toString();
      query.set(TableQueryParams.ROWS_PER_PAGE, rowsPerPage);
    } else {
      query.delete(TableQueryParams.ROWS_PER_PAGE);
    }

    if (tableState?.page) {
      const page = tableState.page.toString();
      query.set(TableQueryParams.PAGE, page);
    } else {
      query.delete(TableQueryParams.PAGE);
    }

    if (tableState?.sortOrder.name && tableState?.sortOrder.direction) {
      const sort = `${tableState?.sortOrder.name}:${tableState?.sortOrder.direction}`;
      query.set(TableQueryParams.SORT, sort);
    } else {
      query.delete(TableQueryParams.SORT);
    }

    if (tableState?.filterList && flatten(tableState.filterList).length > 0) {
      query.delete(TableQueryParams.FILTER);
      tableState.filterList.forEach((f) => {
        query.append(TableQueryParams.FILTER, f.join(','));
      });
    } else {
      query.delete(TableQueryParams.FILTER);
    }

    history.push({ search: query.toString() });
  };

  const handleTableChangeWithoutQuery = () => {
    if (!isNoQueryAction) return;

    const params = {
      take: appConfig.ROWS_PER_PAGE,
      skip: 0,
    };

    const tableState = tableStateRef.current;

    if (tableState?.rowsPerPage) {
      params.take = tableState.rowsPerPage;
    }
    if (tableState?.page) {
      params.skip = tableState.page * tableState.rowsPerPage;
    }
    onAction(params);
  };

  const handleTriggerAction = () => {
    const params = getActionParams();
    onAction({ ...params, viewId: currentViewId });
  };

  const handleTableChange = async (action: any, tableState: MUIDataTableState) => {
    tableStateRef.current = tableState;
    switch (action) {
      case 'sort':
      case 'filterChange':
      case 'changePage':
      case 'search':
      case 'resetFilters':
      case 'changeRowsPerPage':
        isNoQueryAction ? handleTableChangeWithoutQuery() : setQueryParams();
        break;
      default:
        break;
    }
  };

  const allColumns = useMemo(
    () =>
      arraySortByAnotherArray(
        columns?.map((column, index) => ({
          ...column,
          options: {
            filter: false,
            sort: true,
            setCellProps: () => ({
              className: cn(
                { 'text-clip': !noWrapText && column.name },
                {
                  'text-wrap': wrapClipColumns.includes(column.name),
                }
              ),
            }),
            customBodyRender: column.options?.customBodyRender
              ? column.options.customBodyRender
              : (value: string) => value || '--',
            filterList: currentFilterList[index],
            ...(column.name && column.name !== TableKey.Action && !noWrapText
              ? {
                  customHeadRender: (
                    columnMeta: MUIDataTableCustomHeadRenderer,
                    handleToggleColumn: (columnIndex: number) => void,
                    sortOrder: MUISortOptions
                  ) => {
                    return (
                      <TableHead
                        key={index}
                        columnMeta={columnMeta}
                        handleToggleColumn={handleToggleColumn}
                        sortOrder={sortOrder}
                      />
                    );
                  },
                }
              : null),
            display:
              isEmpty(fields) || !column.name
                ? 'true'
                : (`${allViewColumns?.includes(column.name)}` as Display),
            ...column.options,
          },
        })),
        allViewColumns,
        'name'
      ),
    [allViewColumns, columns, currentFilterList, fields, wrapClipColumns, noWrapText]
  );

  return (
    <TableBasic
      title={title ? title : object && <TableTitle />}
      data={data}
      columns={object ? (!isEmpty(fields) ? allColumns : []) : allColumns}
      options={{
        ...tableOptions,
        ...currentState,
      }}
      onTableChange={handleTableChange}
      containerClassName={cn('cmp-table', { [containerClassName]: containerClassName })}
      isLoading={isLoading || isLoadingView}
      emptyComponent={emptyComponent}
      customFilterRender={customFilterRender}
    />
  );
};

export default memo(TableWrapper);
