import cn from 'classnames';
import { isEmpty } from 'lodash';
import { MdOutlineAttachFile, MdOutlineRedo, MdOutlineUndo } from 'react-icons/md';
import { COLOR_CODE } from 'src/appConfig/constants';
import { Toastify } from 'src/services';
import AttachmentUploadButton from '../../AttachmentUploadButton';
import formats from './ToolbarOptions';
import { useUploadAttachments } from 'src/queries';

const renderOptions = (formatData, index) => {
  const { className, options } = formatData;
  return (
    <select key={index} className={cn(className, 'mr-2px')}>
      {options.map((value) => {
        return <option key={value} value={value} />;
      })}
    </select>
  );
};

const renderSingle = (formatData, setFiles, index, maxFileSize, disableAttachmentUpload) => {
  const { className, value } = formatData;

  return className === 'ql-attachments' ? (
    <CustomFileAttachment
      disabled={disableAttachmentUpload}
      key={index}
      setFiles={setFiles}
      maxFileSize={maxFileSize}
    />
  ) : (
    <button key={index} className={cn(className, 'mr-2px')} value={value} />
  );
};

const CustomUndo = ({ disabled }) => (
  <MdOutlineUndo color={disabled ? COLOR_CODE.GRAY_300 : COLOR_CODE.GRAY_600} />
);

const CustomRedo = ({ disabled }) => (
  <MdOutlineRedo color={disabled ? COLOR_CODE.GRAY_300 : COLOR_CODE.GRAY_600} />
);

function undoChange() {
  this.quill.history.undo();
}
function redoChange() {
  this.quill.history.redo();
}

export const modules = {
  toolbar: {
    container: '#toolbar',
    handlers: {
      undo: undoChange,
      redo: redoChange,
    },
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true,
  },
};

const CustomFileAttachment = ({ setFiles, maxFileSize, disabled }) => {
  const { getPresignedUploadUrl } = useUploadAttachments({ onUploadSuccess: setFiles });
  return (
    <AttachmentUploadButton
      disabled={disabled}
      content={''}
      containerClassName="cmp-editor__file-attachment"
      onAddAttachment={(file) => getPresignedUploadUrl(file[0])}
      onError={(error) => Toastify.error(error)}
      maxSize={maxFileSize}
      icon={<MdOutlineAttachFile />}
    />
  );
};

const CustomToolbar = ({ forwardedRef, setFiles, maxFileSize, disableAttachmentUpload }) => {
  const { undo, redo } = forwardedRef?.current?.editor?.history?.stack || {};
  return (
    <div id="toolbar">
      <span className="ql-formats">
        <button className="ql-undo">
          <CustomUndo disabled={isEmpty(undo)} />
        </button>
        <button className="ql-redo">
          <CustomRedo disabled={isEmpty(redo)} />
        </button>
      </span>
      {formats.map((classes, index) => (
        <span key={index} className="ql-formats">
          {classes.map((formatData, index) =>
            formatData.options
              ? renderOptions(formatData, index)
              : renderSingle(formatData, setFiles, index, maxFileSize, disableAttachmentUpload)
          )}
        </span>
      ))}
    </div>
  );
};
export default CustomToolbar;
