import { getFirstLastName } from 'src/utils';
import { toAddress, toAuditData } from '../helpers';
import { TDI15Key } from './keys';

export const toTDI15s = (data) => {
  return {
    ...data,
    data: data?.data?.map((item) => ({
      ...item,
      [TDI15Key.DOLNumber]: item?.employer?.laborNumber,
      [TDI15Key.EmployerName]: item?.employer?.accountName,
      [TDI15Key.EmployerId]: item?.employer?.id,
      [TDI15Key.TDIPlanNumber]: item?.tdiPlanType?.tdiPlanNumber || '',
      [TDI15Key.TDIPlanId]: item?.tdiPlanType?.id,
      [TDI15Key.EmployerPointOfContact]: item?.employerPointOfContact?.contactName,
      [TDI15Key.EmployerPointOfContactEmail]: item?.employerPointOfContact?.email,
      [TDI15Key.EmployerMailingAddress]: toAddress(item?.employer),
      [TDI15Key.EmployerPhone]: item?.employer?.phone,
      [TDI15Key.EmployerFax]: item?.employer?.fax,
      [TDI15Key.AssignedUser]: getFirstLastName(item?.assignedUser),
      ...toAuditData(item),
    })),
  };
};

export const toTDI15Detail = (data) => {
  return {
    ...data,
    [TDI15Key.DOLNumber]: data?.employer?.laborNumber,
    [TDI15Key.EmployerName]: data?.employer?.accountName,
    [TDI15Key.EmployerPointOfContactName]: data?.employerPointOfContact?.contactName,
    [TDI15Key.EmployerPointOfContactEmail]: data?.employerPointOfContact?.email,
    [TDI15Key.EmployerMailingAddress]: toAddress(data?.employer),
    [TDI15Key.EmployerPhone]: data?.employer?.phone,
    [TDI15Key.EmployerFax]: data?.employer?.fax,
    [TDI15Key.Employer]: data?.employer?.accountName,
    [TDI15Key.EmployerStreet]: data?.employer?.street,
    [TDI15Key.EmployerCity]: data?.employer?.city,
    [TDI15Key.EmployerState]: data?.employer?.state,
    [TDI15Key.EmployerZip]: data?.employer?.zip,
    [TDI15Key.POCEmailAddress]: data?.[TDI15Key.EmployerPointOfContact]?.email,
    [TDI15Key.TDIPlanNumber]: data?.tdiPlanType?.tdiPlanNumber,
    [TDI15Key.TDIPlanDepositAmount]: data?.tdiPlanType?.depositAmount,
    [TDI15Key.TDIPlanDepositDate]: data?.tdiPlanType?.depositDate,
    [TDI15Key.TDIPlanNoOfCoveredEmployees]: data?.tdiPlanType?.numberOfEmployees,
    [TDI15Key.TDIPlanIsFinanciallySolvent]: data?.tdiPlanType?.isFinanciallySolvent,
    [TDI15Key.TDIPlanFinancialStatementYearEnding]: data?.tdiPlanType?.financialStatementYearEnding,
    [TDI15Key.TDIPlanIsBond]: data?.tdiPlanType?.isBond,
    [TDI15Key.TDIPlanComment]: data?.tdiPlanType?.comment,
    [TDI15Key.TDIPlanId]: data?.tdiPlanType?.id,
    [TDI15Key.AssignedUserId]: data?.assignedUser?.id,
    [TDI15Key.AssignedUser]: data?.createdUser && getFirstLastName(data?.assignedUser),
    [TDI15Key.DCDStatus]: data?.employer?.dcdStatus,
    [TDI15Key.DCDStatusDate]: data?.employer?.dcdStatusDate,
    ...toAuditData(data),
  };
};
