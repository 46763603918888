import { WC5RawKey,WC5Interface,WC5Key } from './types';
import { PaginationResponseType, toAuditData } from '../helpers';


export const toWC5Details = (data) => {
  return {
    ...data,
    [WC5RawKey.EmployerName]: data?.employer?.accountName,
    [WC5RawKey.ClaimantName]: data?.claimant?.accountName,
    [WC5RawKey.CaseNumber]: data?.case?.caseNumber,
    ...toAuditData(data),
  };
};

export const toWC5 = (data: PaginationResponseType<any>): PaginationResponseType<WC5Interface> => ({
  ...data,
  data: data.data.map((wc5) => ({
    ...wc5,
    [WC5Key.CaseNumber]: wc5?.case?.caseNumber,
    [WC5Key.CaseId]: wc5?.case?.id,
    [WC5Key.AdjustingCompany]: wc5?.adjustingCompany?.accountName,
    [WC5Key.Adjuster]: wc5?.adjuster?.contactName,

    ...toAuditData(wc5),
  })),
});
