import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { AwardWorksheet } from './types';
import { createResponseWrapper } from '../helpers';

export function useCreateAwardWorksheets(
  options?: UseMutationOptions<AwardWorksheet, Error, AwardWorksheet>
) {
  const { mutate: createAwardWorksheets, isLoading } = useMutation<
    AwardWorksheet,
    Error,
    AwardWorksheet
  >({
    mutationFn: (payload: AwardWorksheet) =>
      createResponseWrapper(apiClient.createAwardWorksheet, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    createAwardWorksheets,
    isLoading,
  };
}
