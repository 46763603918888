import { useMutation, UseMutationOptions } from 'react-query';
import { Toastify } from 'src/services';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { User } from '../Users/types';

export function useUnAssignUser(options?: UseMutationOptions<[User, User[]], Error, any>) {
  const {
    mutate: onUnAssignUser,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useMutation<[User, User[]], Error, any>({
    mutationFn: ([params, payload]) => responseWrapper(apiClient.unAssignUser, [params, payload]),
    onError: ({ message }) => Toastify.error(message),
    ...options,
  });

  return {
    onUnAssignUser,
    isLoading,
    isSuccess,
    isError,
    error,
  };
}
