import { useMemo, useState } from 'react';
import { UseInfiniteQueryOptions, useInfiniteQuery } from 'react-query';
import { useDebounce } from 'src/hooks';
import { isEmpty } from 'src/validations';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { Option } from '../types';
import { WC77Key } from './keys';
import { WC77 } from './types';

const pageParam = {
  take: 10,
  skip: 0,
};

export function useLazyWC77(
  options?: UseInfiniteQueryOptions<{ data: WC77[] }, Error> & {
    [key: string]: string | number | string[];
  }
) {
  const [inputSearch, setInputSearch] = useState<string>('');
  const debounceSearch = useDebounce(inputSearch);
  const {
    data,
    error,
    isError,
    isFetching,
    refetch: getWC77s,
    fetchNextPage,
  } = useInfiniteQuery<{ data: WC77[] }, Error>(
    [API_QUERIES.WC77s, pageParam, debounceSearch, { ...options }],
    (props): Promise<{ data: WC77[] }> => {
      const { pageParam, queryKey } = props;
      const search = queryKey.at(-2);
      const options = queryKey.at(-1);

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      return responseWrapper<{ data: WC77[] }>(apiClient.getAllWC77s, [
        { ...pageParam, search, ...(options as Object) },
      ]);
    },
    {
      keepPreviousData: true,
      getNextPageParam(lastPage, allPages) {
        if (lastPage.data?.length < 10) return undefined;
        return {
          take: 10,
          skip: allPages.length * 10,
        };
      },
      notifyOnChangeProps: ['data', 'isFetching'],
      ...options,
    }
  );

  const wc77s: Option[] = useMemo(() => {
    if (isEmpty(data)) return [];
    return data.pages.reduce(
      (state, page, pageIdx) => [
        ...state,
        ...page.data.map(
          (wc77, idx): Option => ({
            label: wc77[WC77Key.FormNumber],
            value: wc77?.id?.toString(),
            index: pageIdx * 10 + idx,
          })
        ),
      ],
      []
    ) as Option[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return {
    wc77s,
    error,
    isError,
    loading: isFetching,
    getWC77s,
    fetchNextPage,
    setInputSearch,
  };
}
