import { useMemo, useState } from 'react';
import { UseInfiniteQueryOptions, useInfiniteQuery, useQueryClient } from 'react-query';
import { useDebounce } from 'src/hooks';
import apiClient from 'src/queries/apiClient';
import { responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { Option } from 'src/queries/types';
import { isEmpty } from 'src/validations';
import { VocationalRehabilitationKey } from '../keys';
import { VocationalRehabilitation } from '../types';

const pageParam = {
  take: 10,
  skip: 0,
};

export function useLazyVRProcess(
  options?: UseInfiniteQueryOptions<{ data: VocationalRehabilitation[] }, Error>
) {
  const [inputSearch, setInputSearch] = useState<string>('');
  const debounceSearch = useDebounce(inputSearch);
  const {
    data,
    error,
    isError,
    isFetching,
    refetch: getVRProcesses,
    fetchNextPage,
  } = useInfiniteQuery<{ data: VocationalRehabilitation[] }, Error>(
    [API_QUERIES.VR_PROCESS, pageParam, debounceSearch],
    (props): Promise<{ data: VocationalRehabilitation[] }> => {
      const { pageParam, queryKey } = props;
      const search = queryKey.at(-1);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      return responseWrapper<{ data: VocationalRehabilitation[] }>(
        apiClient.getAllVocationalRehabilitation,
        [{ ...pageParam, search }]
      );
    },
    {
      keepPreviousData: true,
      getNextPageParam(lastPage, allPages) {
        if (lastPage.data?.length < 10) return undefined;
        return {
          take: 10,
          skip: allPages.length * 10,
        };
      },
      notifyOnChangeProps: ['data', 'isFetching'],
      ...options,
    }
  );

  const vrProcess: Option[] = useMemo(() => {
    if (isEmpty(data)) return [];
    return data.pages.reduce(
      (state, page, pageIdx) => [
        ...state,
        ...page.data.map(
          (process, idx): Option => ({
            label: process[VocationalRehabilitationKey.ProcessNumber as string],
            value: process[VocationalRehabilitationKey.Id as string],
            index: pageIdx * 10 + idx,
          })
        ),
      ],
      []
    ) as Option[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const queryClient = useQueryClient();

  const handleInvalidateLazyVrProcess = () =>
    queryClient.resetQueries([API_QUERIES.VR_PROCESS, pageParam, debounceSearch]);

  return {
    vrProcess,
    error,
    isError,
    loading: isFetching,
    getVRProcesses,
    fetchNextPage,
    setInputSearch,
    handleInvalidateLazyVrProcess,
  };
}
