import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { GetPropertiesParams } from '../types';
import { RelatedTypeAndPurpose, RelatedTypePurposesType } from './types';

export function useGetPurpose(
  options?: UseQueryOptions<RelatedTypeAndPurpose, Error> & {
    hearingType?: string;
    hearingId?: string;
  }
) {
  const [params, setParams] = useState<GetPropertiesParams>({});
  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetTypePurpose,
  } = useQuery<RelatedTypeAndPurpose, Error>(
    [
      API_QUERIES.PURPOSES,
      { ...params, hearingType: options?.hearingType, hearingId: options?.hearingId },
    ],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<RelatedTypeAndPurpose>(apiClient.getPurposes, params);
      },
      notifyOnChangeProps: ['data', 'isFetching'],
      keepPreviousData: true,
      enabled: !!options?.hearingType,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidatePurpose = () =>
    queryClient.invalidateQueries([
      API_QUERIES.PURPOSES,
      {
        hearingType: options?.hearingType,
        hearingId: options?.hearingId,
      },
    ]);

  const purpose: RelatedTypePurposesType[] = data?.data?.typeAndPurpose || [];

  return {
    purpose,
    error,
    isError,
    isFetching,
    onGetTypePurpose,
    handleInvalidatePurpose,
    setParams,
  };
}
