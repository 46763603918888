import { useQuery, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { ApiResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { HistoricalKey } from './keys';
import { HistoryPriorPaymentType, HistoryPriorPaymentPayload } from './types';

export function useGetHistoryPriorPayments(
  options?: UseQueryOptions<
    ApiResponseType<{ historicalPriorPayments: HistoryPriorPaymentType[] }>,
    Error,
    HistoryPriorPaymentType[]
  > & {
    params: HistoryPriorPaymentPayload;
  }
) {
  const {
    data: historyPriorPayments,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetHistoryPriorPayments,
  } = useQuery<
    ApiResponseType<{ historicalPriorPayments: HistoryPriorPaymentType[] }>,
    Error,
    HistoryPriorPaymentType[]
  >([API_QUERIES.PRIOR_PAYMENT, options?.params], {
    queryFn: (query) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...params] = query.queryKey;
      return responseWrapper<
        ApiResponseType<{ historicalPriorPayments: HistoryPriorPaymentType[] }>
      >(apiClient.getAllHistoryPriorPayments, params);
    },
    select: ({ data }) => data.historicalPriorPayments,
    enabled: !!(
      options.params[HistoricalKey.PaymentYear] &&
      options.params[HistoricalKey.InsuranceCarrierId] &&
      options.params[HistoricalKey.CaseId]
    ),
    ...options,
  });

  return {
    historyPriorPayments,
    error,
    isError,
    isLoading,
    onGetHistoryPriorPayments,
  };
}
