import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { createResponseWrapper } from '../helpers';
import { HearingNoteValue } from './types';

export function useCreateNote(
  options?: UseMutationOptions<any, Error, HearingNoteValue>
) {
  const { mutate: createHearingRelatedNote, isLoading } = useMutation<
    any,
    Error,
    HearingNoteValue
  >({
    mutationFn: (payload: HearingNoteValue) =>
      createResponseWrapper(apiClient.createHearingRelatedNote, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    createHearingRelatedNote,
    isLoading,
  };
}
