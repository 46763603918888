import { Search } from '@mui/icons-material';
import { ThemeProvider } from '@mui/material/styles';
import { debounce } from 'lodash';
import React from 'react';
import { useHistory, useLocation } from 'react-router';
import { theme } from 'src/appConfig/muiTheme';
import { Input, View } from 'src/modules/shared-main/components';
import { isEmpty } from 'src/validations';
import CustomFilter from '../CustomFilter';
import ListViewControl from '../ListViewControl';
import { useTableProvider } from '../TableProvider';
import './styles.scss';

const clsPrefix = 'custom-search-table';

const SEARCH_TEXT = 'searchText';

const CustomSearchRender: React.FC<Props> = ({ customFilterRender }) => {
  const { allFields, hasFilter, noListViewControl } = useTableProvider();

  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const search = query.get(SEARCH_TEXT) as string;

  const ref = React.useRef(null);
  const [searchValue, setSearchValue] = React.useState(search || '');

  const onSearch = (value: string) => {
    query.delete(SEARCH_TEXT);
    query.append(SEARCH_TEXT, value ? value : '');
    history.push({ search: query.toString() });
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceValue = React.useCallback(debounce(onSearch, 300), []);

  const applyFilterQuery = (event) => {
    const { value } = event.target;

    setSearchValue(value);
    debounceValue(value);
  };
  // eslint-disable-next-line security/detect-object-injection

  return (
    <ThemeProvider theme={theme}>
      <View justify="flex-end" isRowWrap className={`${clsPrefix}`} forwardRef={ref}>
        <Input
          defaultValue={searchValue}
          placeholder={'Search'}
          iconComponent={<Search />}
          onChange={applyFilterQuery}
          containerClassName="cmp-input-search"
        />
        {!isEmpty(allFields) && hasFilter && (
          <>
            {customFilterRender ?? <CustomFilter.Container />}
            {!noListViewControl && <ListViewControl />}
          </>
        )}
      </View>
    </ThemeProvider>
  );
};

type Props = {
  customFilterRender?: React.ReactNode;
};

export default CustomSearchRender;
