import { useMutation, UseMutationOptions } from 'react-query';
import { Toastify } from 'src/services';
import apiClient from '../apiClient';
import { createResponseWrapper } from '../helpers';
import { AccountDetail } from './types';

export function useCreateAccount(
  options?: UseMutationOptions<AccountDetail, Error, AccountDetail>
) {
  const { mutate: createAccount, isLoading } = useMutation<AccountDetail, Error, AccountDetail>({
    mutationFn: (payload: AccountDetail) =>
      createResponseWrapper(apiClient.createAccount, [payload]),
    onError: ({ message }) => {
      Toastify.error(message);
    },
    ...options,
  });

  return {
    createAccount,
    isLoading,
  };
}
