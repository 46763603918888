import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { PaginationResponseType, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { GetPropertiesParams } from 'src/queries/types';
import { isEmpty } from 'src/validations';
import { WC1Form } from './types';

export function useGetAllWC1Forms(
  options?: UseQueryOptions<PaginationResponseType<WC1Form>, Error>
) {
  const [params, setParams] = useState<GetPropertiesParams>({});
  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetAllWC1Forms,
  } = useQuery<PaginationResponseType<WC1Form>, Error>([API_QUERIES.WC1s, params], {
    queryFn: (query) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...params] = query.queryKey;
      return responseWrapper<PaginationResponseType<WC1Form>>(apiClient.getAllWC1Forms, params);
    },
    notifyOnChangeProps: ['data', 'isFetching'],
    enabled: !isEmpty(params),
    keepPreviousData: true,
    ...options,
  });

  const queryClient = useQueryClient();

  const handleInvalidateAllWC1Form = () => queryClient.invalidateQueries(API_QUERIES.WC1s);

  const { data: wc1Forms, hasNext, payloadSize, totalRecords } = data || {};

  return {
    wc1Forms,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isError,
    isFetching,
    onGetAllWC1Forms,
    setParams,
    handleInvalidateAllWC1Form,
  };
}
