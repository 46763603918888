import { PaginationResponseType } from '../helpers';
import { HRSHARKey } from './keys';
import { DisputedInvolvesTDI, HRSHAR } from './types';

export const getDisputedInvolvesTDIContent = (hrsHar: HRSHAR) => {
  const items = [
    hrsHar?.[HRSHARKey.IsTDIInvolved] && DisputedInvolvesTDI.TDI,
    hrsHar?.[HRSHARKey.IsTDISFInvolvedUI] && DisputedInvolvesTDI.TDIUi,
    hrsHar?.[HRSHARKey.IsTDISFInvolvedNonCompOrBankruptER] &&
      DisputedInvolvesTDI.TDINonCompBankruptEr,
  ].filter(Boolean);

  return items.join(', ');
};

export const toHrsHars = (data: PaginationResponseType<HRSHAR>) => ({
  ...data,
  data: data?.data?.map((hrsHar) => ({
    ...hrsHar,
    [HRSHARKey.DisputeInvolvesTDI]: getDisputedInvolvesTDIContent(hrsHar),
  })),
});
