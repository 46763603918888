export enum RelatedHearingRequiredDocumentsKey {
  Id = 'id',
  HearingNumber = 'hearingNumber',
  HearingId = 'hearingId',
  DocumentName = 'documentName',
  DateObtained = 'dateObtained',
  Status = 'status',
  IsRequired = 'isRequired',
  DateRequested = 'dateRequested',
  DocumentSubType = 'documentSubType',
  DocumentType = 'documentType',
  CreatedUser = 'createdUser',
  UpdatedUser = 'updatedUser',
}
