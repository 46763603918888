import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { ApiResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { toWCIPDetails } from './helpers';
import { WCInsurancePoliciesInterface } from './types';

export function useGetWCInsurancePolicies(
  options?: UseQueryOptions<
    ApiResponseType<{ insurancePolicy: any }>,
    Error,
    WCInsurancePoliciesInterface
  > & {
    id: string;
  }
) {
  const {
    data: wcIp,
    error,
    isError,
    isFetching: isLoading,
    refetch,
  } = useQuery<ApiResponseType<{ insurancePolicy: any }>, Error, any>(
    [API_QUERIES.WCInsurancePolicies, { id: options.id }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;

        return responseWrapper<ApiResponseType<{ insurancePolicy: any }>>(
          apiClient.getWCIPDetail,
          params
        );
      },
      enabled: !!options.id,
      select: ({ data }) => toWCIPDetails(data.insurancePolicy),
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateWCIP = () =>
    queryClient.invalidateQueries([API_QUERIES.WCInsurancePolicies, { id: options.id }]);

  return {
    wcIp,
    error,
    isError,
    isLoading,
    refetch,
    handleInvalidateWCIP,
  };
}
