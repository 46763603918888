import { SearchableSelectType } from 'src/components/common/Table/helpers';
import { isEmpty } from 'src/validations';
import { Option } from '../../common/appConfig/constants';
import {
  emptyFunction,
  formatDate,
  formatDateTime,
  formatMoney,
  formatPhoneNumber,
  formatTime,
  getLabel,
  separateNumberByComma,
} from '../../common';
import { MUIDataTableColumn } from 'mui-datatables';
import Checkbox from '../Checkbox';
import { ViewsOption } from '../../queries';

export enum WrapClipText {
  Wrap = 'wrap',
  Clip = 'clip',
}

export const wrapTextOptions = [
  {
    label: 'Wrap text',
    value: WrapClipText.Wrap,
  },
  {
    label: 'Clip text',
    value: WrapClipText.Clip,
  },
];

export const getDefaultView = (views: ViewsOption[] = []) => {
  if (!isEmpty(views?.filter(({ isPinned }) => isPinned))) {
    return views?.filter(({ isPinned }) => isPinned)?.[0]?.value;
  }
  return views?.filter(({ isDefault }) => isDefault)?.[0]?.value;
};

export enum TableColumnType {
  Text = 'text',
  Select = 'select',
  Time = 'time',
  Checkbox = 'checkbox',
  Date = 'date',
  DateTime = 'dateTime',
  SearchableSelect = 'searchableSelect',
  Currency = 'currency',
  PhoneNumber = 'phoneNumber',
  Number = 'number',
}

export interface TableColumn {
  label: string;
  name: string;
  type?: TableColumnType;
  option?: Option[];
  filterKey?: string;
  searchableSelectType?: SearchableSelectType;
  renderIf?: boolean;
  filterable?: boolean;
  isDefaultSelected?: boolean;
}

type ColumnConfig = TableColumn & {
  options?: Partial<MUIDataTableColumn['options']>;
};

export const generateColumns = (columns: ColumnConfig[]): MUIDataTableColumn[] => {
  return columns
    .filter(({ name }, index) => columns.map(({ name }) => name).lastIndexOf(name) === index)
    .map(({ name, label, type, option, options }) => ({
      name,
      label,
      options: {
        filter: false,
        sort: true,
        ...options,
        customBodyRender: options?.customBodyRender
          ? options?.customBodyRender
          : (value: string) => (!isEmpty(value) ? renderBodyTable(type, value, option) : '--'),
      },
    }));
};

export const renderBodyTable = (fieldType: TableColumnType, value: any, options: Option[]) => {
  switch (fieldType) {
    case TableColumnType.Date:
      return formatDate(value);
    case TableColumnType.DateTime:
      return formatDateTime(value);
    case TableColumnType.Checkbox:
      return <Checkbox.Item checked={value} disabled onChange={emptyFunction} />;
    case TableColumnType.Select:
      return getLabel(options, value);
    case TableColumnType.Time:
      return formatTime(value);
    case TableColumnType.Currency:
      return formatMoney(value);
    case TableColumnType.PhoneNumber:
      return formatPhoneNumber(value);
    case TableColumnType.Number:
      return separateNumberByComma(value);
    default:
      return value;
  }
};
