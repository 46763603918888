import { isEmpty } from 'src/validations';
import { toAuditData } from '../helpers';
import { HC4Key } from './keys';

export const toHC4s = (data) => ({
  ...data,
  data: data.data.map((hc4) => ({
    ...hc4,
    ...toAuditData(hc4),
    [HC4Key.Employer]: hc4?.employer?.accountName || '',
    [HC4Key.LaborNumber]: hc4?.employer?.laborNumber || '',
  })),
});

export const toHC4Detail = (data) => {
  const totalMonthlyPremiumValues = data?.totalMonthlyPremiums?.map((item) => {
    return {
      ...item,
      employerContribution: isEmpty(item?.employerContribution) ? '' : +item?.employerContribution,
      employeeContribution: isEmpty(item?.employeeContribution) ? '' : +item?.employeeContribution,
      totalMonthlyCost: isEmpty(item?.totalMonthlyCost) ? '' : +item?.totalMonthlyCost,
    };
  });

  return {
    ...data,
    [HC4Key.EmployerName]: data?.employer?.accountName || '',
    [HC4Key.LaborNumber]: data?.employer?.laborNumber || '',
    [HC4Key.DBAName]: data?.employer?.dbaName || '',
    [HC4Key.ServiceTypeHCContractorName]: data?.serviceTypeHCContractor?.accountName || '',
    [HC4Key.ReimburseTypeHCContractorName]: data?.reimburseTypeHCContractor?.accountName || '',
    [HC4Key.CollectiveBargainingTypeHCContractorName]:
      data?.collectiveBargainingTypeHCContractor?.accountName || '',
    [HC4Key.TotalMonthlyPremiums]: totalMonthlyPremiumValues,
    ...toAuditData(data),
  };
};
