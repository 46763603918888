import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { createResponseWrapper } from 'src/queries/helpers';
import { ErrorService } from 'src/services';
import { TDI62Action } from './types';

export function useAcceptRejectAction(
  options?: UseMutationOptions<TDI62Action, Error, TDI62Action>
) {
  const { mutate: createAcceptRejectAction, isLoading } = useMutation<
    TDI62Action,
    Error,
    TDI62Action
  >({
    mutationFn: (payload: TDI62Action) =>
      createResponseWrapper(apiClient.acceptRejectAction, [payload]),
    onError: (error) => ErrorService.handler(error),
    ...options,
  });

  return {
    createAcceptRejectAction,
    isLoading,
  };
}
