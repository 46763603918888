import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../../apiClient';
import { ApiResponseType, responseWrapper } from '../../helpers';
import { API_QUERIES } from '../../keys';
import { toCaseSettlementData } from './helpers';
import { CaseSettlementDetailRelatedInterface } from './types';

export function useGetCaseSettlementDetail(
  options?: UseQueryOptions<
    ApiResponseType<{ caseSettlement: any }>,
    Error,
    CaseSettlementDetailRelatedInterface
  > & {
    id: string;
  }
) {
  const {
    data: caseSettlement,
    error,
    isError,
    isFetching: isLoading,
    refetch,
  } = useQuery<ApiResponseType<{ caseSettlement: any }>, Error, any>(
    [API_QUERIES.CASE_SETTLEMENTS, { id: options?.id }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;

        return responseWrapper<ApiResponseType<{ caseSettlement: any }>>(
          apiClient.getCaseSettlementDetail,
          params
        );
      },
      enabled: !!options?.id,
      select: (data) => toCaseSettlementData(data.data.caseSettlement),
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateCaseSettlement = () =>
    queryClient.invalidateQueries([API_QUERIES.CASE_SETTLEMENTS, { id: options?.id }]);

  return {
    caseSettlement,
    error,
    isError,
    isLoading,
    refetch,
    handleInvalidateCaseSettlement,
  };
}
