import { AccountDetail } from '../Accounts';
import { EmployerAuditKey } from './keys';

export interface EmployerAudit {
  [EmployerAuditKey.Id]: string;
  [EmployerAuditKey.EmployerAuditNumber]?: string;
  [EmployerAuditKey.AuditType]?: AuditType;
  [EmployerAuditKey.EmployerID]?: string;
  [EmployerAuditKey.EmployerName]?: string;
  [EmployerAuditKey.NatureOfAudit]?: string;
  [EmployerAuditKey.AuditPeriodStartDate]?: string;
  [EmployerAuditKey.AuditPeriodEndDate]?: string;
  [EmployerAuditKey.ParentCompanyId]?: string;
  [EmployerAuditKey.ParentCompanyName]?: string;
  [EmployerAuditKey.Findings]?: string;
  [EmployerAuditKey.Auditor]?: string;
  [EmployerAuditKey.AuditorId]?: string;
  [EmployerAuditKey.Status]?: Status;
  [EmployerAuditKey.AssignedDate]?: string;
  [EmployerAuditKey.DateCompleted]?: string;

  [EmployerAuditKey.DateSentToAdmin]?: string;
  [EmployerAuditKey.DateReviewedByAdmin]?: string;
  [EmployerAuditKey.ForYearEnded]?: string;
  [EmployerAuditKey.Employer]?: AccountDetail;
  [EmployerAuditKey.DOLNumber]?: string;
  [EmployerAuditKey.City]?: string;
  [EmployerAuditKey.State]?: string;
  [EmployerAuditKey.Zip]?: string;
  [EmployerAuditKey.PhoneNumber]?: string;
  [EmployerAuditKey.KindOfBusiness]?: string;
  [EmployerAuditKey.TotalNumberOfEmployees]?: string;
  [EmployerAuditKey.TotalHawaiiEmployees]?: string;
  [EmployerAuditKey.TotalEligibleHawaiiEmployees]?: string;
  [EmployerAuditKey.TotalCoveredHawaiiEmployees]?: string;

  // Audit
  [EmployerAuditKey.CreatedBy]: string;
  [EmployerAuditKey.CreatedAt]: string;
  [EmployerAuditKey.CreatedUser]: string;
  [EmployerAuditKey.UpdatedAt]: string;
  [EmployerAuditKey.UpdatedBy]: string;
  [EmployerAuditKey.UpdatedUser]: string;
}

export enum AuditType {
  ContinuingPHCSelfInsuranceAudit = 'Continuing PHC Self-Insurance Audit',
  ContinuingTDISelfInsuranceAudit = 'Continuing TDI Self-Insurance Audit',
  InitialPHCSelfInsuranceAudit = 'Initial PHC Self-Insurance Audit',
  InitialTDISelfInsuranceAudit = 'Initial TDI Self-Insurance Audit',
  PremiumSupplementationTrustFundAudit = 'Premium Supplementation (PREM SUP) Trust Fund Audit',
  CompliancePHCTDI = 'Compliance PHC/TDI',
  Other = 'Other',
}

export enum Status {
  Approved = 'Approved',
  NotApproved = 'Not Approved',
}
