import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { createResponseWrapper } from 'src/queries/helpers';
import { Toastify } from 'src/services';
import { WCCoveragesTypes } from './types';

export function useCreateWCCoverage(
  options?: UseMutationOptions<WCCoveragesTypes, Error, WCCoveragesTypes>
) {
  const { mutate: createWCCoverage, isLoading } = useMutation<
    WCCoveragesTypes,
    Error,
    WCCoveragesTypes
  >({
    mutationFn: (payload: WCCoveragesTypes) =>
      createResponseWrapper(apiClient.createWCCoverage, [payload]),
    onError: ({ message }) => Toastify.error(message),
    ...options,
  });

  return {
    createWCCoverage,
    isLoading,
  };
}
