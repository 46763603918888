import { useMutation, UseMutationOptions } from 'react-query';
import { Toastify } from 'src/services';
import apiClient from '../apiClient';
import { createResponseWrapper } from '../helpers';
import { AddressHistory } from './types';

export function useCreateAddressHistory(
  options?: UseMutationOptions<AddressHistory, Error, AddressHistory>
) {
  const { mutate: createAddressHistory, isLoading } = useMutation<
    AddressHistory,
    Error,
    AddressHistory
  >({
    mutationFn: (payload: AddressHistory) =>
      createResponseWrapper(apiClient.createAddressHistory, [payload]),
    onError: ({ message }) => Toastify.error(message),
    ...options,
  });

  return {
    createAddressHistory,
    isLoading,
  };
}
