import { formatDateTime } from 'src/utils';
import { PaginationResponseType, toAuditData } from '../helpers';
import { WC5A, WC5AKey } from './WC5ATypes';

export const toWC5ADetail = (data) => ({
  ...data,
  [WC5AKey.ClaimantName]: data?.claimant?.accountName,
  [WC5AKey.ClaimantId]: data?.claimant?.id,
  [WC5AKey.CaseNumber]: data?.case?.caseNumber,
  [WC5AKey.CaseId]: data?.case?.id,
  [WC5AKey.CreatedAt]: formatDateTime(data?.[WC5AKey.CreatedAt]),
  ...toAuditData(data),
});

export const toWC5As = (data: PaginationResponseType<any>): PaginationResponseType<WC5A> => ({
  ...data,
  data: data.data.map((wc5) => ({
    ...wc5,
    [WC5AKey.Claimant]: wc5?.claimant?.accountName,
    [WC5AKey.Physician]: wc5?.physician?.accountName,
    [WC5AKey.Employer]: wc5?.employer?.accountName,
    [WC5AKey.EmployerPointOfContact]: wc5?.employerPointOfContact?.contactName,
    [WC5AKey.InsuranceCarrier]: wc5?.insuranceCarrier?.accountName,
    [WC5AKey.AdjustingCompany]: wc5?.adjustingCompany?.accountName,
    [WC5AKey.Adjuster]: wc5?.adjuster?.contactName,
    [WC5AKey.CaseNumber]: wc5?.case?.caseNumber,
    [WC5AKey.CaseId]: wc5?.case?.id,
    ...toAuditData(wc5),
  })),
});
