import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { PPDInjuredBodyPartsType } from './types';

export function useEditPpdInjuredBodyParts(
  options?: UseMutationOptions<any, Error, PPDInjuredBodyPartsType>
) {
  const {
    mutate: editPpdInjuredBodyParts,
    isLoading: isUpdating,
    isSuccess,
    isError,
    error,
  } = useMutation<any, Error, PPDInjuredBodyPartsType>({
    mutationFn: (payload: PPDInjuredBodyPartsType) =>
      responseWrapper(apiClient.updatePPDInjuredBodyParts, [payload]),
    onError: (error) => ErrorService.handler(error),
    ...options,
  });

  return {
    editPpdInjuredBodyParts,
    isUpdating,
    isSuccess,
    isError,
    error,
  };
}
