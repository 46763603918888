import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { PaginationResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { GetPropertiesParams } from '../types';
import { toRelatedRequestReconsideration } from './helpers';
import { RequestReconsideration } from './types';

export function useGetAllRelatedRequestReconsideration(
  options?: UseQueryOptions<PaginationResponseType<RequestReconsideration>, Error> & {
    decisionId: string;
  }
) {
  const [params, setParams] = useState<GetPropertiesParams>({});
  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetAllRelatedRequestReconsideration,
  } = useQuery<PaginationResponseType<RequestReconsideration>, Error>(
    [API_QUERIES.DECISIONS_REQUEST_RECONSIDERATION, { ...params, id: options?.decisionId }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<PaginationResponseType<RequestReconsideration>>(
          apiClient.getAllRequestForReconsideration,
          params
        );
      },
      select: toRelatedRequestReconsideration,
      notifyOnChangeProps: ['data', 'isFetching'],
      keepPreviousData: true,
      enabled: !!options?.decisionId,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateAllRelatedRequestReconsideration = () =>
    queryClient.invalidateQueries(API_QUERIES.DECISIONS_REQUEST_RECONSIDERATION);

  const { data: requestsReconsideration, hasNext, payloadSize, totalRecords } = data || {};

  return {
    requestsReconsideration,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isError,
    isFetching,
    onGetAllRelatedRequestReconsideration,
    handleInvalidateAllRelatedRequestReconsideration,
    setParams,
  };
}
