import { PaginationResponseType, toRelatedAuditData } from '../helpers';
import { CaseStatusHistory } from './types';

export const toRelatedStatusHistory = (data): PaginationResponseType<CaseStatusHistory> => ({
  ...data,
  data: data.data.map((item) => ({
    ...item,
    ...toRelatedAuditData(item),
  })),
});
