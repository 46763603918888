import { useMutation, UseMutationOptions } from 'react-query';
import { Toastify } from 'src/services';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { FieldPermissionsPayload } from './types';

export function useEditFieldPermission(options?: UseMutationOptions<any, Error, any>) {
  const {
    mutate: onEditFieldPermission,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useMutation<FieldPermissionsPayload, Error, any>({
    mutationFn: ([id, payload]) => responseWrapper(apiClient.editFieldPermission, [id, payload]),
    onError: ({ message }) => Toastify.error(message),
    ...options,
  });

  return {
    onEditFieldPermission,
    isLoading,
    isSuccess,
    isError,
    error,
  };
}
