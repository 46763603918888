import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from '../apiClient';
import { authResponseWrapper } from '../helpers';
import { SystemPermissionsPayload } from './types';

export function useUpdateSystemPermissions(
  options?: UseMutationOptions<
    { systemPermissions: SystemPermissionsPayload[] },
    Error,
    { systemPermissions: SystemPermissionsPayload[] }
  > & {
    id: string;
  }
) {
  const { mutate: updateSystemPermissions, isLoading } = useMutation<
    { systemPermissions: SystemPermissionsPayload[] },
    Error,
    { systemPermissions: SystemPermissionsPayload[] }
  >({
    mutationFn: (payload: { systemPermissions: SystemPermissionsPayload[] }) =>
      authResponseWrapper(apiClient.updateSystemPermissions, [payload, options?.id]),
    ...options,
  });

  return {
    updateSystemPermissions,
    isLoading,
  };
}
