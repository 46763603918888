import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { responseWrapper } from 'src/queries/helpers';
import { ErrorService } from 'src/services';
import { CaseSettlementsTypes } from './types';

export function useEditCaseSettlement(
  options?: UseMutationOptions<any, Error, CaseSettlementsTypes>
) {
  const {
    mutate: editCaseSettlement,
    isLoading: isUpdating,
    isSuccess,
    isError,
    error,
  } = useMutation<any, Error, CaseSettlementsTypes>({
    mutationFn: (payload: CaseSettlementsTypes) =>
      responseWrapper(apiClient.editCaseSettlement, [payload]),
    onError: (error) => ErrorService.handler(error),
    ...options,
  });

  return {
    editCaseSettlement,
    isUpdating,
    isSuccess,
    isError,
    error,
  };
}
