export enum RelatedVendorsKey {
  Id = 'id',
  VendorId = 'vendorId',
  DCDCase = 'caseId',
  AccountId = 'accountId',
  VendorName = 'vendorName',
  VendorNumber = 'vendorNumber',
  DelinquentEmployer = 'delinquentEmployer',
  ConcurrentEmployer = 'concurrentEmployer',
  RedTag = 'redTag',
  CreateAt = 'createdAt',
  UpdateAt = 'updatedAt',
  CreatedBy = 'createdBy',
  LastModifiedBy = 'lastModifiedBy',
}
