import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { PaginationResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { GetPropertiesParams } from '../types';
import { toRelatedVendors } from './helpers';
import { RelatedVendorsType } from './types';

export function useGetAllRelatedVendors(
  options?: UseQueryOptions<PaginationResponseType<RelatedVendorsType>, Error> & {
    id: string;
  }
) {
  const [params, setParams] = useState<GetPropertiesParams>({});
  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetAllRelatedVendors,
  } = useQuery<PaginationResponseType<RelatedVendorsType>, Error>(
    [API_QUERIES.RELATED_VENDORS, { ...params, caseId: options.id }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<PaginationResponseType<RelatedVendorsType>>(
          apiClient.getAllRelatedVendors,
          params
        );
      },
      select: (data) => toRelatedVendors(data),
      notifyOnChangeProps: ['data', 'isFetching'],
      keepPreviousData: true,
      enabled: !!options.id,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateAllRelatedVendors = () =>
    queryClient.invalidateQueries(API_QUERIES.RELATED_VENDORS);

  const { data: relatedVendors, hasNext, payloadSize, totalRecords } = data || {};

  return {
    relatedVendors,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isError,
    isFetching,
    onGetAllRelatedVendors,
    setParams,
    handleInvalidateAllRelatedVendors,
  };
}
