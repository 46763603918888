/* eslint-disable security/detect-object-injection */
import { MUIDataTableColumn, MUIDataTableOptions, MUISortOptions } from 'mui-datatables';
import React, { memo } from 'react';
import { AppName } from 'src/appConfig/constants';

import { Tag, ViewObject } from 'src/queries';
import './styles.scss';
import { ListViewControlProps, TableProvider } from './TableProvider';
import TableWrapper from './TableWrapper';
import { TableColumn } from './helpers';
import { Service } from '../../queries';

export enum TableKey {
  Action = 'action',
}

const Table: React.FC<TableWrapperProps> = ({
  columns,
  allFields = [],
  noWrapText = false,
  object,
  isRelatedList = false,
  filterByObject,
  appName,
  isNoQueryAction = false,
  noListViewControl = false,
  hasFilter = true,
  service = Service.CaseSvc,
  tag,
  customTableViewProps,
  ...props
}) => {
  return (
    <TableProvider
      hasFilter={hasFilter}
      appName={appName}
      object={object}
      allFields={allFields}
      columns={columns}
      noListViewControl={noListViewControl}
      noWrapText={noWrapText}
      filterByObject={filterByObject}
      service={service}
      isRelatedList={isRelatedList}
      tag={tag}
      customTableViewProps={customTableViewProps}
    >
      <TableWrapper {...props} />
    </TableProvider>
  );
};

export type TableWrapperProps = TableProps & {
  allFields?: TableColumn[];
  noWrapText?: boolean;
  object?: ViewObject;
  isRelatedList?: boolean;
  filterByObject?: string;
  appName?: AppName;
  noListViewControl?: boolean;
  hasFilter?: boolean;
  service?: Service;
  columns: MUIDataTableColumn[];
  tag?: Tag;
  customTableViewProps?: ListViewControlProps;
};

export type TableProps = {
  isLoading?: boolean;
  title?: React.ReactNode;
  data: any[];
  tableOptions: MUIDataTableOptions;
  refresh?: boolean | number | string;
  onAction?: (...args: any[]) => void;
  isNoQueryAction?: boolean;
  defaultSortOrder?: MUISortOptions;
  emptyComponent?: React.ReactNode;
  containerClassName?: string;
  additionalFilterParams?: string[];
  customFilterRender?: React.ReactNode;
};

export default memo(Table);
