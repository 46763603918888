/* eslint-disable react-hooks/exhaustive-deps */
import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IMAGES } from 'src/appConfig/images';
import { withRouter } from 'src/hooks';
import { setActiveApp, setCollapseSidebar } from 'src/redux/common/commonSlice';
import { IRootState } from 'src/redux/rootReducer';

import { useLocation } from 'react-router-dom';
import { AppName } from 'src/appConfig/constants';
import { PATHS } from 'src/appConfig/paths';
import { appOptions, sidebarList } from 'src/appConfig/sidebar';
import { Navigator } from 'src/services';
import { emptyFunction } from 'src/utils';
import Logo from '../Logo';
import SidebarItem from './SidebarItem';
import SidebarItemCollapse from './SidebarItemCollapse';
import './styles.scss';
import { urlAppMap } from './helpers';
import { isEmpty } from 'src/validations';
import { Icon, Image, Select, Text, View } from 'src/modules/shared-main/components';

const Sidebar: React.FC<Props> = ({
  showMiniSidebar,
  isCollapse,
  onSetCollapseSidebar,
  currentApp,
  onSetActiveApp,
}) => {
  const [activeApp, setActiveApp] = useState(currentApp);
  const onChangeActiveApp = (_, value) => {
    setActiveApp(value);
  };

  const location = useLocation();

  useEffect(() => {
    setActiveApp(currentApp);
  }, [currentApp]);

  useEffect(() => {
    onSetActiveApp(Navigator.getApp());
  }, [location]);

  const menuSideBar = () => (
    <>
      <Text
        className={cn({
          'd-none': (isCollapse && !showMiniSidebar) || currentApp !== AppName.Admin,
        })}
        size={20}
      >
        Setup
      </Text>
      {currentApp !== AppName.Admin && (
        <Select
          options={appOptions}
          label=""
          hideSearchIcon={true}
          onChange={onChangeActiveApp}
          value={activeApp}
          onBlur={emptyFunction}
          isClearable={false}
          className="cmp-sidebar__setup__modules"
          maxMenuHeight={null}
        />
      )}
    </>
  );

  return (
    <div>
      <Icon
        className={cn('cmp-sidebar__toggle-button', {
          'cmp-sidebar__toggle-button--closed': isCollapse,
          'is--mini': showMiniSidebar,
        })}
        name={'ic_chevron-left'}
        onClick={() => onSetCollapseSidebar(!isCollapse)}
      />
      <div
        className={cn('cmp-sidebar', {
          'cmp-sidebar--collapse': isCollapse,
          'cmp-sidebar--mini': showMiniSidebar,
        })}
      >
        <View className={cn('cmp-sidebar__container')}>
          <View
            isRowWrap
            align="center"
            justify="space-between"
            className={cn('cmp-sidebar__header')}
            onClick={() => {
              Navigator.navigate(PATHS.dashboard.replace(':app', urlAppMap[AppName.DCDEcms]));
            }}
          >
            {isCollapse && !showMiniSidebar ? (
              <Image className={cn('cmp-sidebar__logo')} src={IMAGES.logo} />
            ) : (
              <div className={cn('cmp-sidebar__logo')}>
                <Logo logoSize="100" />
              </div>
            )}
          </View>
          <View
            className={cn('cmp-sidebar__setup', {
              'cmp-sidebar__setup-collapse': isCollapse && !showMiniSidebar,
            })}
          >
            {menuSideBar()}
          </View>
          <View>
            {sidebarList.map((item, index) =>
              item.apps.includes(activeApp) ? (
                isEmpty(item.subMenu?.filter(({ isHidden }) => !isHidden)) ? (
                  <SidebarItem key={index} item={item} activeApp={activeApp} />
                ) : (
                  <SidebarItemCollapse key={index} item={item} activeApp={activeApp} />
                )
              ) : null
            )}
          </View>
        </View>
      </div>
    </div>
  );
};

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & {};

const mapStateToProps = (state: IRootState) => ({
  isCollapse: state.common.collapseSidebar,
  showMiniSidebar: state.common.showMiniSidebar,
  showSidebar: state.common.showSidebar,
  currentApp: state.common.currentApp,
});

const mapDispatchToProps = {
  onSetCollapseSidebar: setCollapseSidebar,
  onSetActiveApp: setActiveApp,
};

const ConnectedComponent = connect(mapStateToProps, mapDispatchToProps)(Sidebar);

export default withRouter(ConnectedComponent);
