import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { createResponseWrapper } from '../helpers';
import { OrderSubmitApprovalPayload } from './types';

export function useOrderSubmitApproval(
  options?: UseMutationOptions<OrderSubmitApprovalPayload, Error, OrderSubmitApprovalPayload>
) {
  const { mutate: orderSubmitApproval, isLoading } = useMutation<
    OrderSubmitApprovalPayload,
    Error,
    OrderSubmitApprovalPayload
  >({
    mutationFn: (payload: OrderSubmitApprovalPayload) =>
      createResponseWrapper(apiClient.transferCaseLocation, [payload]),
    onError: (error) => ErrorService.handler(error),
    ...options,
  });

  return {
    orderSubmitApproval,
    isLoading,
  };
}
