import { formatDate } from 'src/utils';
import { ApiResponseType } from '../helpers';
import { TDI15sTDISelfInsurerPlanReportKey } from './keys';
import { TDI15sTDISelfInsurerPlanReportResponse } from './types';

export const toTDI15sTDISelfInsurerPlanReport = (
  data
): ApiResponseType<TDI15sTDISelfInsurerPlanReportResponse> => {
  const originalReports = data?.data?.reports?.data || [];

  const formattedReports = originalReports.map((report) => ({
    ...report,
    [TDI15sTDISelfInsurerPlanReportKey.EffectiveDate]: `${formatDate(
      report?.[TDI15sTDISelfInsurerPlanReportKey.StartDate]
    )} - ${formatDate(report?.[TDI15sTDISelfInsurerPlanReportKey.EndDate])}`,
  }));

  return {
    ...data,
    data: {
      ...data.data,
      reports: {
        ...data.data.reports,
        data: formattedReports,
      },
    },
  };
};
