export enum TDI15Key {
  Id = 'id',
  EmployerId = 'employerId',
  EmployerName = 'employer',
  Employer = 'employerName',
  EmployerPhone = 'employerPhone',
  EmployerFax = 'employerFax',
  EmployerStreet = 'employerStreet',
  EmployerCity = 'employerCity',
  EmployerState = 'employerState',
  EmployerZip = 'employerZip',
  RecordNum = 'recordNum',
  DOLNumber = 'laborNumber',
  TDI15Number = 'tdi15Number',
  EffectiveDate = 'effectiveDate',
  NoOfCoveredEmployees = 'noOfCoveredEmployees',
  EmployerPointOfContact = 'employerPointOfContact',
  EmployerPointOfContactName = 'employerPointOfContactName',
  EmployerPointOfContactEmail = 'employerPOCEmail',
  EmployerMailingAddress = 'employerPOCMailingAddress',
  NewExistingPlan = 'newExistingPlan',
  TDIPlanType = 'tdiPlanType',
  TDIPlanDepositAmount = 'tdiPlanDepositAmount',
  TDIPlanIsBond = 'tdiPlanIsBond',
  TDIPlanNoOfCoveredEmployees = 'tdiPlanNoOfCoveredEmployees',
  TDIPlanDepositDate = 'tdiPlanDepositDate',
  TDIPlanRequiredDeposit = 'tdiPlanRequiredDeposit',
  TDIPlanIsFinanciallySolvent = 'tdiPlanIsFinanciallySolvent',
  TDIPlanFinancialStatementYearEnding = 'tdiPlanFinancialStatementYearEnding',
  TDIPlanComment = 'tdiPlanComment',
  TDIPlanNumber = 'tdiPlanNumber',
  TDIPlanAction = 'tdiPlanAction',
  RawEmployerPointOfContact = 'rawEmployerPointOfContact',
  EmployerPointOfContactId = 'employerPointOfContactId',
  RawEmployerMailing = 'rawEmployerMailing',
  RawEmployerName = 'rawEmployerName',
  RawEmployerDolNumber = 'rawEmployerDolNumber',
  RawEmployerPhone = 'rawEmployerPhone',
  RawEmployerFax = 'rawEmployerFax',
  ExcludedClassOfEmployees = 'excludedClassOfEmployees',
  NoOfEmployeesInExcludedClass = 'noOfEmployeesInExcludedClass',
  PlanCoveringExcludedClass = 'planCoveringExcludedClass',
  PercentageWeeklyBenefits = 'percentageWeeklyBenefits',
  CalendarDayDisabilityBenefits = 'calendarDayDisabilityBenefits',
  WeeksBenefitWillContinue = 'weeksBenefitWillContinue',
  BenefitYearStartDate = 'benefitYearStartDate',
  BenefitYearEndDate = 'benefitYearEndDate',
  IsEEContributionsDeducted = 'isEEContributionsDeducted',
  SecurityPaymentBenefits = 'securityPaymentBenefits',
  NameOfClaims = 'nameOfClaims',
  ClaimsAdjustingPOC = 'claimsAdjustingPOC',
  AgreementMailing = 'agreementMailing',
  AgreementEmail = 'agreementEmail',
  AgreementFax = 'agreementFax',
  AgreementPhone = 'agreementPhone',
  EmployerPrintedName = 'employerPrintedName',
  EmployerPrintedTitle = 'employerPrintedTitle',
  SignatureDate = 'signatureDate',
  TDIPlanId = 'tdiPlanId',
  IsFinancialStatement = 'isFinancialStatement',
  IsCD = 'isCD',
  TDI15ReceivedDate = 'tdi15ReceivedDate',
  PlanDocumentReceivedDate = 'planDocumentReceivedDate',
  DateSentToAudit = 'dateSentToAudit',
  AssignedUserId = 'assignedUserId',
  DateLogReceived = 'dateLogReceived',
  IsApprovedSent = 'isApprovedSent',
  ActionTaken = 'actionTaken',
  FinancialStatementYearEnding = 'financialStatementYearEnding',
  IsBond = 'isBond',
  DateAllDocsReceived = 'dateAllDocsReceived',
  DateConfirmationLetterSent = 'dateConfirmationLetterSent',
  IsAuditApproved = 'isAuditApproved',
  DatePlanReviewed = 'datePlanReviewed',
  IsDeniedSent = 'isDeniedSent',
  DateOfAction = 'dateOfAction',
  POCEmailAddress = 'pocEmailAddress',
  NumberOfCoveredEmployeesInHawaii = 'numberOfCoveredEmployeesInHawaii',
  DCDStatus = 'dcdStatus',
  DCDStatusDate = 'dcdStatusDate',

  // Filter key
  DOLNumberFilterKey = 'employer.laborNumber',
  EmployerPOCEmailFilterKey = 'employerPointOfContact.email',

  // Audit
  CreatedBy = 'createdBy',
  CreatedAt = 'createdAt',
  UpdatedUser = 'updatedUser',
  UpdatedAt = 'updatedAt',
  UpdatedBy = 'updatedBy',
  CreatedUser = 'createdUser',
  AssignedUser = 'assignedUser',
}

export enum ActivateUpdatePlanKey {
  PlanType = 'planType',
  TDIPlanId = 'tdiPlanId',
  PlanNameNumber = 'planName',
  EmployerName = 'employerName',
  DOLNumber = 'employerDOLNumber',
  DCDStatus = 'dcdStatus',
  DCDStatusDate = 'dcdStatusDate',
  NumberOfEmployees = 'numberOfEmployees',
  IsFinanciallySolvent = 'isFinanciallySolvent',
  IsBond = 'isBond',
  DepositAmount = 'depositAmount',
  DepositDate = 'depositDate',
  RequiredDeposit = 'requiredDeposit',
  FinancialStatementYearEnding = 'financialStatementYearEnding',
  Comment = 'comment',
  TDIPlanAction = 'tdiPlanAction',
}
