export enum TDI21sAnnualExceptionReportKey {
  Id = 'id',
  County = 'county',
  EmployerName = 'employer',
  EmployerId = 'employerId',
  DOLNumber = 'laborNumber',
  Address = 'employerAddress',
  TypeOfPlanBeingReported = 'typeOfPlan',
  EmployeeToTotalContributionRatio = 'employeeToContributionTotalRatio',
  EmployeeContributionToTaxableWagesRatio = 'employeeContributionToTaxableWagesRatio',
  AverageNumberCoveredEmployees = 'avgNumberCoveredEmployees',
  JanTotalCoveredEmployees = 'totalCoveredEmployeesJan',
  FebTotalCoveredEmployees = 'totalCoveredEmployeesFeb',
  MarTotalCoveredEmployees = 'totalCoveredEmployeesMar',
  AprTotalCoveredEmployees = 'totalCoveredEmployeesApr',
  MayTotalCoveredEmployees = 'totalCoveredEmployeesMay',
  JunTotalCoveredEmployees = 'totalCoveredEmployeesJun',
  JulTotalCoveredEmployees = 'totalCoveredEmployeesJul',
  AugTotalCoveredEmployees = 'totalCoveredEmployeesAug',
  SepTotalCoveredEmployees = 'totalCoveredEmployeesSep',
  OctTotalCoveredEmployees = 'totalCoveredEmployeesOct',
  NovTotalCoveredEmployees = 'totalCoveredEmployeesNov',
  DecTotalCoveredEmployees = 'totalCoveredEmployeesDec',
  TotalWagesPaidCoveredEmployees = 'totalWagesPaidCoveredEmployees',
  TotalTaxableWagesPaid = 'totalTaxableWagesPaid',
  TotalEmployerContributions = 'totalEmployerContributions',
  TotalEmployeeContributions = 'totalEmployeeContributions',
}
