import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { createResponseWrapper } from '../helpers';
import { GLAccount } from './types';

export function useCreateGLAccount(options?: UseMutationOptions<GLAccount, Error, GLAccount>) {
  const { mutate: createGLAccount, isLoading } = useMutation<GLAccount, Error, GLAccount>({
    mutationFn: (payload: GLAccount) => createResponseWrapper(apiClient.createGLAccount, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    createGLAccount,
    isLoading,
  };
}
