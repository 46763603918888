import { Formik, FormikProps } from 'formik';
import { RefObject } from 'react';
import { Button, DatePicker, Form, Grid, Select, View } from 'src/modules/shared-main/components';
import { Callback } from 'src/redux/types';
import {
  ReportGroupRangeDateFilterKey,
  ReportGroupRangeDateFilterPayload,
  getInitialValues,
  groupByOptions,
  validationSchema,
} from '../helpers';
import { Filter } from 'src/modules/shared-main/queries';

const FilterForm: React.FC<Props> = ({
  filterFormRef,
  isLoading,
  toggleShow,
  viewFilters,
  handleApplyFilter,
}) => {
  const handleSubmit = (values: ReportGroupRangeDateFilterPayload) => {
    handleApplyFilter(values);
    toggleShow();
  };

  const handleCancel = () => {
    toggleShow();
  };

  const initialValues = getInitialValues(viewFilters);

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      innerRef={filterFormRef}
    >
      {({
        getFieldProps,
        setFieldTouched,
        setFieldValue,
        touched,
        errors,
        values,
        handleSubmit,
      }) => {
        const getErrorMessage = (fieldName: ReportGroupRangeDateFilterKey) => {
          // eslint-disable-next-line security/detect-object-injection
          return touched[fieldName] && errors[fieldName] ? (errors[fieldName] as string) : '';
        };

        return (
          <Form onSubmit={handleSubmit} autoComplete="off">
            <Grid.Wrap>
              <Grid.Item>
                <DatePicker
                  label="Start Date"
                  {...getFieldProps(ReportGroupRangeDateFilterKey.StartDate)}
                  required
                  maxDate={
                    values?.[ReportGroupRangeDateFilterKey.EndDate]
                      ? new Date(values?.[ReportGroupRangeDateFilterKey.EndDate])
                      : null
                  }
                  errorMessage={getErrorMessage(ReportGroupRangeDateFilterKey.StartDate)}
                  onBlur={setFieldTouched}
                  onChange={setFieldValue}
                />
              </Grid.Item>
              <Grid.Item>
                <DatePicker
                  label="End Date"
                  {...getFieldProps(ReportGroupRangeDateFilterKey.EndDate)}
                  required
                  minDate={
                    values?.[ReportGroupRangeDateFilterKey.StartDate]
                      ? new Date(values?.[ReportGroupRangeDateFilterKey.StartDate])
                      : null
                  }
                  popperPlacement="bottom-end"
                  errorMessage={getErrorMessage(ReportGroupRangeDateFilterKey.EndDate)}
                  onBlur={setFieldTouched}
                  onChange={setFieldValue}
                />
              </Grid.Item>
              <Grid.Item>
                <Select
                  label="Group by"
                  {...getFieldProps(ReportGroupRangeDateFilterKey.GroupBy)}
                  options={groupByOptions}
                  required
                  errorMessage={getErrorMessage(ReportGroupRangeDateFilterKey.GroupBy)}
                  onBlur={setFieldTouched}
                  onChange={setFieldValue}
                />
              </Grid.Item>
            </Grid.Wrap>

            <View className="mt-12 pt-16 pb-4" isRowWrap justify="space-between">
              <Button
                disabled={isLoading}
                className="mr-8"
                variant="outline"
                isFull
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                disabled={isLoading}
                className="ml-8"
                isLoading={isLoading}
                isFull
                type="submit"
              >
                Save
              </Button>
            </View>
          </Form>
        );
      }}
    </Formik>
  );
};

type Props = {
  filterFormRef: RefObject<FormikProps<ReportGroupRangeDateFilterPayload>>;
  isLoading?: boolean;
  toggleShow: Callback;
  handleApplyFilter: Function;
  viewFilters: Filter[];
};

export default FilterForm;
