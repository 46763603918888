import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { ApiResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { toHCPDetail } from './helpers';
import { HealthCarePlan } from './types';

export function useGetHealthCarePlan(
  options?: UseQueryOptions<
    ApiResponseType<{ healthCarePlan: HealthCarePlan }>,
    Error,
    HealthCarePlan
  > & {
    id: string;
  }
) {
  const {
    data: healthCarePlan,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetHealthCarePlanById,
  } = useQuery<ApiResponseType<{ healthCarePlan: HealthCarePlan }>, Error, HealthCarePlan>(
    [API_QUERIES.HealthCarePlans, { id: options.id }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<{ healthCarePlan: HealthCarePlan }>>(
          apiClient.getHCP,
          params
        );
      },
      select: ({ data }) => toHCPDetail(data.healthCarePlan),
      enabled: !!options.id,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateHealthCarePlan = () =>
    queryClient.invalidateQueries([API_QUERIES.HealthCarePlans, { id: options.id }]);

  return {
    healthCarePlan,
    error,
    isError,
    isLoading,
    onGetHealthCarePlanById,
    handleInvalidateHealthCarePlan,
  };
}
