import { PaginationResponseType, toAddress, toRelatedAuditData } from '../helpers';
import { PartyArchiveKey, PartyArchiveType } from './keys';
import { PartyArchive } from './types';

export const toRelatedPartyArchives = (data): PaginationResponseType<PartyArchive> => ({
  ...data,
  data: data?.data.map((item) => ({
    ...item,
    [PartyArchiveKey.PartyName]:
      item?.partyType === PartyArchiveType.Account
        ? item?.account?.accountName
        : item?.contact?.contactName,
    [PartyArchiveKey.PartyId]: item?.accountId || item?.contactId,
    [PartyArchiveKey.PartyAddress]:
      item?.partyType === PartyArchiveType.Account
        ? toAddress(item.account)
        : toAddress(item.contact),
    ...toRelatedAuditData(item),
  })),
});
