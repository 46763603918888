import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { PrehearingForEmployer } from './types';

export function useEditPrehearingForEmployer(
  options?: UseMutationOptions<any, Error, PrehearingForEmployer>
) {
  const {
    mutate: onEditPrehearingForEmployer,
    isLoading: isUpdating,
    isSuccess,
    isError,
    error,
  } = useMutation<any, Error, PrehearingForEmployer>({
    mutationFn: (payload: PrehearingForEmployer) =>
      responseWrapper(apiClient.updatePrehearingForEmployer, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    onEditPrehearingForEmployer,
    isUpdating,
    isSuccess,
    isError,
    error,
  };
}
