import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { authResponseWrapper } from 'src/queries/helpers';
import { Toastify } from 'src/services';

export function useDeleteWC2(options?: UseMutationOptions<string, Error, string>) {
  const { mutate: deleteWC2, isLoading } = useMutation<string, Error, string>({
    mutationFn: (payload: string) => authResponseWrapper(apiClient.deleteWC2, [payload]),
    onError: ({ message }) => Toastify.error(message),
    ...options,
  });

  return {
    deleteWC2,
    isLoading,
  };
}
