import { UseQueryOptions, useQuery, useQueryClient } from 'react-query';
import apiClient from '../apiClient';
import { API_QUERIES } from '../keys';
import { CalculateEquivalencyPayload } from './types';

export function useCalculateEquivalency(
  options: UseQueryOptions & {
    accidentCalendarDayBenefitsCommence: number;
    illness: number;
    initialWage: number;
    initialDuration: number;
    subsequentWage: number;
    subsequentDuration: number;
  }
) {
  const {
    data: equivalency,
    error,
    isError,
    isFetching: isLoading,
    refetch: onCalculateEquivalency,
  } = useQuery([API_QUERIES.CALCULATE_EQUIVALENCY, options], {
    queryFn: (query) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const params = query.queryKey[1] as CalculateEquivalencyPayload;
      return apiClient.calculateEquivalency(params);
    },
    select: ({ data }) => data?.data?.value,
    enabled: options.enabled,
    ...options,
  });

  const queryClient = useQueryClient();

  const handleInvalidateEquivalency = () =>
    queryClient.invalidateQueries([API_QUERIES.CALCULATE_EQUIVALENCY]);

  return {
    equivalency,
    error,
    isError,
    isLoading,
    onCalculateEquivalency,
    handleInvalidateEquivalency,
  };
}
