/* eslint-disable react-hooks/exhaustive-deps */
import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { Accept, FileRejection, useDropzone } from 'react-dropzone';

import { IoAttachOutline } from 'react-icons/io5';
import appConfig from 'src/appConfig';
import { COLOR_CODE, COMMON_TYPE } from 'src/appConfig/constants';
import { Toastify } from 'src/services';
import { Text, View } from '..';
import './styles.scss';

const FileUpload: React.FC<FileUploadProps> = ({
  className,
  onChange,
  innerRef,
  numberAllow = null,
  onError,
  multiple = false,
  acceptFileType,
  message = `Drop file here or select file`,
  maxSize = appConfig.MAXIMUM_FILE_SIZE,
}) => {
  const [myFiles, setMyFiles] = useState<File[]>([]);
  const [rejectFiles, setRejectFiles] = useState<FileRejection[]>([]);
  const [errorMessage, setErrorMessage] = useState('');

  const onDrop = (acceptedFiles: File[], fileRejections: FileRejection[]) => {
    setMyFiles(acceptedFiles);
    setRejectFiles(fileRejections);
  };

  // List MIME can be found here:
  // https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: acceptFileType ? acceptFileType : COMMON_TYPE,
    multiple: multiple,
    maxSize: maxSize,
  });

  useEffect(() => {
    if (rejectFiles.length > 0) {
      rejectFiles.forEach((file) => {
        const error = file.errors[0];
        if (error.code === 'file-too-large') {
          const sizeInMB = (file.file.size / (1024 * 1024)).toFixed(2);
          Toastify.error(`File is larger than ${sizeInMB} MB`);
        } else {
          Toastify.error(error.message);
        }
      });
    }
  }, [rejectFiles]);

  useEffect(() => {
    if (!!numberAllow && myFiles.length > numberAllow)
      return Toastify.error('Can not upload more than 2 files');
    if (myFiles.length > 0) {
      setErrorMessage('');
      onChange(myFiles);
    }
  }, [myFiles]);

  return (
    <View className={cn(className, 'cmp-file-upload')}>
      <View
        {...getRootProps({
          className: cn('cmp-file-upload__body', { 'cmp-file-upload__error': !!errorMessage }),
        })}
      >
        <input
          data-testid="upload-input"
          {...getInputProps()}
          {...(innerRef && {
            ref: innerRef,
          })}
        />
        <View isRowWrap align="center">
          <i
            style={{
              transform: 'translateY(2px)',
            }}
            className="mr-1"
          >
            <IoAttachOutline
              size={22}
              color={errorMessage ? COLOR_CODE.DANGER : COLOR_CODE.PRIMARY}
            />
          </i>
          <Text
            className={cn(
              'fw-medium',
              { 'has-text-danger': !!errorMessage },
              { 'has-text-primary': !errorMessage }
            )}
          >
            {message}
          </Text>
        </View>
      </View>

      <View renderIf={!!errorMessage}>
        <Text className="has-text-danger">{errorMessage}</Text>
      </View>
    </View>
  );
};

export type FileUploadProps = {
  className?: string;
  innerRef?: any;
  numberAllow?: number;
  onChange: (...args: any[]) => void;
  onError?: (value: any) => void;
  acceptFileType?: Accept;
  message?: string;
  maxSize?: number;
  multiple?: boolean;
};

export default FileUpload;
