import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { GetPropertiesParams } from 'src/queries/types';
import { toPeriodsOfTTDData } from './helpers';
import { PeriodsOfTTDResponse } from './types';

export function useGetAllPeriodsOfTTD(
  options?: UseQueryOptions<ApiResponseType<PeriodsOfTTDResponse>, Error, PeriodsOfTTDResponse> & {
    id: string;
  }
) {
  const [params, setParams] = useState<GetPropertiesParams>({});
  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetAllPeriodsOfTTD,
  } = useQuery<ApiResponseType<PeriodsOfTTDResponse>, Error, PeriodsOfTTDResponse>(
    [API_QUERIES.AWARD_WORKSHEET_PERIODS_OF_TTD, { ...params, id: options.id }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<PeriodsOfTTDResponse>>(
          apiClient.getAllPeriodsOfTtd,
          params
        );
      },
      select: (data) => toPeriodsOfTTDData(data?.data),
      notifyOnChangeProps: ['data', 'isFetching'],
      keepPreviousData: true,
      enabled: !!options.id,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidatePeriodsOfTTD = () =>
    queryClient.invalidateQueries(API_QUERIES.AWARD_WORKSHEET_PERIODS_OF_TTD);

  const { periodsOfTtdPagination, totalAwardAmount, totalNumberOfWeeks } = data || {};

  const { data: periodsOfTTDs, hasNext, payloadSize, totalRecords } = periodsOfTtdPagination || {};

  return {
    periodsOfTTDs,
    hasNext,
    payloadSize,
    totalRecords,
    totalAwardAmount,
    totalNumberOfWeeks,
    error,
    isError,
    isFetching,
    onGetAllPeriodsOfTTD,
    setParams,
    handleInvalidatePeriodsOfTTD,
  };
}
