import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { PaginationResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { GetPropertiesParams } from '../types';
import { Profile } from './types';

export function useGetAllProfiles(
  options?: UseQueryOptions<PaginationResponseType<Profile>, Error> & {
    profileName?: string;
  }
) {
  const [params, setParams] = useState<GetPropertiesParams>({});
  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetAllProfile,
  } = useQuery<PaginationResponseType<Profile>, Error>(
    [API_QUERIES.Profiles, { ...params, search: options?.profileName || params?.search }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<PaginationResponseType<Profile>>(apiClient.getAllProfile, params);
      },
      onError: ErrorService.handler,
      notifyOnChangeProps: ['data', 'isFetching'],
      keepPreviousData: true,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateAllProfile = () => queryClient.invalidateQueries(API_QUERIES.Profiles);

  const { data: profiles, hasNext, payloadSize, totalRecords } = data || {};

  return {
    profiles,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isError,
    isFetching,
    onGetAllProfile,
    setParams,
    handleInvalidateAllProfile,
  };
}
