import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import momentTz from 'moment-timezone';
import { isEmpty } from 'src/validations';

dayjs.extend(weekday);

export const DateFormat = 'MM/DD/YYYY';
export const DateFormatWithHour = 'DD/MM/YYYY HH:mm';
export const DateFormatDisplay = 'MMMM DD, YYYY';
export const DateFormatDisplayShort = 'MMM DD, YYYY';
export const MonthYearFormatDisplay = 'MMMM YYYY';
export const DateFormatDisplayMinute = 'MM/DD/YYYY hh:mm A';
export const YEAR_FORMAT = 'YYYY';

export const TimeFormat = 'HH:mm';
export const HalfTimeFormat = 'hh:mm A';
export const HALF_TIME_FORMAT_STRIKE_THROUGH = 'THH:mm:s';

export const DATE_FORMAT_STRIKE_THROUGH = 'YYYY-MM-DD';
export const DATE_PICKER_TIME_FORMAT = 'hh:mm aa';
export const DATE_PICKER_DATE_FORMAT = 'MM/dd/yyyy';
export const DATE_PICKER_DATE_TIME_FORMAT = 'MM/dd/yyyy hh:mm aa';
export const DATE_PICKER_YEAR_FORMAT = 'yyyy';
export const DATE_PICKER_MONTH_YEAR_FORMAT = 'MM/yyyy';

/**
 * Get date display
 * @param {string|date|Dayjs} value
 * @param {string} languageCode
 */
export const getDateDisplay = (value: string) => {
  return dayjs(value).format(DateFormat);
};

/**
 * Get date display
 * @param {string|date|Dayjs} value
 * @param {string} languageCode
 */
export const getTimeDisplay = (value: string) => {
  return dayjs(value).format(TimeFormat);
};

/// dayjs has many cases incorrect format with timezone so using moment-timezone for this case
/// Reference issues : https://github.com/iamkun/dayjs/issues/1827
export const localTimeToHawaii = (dateTime) => {
  const date = momentTz(dateTime).format(DateFormatWithHour);
  return momentTz(date, DateFormatWithHour).utcOffset('-1000').format(DateFormatDisplayMinute);
};

export const addDays = (dateStr: string, days: number = 1) => {
  if (isEmpty(dateStr)) {
    return null;
  }

  const date = new Date(dateStr);
  date.setDate(date.getDate() + days);
  return date;
};

export const subtractDays = (dateStr: string, days: number = 1) => {
  const date = new Date(dateStr);
  date.setDate(date.getDate() - days);
  return date;
};
