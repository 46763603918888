import React, { HTMLProps, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import './styles.scss';
import shortid from 'shortid';
import Element from '../Element';
import { View } from '..';
import Text from '../Text';
import { isEmpty } from 'src/validations';
import { emptyFunction } from 'src/utils';

const Checkbox: React.FC<CheckboxProps> = ({
  label,
  errorMessage,
  className,
  disabled,
  style,
  isCustomLabel,
  labelClassName,
  isGetStringValue,
  isMiddle,
  onChange,
  onBlur = emptyFunction,
  name,
  readOnly,
  ...props
}) => {
  const id = useRef(shortid.generate());
  const hasError = !isEmpty(errorMessage);

  const handleChange = (e) => {
    const { checked } = e.target;
    onChange(name, checked);
    onBlur(name, true);
  };

  return (
    <View
      isRow
      className={cn(
        'cmp-checkbox',
        { 'cmp-checkbox__disabled': disabled, 'cmp-checkbox__readonly': readOnly },
        className
      )}
      style={style}
    >
      <View isRow>
        <input
          id={id.current}
          type="checkbox"
          className={cn('cmp-checkbox__input')}
          style={{ display: 'none' }}
          {...props}
          onChange={handleChange}
          name={name}
        />
        <label
          htmlFor={id.current}
          className={cn(
            'cmp-checkbox__label',
            'check',
            {
              'custom-label': isCustomLabel,
              'is-middle': isMiddle,
            },
            labelClassName
          )}
        >
          <div className="cmp-checkbox__label--box">
            <svg width="18px" height="18px" viewBox="0 0 18 18">
              <path d="M1,9 L1,3.5 C1,2 2,1 3.5,1 L14.5,1 C16,1 17,2 17,3.5 L17,14.5 C17,16 16,17 14.5,17 L3.5,17 C2,17 1,16 1,14.5 L1,9 Z" />
              <polyline points="1 9 7 14 15 4" />
            </svg>
          </div>
          <span className={cn('cmp-checkbox__label--text')}>{label}</span>
        </label>
      </View>
      {hasError && <p className="cmp-checkbox__error">{errorMessage}</p>}
    </View>
  );
};

type BaseInputProps = Pick<
  HTMLProps<HTMLInputElement>,
  Exclude<keyof HTMLProps<HTMLInputElement>, 'label' | 'onChange' | 'onBlur'>
>;
export type CheckboxProps = BaseInputProps & {
  label?: React.ReactNode | string;
  errorMessage?: string;
  isMiddle?: boolean;
  isCustomLabel?: boolean;
  labelClassName?: string;
  noneOfAboveKey?;
  isGetStringValue?: boolean;
  onChange?: (...args: any) => void;
  onBlur?: (...args: any) => void;
};

const Group: React.FC<CheckboxGroupProps> = ({
  label,
  options,
  name,
  onChange = () => {},
  value = [],
  errorMessage,
  containerClassName,
  columns = 2,
  customColumnMargin,
  disabled,
  description,
  required,
  readOnly,
  noneOfAboveKey,
  isGetStringValue,
}) => {
  const [data, setData] = useState<any[]>(value);

  useEffect(() => {
    // if (hasInteract.current)
    setData(value);
    // onChange(name, data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleValueChange = (name) => {
    const newData = [...data];

    const index = newData.indexOf(name);

    if (index >= 0) {
      newData.splice(index, 1);
    } else {
      newData.push(name);
    }

    setData(newData);
    onChange(name, newData);
  };

  const hasDesc = !!description;

  return (
    <Element
      errorMessage={errorMessage}
      required={required}
      label={label}
      className={containerClassName}
    >
      {hasDesc && <Text className="text-is-14 mb-8">{description}</Text>}
      <View isRow>
        {options?.map((option, index) => (
          <Checkbox
            key={`checkbox-${name}-${index}`}
            value={option.value}
            checked={data?.indexOf(option.value) >= 0}
            label={option.label}
            onChange={handleValueChange}
            className={cn(columns && 'cmp-checkbox-group__column mt-8')}
            style={
              customColumnMargin
                ? { marginRight: customColumnMargin + 'px' }
                : { width: `${100 / columns}%` }
            }
            disabled={disabled}
            name={option.value}
            readOnly={readOnly}
          />
        ))}
      </View>
    </Element>
  );
};

export type CheckboxGroupProps = {
  label?: React.ReactNode | string;
  options?: { value: any; label: React.ReactNode | string; key?: string }[];
  value?: any[];
  name?: string;
  required?: boolean;
  onChange?: (...arg: any[]) => void;
  errorMessage?: string;
  containerClassName?: string;
  labelClassName?: string;
  description?: string;
  columns?: number;
  customColumnMargin?: number;
  disabled?: boolean;
  readOnly?: boolean;
  noneOfAboveKey?;
  isCustomLabel?: boolean;
  isGetStringValue?: boolean;
};

export default { Item: Checkbox, Group };
