import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { TDIPlan } from './types';

export function useEditTdiPlan(options?: UseMutationOptions<any, Error, TDIPlan>) {
  const {
    mutate: onEditTdiPlan,
    isLoading: isUpdating,
    isSuccess,
    isError,
    error,
  } = useMutation<any, Error, TDIPlan>({
    mutationFn: (payload: TDIPlan) => responseWrapper(apiClient.updateTDIPlan, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    onEditTdiPlan,
    isUpdating,
    isSuccess,
    isError,
    error,
  };
}
