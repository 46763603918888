import { useMemo, useState } from 'react';
import { UseInfiniteQueryOptions, useInfiniteQuery, useQueryClient } from 'react-query';
import { useDebounce } from 'src/hooks';
import { isEmpty } from 'src/validations';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { Option } from '../types';
import { ContactKey } from './keys';
import { Contact } from './types';

const pageParam = {
  take: 10,
  skip: 0,
};

export function useLazyContact(
  options?: UseInfiniteQueryOptions<{ data: Contact[] }, Error> & {
    [key: string]: string | number | string[];
  }
) {
  const [inputSearch, setInputSearch] = useState<string>('');
  const debounceSearch = useDebounce(inputSearch);
  const {
    data,
    error,
    isError,
    isFetching,
    refetch: getContact,
    fetchNextPage,
  } = useInfiniteQuery<{ data: Contact[] }, Error>(
    [API_QUERIES.Contacts, pageParam, debounceSearch, { ...options }],
    (props): Promise<{ data: Contact[] }> => {
      const { pageParam, queryKey } = props;
      const search = queryKey.at(-2);
      const options = queryKey.at(-1);

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      return responseWrapper<{ data: Contact[] }>(apiClient.getAllContacts, [
        { ...pageParam, search, ...(options as Object) },
      ]);
    },
    {
      keepPreviousData: true,
      getNextPageParam(lastPage, allPages) {
        if (lastPage?.data?.length < 10) return undefined;
        return {
          take: 10,
          skip: allPages?.length * 10,
        };
      },
      notifyOnChangeProps: ['data', 'isFetching'],
      ...options,
    }
  );

  const contacts: Option[] = useMemo(() => {
    if (isEmpty(data)) return [];
    return data?.pages?.reduce(
      (state, page, pageIdx) => [
        ...state,
        ...page.data.map(
          (contact, idx): Option => ({
            label: contact[ContactKey.ContactName],
            value: contact[options?.valueKey as string] || contact[ContactKey?.Id],
            index: pageIdx * 10 + idx,
            subLabel: contact?.accountName,
          })
        ),
      ],
      []
    ) as Option[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const queryClient = useQueryClient();

  const handleInvalidateLazyContact = () => queryClient.resetQueries([API_QUERIES.Contacts]);

  return {
    contacts,
    error,
    isError,
    loading: isFetching,
    getContact,
    fetchNextPage,
    setInputSearch,
    handleInvalidateLazyContact,
  };
}
