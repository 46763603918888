import React, { Fragment, useState } from 'react';
import './styles.scss';
import { Typography, Stack, Box } from '@mui/material';
import { IMAGES } from 'src/appConfig/images';
import { Menu, MenuItem } from '@mui/material';
import { RiLogoutBoxLine, RiAccountCircleFill } from 'react-icons/ri';
import { useLogout, useProfile, useGetPresignedDownloadAvatarUrl } from 'src/queries';
import { Navigator } from 'src/services';
import { Image, Text } from 'src/modules/shared-main/components';

const NavAvatar: React.FC<Props> = ({ pathname }) => {
  const { logout } = useLogout();

  const { profile } = useProfile();

  const { avatarUrl } = useGetPresignedDownloadAvatarUrl({
    filePath: profile?.profile?.avatar || null,
  });

  const { firstName, lastName, userType } = profile || {};
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    setAnchorEl(null);
    logout();
  };
  const open = Boolean(anchorEl);
  return (
    <Fragment>
      <Stack
        flexDirection={'row'}
        alignItems={'center'}
        onClick={handleClick}
        sx={{ cursor: 'pointer' }}
      >
        <Image src={avatarUrl ? avatarUrl : IMAGES.defaultUser} className="ctn-navbar__avatar" />
        <Box sx={{ marginLeft: 1 }}>
          <Text size={18} className="ctn-navbar__text fw-bold" style={{ padding: 0 }}>
            {firstName} {lastName}
          </Text>
          <Typography variant="body2" className="ctn-navbar__text">
            {userType}
          </Typography>
        </Box>
      </Stack>
      <Menu
        id={'simple-Menu'}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{ mt: 1 }}
        elevation={1}
        disableScrollLock={true}
      >
        <MenuItem
          onClick={() => {
            Navigator.navigate('/me');
            handleClose();
          }}
          sx={{ color: '#333333' }}
        >
          <RiAccountCircleFill size={20} />
          <Typography sx={{ ml: 1 }}>Personal Info</Typography>
        </MenuItem>
        <MenuItem onClick={handleLogOut} sx={{ color: '#333333' }}>
          <RiLogoutBoxLine size={20} />
          <Typography sx={{ ml: 1 }}>Log out</Typography>
        </MenuItem>
      </Menu>
    </Fragment>
  );
};

type Props = { pathname: string };

export default NavAvatar;
