import { PaginationResponseType } from '../helpers';
import { User } from '../Users';

export enum GroupKey {
  Name = 'name',
  SendEmailToMember = 'sendEmail',
  MemberUser = 'users',
  LastModifiedAt = 'updatedAt',
  LastModifiedBy = 'updatedBy',
}

export interface Group {
  id: string;
  [GroupKey.Name]: string;
  [GroupKey.SendEmailToMember]: boolean;
  [GroupKey.MemberUser]?: string;
  [GroupKey.LastModifiedAt]: string;
  [GroupKey.LastModifiedBy]: UserModified;
  modifyBy?: string;
  updatedByUser?: UserModified;
}

export interface UserModified {
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
}

export type UpdateSendEmailPayload = {
  id: string;
  sendEmail: boolean;
};

export interface AddUsersToGroupResponse {
  group: Group;
  groupUsers: PaginationResponseType<User>;
}
