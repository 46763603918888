import { formatTDICaseNumber } from 'src/utils';
import { toAddress, toAuditData } from '../helpers';
import { TDISFCaseKey } from './keys';

export const toTDISFCaseDetail = (data) => ({
  ...data,
  [TDISFCaseKey.TDISFCaseFormattedNumber]: formatTDICaseNumber(data?.tdiSFCaseNumber),
  [TDISFCaseKey.ClaimantName]: data?.claimant?.accountName,
  [TDISFCaseKey.ClaimantSSN]: data?.claimant?.ssn,
  [TDISFCaseKey.SSN]: data?.claimant?.ssn,
  [TDISFCaseKey.ClaimantDOB]: data?.claimant?.dateOfBirth,
  [TDISFCaseKey.ClaimantDateOfBirth]: data?.claimant?.dateOfBirth,
  [TDISFCaseKey.ClaimantPhone]: data?.claimant?.phone,
  [TDISFCaseKey.ClaimantDateOfDeath]: data?.claimant?.dateOfDeath,
  [TDISFCaseKey.ClaimantAddress]: toAddress(data?.claimant),
  [TDISFCaseKey.ClaimantState]: data?.claimant?.state,
  [TDISFCaseKey.ClaimantZip]: data?.claimant?.zip,
  [TDISFCaseKey.ClaimantCity]: data?.claimant?.city,
  [TDISFCaseKey.ClaimantStreet]: data?.claimant?.street,
  [TDISFCaseKey.ClaimantPhoneNumber]: data?.claimant?.phone,
  [TDISFCaseKey.ClaimantEmail]: data?.claimant?.email,
  [TDISFCaseKey.ClaimantSex]: data?.claimant?.gender,
  [TDISFCaseKey.ClaimantMaritalStatus]: data?.claimant?.maritalStatus,

  [TDISFCaseKey.EmployerName]: data?.employer?.accountName,
  [TDISFCaseKey.EmployerAddress]: toAddress(data?.employer),
  [TDISFCaseKey.EmployerStatus]: data?.employer?.dcdStatus,
  [TDISFCaseKey.EmployerStatusDate]: data?.employer?.dcdStatusDate,

  [TDISFCaseKey.PhysicianName]: data?.physician?.accountName,
  [TDISFCaseKey.PhysicianAddress]: toAddress(data?.physician),
  [TDISFCaseKey.PhysicianPhoneNumber]: data?.physician?.phone,
  ...toAuditData(data),
});

export const toTDISFCase = (data) => ({
  ...data,
  data: data.data.map((item) => ({
    ...item,
    [TDISFCaseKey.ClaimantName]: item?.claimant?.accountName,
    [TDISFCaseKey.ClaimantId]: item?.claimant?.id,
    [TDISFCaseKey.ClaimantSSN]: item?.claimant?.ssn,
    [TDISFCaseKey.ClaimantDOB]: item?.claimant?.dateOfBirth,
    [TDISFCaseKey.ClaimantPhoneNumber]: item?.claimant?.phone,
    [TDISFCaseKey.ClaimantEmail]: item?.claimant?.email,
    [TDISFCaseKey.ClaimantState]: item?.claimant?.state,
    [TDISFCaseKey.ClaimantZip]: item?.claimant?.zip,
    [TDISFCaseKey.ClaimantCity]: item?.claimant?.city,
    [TDISFCaseKey.ClaimantStreet]: item?.claimant?.street,
    [TDISFCaseKey.ClaimantSex]: item?.claimant?.gender,
    [TDISFCaseKey.ClaimantMaritalStatus]: item?.claimant?.maritalStatus,

    [TDISFCaseKey.EmployerName]: item?.employer?.accountName,
    [TDISFCaseKey.EmployerId]: item?.employer?.id,
    [TDISFCaseKey.EmployerStatus]: item?.employer?.dcdStatus,
    [TDISFCaseKey.EmployerStatusDate]: item?.employer?.dcdStatusDate,
    [TDISFCaseKey.EmployerState]: item?.employer?.state,
    [TDISFCaseKey.EmployerZip]: item?.employer?.zip,
    [TDISFCaseKey.EmployerCity]: item?.employer?.city,
    [TDISFCaseKey.EmployerStreet]: item?.employer?.street,

    [TDISFCaseKey.PhysicianName]: item?.physician?.accountName,
    [TDISFCaseKey.PhysicianId]: item?.physician?.id,
    [TDISFCaseKey.PhysicianPhoneNumber]: item?.physician?.phone,
    [TDISFCaseKey.PhysicianState]: item?.physician?.state,
    [TDISFCaseKey.PhysicianZip]: item?.physician?.zip,
    [TDISFCaseKey.PhysicianCity]: item?.physician?.city,
    [TDISFCaseKey.PhysicianStreet]: item?.physician?.street,
    ...toAuditData(item),
  })),
});
