import { useMutation, UseMutationOptions } from 'react-query';
import { Toastify } from 'src/services';
import apiClient from '../apiClient';
import { createResponseWrapper } from '../helpers';
import { BenefitPaymentSummaryPayload } from './BenefitPaymentSummaryType';

export function useCreateBenefitPaymentSummary(
  options?: UseMutationOptions<BenefitPaymentSummaryPayload, Error, BenefitPaymentSummaryPayload>
) {
  const { mutate: createBenefitPaymentSummary, isLoading } = useMutation<
    BenefitPaymentSummaryPayload,
    Error,
    BenefitPaymentSummaryPayload
  >({
    mutationFn: (payload: BenefitPaymentSummaryPayload) =>
      createResponseWrapper(apiClient.createBenefitPaymentSummary, [payload]),
    onError: ({ message }) => Toastify.error(message),
    ...options,
  });

  return {
    createBenefitPaymentSummary,
    isLoading,
  };
}
