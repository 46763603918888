import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { PaginationResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { GetPropertiesParams } from '../types';
import { toRequiredDocument } from './helpers';
import { RelatedHearingRequiredDocumentsType } from './types';

export function useGetAllRelatedHearingRequiredDocuments(
  options?: UseQueryOptions<PaginationResponseType<RelatedHearingRequiredDocumentsType>, Error> & {
    id: string;
  }
) {
  const [params, setParams] = useState<GetPropertiesParams>({});
  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetAllRelatedHearingRequiredDocuments,
  } = useQuery<PaginationResponseType<RelatedHearingRequiredDocumentsType>, Error>(
    [API_QUERIES.RELATED_HEARING_REQUIRED_DOCUMENTS, { ...params, hearingId: options.id }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<PaginationResponseType<RelatedHearingRequiredDocumentsType>>(
          apiClient.getAllRelatedHearingRequiredDocuments,
          params
        );
      },
      select: (data) => toRequiredDocument(data),
      notifyOnChangeProps: ['data', 'isFetching'],
      keepPreviousData: true,
      enabled: !!options.id,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateAllRelatedHearingRequiredDocuments = () =>
    queryClient.invalidateQueries(API_QUERIES.RELATED_HEARING_REQUIRED_DOCUMENTS);

  const { data: relatedHearingRequiredDocuments, hasNext, payloadSize, totalRecords } = data || {};

  return {
    relatedHearingRequiredDocuments,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isError,
    isFetching,
    onGetAllRelatedHearingRequiredDocuments,
    setParams,
    handleInvalidateAllRelatedHearingRequiredDocuments,
  };
}
