import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { ApiResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { GetPropertiesParams } from '../types';
import { toTDI15sTDISelfInsurerPlanReport } from './helpers';
import { TDI15sTDISelfInsurerPlanReportResponse } from './types';

export function useGetTDI15sTDISelfInsurerPlanReport(
  options?: UseQueryOptions<ApiResponseType<TDI15sTDISelfInsurerPlanReportResponse>, Error>
) {
  const [params, setParams] = useState<GetPropertiesParams>({});

  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetRejectedSelfInsuredHCPReport,
  } = useQuery<ApiResponseType<TDI15sTDISelfInsurerPlanReportResponse>, Error>(
    [API_QUERIES.REPORT_TDI_15S_TDI_SELF_INSURER_PLAN, { ...params, ...options }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<TDI15sTDISelfInsurerPlanReportResponse>>(
          apiClient.getTDI15sTDISelfInsurerPlanReport,
          params
        );
      },
      onError: ErrorService.handler,
      notifyOnChangeProps: ['data', 'isFetching'],
      select: (data) => toTDI15sTDISelfInsurerPlanReport(data),
      keepPreviousData: true,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateTDI15sTDISelfInsurerPlanReport = () =>
    queryClient.invalidateQueries(API_QUERIES.REPORT_TDI_15S_TDI_SELF_INSURER_PLAN);

  const {
    data: tdi15sTDISelfInsurerPlanReport,
    hasNext,
    payloadSize,
    totalRecords,
  } = data?.data?.reports || {};

  return {
    tdi15sTDISelfInsurerPlanReport,
    totalAcceptActionTaken: data?.data?.totalAcceptedActionTaken || 0,
    totalRejectActionTaken: data?.data?.totalRejectedActionTaken || 0,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isError,
    isFetching,
    onGetRejectedSelfInsuredHCPReport,
    setParams,
    handleInvalidateTDI15sTDISelfInsurerPlanReport,
  };
}
