import { useMemo, useState } from 'react';
import { UseInfiniteQueryOptions, useInfiniteQuery, useQueryClient } from 'react-query';
import { useDebounce } from 'src/hooks';
import { isEmpty } from 'src/validations';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { Option } from '../types';
import { WC1, WC1Key } from './types';

const pageParam = {
  take: 10,
  skip: 0,
};

export function useLazyWC1s(
  options?: UseInfiniteQueryOptions<{ data: WC1[] }, Error> & {
    [key: string]: string | number | string[];
  }
) {
  const [inputSearch, setInputSearch] = useState<string>('');
  const debounceSearch = useDebounce(inputSearch);
  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetAllWC1s,
    fetchNextPage,
  } = useInfiniteQuery<{ data: WC1[] }, Error>(
    [API_QUERIES.WC1s, pageParam, debounceSearch, { ...options }],
    (props): Promise<{ data: WC1[] }> => {
      const { pageParam, queryKey } = props;
      const search = queryKey.at(-2);
      const options = queryKey.at(-1);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      return responseWrapper<{ data: WC1[] }>(apiClient.getAllWC1s, [
        { ...pageParam, search, ...(options as Object) },
      ]);
    },
    {
      keepPreviousData: true,
      getNextPageParam(lastPage, allPages) {
        if (lastPage?.data?.length < 10) return undefined;
        return {
          take: 10,
          skip: allPages?.length * 10,
        };
      },
      notifyOnChangeProps: ['data', 'isFetching'],
      ...options,
    }
  );

  const wc1s: Option[] = useMemo(() => {
    if (isEmpty(data)) return [];
    return data.pages.reduce(
      (state, page, pageIdx) => [
        ...state,
        ...page.data.map(
          (wc1, idx): Option => ({
            label: wc1[WC1Key.RawWC1Number],
            value: wc1?.id.toString(),
            index: pageIdx * 10 + idx,
          })
        ),
      ],
      []
    ) as Option[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const queryClient = useQueryClient();

  const handleInvalidateLazyWC1 = () => queryClient.invalidateQueries([API_QUERIES.WC1s]);

  return {
    wc1s,
    error,
    setInputSearch,
    isError,
    loading: isFetching,
    onGetAllWC1s,
    fetchNextPage,
    handleInvalidateLazyWC1,
  };
}
