import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { createResponseWrapper } from '../helpers';
import { AwardEmployment } from './types';

export function useCreateAwardEmployment(
  options?: UseMutationOptions<AwardEmployment, Error, AwardEmployment>
) {
  const { mutate: createAwardEmployment, isLoading } = useMutation<
    AwardEmployment,
    Error,
    AwardEmployment
  >({
    mutationFn: (payload: AwardEmployment) =>
      createResponseWrapper(apiClient.createAwardEmployment, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    createAwardEmployment,
    isLoading,
  };
}
