import { AwardSummaryKey } from './types';

export const getAwardSummary = (data) => ({
  ...data,
  [AwardSummaryKey.CaseNumber]: data?.wcCase?.caseNumber,
  [AwardSummaryKey.ClaimantId]: data?.wcCase?.claimantId,
  [AwardSummaryKey.ClaimantName]: data?.wcCase?.claimant?.accountName,
  [AwardSummaryKey.ClaimantSSN]: data?.wcCase?.claimant?.ssn,
  [AwardSummaryKey.DateOfInjury]: data?.wcCase?.dateOfInjury,
});
