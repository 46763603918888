export enum HearingReassignKey {
  CurrentHearingOfficer = 'hearingOfficer',
  MinimumOfficerPosition = 'minimumOfficerPosition',
  CurrentServicingLocation = 'currentServicingLocation',
  ReassignHearingOfficerId = 'reassignHearingOfficerId',
  ReassignRefereeId = 'reassignRefereeId',
  HOReassignmentReason = 'hoReassignmentReason',
  CurrentReferee = 'currentReferee',
}

export enum HearingCancelKey {
  CancellationReason = 'cancellationReason',
}

export enum HearingPostponeKey {
  PostponeReason = 'postponeReason',
  DetailMoreInformation = 'detailMoreInformation',
}

export enum HearingAssignKey {
  HearingOfficer = 'hearingOfficer',
  HearingOfficerId = 'hearingOfficerId',
  MinimumOfficerPosition = 'minimumOfficerPosition',
}
