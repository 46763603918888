import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { PaginationResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { GetPropertiesParams } from '../types';
import { toReceipts } from './helpers';
import { Receipt } from './types';

export function useGetAllReceipts(
  options?: UseQueryOptions<PaginationResponseType<Receipt>, Error> & {
    caseId: string;
  }
) {
  const [params, setParams] = useState<GetPropertiesParams>({});

  const { data, error, isError, isFetching } = useQuery<PaginationResponseType<Receipt>, Error>(
    [API_QUERIES.Receipts, { ...params, caseId: options?.caseId }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<PaginationResponseType<Receipt>>(apiClient.getAllReceipts, params);
      },
      notifyOnChangeProps: ['data', 'isFetching'],
      keepPreviousData: true,
      select: toReceipts,
      onError: ErrorService.handler,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateAllReceipts = () => queryClient.invalidateQueries(API_QUERIES.Receipts);

  const { data: receipts, hasNext, payloadSize, totalRecords } = data || {};

  return {
    receipts,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isError,
    isFetching,
    setParams,
    handleInvalidateAllReceipts,
  };
}
