import { Divider } from '@mui/material';
import cn from 'classnames';
import { isString } from 'lodash';
import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import Select, { OptionProps, components } from 'react-select';
import { COLOR_CODE } from 'src/modules/shared-main/common/appConfig/constants';
import {
  DropdownIndicator,
  IndicatorSeparator,
  Control,
  ControlWithLabel,
  ControlNoSearchIcon,
} from './components';
import { emptyFunction, getRandomId } from 'src/utils';
import { isEmpty } from 'src/validations';
import LazyCheckPoint from './LazyCheckPoint';
import './styles.scss';
import {
  toDefaultSelectedOptions,
  toOptions,
  getSelectedOption,
  getMenuPlacement,
  getFilteredSelectedOption,
  MenuPlacement,
  MAX_MENU_HEIGHT,
} from './helpers';
import { SelectProps } from './types';
import { View, Image, Button, Text } from '..';
import Element from '../Element';

const SearchableSelect: React.FC<SelectProps> = ({
  containerClassName = '',
  label,
  errorMessage = '',
  required = false,
  infoTooltipMessage = '',
  infoTooltipPlacement = 'right',
  infoToolTipWithArrow = true,
  subLabel,
  postfixLabel,
  onChange = emptyFunction,
  onInputChange = emptyFunction,
  className = '',
  value,
  placeholder = '',
  onBlur,
  name = '',
  isSearchable = true,
  isDisabled = false,
  isMulti = false,
  options,
  defaultOptions = [],
  isLoading,
  fetchNextPage,
  menuPlacement = 'auto',
  menuPosition = 'fixed',
  defaultInputValue,
  handleClickNew,
  newButtonLabel,
  iconSearch,
  labelRecent,
  labelControl = '',
  hideSearchIcon = false,
  isGetOptionOnChange = false,
  subLabelKey = 'subLabel',
  ...props
}) => {
  const id = useRef<string>(`search-select-${getRandomId()}`);
  const defaultPlaceholder =
    isString(label) && !isDisabled ? `Search ${label.toLowerCase()}` : null;

  const [defaultSelectedOptions, setDefaultSelectedOptions] = useState([]);

  useEffect(() => {
    if (isMulti) {
      const filteredSelectedOption = getFilteredSelectedOption(value);
      setDefaultSelectedOptions(filteredSelectedOption);
    } else {
      setDefaultSelectedOptions(value ? [value] : []);
    }
  }, [value, isMulti]);

  const { defaultOptionLength } = useMemo(
    () => ({
      defaultOptionLength: defaultOptions.length,
    }),
    [defaultOptions]
  );

  const Option = useCallback(
    (props: OptionProps<any, false, any>) => {
      const { data, options } = props;
      const children = (
        <>
          <View isRowWrap align="center">
            {iconSearch && typeof iconSearch === 'string' ? (
              <Image src={iconSearch as string} className="searchable-select__icon" />
            ) : (
              iconSearch
            )}
            <View>
              <span>
                {data.label} {data.postfixLabel}
              </span>

              <span className="text-color-grey-600 text-is-12 sub-label">
                {
                  // eslint-disable-next-line security/detect-object-injection
                  data?.[subLabelKey]
                }
              </span>
            </View>
          </View>
        </>
      );
      if (data.isHide) return null;
      if (data.isShowDivider)
        return (
          <Fragment>
            <components.Option {...props} children={children} />
            <Divider
              style={{
                margin: 8,
              }}
            />
          </Fragment>
        );
      if (data.index === options.length - defaultOptionLength - 1) {
        return (
          <LazyCheckPoint onFirstEnter={fetchNextPage}>
            <components.Option {...props} children={children} />
          </LazyCheckPoint>
        );
      }
      return <components.Option {...props} children={children} />;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [defaultOptionLength, defaultSelectedOptions]
  );

  const selectOptions = useMemo(
    () => [
      ...toDefaultSelectedOptions(defaultSelectedOptions),
      ...toOptions(defaultOptions, defaultSelectedOptions, value, isMulti),
      ...toOptions(options, defaultSelectedOptions, value, isMulti),
    ],
    [defaultSelectedOptions, defaultOptions, options, isMulti, value]
  );

  const handleChange = (selectedOption) => {
    if (isMulti) {
      const filteredSelectedOption = getFilteredSelectedOption(selectedOption);
      onChange(
        name,
        isGetOptionOnChange
          ? selectedOption
          : selectedOption
          ? filteredSelectedOption.map((item) => item?.value)
          : null
      );
    } else {
      onChange(
        name,
        isGetOptionOnChange ? selectedOption : selectedOption?.value || null,
        selectedOption
      );
    }
  };

  const handleSelectBlur = () => {
    onBlur && onBlur(name, true);
  };
  const hasError = !isEmpty(errorMessage);

  const Menu = useCallback(
    (props) => {
      return (
        <components.Menu {...props}>
          {labelRecent && (
            <View className="ml-12 mt-16 mb-12 has-text-gray-600">{`Recent ${labelRecent}`}</View>
          )}
          {props.children}
          {newButtonLabel && (
            <Button
              className="px-16 cmp-button-option"
              icon={<AiOutlinePlus size={24} />}
              variant="text"
              onClick={() => handleClickNew && handleClickNew()}
            >
              <Text size={16}>{newButtonLabel}</Text>
            </Button>
          )}
        </components.Menu>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [defaultOptionLength]
  );

  useEffect(() => {
    if (
      !isEmpty(value) &&
      isString(value) &&
      !selectOptions.some((option) => option.value === value)
    ) {
      fetchNextPage();
    }
    if (
      isMulti &&
      !isEmpty(value) &&
      (Array.isArray(value) ? value : value.split(',')).some(
        (id: string) => !selectOptions.map(({ value }) => value).includes(id)
      )
    ) {
      fetchNextPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, value, fetchNextPage]);

  const ref = useRef(null);

  const windowHeight = window.innerHeight;
  const elementRect = ref?.current?.getBoundingClientRect();
  const elementTop = elementRect?.top;
  const elementToWindowBottom = windowHeight - elementTop;

  const autoMenuPlacement = useMemo(
    () => getMenuPlacement(elementToWindowBottom),
    [elementToWindowBottom]
  );

  const maxMenuHeight = useMemo(
    () =>
      autoMenuPlacement === MenuPlacement.Top ? elementTop - 200 : elementToWindowBottom - 200,
    [elementToWindowBottom, elementTop, autoMenuPlacement]
  );

  return (
    <Element
      forwardRef={ref}
      id={id.current}
      errorMessage={errorMessage}
      label={label}
      className={containerClassName}
      subLabel={subLabel}
      required={required}
      infoTooltipMessage={infoTooltipMessage}
      infoTooltipPlacement={infoTooltipPlacement}
      infoToolTipWithArrow={infoToolTipWithArrow}
    >
      <View onClick={(e) => e.stopPropagation()}>
        <Select
          id={id.current}
          styles={{
            singleValue: (provided) => ({
              ...provided,
              color: COLOR_CODE.BLACK_500,
            }),
          }}
          maxMenuHeight={maxMenuHeight < MAX_MENU_HEIGHT ? maxMenuHeight : MAX_MENU_HEIGHT}
          isMulti={isMulti}
          isSearchable={isSearchable}
          isDisabled={isDisabled}
          isLoading={isLoading}
          value={getSelectedOption(selectOptions, value, isMulti, defaultOptionLength)}
          placeholder={placeholder ? placeholder : defaultPlaceholder}
          onChange={handleChange}
          onInputChange={onInputChange}
          options={selectOptions}
          className={cn('cmp-select', className, {
            'cmp-select--error': hasError,
            'cmp-select--is-disabled': isDisabled,
          })}
          classNamePrefix="cmp-select"
          menuPlacement={autoMenuPlacement}
          menuPosition={menuPosition}
          onBlur={handleSelectBlur}
          closeMenuOnSelect={!isMulti}
          name={name}
          theme={(theme) => ({
            ...theme,
            colors: {
              ...theme.colors,
              primary: COLOR_CODE.PRIMARY,
              neutral20: hasError ? COLOR_CODE.DANGER : COLOR_CODE.DISABLED,
            },
          })}
          filterOption={(option, inputValue) => {
            const { label, value } = option;
            const otherKey = options.filter(
              (option) => option.label === label && option.value.includes(inputValue)
            );
            return value?.toLowerCase().includes(inputValue) || otherKey.length > 0;
          }}
          // @ts-ignore
          labelControl={labelControl}
          defaultInputValue={defaultInputValue}
          components={{
            Control: labelControl
              ? ControlWithLabel
              : hideSearchIcon
              ? ControlNoSearchIcon
              : Control,
            DropdownIndicator: isDisabled ? () => null : DropdownIndicator,
            IndicatorSeparator: isDisabled ? () => null : IndicatorSeparator,
            Menu,
            Option,
          }}
          menuPortalTarget={document.querySelector('#root')}
          isClearable={!required}
          {...props}
        />
      </View>
    </Element>
  );
};

export default SearchableSelect;
