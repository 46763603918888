import { useMutation, UseMutationOptions } from 'react-query';
import { WC14 } from 'src/queries/WC14s/types';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';

export function useEditWC14(options?: UseMutationOptions<WC14, Error, WC14>) {
  const {
    mutate: onEditWC14,
    isLoading: isUpdating,
    isSuccess,
    isError,
    error,
  } = useMutation<WC14, Error, WC14>({
    mutationFn: (payload: WC14) => responseWrapper(apiClient.updateWC14, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    onEditWC14,
    isUpdating,
    isSuccess,
    isError,
    error,
  };
}
