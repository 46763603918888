import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { ApiResponseType, PaginationResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { GetPropertiesParams } from '../types';
import { toHC15sReport } from './helpers';
import { HC15sReport } from './types';

export function useGetHC15sReport(
  options?: UseQueryOptions<
    ApiResponseType<{
      reports: PaginationResponseType<HC15sReport>;
      totalNewlyEnrolledCA: number;
      totalCanceledCA: number;
      totalIntentToCanceledCA: number;
    }>,
    Error
  >
) {
  const [params, setParams] = useState<GetPropertiesParams>({});

  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetHC15sReport,
  } = useQuery<
    ApiResponseType<{
      reports: PaginationResponseType<HC15sReport>;
      totalNewlyEnrolledCA: number;
      totalCanceledCA: number;
      totalIntentToCanceledCA: number;
    }>,
    Error
  >([API_QUERIES.REPORT_HC_15S, { ...params, ...options }], {
    queryFn: (query) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...params] = query.queryKey;
      return responseWrapper<
        ApiResponseType<{
          reports: PaginationResponseType<HC15sReport>;
          totalNewlyEnrolledCA: number;
          totalCanceledCA: number;
          totalIntentToCanceledCA: number;
        }>
      >(apiClient.getHC15sReport, params);
    },
    onError: ErrorService.handler,
    notifyOnChangeProps: ['data', 'isFetching'],
    select: (data) => toHC15sReport(data),
    keepPreviousData: true,
    ...options,
  });

  const queryClient = useQueryClient();

  const handleInvalidateHC15sReport = () =>
    queryClient.invalidateQueries(API_QUERIES.REPORT_HC_15S);

  const { data: hc15sReport, hasNext, payloadSize, totalRecords } = data?.data?.reports || {};

  return {
    hc15sReport,
    totalNewlyEnrolledCA: data?.data?.totalNewlyEnrolledCA || 0,
    totalCanceledCA: data?.data?.totalCanceledCA || 0,
    totalIntentToCanceledCA: data?.data?.totalIntentToCanceledCA || 0,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isError,
    isFetching,
    onGetHC15sReport,
    setParams,
    handleInvalidateHC15sReport,
  };
}
