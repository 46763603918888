import cn from 'classnames';
import React, { useEffect, useState } from 'react';
import { BsFillLightningChargeFill } from 'react-icons/bs';
import Button from '../Button';
import './styles.scss';
import { emptyFunction } from 'src/utils';
import { View, Text } from '..';

const StatusBar: React.FC<Props> = ({
  current,
  onChange,
  selecting,
  isLoading,
  statusItems,
  defaultStatus,
  containerClassName,
}) => {
  const [currentStatus, setCurrentStatus] = useState(current || defaultStatus);
  const [selectingStatus, setSelectingStatus] = useState(current || selecting);
  const isLastItem = currentStatus === statusItems[statusItems.length - 1].value;
  const disableButton =
    (isLastItem && selectingStatus === statusItems[statusItems.length - 1].value) ||
    (isLastItem && !selectingStatus);

  const handleSelectStatus = (value) => {
    if (selectingStatus !== value) {
      setSelectingStatus(value);
    }
  };

  useEffect(() => {
    setCurrentStatus(current || defaultStatus);
    setSelectingStatus(current || defaultStatus);
  }, [current, defaultStatus]);

  const handleChangeStatus = () => {
    if (selectingStatus !== currentStatus) {
      onChange && onChange(selectingStatus);
    } else if (!isLastItem) {
      const currentIndex = statusItems.findIndex((item) => item.value === currentStatus);
      const nextStatus = statusItems[currentIndex + 1].value;

      onChange && onChange(nextStatus);
    }
  };

  const getButtonTitle = () => {
    return selectingStatus !== currentStatus && selectingStatus
      ? 'Mark as Current Status'
      : 'Mark Status as Complete';
  };

  return (
    <View className={cn('section-container cmp-status-dcd-bar', containerClassName)} isRow>
      <View isRow className="cmp-status-dcd-bar__item-container">
        {statusItems.map(({ value, label, isDisabled }) => (
          <View
            className={cn(
              'cmp-status-dcd-bar__items',
              {
                'cmp-status-dcd-bar__items--selecting':
                  currentStatus !== value && selectingStatus === value,
              },
              { 'cmp-status-dcd-bar__items--current': currentStatus === value }
            )}
            key={value}
            title={label}
            onClick={() => (isDisabled ? emptyFunction() : handleSelectStatus(value))}
          >
            <Text className="text-center" size={14}>
              {label}
            </Text>
          </View>
        ))}
      </View>
      <Button
        onClick={handleChangeStatus}
        disabled={disableButton}
        isLoading={isLoading}
        icon={<BsFillLightningChargeFill />}
      >
        {getButtonTitle()}
      </Button>
    </View>
  );
};

type Props = {
  current?: string;
  selecting?: string;
  onChange?: Function;
  isLoading?: boolean;
  statusItems: Array<{ label: string; value: string; isDisabled?: boolean }>;
  defaultStatus: string;
  containerClassName?: string;
};

export default StatusBar;
