import { TDISFCaseKey } from './keys';

export enum TDISFCaseStatus {
  NewCase = 'NEW CASE',
  Filed = 'FILED',
  UnderReview = 'UNDER REVIEW',
  Established = 'ESTABLISHED',
  HearingsReviewed = 'HEARINGS REVIEW',
  JudicialReview = 'JUDICIAL REVIEW',
  Ended = 'ENDED',
}

export enum DenialReason {
  NotReceiving = 'NOT RECEIVING UI',
  Denied = 'DENIED UI',
  Received = 'RECEIVED',
  BenefitYearExpired = 'BENEFIT YEAR EXPIRED',
  Eligible = 'ELIGIBLE',
  Other = 'OTHER',
}

export enum ClaimType {
  IneligibleUnemployment = 'INELIGIBLE UNEMPLOYMENT',
  BankruptEmployer = 'BANKRUPT EMPLOYER',
  NonCompliant = 'NON COMPLIANT',
}

export enum TDIClaimStatus {
  Paid = 'PAID',
  Denied = 'DENIED',
  Pending = 'PENDING',
}

export type TDISFCaseStatusPayload = {
  [TDISFCaseKey.Id]?: string;
  [TDISFCaseKey.Status]?: string;
};

export enum ReceiveBenefitFrom {
  FederalDisabilityInsuranceBenefits = 'FEDERAL',
  WorkmenCompensationBenefits = 'WORKMEN COMPENSATION',
  WagesSalaryOrSeparationPay = 'WAGES SALARY',
  DamagesForPersonalInjury = 'PERSONAL INJURY DAMAGES',
}

export interface TDISFCase {
  [TDISFCaseKey.Id]?: string;
  [TDISFCaseKey.CaseNumber]?: string;

  // Case Information
  [TDISFCaseKey.ClaimantId]?: string;
  [TDISFCaseKey.ClaimantName]?: string;
  [TDISFCaseKey.EmployerId]?: string;
  [TDISFCaseKey.ClaimantDateOfBirth]?: string;
  [TDISFCaseKey.ClaimantPhone]?: string;
  [TDISFCaseKey.Status]?: TDISFCaseStatus;
  [TDISFCaseKey.EmployerName]?: string;
  [TDISFCaseKey.SSN]?: string;
  [TDISFCaseKey.StatusLastChangeDate]?: string;
  [TDISFCaseKey.DisabilityDate]?: string;
  [TDISFCaseKey.ReceivedDate]?: string;
  [TDISFCaseKey.DenialReason]?: DenialReason;
  [TDISFCaseKey.DenialOtherReason]?: string;

  // Special Fund Information
  [TDISFCaseKey.BenefitYearStartDate]?: string;
  [TDISFCaseKey.BenefitYearEndDate]?: string;
  [TDISFCaseKey.WeeklyBenefitAmount]?: string | number;
  [TDISFCaseKey.SpecialFundMaximumPayment]?: string | number;
  [TDISFCaseKey.MaximumDuration]?: string;
  [TDISFCaseKey.MaxDurationAmount]?: string;
  [TDISFCaseKey.MaxDurationWeeks]?: string | number;
  [TDISFCaseKey.TypeOfClaim]?: ClaimType;
  [TDISFCaseKey.TotalPayment]?: string | number;
  [TDISFCaseKey.RunningTotalOfBenefitPaid]?: string;

  // Claimant Information
  [TDISFCaseKey.ClaimantName]?: string;
  [TDISFCaseKey.ClaimantSSN]?: string;
  [TDISFCaseKey.ClaimantDateOfDeath]?: string;
  [TDISFCaseKey.ClaimantDOB]?: string;
  [TDISFCaseKey.ClaimantAddress]?: string;
  [TDISFCaseKey.ClaimantState]?: string;
  [TDISFCaseKey.ClaimantZip]?: string;
  [TDISFCaseKey.ClaimantCity]?: string;
  [TDISFCaseKey.ClaimantStreet]?: string;
  [TDISFCaseKey.ClaimantPhoneNumber]?: string;
  [TDISFCaseKey.ClaimantEmail]?: string;
  [TDISFCaseKey.ClaimantSex]?: string;
  [TDISFCaseKey.ClaimantMaritalStatus]?: string;
  [TDISFCaseKey.ClaimantRepresentative]?: string;
  [TDISFCaseKey.RelationShipToClaimant]?: string;

  // Disability Information
  [TDISFCaseKey.DisabilityDescription]?: string;
  [TDISFCaseKey.FirstDateDisabled]?: string;
  [TDISFCaseKey.IsRecoveredFromDisability]?: boolean;
  [TDISFCaseKey.DateRecovered]?: string;

  // Employer Information
  [TDISFCaseKey.EmployerAddress]?: string;
  [TDISFCaseKey.EmployerStatus]?: string;
  [TDISFCaseKey.EmployerStatusDate]?: string;
  [TDISFCaseKey.EmployerPosition]?: string;
  [TDISFCaseKey.EmployerStartDate]?: string;
  [TDISFCaseKey.EmployerEndDate]?: string;
  [TDISFCaseKey.EmployerOccupation]?: string;

  // Unemployment Insurance (UI) Claim Information
  [TDISFCaseKey.IsFiledForUI]?: boolean;
  [TDISFCaseKey.DateLastReportedToUI]?: string;
  [TDISFCaseKey.UIWeeklyBenefitAmount]?: string;
  [TDISFCaseKey.NoClaimReason]?: string;

  // Other Benefit
  [TDISFCaseKey.ReceiveBenefitFrom]?: ReceiveBenefitFrom[];
  [TDISFCaseKey.IsFiledTDI]?: boolean;
  [TDISFCaseKey.TDIClaimStatus]?: string;

  // Medical Certification
  [TDISFCaseKey.PhysicianId]?: string;
  [TDISFCaseKey.PhysicianAddress]?: string;
  [TDISFCaseKey.PhysicianPhoneNumber]?: string;
  [TDISFCaseKey.Diagnosis]?: string;
  [TDISFCaseKey.DisabilityDueToNormalPregnancy]?: boolean;
  [TDISFCaseKey.DueToComplicationsOfPregnancy]?: boolean;
  [TDISFCaseKey.DisabilityCausedByEmployment]?: boolean;
  [TDISFCaseKey.IsWC2Filed]?: boolean;
  [TDISFCaseKey.WC2FiledWith]?: string;
  [TDISFCaseKey.ClaimantHospitalizedStartDate]?: string;
  [TDISFCaseKey.ClaimantHospitalizedEndDate]?: string;
  [TDISFCaseKey.IsSurgeryIndicated]?: boolean;
  [TDISFCaseKey.SurgeryType]?: string;
  [TDISFCaseKey.DateOfFirstTreatment]?: string;
  [TDISFCaseKey.FirstDateClaimantUnableToWork]?: string;
  [TDISFCaseKey.MostRecentTreatmentDate]?: string;
  [TDISFCaseKey.DischargedDate]?: string;
  [TDISFCaseKey.Remarks]?: string;

  [TDISFCaseKey.CreatedAt]?: string;
  [TDISFCaseKey.CreatedBy]?: string;
  [TDISFCaseKey.UpdatedBy]?: string;
  [TDISFCaseKey.UpdatedAt]?: string;
}
