import { AuditKey } from '../keys';
import { IdentificationType } from '../WC14s';
import { WC77Key } from './keys';

export enum WC77IssueStatement {
  ReviewOfEmployerDenialOfHealthcare = "REVIEW OF EMPLOYER''S DENIAL OF HEALTHCARE",
  Compensability = 'COMPENSABILITY',
  TerminationOfTemporaryTotalDisability = 'TERMINATION OF TEMPORARY TOTAL DISABILITY',
  TerminationOfTemporaryPartialDisability = 'TERMINATION OF TEMPORARY PARTIAL DISABILITY',
  PermanentDisability = 'PERMANENT DISABILITY',
  Disfigurement = 'DISFIGUREMENT',
  DependentDeathBenefits = 'DEPENDENT DEATH BENEFITS',
  ConcurrentEmployment = 'CONCURRENT EMPLOYMENT',
  Reopening = 'REOPENING',
  OtherIssues = 'OTHER ISSUES',
}

export enum ApplicantRepresenting {
  Employer = 'EMPLOYER',
  Employee = 'EMPLOYEE',
  Other = 'OTHER',
}

export enum HearingDeniedReasons {
  LackMedicalEvidence = 'Lack of medical evidence',
  IssuesIsNotWithinDepartmentJurisdiction = 'Issues is not within the Department’s jurisdiction',
  Other = 'Other',
}

export interface WC77Witness {
  [WC77Key.WitnessName]: string;
  [WC77Key.WitnessWorkPhone]: string;
  [WC77Key.WitnessHomePhone]: string;
  [WC77Key.WitnessAddress]: string;
  [WC77Key.WitnessCity]: string;
  [WC77Key.WitnessZipCode]: string;
}

export interface WC77 {
  id?: string;
  [WC77Key.ExternalWCFormId]: string;
  [WC77Key.RawDateReceived]: string;
  [WC77Key.RawSignature]: string;
  [WC77Key.RawSignatureDate]: string;
  [WC77Key.WCCaseId]: string;
  [WC77Key.DCDCaseNumber]: string;
  [WC77Key.HearingNumber]: string;
  [WC77Key.HearingId]: string;

  [WC77Key.ApplicantName]: string;
  [WC77Key.ApplicantAddress]: string;
  [WC77Key.ApplicantCity]: string;
  [WC77Key.ApplicantState]: string;
  [WC77Key.ApplicantZipCode]: string;
  [WC77Key.ApplicantPhone]: string;
  [WC77Key.ApplicantRepresenting]: ApplicantRepresenting;
  [WC77Key.ApplicantRepOther]: string;

  [WC77Key.ClaimantId]: string;
  [WC77Key.ClaimantName]: string;

  [WC77Key.ClaimantFirstName]: string;
  [WC77Key.ClaimantLastName]: string;
  [WC77Key.ClaimantMiddleInitial]: string;
  [WC77Key.ClaimantSuffix]: string;
  [WC77Key.ClaimantIdType]: IdentificationType;
  [WC77Key.ClaimantIdNumber]: string;
  [WC77Key.ClaimantAddress]: string;
  [WC77Key.ClaimantCity]: string;
  [WC77Key.ClaimantState]: string;
  [WC77Key.ClaimantZipCode]: string;

  [WC77Key.EmployerName]: string;
  [WC77Key.EmployerId]: string;

  [WC77Key.EmployerCarrierName]: string;
  [WC77Key.EmployerCarrierAddress]: string;
  [WC77Key.EmployerCarrierCity]: string;
  [WC77Key.EmployerCarrierState]: string;
  [WC77Key.EmployerCarrierZipCode]: string;
  [WC77Key.DateOfInjury]: string;

  [WC77Key.InsuranceCarrierId]: string;
  [WC77Key.InsuranceCarrierName]: string;

  [WC77Key.HearingApplicant]: string;
  [WC77Key.HearingDisputeSummary]: string;
  [WC77Key.HearingIssuesStatement]: string;
  [WC77Key.HearingIssueStatement]: string;
  [WC77Key.HearingOtherIssuesToBeResolved]: string;

  [WC77Key.Witnesses]: WC77Witness[];

  [WC77Key.Witness1Name]: string;
  [WC77Key.Witness1WorkPhone]: string;
  [WC77Key.Witness1HomePhone]: string;
  [WC77Key.Witness1Address]: string;
  [WC77Key.Witness1City]: string;
  [WC77Key.Witness1State]: string;
  [WC77Key.Witness1ZipCode]: string;

  [WC77Key.Witness2Name]: string;
  [WC77Key.Witness2WorkPhone]: string;
  [WC77Key.Witness2HomePhone]: string;
  [WC77Key.Witness2Address]: string;
  [WC77Key.Witness2City]: string;
  [WC77Key.Witness2State]: string;
  [WC77Key.Witness2ZipCode]: string;

  [WC77Key.Witness3Name]: string;
  [WC77Key.Witness3WorkPhone]: string;
  [WC77Key.Witness3HomePhone]: string;
  [WC77Key.Witness3Address]: string;
  [WC77Key.Witness3City]: string;
  [WC77Key.Witness3State]: string;
  [WC77Key.Witness3ZipCode]: string;

  [WC77Key.HearingSpecialConditions]: string;
  [WC77Key.HearingDeniedReasons]: string;
  [WC77Key.HearingsReviewSection]: string;
  [WC77Key.OfficeUseOther]: string;
  [WC77Key.OfficeUseDate]: string;
  [WC77Key.OfficeUseApprovedBy]: string;
  [WC77Key.OfficeUseApprovedDate]: string;

  [WC77Key.IsReviewEmployersDenialOfHealthCare]: boolean;
  [WC77Key.IsCompensability]: boolean;
  [WC77Key.IsTerminationOfTTD]: boolean;
  [WC77Key.IsTerminationOfTPD]: boolean;
  [WC77Key.IsPermanentDisability]: boolean;
  [WC77Key.IsDisfigurement]: boolean;
  [WC77Key.IsDependentOfDeathBenefits]: boolean;
  [WC77Key.IsConcurrentEmployment]: boolean;
  [WC77Key.IsReopening]: boolean;
  [WC77Key.HasOtherIssues]: boolean;

  [AuditKey.CreatedBy]: string;
  [AuditKey.UpdatedBy]: string;
  [AuditKey.UpdatedAt]: string;
  [AuditKey.CreatedAt]: string;
}
