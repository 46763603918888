import cn from 'classnames';
import { useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Grid, Text, View } from 'src/modules/shared-main/components';
import { ViewsOption } from 'src/modules/shared-main/queries';
import { ViewKey } from '../helpers';
import { columns, FieldType } from './helpers';
import './styles.scss';

const FilterDragDrop: React.FC<Props> = ({ errorMessage, fields }) => {
  const [items, setItems] = useState<any>(fields);
  const { setFieldValue } = useFormikContext();

  const reorder = (list, startIndex, endIndex, droppableId) => {
    let newList = list.filter(({ category }) => category === droppableId);
    newList.splice(endIndex, 0, newList.splice(startIndex, 1)[0]);

    return [...list.filter(({ category }) => category !== droppableId), ...newList];
  };

  const transferItem = (list, startIndex, endIndex, droppableId, draggableId) => {
    let newList = list.filter(({ category }) => category === droppableId);
    let others = list.filter(({ category }) => category === draggableId);
    newList.splice(endIndex, 0, { ...others.splice(startIndex, 1)[0], category: droppableId });

    return [...others, ...newList];
  };

  useEffect(() => {
    setFieldValue(
      ViewKey.Fields,
      items.filter(({ category }) => category === FieldType.Selected).map(({ name }) => name)
    );
  }, [items, setFieldValue]);

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    } else if (destination.droppableId !== source.droppableId) {
      setItems((items) =>
        transferItem(
          items,
          source.index,
          destination.index,
          destination.droppableId,
          source.droppableId
        )
      );
    } else {
      setItems(reorder(items, source.index, destination.index, source.droppableId));
    }
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Grid.Wrap>
        {columns.map(({ name, id }, index) => (
          <Grid.Item key={index}>
            <View
              className={cn('cmp-list-field', {
                'cmp-list-field--error': errorMessage && id === FieldType.Selected,
              })}
            >
              <Text className="fw-bold px-12 pt-12 pb-8" size={14}>
                {name}
              </Text>
              <Droppable droppableId={id}>
                {(provided) => {
                  return (
                    <div ref={provided.innerRef}>
                      <ul className="cmp-list-field__container">
                        {items
                          .filter((item) => item.category === id)
                          .map(({ name, label }, index) => (
                            <Draggable draggableId={name} key={name} index={index}>
                              {(provided) => {
                                return (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    className="cmp-list-field__item"
                                  >
                                    <li className={cn('cmp-list-field__item')}>{label}</li>
                                  </div>
                                );
                              }}
                            </Draggable>
                          ))}
                      </ul>
                    </div>
                  );
                }}
              </Droppable>
            </View>
            {id === FieldType.Selected && errorMessage && (
              <Text className="has-text-danger">{errorMessage}</Text>
            )}
          </Grid.Item>
        ))}
      </Grid.Wrap>
    </DragDropContext>
  );
};

type Props = {
  errorMessage?: string;
  fields: ViewsOption[];
};

export default FilterDragDrop;
