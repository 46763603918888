import { PaginationResponseType, toRelatedAuditData } from '../helpers';
import { PartyNameAndAddressKey, PartyNameAndAddressType } from './types';

export const toRelatedPartyNamesAddressData = (
  data
): PaginationResponseType<PartyNameAndAddressType> => ({
  ...data,
  data: data.data.map((item) => ({
    ...item,
    ...toRelatedAuditData(item),
    [PartyNameAndAddressKey.AccountName]: item?.account?.accountName,
    [PartyNameAndAddressKey.ContactName]: item?.contact?.contactName,
  })),
});
