export enum AwardSummaryKey {
  AwardSummaryNumber = 'awardSummaryNumber',
  Payer = 'payer',
  CaseId = 'wcCaseId',
  IsInUse = 'isInUse',
  CaseNumber = 'caseNumber',
  ClaimantName = 'claimantName',
  ClaimantId = 'claimantId',
  DateOfInjury = 'dateOfInjury',
  ClaimantSSN = 'claimantSSN',
  AwardPaymentSummary = 'awardPaymentSummary',
}

export enum AwardPaymentSummaryKey {
  PaymentType = 'paymentType',
  PeriodEndDate = 'periodEndDate',
  PaymentTypeName = 'paymentTypeName',
  PeriodStartDate = 'periodStartDate',
  TotalWeeksDaysPMT = 'totalWeeksDaysPMT',
  TotalWeeksDaysAward = 'totalWeeksDaysAward',
  TotalBenefitAmountPMT = 'totalBenefitAmountPMT',
  TotalBenefitAmountAward = 'totalBenefitAmountAward',
  TotalWeeksDaysDifference = 'totalWeeksDaysDifference',
  TotalBenefitAmountDifference = 'totalBenefitAmountDifference',
}

export enum AwardSummaryPaymentType {
  TTD = 'TTD',
  TPD = 'TPD',
  PPD = 'PPD',
  PTD = 'PTD',
  Death = 'Death',
  Disfigurement = 'Disfigurement',
  Medical = 'Medical',
  Other = 'Other',
  ServicesOfAttendant = 'ServicesOfAttendant',
  RehabilitationBenefitAdjustment = 'RehabilitationBenefitAdjustment',
  CityCounty = 'CityCounty',
  MaterialsBenefitReimbursement = 'MaterialsBenefitReimbursement',
}

export interface AwardSummaries {
  id: string;
  [AwardSummaryKey.AwardSummaryNumber]: string;
  [AwardSummaryKey.Payer]: string;
}

export interface CaseAwardPayer {
  [AwardSummaryKey.Payer]: string;
  [AwardSummaryKey.IsInUse]: boolean;
}

export interface AwardSummaryPayload {
  [AwardSummaryKey.Payer]: string;
  [AwardSummaryKey.CaseId]: string;
}

export interface AwardSummary {
  id: string;
  [AwardSummaryKey.AwardSummaryNumber]: string;
  [AwardSummaryKey.Payer]: string;
  [AwardSummaryKey.ClaimantName]: string;
  [AwardSummaryKey.ClaimantId]: string;
  [AwardSummaryKey.CaseNumber]: string;
  [AwardSummaryKey.CaseId]: string;
  [AwardSummaryKey.ClaimantSSN]: string;
  [AwardSummaryKey.DateOfInjury]: string;
  [AwardSummaryKey.AwardPaymentSummary]: AwardPaymentSummary[];
}

export type AwardPaymentSummary = {
  [AwardPaymentSummaryKey.PaymentType]: AwardSummaryPaymentType;
  [AwardPaymentSummaryKey.PeriodEndDate]: string;
  [AwardPaymentSummaryKey.PaymentTypeName]: string;
  [AwardPaymentSummaryKey.PeriodStartDate]: string;
  [AwardPaymentSummaryKey.TotalWeeksDaysPMT]: string;
  [AwardPaymentSummaryKey.TotalWeeksDaysAward]: string;
  [AwardPaymentSummaryKey.TotalBenefitAmountPMT]: string;
  [AwardPaymentSummaryKey.TotalBenefitAmountAward]: string;
  [AwardPaymentSummaryKey.TotalBenefitAmountDifference]: string;
};
