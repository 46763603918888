import { User } from '../Users';
import { HRSHARKey } from './keys';

export type HRSHAR = {
  [HRSHARKey.Id]: string;
  [HRSHARKey.IsSelected]?: boolean;
  [HRSHARKey.CreatedAt]?: string;
  [HRSHARKey.UpdatedAt]?: string;
  [HRSHARKey.CreatedUser]?: User;
  [HRSHARKey.UpdatedUser]?: User;
  [HRSHARKey.HrsHarSection]?: string;
  [HRSHARKey.Summary]?: string;
  [HRSHARKey.HrsHarCovers]?: string;
  [HRSHARKey.IsTDIInvolved]?: boolean;
  [HRSHARKey.IsTDISFInvolvedUI]?: boolean;
  [HRSHARKey.IsTDISFInvolvedNonCompOrBankruptER]?: boolean;
  [HRSHARKey.RequiredDocument]?: string;
  [HRSHARKey.HearingPotentiallyAddress]?: string;
  [HRSHARKey.IsDefault]?: boolean;
};

export enum DisputedInvolvesTDI {
  TDI = 'TDI',
  TDIUi = 'TDI SF (UI)',
  TDINonCompBankruptEr = 'TDI SF (Non Comp or bankrupt ER)',
}

export type HrsHarForm = {
  [HRSHARKey.HrsHars]?: Array<HRSHAR>;
};

export type HrsHarPayload = {
  [HRSHARKey.HearingId]: string;
  [HRSHARKey.SelectedIds]: Array<string>;
  [HRSHARKey.ShowingIds]: Array<string>;
  [HRSHARKey.CustomRecords]: Array<HRSHAR>;
};
