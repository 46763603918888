import { getFirstLastName } from 'src/utils';
import { PaginationResponseType, toAuditData } from '../helpers';
import { RelatedOrderKey, RelatedOrderType } from './types';

export const toRelatedOrder = (
  data: PaginationResponseType<RelatedOrderType>
): PaginationResponseType<RelatedOrderType> => {
  return {
    ...data,
    data: data?.data.map((relatedOrder) => {
      return {
        ...relatedOrder,
        [RelatedOrderKey.CreatedBy]: getFirstLastName(relatedOrder?.createdUser),
        [RelatedOrderKey.LastModifiedBy]: getFirstLastName(relatedOrder?.updatedUser),
        [RelatedOrderKey.HearingOfficer]: getFirstLastName(relatedOrder?.hearingOfficer),
      };
    }),
  };
};

export const toOrderDetail = (data) => ({
  ...data,
  [RelatedOrderKey.HearingOfficer]: getFirstLastName(data?.hearingOfficer),
  [RelatedOrderKey.CaseNumber]: data?.case?.caseNumber,
  [RelatedOrderKey.DateOfII]: data?.case?.dateOfInjury,
  [RelatedOrderKey.Claimant]: data?.case?.claimant,
  ...toAuditData(data),
});
