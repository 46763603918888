import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { Hearing } from './types';

export function useUpdateHearing(options?: UseMutationOptions<any, Error, Hearing>) {
  const {
    mutate: onEditHearing,
    isLoading: isUpdating,
    isSuccess,
    isError,
    error,
  } = useMutation<any, Error, Hearing>({
    mutationFn: (payload: Hearing) => responseWrapper(apiClient.updateHearing, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    onEditHearing,
    isUpdating,
    isSuccess,
    isError,
    error,
  };
}
