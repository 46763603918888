import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { PaginationResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { GetPropertiesParams } from '../types';
import { toRelatedAwardEmployments } from './helpers';
import { AwardEmployment } from './types';

export function useGetAllRelatedAwardEmployments(
  options?: UseQueryOptions<PaginationResponseType<AwardEmployment>, Error> & {
    awardWorksheetId: string;
  }
) {
  const [params, setParams] = useState<GetPropertiesParams>({});
  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetAllRelatedAwardEmployment,
  } = useQuery<PaginationResponseType<AwardEmployment>, Error>(
    [API_QUERIES.RELATED_AWARD_EMPLOYMENTS, { ...params, id: options.awardWorksheetId }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<PaginationResponseType<AwardEmployment>>(
          apiClient.getAllRelatedAwardEmployments,
          params
        );
      },
      notifyOnChangeProps: ['data', 'isFetching'],
      keepPreviousData: true,
      enabled: !!options.awardWorksheetId,
      select: toRelatedAwardEmployments,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateAllRelatedAwardEmployments = () =>
    queryClient.invalidateQueries(API_QUERIES.RELATED_AWARD_EMPLOYMENTS);

  const { data: relatedAwardEmployments, hasNext, payloadSize, totalRecords } = data || {};

  return {
    relatedAwardEmployments,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isError,
    isFetching,
    onGetAllRelatedAwardEmployment,
    setParams,
    handleInvalidateAllRelatedAwardEmployments,
  };
}
