import { getFirstLastName } from 'src/modules/shared-main/common';
import { PaginationResponseType, toAuditData } from '../helpers';
import { WC21Key } from './keys';
import { WC21 } from './types';

export const getWC21s = (data): PaginationResponseType<WC21> => ({
  ...data,
  data: data.data.map((item) => ({
    ...item,
    [WC21Key.EmployerName]: item?.employer?.accountName,
    [WC21Key.AssignedUserName]: getFirstLastName(item?.assignedUser),
    ...toAuditData(item),
  })),
});

export const toWC21Detail = (data) => ({
  ...data,
  [WC21Key.AssignedUserName]: getFirstLastName(data?.assignedUser),
  [WC21Key.EmployerName]: data?.employer?.accountName,
  ...toAuditData(data),
});
