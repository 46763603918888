import React from 'react';
import { connect } from 'react-redux';
import CurrencyFormat from 'react-currency-format';
import { IRootState } from 'src/redux/rootReducer';
import { InputProps } from '../Input';
import { Input } from '..';
import { Callback } from 'src/redux/types';

const customInput = ({ ...props }) => <Input {...props} />;

const DEFAULT_MAX_LENGTH = 16;

const InputNumber: React.FC<Props> = ({
  name,
  value,
  onChange,
  onBlur,
  maxLength = DEFAULT_MAX_LENGTH,
  isThousandsSeparator = false,
  ...props
}) => {
  const handleChange = (values) => {
    const { floatValue, formattedValue } = values;

    onChange(name, floatValue || formattedValue);
  };

  const onTouched = () => {
    onBlur(name, true);
  };

  const checkIsExceedMaxLength = (values) => {
    const { floatValue } = values;

    return !maxLength || floatValue?.toString().length <= maxLength;
  };

  return (
    <CurrencyFormat
      customInput={customInput}
      thousandSeparator={isThousandsSeparator}
      fixedDecimalScale={true}
      decimalScale={0}
      displayType="input"
      onValueChange={handleChange}
      isAllowed={checkIsExceedMaxLength}
      {...props}
      name={name}
      value={value?.toString()}
      onBlur={onTouched}
    />
  );
};
type Props = Omit<CurrencyFormat.Props, 'InputProps'> & { InputProps: InputProps } & {
  isThousandsSeparator?: boolean;
  value: string | number;
  name: string;
  onChange: Callback;
  onBlur?: Callback;
  maxLength?: number;
};

const mapStateToProps = (state: IRootState) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(InputNumber);
