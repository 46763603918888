export enum TDI46ReconsiderationAppealsReportKey {
  StartDate = 'startDate',
  EndDate = 'endDate',
  DateReceived = 'dateReceived',
  TDI46Reconsideration = 'tdi46Reconsideration',
  TDI46Appeals = 'tdi46Appeals',
  TotalReconsideration = 'totalReconsideration',
  TotalAppeals = 'totalAppeals',
  TDI46ReconsiderationAppealType = 'tdi46ReconsiderationAppealType',
}

export enum TDI46ReconsiderationAppealsType {
  Reconsideration = 'RECONSIDERATION',
  Appeal = 'APPEAL',
}
