import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { Account } from '../Accounts';
import apiClient from '../apiClient';
import { PaginationResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { GetPropertiesParams } from '../types';
import { DOLTypeEmployerRegistrationActivities, EmployerRegistrationActivitiesKey } from './keys';

export function useGetEmployerRegistrationActivitiesDetail(
  options?: UseQueryOptions<PaginationResponseType<Account>, Error> & {
    [EmployerRegistrationActivitiesKey.StartDate]: string;
    [EmployerRegistrationActivitiesKey.EndDate]: string;
    [EmployerRegistrationActivitiesKey.DOLType]: DOLTypeEmployerRegistrationActivities;
  }
) {
  const [params, setParams] = useState<GetPropertiesParams>({});

  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetEmployerRegistrationActivitiesDetail,
  } = useQuery<PaginationResponseType<Account>, Error>(
    [API_QUERIES.REPORT_EMPLOYER_REGISTRATION_ACTIVITIES, { ...params, ...options }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<PaginationResponseType<Account>>(
          apiClient.getEmployerRegistrationActivitiesDetail,
          params
        );
      },
      notifyOnChangeProps: ['data', 'isFetching'],
      keepPreviousData: true,
      enabled: !!options?.startDate && !!options?.endDate && !!options?.dolType,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateEmployerRegistrationActivitiesDetail = () =>
    queryClient.invalidateQueries(API_QUERIES.REPORT_EMPLOYER_REGISTRATION_ACTIVITIES);

  const {
    data: employerRegistrationActivitiesDetail,
    hasNext,
    payloadSize,
    totalRecords,
  } = data || {};

  return {
    employerRegistrationActivitiesDetail,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isError,
    isFetching,
    onGetEmployerRegistrationActivitiesDetail,
    setParams,
    handleInvalidateEmployerRegistrationActivitiesDetail,
  };
}
