import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { AwardWorksheetCalculator } from './types';

export function useCalculateAwardWorksheet(
  options?: UseMutationOptions<AwardWorksheetCalculator, Error, AwardWorksheetCalculator>
) {
  const {
    mutate: onCalculateAwardWorksheet,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useMutation<AwardWorksheetCalculator, Error, AwardWorksheetCalculator>({
    mutationFn: (payload: AwardWorksheetCalculator) =>
      responseWrapper(apiClient.calculateAwardWorksheet, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    onCalculateAwardWorksheet,
    isLoading,
    isSuccess,
    isError,
    error,
  };
}
