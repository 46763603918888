import { AccountDetail } from '../Accounts';
import { UserDetail } from '../Users';
import { HC15Key } from './keys';

export type HC15 = {
  [HC15Key.Id]?: string;
  [HC15Key.HC15Number]?: string;
  [HC15Key.DateReceived]?: string;

  // Employer
  [HC15Key.EmployerId]?: string;
  [HC15Key.EmployerName]?: string;
  [HC15Key.DOLNumber]?: string;
  [HC15Key.RawEmployerName]?: string;
  [HC15Key.EmployerDolNumber]?: string;
  [HC15Key.Employer]?: UserDetail;

  // Health Care Plan
  [HC15Key.HealthCarePlanName]?: string;
  [HC15Key.HealthCarePlanNumber]?: string;
  [HC15Key.HealthCarePlanId]?: string;
  [HC15Key.RawPlanName]?: string;
  [HC15Key.RawPlanGroupName]?: string;
  [HC15Key.CertifiedAction]?: CertifiedAction;
  [HC15Key.RequestedEffectiveDateOfCoverage]?: string;
  [HC15Key.RequestedEffectiveDateOfCancellation]?: string;
  [HC15Key.IntendedEffectiveDateOfCancellation]?: string;
  [HC15Key.ReasonForCancellationIntentToCancel]?: string;

  // Health Care Contractor
  [HC15Key.HealthCareContractor]?: UserDetail;
  [HC15Key.RawHealthCareContractorName]?: string;
  [HC15Key.HealthCareContractorName]?: string;
  [HC15Key.HealthCareContractorId]?: string;
  [HC15Key.ContactEmailAddress]?: string;
  [HC15Key.ContactPhoneNumber]?: string;
  [HC15Key.SubmittedBy]?: string;
  [HC15Key.SubmittedByUserName]?: string;
  [HC15Key.SubmittedByUserId]?: string;
  [HC15Key.HcContractorAddress]?: string;
  [HC15Key.HcContractorCity]?: string;
  [HC15Key.HcContractorState]?: string;
  [HC15Key.SubmittedDate]?: string;
  [HC15Key.HcContractorZipCode]?: string;
  [HC15Key.Address]?: string;
  [HC15Key.City]?: string;
  [HC15Key.State]?: string;
  [HC15Key.Date]?: string;
  [HC15Key.ZipCode]?: string;

  // Action
  [HC15Key.ActionTaken]?: HC15ActionTaken;
  [HC15Key.EffectiveDate]?: string;
  [HC15Key.CancellationReason]?: HC15CancellationReason;
  [HC15Key.RejectionReason]?: string;
  [HC15Key.ActionDate]?: string;
  [HC15Key.IsAccepted]?: boolean;
  [HC15Key.IsRejected]?: boolean;
  [HC15Key.AssignedToUserName]?: string;
  [HC15Key.AssignedToUserId]?: string;
  [HC15Key.ActionType]?: HC15ActionType;
  [HC15Key.UpdatedUserId]?: string;

  // Audit
  [HC15Key.CreatedBy]?: string;
  [HC15Key.CreatedAt]?: string;
  [HC15Key.UpdatedAt]?: string;
  [HC15Key.UpdatedBy]?: string;
  [HC15Key.UpdatedUser]?: AccountDetail;
  [HC15Key.CreatedUser]?: AccountDetail;

  // Accounts related
  [HC15Key.Accounts]?: Array<{
    [HC15Key.Id]?: string;
    [HC15Key.AccountName]?: string;
    [HC15Key.AccountType]?: string;
    [HC15Key.AccountLaborNumber]?: string;
    [HC15Key.AccountDCDStatus]?: string;
    [HC15Key.AccountDCDStatusDate]?: string;
  }>;
};

export enum CertifiedAction {
  NewlyEnrolled = 'Newly Enrolled',
  Canceled = 'Canceled',
  IntentToCancel = 'Intent to Cancel',
}

export enum HC15ActionTaken {
  NewlyEnrolled = 'Newly Enrolled',
  Canceled = 'Canceled',
  NoActionTaken = 'No Action Taken',
}

export enum HC15CancellationReason {
  Nonpayment = 'Nonpayment',
  Other = 'Other',
}

export enum HC15ActionType {
  Accept = 'ACCEPT',
  Reject = 'REJECT',
}

export enum ContentEmailAction {
  Accepted = 'ACCEPTED',
  Cancelled = 'CANCELLED ',
}
