import cn from 'classnames';
import React from 'react';
import { connect, useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';
import { MenuItem } from 'src/appConfig/sidebar';
import { hideAllDialog, hideDialog, showDialog } from 'src/redux/dialog/dialogSlice';
import { DIALOG_TYPES } from 'src/redux/dialog/type';
import { IRootState } from 'src/redux/store';
import { Navigator } from 'src/services';
import { AppName } from 'src/appConfig/constants';
import { urlAppMap } from './helpers';
import { setActiveApp } from 'src/redux/common/commonSlice';
import { Link, View } from 'src/modules/shared-main/components';

const SidebarItem: React.FC<Props> = ({
  item,
  onShowDialog,
  onHideDialog,
  activeApp,
  currentApp,
  onSetActiveApp,
}) => {
  const location = useLocation();
  const { collapseSidebar, showMiniSidebar } = useSelector((state: IRootState) => state.common);

  const shouldHighlightItem = (menuItem: MenuItem): boolean => {
    if (currentApp !== activeApp) {
      return false;
    }

    if (
      matchPath(location.pathname, {
        path: menuItem.href,
        exact: false,
        strict: false,
      })
    ) {
      return true;
    }

    if (menuItem.subMenu) {
      for (const subMenuItem of menuItem.subMenu) {
        if (shouldHighlightItem(subMenuItem)) {
          return true;
        }
      }
    }

    return false;
  };

  const showContentDialog = () => {
    onShowDialog({
      type: DIALOG_TYPES.OK_DIALOG,
      data: {
        title: 'Oops!',
        content: <View align="center">This feature is temporarily unavailable</View>,
        cancelText: 'Cancel',
        okText: 'Ok',
        onCancel: () => onHideDialog(),
        onOk: () => onHideDialog(),
      },
    });
  };

  return (
    <Link
      href={null}
      className={cn(
        'cmp-sidebar__item',
        {
          'cmp-sidebar__item--closed': collapseSidebar && !showMiniSidebar,
        },
        {
          'cmp-sidebar__item--active': shouldHighlightItem(item),
        }
      )}
      onClick={() => {
        if (!item.href) showContentDialog();
        else {
          onSetActiveApp(activeApp);
          // eslint-disable-next-line security/detect-object-injection
          Navigator.navigate(item.href.replace(':app', urlAppMap[activeApp]));
        }
      }}
    >
      <View className={cn('cmp-sidebar__item__icon')}>{item.icon}</View>
      <View
        className={cn('cmp-sidebar__item__text', {
          'cmp-sidebar__item__text--closed': collapseSidebar && !showMiniSidebar,
        })}
      >
        {item.title}
      </View>
    </Link>
  );
};

type Props = ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps & {
    item: MenuItem;
    activeApp: AppName;
  };

const mapStateToProps = (state: IRootState) => ({
  currentApp: state.common.currentApp,
});

const mapDispatchToProps = {
  onShowDialog: showDialog,
  onHideDialog: hideDialog,
  onHideAllDialog: hideAllDialog,
  onSetActiveApp: setActiveApp,
};

export default connect(mapStateToProps, mapDispatchToProps)(SidebarItem);
