import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { PaginationResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { GetPropertiesParams } from '../types';
import { toRelatedGLAccount } from './helpers';
import { GLAccountRelatedType } from './keys';
import { GLAccount } from './types';

export function useGetAllGLAccountRelated(
  options?: UseQueryOptions<PaginationResponseType<GLAccount>, Error> & {
    id: string;
    type: GLAccountRelatedType;
  }
) {
  const [params, setParams] = useState<GetPropertiesParams>({});
  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetRelatedGLAccounts,
  } = useQuery<PaginationResponseType<GLAccount>, Error>(
    [API_QUERIES.RELATED_GL_ACCOUNTS, { ...params, id: options?.id, type: options?.type }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<PaginationResponseType<GLAccount>>(
          apiClient.getAllRelatedGLAccounts,
          params
        );
      },
      select: toRelatedGLAccount,
      notifyOnChangeProps: ['data', 'isFetching'],
      keepPreviousData: true,
      enabled: !!options?.id && !!options?.type,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateAllGLAccountRelated = () =>
    queryClient.invalidateQueries([
      API_QUERIES.RELATED_GL_ACCOUNTS,
      { id: options?.id, type: options?.type },
    ]);

  const { data: relatedGLAccounts, hasNext, payloadSize, totalRecords } = data || {};

  return {
    onGetRelatedGLAccounts,
    relatedGLAccounts,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isError,
    isFetching,
    handleInvalidateAllGLAccountRelated,
    setParams,
  };
}
