import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { PaginationResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { GetPropertiesParams } from '../types';
import { toRelatedPartyNamesAddress } from './helpers';
import { RelatedPartyNamesAddressPayLoad } from './types';

export function useGetAllWCCaseParty(
  options?: UseQueryOptions<PaginationResponseType<RelatedPartyNamesAddressPayLoad>, Error> & {
    caseId?: string;
    tdiCaseId?: string;
    tdiSFCaseId?: string;
  }
) {
  const [params, setParams] = useState<GetPropertiesParams>({});
  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetAllRelatedPartyNames,
  } = useQuery<PaginationResponseType<RelatedPartyNamesAddressPayLoad>, Error>(
    [
      API_QUERIES.RELATED_WC_CASE_PARTY_NAMES_ADDRESS,
      {
        ...params,
        ...options,
      },
    ],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<PaginationResponseType<RelatedPartyNamesAddressPayLoad>>(
          apiClient.getAllWCCaseParty,
          params
        );
      },
      select: toRelatedPartyNamesAddress,
      notifyOnChangeProps: ['data', 'isFetching'],
      keepPreviousData: true,
      enabled: !!options.caseId || !!options.tdiCaseId || !!options.tdiSFCaseId,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateAllRelatedPartyNamesAddress = () =>
    queryClient.invalidateQueries([API_QUERIES.RELATED_WC_CASE_PARTY_NAMES_ADDRESS]);

  const { data: caseParties, hasNext, payloadSize, totalRecords } = data || {};

  return {
    caseParties,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isError,
    isFetching,
    onGetAllRelatedPartyNames,
    setParams,
    handleInvalidateAllRelatedPartyNamesAddress,
  };
}
