import { formatDate } from 'src/utils';
import { ApiResponseType } from '../helpers';
import { EmployerRegistrationActivitiesKey } from './keys';
import { EmployerRegistrationActivitiesResponse } from './types';

export const toEmployerRegistrationActivitiesReport = (
  data
): ApiResponseType<EmployerRegistrationActivitiesResponse> => {
  const { reports } = data?.data || {};
  const formattedReports = reports?.data.map((report) => ({
    ...report,
    [EmployerRegistrationActivitiesKey.CreatedDate]: `${formatDate(
      report?.[EmployerRegistrationActivitiesKey.StartDate]
    )} - ${formatDate(report?.[EmployerRegistrationActivitiesKey.EndDate])}`,
  }));

  return {
    ...data,
    data: {
      ...data?.data,
      reports: {
        ...reports,
        data: formattedReports,
      },
    },
  };
};
