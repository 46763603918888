export enum MenuPlacement {
  Top = 'top',
  Bottom = 'bottom',
}

export const MAX_MENU_HEIGHT = 300;

export const ALL_OPTIONS_VALUE = 'ALL_OPTIONS';

export const toDefaultSelectedOptions = (defaultSelectedOptions) =>
  defaultSelectedOptions.map((option) => ({ ...option, isHide: true }));

export const toOptions = (options, defaultSelectedOptions, value, isMulti) =>
  options
    .filter((item) => !defaultSelectedOptions.map((item) => item.value).includes(item.value))
    .map((item) => ({
      ...item,
      isHide: isMulti ? value?.includes(item?.value) : item.value === value,
    }));

export const getSelectedOption = (selectOptions, value, isMulti, defaultOptionLength) => {
  if (isMulti) {
    return selectOptions?.filter((option) => value?.includes(option.value)) || null;
  }

  const foundOption = selectOptions?.find((option) => option.value === value);

  if (foundOption) {
    return foundOption;
  }

  if (value) {
    return {
      id: defaultOptionLength,
      label: '',
      value: value,
    };
  }

  return null;
};

export const getMenuPlacement = (elementToWindowBottom: number) => {
  return elementToWindowBottom <= 300 ? MenuPlacement.Top : MenuPlacement.Bottom;
};

export const getFilteredSelectedOption = (selectedOption) => {
  let filteredSelectedOption;

  if (selectedOption?.[selectedOption.length - 1]?.value === ALL_OPTIONS_VALUE) {
    filteredSelectedOption = selectedOption.filter(({ value }) => value === ALL_OPTIONS_VALUE);
  } else {
    filteredSelectedOption = selectedOption.filter(({ value }) => value !== ALL_OPTIONS_VALUE);
  }

  return filteredSelectedOption;
};
