import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { createResponseWrapper } from '../helpers';
import { DecisionPartyNameAndAddressType } from './types';

export function useCreatePartyNamesAddressDecision(
  options?: UseMutationOptions<any, Error, DecisionPartyNameAndAddressType>
) {
  const { mutate: createDecisionRelatedPartyNamesAddress, isLoading } = useMutation<
    any,
    Error,
    DecisionPartyNameAndAddressType
  >({
    mutationFn: (payload: DecisionPartyNameAndAddressType) =>
      createResponseWrapper(apiClient.createDecisionRelatedPartyNamesAddress, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    createDecisionRelatedPartyNamesAddress,
    isLoading,
  };
}
