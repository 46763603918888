import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { createResponseWrapper } from '../helpers';
import { TDI21 } from './types';

export function useCreateTDI21(options?: UseMutationOptions<TDI21, Error, TDI21>) {
  const { mutate: createTDI21, isLoading } = useMutation<TDI21, Error, TDI21>({
    mutationFn: (payload: TDI21) => createResponseWrapper(apiClient.createTDI21, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    createTDI21,
    isLoading,
  };
}
