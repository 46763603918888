export enum WC1RawKey {
  Id = 'id',
  RawWC1Number = 'formNumber', //

  //===================== Header and Signature =====================
  RawNewAmend = 'rawNewAmend',
  RawInitialCaseNumber = 'rawHeaderCaseNumber',
  RawDateClaimReceivedByDCD = 'rawDateClaimReceived',
  RawDateReceived = 'rawDateReceived',
  RawSignerTitle = 'rawSignerTitle',
  RawSignedDate = 'rawSignedDate',

  //===================== Identification - Section 1 - Employee Information =====================
  RawEmployeeFirstName = 'rawEmployeeFirstName',
  RawEmployeeLastName = 'rawEmployeeLastName',
  RawEmployeeMiddleName = 'rawEmployeeMiddleInitial',
  RawSuffix = 'rawEmployeeSuffix',
  RawGender = 'rawGender',
  RawMartialStatus = 'rawMaritalStatus',
  RawIdNumber = 'rawIdNumber',
  RawIdentityType = 'rawIdentityType',
  RawSSN = 'rawSSN',
  RawPassportNumber = 'rawPassportNumber',
  RawDateOfBirth = 'rawDateOfBirth',
  RawAddressEmployee = 'rawEmployeeAddress',
  RawAddressAdditionEmployee = 'rawEmployeeAdditionAddress',
  RawCityEmployee = 'rawEmployeeCity',
  RawStateEmployee = 'rawEmployeeState',
  RawZipCodeEmployee = 'rawEmployeeZipcode',
  RawEmailEmployee = 'rawEmployeeEmail',
  RawPhoneEmployee = 'rawEmployeePhone',
  RawDateHiredEmployee = 'rawEmployeeDateHired',
  RawYearEmployedCode = 'rawEmployeeYearEmployedCode',
  RawOccupation = 'rawOccupation',
  RawDepartment = 'rawDepartment',
  RawPayrollCompClassCode = 'rawPayrollCompClassCode',
  RawSocCode = 'rawSocCode',
  RawOccCode = 'rawOccCode',

  //===================== Employer Information =====================
  RawRegisteredEmployer = 'rawRegisteredEmployer',
  RawDbaName = 'rawDbaName',
  RawAddressEmployer = 'rawEmployerAddress',
  RawCityEmployer = 'rawEmployerCity',
  RawStateEmployer = 'rawEmployerState',
  RawZipCodeEmployer = 'rawEmployerZipcode',
  RawEmployerPointOfContact = 'rawEmployerPointOfContact',
  RawPhoneEmployer = 'rawEmployerPhone',
  RawEmailEmployer = 'rawEmployerEmail',
  RawEmployerNatureBusiness = 'rawEmployerNatureBusiness',
  RawPreFabricated = 'rawPreFabricated',
  RawEmployerDolNumber = 'rawEmployerDolNumber',
  RawFederalIdNumber = 'rawFederalIdNumber',

  //===================== Detail of Injury/ Illness (I/I) - Section 2 =====================
  RawDiiReportedDate = 'rawDiiReportedDate',
  RawDiiDate = 'rawDiiDate',
  RawDiiTime = 'rawDiiTime', // TODO: be lack this field
  RawDiiTimeOfDay = 'rawDiiTimeOfDay',
  RawDiiOnEmployerPremise = 'rawDiiOnEmployerPremise',
  RawDiiDidEmployeeWorkFullShift = 'rawDiiDidEmployeeWorkFullShift',
  RawDiiWhereInjuryOccur = 'rawDiiWhereInjuryOccur',
  RawDiiCity = 'rawDiiCity',
  RawDiiState = 'rawDiiState',
  RawDiiZipcode = 'rawDiiZipcode',
  RawDiiHowOccour = 'rawDiiHow',
  RawDiiTimeWorkShiftBegan = 'rawDiiTimeWorkShiftBegan',
  RawDiiTimeWorkShiftEndedTimeOfDate = 'rawDiiTimeWorkShiftEndedTimeOfDate', // TODO: be lack this field
  RawDiiTimeWorkShiftBeganTimeOfDate = 'rawDiiTimeWorkShiftBeganTimeOfDate', // TODO: be lack this field
  RawDiiTimeWorkShiftEndTimeOfDate = 'rawDiiTimeWorkShiftEndTimeOfDate', // TODO: be lack this field
  RawDiiTimeWorkShiftEnd = 'rawDiiTimeWorkShiftEnd',
  RawDiiSourceOfInjury = 'rawDiiSourceOfInjury',
  RawDiiEvent = 'rawDiiEvent',
  RawDiiTask = 'rawDiiTask',
  RawDiiActivity = 'rawDiiActivity',
  RawDiiInjuryFactor = 'rawDiiInjuryFactor',
  RawDiiAos = 'rawDiiAos',
  RawDiiWhatWasEeDoing = 'rawDiiWhatWasEeDoing',
  RawDiiObjectInjuredEmployee = 'rawDiiObjectInjuredEmployee',

  //===================== Detail of Injury/ Illness (I/I) - Section 2 (Continued) =====================
  RawDiiDescribeDetail = 'rawDiiDescribeDetail',
  RawDiiMultipleBodyParts = 'rawDiiMultipleBodyParts',
  RawDiiNatureOfInjury = 'rawDiiNatureOfInjury',
  RawDiiPartOfBodyCode = 'rawDiiPartOfBodyCode',

  //===================== Side of Injury/Illness #1 =====================
  RawInjury1LeftRightSide = 'rawInjury1LeftRightSide',
  RawInjury1PartOfBody = 'rawInjury1PartOfBody',
  RawInjury1FrontBackSide = 'rawInjury1FrontBackSide',
  RawInjury1Disfigurement = 'rawInjury1Disfigurement',
  RawInjury1Burn = 'rawInjury1Burn',
  RawInjury1Type = 'rawInjury1Type',
  RawInjury1Other = 'rawInjury1Other',

  //===================== Side of Injury/Illness #2 =====================
  RawInjury2LeftRightSide = 'rawInjury2LeftRightSide',
  RawInjury2PartOfBody = 'rawInjury2PartOfBody',
  RawInjury2FrontBackSide = 'rawInjury2FrontBackSide',
  RawInjury2Disfigurement = 'rawInjury2Disfigurement',
  RawInjury2Burn = 'rawInjury2Burn',
  RawInjury2Type = 'rawInjury2Type',
  RawInjury2Other = 'rawInjury2Other',

  //===================== Side of Injury/Illness #3 =====================
  RawInjury3LeftRightSide = 'rawInjury3LeftRightSide',
  RawInjury3PartOfBody = 'rawInjury3PartOfBody',
  RawInjury3FrontBackSide = 'rawInjury3FrontBackSide',
  RawInjury3Disfigurement = 'rawInjury3Disfigurement',
  RawInjury3Burn = 'rawInjury3Burn',
  RawInjury3Type = 'rawInjury3Type',
  RawInjury3Other = 'rawInjury3Other',

  //===================== Side of Injury/Illness #4 =====================
  RawInjury4LeftRightSide = 'rawInjury4LeftRightSide',
  RawInjury4PartOfBody = 'rawInjury4PartOfBody',
  RawInjury4FrontBackSide = 'rawInjury4FrontBackSide',
  RawInjury4Disfigurement = 'rawInjury4Disfigurement',
  RawInjury4Burn = 'rawInjury4Burn',
  RawInjury4Type = 'rawInjury4Type',
  RawInjury4Other = 'rawInjury4Other',

  //===================== Side of Injury/Illness #5 =====================
  RawInjury5LeftRightSide = 'rawInjury5LeftRightSide',
  RawInjury5PartOfBody = 'rawInjury5PartOfBody',
  RawInjury5FrontBackSide = 'rawInjury5FrontBackSide',
  RawInjury5Disfigurement = 'rawInjury5Disfigurement',
  RawInjury5Burn = 'rawInjury5Burn',
  RawInjury5Type = 'rawInjury5Type',
  RawInjury5Other = 'rawInjury5Other',

  //==================== Side of Injury/Illness #6 =====================
  RawInjury6Type = 'rawInjury6Type',
  RawInjury6Other = 'rawInjury6Other',
  RawInjury6LeftRightSide = 'rawInjury6LeftRightSide',
  RawInjury6FrontBackSide = 'rawInjury6FrontBackSide',
  RawInjury6PartOfBody = 'rawInjury6PartOfBody',
  RawInjury6Disfigurement = 'rawInjury6Disfigurement',
  RawInjury6Burn = 'rawInjury6Burn',

  //==================== Side of Injury/Illness #7 =====================
  RawInjury7Type = 'rawInjury7Type',
  RawInjury7Other = 'rawInjury7Other',
  RawInjury7LeftRightSide = 'rawInjury7LeftRightSide',
  RawInjury7FrontBackSide = 'rawInjury7FrontBackSide',
  RawInjury7PartOfBody = 'rawInjury7PartOfBody',
  RawInjury7Disfigurement = 'rawInjury7Disfigurement',
  RawInjury7Burn = 'rawInjury7Burn',

  //==================== Side of Injury/Illness #8 =====================
  RawInjury8Type = 'rawInjury8Type',
  RawInjury8Other = 'rawInjury8Other',
  RawInjury8LeftRightSide = 'rawInjury8LeftRightSide',
  RawInjury8FrontBackSide = 'rawInjury8FrontBackSide',
  RawInjury8PartOfBody = 'rawInjury8PartOfBody',
  RawInjury8Disfigurement = 'rawInjury8Disfigurement',
  RawInjury8Burn = 'rawInjury8Burn',

  //==================== Side of Injury/Illness #9 =====================
  RawInjury9Type = 'rawInjury9Type',
  RawInjury9Other = 'rawInjury9Other',
  RawInjury9LeftRightSide = 'rawInjury9LeftRightSide',
  RawInjury9FrontBackSide = 'rawInjury9FrontBackSide',
  RawInjury9PartOfBody = 'rawInjury9PartOfBody',
  RawInjury9Disfigurement = 'rawInjury9Disfigurement',
  RawInjury9Burn = 'rawInjury9Burn',

  //==================== Side of Injury/Illness #10 =====================
  RawInjury10Type = 'rawInjury10Type',
  RawInjury10Other = 'rawInjury10Other',
  RawInjury10LeftRightSide = 'rawInjury10LeftRightSide',
  RawInjury10FrontBackSide = 'rawInjury10FrontBackSide',
  RawInjury10PartOfBody = 'rawInjury10PartOfBody',
  RawInjury10Disfigurement = 'rawInjury10Disfigurement',
  RawInjury10Burn = 'rawInjury10Burn',

  //===================== Time Lost Information - Section 3 =====================
  RawDateDisabilityBegan = 'rawDateDisabilityBegan',
  RawEmployeeFurnishesMealsTipsLodging = 'rawWasEmployeeFurnishesMealsTipsLodging',
  RawAvgWeeklyWage = 'rawAvgWeeklyWage',
  RawDateBackToWork = 'rawDateBackToWork',
  RawEmployeePaidForFullDayOfInjury = 'rawIsEmployeePaidForFullDayOfInjury',
  RawHourlyWage = 'rawHourlyWage',
  RawMonthlySalary = 'rawMonthlySalary',
  RawHoursWorkedWeek = 'rawHoursWorkedWeek',
  RawWeightingFactor = 'rawWeightingFactor',

  //===================== Dependent 1 =====================
  RawDep1LastName = 'rawDep1LastName',
  RawDep1FirstName = 'rawDep1FirstName',
  RawDep1MiddleInitial = 'rawDep1MiddleInitial',
  RawDep1Suffix = 'rawDep1Suffix',
  RawDep1RelationToDeceased = 'rawDep1RelationToDeceased',
  RawDep1Address = 'rawDep1Address',
  RawDep1City = 'rawDep1City',
  RawDep1State = 'rawDep1State',
  RawDep1Zipcode = 'rawDep1Zipcode',
  RawDep1Phone = 'rawDep1Phone',

  //===================== Dependent 2 =====================
  RawDep2LastName = 'rawDep2LastName',
  RawDep2FirstName = 'rawDep2FirstName',
  RawDep2MiddleInitial = 'rawDep2MiddleInitial',
  RawDep2Suffix = 'rawDep2Suffix',
  RawDep2RelationToDeceased = 'rawDep2RelationToDeceased',
  RawDep2Address = 'rawDep2Address',
  RawDep2City = 'rawDep2City',
  RawDep2State = 'rawDep2State',
  RawDep2Zipcode = 'rawDep2Zipcode',
  RawDep2Phone = 'rawDep2Phone',

  //===================== Dependent 3 =====================
  RawDep3LastName = 'rawDep3LastName',
  RawDep3FirstName = 'rawDep3FirstName',
  RawDep3MiddleInitial = 'rawDep3MiddleInitial',
  RawDep3Suffix = 'rawDep3Suffix',
  RawDep3RelationToDeceased = 'rawDep3RelationToDeceased',
  RawDep3Address = 'rawDep3Address',
  RawDep3City = 'rawDep3City',
  RawDep3State = 'rawDep3State',
  RawDep3Zipcode = 'rawDep3Zipcode',
  RawDep3Phone = 'rawDep3Phone',

  //===================== Dependent 4 =====================
  RawDep4LastName = 'rawDep4LastName',
  RawDep4FirstName = 'rawDep4FirstName',
  RawDep4MiddleInitial = 'rawDep4MiddleInitial',
  RawDep4Suffix = 'rawDep4Suffix',
  RawDep4RelationToDeceased = 'rawDep4RelationToDeceased',
  RawDep4Address = 'rawDep4Address',
  RawDep4City = 'rawDep4City',
  RawDep4State = 'rawDep4State',
  RawDep4Zipcode = 'rawDep4Zipcode',
  RawDep4Phone = 'rawDep4Phone',

  //===================== Treatment (obtain name of treating physician from employee) - Section 5 =====================
  RawTrNameOfPhysician = 'rawTrNameOfPhysician',
  RawTrPhysicianPhone = 'rawTrPhysicianPhone',
  RawTrPhysicianEmail = 'rawTrPhysicianEmail',
  RawTrPhysicianAddress = 'rawTrPhysicianAddress',
  RawTrPhysicianCity = 'rawTrPhysicianCity',
  RawTrPhysicianState = 'rawTrPhysicianState',
  RawTrPhysicianZipcode = 'rawTrPhysicianZipcode',
  RawTrInpatientOvernight = 'rawTrInpatientOvernight',
  RawTrEmergencyRoomOnly = 'rawTrEmergencyRoomOnly',
  RawTrMedicalFacilityName = 'rawTrMedicalFacilityName',
  RawTrMedicalFacilityAddress = 'rawTrMedicalFacilityAddress',
  RawTrMedicalFacilityCity = 'rawTrMedicalFacilityCity',
  RawTrMedicalFacilityState = 'rawTrMedicalFacilityState',
  RawTrMedicalFacilityZipcode = 'rawTrMedicalFacilityZipcode',

  //===================== Insurance Carrier =====================
  RawIcName = 'rawIcName',
  RawIcCarrierId = 'rawIcCarrierId',
  RawIcIsLiabilityDenied = 'rawIcIsLiabilityDenied',
  RawIcLiabilityDeniedWhy = 'rawIcLiabilityDeniedWhy',
  RawIcAdjustingCompanyName = 'rawIcAdjustingCompanyName',
  RawIcAdjusterName = 'rawIcAdjusterName',
  RawIcAdjusterEmail = 'rawIcAdjusterEmail',
  RawIcAdjusterPhone = 'rawIcAdjusterPhone',
  RawIcAdjusterId = 'rawIcAdjusterId',
  RawIcCarrierClaimNumber = 'rawIcCarrierClaimNumber',
  RawIcPolicyNumber = 'rawIcPolicyNumber',
  RawIcPolicyPeriodFrom = 'rawIcPolicyPeriodFrom',
  RawIcPolicyPeriodTo = 'rawIcPolicyPeriodTo',
  RawIcMedicalDeductible = 'rawIcMedicalDeductible',

  RawClaimant = 'claimant',
  RawStatus = 'status',

  RawCaseId = 'caseId',

  //===================== Audit Infomation =====================
  CreateUser = 'createdUser',
  UpdatedUser = 'updatedUser',
  UpdatedBy = 'updatedBy',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  FirstName = 'firstName',
  LastName = 'lastName',
  CreatedBy = 'createdBy',
}
