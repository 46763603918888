/* eslint-disable security/detect-object-injection */
import { Filter, FilterKey, FilterOwner, Logic, Operator } from 'src/modules/shared-main/queries';
import { Yup } from 'src/services';
import { getTitleCase } from 'src/utils';
import { TableColumnType } from '../helpers';

export enum QUERY_KEY {
  Filter = 'filter',
  SearchText = 'searchText',
}

export const filterSchema = Yup.object().shape({
  [FilterKey.Name]: Yup.string().nullable().required(),
  [FilterKey.Field]: Yup.string().nullable().required(),
  [FilterKey.Operator]: Yup.string().nullable().required(),
  [FilterKey.Value]: Yup.mixed()
    .nullable()
    .when([FilterKey.FieldType], {
      is: (type: TableColumnType) =>
        type !== TableColumnType.Select &&
        type !== TableColumnType.Checkbox &&
        type !== TableColumnType.SearchableSelect,
      then: Yup.string().nullable().required(),
      otherwise: Yup.array().nullable().min(1, 'This field is required.'),
    }),
});

export const filtersSchema = Yup.array().min(1).of(filterSchema);

export const initialValues: Filter = {
  [FilterKey.Name]: 'Filter 1',
  [FilterKey.Field]: '',
  [FilterKey.Operator]: '',
  [FilterKey.Value]: '',
};

export const checkboxOptions = [
  {
    label: 'True',
    value: 'true',
  },
  {
    label: 'False',
    value: 'false',
  },
];

export const operatorOptions = [
  {
    label: 'Equals',
    value: Operator.Equals,
  },
  {
    label: 'Not Equal To',
    value: Operator.NotEqualTo,
  },
  {
    label: 'Less Than',
    value: Operator.LessThan,
    notFieldTypeCondition: [TableColumnType.Checkbox, TableColumnType.Select],
  },
  {
    label: 'Greater Than',
    value: Operator.GreaterThan,
    notFieldTypeCondition: [TableColumnType.Checkbox, TableColumnType.Select],
  },
  {
    label: 'Less Or Equal',
    value: Operator.LessOrEqual,
    notFieldTypeCondition: [TableColumnType.Checkbox, TableColumnType.Select],
  },
  {
    label: 'Greater Or Equal',
    value: Operator.GreaterOrEqual,
    notFieldTypeCondition: [TableColumnType.Checkbox, TableColumnType.Select],
  },
  {
    label: 'Contains',
    value: Operator.Contains,
    notFieldTypeCondition: [TableColumnType.Checkbox, TableColumnType.Select],
  },
  {
    label: 'Does Not Contain',
    value: Operator.DoesNotContain,
    notFieldTypeCondition: [TableColumnType.Checkbox, TableColumnType.Select],
  },
  {
    label: 'Starts With',
    value: Operator.StartsWith,
    notFieldTypeCondition: [TableColumnType.Checkbox, TableColumnType.Select],
  },
];

export const conditionOptions = [
  {
    label: 'And',
    value: Logic.And,
  },
  {
    label: 'Or',
    value: Logic.Or,
  },
  {
    label: 'Not',
    value: Logic.Not,
  },
];

export enum LogicElementType {
  Condition = 'Condition',
  Filter = 'Filter',
}

export type LogicType = {
  value: string;
  type: LogicElementType;
};

export const getLogicLength = (logic: LogicType[], logicType: LogicElementType) =>
  logic.filter(({ type }) => type === logicType).length;

export const checkUnique = (logic: LogicType[], filterLength: number) => {
  const unique = logic.filter(
    (obj, index) =>
      logic.findIndex(
        (item) => item.value === obj.value && item.type === LogicElementType.Filter
      ) === index
  );
  return unique.length === filterLength;
};

export const checkLastItem = (logic: LogicType[]) =>
  logic[logic.length - 1]?.type === LogicElementType.Filter;

export const checkOrder = (logic: LogicType[]): boolean => {
  return !logic.find(
    ({ type }, index) =>
      (type === LogicElementType.Filter && index % 2) ||
      (type === LogicElementType.Condition && !(index % 2))
  );
};

export const convertFromLogic = (logic = {}) => {
  const result = [];

  for (let index = Object.keys(logic).length - 1; index >= 0; index--) {
    const element = Object.keys(logic)[index];
    result.unshift({
      value: getTitleCase(element),
      type: LogicElementType.Condition,
    });

    if (typeof logic[element] === 'string') {
      result.push({
        value: logic[element],
        type: LogicElementType.Filter,
      });
    } else {
      for (let i = logic[element].length - 1; i >= 0; i--) {
        if (typeof logic[element][i] === 'string') {
          if (result[0].type === LogicElementType.Condition && result[0].value === 'Not') {
            result.unshift({
              value: logic[element][i],
              type: LogicElementType.Filter,
            });
          } else {
            result.push({
              value: logic[element][i],
              type: LogicElementType.Filter,
            });
          }
        } else if (
          Array.isArray(Object.values(logic[element][i])[0]) &&
          !(Object.values(logic[element][i])[0] as Array<any>).some(
            (item) => typeof item !== 'string'
          )
        ) {
          result.unshift(
            {
              value: Object.values(logic[element][i])[0][0],
              type: LogicElementType.Filter,
            },
            {
              value: getTitleCase(Object.keys(logic[element][i])[0]),
              type: LogicElementType.Condition,
            },
            {
              value: Object.values(logic[element][i])[0][1],
              type: LogicElementType.Filter,
            }
          );
        } else {
          result.unshift(...convertFromLogic(logic[element][i]));
        }
      }
    }
  }
  if (result[result.length - 1]?.type === LogicElementType.Condition) {
    result.pop();
  } else if (result[0]?.type === LogicElementType.Condition && result.length === 3) {
    result.splice(0, 0, result[result.length - 1]);
    result.pop();
  }

  return result;
};

export const removeFilter = (logic: LogicType[], filterName: string, newFilters) => {
  if (logic.length === 3 && logic.some(({ value }) => value === filterName)) {
    return !!newFilters.length
      ? []
      : newFilters.flatMap(({ name }, index) =>
          !!index
            ? [
                { value: getTitleCase(Logic.And), type: LogicElementType.Condition },
                {
                  value: name,
                  type: LogicElementType.Filter,
                },
              ]
            : { value: name, type: LogicElementType.Filter }
        );
  }

  logic.forEach(({ value }, index) => {
    if (value === filterName) {
      index ? logic.splice(index - 1, 2) : logic.splice(index, 2);
    }
  });

  return logic;
};

const logicConvert = (arrayPosition) => {
  if (arrayPosition.length > 1) {
    for (let index = arrayPosition.length - 1; index >= 0; index--) {
      const element = arrayPosition[index];
      if (element.type === LogicElementType.Condition) {
        if (element.value === getTitleCase(Logic.Not)) {
          return {
            AND: [
              logicConvert(arrayPosition.slice(0, -2)),
              {
                NOT: arrayPosition[index + 1]?.value,
              },
            ],
          };
        }
        return {
          [element.value?.toUpperCase()]: [
            logicConvert(arrayPosition.slice(0, -2)),
            arrayPosition[index + 1]?.value,
          ],
        };
      }
    }
  } else {
    return arrayPosition[0]?.value;
  }
};

export const convertToLogic = (filters = []): Object => {
  const arrayPosition = [...filters];
  return logicConvert(arrayPosition);
};

export const validateDuplicateName = (values: Filter[], currentIndex: number) => {
  const names = values.map(({ name }) => name);
  if (values.some(({ name }, index) => names.indexOf(name) !== index)) {
    return values.map((_, index) => {
      if (index === currentIndex) {
        return {
          [FilterKey.Name]: 'This filter name is already in use.',
        };
      } else {
        return {};
      }
    });
  }

  return {};
};

export const getNewLogic = (
  values: Filter[],
  logic: Object,
  currentIndex: number,
  isEdit = false
) => {
  if (values.length === 1) {
    return { logic: { AND: values?.map(({ name }) => name) } };
  }

  if (values.length >= 2 && isEdit) {
    const currentLogic = convertFromLogic(logic);
    const valuesName = values.map(({ name }) => name);

    const newLogic = currentLogic.map((item) => {
      if (item.type === LogicElementType.Filter && valuesName.indexOf(item.value) === -1) {
        return { ...item, value: valuesName[currentIndex] };
      }
      return item;
    });

    return { logic: convertToLogic(newLogic) };
  }

  if (values.length > 2 && !isEdit) {
    return {
      logic: convertToLogic([
        ...convertFromLogic(logic),
        { value: getTitleCase(Logic.And), type: LogicElementType.Condition },
        { value: values[currentIndex].name, type: LogicElementType.Filter },
      ]),
    };
  }

  if (values.length === 2 && !isEdit) {
    return {
      logic: convertToLogic([
        { value: values[0].name, type: LogicElementType.Filter },
        { value: getTitleCase(Logic.And), type: LogicElementType.Condition },
        { value: values[currentIndex].name, type: LogicElementType.Filter },
      ]),
    };
  }

  return null;
};

export const filterOwnerOptions = (filterByObject: string) => [
  {
    label: `All ${filterByObject}`,
    value: FilterOwner.AllUsers,
  },
  {
    label: `My ${filterByObject}`,
    value: FilterOwner.OnlyMe,
  },
];
