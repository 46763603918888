import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { createResponseWrapper } from '../helpers';
import { DecisionPayLoad } from './types';

export function useCreateDecision(
  options?: UseMutationOptions<DecisionPayLoad, Error, DecisionPayLoad>
) {
  const { mutate: createDecision, isLoading } = useMutation<
    DecisionPayLoad,
    Error,
    DecisionPayLoad
  >({
    mutationFn: (payload: DecisionPayLoad) =>
      createResponseWrapper(apiClient.createDecision, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    createDecision,
    isLoading,
  };
}
