import apisauce from 'apisauce';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import appConfig from 'src/appConfig';
import {
  AccountDetail,
  ActivatePlanHC61Payload,
  ActivatePlanPayload,
  AddProfilePayload,
  AddUserPayload,
  AddressHistory,
  AppPermissionPayload,
  AwardEmployment,
  AwardSummaryPayload,
  AwardWorksheet,
  AwwDependent,
  BenefitPaymentSummaryPayload,
  CalculateEquivalencyPayload,
  CaseProcessingLog,
  CaseSettlementsTypes,
  ChangePasswordPayload,
  CloneProfilePayload,
  CommentPayload,
  Complaint,
  CompleteNewPasswordPayload,
  ConfirmPasswordPayload,
  ConfirmSignInPayload,
  CustomSettings,
  DecisionPartyNameAndAddressType,
  DecisionPayLoad,
  DecisionTextPayLoad,
  Disbursement,
  EmployerAudit,
  EmploymentAtTimeOfII,
  ExistedPartiesDecision,
  FieldPermissionsPayload,
  FinalReportPayload,
  ForgotPasswordPayload,
  GLAccount,
  GetPresignedAvatarPayload,
  GetPresignedPayload,
  GetPropertiesParams,
  HC15,
  HC4,
  HC61,
  HealthCarePlan,
  HearingScheduleInterface,
  HistoricalFormValue,
  HrsHarPayload,
  NoteDetail,
  NotifyFiscalPayload,
  ObjectPermissionsPayload,
  PHCCoverage,
  PortalUserPayload,
  PrehearingForEmployer,
  PreparePayload,
  ProfilePayload,
  ReadNotificationPayload,
  Receipt,
  RelatedGLAccountPayload,
  RelatedHearingRequiredDocumentsType,
  RelatedOrderType,
  RelatedVendorsType,
  Report,
  Request,
  RequestReconsiderationPayload,
  ResetUsersPasswordPayload,
  ScheduleOfDisbursementPayload,
  SendDisfigurementEmail,
  SignInPayload,
  StatusPayload as StatusUserPayload,
  SubmitForgotPasswordPayload,
  SystemPermissionsPayload,
  TDI15,
  TDI21,
  TDI30,
  TDI62,
  TDI62Action,
  TDICase,
  TDIPlan,
  TDISFCase,
  TDISFCaseStatusPayload,
  UpdateCaseStatus,
  UpdateHistoryStatusPayload,
  UpdateSendEmailPayload,
  UploadFilePayload,
  User,
  UserDetail,
  VRProvider,
  VocationalRehabilitation,
  WC1,
  WC14,
  WC2FormPayload,
  WC3AFormPayload,
  WC5FormPayload,
  WC5RawInterface,
  WC77,
  WCCoveragesTypes,
  WCInsurancePoliciesInterface,
  updateDCDStatusPayload,
} from 'src/queries';
import { Contact, EmailPayload } from 'src/queries/Contacts';
import { CoverageEquivalencyRelated } from 'src/queries/CoverageEquivalency/types';
import { PPDInjuredBodyPartsType } from 'src/queries/PPDInjuredBodyParts';
import { RelatedAccountContactType } from 'src/queries/RelatedAccountContact/types';
import { CaseAward } from 'src/queries/RelatedCaseAward';
import { RelatedCaseDependentsType } from 'src/queries/RelatedCaseDependents/types';
import { RelatedHearing } from 'src/queries/RelatedHearings/types';
import { RelatedInjuryIllnessType } from 'src/queries/RelatedInjuryIllness/types';
import { PotentialAssociated } from 'src/queries/RelatedPotentialAssociated';
import { TDI46 } from 'src/queries/TDI46s';
import { TDICoverage } from 'src/queries/TDICoverages';
import { TrackingLog } from 'src/queries/TrackingLogs';
import { BenefitPaymentsDetailType } from 'src/queries/WC3/BenefitPayments';
import { WC3AInterface } from 'src/queries/WC3A/WC3ATypes';
import { newCancelToken, stringify } from 'src/utils';
import { TokenService } from '.';
import {
  ExistedParties,
  PartyNameAndAddressType,
} from '../queries/HearingRelatedPartyNamesAddress/types';
import { RelatedTypePurposesType } from '../queries/HearingRelatedTypePurposes/types';
import { RelatedPartyNamesAddressPayLoad } from '../queries/RelatedPartyNamesAndAddresses';
import languages from './languages';
import locales from './locales';

axios.defaults.withCredentials = true;

const create = (baseURL = appConfig.API_URL) => {
  //
  // Create and configure an apisauce-based api object.
  //

  const api = apisauce.create({
    baseURL,
    headers: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: 0,
      Accept: 'application/json',
    },
    timeout: appConfig.CONNECTION_TIMEOUT,
  });

  api.axiosInstance.interceptors.request.use((config) => {
    return TokenService.getToken()
      .then((token) => {
        config.headers.Authorization = 'Bearer ' + token;
        return Promise.resolve(config);
      })
      .catch(() => {
        return Promise.resolve(config);
      });
  });
  const getRoot = () => api.get('');

  // ====================== Auth ======================
  const signIn = (body: SignInPayload) => Auth.signIn(body.username, body.password);

  const signOut = () => Auth.signOut();

  const forgotPassword = (body: ForgotPasswordPayload) => Auth.forgotPassword(body.username);

  const submitForgotPassword = (body: SubmitForgotPasswordPayload) =>
    Auth.forgotPasswordSubmit(body.email, body.token, body.password);

  const changePassword = (body: ChangePasswordPayload) =>
    Auth.changePassword(body.user, body.currentPassword, body.newPassword);

  const confirmSignIn = (body: ConfirmSignInPayload) =>
    Auth.sendCustomChallengeAnswer(body.user, body.code);

  const confirmPassword = (password: ConfirmPasswordPayload) => {
    return Auth.currentAuthenticatedUser().then((user) =>
      Auth.signIn({
        username: user.username,
        password: password.password,
      })
    );
  };

  const completeNewPassword = (body: CompleteNewPasswordPayload) =>
    Auth.completeNewPassword(body.user, body.password, body.requiredAttributes);

  // ====================== Profile ======================
  const getUserId = (params: { username: string }) => {
    const username = { username: params.username };
    const queryString = stringify(username);
    return api.get(`/account-svc/v1/users/user-id?${queryString}`, {}, newCancelToken());
  };

  // ====================== Profile ======================
  const getMyProfile = () => api.get('/account-svc/v1/me', {}, newCancelToken());

  const updateUserAvatar = (body: { avatar: string }) =>
    api.put(`/account-svc/v1/me/avatar`, body, newCancelToken());

  const updateMyProfile = (body: ProfilePayload) =>
    api.put(`/account-svc/v1/me`, body, newCancelToken());

  const getPresignedDownloadAvatarUrl = (params: { filePath: string }) => {
    const filePath = { filePath: params.filePath };
    const queryString = stringify(filePath);
    return api.get(
      `/account-svc/v1/files/presigned-download-url?${queryString}`,
      {},
      newCancelToken()
    );
  };

  const createProfile = (payload: AddProfilePayload) => {
    return api.post(
      '/account-svc/v1/profiles',
      {
        ...payload,
      },
      newCancelToken()
    );
  };

  const cloneProfile = (body: CloneProfilePayload) => {
    const profileId = body.id;
    return api.post(`/account-svc/v1/profiles/${profileId}/clone`, body, newCancelToken());
  };

  const editProfile = (body: CloneProfilePayload) => {
    const profileId = body.id;
    return api.put(`/account-svc/v1/profiles/${profileId}`, body, newCancelToken());
  };

  const getPresignedUploadAvatarUrl = (params: GetPresignedAvatarPayload) => {
    return api.get('/account-svc/v1/files/presigned-upload-url', params, newCancelToken());
  };

  // ====================== Content ======================
  const getContents = () => api.get('/account-svc/v1/contents', {}, newCancelToken());

  // ====================== File ======================
  const getPresignedUserServiceUrl = (params: GetPresignedPayload) => {
    return api.get('/file-svc/v1/presigned-upload-url', params, newCancelToken());
  };
  const uploadFile = (body: UploadFilePayload) =>
    axios.put(body.url, body.fileData, {
      headers: { 'Content-Type': body.contentType },
      withCredentials: false,
    });

  const getDecodeUserServiceUrl = (params: { filePath: string }) =>
    api.get('/account-svc/v1/files/presigned-download-url', params, newCancelToken());

  const uploadFileWithProgress = (body: UploadFilePayload) =>
    axios.put(body.url, body.fileData, {
      onUploadProgress: (progress) => {
        const { loaded, total } = progress;
        const percentageProgress = Math.floor((loaded / total) * 100);
        body.setProgress(percentageProgress);
      },
    });

  const getLanguages = (params) => languages.getPaginationLanguage(params);

  const getLocale = (params) => locales.getPaginationLocale(params);

  // ====================== System Accounts ======================

  const searchUserAccounts = (params: { search: string }) => {
    const queryString = stringify(params);
    return api.get(`/users/search?${queryString}`, {}, newCancelToken());
  };
  const searchUserAccountsAxios = (params: { search: string; skip: number; take: number }) => {
    const queryString = stringify(params);
    return api.get(`${appConfig.API_URL}/users/search?${queryString}`, {}, newCancelToken());
  };
  const searchUserAccountsByOrderAxios = (params: {
    search: string;
    skip: number;
    take: number;
    order: string;
  }) => {
    const queryString = stringify(params);
    return api.get(`${appConfig.API_URL}/users/search?${queryString}`, {}, newCancelToken());
  };

  // ====================== System Accounts ======================
  const getMyPermissions = () => api.get('/account-svc/v1/permissions/me', {}, newCancelToken());

  // ====================== Groups Management ======================
  const getAllGroup = (params: GetPropertiesParams) =>
    api.get(`account-svc/v1/groups?${stringify(params)}`, {}, newCancelToken());

  const getUsersFromGroup = (params: GetPropertiesParams) =>
    api.get(`account-svc/v1/groups/${params.id}/users?${stringify(params)}`, {}, newCancelToken());

  const addUsersToGroup = (params: { id: string }, payload: User['id'][]) =>
    api.post(`/account-svc/v1/groups/${params.id}/users`, payload, newCancelToken());

  const removeUsersFromGroup = (params: { id: string }, payload: User['id'][]) =>
    api.patch(`/account-svc/v1/groups/${params.id}/users`, payload, newCancelToken());

  const updateSendEmailGroup = (body: UpdateSendEmailPayload) => {
    const id = body.id;
    return api.put(`/account-svc/v1/groups/${id}`, body, newCancelToken());
  };

  // ====================== Accounts Management ======================
  const getAllAccounts = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/accounts?${stringify(params)}`, {}, newCancelToken());

  const deleteAccount = (accountId: string) =>
    api.delete(`/case-svc/v1/accounts/${accountId}`, {}, newCancelToken());

  const getAccount = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/accounts/${params.id}`, {}, newCancelToken());

  const getAccountHierarchy = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/accounts/${params.id}/hierarchy`, {}, newCancelToken());

  // ====================== Users Management ======================
  const getAllUsers = (params: GetPropertiesParams) =>
    api.get(`account-svc/v1/users?${stringify(params)}`, {}, newCancelToken());

  const getAllProfile = (params: GetPropertiesParams) =>
    api.get(`account-svc/v1/profiles?${stringify(params)}`, {}, newCancelToken());

  const getProfile = (params: { id: string }) =>
    api.get(`/account-svc/v1/profiles/${params.id}`, {}, newCancelToken());

  const getAssignedUsers = (params: GetPropertiesParams) =>
    api.get(
      `/account-svc/v1/profiles/${params.id}/assigned-users?${stringify(params)}`,
      {},
      newCancelToken()
    );

  const assignUser = (params: { id: string }, payload: User['id'][]) =>
    api.put(`/account-svc/v1/profiles/${params.id}/assigned-users`, payload, newCancelToken());

  const unAssignUser = (params: { id: string }, payload: User['id'][]) =>
    api.patch(`/account-svc/v1/profiles/${params.id}/assigned-users`, payload, newCancelToken());

  const getSystemPermissions = (params: { id: string }) =>
    api.get(`/account-svc/v1/profiles/${params.id}/system-permissions`, {}, newCancelToken());

  const updateSystemPermissions = (payload: SystemPermissionsPayload[], id: string) =>
    api.put(`/account-svc/v1/profiles/${id}/system-permissions`, payload, newCancelToken());

  const deleteProfile = (profileId: string) =>
    api.delete(`/account-svc/v1/profiles/${profileId}`, {}, newCancelToken());

  const getUser = (params: { id: User['id'] }) => {
    return api.get(`/account-svc/v1/users/${params.id}`, {}, newCancelToken());
  };

  const createUser = (payload: UserDetail) => {
    return api.post(
      `/account-svc/v1/users`,
      {
        ...payload,
      },
      newCancelToken()
    );
  };

  const deleteUser = (userId: User['id']) => {
    return api.delete(`/account-svc/v1/users/${userId}`, {}, newCancelToken());
  };

  const searchUsers = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(`/account-svc/v1/users/search/delegates?${queryString}`, {}, newCancelToken());
  };

  const searchProjects = (params: GetPropertiesParams) => {
    const queryString = stringify(params);
    return api.get(`/account-svc/v1/projects?${queryString}`, {}, newCancelToken());
  };

  const updateUser = (body: AddUserPayload) => {
    const userId = body.id;
    return api.put(`/account-svc/v1/users/${userId}`, body, newCancelToken());
  };

  const resetUsersPassword = (body: ResetUsersPasswordPayload) => {
    return api.post(`/account-svc/v1/users/reset-password`, { ids: body }, newCancelToken());
  };

  const editUserStatus = (body: StatusUserPayload) => {
    const userId = body.id;
    const status = body.action;
    return api.patch(
      `/account-svc/v1/users/${userId}/status`,
      { status: status },
      newCancelToken()
    );
  };

  // ====================== Permission ======================
  const getObjectPermission = (body) =>
    api.get(`/account-svc/v1/profiles/${body.id}/object-permissions`);

  const editObjectPermission = (body: ObjectPermissionsPayload) => {
    const id = body.id;
    const objectPermissions = body.objectPermissions;
    return api.put(
      `/account-svc/v1/profiles/${id}/object-permissions`,
      { objectPermissions },
      newCancelToken()
    );
  };

  const getFieldPermission = (params: { id: string }) =>
    api.get(`/account-svc/v1/permissions/${params.id}`);

  const editFieldPermission = (id: string, body: FieldPermissionsPayload) => {
    return api.put(`/account-svc/v1/permissions/${id}`, body, newCancelToken());
  };
  const getAppPermissions = (params: { id: string }) =>
    api.get(`/account-svc/v1/profiles/${params.id}/app-permissions`, {}, newCancelToken());

  const editAppPermissions = (body: AppPermissionPayload) => {
    const id = body.id;
    return api.put(
      `/account-svc/v1/profiles/${id}/app-permissions`,
      { appPermissions: body.data },
      newCancelToken()
    );
  };

  // ====================== Account ======================

  const createAccount = (body: AccountDetail) => {
    return api.post(
      `/case-svc/v1/accounts`,
      {
        ...body,
      },
      newCancelToken()
    );
  };

  const updateAccount = (body: AccountDetail) => {
    const id = body.id;
    return api.put(`/case-svc/v1/accounts/${id}`, body, newCancelToken());
  };

  const notifyBouncedCheckAccount = (body: NotifyFiscalPayload) => {
    return api.post(`/case-svc/v1/accounts/${body?.id}/notify`, body, newCancelToken());
  };

  const getAllEmployerComments = (params) => {
    const { accountId, ...searchParams } = params || {};
    return api.get(
      `/case-svc/v1/accounts/${params.accountId}/employer-comments?${stringify(searchParams)}`,
      {},
      newCancelToken()
    );
  };

  const createEmployerComment = (body: CommentPayload) => {
    return api.post(
      `/case-svc/v1/accounts/${body.accountId}/employer-comments`,
      {
        ...body,
      },
      newCancelToken()
    );
  };

  const updateEmployerComment = (body: CommentPayload) => {
    const id = body.id;
    return api.put(`/case-svc/v1/accounts/employer-comments/${id}`, body, newCancelToken());
  };

  const deleteEmployerComment = (id: string) =>
    api.delete(`/case-svc/v1/accounts/employer-comments/${id}`, {}, newCancelToken());

  // ====================== Contact ======================

  const getContact = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/contacts/${params.id}`, {}, newCancelToken());

  const createContact = (body: Contact) => {
    return api.post(`/case-svc/v1/contacts`, { ...body }, newCancelToken());
  };

  const updateContact = (body: Contact) => {
    const id = body.id;
    return api.put(`/case-svc/v1/contacts/${id}`, body, newCancelToken());
  };

  const deleteContact = (id: string) =>
    api.delete(`/case-svc/v1/contacts/${id}`, {}, newCancelToken());

  const getAllRelatedContacts = (params: GetPropertiesParams) =>
    api.get(
      `/case-svc/v1/accounts/${params.id}/contacts?${stringify(params)}`,
      {},
      newCancelToken()
    );

  // ====================== Address History ======================
  const getAddressHistories = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/address-histories?${stringify(params)}`, {}, newCancelToken());

  const getAddressHistory = (id: string) =>
    api.get(`/case-svc/v1/address-histories/${id}`, {}, newCancelToken());

  const createAddressHistory = (body: AddressHistory) => {
    return api.post('/case-svc/v1/address-histories', { ...body }, newCancelToken());
  };

  const updateAddressHistory = (body: AddressHistory) => {
    const id = body.id;
    return api.put(`/case-svc/v1/address-histories/${id}`, body, newCancelToken());
  };

  const deleteAddressHistory = (id: string) => {
    return api.delete(`/case-svc/v1/address-histories/${id}`, {}, newCancelToken());
  };

  // ====================== Contacts ======================
  const getAllContacts = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/contacts?${stringify(params)}`, {}, newCancelToken());

  const getAllAddress = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/smarty-streets?${stringify(params)}`, {}, newCancelToken());

  const getAllRelatedAccountContact = (id: string, params) =>
    api.get(
      `/case-svc/v1/accounts/${id}/account-contacts?${stringify(params)}`,
      {},
      newCancelToken()
    );

  const createRelatedAccountContact = (body: AccountDetail) => {
    return api.post(
      `/case-svc/v1/account-contacts`,
      {
        ...body,
      },
      newCancelToken()
    );
  };

  const deleteRelatedAccountContact = (id: RelatedAccountContactType['id']) => {
    return api.delete(`/case-svc/v1/account-contacts/${id}`, {}, newCancelToken());
  };

  const editRelatedAccountContact = (body) => {
    const id = body.id;
    return api.put(`/case-svc/v1/account-contacts/${id}`, body, newCancelToken());
  };

  const getRelatedAccountContact = (params) => {
    return api.get(`/case-svc/v1/account-contacts/${params.contactId}`, {}, newCancelToken());
  };

  // ====================== DCD Cases ======================
  const getAllDCDCases = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/wc-cases?${stringify(params)}`, {}, newCancelToken());

  const getDCDCase = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/wc-cases/${params.id}`, {}, newCancelToken());

  const deleteDCDCase = (id: string) => {
    return api.delete(`/case-svc/v1/wc-cases/${id}`, {}, newCancelToken());
  };

  const updateDCDCase = (body) => {
    const id = body.id;
    return api.put(`/case-svc/v1/wc-cases/${id}`, body, newCancelToken());
  };

  const getCasePaymentYears = (caseId: string) =>
    api.get(`/case-svc/v1/wc-cases/${caseId}/payment-years`, {}, newCancelToken());

  const getLedgers = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/ledgers?${stringify(params)}`, {}, newCancelToken());

  const deleteLedger = (id: string) => {
    return api.delete(`/case-svc/v1/ledgers/${id}`, {}, newCancelToken());
  };

  const getScheduleOfDisbursements = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/schedule-disbursements?${stringify(params)}`, {}, newCancelToken());

  const deleteScheduleOfDisbursement = (id: string) => {
    return api.delete(`/case-svc/v1/schedule-disbursements/${id}`, {}, newCancelToken());
  };

  const createScheduleOfDisbursement = (body: ScheduleOfDisbursementPayload) => {
    return api.post('/case-svc/v1/schedule-disbursements', body, newCancelToken());
  };

  const editScheduleOfDisbursement = (body: ScheduleOfDisbursementPayload) => {
    const id = body.id;
    return api.put(`/case-svc/v1/schedule-disbursements/${id}`, body, newCancelToken());
  };

  const holdDisbursement = (body) => {
    const caseId = body.caseId;
    return api.post(`/case-svc/v1/wc-cases/${caseId}/hold-disbursements`, body, newCancelToken());
  };

  const releaseDisbursement = (caseId: string) =>
    api.post(`/case-svc/v1/wc-cases/${caseId}/release-disbursements`, {}, newCancelToken());

  const transferCaseLocation = (body) => {
    return api.post(`/case-svc/v1/approval-histories`, body, newCancelToken());
  };

  const getAllOrders = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/orders?${stringify(params)}`, {}, newCancelToken());

  const getOrder = (params: { id: string }) =>
    api.get(`/case-svc/v1/orders/${params.id}`, {}, newCancelToken());

  const deleteOrder = (id: string) => {
    return api.delete(`/case-svc/v1/orders/${id}`, {}, newCancelToken());
  };

  const createOrder = (body: RelatedOrderType) => {
    return api.post('/case-svc/v1/orders', body, newCancelToken());
  };

  const updateOrder = (body) => {
    const id = body.id;
    return api.put(`/case-svc/v1/orders/${id}`, body, newCancelToken());
  };

  const changeControlCase = (body) => {
    const caseId = body.caseId;
    return api.put(`/case-svc/v1/wc-cases/${caseId}/change-control-case`, body, newCancelToken());
  };

  const getCasePartiesAndAddresses = (params: GetPropertiesParams) =>
    api.get(`case-svc/v1/parties?${stringify(params)}`, {}, newCancelToken());

  const getAwardSummaries = (params: GetPropertiesParams) =>
    api.get(
      `/case-svc/v1/wc-cases/${params?.caseId}/award-summaries?${stringify(params)}`,
      {},
      newCancelToken()
    );

  const getCaseAwardPayers = (params: GetPropertiesParams) =>
    api.get(
      `/case-svc/v1/wc-cases/${params?.caseId}/case-awards-payers?${stringify(params)}`,
      {},
      newCancelToken()
    );

  const createAwardSummary = (body: AwardSummaryPayload) => {
    const { wcCaseId, ...payload } = body;
    return api.post(`/case-svc/v1/wc-cases/${wcCaseId}/award-summaries`, payload, newCancelToken());
  };

  const getAwardSummary = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/wc-cases/award-summaries/${params.id}`, {}, newCancelToken());

  const deleteAwardSummary = (id: string) =>
    api.delete(`/case-svc/v1/wc-cases/award-summaries/${id}`, {}, newCancelToken());

  // ====================== WC1 Forms ======================
  const getAllWC1Forms = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/wc-1s?${stringify(params)}`, {}, newCancelToken());

  const deleteWC1Form = (id: string) =>
    api.delete(`/case-svc/v1/wc-1s/${id}`, {}, newCancelToken());

  // ====================== WC2 Forms ======================
  const getAllWC2Forms = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/wc-2s?${stringify(params)}`, {}, newCancelToken());

  const deleteWC2Form = (id: string) =>
    api.delete(`/case-svc/v1/wc-2s/${id}`, {}, newCancelToken());

  // ====================== WC3A Forms ======================
  const getAllWC3AForms = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/wc-3as?${stringify(params)}`, {}, newCancelToken());

  // ====================== WC5 Forms ======================
  const getAllWC5Forms = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/wc-5s?${stringify(params)}`, {}, newCancelToken());

  const createWC5Form = (body: WC5FormPayload) => {
    return api.post('/case-svc/v1/wc-5s', body, newCancelToken());
  };

  // ====================== WC CASES ======================
  const getPresignedUrlCaseService = (params: GetPresignedPayload) => {
    return api.get('/case-svc/v1/files/presigned-upload-url', params, newCancelToken());
  };

  const sendEmail = (body: EmailPayload) => api.post(`/case-svc/v1/emails`, body, newCancelToken());

  const sendDisfigurementEmail = (body: SendDisfigurementEmail) =>
    api.post(`/case-svc/v1/emails`, body, newCancelToken());

  const getPresignedUploadFile = (params) => {
    return api.get('/case-svc/v1/files/presigned-upload-url', params, newCancelToken());
  };

  const getPresignedDownloadFileUrl = (params) => {
    return api.get('/case-svc/v1/files/presigned-download-url', params, newCancelToken());
  };

  const submitUploadFile = (body) => {
    return api.post('/case-svc/v1/upload-files', { ...body }, newCancelToken());
  };

  const markDoneUploadFile = (body: { id: string }) => {
    const id = body.id;
    return api.put(`/case-svc/v1/upload-files/${id}/mark-done`, { id }, newCancelToken());
  };

  const updateDCDStatus = (body: updateDCDStatusPayload) => {
    const { id, status } = body;
    return api.patch(`/case-svc/v1/wc-cases/${id}/status`, { status }, newCancelToken());
  };

  const updateDCDCaseStatus = (body: UpdateCaseStatus) => {
    const { id, status } = body;
    return api.put(`/case-svc/v1/wc-cases/${id}/case-status`, { status }, newCancelToken());
  };

  const getDisfigurementBodyParts = ({ id }: { id: string }) => {
    return api.get(`/case-svc/v1/wc-cases/${id}/disfigurement-body-parts`, {}, newCancelToken());
  };

  // ====================== Notifications ======================
  const getAllNotifications = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/notifications?${stringify(params)}`, {}, newCancelToken());

  const readNotification = (body: ReadNotificationPayload) => {
    const id = body.id;
    return api.put(`/case-svc/v1/notifications/${id}`, body, newCancelToken());
  };

  const markAllReadNotifications = () =>
    api.post(`/case-svc/v1/notifications/mark-read`, {}, newCancelToken());

  // ====================== WC1 ======================
  const updateWC1Status = (body: updateDCDStatusPayload) => {
    const { id, status } = body;
    return api.patch(`/case-svc/v1/wc-1s/${id}/status`, { status }, newCancelToken());
  };

  const getWC1Detail = (params: { id: WC1['id'] }) => {
    return api.get(`/case-svc/v1/wc-1s/${params.id}`, {}, newCancelToken());
  };

  const getAllWC1s = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/wc-1s?${stringify(params)}`, {}, newCancelToken());

  const updateWC1 = (body: any) => {
    const id = body.id;
    return api.put(`/case-svc/v1/wc-1s/${id}`, body, newCancelToken());
  };

  // ====================== Injury/Illness ======================
  const getAllRelatedInjuryIllness = (id: string, params) =>
    api.get(
      `/case-svc/v1/wc-cases/${id}/wc-case-injury-body-parts?${stringify(params)}`,
      {},
      newCancelToken()
    );

  const getInjuryIllnessPartBodyCode = (params: GetPropertiesParams) =>
    api.get(
      `/case-svc/v1/wc-case-injury-body-parts/part-of-body-code?${stringify(params)}`,
      {},
      newCancelToken()
    );

  const createRelatedInjuryIllness = (body: RelatedInjuryIllnessType) => {
    return api.post('/case-svc/v1/wc-case-injury-body-parts', body, newCancelToken());
  };

  const editRelatedInjuryIllness = (body: RelatedInjuryIllnessType) => {
    const id = body.id;
    return api.put(`/case-svc/v1/wc-case-injury-body-parts/${id}`, body, newCancelToken());
  };

  const deleteRelatedInjuryIllness = (id: RelatedInjuryIllnessType['id']) => {
    return api.delete(`/case-svc/v1/wc-case-injury-body-parts/${id}`, {}, newCancelToken());
  };

  // ====================== Benefit Payments ======================
  const getAllBenefitPayments = (params: GetPropertiesParams) => {
    return api.get(
      `/case-svc/v1/wc-3s/benefit-payments?${stringify(params)}`,
      {},
      newCancelToken()
    );
  };

  const deleteBenefitPayment = (id: string) => {
    return api.delete(`/case-svc/v1/wc-3s/benefit-payments/${id}`, {}, newCancelToken());
  };

  const createBenefitPayment = (body: BenefitPaymentsDetailType) => {
    return api.post('/case-svc/v1/wc-3s/benefit-payments', body, newCancelToken());
  };

  const editBenefitPayment = (body: BenefitPaymentsDetailType) => {
    const id = body.id;
    return api.put(`/case-svc/v1/wc-3s/benefit-payments/${id}`, body, newCancelToken());
  };

  // ====================== WC-2s ======================
  const createWC2sForm = (body: WC2FormPayload) => {
    return api.post('/case-svc/v1/wc-2s', body, newCancelToken());
  };

  const getAllWC2 = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/wc-2s?${stringify(params)}`, {}, newCancelToken());

  const deleteWC2 = (id: string) => api.delete(`/case-svc/v1/wc-2s/${id}`, {}, newCancelToken());

  // ====================== WC-3As ======================
  const getAllWC3As = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/wc-3as?${stringify(params)}`, {}, newCancelToken());

  const deleteWC3As = (id: string) => api.delete(`/case-svc/v1/wc-3as/${id}`, {}, newCancelToken());

  const createWC3AForm = (body: WC3AFormPayload) => {
    return api.post('/case-svc/v1/wc-3as', body, newCancelToken());
  };

  // ====================== WC-5s ======================
  const getAllWC5 = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/wc-5s?${stringify(params)}`, {}, newCancelToken());

  const deleteWC5 = (id: string) => api.delete(`/case-svc/v1/wc-5s/${id}`, {}, newCancelToken());

  const getWC5Detail = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/wc-5s/${params.id}`, {}, newCancelToken());

  const updateWC5 = (body: WC5RawInterface) =>
    api.put(`/case-svc/v1/wc-5s/${body.id}`, body, newCancelToken());

  // ====================== WC-5As ======================
  const getAllWC5As = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/wc-5As?${stringify(params)}`, {}, newCancelToken());

  const createWC5AForm = (body: WC2FormPayload) => {
    return api.post('/case-svc/v1/wc-5as', body, newCancelToken());
  };

  const deleteWC5A = (id: string) => api.delete(`/case-svc/v1/wc-5as/${id}`, {}, newCancelToken());

  const getWC5ADetail = (params: { id: string }) =>
    api.get(`/case-svc/v1/wc-5as/${params.id}`, {}, newCancelToken());

  const updateWC5A = (body: any) => {
    const id = body.id;
    return api.put(`/case-svc/v1/wc-5as/${id}`, body, newCancelToken());
  };

  // ====================== WC-Insurance-Policies ======================
  const getAllWCIP = (params: GetPropertiesParams) => {
    return api.get(`/case-svc/v1/insurance-policies?${stringify(params)}`, {}, newCancelToken());
  };

  const createWCIP = (body: WCInsurancePoliciesInterface) => {
    return api.post('/case-svc/v1/insurance-policies', body, newCancelToken());
  };

  const deleteWCIP = (id: string) => {
    return api.delete(`/case-svc/v1/insurance-policies/${id}`, {}, newCancelToken());
  };

  const getWCIPDetail = (params: { id: string }) => {
    return api.get(`/case-svc/v1/insurance-policies/${params.id}`, {}, newCancelToken());
  };

  const updateWCIP = (body: any) => {
    const id = body.id;
    return api.put(`/case-svc/v1/insurance-policies/${id}`, body, newCancelToken());
  };

  // ====================== Approval History ======================
  const getApprovalHistoryDetail = (params: { id: string }) => {
    return api.get(`/case-svc/v1/approval-histories/${params.id}`, {}, newCancelToken());
  };

  // ====================== Approval History ======================
  const getRooms = () => {
    return api.get(`/case-svc/v1/rooms`, {}, newCancelToken());
  };

  // ====================== CaseSettlement ======================
  const getCaseSettlementDetail = (params: { id: string }) => {
    return api.get(`/case-svc/v1/case-settlements/${params.id}`, {}, newCancelToken());
  };

  const updateCaseSettlementDetails = (body: any) => {
    const id = body.id;
    return api.put(`/case-svc/v1/case-settlements/${id}`, body, newCancelToken());
  };

  // ====================== WC-3s ======================
  const getAllWC3 = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/wc-3s?${stringify(params)}`, {}, newCancelToken());

  const deleteWC3 = (id: string) => api.delete(`/case-svc/v1/wc-3s/${id}`, {}, newCancelToken());

  const updateWC3 = (body: any) => {
    const id = body.id;
    return api.put(`/case-svc/v1/wc-3s/${id}`, body, newCancelToken());
  };

  const createBenefitPaymentSummary = (body: BenefitPaymentSummaryPayload) =>
    api.post('case-svc/v1/wc-3s/benefit-payment-summaries', body, newCancelToken());

  const getAllBenefitPaymentSummaries = (params: GetPropertiesParams) =>
    api.get(
      `/case-svc/v1/wc-3s/benefit-payment-summaries?${stringify(params)}`,
      {},
      newCancelToken()
    );

  const deleteBenefitPaymentSummary = (id: string) =>
    api.delete(`/case-svc/v1/wc-3s/benefit-payment-summaries/${id}`, {}, newCancelToken());

  const updateBenefitPaymentSummary = (body: BenefitPaymentSummaryPayload) => {
    const id = body.id;
    return api.put(`case-svc/v1/wc-3s/benefit-payment-summaries/${id}`, body, newCancelToken());
  };

  const createWC3Historical = (body: HistoricalFormValue) => {
    return api.post('/case-svc/v1/wc-3s/historical', body, newCancelToken());
  };

  const createFinalReport = (body: FinalReportPayload) =>
    api.post('case-svc/v1/wc-3s/final-reports', body, newCancelToken());

  const getAllHistoryPriorPayments = (params: GetPropertiesParams) =>
    api.get(
      `/case-svc/v1/wc-3s/historical-prior-payments?${stringify(params)}`,
      {},
      newCancelToken()
    );

  // ====================== Case Dependents ======================
  const getAllRelatedCaseDependents = (id: string, params) =>
    api.get(
      `/case-svc/v1/wc-cases/${id}/wc-case-dependents?${stringify(params)}`,
      {},
      newCancelToken()
    );

  const createRelatedCaseDependent = (body: RelatedCaseDependentsType) => {
    return api.post('/case-svc/v1/wc-case-dependents', body, newCancelToken());
  };

  const deleteRelatedCaseDependent = (id: RelatedCaseDependentsType['id']) => {
    return api.delete(`/case-svc/v1/wc-case-dependents/${id}`, {}, newCancelToken());
  };

  // ====================== Vendors ======================
  const getAllRelatedVendors = (params: { caseId: string }) =>
    api.get(`/case-svc/v1/wc-case-vendors?caseId=${params.caseId}`, {}, newCancelToken());

  const deleteRelatedVendor = (id: string) =>
    api.delete(`/case-svc/v1/wc-case-vendors/${id}`, {}, newCancelToken());

  const createRelatedVendor = (body: RelatedVendorsType) =>
    api.post('/case-svc/v1/wc-case-vendors', body, newCancelToken());

  const updateRelatedVendor = (body: RelatedVendorsType) => {
    const vendorId = body.vendorId;
    return api.put(`/case-svc/v1/wc-case-vendors/${vendorId}`, body, newCancelToken());
  };

  // ====================== Hearing Required Documents ======================
  const getAllRelatedHearingRequiredDocuments = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/required-documents?${stringify(params)}`, {}, newCancelToken());

  const deleteRelatedHearingRequiredDocuments = (id: string) =>
    api.delete(`/case-svc/v1/required-documents/${id}`, {}, newCancelToken());

  const createRelatedHearingRequiredDocuments = (body: RelatedHearingRequiredDocumentsType) =>
    api.post(`/case-svc/v1/required-documents/${body.hearingId}`, body, newCancelToken());

  const updateRelatedHearingRequiredDocuments = (body: RelatedHearingRequiredDocumentsType) => {
    const id = body.id;
    return api.put(`/case-svc/v1/required-documents/${id}`, body, newCancelToken());
  };
  // ===================================================================

  const getWC2Detail = (params: { id: string }) =>
    api.get(`/case-svc/v1/wc-2s/${params.id}`, {}, newCancelToken());

  const updateWC2 = (body: any) => {
    const id = body.id;
    return api.put(`/case-svc/v1/wc-2s/${id}`, body, newCancelToken());
  };
  //

  const getWC3ADetail = (params: { id: WC3AInterface['id'] }) => {
    return api.get(`/case-svc/v1/wc-3as/${params.id}`, {}, newCancelToken());
  };

  const updateWC3A = (body: WC3AInterface) =>
    api.put(`/case-svc/v1/wc-3as/${body.id}`, body, newCancelToken());

  const getWC3 = (params: { id: string }) => {
    return api.get(`/case-svc/v1/wc-3s/${params.id}`, {}, newCancelToken());
  };

  // ====================== Case Settlements ======================
  const getAllCaseSettlements = (params) =>
    api.get(`/case-svc/v1/case-settlements?${stringify(params)}`, {}, newCancelToken());

  const createCaseSettlement = (body: CaseSettlementsTypes) =>
    api.post('/case-svc/v1/case-settlements', body, newCancelToken());

  const editCaseSettlement = (body: CaseSettlementsTypes) =>
    api.put(`/case-svc/v1/case-settlements/${body.id}`, body, newCancelToken());

  const deleteCaseSettlement = (id: string) =>
    api.delete(`/case-svc/v1/case-settlements/${id}`, {}, newCancelToken());

  // ====================== DCD Case Related Hearings ======================
  const getAllRelatedHearings = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/case-hearings?${stringify(params)}`, {}, newCancelToken());

  const createRelatedHearing = (body: RelatedHearing) =>
    api.post('/case-svc/v1/case-hearings', body, newCancelToken());

  const editRelatedHearing = (body: RelatedHearing) =>
    api.put(`/case-svc/v1/case-hearings/${body.id}`, body, newCancelToken());

  const deleteRelatedHearing = (id: string) =>
    api.delete(`/case-svc/v1/case-hearings/${id}`, {}, newCancelToken());

  // ====================== Receipts ======================
  const getAllReceipts = (params) =>
    api.get(`/case-svc/v1/receipts?${stringify(params)}`, {}, newCancelToken());

  const deleteRelatedReceipt = (id: string) =>
    api.delete(`/case-svc/v1/receipts/${id}`, {}, newCancelToken());

  const createReceipt = (payload: Receipt) => {
    return api.post(
      `/case-svc/v1/receipts`,
      {
        ...payload,
      },
      newCancelToken()
    );
  };

  const updateReceipt = (payload: Receipt) => {
    return api.put(
      `/case-svc/v1/receipts/${payload?.id}`,
      {
        ...payload,
      },
      newCancelToken()
    );
  };

  const getReceipt = (params: { id: string }) =>
    api.get(`/case-svc/v1/receipts/${params.id}`, {}, newCancelToken());

  const notifyFiscalReceipt = (body: NotifyFiscalPayload) => {
    return api.post(`/case-svc/v1/receipts/${body?.id}/notify-fiscal`, body, newCancelToken());
  };

  const notifyBouncedCheckReceipt = (body: NotifyFiscalPayload) => {
    return api.post(
      `/case-svc/v1/receipts/${body?.id}/notify-bounced-check`,
      body,
      newCancelToken()
    );
  };

  // ====================== Disbursements ======================

  const getAllDisbursements = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/disbursements?${stringify(params)}`, {}, newCancelToken());

  const deleteDisbursement = (id: string) =>
    api.delete(`/case-svc/v1/disbursements/${id}`, {}, newCancelToken());

  const getDisbursement = (params: { id: string }) => {
    return api.get(`/case-svc/v1/disbursements/${params.id}`, {}, newCancelToken());
  };
  const createDisbursement = (payload: Disbursement) => {
    return api.post(
      `/case-svc/v1/disbursements`,
      {
        ...payload,
      },
      newCancelToken()
    );
  };

  const postDisbursement = (body: Disbursement) =>
    api.put(`/case-svc/v1/disbursements/${body.id}/post`, body, newCancelToken());

  const setPaymentReminder = (body) => {
    const id = body.id;
    return api.post(`/case-svc/v1/disbursements/${id}/payment-reminders`, body, newCancelToken());
  };

  const releasePaymentReminder = (id: string) =>
    api.delete(`/case-svc/v1/disbursements/${id}/payment-reminders`, {}, newCancelToken());

  const updateDisbursement = (payload: any) => {
    return api.put(
      `/case-svc/v1/disbursements/${payload?.id}`,
      {
        ...payload,
      },
      newCancelToken()
    );
  };

  const notifyFiscalDisbursement = (body) => {
    return api.post(`/case-svc/v1/disbursements/${body?.id}/notify-fiscal`, body, newCancelToken());
  };

  // ====================== Prehearing for employer ======================

  const getAllRelatedPrehearingForEmployer = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/prehearings-for-delinquents?${stringify(params)}`, {}, newCancelToken());

  const createPrehearingForEmployer = (payload: PrehearingForEmployer) => {
    return api.post(
      `/case-svc/v1/prehearings-for-delinquents`,
      {
        ...payload,
      },
      newCancelToken()
    );
  };

  const updatePrehearingForEmployer = (payload: PrehearingForEmployer) => {
    return api.put(
      `/case-svc/v1/prehearings-for-delinquents/${payload?.id}`,
      {
        ...payload,
      },
      newCancelToken()
    );
  };

  const getPrehearingForEmployer = (params: { id: string }) => {
    return api.get(`/case-svc/v1/prehearings-for-delinquents/${params.id}`, {}, newCancelToken());
  };

  const deletePrehearingForEmployer = (id: string) =>
    api.delete(`/case-svc/v1/prehearings-for-delinquents/${id}`, {}, newCancelToken());

  // ====================== Status history ======================

  const getAllRelatedCaseStatusHistory = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/case-status-histories?${stringify(params)}`, {}, newCancelToken());

  const deleteCaseStatusHistory = (id: string) =>
    api.delete(`/case-svc/v1/case-status-histories/${id}`, {}, newCancelToken());

  // ====================== Status history ======================

  const getAllRelatedAssociatedCases = (params: GetPropertiesParams) =>
    api.get(
      `/case-svc/v1/wc-cases/${params?.caseId}/wc-associated-cases?${stringify(params)}`,
      {},
      newCancelToken()
    );

  const deleteAssociatedCases = (id: string) =>
    api.delete(`/case-svc/v1/wc-associated-cases/${id}`, {}, newCancelToken());

  // ====================== Related Approval History ======================

  const getAllRelatedApprovalHistory = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/approval-histories?${stringify(params)}`, {}, newCancelToken());

  const updateHistoryStatus = (body: UpdateHistoryStatusPayload) => {
    const id = body.id;
    return api.put(`/case-svc/v1/approval-histories/${id}`, body, newCancelToken());
  };

  // ====================== Related Party History Archives ======================
  const getAllHearingRelatedPartyArchives = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/case-hearing-party-archives?${stringify(params)}`, {}, newCancelToken());

  const deleteHearingRelatedPartyArchive = (id: string) =>
    api.delete(`/case-svc/v1/case-hearing-party-archives/${id}`, {}, newCancelToken());

  const getAllDecisionRelatedPartyArchives = (params: GetPropertiesParams) =>
    api.get(
      `/case-svc/v1/decisions/${params?.decisionId}/party-history-archives?${stringify(params)}`,
      {},
      newCancelToken()
    );

  const deleteDecisionRelatedPartyArchive = (id: string) =>
    api.delete(`/case-svc/v1/decisions/party-history-archives/${id}`, {}, newCancelToken());

  // ====================== Vocational Rehabilitation Process ======================
  const getAllVocationalRehabilitation = (params: GetPropertiesParams) => {
    return api.get(`/case-svc/v1/vr-processes?${stringify(params)}`, {}, newCancelToken());
  };

  const deleteVocationalRehabilitation = (vrProcessId: string) =>
    api.delete(`/case-svc/v1/vr-processes/${vrProcessId}`, {}, newCancelToken());

  const createVocationalRehabilitation = (body: VocationalRehabilitation) =>
    api.post('/case-svc/v1/vr-processes', body, newCancelToken());

  const editVocationalRehabilitation = (body: VocationalRehabilitation) =>
    api.put(`/case-svc/v1/vr-processes/${body.id}`, body, newCancelToken());

  const getVocationalRehabilitation = (body: VocationalRehabilitation) =>
    api.get(`/case-svc/v1/vr-processes/${body.id}`, {}, newCancelToken());

  // ====================== Case Award ======================
  const deleteCaseAward = (id: string) =>
    api.delete(`/case-svc/v1/wc-case-awards/${id}`, {}, newCancelToken());

  const getAllRelatedCaseAwards = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/wc-case-awards?${stringify(params)}`, {}, newCancelToken());

  const getCaseAward = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/wc-case-awards/${params.id}`, {}, newCancelToken());

  const createCaseAward = (body: CaseAward) =>
    api.post(`/case-svc/v1/wc-case-awards`, body, newCancelToken());

  const updateCaseAward = (body: CaseAward) =>
    api.put(`/case-svc/v1/wc-case-awards/${body.id}`, body, newCancelToken());

  // ======================Related WC Case Party Names Address ======================
  const getAllWCCaseParty = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/parties?${stringify(params)}`, {}, newCancelToken());

  const getWCCaseParty = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/parties/${params.id}`, {}, newCancelToken());

  const updateWCCaseParty = (body: RelatedPartyNamesAddressPayLoad) => {
    const id = body.id;
    return api.put(`/case-svc/v1/parties/${id}`, body, newCancelToken());
  };

  const deleteWCCaseParty = (id: string) =>
    api.delete(`/case-svc/v1/parties/${id}`, {}, newCancelToken());

  const createWCCaseParty = (body: RelatedPartyNamesAddressPayLoad) =>
    api.post(`/case-svc/v1/parties`, body, newCancelToken());

  const getAllCasePartyActiveHistory = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/parties/active-histories?${stringify(params)}`, {}, newCancelToken());

  // ====================== VR Provider ======================
  const getVRProviders = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/vr-providers?${stringify(params)}`, {}, newCancelToken());

  const deleteVRProvider = (id: string) => {
    return api.delete(`/case-svc/v1/vr-providers/${id}`, {}, newCancelToken());
  };

  const createVRProvider = (body: VRProvider) => {
    return api.post('/case-svc/v1/vr-providers', body, newCancelToken());
  };

  const editVRProvider = (body: VRProvider) => {
    const id = body.id;
    return api.put(`/case-svc/v1/vr-providers/${id}`, body, newCancelToken());
  };

  // ====================== Employment At Time Of Injury/Illness ======================
  const getEmploymentAtTimeOfII = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/wc-case-employments?${stringify(params)}`, {}, newCancelToken());

  const deleteEmploymentAtTimeOfII = (id: string) => {
    return api.delete(`/case-svc/v1/wc-case-employments/${id}`, {}, newCancelToken());
  };

  const createEmploymentAtTimeOfII = (body: EmploymentAtTimeOfII) => {
    return api.post('/case-svc/v1/wc-case-employments', body, newCancelToken());
  };

  const editEmploymentAtTimeOfII = (body: EmploymentAtTimeOfII) => {
    const id = body.id;
    return api.put(`/case-svc/v1/wc-case-employments/${id}`, body, newCancelToken());
  };

  // ====================== Related Type Purposes======================
  const getAllRelatedTypePurposes = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/type-purposes?${stringify(params)}`, {}, newCancelToken());

  const getRelatedTypePurposes = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/type-purposes?${stringify(params)}`, {}, newCancelToken());

  const deleteRelatedTypePurposes = (id: string) => {
    return api.delete(`/case-svc/v1/type-purposes/${id}`, {}, newCancelToken());
  };

  const updateRelatedTypePurposes = (body: RelatedTypePurposesType) => {
    const id = body.id;
    return api.put(`/case-svc/v1/type-purposes/${id}`, body, newCancelToken());
  };
  const createRelatedTypePurposes = (body: RelatedTypePurposesType) => {
    return api.post(`/case-svc/v1/type-purposes`, body, newCancelToken());
  };

  const getTypePurposes = (params) => {
    return api.get(`/case-svc/v1/case-hearings/${params?.hearingId}/types`, {}, newCancelToken());
  };

  const getPurposes = (params) => {
    return api.get(
      `/case-svc/v1/type-purposes/${params?.hearingType}?hearingId=${params?.hearingId}`,
      {},
      newCancelToken()
    );
  };

  // ====================== WC-Coverages ======================
  const getAllWCCoverages = (params: GetPropertiesParams) => {
    return api.get(`/case-svc/v1/coverages?${stringify(params)}`, {}, newCancelToken());
  };

  const createWCCoverage = (body: WCCoveragesTypes) => {
    return api.post('/case-svc/v1/coverages', body, newCancelToken());
  };

  const editWCCoverage = (body: WCCoveragesTypes) => {
    const id = body.id;
    return api.put(`/case-svc/v1/coverages/${id}`, body, newCancelToken());
  };

  const deleteWCCoverage = (id: string) => {
    return api.delete(`/case-svc/v1/coverages/${id}`, {}, newCancelToken());
  };

  // ====================== Prepare Expenditure Vouchers =====================
  const prepareExpenditureVouchers = (body: PreparePayload) => {
    const { caseId, vendorId } = body;
    return api.post(
      `case-svc/v1/wc-cases/${caseId}/expenditure-vouchers`,
      { vendorId },
      newCancelToken()
    );
  };

  // ====================== Hearing Related Notes ======================
  const getAllHearingRelatedNotes = (params) =>
    api.get(`/case-svc/v1/case-hearing-notes?${stringify(params)}`, {}, newCancelToken());

  const getHearingRelatedNote = (params: { id: string }) =>
    api.get(`/case-svc/v1/case-hearing-notes/${params?.id}`, {}, newCancelToken());

  const removeHearingRelatedNote = (params) =>
    api.delete(`/case-svc/v1/case-hearing-notes?${stringify(params)}`, newCancelToken());

  const createHearingRelatedNote = (body: NoteDetail) =>
    api.post('/case-svc/v1/case-hearing-notes', body, newCancelToken());

  const updateHearingRelatedNote = (body: NoteDetail) =>
    api.put(`/case-svc/v1/case-hearing-notes/${body.id}`, body, newCancelToken());

  const shareHearingRelatedNote = (body: NoteDetail) =>
    api.post(`/case-svc/v1/case-hearing-notes/${body.id}/sharing`, body, newCancelToken());

  const deleteHearingRelatedNote = (id: string) =>
    api.delete(`/case-svc/v1/case-hearing-notes/${id}`, {}, newCancelToken());

  // ====================== GL Accounts Management ======================

  const createGLAccount = (body: GLAccount) => {
    return api.post('/case-svc/v1/gl-accounts', body, newCancelToken());
  };

  const updateGLAccount = (body: GLAccount) => {
    const id = body.id;
    return api.put(`/case-svc/v1/gl-accounts/${id}`, body, newCancelToken());
  };

  // ====================== Hearings ======================
  const getAllHearings = (params) =>
    api.get(`/case-svc/v1/case-hearings?${stringify(params)}`, {}, newCancelToken());

  const deleteHearing = (id: string) =>
    api.delete(`/case-svc/v1/case-hearings/${id}`, {}, newCancelToken());

  const getHearingDetail = (params: { id: WC1['id'] }) => {
    return api.get(`/case-svc/v1/case-hearings/${params.id}`, {}, newCancelToken());
  };

  const updateHearing = (body: any) => {
    const id = body.id;
    return api.put(`/case-svc/v1/case-hearings/${id}`, body, newCancelToken());
  };

  const getAllRooms = (params) =>
    api.get(`/case-svc/v1/rooms?${stringify(params)}`, {}, newCancelToken());

  const createHearingSchedule = (body: HearingScheduleInterface) => {
    const id = body.id;
    return api.post(`/case-svc/v1/case-hearings/${id}/schedules`, body, newCancelToken());
  };

  const getHearingSchedule = (params) => {
    const { id, ...otherParams } = params;
    return api.get(
      `/case-svc/v1/case-hearings/${id}/schedules?${stringify(otherParams)}`,
      {},
      newCancelToken()
    );
  };

  const getScheduleAvailability = (params) => {
    const { id, ...otherParams } = params;
    return api.get(
      `/case-svc/v1/case-hearings/${id}/schedule-availability?${stringify(otherParams)}`,
      {},
      newCancelToken()
    );
  };

  const getHearingDecisions = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/decisions?${stringify(params)}`, {}, newCancelToken());

  const deleteHearingDecision = (id: string) =>
    api.delete(`/case-svc/v1/decisions/${id}`, {}, newCancelToken());

  // ====================== TDI-62 ======================
  const getAllTDI62 = (params) =>
    api.get(`/case-svc/v1/tdi-62s?${stringify(params)}`, {}, newCancelToken());

  const deleteTDI62 = (id: string) =>
    api.delete(`/case-svc/v1/tdi-62s/${id}`, {}, newCancelToken());

  const getTDI62Detail = (params: { id: string }) => {
    return api.get(`/case-svc/v1/tdi-62s/${params.id}`, {}, newCancelToken());
  };

  const acceptRejectAction = (body: TDI62Action) => {
    const id = body.id;
    return api.put(`/case-svc/v1/tdi-62s/${id}/accept-reject-action`, body, newCancelToken());
  };

  const getTDI62RelatedRelationships = (params: { id: string }) => {
    return api.get(`/case-svc/v1/tdi-62s/relationship/${params.id}`, {}, newCancelToken());
  };

  const editTDI62 = (body: TDI62) => {
    const id = body.id;
    return api.put(`/case-svc/v1/tdi-62s/${id}`, body, newCancelToken());
  };

  // ====================== TDI-46 ======================
  const getAllTDI46 = (params) =>
    api.get(`/case-svc/v1/tdi-46s?${stringify(params)}`, {}, newCancelToken());

  const deleteTDI46 = (id: string) =>
    api.delete(`/case-svc/v1/tdi-46s/${id}`, {}, newCancelToken());

  const getTDI46Detail = (params: { id: string }) => {
    return api.get(`/case-svc/v1/tdi-46s/${params.id}`, {}, newCancelToken());
  };

  // ====================== TDI-21 ======================
  const getAllTDI21 = (params) =>
    api.get(`/case-svc/v1/tdi-21s?${stringify(params)}`, {}, newCancelToken());

  const deleteTDI21 = (id: string) =>
    api.delete(`/case-svc/v1/tdi-21s/${id}`, {}, newCancelToken());

  const getTDI21Detail = (params: { id: string }) => {
    return api.get(`/case-svc/v1/tdi-21s/${params.id}`, {}, newCancelToken());
  };

  const createTDI21 = (body: TDI21) => api.post(`/case-svc/v1/tdi-21s`, body, newCancelToken());

  const updateTDI21 = (body: TDI21) =>
    api.put(`/case-svc/v1/tdi-21s/${body.id}`, body, newCancelToken());

  // ====================== TDI-15 ======================
  const getAllTDI15 = (params) =>
    api.get(`/case-svc/v1/tdi-15s?${stringify(params)}`, {}, newCancelToken());

  const deleteTDI15 = (id: string) =>
    api.delete(`/case-svc/v1/tdi-15s/${id}`, {}, newCancelToken());

  const getTDI15Detail = (params: { id: string }) => {
    return api.get(`/case-svc/v1/tdi-15s/${params.id}`, {}, newCancelToken());
  };

  const createTDI15 = (payload: TDI15) => {
    return api.post(
      `/case-svc/v1/tdi-15s`,
      {
        ...payload,
      },
      newCancelToken()
    );
  };

  const updateTDI15 = (payload: TDI15) => {
    const id = payload?.id;
    return api.put(`/case-svc/v1/tdi-15s/${id}`, payload, newCancelToken());
  };

  const activateUpdatePlan = (body: ActivatePlanPayload) =>
    api.patch(`/case-svc/v1/tdi-15s/${body.id}/active-update-plan`, body, newCancelToken());

  // ====================== TDI-Case ======================
  const getAllTDICases = (params) =>
    api.get(`/case-svc/v1/tdi-cases?${stringify(params)}`, {}, newCancelToken());

  const deleteTDICase = (id: string) =>
    api.delete(`/case-svc/v1/tdi-cases/${id}`, {}, newCancelToken());

  const getTDICaseDetail = (params: { id: string }) => {
    return api.get(`/case-svc/v1/tdi-cases/${params.id}`, {}, newCancelToken());
  };

  // ====================== Decisions ======================
  const getAllDecisions = (params: GetPropertiesParams) => {
    return api.get(`/case-svc/v1/decisions?${stringify(params)}`, {}, newCancelToken());
  };

  const deleteDecision = (id: string) => {
    return api.delete(`/case-svc/v1/decisions/${id}`, {}, newCancelToken());
  };

  // ======================  TDI Coverage ======================
  const getAllRelatedTDICoverages = (params: GetPropertiesParams) => {
    return api.get(`/case-svc/v1/tdi-coverages?${stringify(params)}`, {}, newCancelToken());
  };

  const deleteRelatedTDICoverage = (id: string) => {
    return api.delete(`/case-svc/v1/tdi-coverages/${id}`, {}, newCancelToken());
  };

  // ====================== Case Processing Log ======================
  const getAllCaseProcessingLogs = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/case-processing-logs?${stringify(params)}`, {}, newCancelToken());

  const deleteCaseProcessingLog = (id: string) =>
    api.delete(`/case-svc/v1/case-processing-logs/${id}`, {}, newCancelToken());

  const createCaseProcessingLog = (body: CaseProcessingLog) => {
    return api.post('/case-svc/v1/case-processing-logs', body, newCancelToken());
  };

  const editCaseProcessingLog = (body: CaseProcessingLog) => {
    const id = body.id;
    return api.put(`/case-svc/v1/case-processing-logs/${id}`, body, newCancelToken());
  };

  // ====================== Award Worksheets ======================
  const getAllAwardWorksheets = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/award-worksheets?${stringify(params)}`, {}, newCancelToken());

  const getAwardWorksheet = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/award-worksheets/${params.id}`, {}, newCancelToken());

  const deleteAwardWorksheets = (id: string) =>
    api.delete(`/case-svc/v1/award-worksheets/${id}`, {}, newCancelToken());

  const createAwardWorksheet = (body: AwardWorksheet) => {
    return api.post('/case-svc/v1/award-worksheets', body, newCancelToken());
  };

  const updateAwardWorksheet = (body: AwardWorksheet) =>
    api.put(`/case-svc/v1/award-worksheets/${body.id}`, body, newCancelToken());

  const calculateAwardWorksheet = (body: AwardWorksheet) =>
    api.put(`/case-svc/v1/award-worksheets/${body.id}/calculate`, body, newCancelToken());

  // ====================== Related Award Employments ======================

  const getAllRelatedAwardEmployments = (params: GetPropertiesParams) =>
    api.get(
      `/case-svc/v1/award-worksheets/${params.id}/award-employments?${stringify(params)}`,
      {},
      newCancelToken()
    );

  const createAwardEmployment = (body: AwardEmployment) =>
    api.post(
      `/case-svc/v1/award-worksheets/${body?.worksheetId}/award-employments`,
      body,
      newCancelToken()
    );

  const updateAwardEmployment = (body: AwardEmployment) =>
    api.put(`/case-svc/v1/award-worksheets/award-employments/${body.id}`, body, newCancelToken());

  const deleteAwardEmployment = (id: string) => {
    return api.delete(
      `/case-svc/v1/award-worksheets/award-employments/${id}`,
      {},
      newCancelToken()
    );
  };

  // ====================== PPD Injured Body Parts ======================
  const getAllPPDInjuredBodyParts = (params: GetPropertiesParams) => {
    const { id, ...others } = params;
    return api.get(
      `/case-svc/v1/award-worksheets/${id}/injured-body-parts?${stringify(others)}`,
      {},
      newCancelToken()
    );
  };

  const deletePPDInjuredBodyParts = (id: string) => {
    return api.delete(
      `/case-svc/v1/award-worksheets/injured-body-parts/${id}`,
      {},
      newCancelToken()
    );
  };

  const createPPDInjuredBodyParts = (body: PPDInjuredBodyPartsType) => {
    const { id } = body;
    return api.post(
      `/case-svc/v1/award-worksheets/${id}/injured-body-parts`,
      body,
      newCancelToken()
    );
  };

  const updatePPDInjuredBodyParts = (body: PPDInjuredBodyPartsType) => {
    const { id } = body;
    return api.put(
      `/case-svc/v1/award-worksheets/injured-body-parts/${id}`,
      body,
      newCancelToken()
    );
  };
  const getAllPeriodsOfTpd = (params: GetPropertiesParams) => {
    const { id, ...othersParams } = params;
    return api.get(
      `/case-svc/v1/award-worksheets/${id}/periods-of-tpd?${stringify(othersParams)}`,
      {},
      newCancelToken()
    );
  };
  // ====================== Periods Of Ttd ======================

  const getAllPeriodsOfTtd = (params: GetPropertiesParams) => {
    const { id, ...otherParams } = params;
    return api.get(
      `/case-svc/v1/award-worksheets/${id}/periods-of-ttd?${stringify(otherParams)}`,
      {},
      newCancelToken()
    );
  };

  // ====================== Dependents ======================
  const getAllRelatedDependents = (params: GetPropertiesParams) =>
    api.get(
      `/case-svc/v1/award-worksheets/${params.awwId}/death-dependents?${stringify(params)}`,
      {},
      newCancelToken()
    );

  const createDependent = (body: AwwDependent) =>
    api.post(
      `/case-svc/v1/award-worksheets/${body.worksheetId}/death-dependents?id=${body.worksheetId}`,
      body,
      newCancelToken()
    );

  const deleteDependent = (id: string) =>
    api.delete(`/case-svc/v1/award-worksheets/death-dependents/${id}`, {}, newCancelToken());

  const getDependent = (params: { id: string }) =>
    api.get(`/case-svc/v1/award-worksheets/death-dependents/${params.id}`, {}, newCancelToken());

  const updateDependent = (body: AwwDependent) =>
    api.put(`/case-svc/v1/award-worksheets/death-dependents/${body.id}`, body, newCancelToken());

  // ====================== GL Accounts Management ======================
  const getAllGLAccounts = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/gl-accounts?${stringify(params)}`, {}, newCancelToken());

  const deleteGLAccount = (accountId: string) =>
    api.delete(`/case-svc/v1/gl-accounts/${accountId}`, {}, newCancelToken());

  const getGLAccount = (params: { id: string }) =>
    api.get(`/case-svc/v1/gl-accounts/${params.id}`, {}, newCancelToken());

  const getAllRelatedGLAccounts = (params) =>
    api.get(
      `/case-svc/v1/gl-accounts/${params.id}/related?${stringify(params)}`,
      {},
      newCancelToken()
    );

  const deleteGLAccountRelated = (id: string) =>
    api.delete(`/case-svc/v1/gl-accounts/related/${id}`, {}, newCancelToken());

  const updateGLAccountRelated = (body: RelatedGLAccountPayload) => {
    return api.put(
      `/case-svc/v1/gl-accounts/related/${body.glAccountRelationId}`,
      body,
      newCancelToken()
    );
  };

  const createGLRelated = (body: RelatedGLAccountPayload) =>
    api.post(`/case-svc/v1/gl-accounts/${body.glAccountId}/related`, body, newCancelToken());

  // ====================== PHCCoverages ======================
  const getPHCCoverage = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/phc-coverages/${params.id}`, {}, newCancelToken());

  const getPHCCoverageDetail = (params: { id: string }) =>
    api.get(`/case-svc/v1/phc-coverages/${params.id}`, {}, newCancelToken());

  const getPHCCoverageLatestActive = (params: { employerId: string; hcPlanId: string }) =>
    api.get(`/case-svc/v1/phc-coverages/latest-active?${stringify(params)}`, {}, newCancelToken());

  const getAllPHCCoverages = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/phc-coverages?${stringify(params)}`, {}, newCancelToken());

  const createPHCCoverage = (body: PHCCoverage) =>
    api.post(`/case-svc/v1/phc-coverages`, body, newCancelToken());

  const updatePHCCoverage = (body: PHCCoverage) =>
    api.put(`/case-svc/v1/phc-coverages/${body.id}`, body, newCancelToken());

  const deletePHCCoverage = (id: string) => {
    return api.delete(`/case-svc/v1/phc-coverages/${id}`, {}, newCancelToken());
  };

  // ====================== Tracking Log ======================
  const getTrackingLog = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/tracking-logs/${params.id}`, {}, newCancelToken());

  const getAllTrackingLogs = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/tracking-logs?${stringify(params)}`, {}, newCancelToken());

  const createTrackingLog = (body: TrackingLog) =>
    api.post(`/case-svc/v1/tracking-logs`, body, newCancelToken());

  const updateTrackingLog = (body: TrackingLog) =>
    api.put(`/case-svc/v1/tracking-logs/${body.id}`, body, newCancelToken());

  const deleteTrackingLog = (id: string) => {
    return api.delete(`/case-svc/v1/tracking-logs/${id}`, {}, newCancelToken());
  };

  // ====================== Request for Reconsideration ======================
  const getAllRequestForReconsideration = (params: GetPropertiesParams) =>
    api.get(
      `/case-svc/v1/decisions/${params.id}/requests-for-reconsideration?${stringify(params)}`,
      {},
      newCancelToken()
    );

  const deleteRequestForReconsideration = (id: string) =>
    api.delete(`/case-svc/v1/decisions/requests-for-reconsideration/${id}`, {}, newCancelToken());

  const updateRequestForReconsideration = (body: RequestReconsiderationPayload) =>
    api.put(
      `/case-svc/v1/decisions/requests-for-reconsideration/${body.id}`,
      body,
      newCancelToken()
    );

  const getRequestForReconsideration = (params: { id: string }) =>
    api.get(
      `case-svc/v1/decisions/requests-for-reconsideration/${params.id}`,
      {},
      newCancelToken()
    );

  // ====================== TDIPlans  ======================
  const getAllTDIPlans = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/tdi-plans?${stringify(params)}`, {}, newCancelToken());

  const getTDIPlan = (params: { id: string }) => {
    return api.get(`/case-svc/v1/tdi-plans/${params?.id}`, {}, newCancelToken());
  };

  const deleteTDIPlan = (id: string) => {
    return api.delete(`/case-svc/v1/tdi-plans/${id}`, {}, newCancelToken());
  };

  //====================== Hearing Related Party Names Address  ======================
  const getAllRelatedPartyNamesAddress = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/case-hearing-parties?${stringify(params)}`, {}, newCancelToken());

  const getRelatedPartyNamesAddress = (params: { id: string }) => {
    return api.get(`/case-svc/v1/case-hearing-parties/${params.id}`, {}, newCancelToken());
  };

  const createRelatedPartyNamesAddress = (payload: PartyNameAndAddressType) => {
    return api.post(
      `/case-svc/v1/case-hearing-parties`,
      {
        ...payload,
      },
      newCancelToken()
    );
  };

  const updateRelatedPartyNamesAddress = (payload: PartyNameAndAddressType) => {
    return api.put(
      `/case-svc/v1/case-hearing-parties/${payload?.id}`,
      {
        ...payload,
      },
      newCancelToken()
    );
  };

  const addExistedParties = (body: ExistedParties) => {
    return api.post(
      `/case-svc/v1/case-hearing-parties/add-existed-parties`,
      {
        ...body,
      },
      newCancelToken()
    );
  };

  const deletePartyNamesAddress = (id: string) => {
    return api.delete(`/case-svc/v1/case-hearing-parties/${id}`, {}, newCancelToken());
  };

  const getExistedParties = (params: { id: string }) => {
    return api.get(
      `/case-svc/v1/case-hearings/${params.id}/existing-case-parties`,
      {},
      newCancelToken()
    );
  };

  const createRequestForReconsideration = (body: RequestReconsiderationPayload) => {
    const { decisionId } = body;
    return api.post(
      `case-svc/v1/decisions/${decisionId}/requests-for-reconsideration`,
      body,
      newCancelToken()
    );
  };

  //====================== Decision Related Party Names Address  ======================
  const getAllDecisionRelatedPartyNamesAddress = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/decision-parties?${stringify(params)}`, {}, newCancelToken());

  const getDecisionRelatedPartyNamesAddress = (params: { id: string }) => {
    return api.get(`/case-svc/v1/decision-parties/${params.id}`, {}, newCancelToken());
  };

  const createDecisionRelatedPartyNamesAddress = (payload: DecisionPartyNameAndAddressType) => {
    return api.post(
      `/case-svc/v1/decision-parties`,
      {
        ...payload,
      },
      newCancelToken()
    );
  };

  const updateDecisionRelatedPartyNamesAddress = (payload: PartyNameAndAddressType) => {
    return api.put(
      `/case-svc/v1/decision-parties/${payload?.id}`,
      {
        ...payload,
      },
      newCancelToken()
    );
  };

  const addDecisionExistedParties = (body: ExistedPartiesDecision) => {
    return api.post(
      `/case-svc/v1/decision-parties/existing-parties`,
      {
        ...body,
      },
      newCancelToken()
    );
  };

  const deleteDecisionPartyNamesAddress = (id: string) => {
    return api.delete(`/case-svc/v1/decision-parties/${id}`, {}, newCancelToken());
  };

  const getExistedPartiesDecision = (params: { id: string }) => {
    return api.get(
      `/case-svc/v1/decisions/${params.id}/existing-case-parties`,
      {},
      newCancelToken()
    );
  };

  const createTDIPlan = (body: TDIPlan) =>
    api.post(`/case-svc/v1/tdi-plans`, body, newCancelToken());

  const calculateEquivalency = (params: CalculateEquivalencyPayload) => {
    const { enabled, ...rest } = params;

    return api.get(
      `case-svc/v1/tdi-plans/equivalency-value?${stringify(rest)}`,
      {},
      newCancelToken()
    );
  };

  const updateTDIPlan = (body: TDIPlan) =>
    api.put(`/case-svc/v1/tdi-plans/${body.id}`, body, newCancelToken());

  //====================== TDI Coverages  ======================
  const createTDICoverage = (body: TDICoverage) =>
    api.post(`/case-svc/v1/tdi-coverages`, body, newCancelToken());

  const updateTDICoverage = (body: TDICoverage) =>
    api.put(`/case-svc/v1/tdi-coverages/${body.id}`, body, newCancelToken());

  const getTDICoverage = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/tdi-coverages/${params.id}`, {}, newCancelToken());

  // ====================== TDI Cases ======================
  const getTDICase = (params: { id: string }) =>
    api.get(`case-svc/v1/tdi-cases/${params.id}`, {}, newCancelToken());

  const updateTDICase = (body: TDICase) =>
    api.put(`/case-svc/v1/tdi-cases/${body.id}`, body, newCancelToken());

  const getAllPotentialAssociated = (params: { id: string }) =>
    api.get(
      `case-svc/v1/tdi-cases/${params.id}/potential-associated-wc-cases`,
      {},
      newCancelToken()
    );

  const updatePotentialAssociated = (body: PotentialAssociated) =>
    api.put(
      `/case-svc/v1/tdi-cases/potential-associated-wc-cases/${body.id}`,
      body,
      newCancelToken()
    );

  // ====================== Decisions ======================
  const getDecision = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/decisions/${params.id}`, {}, newCancelToken());

  const getDecisions = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/decisions?${stringify(params)}`, {}, newCancelToken());

  const createDecision = (body: DecisionPayLoad) =>
    api.post(`/case-svc/v1/decisions`, body, newCancelToken());

  const updateDecision = (body: DecisionPayLoad) =>
    api.put(`/case-svc/v1/decisions/${body.id}`, body, newCancelToken());

  const updateDecisionText = (body: DecisionTextPayLoad) =>
    api.put(`/case-svc/v1/decisions/${body.id}/text-details`, body, newCancelToken());

  // ====================== TDI SF Cases ======================
  const getAllTDISFCases = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/tdi-sf-cases?${stringify(params)}`, {}, newCancelToken());

  const getTDISFCase = (params: { id: string }) =>
    api.get(`case-svc/v1/tdi-sf-cases/${params.id}`, {}, newCancelToken());

  const updateTDISFCaseStatus = (body: TDISFCaseStatusPayload) => {
    const { id, caseStatus } = body;
    return api.patch(`/case-svc/v1/tdi-sf-cases/${id}/status`, { caseStatus }, newCancelToken());
  };

  const createTDISFCase = (body: TDISFCase) =>
    api.post(`case-svc/v1/tdi-sf-cases`, body, newCancelToken());

  const editTDISFCase = (body: TDISFCase) =>
    api.put(`case-svc/v1/tdi-sf-cases/${body.id}`, body, newCancelToken());

  const deleteTDISFCase = (id: string) => {
    return api.delete(`/case-svc/v1/tdi-sf-cases/${id}`, {}, newCancelToken());
  };

  const createTDISFCaseByTDI30 = (body: { tdi30Id: string }) =>
    api.post(`case-svc/v1/tdi-sf-cases/based-on-tdi-30`, body, newCancelToken());

  //====================== TDI-46s ======================
  const updateTDI46 = (body: TDI46) =>
    api.put(`/case-svc/v1/tdi-46s/${body.id}`, body, newCancelToken());

  const getTDI46 = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/tdi-46s/${params.id}`, {}, newCancelToken());

  //====================== TDI-30s ======================
  const getTDI30 = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/tdi-30s/${params.id}`, {}, newCancelToken());

  const createTDI30 = (body: TDI30) => api.post('/case-svc/v1/tdi-30s', body, newCancelToken());

  const updateTDI30 = (payload: TDI30) => {
    const id = payload?.id;
    return api.put(`/case-svc/v1/tdi-30s/${id}`, payload, newCancelToken());
  };

  const getAllTDI30 = (params) =>
    api.get(`/case-svc/v1/tdi-30s?${stringify(params)}`, {}, newCancelToken());

  const deleteTDI30 = (id: string) =>
    api.delete(`/case-svc/v1/tdi-30s/${id}`, {}, newCancelToken());

  //====================== HEALTH CARE PLAN ======================
  const getHCP = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/health-care-plans/${params.id}`, {}, newCancelToken());

  const getAllHCPs = (params) =>
    api.get(`/case-svc/v1/health-care-plans?${stringify(params)}`, {}, newCancelToken());

  const createHCP = (body: HealthCarePlan) =>
    api.post(`/case-svc/v1/health-care-plans`, body, newCancelToken());

  const deleteHCP = (id: string) =>
    api.delete(`/case-svc/v1/health-care-plans/${id}`, {}, newCancelToken());

  const updateHCP = (body: HealthCarePlan) =>
    api.put(`/case-svc/v1/health-care-plans/${body.id}`, body, newCancelToken());

  // ===========================HC-4s==============================
  const updateHC4 = (body: HC4) => api.put(`/case-svc/v1/hc-4s/${body.id}`, body, newCancelToken());

  const createHC4 = (body: HC4) => api.post(`/case-svc/v1/hc-4s`, body, newCancelToken());

  const getAllHC4 = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/hc-4s?${stringify(params)}`, {}, newCancelToken());

  const getHC4 = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/hc-4s/${params.id}`, {}, newCancelToken());

  const deleteHC4 = (id: string) => api.delete(`/case-svc/v1/hc-4s/${id}`, {}, newCancelToken());

  // =========================== HC-15s ==============================
  const getAllHC15 = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/hc-15s?${stringify(params)}`, {}, newCancelToken());

  const deleteHC15 = (id: string) => {
    return api.delete(`/case-svc/v1/hc-15s/${id}`, {}, newCancelToken());
  };

  const updateHC15 = (body: HC15) =>
    api.put(`/case-svc/v1/hc-15s/${body.id}`, body, newCancelToken());

  const getHC15 = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/hc-15s/${params.id}`, {}, newCancelToken());

  const acceptRejectHC15Action = (body: HC15) =>
    api.patch(`/case-svc/v1/hc-15s/${body.id}/accept-reject-action`, body, newCancelToken());

  // ====================== Coverage Equivalency ======================
  const getCoverageEquivalency = (params: GetPropertiesParams) =>
    api.get(`case-svc/v1/coverage-equivalencies?${stringify(params)}`, {}, newCancelToken());

  const updateCoverageEquivalency = (body: CoverageEquivalencyRelated) =>
    api.put(`/case-svc/v1/coverage-equivalencies`, body, newCancelToken());

  // ====================== Select HRS/HAR ======================
  const getAllHRSs = (params: GetPropertiesParams) => {
    const { hearingId } = params;

    return api.get(
      `case-svc/v1/case-hearings/${hearingId}/hrs-hars?${stringify(params)}`,
      {},
      newCancelToken()
    );
  };

  const updateSelectedHRS = (body: HrsHarPayload) => {
    const { hearingId } = body;

    return api.put(`case-svc/v1/case-hearings/${hearingId}/hrs-hars`, body, newCancelToken());
  };

  const deleteHRS = (id: string) =>
    api.delete(`case-svc/v1/case-hearings/hrs-hars/${id}`, {}, newCancelToken());

  // ====================== Request ======================
  const getAllRequests = (params) =>
    api.get(`/case-svc/v1/requests?${stringify(params)}`, {}, newCancelToken());

  const deleteRequest = (id: string) =>
    api.delete(`/case-svc/v1/requests/${id}`, {}, newCancelToken());

  const createRequest = (body: Request) =>
    api.post(`/case-svc/v1/requests`, body, newCancelToken());

  const updateRequest = (body: Request) =>
    api.put(`/case-svc/v1/requests/${body.id}`, body, newCancelToken());

  const getRequest = (params: { id: string }) =>
    api.get(`case-svc/v1/requests/${params.id}`, {}, newCancelToken());

  // ====================== HC-61s ======================
  const getAllHC61s = (params) =>
    api.get(`/case-svc/v1/hc-61s?${stringify(params)}`, {}, newCancelToken());

  const deleteHC61 = (id: string) => api.delete(`/case-svc/v1/hc-61s/${id}`, {}, newCancelToken());

  const createHC61 = (body: HC61) => api.post(`/case-svc/v1/hc-61s`, body, newCancelToken());

  const updateHC61 = (body: HC61) =>
    api.put(`/case-svc/v1/hc-61s/${body.id}`, body, newCancelToken());

  const activateUpdatePlanHC61 = (body: ActivatePlanHC61Payload) =>
    api.patch(`/case-svc/v1/hc-61s/${body.id}/activate-update-plan`, body, newCancelToken());

  const getHC61 = (params: { id: string }) =>
    api.get(`case-svc/v1/hc-61s/${params.id}`, {}, newCancelToken());

  // ====================== Complaints ======================
  const getAllComplaint = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/complaints?${stringify(params)}`, {}, newCancelToken());

  const getComplaint = (params: { id: string }) =>
    api.get(`case-svc/v1/complaints/${params.id}`, {}, newCancelToken());

  const deleteComplaint = (id: string) =>
    api.delete(`/case-svc/v1/complaints/${id}`, {}, newCancelToken());

  const createComplaint = (body: Complaint) =>
    api.post(`/case-svc/v1/complaints`, body, newCancelToken());

  const updateComplaint = (body: Complaint) =>
    api.put(`/case-svc/v1/complaints/${body.id}`, body, newCancelToken());

  // ===========================Employer Audits==============================
  const getAllEmployerAudits = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/employer-audits?${stringify(params)}`, {}, newCancelToken());

  const deleteEmployerAudit = (id: string) =>
    api.delete(`/case-svc/v1/employer-audits/${id}`, {}, newCancelToken());

  const getEmployerAudit = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/employer-audits/${params.id}`, {}, newCancelToken());

  const createEmployerAudit = (body: EmployerAudit) =>
    api.post(`/case-svc/v1/employer-audits`, body, newCancelToken());

  const updateEmployerAudit = (body: EmployerAudit) =>
    api.put(`/case-svc/v1/employer-audits/${body.id}`, body, newCancelToken());

  const getAllUploadFiles = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/uploaded-files?${stringify(params)}`, {}, newCancelToken());

  // ===========================Routing Information==============================
  const createRoutingInformation = (body: RelatedPartyNamesAddressPayLoad) =>
    api.post(`/case-svc/v1/routing-information`, body, newCancelToken());

  const getAllRoutingInformation = (params) =>
    api.get(`case-svc/v1/routing-information?${stringify(params)}`, {}, newCancelToken());

  const deleteRoutingInformation = (id: string) => {
    return api.delete(`/case-svc/v1/routing-information/${id}`, {}, newCancelToken());
  };

  // ===========================Custom Settings==============================
  const getCustomSetting = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/settings/${params.tab}`, {}, newCancelToken());

  const updateCustomSettings = (body: CustomSettings) => {
    return api.put(`/case-svc/v1/settings/${body.tab}`, body, newCancelToken());
  };
  // =========================== Reports ==============================
  const getReports = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/reports?${stringify(params)}`, {}, newCancelToken());

  const editReport = (body: Report) =>
    api.put(`/case-svc/v1/reports/${body.id}`, body, newCancelToken());

  const getTDI21sAnnualReportsNotFiled = (params: GetPropertiesParams) =>
    api.get(
      `/case-svc/v1/reports/TDI21sAnnualReportsNotFiled?${stringify(params)}`,
      {},
      newCancelToken()
    );

  const getTDI21sAnnualExceptionReport = (params: GetPropertiesParams) =>
    api.get(
      `/case-svc/v1/reports/TDI21sAnnualException?${stringify(params)}`,
      {},
      newCancelToken()
    );

  const getTDI21sAnnualReportSummaryPlanType = (params: GetPropertiesParams) =>
    api.get(
      `/case-svc/v1/reports/TDI21sAnnualReportSummaryByPlanType?${stringify(params)}`,
      {},
      newCancelToken()
    );

  const getTDI21sAnnualDetail = (params: GetPropertiesParams) =>
    api.get(
      `/case-svc/v1/reports/TDI21sAnnualReportSummaryByPlanType/details?${stringify(params)}`,
      {},
      newCancelToken()
    );

  const getNonCompliantEmployerInsuranceReport = (params: GetPropertiesParams) =>
    api.get(
      `/case-svc/v1/reports/NonCompliantEmployerInsurance?${stringify(params)}`,
      {},
      newCancelToken()
    );

  const getNonCompliantWCEmployerInsuranceReport = (params: GetPropertiesParams) =>
    api.get(
      `case-svc/v1/reports/NonCompliantWCEmployerInsurance?${stringify(params)}`,
      {},
      newCancelToken()
    );

  const getRejectedSelfInsuredHCPReport = (params: GetPropertiesParams) =>
    api.get(
      `/case-svc/v1/reports/RejectedPHCSelfInsurance?${stringify(params)}`,
      {},
      newCancelToken()
    );

  const getApprovedHealthCarePlansReport = (params: GetPropertiesParams) =>
    api.get(
      `/case-svc/v1/reports/ApprovedHealthCarePlans?${stringify(params)}`,
      {},
      newCancelToken()
    );

  const getRejectedHCPDetail = (params: GetPropertiesParams) =>
    api.get(
      `/case-svc/v1/reports/RejectedPHCSelfInsurance/details?${stringify(params)}`,
      {},
      newCancelToken()
    );

  const getApprovedHealthCarePlansDetail = (params: GetPropertiesParams) =>
    api.get(
      `/case-svc/v1/reports/ApprovedHealthCarePlans/details?${stringify(params)}`,
      {},
      newCancelToken()
    );

  const getTDIAndPHCPhysicalFilePurgeReport = (params: GetPropertiesParams) =>
    api.get(
      `case-svc/v1/reports/TDIAndPHCPhysicalFilePurge?${stringify(params)}`,
      {},
      newCancelToken()
    );

  const getHC15sReport = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/reports/HC15s?${stringify(params)}`, {}, newCancelToken());

  const getHC15sReportDetail = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/reports/HC15s/details?${stringify(params)}`, {}, newCancelToken());

  const getTDI15sTDISelfInsurerPlanReport = (params: GetPropertiesParams) =>
    api.get(
      `/case-svc/v1/reports/TDI15sTDISelfInsurerPlanReport?${stringify(params)}`,
      {},
      newCancelToken()
    );

  const getTDI15SelfInsurerDetail = (params: GetPropertiesParams) =>
    api.get(
      `/case-svc/v1/reports/TDI15sTDISelfInsurerPlanReport/details?${stringify(params)}`,
      {},
      newCancelToken()
    );

  const getEmployerRegistrationActivities = (params: GetPropertiesParams) =>
    api.get(
      `/case-svc/v1/reports/EmployerRegistrationActivities?${stringify(params)}`,
      {},
      newCancelToken()
    );

  const getEmployerRegistrationActivitiesDetail = (params: GetPropertiesParams) =>
    api.get(
      `/case-svc/v1/reports/EmployerRegistrationActivities/details?${stringify(params)}`,
      {},
      newCancelToken()
    );

  const getTDI46ReconsiderationAppealsReport = (params: GetPropertiesParams) =>
    api.get(
      `/case-svc/v1/reports/TDI46ReconsiderationsAppeals?${stringify(params)}`,
      {},
      newCancelToken()
    );

  const getTDI46ReconsiderationAppealsDetail = (params: GetPropertiesParams) =>
    api.get(
      `/case-svc/v1/reports/TDI46ReconsiderationsAppeals/details?${stringify(params)}`,
      {},
      newCancelToken()
    );

  const getTDI62sReport = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/reports/TDI62s?${stringify(params)}`, {}, newCancelToken());

  const getTDI62sReportDetail = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/reports/TDI62s/details?${stringify(params)}`, {}, newCancelToken());

  const getTDI30sReport = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/reports/TDI30s?${stringify(params)}`, {}, newCancelToken());

  const getTDI30sReportDetail = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/reports/TDI30s/details?${stringify(params)}`, {}, newCancelToken());

  const getTDI21NumberOfEmployeesReport = (params: GetPropertiesParams) =>
    api.get(
      `case-svc/v1/reports/TDI21NumberOfEmployees?${stringify(params)}`,
      {},
      newCancelToken()
    );

  //====================== Portal User  ======================
  const getPortalUser = (params: { id: string }) =>
    api.get(`case-svc/v1/portal/users/${params.id}`, {}, newCancelToken());

  const getAllPortalUser = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/portal/users?${stringify(params)}`, {}, newCancelToken());

  const deletePortalUser = (id: string) => {
    return api.delete(`/case-svc/v1/portal/users/${id}`, {}, newCancelToken());
  };

  const getAllPortalUserAuditHistories = (params: GetPropertiesParams) => {
    const { portalUserId, ...queryString } = params;

    return api.get(
      `/case-svc/v1/portal/users/${portalUserId}/audit-history?${stringify(queryString)}`,
      {},
      newCancelToken()
    );
  };
  const updatePortalUser = (body: PortalUserPayload) => {
    const { id, ...payload } = body;
    return api.put(`/case-svc/v1/portal/users/${id}`, payload, newCancelToken());
  };

  const getAllRelatedAccountPortalUsers = ({ id, ...params }: GetPropertiesParams) =>
    api.get(`/case-svc/v1/accounts/${id}/portal-users?${stringify(params)}`, {}, newCancelToken());

  const getAllWC14s = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/wc-14s?${stringify(params)}`, {}, newCancelToken());

  const deleteWC14 = (id: string) => api.delete(`/case-svc/v1/wc-14s/${id}`, {}, newCancelToken());

  const getWC14Detail = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/wc-14s/${params.id}`, {}, newCancelToken());

  const createWC14 = (body: WC14) => {
    return api.post('/case-svc/v1/wc-14s', body, newCancelToken());
  };

  const updateWC14 = (body: WC14) => {
    const id = body.id;
    return api.put(`/case-svc/v1/wc-14s/${id}`, body, newCancelToken());
  };

  //====================== WC77 ======================

  const createWC77 = (body: WC77) => api.post(`/case-svc/v1/wc-77s`, body, newCancelToken());

  const updateWC77 = (body: WC77) =>
    api.put(`/case-svc/v1/wc-77s/${body.id}`, body, newCancelToken());

  const getWC77 = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/wc-77s/${params.id}`, {}, newCancelToken());

  const getAllWC77s = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/wc-77s?${stringify(params)}`, {}, newCancelToken());

  const deleteWC77 = (id: string) => api.delete(`/case-svc/v1/wc-77s/${id}`, {}, newCancelToken());

  //====================== WC21 ======================
  const getWC21Detail = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/wc-21s/${params.id}`, {}, newCancelToken());

  const getAllWC21s = (params: GetPropertiesParams) =>
    api.get(`/case-svc/v1/wc-21s?${stringify(params)}`, {}, newCancelToken());

  const deleteWC21 = (id: string) => api.delete(`/case-svc/v1/wc-21s/${id}`, {}, newCancelToken());

  const updateWC21 = (body: WC14) => {
    const id = body.id;
    return api.put(`/case-svc/v1/wc-21s/${id}`, body, newCancelToken());
  };

  // Return back a collection of functions that we would consider our
  // interface.  Most of the time it'll be just the list of all the
  //
  // Notice we're not returning back the `api` created in step 1. That's
  // because it is scoped privately.  This is one way to create truly
  // private scoped goodies in JavaScript.
  //
  return {
    getRoot,
    // ====================== Auth ======================
    // getPermission,
    confirmSignIn,
    signIn,

    signOut,
    forgotPassword,
    submitForgotPassword,
    changePassword,
    // setPreferredMfa,
    completeNewPassword,

    // ====================== File ======================
    getPresignedUserServiceUrl,
    uploadFile,
    uploadFileWithProgress,
    getDecodeUserServiceUrl,
    getLanguages,
    getLocale,

    // ====================== Content ======================
    getContents,

    // ====================== Users ======================
    getUserId,

    // ====================== Profile ======================
    getMyProfile,
    updateMyProfile,
    updateUserAvatar,
    confirmPassword,
    getPresignedDownloadAvatarUrl,
    getPresignedUploadAvatarUrl,

    // ====================== System Accounts ======================
    searchUserAccounts,
    searchUserAccountsAxios,
    searchUserAccountsByOrderAxios,

    // ====================== System Accounts ======================
    getMyPermissions,

    // ====================== Users Management ======================
    getAllUsers,
    getUser,
    createUser,
    deleteUser,
    searchUsers,
    searchProjects,
    updateUser,
    editUserStatus,

    // ====================== Accounts Management ======================
    getAllAccounts,
    deleteAccount,
    getAccount,
    getAccountHierarchy,

    // ====================== Users Permissions ======================
    getAllProfile,
    getProfile,
    createProfile,
    getAssignedUsers,
    deleteProfile,
    cloneProfile,
    editProfile,
    assignUser,
    unAssignUser,
    getSystemPermissions,
    updateSystemPermissions,

    resetUsersPassword,

    getObjectPermission,
    editObjectPermission,

    getFieldPermission,
    editFieldPermission,

    // ====================== Groups Management ======================
    getAllGroup,
    getUsersFromGroup,
    addUsersToGroup,
    removeUsersFromGroup,
    editAppPermissions,
    getAppPermissions,
    updateSendEmailGroup,

    // ====================== Account ======================
    createAccount,
    updateAccount,
    notifyBouncedCheckAccount,
    getAllEmployerComments,
    createEmployerComment,
    updateEmployerComment,
    deleteEmployerComment,

    // ====================== Contact ======================
    getContact,
    createContact,
    updateContact,
    deleteContact,
    getAllRelatedContacts,

    // ====================== Address History ======================
    getAddressHistories,
    getAddressHistory,
    createAddressHistory,
    updateAddressHistory,
    deleteAddressHistory,

    // ====================== Address ======================
    getAllAddress,

    // ====================== Contacts ======================
    getAllContacts,

    getAllRelatedAccountContact,

    createRelatedAccountContact,
    deleteRelatedAccountContact,
    editRelatedAccountContact,
    getRelatedAccountContact,

    // ====================== DCD Cases ======================
    getAllDCDCases,
    deleteDCDCase,
    updateDCDCase,
    getDCDCase,
    submitUploadFile,
    getPresignedUploadFile,
    getPresignedDownloadFileUrl,
    markDoneUploadFile,
    updateDCDStatus,
    updateDCDCaseStatus,
    holdDisbursement,
    releaseDisbursement,
    getLedgers,
    deleteLedger,
    changeControlCase,
    transferCaseLocation,
    getAllOrders,
    getOrder,
    deleteOrder,
    createOrder,
    updateOrder,
    getScheduleOfDisbursements,
    deleteScheduleOfDisbursement,
    createScheduleOfDisbursement,
    editScheduleOfDisbursement,
    getCasePaymentYears,
    getCasePartiesAndAddresses,
    getDisfigurementBodyParts,
    sendDisfigurementEmail,

    // ======================DCD Case Related Hearings ======================
    getAllRelatedHearings,
    deleteRelatedHearing,
    createRelatedHearing,
    editRelatedHearing,

    // ====================== Email ======================
    sendEmail,
    getPresignedUrlCaseService,

    // ====================== Notifications ======================
    getAllNotifications,
    readNotification,
    markAllReadNotifications,

    // ====================== WC1 Form ======================
    getAllWC1Forms,
    deleteWC1Form,

    // ====================== WC2 Form ======================
    getAllWC2Forms,
    deleteWC2Form,

    // ====================== WC3A Form ======================
    getAllWC3AForms,

    // ====================== WC5 Form ======================
    getAllWC5Forms,
    createWC5Form,

    // ====================== WC1 ======================
    updateWC1Status,
    getAllWC1s,
    getWC1Detail,
    updateWC1,

    // ====================== WC2s ======================
    createWC2sForm,

    // ====================== WC5As ======================
    createWC5AForm,

    // ====================== WC3A ======================
    getWC3ADetail,
    updateWC3A,
    getAllWC3As,
    deleteWC3As,
    createWC3AForm,
    getAllHistoryPriorPayments,

    // ====================== WC5A ======================
    getAllWC5As,
    deleteWC5A,
    getWC5ADetail,
    updateWC5A,

    // ====================== WCInsurancePolicies ======================
    createWCIP,
    getAllWCIP,
    deleteWCIP,
    getWCIPDetail,
    updateWCIP,

    // ====================== Approval History ======================
    getApprovalHistoryDetail,

    // ====================== Rooms ======================
    getRooms,

    // ====================== CaseSettlement ======================
    getCaseSettlementDetail,
    updateCaseSettlementDetails,

    // ====================== WC-3s ======================
    getAllWC3,
    deleteWC3,
    getWC3,
    updateWC3,
    getAllBenefitPaymentSummaries,
    deleteBenefitPaymentSummary,
    updateBenefitPaymentSummary,
    createWC3Historical,
    createBenefitPaymentSummary,
    createFinalReport,

    // ====================== Injury/Illness ======================
    getAllRelatedInjuryIllness,
    deleteRelatedInjuryIllness,
    createRelatedInjuryIllness,
    editRelatedInjuryIllness,
    getInjuryIllnessPartBodyCode,

    // ====================== Case Dependents ======================
    getAllRelatedCaseDependents,
    deleteRelatedCaseDependent,
    createRelatedCaseDependent,

    // ====================== Vendors ======================
    getAllRelatedVendors,
    deleteRelatedVendor,
    createRelatedVendor,
    updateRelatedVendor,

    // ====================== TDI-62 ======================
    getAllTDI62,
    deleteTDI62,
    getTDI62Detail,
    acceptRejectAction,
    editTDI62,
    getTDI62RelatedRelationships,

    // ====================== TDI-46 ======================
    getAllTDI46,
    deleteTDI46,
    getTDI46Detail,
    updateTDI46,
    getTDI46,

    // ====================== TDI-30s ======================
    getTDI30,
    createTDI30,
    updateTDI30,
    getAllTDI30,
    deleteTDI30,

    // ====================== Health Care Plan ======================
    getHCP,
    getAllHCPs,
    deleteHCP,
    createHCP,
    updateHCP,

    // ====================== TDI-15s ======================
    getAllTDI15,
    deleteTDI15,
    getTDI15Detail,
    createTDI15,
    updateTDI15,
    activateUpdatePlan,

    // ====================== TDI-Case ======================
    getAllTDICases,
    deleteTDICase,
    getTDICaseDetail,

    // ====================== Hearing Required Documents ======================
    getAllRelatedHearingRequiredDocuments,
    deleteRelatedHearingRequiredDocuments,
    createRelatedHearingRequiredDocuments,
    updateRelatedHearingRequiredDocuments,

    // ====================== Hearing Related Notes ======================
    getAllHearingRelatedNotes,
    getHearingRelatedNote,
    deleteHearingRelatedNote,
    removeHearingRelatedNote,
    createHearingRelatedNote,
    updateHearingRelatedNote,
    shareHearingRelatedNote,

    // ====================== Benefit Payments ======================
    getAllBenefitPayments,
    deleteBenefitPayment,
    createBenefitPayment,
    editBenefitPayment,

    // ====================== WC2 ======================
    getWC2Detail,
    updateWC2,
    getAllWC2,
    deleteWC2,

    // ====================== WC5 ======================
    getWC5Detail,
    updateWC5,
    getAllWC5,
    deleteWC5,

    // ====================== Case Settlements ======================
    getAllCaseSettlements,
    deleteCaseSettlement,
    createCaseSettlement,
    editCaseSettlement,

    // ====================== Receipts ======================
    getAllReceipts,
    deleteRelatedReceipt,
    createReceipt,
    updateReceipt,
    getReceipt,
    notifyFiscalReceipt,
    notifyBouncedCheckReceipt,

    // ====================== Disbursements ======================
    getAllDisbursements,
    deleteDisbursement,
    getDisbursement,
    postDisbursement,
    setPaymentReminder,
    releasePaymentReminder,
    createDisbursement,
    updateDisbursement,
    notifyFiscalDisbursement,

    // ====================== Prehearing for employer ======================
    getAllRelatedPrehearingForEmployer,
    createPrehearingForEmployer,
    updatePrehearingForEmployer,
    getPrehearingForEmployer,
    deletePrehearingForEmployer,

    // ====================== Status history ======================
    getAllRelatedCaseStatusHistory,
    deleteCaseStatusHistory,

    // ====================== Associated Cases ======================
    getAllRelatedAssociatedCases,
    deleteAssociatedCases,

    // ====================== Related Approval History ======================
    getAllRelatedApprovalHistory,
    updateHistoryStatus,

    // ====================== Related Party History Archives ======================
    getAllHearingRelatedPartyArchives,
    deleteHearingRelatedPartyArchive,
    getAllDecisionRelatedPartyArchives,
    deleteDecisionRelatedPartyArchive,

    // ====================== Vocational Rehabilitation Process ======================
    getAllVocationalRehabilitation,
    deleteVocationalRehabilitation,
    createVocationalRehabilitation,
    editVocationalRehabilitation,
    getVocationalRehabilitation,

    // ====================== Case Award ======================
    deleteCaseAward,
    getAllRelatedCaseAwards,
    getCaseAward,
    createCaseAward,
    updateCaseAward,

    //====================== Hearing Related Party Names Address  ======================
    getAllRelatedPartyNamesAddress,
    getRelatedPartyNamesAddress,
    createRelatedPartyNamesAddress,
    updateRelatedPartyNamesAddress,
    addExistedParties,
    deletePartyNamesAddress,
    getExistedParties,

    // ====================== VR Provider ======================
    getVRProviders,
    deleteVRProvider,
    createVRProvider,
    editVRProvider,

    // ====================== Employment At Time Of Injury/Illness ======================
    getEmploymentAtTimeOfII,
    deleteEmploymentAtTimeOfII,
    createEmploymentAtTimeOfII,
    editEmploymentAtTimeOfII,

    // ======================Related WC Case Party Names Address ======================
    getAllWCCaseParty,
    getWCCaseParty,
    updateWCCaseParty,
    deleteWCCaseParty,
    createWCCaseParty,
    getAllCasePartyActiveHistory,

    // ====================== Related Type Purposes======================
    getAllRelatedTypePurposes,
    getRelatedTypePurposes,
    deleteRelatedTypePurposes,
    updateRelatedTypePurposes,
    createRelatedTypePurposes,
    getTypePurposes,
    getPurposes,

    // ====================== WC-Coverages ======================
    getAllWCCoverages,
    createWCCoverage,
    editWCCoverage,
    deleteWCCoverage,

    // ====================== Hearing ======================
    getAllHearings,
    getHearingDetail,
    deleteHearing,
    updateHearing,
    createHearingSchedule,
    getHearingDecisions,
    deleteHearingDecision,
    getAllRooms,
    getHearingSchedule,
    getScheduleAvailability,

    // ====================== GL Account ======================
    getAllGLAccounts,
    deleteGLAccount,
    getGLAccount,
    createGLAccount,
    updateGLAccount,
    getAllRelatedGLAccounts,
    deleteGLAccountRelated,
    updateGLAccountRelated,
    createGLRelated,

    // ====================== Award Worksheets ======================
    getAllAwardWorksheets,
    getAwardWorksheet,
    updateAwardWorksheet,
    getAllPeriodsOfTpd,
    deleteAwardWorksheets,
    getAllPeriodsOfTtd,
    createAwardWorksheet,
    calculateAwardWorksheet,

    // ====================== PPD Injured Body Parts ======================
    getAllPPDInjuredBodyParts,
    deletePPDInjuredBodyParts,
    createPPDInjuredBodyParts,
    updatePPDInjuredBodyParts,

    // ====================== Related Award Employments ======================
    getAllRelatedAwardEmployments,
    createAwardEmployment,
    updateAwardEmployment,
    deleteAwardEmployment,

    // ====================== Dependents ======================
    getAllRelatedDependents,
    createDependent,
    deleteDependent,
    getDependent,
    updateDependent,

    // ====================== Decisions ======================
    getDecision,
    createDecision,
    updateDecision,
    getDecisions,
    updateDecisionText,

    // ====================== Request for Reconsideration ======================
    getAllRequestForReconsideration,
    createRequestForReconsideration,
    deleteRequestForReconsideration,
    updateRequestForReconsideration,
    getRequestForReconsideration,

    // ====================== Decisions Related Party Names And Address ======================
    getAllDecisionRelatedPartyNamesAddress,
    getDecisionRelatedPartyNamesAddress,
    createDecisionRelatedPartyNamesAddress,
    updateDecisionRelatedPartyNamesAddress,
    addDecisionExistedParties,
    deleteDecisionPartyNamesAddress,
    getExistedPartiesDecision,

    // ====================== Case Processing Log ======================
    getAllCaseProcessingLogs,
    deleteCaseProcessingLog,
    createCaseProcessingLog,
    editCaseProcessingLog,

    // ====================== Prepare Expenditure Vouchers ======================
    prepareExpenditureVouchers,

    // ====================== Decisions ======================
    getAllDecisions,
    deleteDecision,

    // ====================== TDI Coverage ======================
    getAllRelatedTDICoverages,
    deleteRelatedTDICoverage,
    createTDICoverage,
    updateTDICoverage,
    getTDICoverage,

    // ====================== Tdi Plans ======================
    getTDIPlan,
    getAllTDIPlans,
    deleteTDIPlan,
    createTDIPlan,
    updateTDIPlan,
    calculateEquivalency,

    // ====================== Coverage Equivalency ======================
    getCoverageEquivalency,
    updateCoverageEquivalency,

    // ====================== TDI Cases ======================
    getTDICase,
    updateTDICase,
    getAllPotentialAssociated,
    updatePotentialAssociated,

    // ====================== TDI SF Cases ======================
    getAllTDISFCases,
    getTDISFCase,
    updateTDISFCaseStatus,
    createTDISFCase,
    deleteTDISFCase,
    editTDISFCase,
    createTDISFCaseByTDI30,

    // ====================== PHCCoverages ======================
    getPHCCoverage,
    getPHCCoverageDetail,
    getPHCCoverageLatestActive,
    createPHCCoverage,
    updatePHCCoverage,
    getAllPHCCoverages,
    deletePHCCoverage,

    // ====================== Tracking Log ======================
    getTrackingLog,
    createTrackingLog,
    updateTrackingLog,
    getAllTrackingLogs,
    deleteTrackingLog,

    // ====================== HC-4s ======================
    getAllHC4,
    getHC4,
    updateHC4,
    createHC4,
    deleteHC4,

    // ====================== HC-15s ======================
    getHC15,
    getAllHC15,
    deleteHC15,
    updateHC15,
    acceptRejectHC15Action,

    // ====================== Select HRS/HAR ======================
    getAllHRSs,
    updateSelectedHRS,
    deleteHRS,

    // ====================== HC-61s ======================
    createHC61,
    updateHC61,
    activateUpdatePlanHC61,
    getHC61,
    getAllHC61s,
    deleteHC61,

    // ====================== Request ======================
    getAllRequests,
    createRequest,
    updateRequest,
    getRequest,
    deleteRequest,

    // ====================== TDI-21s ======================
    getAllTDI21,
    deleteTDI21,
    getTDI21Detail,
    createTDI21,
    updateTDI21,

    // ====================== Complaints ======================
    getAllComplaint,
    getComplaint,
    deleteComplaint,
    createComplaint,
    updateComplaint,

    // ====================== Employer Audits ======================
    getAllEmployerAudits,
    getEmployerAudit,
    deleteEmployerAudit,
    createEmployerAudit,
    updateEmployerAudit,

    // ====================== Files ======================
    getAllUploadFiles,

    // ====================== Routing Information ======================
    createRoutingInformation,
    getAllRoutingInformation,
    deleteRoutingInformation,

    // ====================== Custom Settings ======================
    getCustomSetting,
    updateCustomSettings,

    // ====================== Reports ======================
    getReports,
    editReport,
    getTDI21sAnnualReportsNotFiled,
    getTDI21sAnnualExceptionReport,
    getNonCompliantEmployerInsuranceReport,
    getNonCompliantWCEmployerInsuranceReport,
    getRejectedSelfInsuredHCPReport,
    getRejectedHCPDetail,
    getApprovedHealthCarePlansReport,
    getApprovedHealthCarePlansDetail,
    getTDIAndPHCPhysicalFilePurgeReport,
    getHC15sReport,
    getHC15sReportDetail,
    getTDI21sAnnualReportSummaryPlanType,
    getTDI21sAnnualDetail,
    getTDI15sTDISelfInsurerPlanReport,
    getTDI15SelfInsurerDetail,
    getEmployerRegistrationActivities,
    getEmployerRegistrationActivitiesDetail,
    getTDI46ReconsiderationAppealsReport,
    getTDI46ReconsiderationAppealsDetail,
    getTDI62sReport,
    getTDI62sReportDetail,
    getTDI30sReport,
    getTDI30sReportDetail,
    getTDI21NumberOfEmployeesReport,

    // ====================== Portal User ======================
    getPortalUser,
    getAllPortalUserAuditHistories,
    getAllPortalUser,
    deletePortalUser,
    updatePortalUser,
    getAllRelatedAccountPortalUsers,

    getAllWC14s,
    deleteWC14,
    getWC14Detail,
    createWC14,
    updateWC14,
    // ====================== WC77 ======================
    createWC77,
    updateWC77,
    getWC77,
    deleteWC77,
    getAllWC77s,

    // ====================== WC21 ======================
    getWC21Detail,
    getAllWC21s,
    deleteWC21,
    updateWC21,

    // ====================== Award Summary ======================

    getAwardSummaries,
    getCaseAwardPayers,
    createAwardSummary,
    getAwardSummary,
    deleteAwardSummary,
  };
};

export type Apis = ReturnType<typeof create>;

export default {
  create,
};
