import { useMutation, UseMutationOptions } from 'react-query';
import { Toastify } from 'src/services';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { AppPermissionPayload } from './types';

export function useEditAppPermission(
  options?: UseMutationOptions<any, Error, AppPermissionPayload>
) {
  const {
    mutate: onEditAppPermission,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useMutation<AppPermissionPayload, Error, AppPermissionPayload>({
    mutationFn: (payload: AppPermissionPayload) =>
      responseWrapper(apiClient.editAppPermissions, [payload]),
    onError: ({ message }) => Toastify.error(message),
    ...options,
  });

  return {
    onEditAppPermission,
    isLoading,
    isSuccess,
    isError,
    error,
  };
}
