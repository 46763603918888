import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { PaginationResponseType, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { GetPropertiesParams } from 'src/queries/types';
import { toCaseSettlement } from './helpers';
import { CaseSettlementsTypes } from './types';

export function useGetAllCaseSettlements(
  options?: UseQueryOptions<PaginationResponseType<CaseSettlementsTypes>, Error> & {
    caseId?: string;
  }
) {
  const [params, setParams] = useState<GetPropertiesParams>({});

  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetAllCaseSettlements,
  } = useQuery<PaginationResponseType<CaseSettlementsTypes>, Error>(
    [API_QUERIES.CASE_SETTLEMENTS, { ...params, caseId: options?.caseId }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<PaginationResponseType<CaseSettlementsTypes>>(
          apiClient.getAllCaseSettlements,
          params
        );
      },
      select: (data) => toCaseSettlement(data),
      notifyOnChangeProps: ['data', 'isFetching'],
      enabled: true,
      keepPreviousData: true,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateAllCaseSettlements = () =>
    queryClient.invalidateQueries(API_QUERIES.CASE_SETTLEMENTS);

  const { data: caseSettlements, hasNext, totalRecords, payloadSize } = data || {};

  return {
    caseSettlements,
    error,
    hasNext,
    totalRecords,
    payloadSize,
    isError,
    isFetching,
    onGetAllCaseSettlements,
    setParams,
    handleInvalidateAllCaseSettlements,
  };
}
