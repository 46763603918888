import React from 'react';
import CurrencyFormat from 'react-currency-format';
import { emptyFunction, MoneyInputDetect } from 'src/modules/shared-main/common';
import { Callback } from 'src/redux/types';
import { Element, Input } from '..';
import { InputIcon, InputProps } from '../Input';

const DollarInputIcon = (
  <InputIcon
    iconName={'ic_dollar'}
    isIconPositionLeft={true}
    iconComponent={null}
    onIconClick={emptyFunction}
  />
);

const customInput = ({ ...props }) => (
  <Input customIcon={DollarInputIcon} iconPosition="left" {...props} />
);

const MAX_INTEGER_LENGTH = 13;

const InputCurrency: React.FC<CurrencyProps> = ({
  unFixedDecimalScale = false,
  name,
  value,
  decimalPlaces = 2,
  onChange,
  onBlur,
  infoTooltipMessage,
  label,
  required,
  maxIntegerLength = MAX_INTEGER_LENGTH,
  thousandSeparator = true,
  disabled,
  placeholder = '',
  ...props
}) => {
  const handleChange = (values) => {
    const { floatValue } = values;
    const returnValue = floatValue ? floatValue : floatValue === 0 ? 0 : null;
    onChange(name, returnValue);
  };

  const onTouched = () => {
    onBlur(name, true);
  };

  const defaultPlaceholder = disabled
    ? ''
    : typeof label === 'string'
    ? `Enter ${label.toLowerCase()}`
    : '';

  return (
    <Element required={required} label={label} infoTooltipMessage={infoTooltipMessage}>
      <CurrencyFormat
        customInput={customInput}
        thousandSeparator={thousandSeparator}
        fixedDecimalScale={!unFixedDecimalScale}
        decimalScale={decimalPlaces}
        displayType="input"
        onValueChange={handleChange}
        {...props}
        name={name}
        value={typeof value === 'string' ? value : MoneyInputDetect(value)}
        onBlur={onTouched}
        placeholder={placeholder || defaultPlaceholder}
        disabled={disabled}
        isAllowed={({ floatValue }) => {
          return floatValue ? `${Math.trunc(floatValue)}`.length <= maxIntegerLength : true;
        }}
      />
    </Element>
  );
};

export type CurrencyProps = Omit<CurrencyFormat.Props, 'InputProps'> & {
  InputProps?: InputProps;
} & {
  unFixedDecimalScale?: boolean;
  value: string | number;
  decimalPlaces?: number;
  max?: number;
  name: string;
  onChange: Callback;
  onBlur?: Callback;
  infoTooltipMessage?: string;
  label?: string;
  required?: boolean;
  maxLength?: number;
  thousandSeparator?: boolean;
  placeholder?: string;
  disabled?: boolean;
  maxIntegerLength?: number;
};

export default InputCurrency;
