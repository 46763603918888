import { useMemo } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { ApiResponseType, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { Option } from 'src/queries/types';
import { isEmpty } from 'src/validations';
import { CaseAwardPayer } from './types';

export function useGetAllCaseAwardPayers(
  options?: UseQueryOptions<
    ApiResponseType<{ payers: CaseAwardPayer[] }>,
    Error,
    CaseAwardPayer[]
  > & {
    caseId: string;
  }
) {
  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetCaseAwardPayers,
  } = useQuery<ApiResponseType<{ payers: CaseAwardPayer[] }>, Error, CaseAwardPayer[]>(
    [API_QUERIES.CASE_AWARD_PAYERS, { ...options }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<{ payers: CaseAwardPayer[] }>>(
          apiClient.getCaseAwardPayers,
          params
        );
      },
      select: ({ data }) => data?.payers,
      enabled: !!options?.caseId,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateCaseAwardPayers = () =>
    queryClient.invalidateQueries([API_QUERIES.CASE_AWARD_PAYERS, { ...options }]);

  const caseAwardPayers: Option[] = useMemo(() => {
    if (isEmpty(data)) return [];
    return data?.map(({ payer, isInUse }) => ({
      value: payer,
      label: payer,
      disabled: isInUse,
    })) as Option[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return {
    caseAwardPayers,
    error,
    isError,
    isFetching,
    onGetCaseAwardPayers,
    handleInvalidateCaseAwardPayers,
  };
}
