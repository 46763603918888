export enum HCPKey {
  Id = 'id',

  //TDI plan
  PlanNumber = 'planNumber',
  PlanType = 'planType',
  PlanIdNumber = 'planIdNumber',
  PlanName = 'planName',

  //HCContractor
  HCContractor = 'hcContractor',
  HCContractorName = 'hcContractorName',
  HCContractorNumber = 'hcContractorNumber',
  HCContractorStatusDate = 'contractorStatusDate',
  HCContractorStatus = 'contractorStatus',
  HCContractorId = 'hcContractorId',

  // Employer
  EmployerName = 'employerName',
  EmployerId = 'employerId',
  EmployerDOL = 'laborNumber',
  EmployerDCDStatus = 'dcdStatus',
  EmployerDCDStatusDate = 'dcdStatusDate',
  Employer = 'employer',

  //other
  RecordNum = 'recordNum',
  Status = 'status',
  StatusEffectiveDate = 'statusEffectiveDate',
  HrsSection = 'hrsSection',
  BenefitLevel = 'benefitLevel',
  FinancialStatementYearEnding = 'financialStatementYearEnding',
  LastAuditDate = 'lastAuditDate',

  // Filter keys
  HcContractNameFilter = 'hcContractor.accountName',
  HcContractIDFilter = 'hcContractor.carrierId',
  HcContractStatusFilter = 'hcContractor.dcdStatus',
  HcContractDCDStatusDateFilter = 'hcContractor.dcdStatusDate',
  EmployerNameFilter = 'employer.accountName',
  EmployerDCDStatusDateFilter = 'employer.dcdStatusDate',
  EmployerDOLFilter = 'employer.laborNumber',
  EmployerDCDStatusFilter = 'employer.dcdStatus',

  // ============= Audit =============
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  CreatedBy = 'createdBy',
  UpdatedBy = 'updatedBy',
  CreatedByUser = 'createdUser',
  UpdatedUser = 'updatedUser',
}
