import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { HC4 } from './types';

export function useEditHC4(options?: UseMutationOptions<HC4, Error, HC4>) {
  const {
    mutate: onEditHC4,
    isLoading: isUpdating,
    isError,
    error,
  } = useMutation<HC4, Error, HC4>({
    mutationFn: (payload: HC4) => responseWrapper(apiClient.updateHC4, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    onEditHC4,
    isUpdating,
    isError,
    error,
  };
}
