import { formatDate } from 'src/utils';
import { ApiResponseType, PaginationResponseType } from '../helpers';
import { HC15sReportKey } from './keys';
import { HC15sReport } from './types';

export const toHC15sReport = (
  data
): ApiResponseType<{
  reports: PaginationResponseType<HC15sReport>;
  totalNewlyEnrolledCA: number;
  totalCanceledCA: number;
  totalIntentToCanceledCA: number;
}> => {
  const originalReport = data?.data?.reports?.data || [];

  const formattedReport = originalReport.map((report) => ({
    ...report,
    [HC15sReportKey.DateReceived]: `${formatDate(
      report?.[HC15sReportKey.StartDate]
    )} - ${formatDate(report?.[HC15sReportKey.EndDate])}`,
  }));

  return {
    ...data,
    data: {
      ...data?.data,
      reports: {
        ...data?.data?.reports,
        data: formattedReport,
      },
    },
  };
};
