import { toAuditData } from '../helpers';
import { TDI21Key } from './keys';

export const toTDI21s = (data) => ({
  ...data,
  data: data.data.map((tdi21) => ({
    ...tdi21,
    // EMPLOYER
    [TDI21Key.EmployerId]: tdi21?.employer?.id || '',
    [TDI21Key.Employer]: tdi21?.employer?.accountName || '',
    [TDI21Key.DOLNumber]: tdi21?.employer?.laborNumber || '',
    [TDI21Key.DBAName]: tdi21?.employer?.dbaName || '',
    [TDI21Key.EmployerStreet]: tdi21?.employer?.street || '',
    [TDI21Key.EmployerCity]: tdi21?.employer?.city || '',
    [TDI21Key.EmployerState]: tdi21?.employer?.state || '',
    [TDI21Key.EmployerZipCode]: tdi21?.employer?.zip || '',
    [TDI21Key.TDIForm]: tdi21?.tdiForm?.tdiFormNumber || '',
    ...toAuditData(tdi21),
  })),
});

export const toNumberOfCoveredEmployeesTable = (tdi21) => {
  return [
    {
      title: 'Number of covered male employees (EE)',
      headerTooltip:
        'Number of covered male employees (EE) on the payroll for the pay period which includes the twelfth day of each month.',
      [TDI21Key.January]: tdi21?.noOfCoveredMaleJan || '',
      [TDI21Key.February]: tdi21?.noOfCoveredMaleFeb || '',
      [TDI21Key.March]: tdi21?.noOfCoveredMaleMar || '',
      [TDI21Key.April]: tdi21?.noOfCoveredMaleApr || '',
      [TDI21Key.May]: tdi21?.noOfCoveredMaleMay || '',
      [TDI21Key.June]: tdi21?.noOfCoveredMaleJun || '',
      [TDI21Key.July]: tdi21?.noOfCoveredMaleJul || '',
      [TDI21Key.August]: tdi21?.noOfCoveredMaleAug || '',
      [TDI21Key.September]: tdi21?.noOfCoveredMaleSep || '',
      [TDI21Key.October]: tdi21?.noOfCoveredMaleOct || '',
      [TDI21Key.November]: tdi21?.noOfCoveredMaleNov || '',
      [TDI21Key.December]: tdi21?.noOfCoveredMaleDec || '',
    },
    {
      title: 'Number of covered female employees (EE)',
      headerTooltip:
        'Number of covered female employees (EE) on the payroll for the pay period which includes the twelfth day of each month. ',
      [TDI21Key.January]: tdi21?.noOfCoveredFemaleJan || '',
      [TDI21Key.February]: tdi21?.noOfCoveredFemaleFeb || '',
      [TDI21Key.March]: tdi21?.noOfCoveredFemaleMar || '',
      [TDI21Key.April]: tdi21?.noOfCoveredFemaleApr || '',
      [TDI21Key.May]: tdi21?.noOfCoveredFemaleMay || '',
      [TDI21Key.June]: tdi21?.noOfCoveredFemaleJun || '',
      [TDI21Key.July]: tdi21?.noOfCoveredFemaleJul || '',
      [TDI21Key.August]: tdi21?.noOfCoveredFemaleAug || '',
      [TDI21Key.September]: tdi21?.noOfCoveredFemaleSep || '',
      [TDI21Key.October]: tdi21?.noOfCoveredFemaleOct || '',
      [TDI21Key.November]: tdi21?.noOfCoveredFemaleNov || '',
      [TDI21Key.December]: tdi21?.noOfCoveredFemaleDec || '',
    },
  ];
};

export const toTDI21Detail = (data) => {
  return {
    ...data,
    [TDI21Key.NumberOfCoveredEmployeesTable]: toNumberOfCoveredEmployeesTable(data),
    [TDI21Key.DOLNumber]: data?.employerDOLNumber,
    [TDI21Key.DBAName]: data?.employerDBAName,
    [TDI21Key.EmployerCity]: data?.employerCity,
    [TDI21Key.EmployerNameRelated]: data?.employer?.accountName,
    [TDI21Key.EmployerIdRelated]: data?.employer?.id,
    [TDI21Key.InsuranceNameRelated]: data?.insuranceCarrier?.accountName,
    [TDI21Key.InsuranceIdRelated]: data?.insuranceCarrier?.id,
    [TDI21Key.EmployerState]: data?.employerState,
    [TDI21Key.EmployerAddress]: data?.employerAddress,
    [TDI21Key.EmployerZipCode]: data?.employerZipcode,
    ...toAuditData(data),
  };
};
