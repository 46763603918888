import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { toHCPs } from 'src/queries/HealthCarePlans/helpers';
import { PaginationResponseType, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { GetPropertiesParams } from 'src/queries/types';
import { HealthCarePlan } from './types';

export function useGetAllHCP(
  options?: UseQueryOptions<PaginationResponseType<HealthCarePlan>, Error>
) {
  const [params, setParams] = useState<GetPropertiesParams>({});

  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetAllHCP,
  } = useQuery<PaginationResponseType<HealthCarePlan>, Error>(
    [API_QUERIES.HealthCarePlans, params],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<PaginationResponseType<HealthCarePlan>>(
          apiClient.getAllHCPs,
          params
        );
      },
      notifyOnChangeProps: ['data', 'isFetching'],
      keepPreviousData: true,
      select: (data) => toHCPs(data),
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateAllHCP = () => queryClient.invalidateQueries(API_QUERIES.HealthCarePlans);

  const { data: healthCarePlans, hasNext, payloadSize, totalRecords } = data || {};

  return {
    healthCarePlans,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isError,
    isFetching,
    onGetAllHCP,
    setParams,
    handleInvalidateAllHCP,
  };
}
