import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { authResponseWrapper } from '../helpers';

export function useDeletePartyNamesAddressDecision(
  options?: UseMutationOptions<string, Error, string>
) {
  const { mutate: deleteDecisionPartyNamesAddress, isLoading } = useMutation<string, Error, string>(
    {
      mutationFn: (payload: string) =>
        authResponseWrapper(apiClient.deleteDecisionPartyNamesAddress, [payload]),
      onError: ErrorService.handler,
      ...options,
    }
  );
  return { deleteDecisionPartyNamesAddress, isLoading };
}
