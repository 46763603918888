import { useMutation, UseMutationOptions } from 'react-query';
import { HealthCarePlan } from 'src/queries/HealthCarePlans/types';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';

export function useEditHCP(options?: UseMutationOptions<HealthCarePlan, Error, HealthCarePlan>) {
  const {
    mutate: onEditHCP,
    isLoading: isUpdating,
    isError,
    error,
  } = useMutation<HealthCarePlan, Error, HealthCarePlan>({
    mutationFn: (payload: HealthCarePlan) => responseWrapper(apiClient.updateHCP, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    onEditHCP,
    isUpdating,
    isError,
    error,
  };
}
