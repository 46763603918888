import { MUIDataTableColumn } from 'mui-datatables';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { AppName } from 'src/appConfig/constants';

import { Tag, ViewObject } from 'src/queries';
import { getDefaultView, TableColumn } from '../helpers';
import { emptyFunction } from 'src/utils';
import {
  RestrictGroup,
  Service,
  useGetViewDetail,
  useGetViews,
  View,
  ViewsOption,
} from 'src/modules/shared-main/queries';

export type ListViewControlProps = {
  hideVisibilityRestrict?: boolean;
  defaultVisibilityRestrict?: RestrictGroup;
};

interface TableContextType {
  appName: AppName;
  object: ViewObject;
  allFields: TableColumn[];
  hasFilter: boolean;
  view: View;
  wrapClipColumns: string[];
  columns: MUIDataTableColumn[];
  noListViewControl: boolean;
  noWrapText: boolean;
  tag?: Tag;
  filterByObject: string;
  service: Service;
  views: ViewsOption[];
  handleChangeWrapClipColumns: (columns: string[]) => void;
  isRelatedList: boolean;
  handleChangeCurrentViewId: (viewId: string) => void;
  isLoadingView: boolean;
  currentViewId: string;
  customTableViewProps: ListViewControlProps;
}

interface TableProviderProps {
  children: React.ReactNode;
  appName: AppName;
  object: ViewObject;
  allFields: TableColumn[];
  hasFilter: boolean;
  columns: MUIDataTableColumn[];
  noListViewControl: boolean;
  noWrapText: boolean;
  tag: Tag;
  filterByObject: string;
  service: Service;
  isRelatedList: boolean;
  customTableViewProps: ListViewControlProps;
}

const TableContext = createContext<TableContextType>({
  appName: null,
  object: null,
  allFields: [],
  hasFilter: true,
  view: null,
  wrapClipColumns: [],
  columns: [],
  noListViewControl: false,
  noWrapText: false,
  filterByObject: '',
  service: Service.CaseSvc,
  views: [],
  handleChangeWrapClipColumns: emptyFunction,
  isRelatedList: false,
  handleChangeCurrentViewId: emptyFunction,
  isLoadingView: false,
  currentViewId: '',
  customTableViewProps: {
    hideVisibilityRestrict: false,
    defaultVisibilityRestrict: RestrictGroup.AllUsers,
  },
});

export const TableProvider = ({
  children,
  appName,
  object,
  allFields,
  hasFilter,
  columns,
  noListViewControl,
  noWrapText,
  tag,
  filterByObject,
  service,
  isRelatedList,
  customTableViewProps,
}: TableProviderProps) => {
  const [viewId, setViewId] = useState('');

  const { views, isLoading: isLoadingViews } = useGetViews({
    object,
    appName,
    tags: tag,
    service,
  });

  const currentViewId = useMemo(() => viewId || getDefaultView(views), [views, viewId]);

  const { view, isLoading: isFetching } = useGetViewDetail({
    id: currentViewId,
    object,
    service,
  });

  useEffect(() => {
    if ((currentViewId && !views.some(({ value }) => value === currentViewId)) || !currentViewId) {
      setViewId(getDefaultView(views));
    }
  }, [views, currentViewId]);

  const [wrapClipColumns, setWrapClipColumn] = useState<string[]>([]);

  const handleChangeWrapClipColumns = (columns: string[]) => {
    setWrapClipColumn(columns);
  };

  const handleChangeCurrentViewId = (viewId: string) => {
    setViewId(viewId);
  };

  const isLoadingView = isFetching || isLoadingViews;

  return (
    <TableContext.Provider
      value={{
        appName,
        object,
        allFields,
        hasFilter,
        view,
        wrapClipColumns,
        columns,
        noListViewControl,
        noWrapText,
        tag,
        filterByObject,
        service,
        views,
        handleChangeWrapClipColumns,
        isRelatedList,
        handleChangeCurrentViewId,
        isLoadingView,
        currentViewId,
        customTableViewProps,
      }}
    >
      {children}
    </TableContext.Provider>
  );
};

export const useTableProvider = () => useContext(TableContext);
