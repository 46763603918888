import { TDI30Key } from './keys';

export enum DaysNormallyWorked {
  Monday = 'MONDAY',
  Tuesday = 'TUESDAY',
  Wednesday = 'WEDNESDAY',
  Thursday = 'THURSDAY',
  Friday = 'FRIDAY',
  Saturday = 'SATURDAY',
  Sunday = 'SUNDAY',
}

export enum TDI30ReceiveBenefitFrom {
  FederalDisabilityInsuranceBenefits = 'FEDERAL',
  WorkmenCompensationBenefits = 'WORKMEN COMPENSATION',
  WagesSalaryOrSeparationPay = 'WAGES SALARY',
  DamagesForPersonalInjury = 'PERSONAL INJURY DAMAGES',
}

export enum TDI30ClaimStatus {
  Paid = 'PAID',
  Denied = 'DENIED',
  Pending = 'PENDING',
}

export enum TDI30ReasonNotCounted {
  Duplicate = 'DUPLICATE',
  Incomplete = 'INCOMPLETE',
  Other = 'OTHER',
}

export interface TDI30 {
  [TDI30Key.Id]?: string;
  [TDI30Key.TDI30Number]?: string;
  [TDI30Key.TDISFCaseId]?: string;

  // ============= Claimant =============
  [TDI30Key.Claimant]?: string;
  [TDI30Key.ClaimantName]?: string;
  [TDI30Key.ClaimantId]?: string;
  [TDI30Key.ClaimantAddress]?: string;
  [TDI30Key.ClaimantStreet]?: string;
  [TDI30Key.ClaimantCity]?: string;
  [TDI30Key.ClaimantZip]?: string;
  [TDI30Key.ClaimantSSN]?: string;
  [TDI30Key.ClaimantBirthDate]?: string;
  [TDI30Key.ClaimantPhone]?: string;
  [TDI30Key.ClaimantEmail]?: string;
  [TDI30Key.ClaimantSex]?: string;
  [TDI30Key.ClaimantMaritalStatus]?: string;

  // ============= Disability Information =============
  [TDI30Key.MyDisabilityIs]?: string;
  [TDI30Key.FirstDateDisabled]?: string;
  [TDI30Key.IsRecoveredFromDisability]?: boolean;
  [TDI30Key.DateRecovered]?: string;

  // ============= Employment Information =============
  [TDI30Key.Employer]?: string;
  [TDI30Key.EmployerId]?: string;
  [TDI30Key.EmployerName]?: string;
  [TDI30Key.EmployerAddress]?: string;
  [TDI30Key.EmployerCity]?: string;
  [TDI30Key.EmployerZip]?: string;
  [TDI30Key.EmployerState]?: string;
  [TDI30Key.DateHired]?: string;
  [TDI30Key.LastDateWorkedBeforeDisability]?: string;
  [TDI30Key.NumberDaysWorkedPerWeek]?: string;
  [TDI30Key.DaysNormallyWorked]?: DaysNormallyWorked[];

  // ============= Unemployment Insurance (UI) Claim Information =============
  [TDI30Key.HaveFiledClaimForUIBenefits]?: boolean;
  [TDI30Key.DateUIBenefitYearBegan]?: string;
  [TDI30Key.DateOnAttachedNoticeUIDecision]?: string;
  [TDI30Key.UIWeeklyBenefitAmount]?: string;
  [TDI30Key.NoClaimOrNoUIBenefit]?: string;

  // ============= Other Benefits (BEN) =============
  [TDI30Key.ClaimingOrReceivingBenefitsFrom]?: TDI30ReceiveBenefitFrom[];
  [TDI30Key.IsFiledTDIThroughEmployer]?: boolean;
  [TDI30Key.TDIClaimStatus]?: TDI30ClaimStatus;

  // ============= Representative =============
  [TDI30Key.SignatureName]?: string;
  [TDI30Key.SignatureDate]?: string;
  [TDI30Key.RepresentativeName]?: string;
  [TDI30Key.Relationship]?: string;

  // ============= Medical Certification =============
  [TDI30Key.ClaimantAge]?: string;
  [TDI30Key.Diagnosis]?: string;
  [TDI30Key.IsDisabilityDueToNormalPregnancy]?: boolean;
  [TDI30Key.IsDueToComplicationsOfPregnancy]?: boolean;
  [TDI30Key.WasDisabilityCausedByEmployment]?: boolean;
  [TDI30Key.IsWC2Filed]?: boolean;
  [TDI30Key.WC2FiledWith]?: string;
  [TDI30Key.WasClaimantHospitalized]?: boolean;
  [TDI30Key.ClaimantHospitalizedStartDate]?: string;
  [TDI30Key.ClaimantHospitalizedEndDate]?: string;
  [TDI30Key.IsSurgeryIndicated]?: boolean;
  [TDI30Key.SurgeryType]?: string;
  [TDI30Key.FirstDateTreatment]?: string;
  [TDI30Key.FirstDateClaimantUnableToWork]?: string;
  [TDI30Key.MostRecentTreatmentDate]?: string;
  [TDI30Key.DateDischargedOrAbleToPerformWork]?: string;
  [TDI30Key.Remarks]?: string;

  // ============= Doctor/APRN Information =============
  [TDI30Key.Doctor]?: string;
  [TDI30Key.DoctorId]?: string;
  [TDI30Key.DoctorName]?: string;
  [TDI30Key.DoctorAddress]?: string;
  [TDI30Key.DoctorPhone]?: string;
  [TDI30Key.DoctorEmail]?: string;
  [TDI30Key.DoctorFax]?: string;
  [TDI30Key.DoctorDate]?: string;

  // ============= Form Review =============
  [TDI30Key.IsCount]: boolean;
  [TDI30Key.ReasonNotCounted]: TDI30ReasonNotCounted;
  [TDI30Key.BenefitYearBeginDateRequestedOn]: string;
  [TDI30Key.IsNoticeOnUIDecisionReceived]: boolean;

  // ============= Audit =============
  [TDI30Key.CreatedAt]?: string;
  [TDI30Key.UpdatedAt]?: string;
  [TDI30Key.CreatedBy]?: string;
  [TDI30Key.UpdatedBy]?: string;
  [TDI30Key.CreatedUser]?: string;
  [TDI30Key.UpdatedUser]?: string;
}
