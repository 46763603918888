import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { TDI30 } from './types';

export function useUpdateTDI30(options?: UseMutationOptions<TDI30, Error, TDI30>) {
  const {
    mutate: onUpdateTDI30,
    isLoading: isUpdating,
    isSuccess,
    isError,
    error,
  } = useMutation<TDI30, Error, TDI30>({
    mutationFn: (payload: TDI30) => responseWrapper(apiClient.updateTDI30, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    onUpdateTDI30,
    isUpdating,
    isSuccess,
    isError,
    error,
  };
}
