import { ListItem, ListItemAvatar } from '@mui/material';
import cn from 'classnames';
import React from 'react';
import { IMAGES } from 'src/appConfig/images';
import { Callback } from 'src/redux/types';
import './styles.scss';
import { Image, View } from 'src/modules/shared-main/components';

const clsPrefix = 'cmp-logo';

const Logo: React.FC<Props> = ({ className = '', logoSize = 80, onClick }) => {
  return (
    <View className={cn(clsPrefix, className, { 'cursor-pointer': !!onClick })} onClick={onClick}>
      <ListItem
        alignItems="center"
        classes={{ root: className ? className : undefined }}
        disableGutters
      >
        <ListItemAvatar style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Image
            src={IMAGES.logo}
            className={`${clsPrefix}__img`}
            width={logoSize}
            height={logoSize}
          />
        </ListItemAvatar>
      </ListItem>
    </View>
  );
};

type Props = {
  className?: string;
  logoSize?: string | number;
  onClick?: Callback;
};

export default Logo;
