import { toAuditData } from '../helpers';
import { TDI46Key } from './keys';

export const toTDI46 = (data) => ({
  ...data,
  data: data.data.map((tdi46) => ({
    ...tdi46,
    [TDI46Key.Employer]: tdi46?.employer?.accountName,
    [TDI46Key.Carrier]: tdi46?.insuranceCarrier?.accountName,
    [TDI46Key.Claimant]: tdi46?.claimant?.accountName,
    [TDI46Key.AdjustingCompanyName]: tdi46?.adjustingCompany?.accountName,
    [TDI46Key.TDICase]: tdi46?.tdiCase?.tdiCaseNumber,
    [TDI46Key.EmployerId]: tdi46?.employer?.id,
    [TDI46Key.AdjustingCompanyId]: tdi46?.adjustingCompany?.id,
    [TDI46Key.InsuranceCarrierId]: tdi46?.insuranceCarrier?.id,
    [TDI46Key.ClaimantId]: tdi46?.claimant?.id,
    [TDI46Key.TDICaseId]: tdi46?.tdiCase?.id,
    [TDI46Key.TDIForm]: tdi46?.tdiForm?.tdiFormNumber,
  })),
});

export const toTDI46Detail = (data) => ({
  ...data,
  [TDI46Key.ClaimantName]: data?.claimant?.accountName,
  [TDI46Key.EmployerName]: data?.employer?.accountName,
  [TDI46Key.InsuranceCarrierName]: data?.insuranceCarrier?.accountName,
  [TDI46Key.TDICaseNumber]: data?.tdiCase?.tdiCaseNumber,
  [TDI46Key.AdjustingCompanyName]: data?.adjustingCompany?.accountName,
  [TDI46Key.InsuranceCarrierId]: data?.insuranceCarrier?.id,
  ...toAuditData(data),
});
