import cn from 'classnames';
import { FormikProvider, useFormik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
import { FaFilter, FaPlus } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { COLOR_CODE } from 'src/appConfig/constants';
import { Button, Form, Grid, RadioButton, Text, View } from 'src/modules/shared-main/components';
import Toggle from 'src/modules/shared-main/components/Toggle';
import {
  FilterKey,
  FilterOwner,
  useGetViewDetail,
  useUpdateView,
} from 'src/modules/shared-main/queries';
import { hideDialog, showDialog } from 'src/redux/dialog/dialogSlice';
import { DIALOG_TYPES } from 'src/redux/dialog/type';
import { isEmpty } from 'src/validations';
import AddLogic from './AddLogic';
import CustomFilterEdit from './CustomFilterEdit';
import CustomFilterView from './CustomFilterView';
import {
  filterOwnerOptions,
  filtersSchema,
  getNewLogic,
  initialValues,
  validateDuplicateName,
} from './helpers';
import './styles.scss';
import MuiPopOverFilter from 'src/components/MuiPopOverFilter';
import { useTableProvider } from '../TableProvider';
import { TableColumnType } from '../helpers';
import { ViewObject } from 'src/queries';
import { sortByName } from 'src/modules/shared-main/common';

const FilterContainer: React.FC<Props> = ({ hideFilterByOwner = false }) => {
  const { allFields: fieldOptions, filterByObject, view, service } = useTableProvider();
  const {
    logic,
    applyFilter,
    id,
    canBeUpdated,
    object,
    filterOwner,
    filters: viewFilters,
  } = view || {};

  const dispatch = useDispatch();
  const [isShow, setIsShow] = useState<boolean>(false);

  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const [filters, setFilters] = useState([]);

  const filterOwnerOption = useMemo(() => filterOwnerOptions(filterByObject), [filterByObject]);

  useEffect(() => {
    setFilters(
      viewFilters?.map((item) => {
        const type = fieldOptions.find(
          ({ name, filterKey }) => name === item.field || filterKey === item.field
        )?.type;

        return {
          ...item,
          [FilterKey.Value]:
            type === TableColumnType.Checkbox ||
            type === TableColumnType.Select ||
            type === TableColumnType.SearchableSelect
              ? (item.value as string).split(',')
              : item.value,
          [FilterKey.FieldType]: type,
        };
      }) || []
    );
  }, [viewFilters, fieldOptions]);

  const { handleInvalidateAllViewDetail } = useGetViewDetail({
    id: id,
    object: object,
    service: service,
    onSuccess: () => {
      dispatch(hideDialog());
    },
    onSettled: ({ filters }) => {
      setFilters(
        filters?.map((item) => {
          const type = fieldOptions.find(
            ({ name, filterKey }) => name === item.field || filterKey === item.field
          )?.type;
          return {
            ...item,
            [FilterKey.Value]:
              type === TableColumnType.Checkbox ||
              type === TableColumnType.Select ||
              type === TableColumnType.SearchableSelect
                ? (item.value as string).split(',')
                : item.value,
            [FilterKey.FieldType]: type,
          };
        }) || []
      );
      dispatch(hideDialog());
    },
  });

  const allFields = useMemo(() => sortByName(fieldOptions, 'label'), [fieldOptions]);

  const FormFilterList = () => (
    <>
      {filters.map((value, index: number) => (
        <CustomFilterView
          fieldOptions={allFields}
          onSetEditMode={() => handleShowAddFilter(index, true)}
          key={index}
          values={value}
          view={view}
          service={service}
        />
      ))}
    </>
  );

  const FormFilter = ({ prefix = 0, isEdit = false }) => {
    const { updateView, isLoading } = useUpdateView({
      onSuccess: () => {
        handleInvalidateAllViewDetail();
      },
    });

    const handleSubmitForm = (values) => {
      updateView({
        ...view,
        service,
        filters: values,
        ...getNewLogic(values, logic, prefix, isEdit),
      });
    };

    const formik = useFormik({
      initialValues: filters,
      onSubmit: handleSubmitForm,
      validationSchema: filtersSchema,
      enableReinitialize: true,
      validate: (values) => validateDuplicateName(values, prefix),
    });

    const { handleSubmit, values, setValues } = formik || {};

    const handleCloseFilter = () => {
      if (values.length === 1) {
        setIsShow(false);
      }
    };

    if (!isEdit && prefix === values.length && !isLoading) {
      setValues([
        ...filters,
        { ...initialValues, [FilterKey.Name]: `Filter ${values.length + 1}` },
      ]);
    }

    return (
      <FormikProvider value={formik}>
        <Form onSubmit={handleSubmit} autoComplete="off">
          <CustomFilterEdit
            prefix={prefix}
            fieldOptions={allFields}
            isLoading={isLoading}
            isEdit={isEdit}
            handleClose={handleCloseFilter}
          />
        </Form>
      </FormikProvider>
    );
  };

  const handleShowAddFilter = (prefix: number, isEdit = false) => {
    dispatch(
      showDialog({
        type: DIALOG_TYPES.CONTENT_DIALOG,
        data: {
          content: <FormFilter prefix={prefix} isEdit={isEdit} />,
          hideTitle: true,
          hideCloseButton: true,
        },
      })
    );
  };

  const filter = query.getAll('filter') as string[];

  const isHasFilter = !isEmpty(filter) && !isEmpty(filter.filter((x) => x));

  const handleOpenAddFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    handleShowAddFilter(filters.length, false);
  };

  const { updateView } = useUpdateView({
    onSuccess: () => {
      handleInvalidateAllViewDetail();
    },
  });

  const onChangeFilterOwner = (value: FilterOwner) => {
    updateView({ ...view, filterOwner: value, service });
  };

  const onChangeApplyFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateView({ ...view, applyFilter: e.target.checked, service });
  };

  return (
    <MuiPopOverFilter
      isShow={isShow}
      onShow={setIsShow}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      disabled={!view || !canBeUpdated}
      label={
        <View
          isRowWrap
          align="center"
          className={cn(
            'cmp-filter-container__label',
            { 'is-focus': isShow },
            { 'is-filter': isHasFilter }
          )}
        >
          <FaFilter className="cmp-filter-icon__icon" color={COLOR_CODE.GRAY_600} />
        </View>
      }
      body={
        <Grid.Wrap className="cmp-filter-container__form">
          <Grid.Item variant="is-full">
            <View justify="space-between" isRowWrap align="center">
              <Text size={20} className="fw-medium text-color-grey-900">
                Filter
              </Text>
              {!!filters?.length && (
                <Toggle
                  onChange={onChangeApplyFilter}
                  defaultChecked={applyFilter}
                  name="applyFilters"
                  label="Apply Filter"
                />
              )}
            </View>
          </Grid.Item>
          <Grid.Item renderIf={object !== ViewObject.User && !hideFilterByOwner} variant="is-full">
            <Text className="text-color-grey-900">Filter by Owner</Text>
            <View className="cmp-filter__view mt-16" fullWidth>
              <RadioButton
                columns={2}
                options={filterOwnerOption}
                value={filterOwner ?? FilterOwner.AllUsers}
                onChange={(_, value) => onChangeFilterOwner(value)}
              />
            </View>
          </Grid.Item>
          <Grid.Item variant="is-full">
            <AddLogic />
          </Grid.Item>
          <Grid.Item renderIf={!!filters.length} variant="is-full">
            <FormFilterList />
          </Grid.Item>
          <Grid.Item renderIf={!filters.length} variant="is-full">
            <FormFilter />
          </Grid.Item>
          <Grid.Item renderIf={!!filters.length && filters.length < 10} variant="is-full">
            <View isRowWrap justify="flex-end" className="pb-16">
              <Button
                icon={<FaPlus />}
                label="Add Filter"
                onClick={handleOpenAddFilter}
                className="px-12"
                variant="outline"
              />
            </View>
          </Grid.Item>
        </Grid.Wrap>
      }
    />
  );
};

type Props = {
  hideFilterByOwner?: boolean;
};

export default FilterContainer;
