export enum TDI62RelatedRelationshipKey {
  Id = 'id',
  TDIPlanType = 'tdiPlanType',
  TDIPlanId = 'tdiPlanId',
  Employer = 'employer',
  EmployerName = 'employerName',
  DOLNumber = 'dolNumber',
  EmployerId = 'employerId',
  InsuranceCarrier = 'insuranceCarrier',
  InsuranceCarrierName = 'insuranceCarrierName',
  InsuranceCarrierId = 'insuranceCarrierId',
  WeeklyBenefit = 'rawWeeklyBenefit',
  DayBenefitsBeginForAccident = 'dayBenefitsBeginForAccident',
  DayBenefitsBeginForSickness = 'dayBenefitsBeginForSickness',
  MaximumBenefitPeriod = 'maximumBenefitPeriod',
}
