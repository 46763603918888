import { Skeleton } from '@mui/material';
import { Grid } from 'src/modules/shared-main/components';

const LoadingSkeleton: React.FC<Props> = ({ isLoading = false }) => {
  return (
    <Grid.Wrap renderIf={isLoading} className="pt-16">
      <Grid.Item variant="is-full">
        <Skeleton variant="text" width="45%" className="ml-8" />
        <Skeleton variant="rounded" height={60} width="100%" className="ml-8" />
      </Grid.Item>
    </Grid.Wrap>
  );
};

type Props = {
  isLoading?: boolean;
};

export default LoadingSkeleton;
