import { ComplaintKey } from './keys';

export enum WageBase {
  Commission = 'Commission',
  Hourly = 'Hourly',
  Salary = 'Salary',
  Other = 'Other',
}

export enum ComplaintStatus {
  New = 'New',
  InProgress = 'In Progress',
  Closed = 'Closed',
}

export enum ComplaintType {
  PHC = 'Prepaid Health Care (PHC)',
  TDI = 'Temporary Disability Insurance (TDI)',
  Fraud = 'Fraud',
}

export interface Complaint {
  id?: string;
  // COMPLAINANT INFORMATION
  [ComplaintKey.ComplaintType]: ComplaintType;
  [ComplaintKey.ComplaintNumber]: string;
  [ComplaintKey.ComplaintName]: string;
  [ComplaintKey.Complainant]: string;
  [ComplaintKey.ComplaintAccountId]: string;

  [ComplaintKey.ComplaintAccountPhoneNumber]: string;
  [ComplaintKey.ComplaintAccountEmail]: string;
  [ComplaintKey.ComplaintAccountAddress]: string;
  [ComplaintKey.ComplaintAccountCity]: string;
  [ComplaintKey.ComplaintAccountState]: string;
  [ComplaintKey.ComplaintAccountZipCode]: string;
  [ComplaintKey.WorkPhoneNumber]: string;
  [ComplaintKey.CellPhoneNumber]: string;
  [ComplaintKey.DateOfBirth]: string;
  [ComplaintKey.Occupation]: string;
  [ComplaintKey.RepresentativeName]: string;
  [ComplaintKey.ComplainantRelationship]: string;
  [ComplaintKey.ComplaintAddress]: string;
  [ComplaintKey.ComplaintCity]: string;
  [ComplaintKey.ComplaintState]: string;
  [ComplaintKey.ComplaintZipCode]: string;
  [ComplaintKey.ComplaintEmail]: string;

  // EMPLOYER INFORMATION
  [ComplaintKey.EmployerName]: string;
  [ComplaintKey.EmployerId]: string;
  [ComplaintKey.DoingBusinessAs]: string;
  [ComplaintKey.EmployerLaborNumber]: string;
  [ComplaintKey.EmployerEmail]: string;
  [ComplaintKey.EmployerAddress]: string;
  [ComplaintKey.EmployerCity]: string;
  [ComplaintKey.EmployerState]: string;
  [ComplaintKey.EmployerZipCode]: string;
  [ComplaintKey.EmployerMailingAddress]: string;
  [ComplaintKey.EmployerMailingCity]: string;
  [ComplaintKey.EmployerMailingState]: string;
  [ComplaintKey.EmployerMailingZipCode]: string;
  [ComplaintKey.POCName]: string;
  [ComplaintKey.POCTitle]: string;
  [ComplaintKey.POCEmail]: string;
  [ComplaintKey.POCPhoneNumber]: string;

  // DISABILITY INFORMATION
  [ComplaintKey.TDIInsuranceCarrierId]: string;
  [ComplaintKey.TDIInsuranceCarrierName]: string;
  [ComplaintKey.HealthCareContractorId]: string;
  [ComplaintKey.HealthCareContractor]: string;
  [ComplaintKey.HealthCareContractorName]: string;
  [ComplaintKey.FilingDate]: string;
  [ComplaintKey.ClaimFiledBy]: string;
  [ComplaintKey.DisabilityDate]: string;
  [ComplaintKey.DisabilityNature]: string;
  [ComplaintKey.UnionName]: string;
  [ComplaintKey.LocalNumber]: string;
  [ComplaintKey.WageBase]: WageBase;

  // COMPLAINT INFORMATION
  [ComplaintKey.ComplaintDetails]: string;
  [ComplaintKey.ComplaintId]: string;
  [ComplaintKey.ComplaintAgainstId]: string;
  [ComplaintKey.ComplaintAgainst]: string;
  [ComplaintKey.ComplaintAgainstName]: string;
  [ComplaintKey.ComplaintAgainstStreet]: string;
  [ComplaintKey.ComplaintAgainstState]: string;
  [ComplaintKey.ComplaintAgainstCity]: string;
  [ComplaintKey.ComplaintAgainstZip]: string;

  // COMPLAINT ADMINISTRATION
  [ComplaintKey.Status]: ComplaintStatus;
  [ComplaintKey.ReceivedById]: string;
  [ComplaintKey.ReceivedBy]: string;
  [ComplaintKey.ReceivedDate]: string;
  [ComplaintKey.AssignedToId]: string;
  [ComplaintKey.AssignedToName]: string;
  [ComplaintKey.AssignedDate]: string;
  [ComplaintKey.ActionTaken]: string;
  [ComplaintKey.ActionDate]: string;
  [ComplaintKey.ClosedDate]: string;
  [ComplaintKey.ClosedBy]: string;
  [ComplaintKey.ClosedByName]: string;
  [ComplaintKey.FraudComplaintMailedCopyDate]: string;
  [ComplaintKey.ResponseDueDate]: string;
  [ComplaintKey.ForwardedToHearingDate]: string;

  // CASE INFORMATION
  [ComplaintKey.CaseId]: string;
  [ComplaintKey.ClaimantId]: string;
  [ComplaintKey.ClaimantName]: string;
  [ComplaintKey.IIDate]: string;
  [ComplaintKey.CaseEmployerName]: string;
  [ComplaintKey.CaseEmployerId]: string;
  [ComplaintKey.CaseEmployerLaborNumber]: string;
  [ComplaintKey.CaseNumber]: string;

  // AUDIT INFORMATION
  [ComplaintKey.CreatedBy]: string;
  [ComplaintKey.UpdatedBy]: string;
  [ComplaintKey.UpdatedAt]: string;
  [ComplaintKey.CreatedAt]: string;
}
