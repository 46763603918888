export enum WC77Key {
  Id = 'id',
  ExternalWCFormId = 'externalWCFormId',
  ExternalWCFormNumber = 'externalWCFormNumber',
  RawDateReceived = 'rawDateReceived',
  RawSignature = 'rawSignature',
  RawSignatureDate = 'rawSignatureDate',
  WCCaseId = 'wcCaseId',
  DCDCaseNumber = 'wcCase',
  FormNumber = 'formNumber',
  HearingId = 'hearingId',
  RawHeaderCaseNumber = 'rawHeaderCaseNumber',

  ApplicantName = 'rawApplicantName',
  ApplicantAddress = 'rawApplicantAddress',
  ApplicantCity = 'rawApplicantCity',
  ApplicantState = 'rawApplicantState',
  ApplicantZipCode = 'rawApplicantZipcode',
  ApplicantPhone = 'rawApplicantPhone',
  ApplicantRepresenting = 'rawApplicantRepresenting',
  ApplicantRepOther = 'rawApplicantRepOther',

  ClaimantId = 'claimantId',

  ClaimantFirstName = 'rawClaimantFirstName',
  ClaimantLastName = 'rawClaimantLastName',
  ClaimantMiddleInitial = 'rawClaimantMiddleInitial',
  ClaimantSuffix = 'rawClaimantSuffix',
  ClaimantIdType = 'rawClaimantIdType',
  ClaimantIdNumber = 'rawClaimantIdNumber',
  ClaimantAddress = 'rawClaimantAddress',
  ClaimantCity = 'rawClaimantCity',
  ClaimantState = 'rawClaimantState',
  ClaimantZipCode = 'rawClaimantZipcode',

  EmployerId = 'employerId',

  EmployerCarrierName = 'rawEmployerCarrierName',
  EmployerCarrierAddress = 'rawEmployerCarrierAddress',
  EmployerCarrierCity = 'rawEmployerCarrierCity',
  EmployerCarrierState = 'rawEmployerCarrierState',
  EmployerCarrierZipCode = 'rawEmployerCarrierZipcode',
  DateOfInjury = 'rawDateOfInjury',

  InsuranceCarrierId = 'insuranceCarrierId',

  HearingApplicant = 'rawHearingApplicant',
  HearingDisputeSummary = 'rawHearingDisputeSummary',
  HearingIssuesStatement = 'rawHearingIssuesStatement',
  HearingIssueStatement = 'issueStatement',
  HearingOtherIssuesToBeResolved = 'otherIssuesToBeResolved',

  Witnesses = 'rawWitnesses',
  Witness1Name = 'rawWitness1Name',
  Witness1WorkPhone = 'rawWitness1WorkPhone',
  Witness1HomePhone = 'rawWitness1HomePhone',
  Witness1Address = 'rawWitness1Address',
  Witness1City = 'rawWitness1City',
  Witness1State = 'rawWitness1State',
  Witness1ZipCode = 'rawWitness1Zipcode',

  Witness2Name = 'rawWitness2Name',
  Witness2WorkPhone = 'rawWitness2WorkPhone',
  Witness2HomePhone = 'rawWitness2HomePhone',
  Witness2Address = 'rawWitness2Address',
  Witness2City = 'rawWitness2City',
  Witness2State = 'rawWitness2State',
  Witness2ZipCode = 'rawWitness2Zipcode',

  Witness3Name = 'rawWitness3Name',
  Witness3WorkPhone = 'rawWitness3WorkPhone',
  Witness3HomePhone = 'rawWitness3HomePhone',
  Witness3Address = 'rawWitness3Address',
  Witness3City = 'rawWitness3City',
  Witness3State = 'rawWitness3State',
  Witness3ZipCode = 'rawWitness3Zipcode',

  WitnessName = 'name',
  WitnessWorkPhone = 'workPhone',
  WitnessHomePhone = 'homePhone',
  WitnessState = 'state',
  WitnessAddress = 'address',
  WitnessCity = 'city',
  WitnessZipCode = 'zipcode',

  HearingSpecialConditions = 'rawSpecialConditions',
  HearingDeniedReasons = 'hearingDeniedReasons',
  HearingsReviewSection = 'hearingsReviewSection',
  OfficeUseOther = 'officeUseOther',
  OfficeUseDate = 'officeUseDate',

  OfficeUseApprovedBy = 'officeUseApprovedBy',
  OfficeUseApprovedDate = 'officeUseApprovedDate',

  //============= Filter Keys ======================

  IsReviewEmployersDenialOfHealthCare = 'isReviewEmployersDenialOfHealthCare',
  IsCompensability = 'isCompensability',
  IsTerminationOfTTD = 'isTerminationOfTTD',
  IsTerminationOfTPD = 'isTerminationOfTPD',
  IsPermanentDisability = 'isPermanentDisability',
  IsDisfigurement = 'isDisfigurement',
  IsDependentOfDeathBenefits = 'isDependentOfDeathBenefits',
  IsConcurrentEmployment = 'isConcurrentEmployment',
  IsReopening = 'isReopening',
  HasOtherIssues = 'hasOtherIssues',

  DCDCaseNumberFilter = 'wcCase.caseNumber',
  HearingNumber = 'hearing.hearingNumber',
  ClaimantName = 'claimant.accountName',
  EmployerName = 'employer.accountName',
  InsuranceCarrierName = 'insuranceCarrier.accountName',
  PortalFormNumber = 'wcForm.wcFormNumber',
}
