export enum TDISFCaseKey {
  Id = 'id',
  CaseNumber = 'tdiSFCaseNumber',
  TDISFCaseFormattedNumber = 'tdiSFCaseFormattedNumber',
  // Case Information
  ClaimantId = 'claimantId',
  EmployerId = 'employerId',
  Status = 'caseStatus',
  StatusLastChangeDate = 'lastStatusDate',
  DisabilityDate = 'disabilityDate',
  ReceivedDate = 'receivedDate',
  DenialReason = 'denialReason',
  DenialOtherReason = 'otherReason',

  // Special Fund Information
  BenefitYearStartDate = 'benefitYearStartDate',
  BenefitYearEndDate = 'benefitYearEndDate',
  WeeklyBenefitAmount = 'weeklyBenefitAmount',
  SpecialFundMaximumPayment = 'specialFundMaxPayment',
  MaximumDuration = 'maxDurationResult',
  MaxDurationAmount = 'maxDurationAmount',
  MaxDurationWeeks = 'maxDurationWeeks',
  TypeOfClaim = 'typeOfClaim',
  TotalPayment = 'totalPayment',
  RunningTotalOfBenefitPaid = 'runningTotalBenefitsPaid',

  // Claimant Information
  ClaimantName = 'claimant',
  ClaimantPhone = 'claimantPhone',
  ClaimantDateOfBirth = 'claimantDOB',
  ClaimantDateOfDeath = 'claimantOfDateOfDeath',
  ClaimantSSN = 'claimant.ssn',
  ClaimantDOB = 'claimant.dateOfBirth',
  ClaimantAddress = 'claimantAddress',
  ClaimantPhoneNumber = 'claimant.phone',
  ClaimantEmail = 'claimant.email',
  ClaimantSex = 'claimant.gender',
  ClaimantCity = 'claimant.city',
  ClaimantStreet = 'claimant.street',
  ClaimantState = 'claimant.state',
  ClaimantZip = 'claimant.zip',
  ClaimantMaritalStatus = 'claimant.martialStatus',
  ClaimantRepresentative = 'claimantRepresentative',
  RelationShipToClaimant = 'relationshipToClaimant',
  SSN = 'ssn',

  // Disability Information
  DisabilityDescription = 'disabilityDescription',
  FirstDateDisabled = 'firstDateDisabled',
  IsRecoveredFromDisability = 'isRecoveredDisability',
  DateRecovered = 'dateRecovered',

  // Employer Information
  EmployerName = 'employer',
  EmployerAddress = 'employerAddress',
  EmployerStatus = 'employer.dcdStatus',
  EmployerStatusDate = 'employer.dcdStatusDate',
  EmployerPosition = 'employerPosition',
  EmployerStartDate = 'employerStartDate',
  EmployerEndDate = 'employerEndDate',
  EmployerOccupation = 'employerOccupation',
  EmployerCity = 'employer.city',
  EmployerStreet = 'employer.street',
  EmployerState = 'employer.state',
  EmployerZip = 'employer.zip',

  // Unemployment Insurance (UI) Claim Information
  IsFiledForUI = 'isFiledForUIBenefitsAfterLastJob',
  DateLastReportedToUI = 'dateLastReportedToUIOffice',
  UIWeeklyBenefitAmount = 'uiWeeklyBenefitAmount',
  NoClaimReason = 'noClaimOrNoUIRecReason',

  // Other Benefit
  ReceiveBenefitFrom = 'claimingOrBenefitsFrom',
  IsFiledTDI = 'isFiledTDIThroughEmployer',
  TDIClaimStatus = 'statusTDIClaim',

  // Medical Certification
  PhysicianName = 'physician.name',
  PhysicianId = 'physicianId',
  PhysicianAddress = 'physicianAddress',
  PhysicianCity = 'physician.city',
  PhysicianStreet = 'physician.street',
  PhysicianState = 'physician.state',
  PhysicianZip = 'physician.zip',
  PhysicianPhoneNumber = 'physician.phone',
  Diagnosis = 'diagnosis',
  DisabilityDueToNormalPregnancy = 'isDisabilityDueToNormalPregnancy',
  DueToComplicationsOfPregnancy = 'isDueToComplicationsOfPregnancy',
  DisabilityCausedByEmployment = 'isDisabilityCausedByEmployment',
  IsWC2Filed = 'isWC2Filed',
  WC2FiledWith = 'wc2FiledWith',
  ClaimantHospitalizedStartDate = 'claimantHospitalizedStartDate',
  ClaimantHospitalizedEndDate = 'claimantHospitalizedEndDate',
  IsSurgeryIndicated = 'isSurgeryIndicated',
  SurgeryType = 'surgeryType',
  DateOfFirstTreatment = 'firstTreatmentDate',
  FirstDateClaimantUnableToWork = 'firstDateClaimantUnableToWork',
  MostRecentTreatmentDate = 'mostRecentTreatmentDate',
  DischargedDate = 'dateDischargedOrAbleToPerformWork',
  Remarks = 'remarks',

  CreatedBy = 'createdBy',
  UpdatedBy = 'updatedBy',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
}
