import { PaginationResponseType, toRelatedAuditData } from '../helpers';
import { RelatedPartyNameAddressKey, RelatedPartyNamesAddressPayLoad } from './types';

export const toRelatedPartyNamesAddress = (
  data
): PaginationResponseType<RelatedPartyNamesAddressPayLoad> => ({
  ...data,
  data: data.data.map((item) => ({
    ...item,
    ...toRelatedAuditData(item),
    [RelatedPartyNameAddressKey.AccountName]: item?.account?.accountName,
    [RelatedPartyNameAddressKey.ContactName]: item?.contact?.contactName,
  })),
});
