import { GLAccountKey, GLAccountRelatedType, RelatedGLAccountKey } from './keys';

export interface GLAccount {
  [GLAccountKey.Id]?: string;
  [GLAccountKey.GLNumber]?: number;
  [GLAccountKey.AccountType]?: GLAccountType;
  [GLAccountKey.AccountDescription]?: string;
  [GLAccountKey.AccountNotes]?: string;
  [GLAccountKey.UpdatedAt]?: string;
  [GLAccountKey.CreatedAt]?: string;
  [GLAccountKey.CreatedBy]?: string;
  [GLAccountKey.UpdatedBy]?: string;
}

export interface GLAccountRelated {
  [GLAccountKey.Id]: string;
  [GLAccountKey.GLNumber]?: number;
  [GLAccountKey.AccountType]?: GLAccountType;
  [GLAccountKey.AccountDescription]?: string;
  [GLAccountKey.AccountNotes]?: string;
  [GLAccountKey.UpdatedAt]?: string;
  [GLAccountKey.CreatedAt]?: string;
  [GLAccountKey.CreatedBy]?: string;
  [GLAccountKey.UpdatedBy]?: string;
  [GLAccountKey.RelationshipId]?: string;
  [GLAccountKey.RelatedType]: GLAccountRelatedType;
}

export interface RelatedGLAccountPayload {
  id?: string;
  [RelatedGLAccountKey.RelatedGLAccountId]: string;
  [RelatedGLAccountKey.GLAccountId]?: string;
  [RelatedGLAccountKey.AccountDescription]: string;
  [RelatedGLAccountKey.AccountType]: GLAccountType;
  [RelatedGLAccountKey.RelatedType]: GLAccountRelatedType;
  [RelatedGLAccountKey.GLAccountRelationId]?: string;
}

export enum GLAccountType {
  Asset = 'ASSET',
  Expense = 'EXPENSE',
  FundBalance = 'FUND_BALANCE',
  Liabiality = 'LIABILITY',
  Revenue = 'REVENUE',
}
