import { getFirstLastName } from 'src/modules/shared-main/common';
import { isEmpty } from 'src/validations';
import { PaginationResponseType, toAuditData } from '../helpers';
import { TDIPlanKey } from './keys';
import { TDIPlan } from './types';

export const toTdiPlanDetail = (data) => {
  if (!data) return null;

  return {
    ...data,
    [TDIPlanKey.InsuranceCarrierCarrierId]: data?.insuranceCarrier?.carrierId,
    [TDIPlanKey.EmployerObject]: data?.employer,
    [TDIPlanKey.AssignedUser]: getFirstLastName(data?.assignedUser),
    [TDIPlanKey.TDI15]: data?.tdi15?.tdi15Number,
    [TDIPlanKey.ClaimsAdmin]: data?.claimsAdmin?.accountName,
    [TDIPlanKey.EmployerAudit]: data?.mostRecentEmployerAudit?.employerAuditNumber,
    [TDIPlanKey.EmployerAuditStatus]: data?.mostRecentEmployerAudit?.status,
    [TDIPlanKey.Auditor]: getFirstLastName(data?.mostRecentEmployerAudit?.auditor),
    [TDIPlanKey.EmployerAuditCompletedDate]: data?.mostRecentEmployerAudit?.completedDate,
    [TDIPlanKey.Plan1]: toTdiPlanDetail(data?.[TDIPlanKey.Plan1]),
    [TDIPlanKey.Plan2]: toTdiPlanDetail(data?.[TDIPlanKey.Plan2]),
    ...toAuditData(data),
  };
};

export const toTDIPlans = (data): PaginationResponseType<TDIPlan> => {
  return {
    ...data,
    data: data?.data?.map((item) => ({
      ...item,
      [TDIPlanKey.CarrierID]: item?.insuranceCarrier?.carrierId,
      [TDIPlanKey.InsuranceCarrierId]: item?.insuranceCarrier?.id,
      [TDIPlanKey.InsuranceCarrierStatusDate]: item?.insuranceCarrier?.dcdStatusDate,
      [TDIPlanKey.Employer]: item?.employer?.accountName,
      [TDIPlanKey.EmployerId]: item?.employer?.id,
      [TDIPlanKey.EmployerDOLNumber]: item?.employer?.laborNumber,
      [TDIPlanKey.DCDStatusDate]: item?.employer?.dcdStatusDate,
      [TDIPlanKey.DCDStatus]: item?.employer?.dcdStatus,
      [TDIPlanKey.InsuranceCarrierStatus]: item?.insuranceCarrier?.dcdStatus,
      [TDIPlanKey.NumberOfEmployees]: isEmpty(item?.numberOfEmployees)
        ? ''
        : item?.numberOfEmployees?.toString(),
      [TDIPlanKey.TDI15]: item?.tdi15?.tdi15Number,
      [TDIPlanKey.TDI15Id]: item?.tdi15?.id,
      [TDIPlanKey.ClaimsAdmin]: item?.claimsAdmin?.accountName,
      [TDIPlanKey.ClaimsAdminId]: item?.claimsAdmin?.id,
      [TDIPlanKey.EmployerAudit]: item?.mostRecentEmployerAudit?.employerAuditNumber,
      [TDIPlanKey.EmployerAuditId]: item?.mostRecentEmployerAudit?.id,
      [TDIPlanKey.EmployerAuditStatus]: item?.mostRecentEmployerAudit?.status,
      [TDIPlanKey.EmployerAuditCompletedDate]: item?.mostRecentEmployerAudit?.completedDate,
      [TDIPlanKey.Auditor]: getFirstLastName(item?.mostRecentEmployerAudit?.auditor),

      [TDIPlanKey.AssignedUser]: getFirstLastName(item?.assignedUser),

      ...toAuditData(item),
    })),
  };
};
