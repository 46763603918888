import { AccountDetail } from '../Accounts';
import { DCDCase } from '../DCDCases';
import { ApprovalHistoryKey, ApprovalObjectType } from '../RelatedApprovalHistory';
import { TDICase } from '../TDICases';
import { TDISFCase } from '../TDISFCases';
import {
  AppealDecisionInformation,
  DCDecisionInformation,
  DecisionFormat,
  DecisionKey,
  DecisionStatus,
  DecisionTextKey,
  DecisionType,
  Source,
} from './keys';

export interface DecisionPayLoad {
  [DecisionKey.Id]?: string;
  [DecisionKey.DecisionType]: DecisionType;
  [DecisionKey.CaseId]?: string;
  [DecisionKey.DecisionNumber]?: string;
  [DecisionKey.CaseNumber]?: string;
  [DecisionKey.Claimant]?: AccountDetail;
  [DecisionKey.ClaimantName]?: string;
  [DecisionKey.ClaimantSSN]?: string;
  [DecisionKey.DateOfII]?: string;
  [DecisionKey.DateOfDeath]?: string;
  [DecisionKey.FiledBy]?: AccountDetail;
  [DecisionKey.TreatmentDate]?: string;
  [DecisionKey.FiledById]?: string;
  [DecisionKey.IsDecisionReadyToBeMailed]?: boolean;
  [DecisionKey.DecisionReadyToBeMailedDate]?: string;
  [DecisionKey.DecisionMailedDate]?: string;
  [DecisionKey.HearingOfficer]?: string;
  [DecisionKey.AppealedDecision]?: string;
  [DecisionKey.AppealedDecisionId]?: string;
  [DecisionKey.AmendedDecision]?: string;
  [DecisionKey.AmendedDecisionId]?: string;
  [DecisionKey.ReopenedDecision]?: string;
  [DecisionKey.ReopenedDecisionId]?: string;
  [DecisionKey.MotionStay]?: boolean;
  [DecisionKey.CurrentServicingLocation]?: string;
  [DecisionKey.InvolveSCF]?: boolean;

  [DecisionKey.DecisionFormat]?: DecisionFormat;
  [DecisionKey.DCDecisionInformation]?: DCDecisionInformation[];
  [DecisionKey.AppealDecisionInformation]?: AppealDecisionInformation[];
  [DecisionKey.Source]?: Source;

  [DecisionKey.Hearing]?: string;
  [DecisionKey.HearingOfficerFromHearing]?: string;
  [DecisionKey.DateOfHearingRequest]?: string;
  [DecisionKey.HearingNoticeMailDate]?: string;
  [DecisionKey.HearingDate]?: string;

  [DecisionKey.DecisionStatus]?: DecisionStatus;
  [DecisionKey.DecisionStatusDate]?: string;
  [DecisionKey.DecisionNotes]?: string;
  [DecisionKey.OperationalNotes]?: string;
  [DecisionKey.DecisionFinalizedDate]?: string;
  [DecisionKey.DueDateExtensionNeeded]?: boolean;

  [DecisionKey.DecisionDueDate]?: string;
  [DecisionKey.DecisionCompletedDate]?: string;
  [DecisionKey.AssignedTo]?: string;
  [DecisionKey.AssignedUserId]?: string;
  [DecisionKey.DecisionAdministrator]?: string;
  [DecisionKey.ClericalServices]?: string;
  [DecisionKey.DecisionFinalizer]?: string;
  [DecisionKey.MailProcessor]?: string;
  [DecisionKey.DecisionProcessor]?: string;
  [DecisionKey.DecisionReviewer]?: string;
  [DecisionKey.HearingId]?: string;
  [DecisionKey.DistrictLevelReviewer]?: string;
  [DecisionKey.CaseAdministrator]?: string;

  [DecisionKey.AdministratorApprovedDate]?: string;
  [DecisionKey.ReviewerApprovedDate]?: string;

  [DecisionKey.JacketBorrowedDate]?: string;
  [DecisionKey.JacketReturnedLectrieverDate]?: string;

  [DecisionKey.TDICase]: TDICase;
  [DecisionKey.TDICaseNumber]?: string;

  [DecisionKey.CreatedByUser]?: string;
  [DecisionKey.Case]?: DCDCase;
  [DecisionKey.CreatedAt]?: string;
  [DecisionKey.HearingNumber]?: string;
  [DecisionKey.CreatedBy]?: string;
  [DecisionKey.UpdatedBy]?: string;

  // TDI Decision
  [DecisionKey.TDISFCaseNumber]?: string;
  [DecisionKey.TDISFCaseId]?: string;
  [DecisionKey.TDIClaimantId]?: string;
  [DecisionKey.TDICaseNumber]?: string;
  [DecisionKey.TDICaseId]?: string;
  [DecisionKey.RefereeUser]?: string;
  [DecisionKey.FirstDateOfDisabilityClaimed]?: string;
  [DecisionKey.DisabilityDate]?: string;
  [DecisionKey.TDISFCase]?: TDISFCase;
  [DecisionKey.DecisionBenefits]?: DecisionBenefits;
  [DecisionKey.AffirmModifyPreviousDecision]?: AffirmModifyDecision;
  [DecisionKey.RefereeDecision]?: string;
  [DecisionKey.RefereeHearingId]?: string;
  [DecisionKey.RefereeHearing]?: AccountDetail;
  [DecisionKey.CopyCertifiedBy]?: AccountDetail;
  [DecisionKey.AdditionalHOCertifiedDECApproval]?: AccountDetail;
  [DecisionKey.AdditionalHearingOfficerApprovalDate]?: string;
}

export type DecisionSubmitApprovalPayload = {
  [ApprovalHistoryKey.ObjectId]?: string;
  [ApprovalHistoryKey.Comments]?: string;
  [ApprovalHistoryKey.ObjectType]?: ApprovalObjectType;
  [ApprovalHistoryKey.AssignedToId]?: number;
  [ApprovalHistoryKey.AssignedTo]?: string;
};

export interface DecisionTextPayLoad {
  [DecisionKey.Id]?: string;
  [DecisionTextKey.Introduction]?: string;
  [DecisionTextKey.Issues]?: string;
  [DecisionTextKey.PartiesPositions]?: string;
  [DecisionTextKey.FindingsFact]?: string;
  [DecisionTextKey.ConclusionsLaw]?: string;
  [DecisionTextKey.PrinciplesLaw]?: string;
  [DecisionTextKey.DecisionAndOrder]?: string;
  [DecisionTextKey.TreatmentDecisionText]?: string;
}

export interface RelatedDecision {
  [DecisionKey.Id]?: string;
  [DecisionKey.DecisionNumber]?: string;
  [DecisionKey.ClaimantName]?: string;
  [DecisionKey.DCDecisionInformation]?: string;
  [DecisionKey.DecisionStatus]?: string;
  [DecisionKey.Source]?: string;

  [DecisionKey.CreatedByUser]?: string;
  [DecisionKey.CreatedAt]?: string;
  [DecisionKey.CreatedBy]?: string;
  [DecisionKey.UpdatedBy]?: string;
}

export enum DecisionBenefits {
  DecisionAllowsBenefits = 'Decision Allows Benefits',
  DecisionModifiesBenefits = 'Decision Modifies Benefits',
  DecisionDeniesBenefits = 'Decision Denies Benefits',
}

export enum AffirmModifyDecision {
  Affirm = 'Affirm',
  Modify = 'Modify',
}
