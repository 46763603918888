import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { ApiResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { toPHCCoverageDetail } from './helpers';
import { PHCCoverage } from './types';

export function useGetPHCCoverage(
  options?: UseQueryOptions<ApiResponseType<{ phcCoverage: PHCCoverage }>, Error, PHCCoverage> & {
    id: PHCCoverage['id'];
  }
) {
  const {
    data: phcCoverage,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetPHCCoverageById,
  } = useQuery<ApiResponseType<{ phcCoverage: PHCCoverage }>, Error, PHCCoverage>(
    [API_QUERIES.PHC_COVERAGES, { id: options.id }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<{ phcCoverage: PHCCoverage }>>(
          apiClient.getPHCCoverageDetail,
          params
        );
      },
      select: (data) => toPHCCoverageDetail(data?.data?.phcCoverage),
      enabled: !!options.id,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidatePHCCoverage = () =>
    queryClient.invalidateQueries([API_QUERIES.PHC_COVERAGES, { id: options.id }]);

  return {
    phcCoverage,
    error,
    isError,
    isLoading,
    onGetPHCCoverageById,
    handleInvalidatePHCCoverage,
  };
}
