import { formatDate } from 'src/utils';
import { ApiResponseType } from '../helpers';
import { TDI62sReportKey } from './keys';
import { TDI62sReportResponse } from './types';

export const toTDI62sReport = (data): ApiResponseType<TDI62sReportResponse> => {
  const { reports } = data?.data || {};
  const formattedReports = reports?.data.map((report) => ({
    ...report,
    [TDI62sReportKey.ActionAccepted]: report?.actionAccepted,
    [TDI62sReportKey.ActionRejected]: report?.actionRejected,
    [TDI62sReportKey.DateReceived]: `${formatDate(
      report?.[TDI62sReportKey.StartDate]
    )} - ${formatDate(report?.[TDI62sReportKey.EndDate])}`,
  }));

  return {
    ...data,
    data: {
      ...data?.data,
      reports: {
        ...reports,
        data: formattedReports,
      },
    },
  };
};
