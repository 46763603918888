export enum UserKey {
  Name = 'fullName',
  Alias = 'alias',
  Username = 'username',
  Email = 'email',
  LastLoginDate = 'lastLogin',
  Status = 'status',
  Profile = 'profiles',
  RoleName = 'roleName',
  FullName = 'fullName',
  FirstName = 'firstName',
  LastName = 'lastName',
  MiddleName = 'middleName',
  Suffix = 'suffix',
  Nickname = 'nickname',
  EmailEncoding = 'emailEncoding',
  IsClassicOnly = 'isClassicOnly',
  Extension = 'extension',
  IsHighContrast = 'isHighContrast',
  Phone = 'phone',
  Fax = 'fax',
  Mobile = 'mobile',
  Company = 'company',
  Title = 'title',
  Department = 'department',
  Division = 'division',
  EmployeeNumber = 'employeeNumber',
  Individual = 'individual',
  StartOfDay = 'startDate',
  EndOfDay = 'endDate',
  Street = 'street',
  City = 'city',
  State = 'state',
  ZipCode = 'zipCode',
  Country = 'country',
  Federation = 'federationId',
  ExternalId = 'externalId',
  HOLevel = 'hoLevel',
  IsAvailableForHearing = 'isAvailableForHearing',
  IsIgnoreAutomation = 'isIgnoreAutomation',
  IsAbleToUnlockDecisions = 'isAbleToUnlockDecisions',
  Language = 'language',
  TimeZone = 'timeZone',
  Locale = 'locale',
  DelegatedApprover = 'delegatedApprover',
  Manager = 'manager',
  ReceiveApprovalRequestEmails = 'receiveApprovalRequestEmails',
  Role = 'role',
  ManagerId = 'managerId',
  ProfileId = 'permissionGroupId',
  DelegatedApproverId = 'delegatedApproverId',
  IndividualId = 'individualId',
}

export enum ApprovalGroupsUserKey {
  Name = 'name',
  Type = 'type',
}

export interface ProfileGroup {
  id: string;
  name: string;
}

export enum UserStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export interface User {
  firstName: string;
  lastName: string;
  name: string;
  alias: string;
  username: string;
  email: string;
  lastLogin: string;
  status: UserStatus;
  profile: {
    name: string;
    id: string;
  };
  role: string;
  id: string;
  profileId: string;
}

export type DelegateAccess = {
  delegatedUserId: any;
  roleName: string;
  startDate: string;
  endDate: string;
  isAllProjects: boolean;
  projectNumber: string;
  userId?: string;
  roleId?: string;
  delegatedUser?: {
    id: string;
    createdAt: string;
    updatedAt: string;
    firstName: string;
    lastName: string;
    middleName: string;
    fullName: string;
    username: string;
    email: string;
    lastLoginDate: string;
    isDhUser: boolean;
    allowMaintenanceModeLogin: boolean;
    passwordSetDate: string;
    comments: string;
    defaultUserType: string;
    status: UserStatus;
  };
};

export type AddUserPayload = {
  id?: string;
  individualId: string;
  firstName: string;
  lastName: string;
  middleName: string;
  suffix: string;
  alias: string;
  nickname: string;
  email: string;
  phone: string;
  isClassicOnly: boolean;
  isHighContrast: boolean;
  extension: string;
  fax: string;
  mobile: string;
  emailEncoding: string;
  status: UserStatus;
  company: string;
  title: string;
  department: string;
  division: string;
  employeeNumber: string;
  startDate: Date | string;
  endDate: string | Date;
  roleName: string;
  profile: string | number;
  street: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  federationId: string;
  externalId: string;
  hoLevel: string;
  isAvailableForHearing: boolean;
  isIgnoreAutomation: boolean;
  isAbleToUnlockDecisions: boolean;
  timeZone: string;
  locale: string;
  language: string;
  receiveApprovalRequestEmails: string;
  delegatedApproverId: string;
  managerId: string;
};

export type UserRole = {
  id: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  displayName: string;
  description: string;
  canBeUpdated: boolean;
  canBeDeleted: boolean;
  domain: string;
};

export type UserDetail = {
  id: string;
  firstName: string;
  lastName: string;
  middleName: string;
  fullName: string;
  username: string;
  email: string;
  userType: string;
  status: UserStatus;
  delegatedApproverId: string;
  managerId: string;
  individualId: string;
  individual: {
    firstName: string;
    lastName: string;
    fullName: string;
  };
  roles: [
    {
      userId: string;
      roleId: string;
      role: {
        id: string;
        name: string;
        displayName: string;
        description: string;
      };
    }
  ];
  permissionGroup: {
    id: number;
    resourceName: string;
  };
  profile: {
    avatar: string;
    id: string;
    suffix: string;
    alias: string;
    nickname: string;
    phone: string;
    isClassicOnly: boolean;
    isHighContrast: boolean;
    extension: string;
    fax: string;
    mobile: string;
    emailEncoding: string;
    userId: string;
    company: string;
    title: string;
    department: string;
    division: string;
    employeeNumber: string;
    startDate: string;
    endDate: string;
    roleName: string;
    street: string;
    city: string;
    state: string;
    zipCode: string;
    country: string;
    federationId: string;
    externalId: string;
    hoLevel: string;
    isAvailableForHearing: boolean;
    isIgnoreAutomation: boolean;
    isAbleToUnlockDecisions: boolean;
    timeZone: string;
    locale: string;
    language: string;
    receiveApprovalRequestEmails: string;
  };
  delegatedApprover: {
    id: string;
    fullName: string;
    firstName: string;
    lastName: string;
  };
  manager: {
    id: string;
    fullName: string;
    firstName: string;
    lastName: string;
  };
  approvalGroups: approvalGroup[];
};

export interface approvalGroup {
  id: string;
  name: string;
  type: string;
}

export interface SearchUser {}
export type SearchProject = {
  id: string;
  createdAt: string;
  updatedAt: string;
  referenceId: number;
  referenceTable: string;
  projectNumber: string;
};

export interface StatusPayload {
  id: string;
  action: string;
}

export type ResetUsersPasswordPayload = string[];

export interface LanguageFromZipFolder {
  language: string;
}

export interface LocaleFromZipFolder {
  locale: string;
}
