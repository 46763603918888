import { AccountDetail, DCDStatus } from '../Accounts';
import { PlanType, TDIPlanKey } from './keys';

export interface TDIPlan {
  [TDIPlanKey.Id]?: string;
  [TDIPlanKey.PlanType]?: PlanType;
  [TDIPlanKey.PlanNameNumber]?: string;
  [TDIPlanKey.ProcessingStatus]?: TDIPlanProcessingStatus;
  [TDIPlanKey.InsuranceCarrierIdSchema]?: string;
  [TDIPlanKey.CarrierIDSchema]?: string;
  [TDIPlanKey.TDIPlanNumber]?: string;
  [TDIPlanKey.InsuranceCarrierIdSchema]?: string;
  [TDIPlanKey.CarrierIDSchema]?: string;
  [TDIPlanKey.InsuranceCarrier]?: AccountDetail;
  [TDIPlanKey.InsuranceCarrierStatusSchema]?: string;
  [TDIPlanKey.InsuranceCarrierStatusDateSchema]?: string;
  [TDIPlanKey.NameOfUnion]?: string;
  [TDIPlanKey.CBAEffectiveDate]?: string;
  [TDIPlanKey.CBAExpiredDate]?: string;
  [TDIPlanKey.AssociationCode]?: string;
  [TDIPlanKey.Solvency]?: Solvency;
  [TDIPlanKey.EmployerAccountNameSchema]?: string;
  [TDIPlanKey.EmployerDOLNumberSchema]?: string;
  [TDIPlanKey.EmployerDCDStatusSchema]?: string;
  [TDIPlanKey.EmployerDCDStatusDateSchema]?: string;
  [TDIPlanKey.InsuranceCarrierId]?: string;
  [TDIPlanKey.InsuranceCarrierCarrierId]?: string;
  [TDIPlanKey.CarrierID]?: string;
  [TDIPlanKey.InsuranceCarrierStatus]?: DCDStatus;
  [TDIPlanKey.InsuranceCarrierStatusDate]?: string;
  [TDIPlanKey.CBAEffectiveDate]?: string;
  [TDIPlanKey.CBAExpiredDate]?: string;
  [TDIPlanKey.EmployerName]?: string;
  [TDIPlanKey.EmployerId]?: string;
  [TDIPlanKey.Employer]?: string;
  [TDIPlanKey.EmployerObject]?: AccountDetail;
  [TDIPlanKey.EmployerDOLNumber]?: string;
  [TDIPlanKey.DCDStatusDate]?: string;
  [TDIPlanKey.EmployerDCDStatusDate]?: string;
  [TDIPlanKey.SelfInsurePlanStatus]?: SelfInsurePlanStatus;
  [TDIPlanKey.NumberOfEmployees]?: string;
  [TDIPlanKey.Plan1]?: TDIPlan;
  [TDIPlanKey.Plan2]?: TDIPlan;
  [TDIPlanKey.IsFinanciallySolvent]?: boolean;
  [TDIPlanKey.RequiredDeposit]?: number;
  [TDIPlanKey.DepositAmount]?: number;
  [TDIPlanKey.DepositDate]?: string;
  [TDIPlanKey.FinancialStatementYearEnding]?: string;
  [TDIPlanKey.Comment]?: string;
  [TDIPlanKey.IsBond]?: boolean;
  [TDIPlanKey.Description]?: string;
  [TDIPlanKey.Tenure]?: string;
  [TDIPlanKey.CoverageEquivalency]?: Array<CoverageEquivalency>;
  [TDIPlanKey.IllnessCalendarDayBenefitsCommence]?: number;
  [TDIPlanKey.AccidentCalendarDayBenefitsCommence]?: number;
  [TDIPlanKey.InitialDurationOfBenefits]?: number;
  [TDIPlanKey.InitialWageLossReplaced]?: number;
  [TDIPlanKey.SubsequentWageLossReplaced]?: number;
  [TDIPlanKey.SubsequentDurationOfBenefits]?: number;
  [TDIPlanKey.EquivalencyValueOfBenefitSchedule]?: number;
  [TDIPlanKey.TDIPlanID]?: string;

  // Plan Review
  [TDIPlanKey.DC17ReceivedDate]?: string;
  [TDIPlanKey.CBAReceivedDate]?: string;
  [TDIPlanKey.DC17Title]?: string;
  [TDIPlanKey.CBATitle]?: string;
  [TDIPlanKey.DateEmployerConfirmationLetterSent]?: string;
  [TDIPlanKey.PlanStatus]?: TDIPlanStatus;
  [TDIPlanKey.PlanStatusDate]?: string;
  [TDIPlanKey.RejectionReason]?: string;
  [TDIPlanKey.AssignedUserId]?: string;
  [TDIPlanKey.TDI15]?: string;
  [TDIPlanKey.TDI15Id]?: string;
  [TDIPlanKey.PlanDocumentReceivedDate]?: string;
  [TDIPlanKey.BondCDReceivedDate]?: string;
  [TDIPlanKey.FinancialStatementsReceivedDate]?: string;
  [TDIPlanKey.LetterOfGuaranteeReceivedDate]?: string;
  [TDIPlanKey.ClaimsAdminId]?: string;
  [TDIPlanKey.PlanDocumentTitle]?: string;
  [TDIPlanKey.BondCDTitle]?: string;
  [TDIPlanKey.FinancialStatementsTitle]?: string;
  [TDIPlanKey.LetterOfGuaranteeTitle]?: string;
  [TDIPlanKey.EmployerAudit]?: string;
  [TDIPlanKey.EmployerAuditId]?: string;

  [TDIPlanKey.CreatedUser]?: string;
  [TDIPlanKey.UpdatedUser]?: string;
  [TDIPlanKey.CreatedBy]?: string;
  [TDIPlanKey.UpdatedBy]?: string;
  [TDIPlanKey.CreatedAt]?: string;
  [TDIPlanKey.UpdatedAt]?: string;
}

export interface TdiPlanType {
  isBetterThanStatutory: boolean;
  isStatutory: boolean;
  isCBA: boolean;
  isSelfInsured: boolean;
  isCombo: boolean;
}

export interface CoverageEquivalency {
  [TDIPlanKey.Id]: string;
  [TDIPlanKey.Tenure]: string;
  [TDIPlanKey.AccidentCalendarDayBenefitsCommence]: number;
  [TDIPlanKey.IllnessCalendarDayBenefitsCommence]: number;
  [TDIPlanKey.InitialDurationOfBenefits]: number;
  [TDIPlanKey.InitialWageLossReplaced]: string;
  [TDIPlanKey.EquivalencyValueOfBenefitSchedule]: number;
  [TDIPlanKey.SubsequentWageLossReplaced]: string;
  [TDIPlanKey.SubsequentDurationOfBenefits]: string;
}

export enum TDIPlanProcessingStatus {
  New = 'New',
  PlansReview = 'Plans Review',
  AuditReview = 'Audit Review',
  EmployerReview = 'Employer Review',
  Completed = 'Completed',
}

export enum SelfInsurePlanStatus {
  Approved = 'Approved',
  Renewed = 'Renewed',
  Cancelled = 'Cancelled',
  InReview = 'In Review',
}

export enum Solvency {
  BondCD = 'Bond/CD',
  FinanciallySolvent = 'Financially Solvent',
}

export enum TDIPlanStatus {
  PlanPendingReview = 'Plan Pending Review',
  PlanAccepted = 'Plan Accepted',
  PlanRejectedResponseNeeded = 'Plan Rejected - Response Needed',
  PlanWithdrawn = 'Plan Withdrawn',
  PlanCancelled = 'Plan Cancelled',
}

export interface CalculateEquivalencyPayload {
  accidentCalendarDayBenefitsCommence: number;
  illness: number;
  initialWage: number;
  initialDuration: number;
  subsequentWage: number;
  subsequentDuration: number;
  enabled?: boolean;
}
