import { Box, Menu } from '@mui/material';
import cn from 'classnames';
import React, { useState } from 'react';
import { IoNotifications } from 'react-icons/io5';
import { Text, View } from 'src/modules/shared-main/components';
import { COLOR_CODE } from '../../common/appConfig/constants';
import { useLazyNotifications, useMarkAllReadNotifications } from '../../queries';
import { ViewMode } from './helpers';
import NotificationItems from './NotificationItems';

const NavNotification: React.FC = () => {
  const [view, setView] = useState(ViewMode.Notification);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const { notifications, handleInvalidateLazyNotification } = useLazyNotifications();

  const { markAllReadNotifications } = useMarkAllReadNotifications({
    onSuccess() {
      handleInvalidateLazyNotification();
    },
  });

  const isAllRead = notifications.every(({ isRead }) => isRead);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    handleInvalidateLazyNotification();
    setAnchorEl(event.currentTarget);
  };

  const handleMarkAllReadNotifications = () => {
    markAllReadNotifications();
  };

  const handleChangeView = (view: ViewMode) => {
    setView(view);
  };

  const handleClose = () => {
    setView(ViewMode.Notification);
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <View
        isRowWrap
        align={'center'}
        onClick={handleClick}
        style={{
          cursor: 'pointer',
          marginRight: '10px',
        }}
      >
        <Text className="ctn-navbar__text" size={38}>
          <span
            className={cn({
              'notification-icon': !isAllRead,
            })}
          >
            <IoNotifications size={24} color={COLOR_CODE.GRAY_600} />
          </span>
        </Text>
      </View>
      <Menu
        id={'notifications'}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'notification',
        }}
        sx={{ mt: 1, maxHeight: 680 }}
        elevation={1}
        disableScrollLock={true}
      >
        <View className="notification-ctn__wrapper">
          <Box className="notification-ctn__header">
            <View isRow align="center">
              <Text
                onClick={() => handleChangeView(ViewMode.Notification)}
                className={cn('notification-ctn__header__title', {
                  'notification-ctn__header__title--selected': view === ViewMode.Notification,
                })}
                size={14}
              >
                Notifications
              </Text>
              <Text
                onClick={() => handleChangeView(ViewMode.UnRead)}
                className={cn('notification-ctn__header__title', {
                  'notification-ctn__header__title--selected': view === ViewMode.UnRead,
                })}
                size={14}
              >
                Unread
              </Text>
            </View>
            <Text
              className={cn('notification-ctn__header__mark-all-read', {
                'notification-ctn__header__mark-all-read--disabled': isAllRead,
              })}
              onClick={handleMarkAllReadNotifications}
            >
              Mark all as read
            </Text>
          </Box>
          <NotificationItems onClose={handleClose} view={view} />
        </View>
      </Menu>
    </>
  );
};

export default NavNotification;
