import { useMemo, useState } from 'react';
import { UseInfiniteQueryOptions, useInfiniteQuery } from 'react-query';
import { useDebounce } from 'src/hooks';
import { isEmpty } from 'src/validations';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { Option } from '../types';
import { User } from './types';

const pageParam = {
  take: 10,
  skip: 0,
};

export function useLazyUsers(
  options?: UseInfiniteQueryOptions<{ data: User[] }, Error> & {
    [key: string]: string | number | string[];
  }
) {
  const [inputSearch, setInputSearch] = useState<string>('');
  const debounceSearch = useDebounce(inputSearch);
  const {
    data,
    error,
    isError,
    isFetching,
    refetch: getUsers,
    fetchNextPage,
  } = useInfiniteQuery<{ data: User[] }, Error>(
    [API_QUERIES.Users, pageParam, debounceSearch, { ...options }],
    (props): Promise<{ data: User[] }> => {
      const { pageParam, queryKey } = props;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const search = queryKey.at(-2);
      const options = queryKey.at(-1);
      return responseWrapper<{ data: User[] }>(apiClient.getAllUsers, [
        { ...pageParam, search, ...(options as Object) },
      ]);
    },
    {
      keepPreviousData: true,
      getNextPageParam(lastPage, allPages) {
        if (lastPage.data?.length < 10) return undefined;
        return {
          take: 10,
          skip: allPages.length * 10,
        };
      },
      notifyOnChangeProps: ['data', 'isFetching'],
      ...options,
    }
  );

  const users: Option[] = useMemo(() => {
    if (isEmpty(data)) return [];
    return data.pages.reduce(
      (state, page, pageIdx) => [
        ...state,
        ...page.data.map(
          (user, idx): Option => ({
            ...user,
            label: `${user.firstName} ${user.lastName}`,
            value: user.id,
            subLabel: user.email,
            index: pageIdx * 10 + idx,
          })
        ),
      ],
      []
    ) as Option[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return {
    users,
    error,
    isError,
    loading: isFetching,
    getUsers,
    fetchNextPage,
    setInputSearch,
  };
}
