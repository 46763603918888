import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { PaginationResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { GetPropertiesParams } from '../types';
import { toTDI21sAnnualExceptionReport } from './helpers';
import { TDI21sAnnualExceptionReport } from './types';

export function useGetTDI21sAnnualExceptionReport(
  options?: UseQueryOptions<PaginationResponseType<TDI21sAnnualExceptionReport>, Error>
) {
  const [params, setParams] = useState<GetPropertiesParams>({});

  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetTDI21sAnnualExceptionReport,
  } = useQuery<PaginationResponseType<TDI21sAnnualExceptionReport>, Error>(
    [API_QUERIES.TDI21S_ANNUAL_EXCEPTION_REPORT, { ...params, ...options }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<PaginationResponseType<TDI21sAnnualExceptionReport>>(
          apiClient.getTDI21sAnnualExceptionReport,
          params
        );
      },
      onError: ErrorService.handler,
      notifyOnChangeProps: ['data', 'isFetching'],
      keepPreviousData: true,
      select: (data) => toTDI21sAnnualExceptionReport(data),
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateTDI21sAnnualExceptionReport = () =>
    queryClient.invalidateQueries(API_QUERIES.TDI21S_ANNUAL_EXCEPTION_REPORT);

  const { data: tdi21sAnnualExceptionReport, hasNext, payloadSize, totalRecords } = data || {};

  return {
    tdi21sAnnualExceptionReport,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isError,
    isFetching,
    onGetTDI21sAnnualExceptionReport,
    setParams,
    handleInvalidateTDI21sAnnualExceptionReport,
  };
}
