import React, { useState } from 'react';
import './styles.scss';
import { styled } from '@mui/material/styles';
import { Input, Slide, Button, IconButton, InputAdornment, ClickAwayListener } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const StyledSearchbar = styled('div')(({ theme }) => ({
  zIndex: 99,
  width: '400px',
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 3),
}));

const NavSearch: React.FC = () => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div>
        {!open && (
          <IconButton onClick={handleOpen}>
            <SearchIcon />
          </IconButton>
        )}

        <Slide direction="left" in={open} mountOnEnter unmountOnExit>
          <StyledSearchbar>
            <Input
              fullWidth
              disableUnderline
              placeholder="Search…"
              startAdornment={
                <InputAdornment position="start">
                  <SearchIcon sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                </InputAdornment>
              }
              sx={{ mr: 1, fontWeight: 'fontWeightBold' }}
            />
            <Button variant="contained" onClick={handleClose}>
              <SearchIcon />
            </Button>
          </StyledSearchbar>
        </Slide>
      </div>
    </ClickAwayListener>
  );
};

export default NavSearch;
