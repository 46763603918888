import { useMemo } from 'react';
import { UseInfiniteQueryOptions, useInfiniteQuery, useQueryClient } from 'react-query';
import { isEmpty } from 'src/validations';
import apiClient from '../apiClient';
import { responseWrapper, toAuditData } from '../helpers';
import { API_QUERIES } from '../keys';
import { Comment, CommentKey } from './types';

const pageParam = {
  take: 10,
  skip: 0,
};

export function useGetEmployerComments(
  options?: UseInfiniteQueryOptions<{ data: Comment[]; totalRecords: number }, Error> & {
    accountId?: string;
  }
) {
  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetAllComments,
    fetchNextPage,
  } = useInfiniteQuery<{ data: Comment[]; totalRecords: number }, Error>(
    [API_QUERIES.COMMENTS, { ...pageParam, ...options }],
    (props): Promise<{ data: Comment[]; totalRecords: number }> => {
      const { pageParam } = props;
      const searchOptions = { ...pageParam };

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      return responseWrapper<{ data: Comment[]; totalRecords: number }>(
        apiClient.getAllEmployerComments,
        [{ ...searchOptions, ...(options as Object) }]
      );
    },
    {
      keepPreviousData: true,
      getNextPageParam(lastPage, allPages) {
        if (lastPage?.data?.length < 10) return undefined;
        return {
          take: 10,
          skip: allPages?.length * 10,
        };
      },
      notifyOnChangeProps: ['data', 'isFetching'],
      enabled: !!options?.accountId,
      ...options,
    }
  );

  const { comments, totalRecords } = useMemo(() => {
    if (isEmpty(data))
      return {
        comments: [],
        totalRecords: 0,
      };

    return {
      totalRecords: data.pages?.[0]?.totalRecords,
      comments: data.pages.reduce(
        (state, page) => [
          ...state,
          ...page.data.map((comment) => ({
            ...comment,
            ...toAuditData(comment),
            [CommentKey.Avatar]: comment?.createdUser?.avatar,
          })),
        ],
        []
      ),
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const queryClient = useQueryClient();

  const handleInvalidateLazyComments = () => queryClient.invalidateQueries([API_QUERIES.COMMENTS]);
  const handleResetLazyComments = () => queryClient.resetQueries([API_QUERIES.COMMENTS]);

  return {
    comments,
    totalRecords,
    error,
    isError,
    loading: isFetching,
    onGetAllComments,
    fetchNextPage,
    handleResetLazyComments,
    handleInvalidateLazyComments,
  };
}
