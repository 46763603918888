import { useMemo, useState } from 'react';
import { UseInfiniteQueryOptions, useInfiniteQuery, useQueryClient } from 'react-query';
import { useDebounce } from 'src/hooks';
import { isEmpty } from 'src/validations';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { Notification } from './types';

const pageParam = {
  take: 10,
  skip: 0,
};

export function useLazyNotifications(
  options?: UseInfiniteQueryOptions<{ data: Notification[] }, Error>
) {
  const [isUnread, setIsUnread] = useState(false);
  const debounceSearch = useDebounce(isUnread);
  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetAllNotifications,
    fetchNextPage,
  } = useInfiniteQuery<{ data: Notification[] }, Error>(
    [API_QUERIES.NOTIFICATIONS, pageParam, debounceSearch],
    (props): Promise<{ data: Notification[] }> => {
      const { pageParam, queryKey } = props;
      const isUnread = queryKey.at(-1);
      const searchOptions = { ...pageParam, isUnread: isUnread };
      !isUnread && delete searchOptions.isUnread;

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      return responseWrapper<{ data: Notification[] }>(apiClient.getAllNotifications, [
        { ...searchOptions },
      ]);
    },
    {
      keepPreviousData: true,
      getNextPageParam(lastPage, allPages) {
        if (lastPage?.data?.length < 10) return undefined;
        return {
          take: 10,
          skip: allPages?.length * 10,
        };
      },
      refetchInterval: 15000,
      notifyOnChangeProps: ['data', 'isFetching'],
      ...options,
    }
  );

  const notifications = useMemo(() => {
    if (isEmpty(data)) return [];
    return data.pages.reduce(
      (state, page, pageIdx) => [...state, ...page.data.map((notification, idx) => notification)],
      []
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const queryClient = useQueryClient();

  const handleInvalidateLazyNotification = () =>
    queryClient.invalidateQueries([API_QUERIES.NOTIFICATIONS]);

  return {
    notifications,
    error,
    setIsUnread,
    isError,
    loading: isFetching,
    onGetAllNotifications,
    fetchNextPage,
    handleInvalidateLazyNotification,
  };
}
