import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { PaginationResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { GetPropertiesParams } from '../types';
import { toVRProcesses } from './helpers';
import { VocationalRehabilitation } from './types';

export function useGetAllVocationalRehabilitation(
  options?: UseQueryOptions<PaginationResponseType<VocationalRehabilitation>, Error> & {
    caseId: string;
  }
) {
  const [params, setParams] = useState<GetPropertiesParams>({});
  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetAllVocationalRehabilitation,
  } = useQuery<PaginationResponseType<VocationalRehabilitation>, Error>(
    [API_QUERIES.RELATED_VOCATIONAL_REHABILITATION, { ...params, caseId: options.caseId }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<PaginationResponseType<VocationalRehabilitation>>(
          apiClient.getAllVocationalRehabilitation,
          params
        );
      },
      notifyOnChangeProps: ['data', 'isFetching'],
      keepPreviousData: true,
      enabled: !!options.caseId,
      select: (data) => toVRProcesses(data),
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateAllVocationalRehabilitation = () =>
    queryClient.invalidateQueries(API_QUERIES.RELATED_VOCATIONAL_REHABILITATION);

  const { data: vocationalRehabilitations, hasNext, payloadSize, totalRecords } = data || {};

  return {
    vocationalRehabilitations,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isError,
    isFetching,
    onGetAllVocationalRehabilitation,
    handleInvalidateAllVocationalRehabilitation,
    setParams,
  };
}
