import cn from 'classnames';
import React, { useRef } from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { Callback } from 'src/redux/types';
import { isEmpty } from 'src/validations';
import Element from '../Element';
import './styles.scss';
import { MAX_DATE_DEFAULT, getRandomId } from 'src/modules/shared-main/common';

const DateSelector: React.FC<Props> = ({
  label,
  onChange,
  errorMessage,
  containerClassName,
  classNames,
  placeholder = 'mm/dd/yyyy',
  dateFormat = 'MM/dd/yyyy',
  required,
  name,
  onBlur,
  positionFixed = true,
  value,
  maxDate,
  disabled = false,
  infoTooltipMessage = '',
  ...props
}) => {
  const id = useRef<string>(`datepicker-${getRandomId()}`);
  // For more information:
  // https://reactdatepicker.com/
  const handleChange = (value: Date) => {
    onChange(name, value);
  };

  const onTouched = () => {
    onBlur(name, true);
  };
  const hasError = !isEmpty(errorMessage);

  return (
    <Element
      id={id.current}
      errorMessage={errorMessage}
      label={label}
      className={cn('cmp-datepicker', containerClassName)}
      required={required}
      infoTooltipMessage={infoTooltipMessage}
    >
      <DatePicker
        id={id.current}
        onChange={handleChange}
        placeholderText={disabled ? '' : placeholder}
        className={cn(
          'cmp-datepicker__input',
          { 'cmp-datepicker__input--error': hasError },
          classNames
        )}
        // showPopperArrow={false}
        dateFormat={dateFormat}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        popperProps={{
          positionFixed: positionFixed,
        }}
        selected={value ? new Date(value) : null}
        portalId="root"
        maxDate={maxDate ?? MAX_DATE_DEFAULT}
        disabled={disabled}
        onBlur={onTouched}
        {...props}
      />
    </Element>
  );
};

type BaseDatePickerProps = Pick<
  ReactDatePickerProps,
  Exclude<keyof ReactDatePickerProps, 'onChange' | 'onBlur'>
>;

type Props = BaseDatePickerProps & {
  errorMessage?: string;
  containerClassName?: string;
  classNames?: string;
  placeholder?: string;
  mask?: string;
  label?: string | React.ReactNode;
  onChange: Callback;
  onBlur?: Callback;
  hasDifferentValue?: boolean;
  positionFixed?: boolean;
  maxDate?: Date;
  infoTooltipMessage?: string;
};

export type DateSelectorProps = Props;

export default DateSelector;
