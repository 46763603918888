import { BodyPartKey } from 'src/containers/Related/RelatedInjuryIllnessManagement/CreateEditRelatedInjuryIllnessManagement/CreateEditRelatedInjuryIllness/helpers';
import { capitalizeFirstLetter } from 'src/utils';
import { PaginationResponseType } from '../helpers';
import { RelatedInjuryIllnessKey, RelatedInjuryIllnessType } from './types';

export const toRelatedInjuryIllness = (
  data: PaginationResponseType<RelatedInjuryIllnessType>
): PaginationResponseType<RelatedInjuryIllnessType> => {
  return {
    ...data,
    data: data?.data.map((relatedInjuryIllness) => {
      const sideLeftRight = capitalizeFirstLetter(
        relatedInjuryIllness?.[RelatedInjuryIllnessKey.SideLeftRight]
      );
      const sideFrontBack = capitalizeFirstLetter(
        relatedInjuryIllness?.[RelatedInjuryIllnessKey.SideFrontBack]
      );
      const bodyPart = getEnumValueFromKey(
        relatedInjuryIllness?.[RelatedInjuryIllnessKey.BodyPart]
      );

      return {
        ...relatedInjuryIllness,
        [RelatedInjuryIllnessKey.SideLeftRight]: sideLeftRight,
        [RelatedInjuryIllnessKey.SideFrontBack]: sideFrontBack,
        [RelatedInjuryIllnessKey.BodyPart]: bodyPart,
      };
    }),
  };
};

export const getEnumValueFromKey = (key: string): BodyPartKey | null => {
  const enumValue = Object.values(BodyPartKey).find(
    (value) => value?.toLowerCase() === key?.toLowerCase()
  );
  return enumValue || null;
};
