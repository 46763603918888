import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { createResponseWrapper } from '../helpers';
import { Request } from './types';

export function useCreateRequest(options?: UseMutationOptions<Request, Error, Request>) {
  const { mutate: createRequest, isLoading } = useMutation<Request, Error, Request>({
    mutationFn: (payload: Request) => createResponseWrapper(apiClient.createRequest, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    createRequest,
    isLoading,
  };
}
