import { useMemo, useState } from 'react';
import { useInfiniteQuery, UseInfiniteQueryOptions } from 'react-query';
import { useDebounce } from 'src/hooks';
import { PaginationResponseType } from 'src/queries';
import { isEmpty } from 'src/validations';
import { Option } from '../../common/appConfig/constants';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { Group } from './types';

const pageParam = {
  take: 10,
};

export function useGetLazyGroup(
  options?: UseInfiniteQueryOptions<PaginationResponseType<Group>, Error>
) {
  const [inputSearch, setInputSearch] = useState<string>('');
  const debounceSearch = useDebounce(inputSearch);
  const {
    data,
    error,
    isError,
    isFetching,
    refetch: getGroups,
    fetchNextPage,
  } = useInfiniteQuery<PaginationResponseType<Group>, Error>(
    [API_QUERIES.GROUPS, pageParam, debounceSearch],
    (props): Promise<PaginationResponseType<Group>> => {
      const { pageParam, queryKey } = props;
      const search = queryKey.at(-1);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      return responseWrapper<PaginationResponseType<Group>>(apiClient.getAllGroup, [
        { ...pageParam, search },
      ]);
    },
    {
      keepPreviousData: true,
      getNextPageParam(lastPage, allPages) {
        if (lastPage.data?.length < 10) return undefined;
        return {
          take: 10,
          skip: allPages.length * 10,
        };
      },
      notifyOnChangeProps: ['data', 'isFetching'],
      ...options,
    }
  );

  const groups: Option[] = useMemo(() => {
    if (isEmpty(data)) return [];
    return data.pages.reduce(
      (state, page, pageIdx) => [
        ...state,
        ...page.data.map(
          ({ name, id }, idx): Option => ({
            label: name,
            value: id.toString(),
            index: pageIdx * 10 + idx,
          })
        ),
      ],
      []
    ) as Option[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return {
    groups,
    error,
    isError,
    loading: isFetching,
    getGroups,
    fetchNextPage,
    setInputSearch,
  };
}
