import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { DecisionTextPayLoad } from './types';

export function useEditDecisionText(
  options?: UseMutationOptions<DecisionTextPayLoad, Error, DecisionTextPayLoad>
) {
  const {
    mutate: onEditDecisionText,
    isLoading: isUpdating,
    isError,
    error,
  } = useMutation<DecisionTextPayLoad, Error, DecisionTextPayLoad>({
    mutationFn: (payload: DecisionTextPayLoad) =>
      responseWrapper(apiClient.updateDecisionText, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    onEditDecisionText,
    isUpdating,
    isError,
    error,
  };
}
