import { useState } from 'react';
import { UseQueryOptions, useQuery, useQueryClient } from 'react-query';
import apiClient from '../apiClient';
import { PaginationResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { GetPropertiesParams } from '../types';
import { toHrsHars } from './helpers';
import { HRSHAR } from './types';

export function useGetAllHRSs(
  options?: UseQueryOptions<PaginationResponseType<HRSHAR>, Error> & {
    hearingId: string;
    isSelected?: boolean;
  }
) {
  const [params, setParams] = useState<GetPropertiesParams>({});
  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetAllHRSs,
  } = useQuery<PaginationResponseType<HRSHAR>, Error>(
    [
      API_QUERIES.HRS_HAR,
      {
        ...params,
        ...options,
      },
    ],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<PaginationResponseType<HRSHAR>>(apiClient.getAllHRSs, params);
      },
      select: (data) => toHrsHars(data),
      notifyOnChangeProps: ['data', 'isFetching'],
      keepPreviousData: true,
      enabled: !!options?.hearingId,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateAllHRSs = () => queryClient.invalidateQueries([API_QUERIES.HRS_HAR]);

  const { data: hrsHars, hasNext, payloadSize, totalRecords } = data || {};

  return {
    hrsHars,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isError,
    isFetching,
    onGetAllHRSs,
    handleInvalidateAllHRSs,
    setParams,
  };
}
