import { useMutation, UseMutationOptions } from 'react-query';
import { Toastify } from 'src/services';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { AddressHistory } from './types';

export function useEditAddressHistory(
  options?: UseMutationOptions<AddressHistory, Error, AddressHistory >
) {
  const {
    mutate: editAddressHistory,
    isLoading: isUpdating,
    isSuccess,
    isError,
    error,
  } = useMutation<AddressHistory, Error, AddressHistory>({
    mutationFn: (payload: AddressHistory) =>
      responseWrapper(apiClient.updateAddressHistory, [payload]),
    onError: ({ message }) => Toastify.error(message),
    ...options,
  });

  return {
    editAddressHistory,
    isUpdating,
    isSuccess,
    isError,
    error,
  };
}
