import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from '../apiClient';
import { ApiResponseType, responseWrapper } from '../helpers';
import { GetPresignedDownloadFilePayload, GetPresignedDownloadFileResponse } from './types';

export function useGetDownloadFileUrl(
  options?: UseMutationOptions<
    ApiResponseType<GetPresignedDownloadFileResponse>,
    Error,
    GetPresignedDownloadFilePayload
  >
) {
  const {
    mutate: onGetDecodeUrl,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useMutation<
    ApiResponseType<GetPresignedDownloadFileResponse>,
    Error,
    GetPresignedDownloadFilePayload
  >({
    mutationFn: (payload: GetPresignedDownloadFilePayload) => {
      return responseWrapper<any>(apiClient.getPresignedDownloadFileUrl, [payload]);
    },
    ...options,
  });

  return {
    onGetDecodeUrl,
    error,
    isError,
    isLoading,
    isSuccess,
  };
}
