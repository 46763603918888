import { useMutation, UseMutationOptions } from 'react-query';
import { Toastify } from 'src/services';
import apiClient from '../apiClient';
import { authResponseWrapper } from '../helpers';

export function useDeleteBenefitPaymentSummary(
  options?: UseMutationOptions<string, Error, string>
) {
  const { mutate: deleteBenefitPaymentSummary, isLoading } = useMutation<string, Error, string>({
    mutationFn: (payload: string) =>
      authResponseWrapper(apiClient.deleteBenefitPaymentSummary, [payload]),
    onError: ({ message }) => Toastify.error(message),
    ...options,
  });

  return {
    deleteBenefitPaymentSummary,
    isLoading,
  };
}
