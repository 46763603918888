import { formatTDICaseNumber, getFirstLastName } from 'src/utils';
import { toAddress, toAuditData } from '../helpers';
import { TDICaseKey } from './keys';

export const toTDICases = (data) => ({
  ...data,
  data: data.data.map((item) => ({
    ...item,
    [TDICaseKey.Employer]: item?.employer?.accountName,
    [TDICaseKey.EmployerId]: item?.employer?.id,
    [TDICaseKey.EmployerDolNumber]: item?.employer?.laborNumber,
    [TDICaseKey.EmployerStatus]: item?.employer?.dcdStatus,
    [TDICaseKey.EmployerStatusDate]: item?.employer?.dcdStatusDate,

    [TDICaseKey.MostRecentTDI46]: item?.mostRecentTDI46?.tdi46Number,
    [TDICaseKey.AdjustingCompany]: item?.adjustingCompany?.accountName,
    [TDICaseKey.AdjustingCompanyId]: item?.adjustingCompany?.id,
    [TDICaseKey.AdjustingCompanyPhone]: item?.adjustingCompany?.phone,

    [TDICaseKey.Claimant]: item?.claimant?.accountName,
    [TDICaseKey.ClaimantId]: item?.claimant?.id,
    [TDICaseKey.ClaimantDOB]: item?.claimant?.dateOfBirth,
    [TDICaseKey.ClaimantEmail]: item?.claimant?.email,
    [TDICaseKey.ClaimantSSN]: item?.claimant?.ssn,
    [TDICaseKey.ClaimantPhone]: item?.claimant?.phone,

    [TDICaseKey.InsuranceCarrierStatus]: item?.insuranceCarrier?.dcdStatus,
    [TDICaseKey.InsuranceCarrierId]: item?.insuranceCarrier?.id,
    [TDICaseKey.InsuranceCarrierCarrierId]: item?.insuranceCarrier?.carrierId,
    [TDICaseKey.InsuranceCarrier]: item?.insuranceCarrier?.accountName,
    [TDICaseKey.InsuranceCarrierStatusDate]: item?.insuranceCarrier?.dcdStatusDate,
    [TDICaseKey.InsuranceCarrierPhone]: item?.insuranceCarrier?.phone,
    [TDICaseKey.InsuranceCarrierStreet]: item?.insuranceCarrier?.street,
    [TDICaseKey.InsuranceCarrierCity]: item?.insuranceCarrier?.city,
    [TDICaseKey.InsuranceCarrierZip]: item?.insuranceCarrier?.zip,
    [TDICaseKey.InsuranceCarrierState]: item?.insuranceCarrier?.state,
    ...toAuditData(item),
  })),
});

export const toTDICaseDetail = (data) => ({
  ...data,
  [TDICaseKey.TDICaseFormattedNumber]: formatTDICaseNumber(data?.tdiCaseNumber),
  [TDICaseKey.TDICaseId]: data?.id || '',
  [TDICaseKey.ClaimantName]: getFirstLastName(data?.claimant) || '',
  [TDICaseKey.InsuranceCarrierAddress]: toAddress(data?.insuranceCarrier) || '',
  [TDICaseKey.EmployerAccountName]: data?.employer?.accountName || '',
  [TDICaseKey.InsuranceCarrierAccountName]: data?.insuranceCarrier?.accountName || '',
  [TDICaseKey.AdjustingCompanyAccountName]: data?.adjustingCompany?.accountName || '',
  [TDICaseKey.MostRecentTDI46Number]: data?.mostRecentTDI46?.tdi46Number || '',
  [TDICaseKey.MostRecentTDI46DateReceived]: data?.mostRecentTDI46?.dateReceived || '',
  [TDICaseKey.ClaimantSSN]: data?.claimant?.ssn || '',
  [TDICaseKey.ClaimantDOB]: data?.claimant?.dateOfBirth || '',
  [TDICaseKey.ClaimantDateOfDeath]: data?.claimant?.dateOfDeath || '',
  [TDICaseKey.ClaimantPhoneSchema]: data?.claimant?.phone || '',
  [TDICaseKey.ClaimantEmailSchema]: data?.claimant?.email || '',
  [TDICaseKey.ClaimantAddress]: toAddress(data?.claimant) || '',
  [TDICaseKey.EmployerLaborNumber]: data?.employer?.laborNumber || '',
  [TDICaseKey.EmployerStatusSchema]: data?.employer?.dcdStatus || '',
  [TDICaseKey.EmployerStatusDateSchema]: data?.employer?.dcdStatusDate || '',
  [TDICaseKey.InsuranceCarrierCarrierId]: data?.insuranceCarrier?.carrierId || '',
  [TDICaseKey.InsuranceCarrierStatusSchema]: data?.insuranceCarrier?.dcdStatus || '',
  [TDICaseKey.InsuranceCarrierStatusDateSchema]: data?.insuranceCarrier?.dcdStatusDate || '',
  [TDICaseKey.InsuranceCarrierPhoneSchema]: data?.insuranceCarrier?.phone || '',
  [TDICaseKey.AdjustingCompanyEmailSchema]: data?.adjustingCompany?.email || '',
  [TDICaseKey.TDICaseNumber]: data?.tdiCaseNumber || '',
  [TDICaseKey.AdjustingCompanyPhone]: data?.adjustingCompany?.phone || '',
  [TDICaseKey.ResolvedInvestigatorFullName]: getFirstLastName(data?.resolvedInvestigator) || '',
  ...toAuditData(data),
});
