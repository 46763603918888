import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from '../apiClient';
import { authResponseWrapper } from '../helpers';
import { CloneProfilePayload } from './types';

export function useCloneProfile(
  options?: UseMutationOptions<CloneProfilePayload, Error, CloneProfilePayload>
) {
  const { mutate: cloneProfile, isLoading } = useMutation<
    CloneProfilePayload,
    Error,
    CloneProfilePayload
  >({
    mutationFn: (payload: CloneProfilePayload) =>
      authResponseWrapper(apiClient.cloneProfile, [payload]),
    ...options,
  });

  return {
    cloneProfile,
    isLoading,
  };
}
