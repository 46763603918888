import { useMemo, useState } from 'react';
import { UseInfiniteQueryOptions, useInfiniteQuery } from 'react-query';
import { useDebounce } from 'src/hooks';
import { isEmpty } from 'src/validations';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { Option } from '../types';
import { Profile } from './types';

const pageParam = {
  take: 10,
};

export function useLazyProfiles(options?: UseInfiniteQueryOptions<{ data: Profile[] }, Error>) {
  const [inputSearch, setInputSearch] = useState<string>('');
  const debounceSearch = useDebounce(inputSearch);
  const {
    data,
    error,
    isError,
    isFetching,
    refetch: getProfiles,
    fetchNextPage,
  } = useInfiniteQuery<{ data: Profile[] }, Error>(
    [API_QUERIES.Profiles, pageParam, debounceSearch],
    (props): Promise<{ data: Profile[] }> => {
      const { pageParam, queryKey } = props;
      const search = queryKey.at(-1);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      return responseWrapper<{ data: Profile[] }>(apiClient.getAllProfile, [
        { ...pageParam, search },
      ]);
    },
    {
      keepPreviousData: true,
      getNextPageParam(lastPage, allPages) {
        if (lastPage.data?.length < 10) return undefined;
        return {
          take: 10,
          skip: allPages.length * 10,
        };
      },
      notifyOnChangeProps: ['data', 'isFetching'],
      ...options,
    }
  );

  const profiles: Option[] = useMemo(() => {
    if (isEmpty(data)) return [];
    return data.pages.reduce(
      (state, page, pageIdx) => [
        ...state,
        ...page.data.map(({ name, id }, idx): any => ({
          label: name,
          value: id.toString(),
          index: pageIdx * 10 + idx,
        })),
      ],
      []
    ) as Option[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return {
    profiles,
    error,
    isError,
    loading: isFetching,
    getProfiles,
    fetchNextPage,
    setInputSearch,
  };
}
