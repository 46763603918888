import cn from 'classnames';
import React, { HTMLProps, MouseEventHandler, RefObject, useRef } from 'react';

import { getRandomId } from 'src/utils';
import { isEmpty } from 'src/validations';
import './styles.scss';
import { Element, View, Icon } from '..';

const Input: React.FC<TextAreaProps> = ({
  children,
  errorMessage,
  label,
  className,
  containerClassName,
  inputRef = null,
  iconName = '',
  onIconClick,
  required,
  placeholder,
  infoTooltipMessage = '',
  infoTooltipPlacement = 'right',
  infoToolTipWithArrow = true,
  disabled,
  ...props
}) => {
  const id = useRef<string>(`text-area-${getRandomId()}`);

  const defaultPlaceholder = disabled
    ? ''
    : typeof label === 'string'
    ? `Enter ${label.toLowerCase()}`
    : null;

  return (
    <Element
      id={id.current}
      errorMessage={errorMessage}
      label={label}
      className={containerClassName}
      required={required}
      infoTooltipMessage={infoTooltipMessage}
      infoTooltipPlacement={infoTooltipPlacement}
      infoToolTipWithArrow={infoToolTipWithArrow}
    >
      <View>
        <textarea
          id={id.current}
          className={cn(className, 'cmp-text-area', {
            'cmp-text-area--error': !isEmpty(errorMessage),
          })}
          ref={inputRef}
          placeholder={placeholder ? placeholder : defaultPlaceholder}
          maxLength={1024}
          disabled={disabled}
          {...props}
        />
        {iconName && <Icon name={iconName} className="cmp-text-area__icon" onClick={onIconClick} />}
      </View>
    </Element>
  );
};

type BaseInputProps = Pick<
  HTMLProps<HTMLTextAreaElement>,
  Exclude<keyof HTMLProps<HTMLTextAreaElement>, 'label'>
>;
export type TextAreaProps = BaseInputProps & {
  errorMessage?: string;
  containerClassName?: string;
  inputRef?: RefObject<HTMLTextAreaElement>;
  iconName?: string;
  onIconClick?: MouseEventHandler<HTMLElement>;
  label?: string | React.ReactNode;
  required?: boolean;
  placeholder?: string;
  infoTooltipMessage?: string;
  infoTooltipPlacement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
  infoToolTipWithArrow?: boolean;
};

export default Input;
