import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { PaginationResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { GetPropertiesParams } from '../types';
import { toEmployerComments } from './helpers';
import { Comment } from './types';

export function useGetAllEmployerComments(
  options?: UseQueryOptions<PaginationResponseType<Comment>, Error> & {
    [key: string]: string | number | string[] | boolean;
  }
) {
  const [params, setParams] = useState<GetPropertiesParams>({});

  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetAllEmployerComments,
  } = useQuery<PaginationResponseType<Comment>, Error>(
    [API_QUERIES.COMMENTS, { ...options, ...params }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<PaginationResponseType<Comment>>(
          apiClient.getAllEmployerComments,
          params
        );
      },
      notifyOnChangeProps: ['data', 'isFetching'],
      keepPreviousData: true,
      select: toEmployerComments,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateAllEmployerComments = () =>
    queryClient.invalidateQueries(API_QUERIES.COMMENTS);

  const { data: comments, hasNext, payloadSize, totalRecords } = data || {};

  return {
    comments,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isError,
    isFetching,
    onGetAllEmployerComments,
    setParams,
    handleInvalidateAllEmployerComments,
  };
}
