import { formatDateTime } from 'src/utils';
import { PaginationResponseType, toAuditData } from '../helpers';
import { ReceiptKey } from './keys';
import { Receipt } from './types';

export const toReceipts = (data): PaginationResponseType<Receipt> => {
  return {
    ...data,
    data: data?.data.map((receipt) => ({
      ...receipt,
      [ReceiptKey.CaseNumber]: receipt?.case?.caseNumber,
      [ReceiptKey.TDICaseNumber]: receipt?.tdiCase?.tdiCaseNumber,
      [ReceiptKey.TDISFCaseNumber]: receipt?.tdiSFCase?.tdiSFCaseNumber,
      [ReceiptKey.Employer]: receipt?.employer?.accountName,
      [ReceiptKey.CaseEmployerId]: receipt?.case?.employer?.id,
      [ReceiptKey.CaseEmployer]: receipt?.case?.employer?.accountName,
      [ReceiptKey.EmployerId]: receipt?.employer?.id,
      [ReceiptKey.Claimant]: receipt?.case?.claimant?.accountName,
      [ReceiptKey.LaborNumber]: receipt?.case?.employer?.laborNumber,
      [ReceiptKey.ClaimantId]: receipt?.case?.claimant?.id,
      [ReceiptKey.ExpenseGeneralLedgerAccountNumber]: receipt?.glAccount?.glNumber,
      [ReceiptKey.ExpenseGeneralLedgerDescription]: receipt?.glAccount?.description,
      [ReceiptKey.ControlNumber]: receipt?.case?.scfControlNum,
      [ReceiptKey.ExpenseGeneralLedgerAccountId]: receipt?.glAccount?.id,
      [ReceiptKey.CreatedByDate]: `${receipt?.createdUser?.firstName} ${
        receipt?.createdUser?.lastName
      }, ${formatDateTime(receipt?.createdAt)}`,
      [ReceiptKey.UpdatedByDate]:
        receipt?.updatedUser &&
        `${receipt?.updatedUser?.firstName} ${receipt?.updatedUser?.lastName}, ${formatDateTime(
          receipt?.updatedAt
        )}`,
      ...toAuditData(receipt),
    })),
  };
};

export const toReceipt = (data): Receipt => ({
  ...data,
  [ReceiptKey.CaseNumber]: data?.case?.caseNumber,
  [ReceiptKey.Employer]: data?.employer?.accountName,
  [ReceiptKey.ClaimantName]: data?.case?.claimant?.accountName,
  [ReceiptKey.EmployerId]: data?.employer?.id,
  [ReceiptKey.ClaimantId]: data?.case?.claimant?.id,
  [ReceiptKey.LaborNumber]: data?.case?.employer?.laborNumber,
  [ReceiptKey.EmployerLaborNumber]: data?.employer?.laborNumber,
  [ReceiptKey.ExpenseGeneralLedgerAccountNumber]: data?.glAccount?.glNumber,
  [ReceiptKey.ExpenseGeneralLedgerDescription]: data?.glAccount?.description,
  [ReceiptKey.ControlNumber]: data?.case?.scfControlNum,
  [ReceiptKey.CaseEmployer]: data?.case?.employer?.accountName,
  [ReceiptKey.CaseEmployerId]: data?.case?.employer?.id,
  [ReceiptKey.TDICaseNumber]: data?.tdiCase?.tdiCaseNumber,
  [ReceiptKey.TDISFCaseNumber]: data?.tdiSFCase?.tdiSFCaseNumber,
  ...toAuditData(data),
});
