import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { NotifyFiscalPayload } from '../Disbursements';
import { createResponseWrapper } from '../helpers';

export function useNotifyFiscalReceipt(
  options?: UseMutationOptions<NotifyFiscalPayload, Error, NotifyFiscalPayload>
) {
  const { mutate: notifyFiscal, isLoading } = useMutation<
    NotifyFiscalPayload,
    Error,
    NotifyFiscalPayload
  >({
    mutationFn: (payload: NotifyFiscalPayload) =>
      createResponseWrapper(apiClient.notifyFiscalReceipt, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    notifyFiscal,
    isLoading,
  };
}
