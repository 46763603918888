import { CustomSettingsKey } from './keys';

export interface CustomSettings {
  [CustomSettingsKey.Tab]?: CustomSettingTabListKey;
  [CustomSettingsKey.Name]?: string;
  [CustomSettingsKey.Title]?: string;
  [CustomSettingsKey.Phone]?: string;
  [CustomSettingsKey.MaximumTaxableWage]?: number;
  [CustomSettingsKey.LastModifiedDate]?: string;
  [CustomSettingsKey.LastModifiedBy]?: string;
  [CustomSettingsKey.UpdatedAt]?: string;
  [CustomSettingsKey.UpdatedUser]?: string;
}

export enum CustomSettingTabListKey {
  GlobalAdministrator = 'GlobalAdministrator',
  DCDHealthCareSpecialist = 'DCDHealthCareSpecialist',
  TDI21MaximumTaxableWage = 'MaximumTaxableWage',
}
