import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { Service, UpdateViewPayload } from './types';

export function useUpdateView(
  options?: UseMutationOptions<UpdateViewPayload, Error, UpdateViewPayload>
) {
  const { mutate: updateView, isLoading } = useMutation<
    UpdateViewPayload,
    Error,
    UpdateViewPayload
  >({
    mutationFn: (payload: UpdateViewPayload) => {
      const body = { ...payload, service: payload?.service || Service.CaseSvc };
      return responseWrapper(apiClient.updateView, [body]);
    },
    onError: ErrorService.handler,
    ...options,
  });

  return {
    updateView,
    isLoading,
  };
}
