import { useMutation, UseMutationOptions } from 'react-query';
import { Toastify } from 'src/services';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { StatusPayload } from './types';

export function useEditUserStatus(options?: UseMutationOptions<any, Error, StatusPayload>) {
  const {
    mutate: onEditUserStatus,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useMutation<any, Error, StatusPayload>({
    mutationFn: (payload: StatusPayload) => responseWrapper(apiClient.editUserStatus, [payload]),
    onError: ({ message }) => Toastify.error(message),
    ...options,
  });

  return {
    onEditUserStatus,
    isLoading,
    isSuccess,
    isError,
    error,
  };
}
