import { useQuery, UseQueryOptions } from 'react-query';
import appConfig from 'src/appConfig';
import apiClient from '../apiClient';
import { ApiResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { usePaginationLanguages } from './usePaginationLanguages';

const FILE_PATH = `${appConfig.AWS_S3_WEB_LOGIN_BUCKET_URL}/languages.zip`;

export function useLanguages(
  options?: UseQueryOptions<ApiResponseType<{ url: string }>, Error, string>
) {
  const {
    data: url,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetLanguages,
  } = useQuery<ApiResponseType<{ url: string }>, Error, string>(
    [API_QUERIES.LANGUAGES, { id: FILE_PATH }],
    {
      queryFn: () => {
        return responseWrapper<ApiResponseType<{ url: string }>>(
          apiClient.getDecodeUserServiceUrl,
          [
            {
              filePath: FILE_PATH,
            },
          ]
        );
      },
      select: ({ data }) => data.url,
      ...options,
    }
  );

  const { languages, loading, fetchNextPage, setInputSearch } = usePaginationLanguages({ url });

  return {
    error,
    isError,
    isLoading,
    onGetLanguages,
    languages,
    loading,
    fetchNextPage,
    setInputSearch,
  };
}
