import { PaginationResponseType } from '../helpers';
import { User } from '../Users/types';

export enum ProfileKey {
  Name = 'resourceName',
  Description = 'description',
  AssignedUsers = 'assignedUsers',
}

export enum SystemPermissionKey {
  Name = 'name',
  IsEnabled = 'isEnabled',
  Description = 'description',
}

export enum TabPermissionKey {
  Hidden = 'HIDDEN',
  DefaultOn = 'DEFAULT_ON',
  DefaultOff = 'DEFAULT_OFF',
}

export interface Profile {
  name: string;
  description: string;
  assignedUsers: number;
  id: number;
}

export interface ProfileDetail {
  id: number;
  resourceName: string;
  description: string;
  updatedAt: string;
  createdAt: string;
  updatedBy: string;
  createdBy: string;
  updatedByUser: {
    firstName: string;
    lastName: string;
    fullName: string;
  };
  createdByUser: {
    firstName: string;
    lastName: string;
    fullName: string;
  };
  isUserProfile?: boolean;
}

export type AddProfilePayload = {
  resourceName: string;
  description: string;
};

export interface FieldPermissions {
  id: string;
  name: string;
  canRead: boolean;
  canUpdate: boolean;
}

export interface ObjectPermissions {
  id: string;
  resourceName: string;
  displayName: string;
  canCreate: boolean;
  canRead: boolean;
  canUpdate: boolean;
  canDelete: boolean;
  canViewAll: boolean;
  canModifyAll: boolean;
  noAccess?: boolean;
}

export interface FieldPermissionsPayload {
  tabPermission: TabPermissionKey;
  fieldPermissions: FieldPermissions[];
}
export interface ObjectPermissionsPayload {
  objectPermissions: ObjectPermissions[];
  id: string;
}

export interface ObjectPermissionsResponse {
  profile: {
    id: string;
    name: string;
  };
  objectPermissions: ObjectPermissions[];
}

export interface FieldPermissionsResponse {
  id: string;
  resourceName: string;
  displayName: string;
  canCreate: boolean;
  canRead: boolean;
  canUpdate: boolean;
  canDelete: boolean;
  canViewAll: boolean;
  canModifyAll: boolean;
  noAccess?: boolean;
  tabPermission: TabPermissionKey;
  fieldPermissions: FieldPermissions[];
  permissionGroup: {
    id: string;
    resourceName: string;
  };
}

export interface AssignedUsersResponse {
  profile: {
    id: number;
    name: string;
  };
  assignedUsers: PaginationResponseType<User>;
}

export type CloneProfilePayload = {
  id: number;
  resourceName: string;
  description: string;
  isUserProfile?: boolean;
};

export interface Systems {
  profile: {
    id: string;
    name: string;
  };
  systemPermissions: SystemPermissions[];
}

export type AppPermissionsResponse = {
  profile: {
    id: string;
    name: string;
  };
  appPermissions: AppPermission[];
};

export interface SystemPermissions {
  id: number;
  name: string;
  resource: string;
  description: string;
  isEnabled: boolean;
}

export interface SystemPermissionsPayload {
  id: number;
  isEnabled: boolean;
}

type AppPermission = {
  id: string;
  isVisible: boolean;
  isDefault: boolean;
  disableVisible?: boolean;
};

export interface AppPermissionPayload {
  data: AppPermission[];
  id: string;
}
