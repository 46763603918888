export enum AddressKey {
  Address = 'address',
  Street = 'street_line',
  City = 'city',
  Zip = 'zipcode',
  State = 'state',
  County = 'county',
  Country = 'country',
  District = 'district',
  IslandCode = 'islandCode',
  Entries = 'entries',
}

export type addressSuggestions = {
  [AddressKey.Entries]?: string;
  [AddressKey.Address]: string;
  [AddressKey.Street]: string;
  [AddressKey.City]: string;
  [AddressKey.Zip]: string;
  [AddressKey.State]?: string;
  [AddressKey.County]?: string;
  [AddressKey.Country]?: string;
  [AddressKey.District]?: string;
  [AddressKey.IslandCode]?: string;
};

export type Address = {
  suggestions: addressSuggestions[];
};
