import { useMemo, useState } from 'react';
import { UseInfiniteQueryOptions, useInfiniteQuery } from 'react-query';
import { useDebounce } from 'src/hooks';
import { isEmpty } from 'src/validations';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { Option } from '../types';
import { DisbursementKey } from './keys';
import { Disbursement } from './types';

const pageParam = {
  take: 10,
  skip: 0,
};

export function useLazyDisbursement(
  options?: UseInfiniteQueryOptions<{ data: Disbursement[] }, Error> & {
    [key: string]: string | number | string[];
  }
) {
  const [inputSearch, setInputSearch] = useState<string>('');
  const debounceSearch = useDebounce(inputSearch);
  const {
    data,
    error,
    isError,
    isFetching,
    refetch: getDisbursement,
    fetchNextPage,
  } = useInfiniteQuery<{ data: Disbursement[] }, Error>(
    [API_QUERIES.Disbursements, pageParam, debounceSearch, { ...options }],
    (props): Promise<{ data: Disbursement[] }> => {
      const { pageParam, queryKey } = props;
      const search = queryKey.at(-2);
      const options = queryKey.at(-1);

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      return responseWrapper<{ data: Disbursement[] }>(apiClient.getAllDisbursements, [
        { ...pageParam, search, ...(options as Object) },
      ]);
    },
    {
      keepPreviousData: true,
      getNextPageParam(lastPage, allPages) {
        if (lastPage.data?.length < 10) return undefined;
        return {
          take: 10,
          skip: allPages.length * 10,
        };
      },
      notifyOnChangeProps: ['data', 'isFetching'],
      ...options,
    }
  );

  const disbursements: Option[] = useMemo(() => {
    if (isEmpty(data)) return [];
    return data.pages.reduce(
      (state, page, pageIdx) => [
        ...state,
        ...page.data.map(
          (disbursement, idx): Option => ({
            label: disbursement[DisbursementKey.DisbursementNumber],
            value: disbursement[DisbursementKey.Id],
            index: pageIdx * 10 + idx,
          })
        ),
      ],
      []
    ) as Option[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return {
    disbursements,
    error,
    isError,
    loading: isFetching,
    getDisbursement,
    fetchNextPage,
    setInputSearch,
  };
}
