import { useMutation, UseMutationOptions } from 'react-query';
import { Toastify } from 'src/services';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { ObjectPermissionsPayload } from './types';

export function useEditObjectPermission(
  options?: UseMutationOptions<any, Error, ObjectPermissionsPayload>
) {
  const {
    mutate: onEditObjectPermission,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useMutation<ObjectPermissionsPayload, Error, ObjectPermissionsPayload>({
    mutationFn: (payload: ObjectPermissionsPayload) =>
      responseWrapper(apiClient.editObjectPermission, [payload]),
    onError: ({ message }) => Toastify.error(message),
    ...options,
  });

  return {
    onEditObjectPermission,
    isLoading,
    isSuccess,
    isError,
    error,
  };
}
