import { useMutation, UseMutationOptions } from 'react-query';
import { Toastify } from 'src/services';
import apiClient from '../apiClient';
import { createResponseWrapper } from '../helpers';
import { CommentPayload } from './types';

export function useCreateEmployerComment(
  options?: UseMutationOptions<CommentPayload, Error, CommentPayload>
) {
  const { mutate: createComment, isLoading } = useMutation<CommentPayload, Error, CommentPayload>({
    mutationFn: (payload: CommentPayload) =>
      createResponseWrapper(apiClient.createEmployerComment, [payload]),
    onError: ({ message }) => {
      Toastify.error(message);
    },
    ...options,
  });

  return {
    createComment,
    isLoading,
  };
}
