import { useMutation, UseMutationOptions } from 'react-query';
import { Toastify } from 'src/services';
import apiClient from '../apiClient';
import { authResponseWrapper } from '../helpers';
import { RelatedOrderType } from './types';

export function useDeleteRelatedOrder(
  options?: UseMutationOptions<any, Error, RelatedOrderType['id']>
) {
  const { mutate: deleteRelatedOrder, isLoading } = useMutation<
    any,
    Error,
    RelatedOrderType['id']
  >({
    mutationFn: (payload: RelatedOrderType['id']) =>
      authResponseWrapper(apiClient.deleteOrder, [payload]),
    ...options,
    onError: (err) => Toastify.error(err.message),
  });

  return {
    deleteRelatedOrder,
    isLoading,
  };
}
