import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { authResponseWrapper } from '../helpers';

export function useDeleteHearingRequiredDocuments(
  options?: UseMutationOptions<string, Error, string>
) {
  const { mutate: deleteRelatedHearingRequiredDocuments, isLoading } = useMutation<
    string,
    Error,
    string
  >({
    mutationFn: (payload: string) =>
      authResponseWrapper(apiClient.deleteRelatedHearingRequiredDocuments, [payload]),
    onError: (error) => ErrorService.handler(error),
    ...options,
  });

  return {
    deleteRelatedHearingRequiredDocuments,
    isLoading,
  };
}
