import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { ApiResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { toReceipt } from './helpers';
import { Receipt } from './types';

export function useGetReceipt(
  options?: UseQueryOptions<ApiResponseType<{ receipt: Receipt }>, Error, Receipt> & {
    id: Receipt['id'];
  }
) {
  const {
    data: receipt,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetReceipt,
  } = useQuery<ApiResponseType<{ receipt: Receipt }>, Error, Receipt>(
    [API_QUERIES.Receipts, { id: options.id }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<{ receipt: Receipt }>>(apiClient.getReceipt, params);
      },
      select: ({ data }) => toReceipt(data?.receipt),
      enabled: !!options.id,
      ...options,
    }
  );
  const queryClient = useQueryClient();

  const handleInvalidateReceipt = () =>
    queryClient.invalidateQueries([API_QUERIES.Receipts, { id: options.id }]);

  return {
    receipt,
    error,
    isError,
    isLoading,
    onGetReceipt,
    handleInvalidateReceipt,
  };
}
