import { toAuditData, toShortAddress, toRelatedAuditData } from '../helpers';
import { TDI30Key } from './keys';

export const toTDI30Detail = (data) => ({
  ...data,
  [TDI30Key.ClaimantName]: data?.claimant?.accountName,
  [TDI30Key.ClaimantSSN]: data?.claimant?.ssn,
  [TDI30Key.ClaimantBirthDate]: data?.claimant?.dateOfBirth,
  [TDI30Key.ClaimantAddress]: toShortAddress(data?.claimant),
  [TDI30Key.ClaimantPhone]: data?.claimant?.phone,
  [TDI30Key.ClaimantEmail]: data?.claimant?.email,
  [TDI30Key.ClaimantSex]: data?.claimant?.gender,
  [TDI30Key.ClaimantMaritalStatus]: data?.claimant?.maritalStatus,

  [TDI30Key.EmployerName]: data?.employer?.accountName,
  [TDI30Key.EmployerAddress]: toShortAddress(data?.employer),

  [TDI30Key.DoctorName]: data?.doctorAPRN?.accountName,
  [TDI30Key.DoctorAddress]: toShortAddress(data?.doctorAPRN),
  [TDI30Key.DoctorPhone]: data?.doctorAPRN?.phone,
  [TDI30Key.DoctorEmail]: data?.doctorAPRN?.email,
  [TDI30Key.TDISFCaseNumber]: data?.tdiSFCase?.tdiSFCaseNumber,
  ...toAuditData(data),
});

export const toTDI30s = (data) => ({
  ...data,
  data: data.data.map((tdi30) => ({
    ...tdi30,
    [TDI30Key.Claimant]: tdi30?.claimant?.accountName,
    [TDI30Key.ClaimantId]: tdi30?.claimant?.id,
    [TDI30Key.ClaimantSSN]: tdi30?.claimant?.ssn,
    [TDI30Key.ClaimantBirthDate]: tdi30?.claimant?.dateOfBirth,
    [TDI30Key.ClaimantStreet]: tdi30?.claimant?.street,
    [TDI30Key.ClaimantZip]: tdi30?.claimant?.zip,
    [TDI30Key.ClaimantCity]: tdi30?.claimant?.city,
    [TDI30Key.ClaimantState]: tdi30?.claimant?.state,
    [TDI30Key.ClaimantPhone]: tdi30?.claimant?.phone,
    [TDI30Key.ClaimantMaritalStatus]: tdi30?.claimant?.maritalStatus,
    [TDI30Key.ClaimantSex]: tdi30?.claimant?.gender,
    [TDI30Key.ClaimantEmail]: tdi30?.claimant?.email,

    [TDI30Key.Employer]: tdi30?.employer?.accountName,
    [TDI30Key.EmployerId]: tdi30?.employer?.id,
    [TDI30Key.EmployerStreet]: tdi30?.employer?.street,
    [TDI30Key.EmployerZip]: tdi30?.employer?.zip,
    [TDI30Key.EmployerCity]: tdi30?.employer?.city,
    [TDI30Key.EmployerState]: tdi30?.employer?.state,

    [TDI30Key.Doctor]: tdi30?.doctorAPRN?.accountName,
    [TDI30Key.DoctorId]: tdi30?.doctorAPRN?.id,
    [TDI30Key.DoctorCity]: tdi30?.doctorAPRN?.city,
    [TDI30Key.DoctorZip]: tdi30?.doctorAPRN?.zip,
    [TDI30Key.DoctorState]: tdi30?.doctorAPRN?.state,
    [TDI30Key.DoctorPhone]: tdi30?.doctorAPRN?.phone,
    [TDI30Key.DoctorEmail]: tdi30?.doctorAPRN?.email,

    [TDI30Key.TDISFCase]: tdi30?.tdiSFCase?.tdiSFCaseNumber,
    [TDI30Key.TDISFCaseId]: tdi30?.tdiSFCase?.id,

    ...toAuditData(tdi30),
    ...toRelatedAuditData(tdi30),
  })),
});
