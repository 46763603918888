import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { ApiResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { toWC5ADetail } from './helpers';
import { WC5ARawInterface } from './WC5ATypes';

export function useGetWC5A(
  options?: UseQueryOptions<ApiResponseType<{ form: any }>, Error, WC5ARawInterface> & {
    id: string;
  }
) {
  const {
    data: wc5a,
    error,
    isError,
    isFetching: isLoading,
    refetch,
  } = useQuery<ApiResponseType<{ form: any }>, Error, any>(
    [API_QUERIES.WC5As, { id: options.id }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<{ form: any }>>(apiClient.getWC5ADetail, params);
      },
      enabled: !!options.id,
      select: ({ data }) => toWC5ADetail(data.form),
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateWC5A = () =>
    queryClient.invalidateQueries([API_QUERIES.WC5As, { id: options.id }]);

  return {
    wc5a,
    error,
    isError,
    isLoading,
    refetch,
    handleInvalidateWC5A,
  };
}
