import { convertUTCDateTimeToHST, formatDate, getFirstLastName } from 'src/utils';
import { PaginationResponseType, toAuditData, toRelatedAuditData } from '../helpers';
import { DecisionKey } from './keys';
import { DecisionPayLoad } from './types';

export const toDecisions = (
  data: PaginationResponseType<any>
): PaginationResponseType<DecisionPayLoad> => ({
  ...data,
  data: data?.data?.map((item) => {
    return {
      ...item,
      ...toAuditData(item),
      ...toRelatedAuditData(item),
      [DecisionKey.CaseNumber]: item?.case?.caseNumber,
      [DecisionKey.CaseId]: item?.case?.id,
      [DecisionKey.InvolveSCF]: item?.isInvolvedSCF,
      [DecisionKey.FiledById]: item?.filedBy?.individualAccount
        ? item?.filedBy?.individualAccount?.id
        : item?.filedBy?.contactDetail?.id,
      [DecisionKey.ClaimantName]:
        item?.case?.claimant?.accountName ||
        item?.tdiCase?.claimant?.accountName ||
        item?.tdiSFCase?.claimant?.accountName,
      [DecisionKey.ClaimantId]:
        item?.case?.claimant?.id || item?.tdiCase?.claimant?.id || item?.tdiSFCase?.claimant?.id,
      [DecisionKey.ClaimantSSN]:
        item?.case?.claimant?.ssn || item?.tdiCase?.claimant?.ssn || item?.tdiSFCase?.claimant?.ssn,
      [DecisionKey.DateOfII]: item?.case?.dateOfInjury,
      [DecisionKey.DateOfDeath]: item?.case?.claimant?.dateOfDeath,
      [DecisionKey.CurrentServicingLocation]: item?.case?.currentServicingLocation,
      [DecisionKey.CaseAdministratorId]: item?.case?.caseAdmin?.id,
      [DecisionKey.FiledBy]: item?.filedBy?.individualAccount
        ? item?.filedBy?.individualAccount?.accountName
        : item?.filedBy?.contactDetail?.contactName,
      [DecisionKey.HearingOfficerFromHearing]:
        item?.hearing?.hearingOfficer?.firstName && getFirstLastName(item?.hearing?.hearingOfficer),
      [DecisionKey.DateOfHearingRequest]: item?.hearing?.hearingRequestDate,
      [DecisionKey.HearingNoticeMailDate]: item?.hearing?.hearingNoticeMailDate,
      [DecisionKey.HearingDate]: formatDate(convertUTCDateTimeToHST(item?.hearing?.scheduleDate)),
      [DecisionKey.HearingNumber]: item?.hearing?.hearingNumber,
      [DecisionKey.HearingId]: item?.hearing?.id,
      [DecisionKey.HearingOfficer]: getFirstLastName(item?.hearingOfficer),
      [DecisionKey.AppealedDecision]: item?.appealedDecision?.decisionNumber,
      [DecisionKey.AppealedDecisionId]: item?.appealedDecision?.id,
      [DecisionKey.AmendedDecision]: item?.amendedDecision?.decisionNumber,
      [DecisionKey.AmendedDecisionId]: item?.amendedDecision?.id,
      [DecisionKey.ReopenedDecision]: item?.reopenedDecision?.decisionNumber,
      [DecisionKey.ReopenedDecisionId]: item?.reopenedDecision?.id,
      [DecisionKey.AssignedTo]: item?.assignedUser && getFirstLastName(item?.assignedUser),
      [DecisionKey.DecisionAdministrator]:
        item?.decisionAdministrator && getFirstLastName(item?.decisionAdministrator),
      [DecisionKey.ClericalServices]:
        item?.clericalServices && getFirstLastName(item?.clericalServices),
      [DecisionKey.DecisionFinalizer]:
        item?.decisionFinalizer && getFirstLastName(item?.decisionFinalizer),
      [DecisionKey.MailProcessor]: item?.mailProcessor && getFirstLastName(item?.mailProcessor),
      [DecisionKey.DecisionProcessor]:
        item?.decisionProcessor && getFirstLastName(item?.decisionProcessor),
      [DecisionKey.DecisionReviewer]:
        item?.decisionReviewer && getFirstLastName(item?.decisionReviewer),
      [DecisionKey.DistrictLevelReviewer]:
        item?.districtLevelReviewer && getFirstLastName(item?.districtLevelReviewer),
      [DecisionKey.CaseAdministrator]:
        item?.case?.caseAdmin && getFirstLastName(item?.case?.caseAdmin),

      // TDI Decision
      [DecisionKey.RefereeUser]: item?.referee ? getFirstLastName(item?.referee) : '',
      [DecisionKey.RefereeHearing]: item?.hearing?.referee
        ? getFirstLastName(item?.hearing?.referee)
        : '',
      [DecisionKey.RefereeHearingId]: item?.hearing?.referee?.id,
      [DecisionKey.CopyCertifiedId]: item?.copyCertifiedUser?.id,
      [DecisionKey.CopyCertifiedBy]: item?.copyCertifiedUser
        ? getFirstLastName(item?.copyCertifiedUser)
        : '',
      [DecisionKey.AdditionalHOCertifiedDECApproval]: item?.additionalHOForCertifiedDECApprovalUser
        ? getFirstLastName(item?.additionalHOForCertifiedDECApprovalUser)
        : '',
      [DecisionKey.AdditionalHOCertifiedDECApprovalId]:
        item?.additionalHOForCertifiedDECApprovalUser?.id,
      [DecisionKey.TDICaseNumber]: item?.tdiCase?.tdiCaseNumber,
      [DecisionKey.TDICaseId]: item?.tdiCase?.id,
      [DecisionKey.Claimant]:
        item?.tdiCase?.claimant?.accountName ||
        item?.tdiSFCase?.claimant?.accountName ||
        item?.case?.claimant?.accountName,
      [DecisionKey.TDIClaimantId]:
        item?.tdiCase?.claimant?.id || item?.tdiSFCase?.claimant?.id || item?.case?.claimant?.id,
      [DecisionKey.TDISFCaseNumber]: item?.tdiSFCase?.tdiSFCaseNumber,
      [DecisionKey.TDISFCaseId]: item?.tdiSFCase?.id,
      [DecisionKey.DisabilityDate]: item?.tdiSFCase?.disabilityDate,
      [DecisionKey.FirstDateOfDisabilityClaimed]: item?.tdiCase?.firstDateOfDisabilityClaimed,
    };
  }),
});

export const toDecisionDetail = (data) => ({
  ...data,
  [DecisionKey.TDISFCaseNumber]: data?.tdiSFCase?.tdiSFCaseNumber,
  [DecisionKey.TDICaseNumber]: data?.tdiCase?.tdiCaseNumber,
  [DecisionKey.CaseNumber]: data?.case?.caseNumber,
  [DecisionKey.ClaimantId]:
    data?.case?.claimant?.id || data?.tdiCase?.claimant?.id || data?.tdiSFCase?.claimant?.id,
  [DecisionKey.ClaimantName]:
    data?.case?.claimant?.accountName ||
    data?.tdiCase?.claimant?.accountName ||
    data?.tdiSFCase?.claimant?.accountName,
  [DecisionKey.ClaimantSSN]:
    data?.case?.claimant?.ssn || data?.tdiCase?.claimant?.ssn || data?.tdiSFCase?.claimant?.ssn,
  [DecisionKey.DateOfII]: data?.case?.dateOfInjury,
  [DecisionKey.DateOfDeath]:
    data?.case?.claimant?.dateOfDeath ||
    data?.tdiCase?.claimant?.dateOfDeath ||
    data?.tdiSFCase?.claimant?.dateOfDeath,
  [DecisionKey.DisabilityDate]: data?.tdiSFCase?.disabilityDate,
  [DecisionKey.FirstDateOfDisabilityClaimed]: data?.tdiCase?.firstDateOfDisabilityClaimed,
  [DecisionKey.CurrentServicingLocation]: data?.case?.currentServicingLocation,
  [DecisionKey.CaseAdministratorId]: data?.case?.caseAdmin?.id,
  [DecisionKey.HearingOfficerFromHearing]:
    data?.hearing?.hearingOfficer?.firstName && getFirstLastName(data?.hearing?.hearingOfficer),
  [DecisionKey.RefereeHearing]: data?.hearing?.referee && getFirstLastName(data?.hearing?.referee),
  [DecisionKey.DateOfHearingRequest]: data?.hearing?.hearingRequestDate,
  [DecisionKey.HearingNoticeMailDate]: data?.hearing?.hearingNoticeMailDate,
  [DecisionKey.HearingDate]: formatDate(convertUTCDateTimeToHST(data?.hearing?.scheduleDate)),

  [DecisionKey.HearingNumber]: data?.hearing?.hearingNumber,
  [DecisionKey.HearingOfficerName]: getFirstLastName(data?.hearingOfficer),
  [DecisionKey.RefereeUser]: getFirstLastName(data?.referee),
  [DecisionKey.AppealedDecisionNumber]: data?.appealedDecision?.decisionNumber,
  [DecisionKey.AmendedDecisionNumber]: data?.amendedDecision?.decisionNumber,
  [DecisionKey.ReopenedDecisionNumber]: data?.reopenedDecision?.decisionNumber,
  [DecisionKey.AssignedTo]: data?.assignedUser && getFirstLastName(data?.assignedUser),
  [DecisionKey.DecisionAdministratorName]:
    data?.decisionAdministrator && getFirstLastName(data?.decisionAdministrator),
  [DecisionKey.ClericalServicesName]:
    data?.clericalServices && getFirstLastName(data?.clericalServices),
  [DecisionKey.DecisionFinalizerName]:
    data?.decisionFinalizer && getFirstLastName(data?.decisionFinalizer),
  [DecisionKey.MailProcessorName]: data?.mailProcessor && getFirstLastName(data?.mailProcessor),
  [DecisionKey.DecisionProcessorName]:
    data?.decisionProcessor && getFirstLastName(data?.decisionProcessor),
  [DecisionKey.DecisionReviewerName]:
    data?.decisionReviewer && getFirstLastName(data?.decisionReviewer),
  [DecisionKey.DistrictLevelReviewerName]:
    data?.districtLevelReviewer && getFirstLastName(data?.districtLevelReviewer),
  [DecisionKey.RefereeUserName]: data?.referee && getFirstLastName(data?.referee),
  [DecisionKey.CopyCertifiedByName]:
    data?.copyCertifiedUser && getFirstLastName(data?.copyCertifiedUser),
  [DecisionKey.AdditionalHOCertifiedDECApproval]:
    data?.additionalHOForCertifiedDECApprovalUser &&
    getFirstLastName(data?.additionalHOForCertifiedDECApprovalUser),
  [DecisionKey.CaseAdministratorName]:
    data?.case?.caseAdmin && getFirstLastName(data?.case?.caseAdmin),
  ...toAuditData(data),
});
