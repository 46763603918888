import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../../apiClient';
import { ApiResponseType, responseWrapper } from '../../helpers';
import { API_QUERIES } from '../../keys';
import { ApprovalHistoryInterface } from './types';

export function useGetApprovalHistory(
  options?: UseQueryOptions<
    ApiResponseType<{ approvalHistory: any }>,
    Error,
    ApprovalHistoryInterface
  > & {
    id: string;
  }
) {
  const {
    data: approvalHistory,
    error,
    isError,
    isFetching: isLoading,
    refetch,
  } = useQuery<ApiResponseType<{ approvalHistory: any }>, Error, any>(
    [API_QUERIES.APPROVAL_HISTORY_DETAIL, { id: options.id }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;

        return responseWrapper<ApiResponseType<{ approvalHistory: any }>>(
          apiClient.getApprovalHistoryDetail,
          params
        );
      },
      enabled: !!options.id,
      select: (data) => data.data.approvalHistory,
      onError: ErrorService.handler,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateApprovalHistory = () =>
    queryClient.invalidateQueries([API_QUERIES.APPROVAL_HISTORY_DETAIL, { id: options.id }]);

  return {
    approvalHistory,
    error,
    isError,
    isLoading,
    refetch,
    handleInvalidateApprovalHistory,
  };
}
