import { AuditKey } from 'src/queries/keys';
import { WC21Key } from './keys';

export interface WC21 {
  id: string;
  [WC21Key.EmployerID]: string;
  [WC21Key.EmployerName]: string;
  [WC21Key.AssignedUserName]: string;
  [WC21Key.FormNumber]: string;
  [WC21Key.EmployerAccountName]: string;
  [WC21Key.RawDateReceived]: string;
  [WC21Key.RawSignature]: string;
  [WC21Key.RawSignatureEmail]: string;
  [WC21Key.RawSignatureDate]: string;
  [WC21Key.RawSignaturePhone]: string;
  [WC21Key.RawOnBehalfOf]: string;
  [WC21Key.RawApplicantName]: string;
  [WC21Key.RawApplicantType]: ApplicantType;
  [WC21Key.RawApplicantDOLNumber]: string;
  [WC21Key.RawApplicantAddress]: string;
  [WC21Key.RawApplicantCity]: string;
  [WC21Key.RawApplicantState]: string;
  [WC21Key.RawApplicantZipCode]: string;
  [WC21Key.RawNatureOfBusiness]: string;
  [WC21Key.RawNumOfHawaiiEmployees]: string;
  [WC21Key.RawAvgMonthlyPay]: string;
  [WC21Key.RawHawaiiAddress]: string;
  [WC21Key.RawHawaiiCity]: string;
  [WC21Key.RawHawaiiZipCode]: string;
  [WC21Key.RawAdditionalEntities]: AdditionalEntities[];
  [WC21Key.RawHawaiiNetProfitLoss]: NetProfitHawaii[];
  [WC21Key.RawHawaiiStateExposure]: StateExposureHawaii[];
  [WC21Key.RawPocMainTitle]: string;
  [WC21Key.RawPocMainFirstName]: string;
  [WC21Key.RawPocMainLastName]: string;
  [WC21Key.RawPocMainEmail]: string;
  [WC21Key.RawPocMainPhone]: string;
  [WC21Key.RawPocMainFax]: string;
  [WC21Key.RawPocMainAddress]: string;
  [WC21Key.RawPocMainCity]: string;
  [WC21Key.RawPocMainState]: string;
  [WC21Key.RawPocMainZipCode]: string;
  [WC21Key.RawPocAnnualReportTitle]: string;
  [WC21Key.RawPocAnnualReportFirstName]: string;
  [WC21Key.RawPocAnnualReportLastName]: string;
  [WC21Key.RawPocAnnualReportEmail]: string;
  [WC21Key.RawPocAnnualReportPhone]: string;
  [WC21Key.RawPocAnnualReportAddress]: string;
  [WC21Key.RawPocAnnualReportCity]: string;
  [WC21Key.RawPocAnnualReportState]: string;
  [WC21Key.RawPocAnnualReportZipCode]: string;
  [WC21Key.RawPocBondTitle]: string;
  [WC21Key.RawPocBondFirstName]: string;
  [WC21Key.RawPocBondLastName]: string;
  [WC21Key.RawPocBondEmail]: string;
  [WC21Key.RawPocBondPhone]: string;
  [WC21Key.RawPocBondAddress]: string;
  [WC21Key.RawPocBondCity]: string;
  [WC21Key.RawPocBondState]: string;
  [WC21Key.RawPocBondZipCode]: string;
  [WC21Key.RawBusinessCommencementDate]: string;
  [WC21Key.RawSecurityDepositForm]: string;
  [WC21Key.RawSupplementedByInsurancePolicy]: string;
  [WC21Key.RawAnyLitigationOrProceedingPending]: string;
  [WC21Key.RawExcessPolicyExplanation]: string;
  [WC21Key.RawRejectedOrCancelled]: string;
  [WC21Key.RawRejectedOrCancelledDate]: string;
  [WC21Key.RawCarrierName]: string;
  [WC21Key.RawPolicyCancelledWhy]: string;
  [WC21Key.RawClaimsAdminPerformedBy]: ClaimsAdministrationPerformed;
  [WC21Key.RawSelfInsurerTitle]: string;
  [WC21Key.RawSelfInsurerFirstName]: string;
  [WC21Key.RawSelfInsurerLastName]: string;
  [WC21Key.RawSelfInsurerPhone]: string;
  [WC21Key.RawSelfInsurerAddress]: string;
  [WC21Key.RawSelfInsurerCity]: string;
  [WC21Key.RawSelfInsurerState]: string;
  [WC21Key.RawSelfInsurerZipCode]: string;
  [WC21Key.RawThirdPartyCompanyName]: string;
  [WC21Key.RawThirdPartyPhone]: string;
  [WC21Key.RawThirdPartyAddress]: string;
  [WC21Key.RawThirdPartyCity]: string;
  [WC21Key.RawThirdPartyState]: string;
  [WC21Key.RawThirdPartyZipCode]: string;
  [WC21Key.RequiredDocsReceived]: RequiredDocumentReceived;
  [WC21Key.ProcessingStatus]: WC21ProcessingStatus;
  [WC21Key.AcceptanceStatus]: AcceptanceStatus;
  [WC21Key.Comments]: string;
  [WC21Key.AssignedUserName]: string;
  [WC21Key.AssignedUserId]: string;
  [WC21Key.RawSignature]: string;
  [WC21Key.RawSignatureDate]: string;
  [WC21Key.RawSignaturePhone]: string;
  [WC21Key.RawSignatureEmail]: string;
  [WC21Key.RawOnBehalfOf]: string;

  [AuditKey.CreatedAt]: string;
  [AuditKey.CreatedBy]: string;
  [AuditKey.UpdatedAt]: string;
  [AuditKey.UpdatedBy]: string;
}

export enum RequiredDocumentReceived {
  IndependentAuditReport = 'Independent Audit Report',
  FinancialStatements = 'Financial Statements',
  CorporationBoardOfDirectionResolution = 'Corporation Board of Direction Resolution',
}

export enum AdditionalEntitiesKey {
  CompanyName = 'companyName',
  DolNumber = 'dolNumber',
  Relationship = 'relationship',
  Address = 'address',
  City = 'city',
  State = 'state',
  ZipCode = 'zipcode',
}

export enum NetProfitHawaiiKey {
  Number = 'number',
  Year = 'year',
  Amount = 'amount',
}

export enum ExposureStateKey {
  Number = 'number',
  Year = 'year',
  Payroll = 'payroll',
  PremiumBeforeDividend = 'premiumBeforeDividend',
  ExperienceModification = 'experienceModification',
  LossIncurred = 'lossIncurred',
  LossRatio = 'lossRatio',
}

export enum AcceptanceStatus {
  Pending = 'Pending',
  Accepted = 'Accepted',
  Denied = 'Denied',
}

export enum ApplicantType {
  Corporation = 'Corporation',
  Partnership = 'Partnership',
  SoleProprietorship = 'Sole Proprietorship',
  Other = 'Other',
}

export enum WC21ProcessingStatus {
  New = 'New',
  InProgress = 'In Progress',
  Completed = 'Completed',
}

export enum ClaimsAdministrationPerformed {
  SelfInsurerOwnOrganization = "Self Insurer''s Own Organization",
  OutsideOfOrganization = 'Outside of the Organization',
}

export interface AdditionalEntities {
  [AdditionalEntitiesKey.CompanyName]: string;
  [AdditionalEntitiesKey.DolNumber]: string;
  [AdditionalEntitiesKey.Relationship]: string;
  [AdditionalEntitiesKey.Address]: string;
  [AdditionalEntitiesKey.City]: string;
  [AdditionalEntitiesKey.State]: string;
  [AdditionalEntitiesKey.ZipCode]: string;
}

export interface NetProfitHawaii {
  [NetProfitHawaiiKey.Number]: number;
  [NetProfitHawaiiKey.Year]: number;
  [NetProfitHawaiiKey.Amount]: number;
}

export interface StateExposureHawaii {
  [ExposureStateKey.Number]: number;
  [ExposureStateKey.Year]: number;
  [ExposureStateKey.Payroll]: number;
  [ExposureStateKey.PremiumBeforeDividend]: number;
  [ExposureStateKey.ExperienceModification]: string;
  [ExposureStateKey.LossIncurred]: number;
  [ExposureStateKey.LossRatio]: number;
}

export const applicantTypeOptions = [
  { value: ApplicantType.Corporation, label: 'Corporation' },
  { value: ApplicantType.Partnership, label: 'Partnership' },
  { value: ApplicantType.SoleProprietorship, label: 'Sole Proprietorship' },
  { value: ApplicantType.Other, label: 'Other' },
];

export const claimsAdministrationPerformedOptions = [
  {
    label: `Self Insurer’s Own Organization`,
    value: ClaimsAdministrationPerformed.SelfInsurerOwnOrganization,
  },
  {
    label: 'Outside of the Organization',
    value: ClaimsAdministrationPerformed.OutsideOfOrganization,
  },
];
