import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { createResponseWrapper } from '../helpers';
import { toValues } from './helpers';
import { HC61 } from './types';

export function useUpdateHC61(options?: UseMutationOptions<HC61, Error, HC61>) {
  const { mutate: editHC61, isLoading: isUpdating } = useMutation<HC61, Error, HC61>({
    mutationFn: (payload: HC61) => createResponseWrapper(apiClient.updateHC61, [toValues(payload)]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    editHC61,
    isUpdating,
  };
}
