import axios from 'axios';
import JSZip from 'jszip';
import { isEmpty } from '../validations';

export type Language = {
  language: string;
};

let languages: Language[] = [];

const LOCAL_LANGUAGES_PATH = 'languages';

export type LanguageParams = {
  take: number;
  skip: number;
  search: string;
  url: string;
};

const loadLanguages = async (url: string) => {
  const languageOptions = localStorage.getItem(LOCAL_LANGUAGES_PATH);
  if (languageOptions) {
    return new Promise((res) => {
      const parseLanguages = JSON.parse(languageOptions);
      languages = parseLanguages;
      res(parseLanguages);
    });
  }

  try {
    const data = await getLanguageFileZip(url);
    languages = data;
    localStorage.setItem(LOCAL_LANGUAGES_PATH, JSON.stringify(data));
    return data;
  } catch {}
};

const getPaginationLanguage = async (params: LanguageParams) => {
  if (isEmpty(languages)) {
    await loadLanguages(params.url);
  }
  const { search, skip = 0, take = 10 } = params;
  if (!search) {
    return languages.slice(skip, skip + take);
  }

  return languages;
};

const getLanguageFileZip = async (url: string): Promise<Language[]> => {
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .get(url, {
          responseType: 'blob',
          withCredentials: false,
        })
        .then((data) => {
          return data.data;
        })
        .then(JSZip.loadAsync)
        .then((zip) => {
          return zip.file('languages.json').async('string');
        })
        .then(async (text) => {
          // eslint-disable-next-line security/detect-eval-with-expression, no-eval
          const parseData = await eval('(' + text + ')');
          resolve(parseData);
        });
    } catch (err) {
      reject(err);
    }
  });
};

export default { getPaginationLanguage };
