import { WC3RawKey } from './WC3RawKey';
import { WC3SubmittalKey } from './WC3SubmittalKey';

export const WC3Key = { ...WC3RawKey, ...WC3SubmittalKey };

export enum HistoricalKey {
  CaseId = 'caseId',
  CaseNumber = 'caseNumber',
  DateReceived = 'dateReceived',
  NewAmend = 'newAmend',
  ClaimantId = 'claimantId',
  EmployerId = 'employerId',
  InsuranceCarrierId = 'insuranceCarrierId',
  AdjusterId = 'adjusterId',
  WC3Type = 'wc3Type',
  WC3TypeValue = 'wc3TypeValue',
  ReturnWorkDate = 'returnToWorkDate',
  WeeklyCompensationRate = 'weeklyCompensationRate',
  CarrierComment = 'carrierComment',
  BenefitPayments = 'benefitPayments',
  PrintedNameCarrier = 'signaturePrintedName',
  SignatureDate = 'signatureDate',
  CarrierCaseNumber = 'carrierCaseNumber',

  CarrierId = 'carrierId',
  ClaimantAddress = 'claimantAddress',
  SSN = 'ssn',
  DateOfII = 'dateOfInjury',
  CarrierClaimNumber = 'carrierClaimNumber',
  MedicalDeductible = 'medicalDeductible',
  AdjusterAddress = 'adjusterAddress',
  DateFirstIncomeReplacement = 'dateFirstIncomeReplacement',
  PaymentYear = 'paymentYear',
  PaymentTypes = 'paymentTypes',
}

export enum BenefitPaymentHistoricalKey {
  PaymentType = 'paymentType',
  Days = 'days',
  PaymentNotPrevReport = 'paymentNotPrevReport',
  PriorPayments = 'priorPayment',
  TotalPayments = 'totalPayments',
}

export enum FinalReportKey {
  CaseId = 'caseId',
  PaymentYear = 'paymentYear',
}
