import { Filter, Operator } from 'src/modules/shared-main/queries';
import { Yup } from 'src/services';

export enum ReportGroupRangeDateFilterKey {
  StartDate = 'startDate',
  EndDate = 'endDate',
  GroupBy = 'groupBy',
}

export enum ReportGroupRangeDateFilterGroupBy {
  Weekly = 'Weekly',
  Monthly = 'Monthly',
  Yearly = 'Yearly',
}

export const groupByOptions = [
  {
    label: 'Weekly',
    value: ReportGroupRangeDateFilterGroupBy.Weekly,
  },
  {
    label: 'Monthly',
    value: ReportGroupRangeDateFilterGroupBy.Monthly,
  },
  {
    label: 'Yearly',
    value: ReportGroupRangeDateFilterGroupBy.Yearly,
  },
];

export type ReportGroupRangeDateFilterPayload = {
  [ReportGroupRangeDateFilterKey.StartDate]: string;
  [ReportGroupRangeDateFilterKey.EndDate]: string;
  [ReportGroupRangeDateFilterKey.GroupBy]?: ReportGroupRangeDateFilterGroupBy;
};

const formatStartDate = (date: string) => {
  const formattedDate = new Date(date);
  formattedDate.setHours(0, 0, 0, 0);
  return formattedDate.toISOString();
};

const formatEndDate = (date: string) => {
  const formattedDate = new Date(date);
  formattedDate.setHours(23, 59, 59, 999);
  return formattedDate.toISOString();
};

export const getInitialValues = (viewFilters: Filter[]): ReportGroupRangeDateFilterPayload => {
  const startDateObj = viewFilters.find(
    (filter) => filter.field === ReportGroupRangeDateFilterKey.StartDate
  );

  const endDateObj = viewFilters.find(
    (filter) => filter.field === ReportGroupRangeDateFilterKey.EndDate
  );

  const groupByObj = viewFilters.find(
    (filter) => filter.field === ReportGroupRangeDateFilterKey.GroupBy
  );

  return {
    [ReportGroupRangeDateFilterKey.StartDate]: (startDateObj?.value as string) || '',
    [ReportGroupRangeDateFilterKey.EndDate]: (endDateObj?.value as string) || '',
    [ReportGroupRangeDateFilterKey.GroupBy]:
      (groupByObj?.value as ReportGroupRangeDateFilterGroupBy) || null,
  };
};

export const validationSchema = Yup.object().shape({
  [ReportGroupRangeDateFilterKey.StartDate]: Yup.string().required().nullable(),
  [ReportGroupRangeDateFilterKey.EndDate]: Yup.string().required().nullable(),
  [ReportGroupRangeDateFilterKey.GroupBy]: Yup.string().required().nullable(),
});

export const formatViewFilter = (filter: Filter, values: ReportGroupRangeDateFilterPayload) => {
  const { field } = filter || {};

  if (field === ReportGroupRangeDateFilterKey.StartDate) {
    return {
      ...filter,
      op: Operator.Equals,
      value: formatStartDate(values?.[ReportGroupRangeDateFilterKey.StartDate]),
    };
  }

  if (field === ReportGroupRangeDateFilterKey.EndDate) {
    return {
      ...filter,
      op: Operator.Equals,
      value: formatEndDate(values?.[ReportGroupRangeDateFilterKey.EndDate]),
    };
  }

  if (field === ReportGroupRangeDateFilterKey.GroupBy) {
    return {
      ...filter,
      op: Operator.Equals,
      value: values?.[ReportGroupRangeDateFilterKey.GroupBy],
    };
  }

  return filter;
};
