import React, { HTMLProps, useState } from 'react';
import './styles.scss';

const Toggle: React.FC<Props> = ({
  name,
  label,
  onChange,
  defaultChecked,
  checked = false,
  ...props
}) => {
  const [isChecked, setIsChecked] = useState(defaultChecked);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(e);
    setIsChecked((prev) => !prev);
  };

  return (
    <div>
      <input
        checked={isChecked}
        type="checkbox"
        id={name}
        className="cmp-switch-input"
        onChange={handleOnChange}
        {...props}
      />
      <label className="cmp-switch-input__label" htmlFor={name}>
        {label}
      </label>
    </div>
  );
};

type BaseInputProps = Pick<
  HTMLProps<HTMLInputElement>,
  Exclude<keyof HTMLProps<HTMLInputElement>, 'label'>
>;

export type Props = BaseInputProps & {
  label: string;
  name: string;
  onChange?: Function;
};

export default Toggle;
