import { PaginationResponseType, toAuditData } from '../helpers';
import { WC14Key } from './keys';
import { WC14 } from './types';

export const getWC14s = (data: PaginationResponseType<any>): PaginationResponseType<WC14> => ({
  ...data,
  data: data.data.map((wc14s) => ({
    ...wc14s,
    [WC14Key.ClaimantName]: wc14s?.claimant?.accountName,
    [WC14Key.EmployerName]: wc14s?.employer?.accountName,
    [WC14Key.CaseNumber]: wc14s?.wcCase?.caseNumber,
    [WC14Key.PortalWCFormNumber]: wc14s?.wcForm?.wcFormNumber,
    ...toAuditData(wc14s),
  })),
});

export const toWC14Detail = (data) => ({
  ...data,
  [WC14Key.CaseNumber]: data?.wcCase?.caseNumber,
  [WC14Key.ClaimantName]: data?.claimant?.accountName,
  [WC14Key.EmployerName]: data?.employer?.accountName,
  ...toAuditData(data),
});
