import { formatDateTime, getFirstLastName } from 'src/utils';
import { CustomSettingsKey } from './keys';
import { CustomSettings } from './types';

export const toCustomSetting = (data: CustomSettings) => ({
  ...data,
  [CustomSettingsKey.MaximumTaxableWage]: data?.maxTaxableWage ? +data.maxTaxableWage : null,
  [CustomSettingsKey.LastModifiedDate]: formatDateTime(data.updatedAt),
  [CustomSettingsKey.LastModifiedBy]: getFirstLastName(data.updatedUser),
});
