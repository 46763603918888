import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { GetPropertiesParams } from '../types';
import { toTDI30sReport } from './helpers';
import { TDI30sReportResponse } from './types';

export function useGetTDI30sReport(options?: UseQueryOptions<TDI30sReportResponse, Error>) {
  const [params, setParams] = useState<GetPropertiesParams>({});

  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetTDI30sReport,
  } = useQuery<TDI30sReportResponse, Error>(
    [API_QUERIES.REPORT_TDI_30S, { ...params, ...options }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<TDI30sReportResponse>(apiClient.getTDI30sReport, params);
      },
      onError: ErrorService.handler,
      notifyOnChangeProps: ['data', 'isFetching'],
      select: (data) => toTDI30sReport(data),
      keepPreviousData: true,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateTDI30sReport = () =>
    queryClient.invalidateQueries(API_QUERIES.REPORT_TDI_30S);

  const { data: tdi30sReport, totalRecords } = data?.data?.reports || {};

  const { totalTDI30s } = data?.data || {};

  return {
    tdi30sReport,
    totalTDI30s,
    totalRecords,
    error,
    isError,
    isFetching,
    onGetTDI30sReport,
    setParams,
    handleInvalidateTDI30sReport,
  };
}
