import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { createResponseWrapper } from 'src/queries/helpers';
import { Toastify } from 'src/services';
import { WC2FormPayload } from './types';

export function useCreateWC2Form(options?: UseMutationOptions<any, Error, WC2FormPayload>) {
  const { mutate: createWC2Form, isLoading } = useMutation<any, Error, WC2FormPayload>({
    mutationFn: (payload: WC2FormPayload) =>
      createResponseWrapper(apiClient.createWC2sForm, [payload]),
    onError: ({ message }) => Toastify.error(message),
    ...options,
  });

  return {
    createWC2Form,
    isLoading,
  };
}
