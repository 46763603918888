import axios from 'axios';
import JSZip from 'jszip';
import { isEmpty } from '../validations';

export type Locale = {
  locale: string;
};

let locales: Locale[] = [];

const LOCAL_LOCALE_PATH = 'locale';

export type LocaleParams = {
  take: number;
  skip: number;
  search: string;
  url: string;
};

const loadLocale = async (url: string) => {
  const localeOptions = localStorage.getItem(LOCAL_LOCALE_PATH);
  if (localeOptions) {
    return new Promise((res) => {
      const parseLocales = JSON.parse(localeOptions);
      locales = parseLocales;
      res(parseLocales);
    });
  }

  try {
    const data = await getLocaleFileZip(url);
    locales = data;
    localStorage.setItem(LOCAL_LOCALE_PATH, JSON.stringify(data));
    return data;
  } catch {}
};

const getPaginationLocale = async (params: LocaleParams) => {
  if (isEmpty(locales)) {
    await loadLocale(params.url);
  }
  const { search, skip = 0, take = 10 } = params;
  if (!search) {
    return locales.slice(skip, skip + take);
  }

  return locales;
};

const getLocaleFileZip = async (url: string): Promise<Locale[]> => {
  return new Promise(async (resolve, reject) => {
    try {
      axios
        .get(url, {
          responseType: 'blob',
          withCredentials: false,
        })
        .then((data) => {
          return data.data;
        })
        .then(JSZip.loadAsync)
        .then((zip) => {
          return zip.file('locales.json').async('string');
        })
        .then(async (text) => {
          // eslint-disable-next-line security/detect-eval-with-expression, no-eval
          const parseData = await eval('(' + text + ')');
          resolve(parseData);
        });
    } catch (err) {
      reject(err);
    }
  });
};

export default { getPaginationLocale };
