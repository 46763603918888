import { User } from 'src/queries/Users';
import { RoutingInformationKey } from './keys';

export interface RoutingInformation {
  [RoutingInformationKey.Id]: string;
  [RoutingInformationKey.RoutingInformationNumber]: string;
  [RoutingInformationKey.Priority]?: RoutingPriority;
  [RoutingInformationKey.RecipientUserIds]?: string[];
  [RoutingInformationKey.RecipientProfileIds]?: string[];
  [RoutingInformationKey.Comments]?: string;
  [RoutingInformationKey.From]?: string;
  [RoutingInformationKey.To]?: string;

  [RoutingInformationKey.CreatedAt]?: string;
  [RoutingInformationKey.UpdatedAt]?: string;
  [RoutingInformationKey.CreatedBy]?: string;
  [RoutingInformationKey.UpdatedBy]?: string;
  [RoutingInformationKey.UpdatedUser]?: User;
  [RoutingInformationKey.CreatedUser]?: User;
}

export interface RoutingInformationPayload {
  [RoutingInformationKey.ObjectId]: string;
  [RoutingInformationKey.Priority]?: RoutingPriority;
  [RoutingInformationKey.RecipientUserIds]?: string[];
  [RoutingInformationKey.RecipientProfileIds]?: string[];
  [RoutingInformationKey.Comments]?: string;
  [RoutingInformationKey.IsAllProfile]?: boolean;
  [RoutingInformationKey.IsAllUser]?: boolean;
}

export enum RoutingPriority {
  Low = 'Low',
  Normal = 'Normal',
  High = 'High',
}

export enum RoutingInformationObjectType {
  CASE_ACCOUNT = 'CASE_ACCOUNT',
  TDI_ACCOUNT = 'TDI_ACCOUNT',
  TDI_CASE = 'TDI_CASE',
  TDI_SF_CASE = 'TDI_SF_CASE',
  WC_COVERAGE_ACCOUNT = 'WC_COVERAGE_ACCOUNT',
  TDI_COVERAGE_ACCOUNT = 'TDI_COVERAGE_ACCOUNT',
  PHC_ACCOUNT = 'PHC_ACCOUNT',
  DCD_CASE = 'DCD_CASE',
  PREHEARING = 'PREHEARING',
  CASE_DECISION = 'CASE_DECISION',
  TDI_DECISION = 'TDI_DECISION',
  COMPLAINT_FRAUD = 'COMPLAINT_FRAUD',
  COMPLAINT_PHC_TDI = 'COMPLAINT_PHC_TDI',
  REQUEST = 'REQUEST',
  CASE_DISBURSEMENT = 'CASE_DISBURSEMENT',
  TDI_DISBURSEMENT = 'TDI_DISBURSEMENT',
  PHC_DISBURSEMENT = 'PHC_DISBURSEMENT',
  CASE_RECEIPT = 'CASE_RECEIPT',
  TDI_RECEIPT = 'TDI_RECEIPT',
  PHC_RECEIPT = 'PHC_RECEIPT',
  EMPLOYER_AUDIT = 'EMPLOYER_AUDIT',
  TDI_62 = 'TDI_62',
  TDI_62_Form = 'TDI_62',
  TDI_15 = 'TDI_15',
  TDI_15_Form = 'TDI_15',
  TDI_21 = 'TDI_21',
  TDI_21_Form = 'TDI_21',
  HC_15 = 'HC_15',
  HC_15_Form = 'HC_15',
  HC_61 = 'HC_61',
  HC_61_Form = 'HC_61',
  HC_4 = 'HC_4',
  HC_4_Form = 'HC_4',
  WC_1 = 'WC_1',
  WC_1_Form = 'WC_1',
  WC_5 = 'WC_5',
  WC_5_Form = 'WC_5',
}

export enum BasePath {
  CASE_ACCOUNT = '/dcd-ecms/accounts/:id',
  TDI_ACCOUNT = '/tdi/accounts/:id',
  WC_COVERAGE_ACCOUNT = '/wc-coverage/accounts/:id',
  TDI_COVERAGE_ACCOUNT = '/tdi-coverage/accounts/:id',
  PHC_ACCOUNT = '/phc-coverage/accounts/:id',
  DCD_CASE = '/dcd-ecms/dcd-cases/:id',
  PREHEARING = '/dcd-ecms/prehearing-for-employer/:id',
  TDI_CASE = '/tdi/tdi-cases/:id',
  TDI_SF_CASE = '/tdi/tdi-sf-cases/:id',
  CASE_DECISION = '/dcd-ecms/decisions/:id',
  TDI_DECISION = '/tdi/decisions/:id',
  COMPLAINT_FRAUD = '/dcd-ecms/complaints/fraud-complaints/:id',
  COMPLAINT_PHC_TDI = '/dcd-ecms/complaints/phc-tdi/:id',
  REQUEST = '/dcd-ecms/requests/:id',
  CASE_DISBURSEMENT = '/dcd-ecms/disbursements/:id',
  TDI_DISBURSEMENT = '/tdi/disbursements/:id',
  PHC_DISBURSEMENT = '/phc-coverage/disbursements/:id',
  CASE_RECEIPT = '/dcd-ecms/receipts/:id',
  TDI_RECEIPT = '/tdi/receipts/:id',
  PHC_RECEIPT = '/phc-coverage/receipts/:id',
  EMPLOYER_AUDIT = '/dcd-ecms/employer-audits/:id',
  TDI_62 = '/tdi-coverage/tdi-62s/:id',
  TDI_62_Form = '/forms/tdi-62s/:id',
  TDI_15 = '/tdi-coverage/tdi-15s/:id',
  TDI_15_Form = '/forms/tdi-15s/:id',
  TDI_21 = '/tdi-coverage/tdi-21s/:id',
  TDI_21_Form = '/forms/tdi-21s/:id',
  HC_15 = '/phc-coverage/hc-15s/:id',
  HC_15_Form = '/forms/hc-15s/:id',
  HC_61 = '/phc-coverage/hc-61s/:id',
  HC_61_Form = '/forms/hc-61s/:id',
  HC_4 = '/phc-coverage/hc-4s/:id',
  HC_4_Form = '/forms/hc-4s/:id',
  WC_1 = '/dcd-ecms/wc-1/:id',
  WC_1_Form = '/forms/wc-1/:id',
  WC_5 = '/dcd-ecms/wc-5/:id',
  WC_5_Form = '/forms/wc-5/:id',
}
