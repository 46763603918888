export enum WC21Key {
  EmployerName = 'employerName',
  FormNumber = 'formNumber',
  CreatedAt = 'createdAt',
  EmployerID = 'employerId',
  EmployerAccountName = 'employer.accountName',
  RawDateReceived = 'rawDateReceived',
  RawSignature = 'rawSignature',
  RawSignatureEmail = 'rawSignatureEmail',
  RawSignatureDate = 'rawSignatureDate',
  RawSignaturePhone = 'rawSignaturePhone',
  RawOnBehalfOf = 'rawOnBehalfOf',
  RawApplicantName = 'rawApplicantName',
  RawApplicantType = 'rawApplicantType',
  RawApplicantDOLNumber = 'rawApplicantDOLNumber',
  RawApplicantAddress = 'rawApplicantAddress',
  RawApplicantCity = 'rawApplicantCity',
  RawApplicantState = 'rawApplicantState',
  RawApplicantZipCode = 'rawApplicantZipcode',
  RawNatureOfBusiness = 'rawNatureOfBusiness',
  RawNumOfHawaiiEmployees = 'rawNumOfHawaiiEmployees',
  RawAvgMonthlyPay = 'rawAvgMonthlyPay',
  RawHawaiiAddress = 'rawHawaiiAddress',
  RawHawaiiCity = 'rawHawaiiCity',
  RawHawaiiZipCode = 'rawHawaiiZipcode',
  RawPocMainTitle = 'rawPocMainTitle',
  RawPocMainFirstName = 'rawPocMainFirstName',
  RawPocMainLastName = 'rawPocMainLastName',
  RawPocMainEmail = 'rawPocMainEmail',
  RawPocMainPhone = 'rawPocMainPhone',
  RawPocMainFax = 'rawPocMainFax',
  RawPocMainAddress = 'rawPocMainAddress',
  RawPocMainCity = 'rawPocMainCity',
  RawPocMainState = 'rawPocMainState',
  RawPocMainZipCode = 'rawPocMainZipcode',
  RawPocAnnualReportTitle = 'rawPocAnnualReportTitle',
  RawPocAnnualReportFirstName = 'rawPocAnnualReportFirstName',
  RawPocAnnualReportLastName = 'rawPocAnnualReportLastName',
  RawPocAnnualReportEmail = 'rawPocAnnualReportEmail',
  RawPocAnnualReportPhone = 'rawPocAnnualReportPhone',
  RawPocAnnualReportAddress = 'rawPocAnnualReportAddress',
  RawPocAnnualReportCity = 'rawPocAnnualReportCity',
  RawPocAnnualReportState = 'rawPocAnnualReportState',
  RawPocAnnualReportZipCode = 'rawPocAnnualReportZipcode',
  RawPocBondTitle = 'rawPocBondTitle',
  RawPocBondFirstName = 'rawPocBondFirstName',
  RawPocBondLastName = 'rawPocBondLastName',
  RawPocBondEmail = 'rawPocBondEmail',
  RawPocBondPhone = 'rawPocBondPhone',
  RawPocBondAddress = 'rawPocBondAddress',
  RawPocBondCity = 'rawPocBondCity',
  RawPocBondState = 'rawPocBondState',
  RawPocBondZipCode = 'rawPocBondZipcode',
  RawBusinessCommencementDate = 'rawBusinessCommencementDate',
  RawSecurityDepositForm = 'rawSecurityDepositForm',
  RawSupplementedByInsurancePolicy = 'rawSupplementedByInsurancePolicy',
  RawAnyLitigationOrProceedingPending = 'rawAnyLitigationOrProceedingPending',
  RawExcessPolicyExplanation = 'rawExcessPolicyExplanation',
  RawRejectedOrCancelled = 'rawRejectedOrCancelled',
  RawRejectedOrCancelledDate = 'rawRejectedOrCancelledDate',
  RawCarrierName = 'rawCarrierName',
  RawPolicyCancelledWhy = 'rawPolicyCancelledWhy',
  RawClaimsAdminPerformedBy = 'rawClaimsAdminPerformedBy',
  RawSelfInsurerTitle = 'rawSelfInsurerTitle',
  RawSelfInsurerFirstName = 'rawSelfInsurerFirstName',
  RawSelfInsurerLastName = 'rawSelfInsurerLastName',
  RawSelfInsurerPhone = 'rawSelfInsurerPhone',
  RawSelfInsurerAddress = 'rawSelfInsurerAddress',
  RawSelfInsurerCity = 'rawSelfInsurerCity',
  RawSelfInsurerState = 'rawSelfInsurerState',
  RawSelfInsurerZipCode = 'rawSelfInsurerZipcode',
  RawThirdPartyCompanyName = 'rawThirdPartyCompanyName',
  RawThirdPartyPhone = 'rawThirdPartyPhone',
  RawThirdPartyAddress = 'rawThirdPartyAddress',
  RawThirdPartyCity = 'rawThirdPartyCity',
  RawThirdPartyState = 'rawThirdPartyState',
  RawThirdPartyZipCode = 'rawThirdPartyZipcode',
  RequiredDocsReceived = 'requiredDocsReceived',
  ProcessingStatus = 'processingStatus',
  AcceptanceStatus = 'acceptanceStatus',
  Comments = 'comments',
  AssignedUserId = 'assignedUserId',
  AssignedUser = 'assignedUser',
  AssignedUserName = 'assignedUserName',
  RawAdditionalEntities = 'rawAdditionalEntities',
  RawHawaiiNetProfitLoss = 'rawHawaiiNetProfitLoss',
  RawHawaiiStateExposure = 'rawHawaiiStateExposure',
}
