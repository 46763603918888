import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { createResponseWrapper } from '../helpers';
import { TDICoverage } from './types';

export function useCreateTDICoverage(
  options?: UseMutationOptions<TDICoverage, Error, TDICoverage>
) {
  const { mutate: createTDICoverage, isLoading } = useMutation<TDICoverage, Error, TDICoverage>({
    mutationFn: (payload: TDICoverage) =>
      createResponseWrapper(apiClient.createTDICoverage, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    createTDICoverage,
    isLoading,
  };
}
