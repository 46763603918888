import { formatDate } from 'src/utils';
import { TDI30sReportKey } from './keys';
import { TDI30sReportResponse } from './types';

export const toTDI30sReport = (data): TDI30sReportResponse => {
  const originalReport = data?.data?.reports?.data;

  const formattedReport = originalReport.map((item) => ({
    ...item,
    [TDI30sReportKey.CreatedDate]: `${formatDate(item?.[TDI30sReportKey.StartDate])} -
      ${formatDate(item?.[TDI30sReportKey.EndDate])}`,
  }));

  return {
    ...data,
    data: {
      ...data?.data,
      reports: {
        ...data?.data?.reports,
        data: formattedReport,
      },
    },
  };
};
