import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { PaginationResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { GetPropertiesParams } from '../types';
import { toCaseProcessingLogs } from './helpers';
import { CaseProcessingLog } from './types';

export function useGetCaseProcessingLogs(
  options?: UseQueryOptions<PaginationResponseType<CaseProcessingLog>, Error> & {
    caseId?: string;
    tdiCaseId?: string;
    tdiSFCaseId?: string;
  }
) {
  const [params, setParams] = useState<GetPropertiesParams>({});

  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetCaseProcessingLogs,
  } = useQuery<PaginationResponseType<CaseProcessingLog>, Error>(
    [
      API_QUERIES.CASE_PROCESSING_LOGS,
      {
        ...params,
        caseId: options?.caseId,
        tdiCaseId: options?.tdiCaseId,
        tdiSFCaseId: options?.tdiSFCaseId,
      },
    ],
    {
      queryFn: ({ queryKey }) => {
        const params = queryKey.at(-1);
        return responseWrapper<PaginationResponseType<CaseProcessingLog>>(
          apiClient.getAllCaseProcessingLogs,
          [params]
        );
      },
      select: toCaseProcessingLogs,
      notifyOnChangeProps: ['data', 'isFetching'],
      keepPreviousData: true,
      enabled: !!options?.caseId || !!options?.tdiCaseId || !!options?.tdiSFCaseId,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateCaseProcessingLogs = () =>
    queryClient.invalidateQueries(API_QUERIES.CASE_PROCESSING_LOGS);

  const { data: caseProcessingLogs, hasNext, payloadSize, totalRecords } = data || {};

  return {
    caseProcessingLogs,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isError,
    isFetching,
    refetch: onGetCaseProcessingLogs,
    setParams,
    handleInvalidateCaseProcessingLogs,
  };
}
