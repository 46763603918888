import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { createResponseWrapper } from '../helpers';
import { ScheduleOfDisbursementPayload } from './types';

export function useCreateScheduleOfDisbursement(
  options?: UseMutationOptions<ScheduleOfDisbursementPayload, Error, ScheduleOfDisbursementPayload>
) {
  const { mutate: createScheduleOfDisbursement, isLoading } = useMutation<
    ScheduleOfDisbursementPayload,
    Error,
    ScheduleOfDisbursementPayload
  >({
    mutationFn: (payload: ScheduleOfDisbursementPayload) =>
      createResponseWrapper(apiClient.createScheduleOfDisbursement, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    createScheduleOfDisbursement,
    isLoading,
  };
}
