import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { AppName } from 'src/appConfig/constants';
import apiClient from 'src/queries/apiClient';
import { PaginationResponseType, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { GetPropertiesParams } from 'src/queries/types';
import { toRelatedHearings } from './helpers';
import { RelatedHearing } from './types';

export function useGetAllRelatedHearings(
  options?: UseQueryOptions<PaginationResponseType<RelatedHearing>, Error> & {
    application?: AppName;
  }
) {
  const [params, setParams] = useState<GetPropertiesParams>({});

  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetAllRelatedHearings,
  } = useQuery<PaginationResponseType<RelatedHearing>, Error>(
    [
      API_QUERIES.RELATED_HEARINGS,
      {
        ...params,
        ...options,
      },
    ],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<PaginationResponseType<RelatedHearing>>(
          apiClient.getAllRelatedHearings,
          params
        );
      },
      select: (data) => toRelatedHearings(data),
      notifyOnChangeProps: ['data', 'isFetching'],
      enabled: true,
      keepPreviousData: true,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateAllRelatedHearings = () =>
    queryClient.invalidateQueries([API_QUERIES.RELATED_HEARINGS]);

  const { data: relatedHearings, hasNext, totalRecords, payloadSize } = data || {};

  return {
    relatedHearings,
    error,
    hasNext,
    totalRecords,
    payloadSize,
    isError,
    isFetching,
    onGetAllRelatedHearings,
    setParams,
    handleInvalidateAllRelatedHearings,
  };
}
