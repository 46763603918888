import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { ApiResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { toPortalUserDetail } from './helpers';
import { PortalUser } from './types';

export function useGetPortalUser(
  options?: UseQueryOptions<ApiResponseType<PortalUser>, Error, PortalUser> & {
    id: PortalUser['id'];
  }
) {
  const {
    data: portalUser,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetPortalUserById,
  } = useQuery<ApiResponseType<PortalUser>, Error, PortalUser>(
    [API_QUERIES.PORTAL_USERS, { id: options.id }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<PortalUser>>(apiClient.getPortalUser, params);
      },
      enabled: !!options.id,
      select: (data) => toPortalUserDetail(data?.data),
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidatePortalUser = () =>
    queryClient.invalidateQueries([API_QUERIES.PORTAL_USERS, { id: options.id }]);

  return {
    portalUser,
    error,
    isError,
    isLoading,
    onGetPortalUserById,
    handleInvalidatePortalUser,
  };
}
