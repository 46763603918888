import { getFirstLastName } from 'src/utils';
import { isEmpty } from 'src/validations';
import { AccountKey } from '../Accounts';
import { formatAddress, toAddress, toAuditData } from '../helpers';
import { HC61Key, NetProfitKey } from './keys';
import { ExistingPlan, HC61 } from './types';

export const ADDRESS_KEYS = [AccountKey.City, AccountKey.State, AccountKey.ZipCode];

export const toHC61 = (data) => ({
  ...data,
  // Employer
  [HC61Key.EmployerName]: data?.employer?.accountName || '',
  [HC61Key.EmployerId]: data?.employer?.id || '',
  [HC61Key.EmployerMailingCity]: data?.employer?.city,
  [HC61Key.EmployerMailingZipCode]: data?.employer?.zip,
  [HC61Key.EmployerMailingState]: data?.employer?.state,

  [HC61Key.DOLNumber]: data?.employer?.laborNumber || '',
  [HC61Key.MailingAddressInHawaii]: formatAddress(data?.employer, ADDRESS_KEYS) || '',

  [HC61Key.Year]: data?.netProfitOrLossAfterTaxes?.year || '',
  [HC61Key.Amount]: data?.netProfitOrLossAfterTaxes?.amount || '',
  [HC61Key.ParentCompanyName]: data?.parentCompany?.accountName || '',
  [HC61Key.ParentCompanyState]: data?.parentCompany?.state || '',
  [HC61Key.ParentCompanyZip]: data?.parentCompany?.zip || '',
  [HC61Key.ParentCompanyCity]: data?.parentCompany?.city || '',
  [HC61Key.ParentCompanyAddress]: formatAddress(data?.parentCompany, ADDRESS_KEYS) || '',
  [HC61Key.DCDStatus]: data?.employer?.dcdStatus,
  [HC61Key.DCDStatusDate]: data?.employer?.dcdStatusDate,
  [HC61Key.HealthCarePlanName]: data?.hcPlan?.planName,
  [HC61Key.HealthCarePlanNumber]: data?.hcPlan?.planNumber,
  [HC61Key.HealthCarePlanType]: data?.hcPlan?.planType,
  [HC61Key.HealthCarePlanStatus]: data?.hcPlan?.status,

  //Contact
  [HC61Key.IndividualResponsibleFinancialsName]:
    data?.individualResponsibleForFinancials?.accountName || '',
  [HC61Key.IndividualResponsibleFinancialsId]: data?.individualResponsibleForFinancials?.id || '',
  [HC61Key.ContactTitleForFinancials]: data?.individualResponsibleForFinancials?.title || '',
  [HC61Key.AddressForFinancials]: toAddress(data?.individualResponsibleForFinancials) || '',
  [HC61Key.ContactPhoneNumberForFinancials]: data?.individualResponsibleForFinancials?.phone || '',
  [HC61Key.IndividualResponsibleForSIName]:
    data?.individualResponsibleForSIProgram?.accountName || '',
  [HC61Key.IndividualResponsibleForSIId]: data?.individualResponsibleForSIProgram?.id || '',
  [HC61Key.ContactTitleForSI]: data?.individualResponsibleForSIProgram?.title || '',
  [HC61Key.AddressForSI]: toAddress(data?.individualResponsibleForSIProgram) || '',
  [HC61Key.ContactPhoneNumberForSI]: data?.individualResponsibleForSIProgram?.phone,

  [HC61Key.OwnOrganizationAdministratorTitle]: data?.ownOrganizationAdministrator?.title,
  [HC61Key.OwnOrganizationAdministratorName]: data?.ownOrganizationAdministrator?.accountName,
  [HC61Key.OwnOrganizationAdministratorAddress]: toAddress(data?.ownOrganizationAdministrator),
  [HC61Key.OwnOrganizationAdministratorPhoneNumber]: data?.ownOrganizationAdministrator?.phone,
  [HC61Key.OwnOrganizationAdministratorCity]: data?.ownOrganizationAdministrator?.city,
  [HC61Key.OwnOrganizationAdministratorState]: data?.ownOrganizationAdministrator?.state,
  [HC61Key.OwnOrganizationAdministratorZip]: data?.ownOrganizationAdministrator?.zip,
  [HC61Key.OwnOrganizationAdministratorId]: data?.ownOrganizationAdministrator?.id,
  [HC61Key.PlanReviewerName]: getFirstLastName(data?.planReviewer),
  [HC61Key.HC4Name]: data?.hc4?.hc4Number,
  ...toAuditData(data),
});

export const toHC61s = (data) => {
  return {
    ...data,
    data: data.data.map((hc61) => ({
      ...hc61,
      ...toAuditData(hc61),
      [HC61Key.Employer]: hc61?.employer?.accountName,
      [HC61Key.EmployerMailingCity]: hc61?.employer?.city,
      [HC61Key.EmployerMailingZipCode]: hc61?.employer?.zip,
      [HC61Key.EmployerMailingState]: hc61?.employer?.state,
      [HC61Key.DOLNumber]: hc61?.employer?.laborNumber,
      [HC61Key.LitigProceedingPendingThreatened]:
        !!hc61?.isLitigationsProceedingPendingOrThreatened,
      [HC61Key.ParentCompanyCity]: hc61?.parentCompany?.city,
      [HC61Key.ParentCompanyZip]: hc61?.parentCompany?.zip,
      [HC61Key.ParentCompanyState]: hc61?.parentCompany?.state,
      [HC61Key.ParentCompany]: hc61?.parentCompany?.accountName,
      [HC61Key.ParentCompanyId]: hc61?.parentCompany?.id,

      [HC61Key.ContactTitleForSI]: hc61?.individualResponsibleForSIProgram?.title,
      [HC61Key.ContactPhoneNumberForSI]: hc61?.individualResponsibleForSIProgram?.phone,
      [HC61Key.CityForSI]: hc61?.individualResponsibleForSIProgram?.city,
      [HC61Key.StateForSI]: hc61?.individualResponsibleForSIProgram?.state,
      [HC61Key.ZipForSI]: hc61?.individualResponsibleForSIProgram?.zip,

      [HC61Key.ContactTitleForFinancials]: hc61?.individualResponsibleForFinancials?.title,
      [HC61Key.ContactPhoneNumberForFinancials]: hc61?.individualResponsibleForFinancials?.phone,
      [HC61Key.CityForFinancials]: hc61?.individualResponsibleForFinancials?.city,
      [HC61Key.StateForFinancials]: hc61?.individualResponsibleForFinancials?.state,
      [HC61Key.ZipForFinancials]: hc61?.individualResponsibleForFinancials?.zip,

      [HC61Key.HealthCareContractorName]: hc61?.hcContractor?.accountName,
      [HC61Key.HealthCarePlan]: hc61?.healthCarePlan,
      [HC61Key.HCPlan]: hc61?.hcPlan?.planName,

      [HC61Key.OwnOrganizationAdministratorTitle]: hc61?.ownOrganizationAdministrator?.title,
      [HC61Key.OwnOrganizationAdministratorPhoneNumber]: hc61?.ownOrganizationAdministrator?.phone,
      [HC61Key.OwnOrganizationAdministratorCity]: hc61?.ownOrganizationAdministrator?.city,
      [HC61Key.OwnOrganizationAdministratorState]: hc61?.ownOrganizationAdministrator?.state,
      [HC61Key.OwnOrganizationAdministratorZip]: hc61?.ownOrganizationAdministrator?.zip,
      [HC61Key.OwnOrganizationAdministratorId]: hc61?.ownOrganizationAdministrator?.id,
    })),
  };
};

export const HAWAII_ADDRESS_KEYS = [
  HC61Key.EmployerHawaiiCity,
  HC61Key.EmployerHawaiiState,
  HC61Key.EmployerHawaiiZipCode,
];

export const OTHER_BUSINESS_ADDRESS_KEYS = [
  HC61Key.OtherBusinessCity,
  HC61Key.OtherBusinessState,
  HC61Key.OtherBusinessZip,
];

export const OUTSIDE_ORGANIZATION_ADDRESS_KEYS = [
  HC61Key.OutsideOrganizationCity,
  HC61Key.OutsideOrganizationState,
  HC61Key.OutsideOrganizationZip,
];

export const toValues = (values: HC61) => ({
  ...values,
  [HC61Key.StreetAddressInHawaii]: formatAddress(values, HAWAII_ADDRESS_KEYS),
  [HC61Key.OutsideOrganizationAddress]: formatAddress(values, OUTSIDE_ORGANIZATION_ADDRESS_KEYS),
  [HC61Key.ParentCompanyStockOwnershipPercent]: isEmpty(
    values?.[HC61Key.ParentCompanyStockOwnershipPercent]
  )
    ? null
    : +values?.[HC61Key.ParentCompanyStockOwnershipPercent],
  [HC61Key.PercentCompanyOwnership]: isEmpty(values?.[HC61Key.PercentCompanyOwnership])
    ? null
    : +values?.[HC61Key.PercentCompanyOwnership],
  [HC61Key.PlanEffectiveDate]: isEmpty(values?.[HC61Key.PlanEffectiveDate])
    ? null
    : values?.[HC61Key.PlanEffectiveDate],
  [HC61Key.HealthCarePlanId]:
    values?.[HC61Key.NewOrExistingPlan] === ExistingPlan.ExistingPlan
      ? values?.[HC61Key.HealthCarePlanId]
      : null,
  [HC61Key.HealthCarePlan]:
    values?.[HC61Key.NewOrExistingPlan] === ExistingPlan.NewPlan
      ? values?.[HC61Key.HealthCarePlan]
      : null,
  [HC61Key.OtherBusinessCity]: values?.[HC61Key.ConductingBusinessUnderAnotherName]
    ? values?.[HC61Key.OtherBusinessCity]
    : null,
  [HC61Key.OtherBusinessAddress]: values?.[HC61Key.ConductingBusinessUnderAnotherName]
    ? formatAddress(values, OTHER_BUSINESS_ADDRESS_KEYS)
    : null,
  [HC61Key.OtherBusinessState]: values?.[HC61Key.ConductingBusinessUnderAnotherName]
    ? values?.[HC61Key.OtherBusinessState]
    : null,
  [HC61Key.OtherBusinessZip]: values?.[HC61Key.ConductingBusinessUnderAnotherName]
    ? values?.[HC61Key.OtherBusinessZip]
    : null,
  [HC61Key.OtherBusinessName]: values?.[HC61Key.ConductingBusinessUnderAnotherName]
    ? values?.[HC61Key.OtherBusinessName]
    : null,
  [HC61Key.OtherBusinessNature]: values?.[HC61Key.ConductingBusinessUnderAnotherName]
    ? values?.[HC61Key.OtherBusinessNature]
    : null,
  [HC61Key.DateOfPolicy]: values?.[HC61Key.RejectedOrPolicyCanceled]
    ? values?.[HC61Key.DateOfPolicy]
    : null,
  [HC61Key.ReasonForRejectionOrCancellation]: values?.[HC61Key.RejectedOrPolicyCanceled]
    ? values?.[HC61Key.ReasonForRejectionOrCancellation]
    : null,
  [HC61Key.HealthCareContractorName]: values?.[HC61Key.RejectedOrPolicyCanceled]
    ? values?.[HC61Key.HealthCareContractorName]
    : null,
  [HC61Key.ExplainLimitations]: !values?.[HC61Key.AdminHasAuthorityToProvideBenefits]
    ? values?.[HC61Key.ExplainLimitations]
    : null,
  [HC61Key.ExplainLitigationProceeding]: values?.[HC61Key.LitigProceedingPendingThreatened]
    ? values?.[HC61Key.ExplainLitigationProceeding]
    : null,
  [HC61Key.NetProfitOrLossAfterTaxes]: values?.[HC61Key.NetProfitOrLossAfterTaxes].map(
    (netProfit) => ({
      [NetProfitKey.Year]: netProfit[NetProfitKey.Year],
      [NetProfitKey.Amount]: isEmpty(netProfit[NetProfitKey.Amount])
        ? null
        : +netProfit[NetProfitKey.Amount],
    })
  ),
});
