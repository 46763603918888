export enum WC5AFormKey {
  Id = 'id',
  DCDCaseId = 'caseId',
  WC5ANumber = 'formNumber',
  DateReceived = 'rawDateReceived',
  PrintedNameDependent = 'rawDependentPrinterName',
  RepresentedBy = 'rawDependentPrinterRepresentedName',
  DateInjuryCausingDeath = 'rawClaimantDateOfInjury',
  CreatedDate = 'createdAt',
}

export interface WC5AForm {
  [WC5AFormKey.Id]: string;
  [WC5AFormKey.DCDCaseId]: string;
  [WC5AFormKey.WC5ANumber]: string;
  [WC5AFormKey.PrintedNameDependent]: string;
  [WC5AFormKey.RepresentedBy]: string;
  [WC5AFormKey.DateInjuryCausingDeath]: string;
  [WC5AFormKey.DateReceived]: string;
  [WC5AFormKey.CreatedDate]: string;
}

export interface WC5AFormPayload {
  [WC5AFormKey.DCDCaseId]: string;
}
