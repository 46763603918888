import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { ApiResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { toVrProcess } from './helpers';
import { VocationalRehabilitationKey } from './keys';
import { VocationalRehabilitation } from './types';

export function useGetVrProcess(
  options?: UseQueryOptions<
    ApiResponseType<{ vrProcess: VocationalRehabilitation }>,
    Error,
    VocationalRehabilitation
  > & {
    id: VocationalRehabilitation[VocationalRehabilitationKey.Id];
  }
) {
  const {
    data: vrProcess,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetVrProcessById,
  } = useQuery<
    ApiResponseType<{ vrProcess: VocationalRehabilitation }>,
    Error,
    VocationalRehabilitation
  >([API_QUERIES.VR_PROCESS, { id: options.id }], {
    queryFn: (query) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...params] = query.queryKey;
      return responseWrapper<ApiResponseType<{ vrProcess: VocationalRehabilitation }>>(
        apiClient.getVocationalRehabilitation,
        params
      );
    },
    select: ({ data }) => toVrProcess(data.vrProcess),
    enabled: !!options.id,
    ...options,
  });

  const queryClient = useQueryClient();

  const handleInvalidateVrProcess = () =>
    queryClient.invalidateQueries([API_QUERIES.VR_PROCESS, { id: options.id }]);

  return {
    vrProcess,
    error,
    isError,
    isLoading,
    onGetVrProcessById,
    handleInvalidateVrProcess,
  };
}
