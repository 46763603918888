import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { PHCCoverage } from './types';

export function useUpdatePHCCoverage(
  options?: UseMutationOptions<PHCCoverage, Error, PHCCoverage>
) {
  const {
    mutate: onEditPHCCoverage,
    isLoading: isUpdating,
    isSuccess,
    isError,
    error,
  } = useMutation<PHCCoverage, Error, PHCCoverage>({
    mutationFn: (payload: PHCCoverage) => responseWrapper(apiClient.updatePHCCoverage, [payload]),
    onError: (error) => ErrorService.handler(error),
    ...options,
  });

  return {
    onEditPHCCoverage,
    isUpdating,
    isSuccess,
    isError,
    error,
  };
}
