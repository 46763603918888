import { formatDateTime } from 'src/utils';
import { PaginationResponseType } from '../helpers';
import { RelatedVendorsKey } from './keys';
import { RelatedVendorsType } from './types';

export const toRelatedVendors = (data): PaginationResponseType<RelatedVendorsType> => {
  return {
    ...data,
    data: data?.data.map((vendor) => ({
      ...vendor,
      [RelatedVendorsKey.CreatedBy]: `${vendor?.createdUser?.firstName} ${
        vendor?.createdUser?.lastName
      }, ${formatDateTime(vendor?.createdAt)}`,
      [RelatedVendorsKey.LastModifiedBy]:
        vendor?.updatedUser &&
        `${vendor?.updatedUser?.firstName} ${vendor?.updatedUser?.lastName}, ${formatDateTime(
          vendor?.updatedAt
        )}`,
      [RelatedVendorsKey.VendorName]: vendor?.account?.accountName,
      [RelatedVendorsKey.VendorNumber]: vendor?.account?.vendorNumber,
      [RelatedVendorsKey.DelinquentEmployer]: vendor?.account?.isDelinquentEmployer,
      [RelatedVendorsKey.ConcurrentEmployer]: vendor?.account?.isConcurrentEmployer,
      [RelatedVendorsKey.RedTag]: vendor?.account?.isRedTag,
      [RelatedVendorsKey.AccountId]: vendor?.account?.id,
    })),
  };
};
