export enum ExpenditureVoucherKey {
  DcdCase = 'dcdCase',
  FormData = 'formData',
  EmploymentAtTimeOfII = 'employmentAtTimeOfII',
  Parties = 'parties',
  CardNum = 'cardNum',
  VoucherNumber = 'voucherNumber',
  CaseId = 'caseId',
  VendorId = 'vendorId',
}

export enum ExpenditureVoucherFormKey {
  AccountId = 'accountId',
  VendorName = 'vendorName',
  VendorNumber = 'vendorNumber',
  VendorStreet = 'vendorStreet',
  VendorCity = 'vendorCity',
  VendorState = 'vendorState',
  VendorZip = 'vendorZip',
  SelectedRow = 'selectedRow',
}

export enum ExpenditureVoucherInformationKey {
  CardNum = 'cardNum',
  VoucherNumber = 'voucherNumber',
}
