import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { ApiResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { toWC1Detail } from './helpers';
import { WC1 } from './types';

export function useGetWC1(
  options?: UseQueryOptions<ApiResponseType<{ form: WC1 }>, Error, WC1> & {
    id: WC1['id'];
  }
) {
  const {
    data: wc1,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetWC1ById,
  } = useQuery<ApiResponseType<{ form: WC1 }>, Error, WC1>([API_QUERIES.WC1s, { id: options.id }], {
    queryFn: (query) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...params] = query.queryKey;
      return responseWrapper<ApiResponseType<{ form: WC1 }>>(apiClient.getWC1Detail, params);
    },
    select: (data) => toWC1Detail(data.data.form),
    enabled: !!options.id,
    ...options,
  });

  const queryClient = useQueryClient();

  const handleInvalidateWC1 = () =>
    queryClient.invalidateQueries([API_QUERIES.WC1s, { id: options.id }]);

  return {
    wc1,
    error,
    isError,
    isLoading,
    onGetWC1ById,
    handleInvalidateWC1,
  };
}
