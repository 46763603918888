export enum ReportId {
  TDI21sAnnualReportsNotFiled = 'TDI21sAnnualReportsNotFiled',
  ApprovedHealthCarePlans = 'ApprovedHealthCarePlans',
  RejectedPHCSelfInsurance = 'RejectedPHCSelfInsurance',
  TDI21sAnnualException = 'TDI21sAnnualException',
  HC15s = 'HC15s',
  TDI15sTDISelfInsurerPlanReport = 'TDI15sTDISelfInsurerPlanReport',
  NonCompliantEmployerInsurance = 'NonCompliantEmployerInsurance',
  TDIAndPHCPhysicalFilePurge = 'TDIAndPHCPhysicalFilePurge',
  TDI21sAnnualReportSummaryByPlanType = 'TDI21sAnnualReportSummaryByPlanType',
  TDIAppealHearingDecisions = 'TDIAppealHearingDecisions',
  NonCompliantWCEmployerInsurance = 'NonCompliantWCEmployerInsurance',
  EmployerRegistrationActivities = 'EmployerRegistrationActivities',
  TDI46ReconsiderationsAppeals = 'TDI46ReconsiderationsAppeals',
  TDI62s = 'TDI62s',
  TDI30s = 'TDI30s',
  TDI21NumberOfEmployees = 'TDI21NumberOfEmployees',
}
