export enum DisbursementKey {
  Id = 'id',
  Case = 'case',
  CaseId = 'caseId',
  TDISFCaseId = 'tdiSFCaseId',
  Status = 'status',
  ApprovalStatus = 'approvalStatus',
  DisbursementType = 'disburseType',
  ExpenseType = 'expenseType',
  DisbursementAmount = 'disburseAmount',
  PeriodStartDate = 'periodStartDate',
  PeriodEndDate = 'periodEndDate',
  DagsDate = 'dagsDate',
  VoucherDate = 'voucherDate',
  DisbursementNumber = 'disburseNumber',
  Vendor = 'vendor',
  VendorName = 'vendorName',
  VendorNumber = 'vendorNumber',
  VendorId = 'vendorId',
  CreatedBy = 'createdBy',
  UpdatedBy = 'updatedBy',
  CaseNumber = 'caseNumber',

  TimeOfNotification = 'timeOfNotification',
  PaymentReminderAt = 'paymentReminderAt',
  RecipientUsers = 'recipientUserIds',
  RecipientProfiles = 'recipientProfileIds',
  Reminder = 'reminder',

  ClaimantId = 'claimantId',
  Claimant = 'claimant',
  ClaimantName = 'claimantName',
  DateOfInjury = 'dateOfInjury',
  LaborNumber = 'laborNumber',
  ClaimantSSN = 'claimantSSN',
  ClaimantAddress = 'claimantAddress',

  ClaimWeeklyBenefit = 'claimWeeklyBenefit',
  WeeksInPeriod = 'weekInPeriod',
  IsDelinquentEmployer = 'isDelinquentEmployer',
  ApprovedPaymentBy = 'approvedPaymentBy',
  DisbursementComment = 'comment',
  ServiceDate = 'serviceDate',

  PostedDate = 'postDate',
  CheckNumber = 'checkNumber',
  CheckDate = 'checkDate',
  CheckMailTo = 'checkMailedTo',
  CheckMailDate = 'checkMailedDate',
  VoucherType = 'voucherType',

  Object = 'voucherObject',
  ControlNumber = 'scfControlNum',
  VoucherDescription = 'voucherDescription',
  ExpenseGeneralLedgerAccountNumber = 'expenseGLAccount',
  ExpenseGeneralLedgerAccountId = 'expenseGLAccountId',
  ExpenseGeneralLedgerDescription = 'expenseGLAccountDescription',
  DateFrom = 'additionFromDate',
  DateTo = 'additionToDate',
  TC = 'additionDagsAccountType',
  Comment = 'additionComment',
  PayMemoTo = 'payMemoTo',
  Title = 'title',
  PayMemoAttentionTo = 'payMemoAttentionTo',

  PayrollType = 'payrollType',
  LogNumber = 'logNumber',
  SSN = 'ssn',

  CaseEmployer = 'caseEmployer',
  CaseEmployerId = 'caseEmployerId',
  CaseEmployerName = 'caseEmployerName',
  CaseEmployerLaborNumber = 'dolCaseEmployer',
  InsuranceCarrierName = 'insuranceCarrierName',
  InsuranceCarrierId = 'insuranceCarrierId',
  BenefitAdjustmentDateFrom = 'benefitAdjustFromDate',
  BenefitAdjustmentDateTo = 'benefitAdjustToDate',
  NewWeeklyRate = 'newWeeklyRate',
  Total = 'total',
  IsComputation = 'isComputation',
  IsOkayToPay = 'isOkToPay',
  IsSCFLogPosted = 'isScfLogPosted',
  WC3PostedDate = 'wc3PostedDate',

  UpdatedAt = 'updatedAt',
  CreatedAt = 'createdAt',
  FundType = 'fundType',

  RecordNum = 'recordNum',
  WeekInPeriod = 'weekInPeriod',
  PostDate = 'postDate',
  CheckMailedTo = 'checkMailedTo',
  CheckMailedDate = 'checkMailedDate',
  VoucherObject = 'voucherObject',
  AdditionFromDate = 'additionFromDate',
  AdditionToDate = 'additionToDate',
  AdditionDagsAccountType = 'additionDagsAccountType',
  AdditionComment = 'additionComment',
  NumberOfDay = 'numberOfDay',

  HRSSection = 'hrsSection',
  DisbursementReason = 'disbursementReason',
  Remarks = 'remarks',
  FiscalYearEnd = 'fiscalYearEnd',
  EmployeeMailingAddress = 'employeeMailingAddress',
  EmployeeCity = 'employeeCity',
  EmployeeState = 'employeeState',
  EmployeeZipCode = 'employeeZip',
  EmployerDOL = 'dolEmployer',
  EmployeeName = 'employeeName',
  EmployeeSSN = 'employeeSSN',
  LastStatusDate = 'lastStatusDate',
  ClosedDate = 'closedDate',
  EmployerName = 'employerName',
  EmployerId = 'employerId',
  EmployerFEIN = 'fein',
  EmployerMailingAddress = 'employerMailingAddress',
  EmployerMailingState = 'employerMailingState',
  EmployerMailingCity = 'employerMailingCity',
  EmployerMailingZipCode = 'employerMailingZip',
  EmployerMailingStreet = 'employerMailingStreet',
  Employer = 'employer',
  CreatedUser = 'createdUser',
  UpdatedUser = 'updatedUser',

  WeeklyBenefitAmount = 'weeklyBenefitAmount',
  RequestedPaymentAmount = 'requestedPaymentAmount',
  AmountBenefitsPaid = 'amountOfBenefitsPaid',
  TDISFCaseNumber = 'tdiSFCaseNumber',
  Employees = 'employees',
  PaymentDate = 'paymentDate',
  TDICaseId = 'tdiCaseId',
  TDICase = 'tdiCase',
  TDISFCase = 'tdiSFCase',
  AmountOfBenefitsPaid = 'amountOfBenefitsPaid',
  DateOfPayment = 'paymentDate',
  TDISFClaimantName = 'tdiSFClaimantName',
  TDISFClaimantId = 'tdiSFClaimantId',
  TDISFEmployerName = 'tdiSFEmployerName',
  TDISFEmployerId = 'tdiSFEmployerId',
}

export enum DisbursementFilterKey {
  VendorNumber = 'vendor.vendorNumber',
  CaseEmployerName = 'case.employer.id',
  ClaimantName = 'case.claimant.id',
  DisbursementNumber = 'id',
  InsuranceCarrierId = 'insuranceCarrierId',
  ControlNumber = 'case.scfControlNum',
  ExpenseGLAccount = 'expenseGLAccount.glNumber',
  ExpenseGLAccountDescription = 'expenseGLAccount.description',
  Employer = 'employer.id',
  EmployerDOL = 'employer.laborNumber',
  CaseEmployerDOL = 'case.employer.laborNumber',
  EmployerMailingState = 'employer.state',
  EmployerMailingCity = 'employer.city',
  EmployerMailingZipCode = 'employer.zip',
  EmployerMailingStreet = 'employer.street',
  EmployerFEIN = 'employer.fein',
  TDISFClaimantName = 'claimant.id',
  TDISFEmployerName = 'employer.id',
}

export enum NotifyFiscalKey {
  Id = 'id',
  FiscalRecipientUsers = 'recipientUserIds',
  Message = 'message',
  IsAllFiscalUser = 'isAllFiscalUser',
}
