import cn from 'classnames';
import { BsFillPinFill } from 'react-icons/bs';
import { View } from 'src/modules/shared-main/components';
import { useTableProvider } from 'src/modules/shared-main/components/Table/TableProvider';
import { useGetViewDetail, useGetViews, usePinView } from 'src/modules/shared-main/queries';
import { Toastify } from 'src/services';
import './styles.scss';

const PinView = () => {
  const { view, service } = useTableProvider();
  const { id, name, isPinned, object } = view || {};

  const { pinView } = usePinView({
    onSuccess: () => {
      handleInvalidateAllViewDetail();
      handleInvalidateViews();
      Toastify.success(`${name} was ${isPinned ? 'unpinned' : 'pinned'}.`);
    },
  });

  const { handleInvalidateAllViewDetail } = useGetViewDetail({ id, object, service });
  const { handleInvalidateViews } = useGetViews({ object, enabled: false });

  const handlePinView = () => pinView({ id, object, service });

  return (
    <View
      justify="center"
      align="center"
      className="cmp-pin-view__container"
      onClick={handlePinView}
    >
      <BsFillPinFill
        size={12}
        className={cn('cmp-pin-view__icon', { 'cmp-pin-view__icon--is-pinned': isPinned })}
      />
    </View>
  );
};

export default PinView;
