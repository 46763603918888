import { PaginationResponseType, toRelatedAuditData } from '../helpers';
import { RelatedTypePurposesKey, RelatedTypePurposesType } from './types';

export const toRelatedTypePurposes = (data): PaginationResponseType<RelatedTypePurposesType> => {
  return {
    ...data,
    data: data?.data.map((item) => ({
      ...item,
      ...toRelatedAuditData(item),
      [RelatedTypePurposesKey.HearingType]: item?.typeAndPurpose?.hearingType?.hearingTypeName,
      [RelatedTypePurposesKey.HearingPurpose]: item?.typeAndPurpose?.hearingPurpose?.purpose,
      [RelatedTypePurposesKey.HearingTypeId]: item?.typeAndPurpose?.hearingTypeId,
    })),
  };
};
