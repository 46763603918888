import { useMutation, UseMutationOptions } from 'react-query';
import { Toastify } from 'src/services';
import apiClient from '../apiClient';
import { authResponseWrapper } from '../helpers';
import { RelatedInjuryIllnessType } from '../RelatedInjuryIllness/types';

export function useDeleteRelatedInjuryIllness(
  options?: UseMutationOptions<any, Error, RelatedInjuryIllnessType['id']>
) {
  const { mutate: deleteRelatedInjuryIllness, isLoading } = useMutation<
    any,
    Error,
    RelatedInjuryIllnessType['id']
  >({
    mutationFn: (payload: RelatedInjuryIllnessType['id']) =>
      authResponseWrapper(apiClient.deleteRelatedInjuryIllness, [payload]),
    ...options,
    onError: (err) => Toastify.error(err.message),
  });

  return {
    deleteRelatedInjuryIllness,
    isLoading,
  };
}
