import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { formatDateTime } from 'src/utils';
import apiClient from '../apiClient';
import { ApiResponseType, getResponseData, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { GetPropertiesParams } from '../types';
import { toUserData } from '../Users/helpers';
import { AddUsersToGroupResponse } from './types';

export function useGetGroupUsers(
  options?: UseQueryOptions<
    ApiResponseType<AddUsersToGroupResponse>,
    Error,
    AddUsersToGroupResponse
  > & {
    id: string;
  }
) {
  const [params, setParams] = useState<GetPropertiesParams>({});
  const {
    data,
    error,
    isError,
    isFetching,
    isLoading,
    refetch: onGetGroupUsers,
  } = useQuery<ApiResponseType<AddUsersToGroupResponse>, Error, AddUsersToGroupResponse>(
    [API_QUERIES.GROUPS, { ...params, id: options.id }],
    {
      queryFn: ({ queryKey }) => {
        const params = queryKey.at(-1);
        return responseWrapper<ApiResponseType<AddUsersToGroupResponse>>(
          apiClient.getUsersFromGroup,
          [params]
        );
      },
      select: (data) => getResponseData(data),
      enabled: !!options.id,
      keepPreviousData: true,
      notifyOnChangeProps: ['data', 'isFetching'],
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateGroupUsers = () =>
    queryClient.invalidateQueries([API_QUERIES.GROUPS, { id: options.id }]);

  const { group: groupData, groupUsers } = data || {};

  if (groupData)
    groupData.modifyBy = `${
      groupData?.updatedByUser
        ? `${groupData?.updatedByUser?.firstName} ${groupData?.updatedByUser?.lastName}`
        : ''
    } ${formatDateTime(groupData?.updatedAt)}`;

  const { totalRecords, data: groupUsersData } = (groupUsers && toUserData(groupUsers)) || {};

  return {
    totalRecords,
    groupData,
    groupUsersData,
    error,
    isError,
    isLoading,
    onGetGroupUsers,
    setParams,
    isFetching,
    handleInvalidateGroupUsers,
  };
}
