export enum ReceiptKey {
  Id = 'id',
  CaseId = 'caseId',
  CaseNumber = 'caseNumber',
  TDICaseNumber = 'tdiCaseNumber',
  TDICaseId = 'tdiCaseId',
  TDISFCaseNumber = 'tdiSFCaseNumber',
  TDISFCaseId = 'tdiSFCaseId',
  FundType = 'fundType',
  CaseEmployer = 'caseEmployer',
  Employer = 'employer',
  ReceiptStatus = 'status',
  ReceiptStatusDate = 'statusDate',
  HRSSection = 'hrsSection',
  PurposeOfReceipt = 'purpose',
  ModeOfPayment = 'paymentMode',
  CreatedBy = 'createdBy',
  LastModifiedDate = 'lastModifiedDate',
  LastModifiedBy = 'lastModifiedBy',
  PaymentFor = 'paymentFor',
  AmountCollected = 'collectedAmount',
  CollectedFrom = 'collectedFrom',
  Payer = 'payer',
  ControlNumber = 'scfControlNum',
  AmountPaid = 'paidAmount',
  BalanceAmount = 'balanceAmount',
  ExpenseGeneralLedgerAccountNumber = 'glAccount',
  ExpenseGeneralLedgerAccountId = 'glAccountId',
  AmountReceived = 'receivedAmount',
  LaborNumber = 'laborNumber',
  Employees = 'employee',
  Remarks = 'remarks',
  Violator = 'violator',
  ReceiptNumber = 'receiptNumber',
  ClaimantName = 'claimantName',
  PayerType = 'payerType',
  Branch = 'branch',
  Other = 'other',
  EmployerId = 'employerId',
  CaseEmployerId = 'caseEmployerId',
  ClaimantId = 'claimantId',
  EmployerName = 'employerName',
  EmployerLaborNumber = 'employerLaborNumber',
  ExpenseGeneralLedgerDescription = 'glAccountDescription',
  OtherRemark = 'otherRemark',
  UpdatedAt = 'updatedAt',
  CreatedAt = 'createdAt',
  UpdatedBy = 'updatedBy',
  RecordType = 'recordType',
  FilterLaborNumber = 'case.employer.laborNumber',
  FilterEmployerLaborNumber = 'employer.laborNumber',
  FilterCaseEmployer = 'case.employer.id',
  FilterExpenseGeneralLedgerDescription = 'glAccount.description',
  FilterEmployer = 'employer.id',
  Claimant = 'claimant',
  UpdatedByDate = 'updatedByDate',
  CreatedByDate = 'createdByDate',
  Number = 'number',
}

export enum NotifyBouncedCheckKey {
  RecipientUsers = 'recipientUsers',
  RecipientProfiles = 'recipientProfiles',
  IsAllUser = 'isAllUser',
  IsAllProfile = 'isAllProfile',
}
