import { useMutation, UseMutationOptions } from 'react-query';
import { Toastify } from 'src/services';
import apiClient from '../apiClient';
import { authResponseWrapper } from '../helpers';

export function useDeleteEmployerComment(options?: UseMutationOptions<string, Error, string>) {
  const { mutate: deleteComment, isLoading } = useMutation<string, Error, string>({
    mutationFn: (payload: string) =>
      authResponseWrapper(apiClient.deleteEmployerComment, [payload]),
    onError: ({ message }) => Toastify.error(message),
    ...options,
  });

  return {
    deleteComment,
    isLoading,
  };
}
