import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { PaginationResponseType, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { GetPropertiesParams } from 'src/queries/types';
import { isEmpty } from 'src/validations';
import { WC5Form } from './types';

export function useGetAllWC5Forms(
  options?: UseQueryOptions<PaginationResponseType<WC5Form>, Error>
) {
  const [params, setParams] = useState<GetPropertiesParams>({});

  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetAllWC5Forms,
  } = useQuery<PaginationResponseType<WC5Form>, Error>([API_QUERIES.WC5s, params], {
    queryFn: (query) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...params] = query.queryKey;
      return responseWrapper<PaginationResponseType<WC5Form>>(apiClient.getAllWC5Forms, params);
    },
    notifyOnChangeProps: ['data', 'isFetching'],
    keepPreviousData: true,
    enabled: !isEmpty(params),
    ...options,
  });

  const queryClient = useQueryClient();

  const handleInvalidateAllWC5Form = () => queryClient.invalidateQueries(API_QUERIES.WC5s);

  const { data: WC5Forms, hasNext, payloadSize, totalRecords } = data || {};

  return {
    WC5Forms,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isError,
    isFetching,
    onGetAllWC5Forms,
    setParams,
    handleInvalidateAllWC5Form,
  };
}
