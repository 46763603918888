import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { createResponseWrapper } from 'src/queries/helpers';
import { ErrorService } from 'src/services';
import { HearingScheduleInterface } from './types';

export function useCreateHearingSchedules(
  options?: UseMutationOptions<HearingScheduleInterface, Error, HearingScheduleInterface>
) {
  const { mutate: createHearingSchedule, isLoading } = useMutation<
    HearingScheduleInterface,
    Error,
    HearingScheduleInterface
  >({
    mutationFn: (payload: HearingScheduleInterface) =>
      createResponseWrapper(apiClient.createHearingSchedule, [payload]),
    onError: (error) => ErrorService.handler(error),
    ...options,
  });

  return {
    createHearingSchedule,
    isLoading,
  };
}
