export enum HearingKey {
  //Other Keys
  Id = 'id',
  FormNumber = 'formNumber',
  CaseId = 'caseId',
  EmployerId = 'employerId',
  Parties = 'parties',

  //Hearing Information
  HearingNumber = 'hearingNumber',
  HearingDate = 'hearingDate',
  DateOfHearingRequest = 'hearingRequestDate',
  DateOfII = 'dateOfInjury',
  DateReadyHearingCreated = 'hearingCreatedDate',
  ReceivedWithDrawalLetter = 'receivedWithdrawalLetterDate',
  RequiredDocuments = 'requiredDocuments',

  //Clerical Information
  ClericalService = 'clericalService',
  ClericalServiceId = 'clericalServiceId',
  CanUpdateInvolvedSCF = 'canUpdateInvolvedSCF',

  //Hearing Information
  CaseNumber = 'caseNumber',
  Case = 'case',

  //Claimant Information
  ClaimantId = 'claimantId',
  ClaimantName = 'accountName',
  ClaimantSSN = 'ssn',
  Claimant = 'accountName',

  //READY HEARING AND SCHEDULING INFORMATION
  ReadyHearingDate = 'readyHearingDate',
  HearingTypeForThisWorksheet = 'hearingTypeForWorksheet',
  HearingPriority = 'hearingPriority', // READY HEARING AND SCHEDULING INFORMATION ----- SCHEDULE INFORMATION
  CalendarDate = 'calendarDate',
  IsInvolvesSCF = 'isInvolvedSCF', // READY HEARING AND SCHEDULING INFORMATION ----- SCHEDULE INFORMATION
  ReadyHearingStatus = 'readyHearingStatus',
  RetireExpectedHearingDuration = 'scheduleRetireExpectedDuration', // READY HEARING AND SCHEDULING INFORMATION ----- SCHEDULE INFORMATION

  //SCHEDULE INFORMATION
  HearingOfficer = 'hearingOfficer',
  HearingOfficerId = 'hearingOfficerId',
  MinimumOfficerPositionNeeded = 'minimumOfficerPosition',
  CancellationDate = 'cancellationDate',
  HearingReviewerId = 'hearingReviewerId',
  HearingReviewer = 'hearingReviewer', // SCHEDULE INFORMATION ------ READY HEARING ADMINISTRATION
  OriginalLocation = 'originalLocation', // SCHEDULE INFORMATION ----- READY HEARING ADMINISTRATION
  StandbyHearing = 'isStandbyHearing',
  HearingLocation = 'hearingLocation',
  RoomId = 'roomId',
  Room = 'room',
  RoomName = 'roomName',
  Status = 'status',
  ScheduleStartTime = 'scheduleStartTime',
  ScheduleEndTime = 'scheduleEndTime',
  HearingDateTime = 'hearingDate',
  Duration = 'scheduleDuration',
  HearingEventStatus = 'eventHearingStatus',
  HearingNoticeMailDate = 'hearingNoticeMailDate',
  DateOfHearing = 'scheduleDate',
  HearingSchedulerId = 'hearingSchedulerId',
  HearingScheduler = 'hearingScheduler', // SCHEDULE INFORMATION ----- READY HEARING ADMINISTRATION
  InterpreterRequestedDate = 'interpreterRequestDate',
  InterpreterConfirmedDate = 'interpreterConfirmDate',

  //ADDITIONAL INFORMATION
  Comment = 'comment',
  PurposesTreatmentPlanDate = 'purposesTreatmentPlanDate',
  PurposesTreatmentPlanPhysician = 'purposesTreatmentPlanPhysician',
  OtherReason = 'reason',
  HearingLifecycle = 'hearingLifeCycle',
  RefereeManager = 'refereeManager',
  RefereeManagerId = 'refereeManagerId',
  CancellationPostponementRequestStatus = 'cancelPostponeRequestStatus',
  CancellationPostponementRequested = 'isCancelPostponeRequest',
  CancellationPostponementReason = 'cancelPostponeReason',

  //READY HEARING ADMINISTRATION
  WorksheetStatus = 'worksheetStatus',
  CurrentServicingLocation = 'currentServicingLocation',
  HearingFacilitator = 'hearingFacilitator',
  HearingFacilitatorId = 'hearingFacilitatorId',

  //===================== Audit Information =====================
  CreatedUser = 'createdUser',
  UpdatedUser = 'updatedUser',
  UpdatedBy = 'updatedBy',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  FirstName = 'firstName',
  LastName = 'lastName',
  CreatedBy = 'createdBy',
  LastModifiedDate = 'updatedAt',

  //Filter keys
  ClaimantNameFilterKey = 'claimant.id',
  ClaimantSSNFilterKey = 'claimant.ssn',
  OriginalLocationFilterKey = 'case.originalLocation',
  CurrentServicingLocationFilterKey = 'case.currentServicingLocation',
  RefereeManagerFilterKey = 'refereeManager.fullName',
  HearingReviewerFilterKey = 'hearingReviewer.fullName',
  HearingSchedulerFilterKeys = 'hearingScheduler.fullName',
  HearingOfficerFilterKeys = 'hearingOfficer.fullName',
  HearingFacilitatorFilterKey = 'hearingFacilitator.fullName',

  // Fields from RelatedHearingsKeys
  SSN = 'ssn',
  WC77Id = 'wc77Id',
  WC77FormNumber = 'WC77FormNumber',
  ReceivedWithdrawalLetter = 'receivedWithdrawalLetterDate',
  DateOfInjuryIllness = 'dateOfInjury',
  WorkSheetStatus = 'worksheetStatus',
  MinimumRefereePositionNeeded = 'minimumOfficerPosition',
  HearingTypeForWorkSheet = 'hearingTypeForWorksheet',
  InvolvedSCF = 'isInvolvedSCF',
  TypePurposeOnHearings = 'typePurposeOnHearings',
  LastModifiedBy = 'lastModifiedBy',
  CreatedDate = 'createdDate',

  //TDI Cases
  TDICaseNumber = 'tdiCaseNumber',
  TDICase = 'tdiCase',
  FirstDateOfDisabilityClaimed = 'firstDateOfDisabilityClaimed',
  FirstDateOfDisabilityClaimedFilterKey = 'tdiCase.firstDateOfDisabilityClaimed',
  IsInvolvesSpecialFund = 'isInvolvesSpecialFund',
  ReasonHearingIsScheduled = 'reasonHearingIsScheduled',
  MinimumUserPosition = 'minimumOfficerPosition',
  StateInPlainLanguageTheIssues = 'stateInPlainLanguageTheIssues',
  RefereeUserId = 'refereeId',
  RefereeUser = 'referee',
  TheIssuesToBeHeard = 'theIssuesToBeHeard',
  CurrentReferee = 'currentReferee',
  RefereeId = 'refereeId',

  // TDI Hearing
  TDISFCaseNumber = 'tdiSFCaseNumber',
  DisabilityDate = 'disabilityDate',
  TDICaseId = 'tdiCaseId',
  TDISFCase = 'tdiSFCase',
  TDISFCaseId = 'tdiSFCaseId',
  LocationType = 'locationType',
  HearingLocationType = 'locationType',
  VideoConferenceURL = 'videoConferenceURL',
  TelephoneHearingCode = 'telephoneHearingCode',
  ClaimantTDISSNFilterKey = 'claimant.ssn',
  ClaimantFilterKey = 'claimant.id',
}

export enum RoomKeys {
  Id = 'id',
  RoomName = 'roomName',
}

export enum HearingScheduleKey {
  Id = 'id',
  HearingId = 'hearingId',
  ScheduleDate = 'scheduleDate',
  ScheduleStartTime = 'scheduleStartTime',
  ScheduleEndTime = 'scheduleEndTime',
  RetireExpectedHearingDuration = 'retireExpectedDuration',
  DurationHours = 'durationHours',
  RefereeUser = 'referee',
  RefereeUserId = 'refereeId',
  HearingOfficerId = 'hearingOfficerId',
  HearingOfficerName = 'hearingOfficerName',
  RoomId = 'roomId',
  RoomName = 'roomName',
  Representatives = 'representatives',
  RepresentativeIds = 'representativeIds',
  AppName = 'appName',
}

export enum ScheduleAvailabilityKey {
  Officer = 'officer',
  Room = 'room',
  Representatives = 'representatives',
}

export enum ReasonHearingIsScheduled {
  TheClaimantHasFiled = 'the claimant has filed an appeal of the TDI determination.',
  TheCarrierHasFiled = `the carrier has filed an appeal to determine the claimant's liability for an overpayment.`,
  TheRefereeHasGranted = `the referee has granted a reopening of the decision.`,
  OfTheIssueSDescribedBelow = `of the issue(s) described below.`,
}
