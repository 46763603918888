import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { createResponseWrapper } from '../helpers';
import { RelatedPartyNamesAddressPayLoad } from './types';

export function useCreateWCCaseParty(
  options?: UseMutationOptions<
    RelatedPartyNamesAddressPayLoad,
    Error,
    RelatedPartyNamesAddressPayLoad
  >
) {
  const { mutate: createWCCaseParty, isLoading } = useMutation<
    RelatedPartyNamesAddressPayLoad,
    Error,
    RelatedPartyNamesAddressPayLoad
  >({
    mutationFn: (payload: RelatedPartyNamesAddressPayLoad) =>
      createResponseWrapper(apiClient.createWCCaseParty, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    createWCCaseParty,
    isLoading,
  };
}
