import React from 'react';

const Image: React.FC<Props> = ({ alt = 'unset', ...props }) => {
  return <img alt={alt} width={'120'} height={'400'} {...props} />;
};

type Props = React.DetailedHTMLProps<
  React.ImgHTMLAttributes<HTMLImageElement>,
  HTMLImageElement
> & {};

export default Image;
