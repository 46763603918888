export enum DecisionType {
  Appealed = 'Appealed',
  Amended = 'Amended',
  Reopened = 'Reopened',
  Administrative = 'Administrative',
  Hearing = 'Hearing',
}

export enum DecisionFormat {
  Amended = 'AMENDED',
  LongDecision = 'LONG_DECISION',
  ShortDecision = 'SHORT_DECISION',
  Settlement = 'SETTLEMENT',
  Supplemental = 'SUPPLEMENTAL',
  Miscellaneous = 'MISCELLANEOUS',
}

export enum DCDecisionInformation {
  Act59 = 'Act59',
  AppealsBoard = 'Appeals Board',
  AttdSvcs = 'Attd Svcs',
  AttyFee = 'Atty Fee',
  BenAdj = 'Ben Adj',
  BenAdj10Yr = 'Ben Adj 10 Yr',
  BillDis = 'Bill Dis',
  ChildSup = 'Child Sup',
  Commutat = 'Commutat',
  DelinScf = 'Delin Scf',
  DenialClaim = 'Denial Claim',
  Dsf = 'Dsf',
  Fatal = 'Fatal',
  Fraud = 'Fraud',
  Medical = 'Medical',
  NoImpairment = 'No Impairment',
  Other = 'Other',
  Penalty = 'Penalty',
  PermanentPartialDisability = 'Permanent Partial Disability',
  PermanentTotalDisability = 'Permanent Total Disability',
  ReopenDenied = 'Reopen Denied',
  Rescind = 'Rescind',
  StipCompDenie = 'Stip/Comp Denie',
  SupremeCourt = 'Supreme Court',
  TemporaryPartialDisability = 'Temporary Partial Disability',
  TreatmentPlan = 'Treatment Plan',
  TemporaryTotalDisability = 'Temporary Total Disability',
  Withdrawn = 'W/Drawn',
}

export enum AppealDecisionInformation {
  Affirm = 'Affirm',
  Dismiss = 'Dismiss',
  Modified = 'Modified',
  Order = 'Order',
  Other = 'Other',
  Remand = 'Remand',
  Reverse = 'Reverse',
  Settlement = 'Settlement',
  TempRem = 'Temp Rem',
  Vacated = 'Vacated',
  WithDrawn = 'W/Drawn',
}

export enum DecisionStatus {
  DecisionRequired = 'Decision Required',
  DraftInPreparation = 'Draft in Preparation',
  DecisionDraftCompleted = 'Decision Draft Completed',
  HearingOfficerReview = 'Hearing Officer Review',
  UnderReview = 'Under Review',
  Completed = 'Completed',
}

export enum Source {
  Hearing = 'DC Hearing',
  Case = 'DC Administrative',
}

export enum DecisionKey {
  Id = 'id',
  DecisionType = 'decisionType',
  DecisionNumber = 'decisionNumber',
  CaseNumber = 'caseNumber',
  Claimant = 'claimant',
  ClaimantName = 'accountName',
  ClaimantSSN = 'ssn',
  DateOfII = 'dateOfInjury',
  DateOfDeath = 'dateOfDeath',
  FiledBy = 'filedBy',
  Message = 'message',
  Case = 'case',
  TreatmentDate = 'treatmentDate',
  IsDecisionReadyToBeMailed = 'isReadyToBeMailed',
  DecisionReadyToBeMailedDate = 'readyToBeMailed',
  DecisionMailedDate = 'mailedDate',
  ReconsiderationRequestDeniedSentDate = 'reconsiderationRequestDeniedSentDate',
  HearingOfficer = 'hearingOfficer',
  HearingOfficerId = 'hearingOfficerId',
  HearingOfficerName = 'hearingOfficer',
  AppealedDecision = 'appealedDecision',
  AmendedDecision = 'amendedDecision',
  ReopenedDecision = 'reopenedDecision',
  AppealedDecisionNumber = 'appealedDecisionNumber',
  AmendedDecisionNumber = 'amendedDecisionNumber',
  ReopenedDecisionNumber = 'reopenedDecisionNumber',
  MotionStay = 'isMotionToStay',
  CurrentServicingLocation = 'currentServicingLocation',
  InvolveSCF = 'isInvolvedSCF',
  DecisionFormat = 'decisionFormat',
  DCDecisionInformation = 'dcDecisionInformation',
  AppealDecisionInformation = 'appealDecisionInformation',
  Source = 'source',
  Hearing = 'hearing',
  HearingId = 'hearingId',
  HearingOfficerFromHearing = 'hearingOfficerFromHearing',
  DateOfHearingRequest = 'hearingRequestDate',
  HearingNoticeMailDate = 'hearingNoticeMailDate',
  HearingDate = 'hearingDate',
  CaseId = 'caseId',
  DecisionStatus = 'decisionStatus',
  DecisionStatusDate = 'decisionStatusDate',
  DecisionNotes = 'decisionNotes',
  OperationalNotes = 'operationalNotes',
  DecisionFinalizedDate = 'decisionFinalizedDate',
  DueDateExtensionNeeded = 'isDueDateExtensionNeeded',
  DecisionDueDate = 'decisionDueDate',
  DecisionCompletedDate = 'decisionCompletedDate',
  AssignedTo = 'assignedUser',
  DecisionAdministrator = 'decisionAdministrator',
  DecisionAdministratorName = 'decisionAdministratorName',
  ClericalServices = 'clericalServices',
  ClericalServicesName = 'clericalServicesName',
  DecisionFinalizer = 'decisionFinalizer',
  DecisionFinalizerName = 'decisionFinalizerName',
  MailProcessor = 'mailProcessor',
  MailProcessorName = 'mailProcessorName',
  DecisionProcessor = 'decisionProcessor',
  DecisionProcessorName = 'decisionProcessorName',
  DecisionReviewer = 'decisionReviewer',
  DecisionReviewerName = 'decisionReviewerName',
  DistrictLevelReviewer = 'districtLevelReviewer',
  DistrictLevelReviewerName = 'districtLevelReviewerName',
  CaseAdministrator = 'caseAdmin',
  CaseAdministratorName = 'caseAdminName',
  CaseAdministratorId = 'caseAdministratorId',
  AdministratorApprovedDate = 'administratorApprovedDate',
  ReviewerApprovedDate = 'reviewerApprovedDate',
  JacketBorrowedDate = 'jacketBorrowedDate',
  JacketReturnedLectrieverDate = 'jacketReturnedToLectrieverDate',
  CreatedByUser = 'createdUser',
  CreatedAt = 'createdAt',
  CreatedBy = 'createdBy',
  UpdatedBy = 'updatedBy',
  UpdatedUser = 'updatedUser',
  UpdatedAt = 'updatedAt',
  CreatedUser = 'createdUser',
  RelatedCreatedBy = 'relatedCreatedBy',
  RelatedUpdatedBy = 'relatedUpdatedBy',

  AccountName = 'accountName',
  HearingNumber = 'hearingNumber',
  Introduction = 'introduction',

  // =====================FILTER KEY=====================
  DecisionAdministratorId = 'decisionAdministratorId',
  ClericalServicesId = 'clericalServicesId',
  DecisionFinalizerId = 'decisionFinalizerId',
  MailProcessorId = 'mailProcessorId',
  FiledById = 'filedById',
  DistrictLevelReviewerId = 'districtLevelReviewerId',
  DecisionReviewerId = 'decisionReviewerId',
  DecisionProcessorId = 'decisionProcessorId',
  AppealedDecisionId = 'appealedDecisionId',
  AssignedUserId = 'assignedUserId',
  AmendedDecisionId = 'amendedDecisionId',
  ReopenedDecisionId = 'reopenedDecisionId',
  DateOfIIFilterKey = 'case.dateOfInjury',
  CaseNumberFilterKey = 'caseId',
  ClaimantId = 'claimant.id',
  ClaimantNameFilterKey = 'case.claimant.accountName',
  ClaimantSSNFilterKey = 'claimant.ssn',
  DateOfDeathFilterKey = 'case.claimant.dateOfDeath',
  CaseAdministratorFilterKey = 'case.caseAdminId',
  AssignedToFilterKey = 'assignedUserId',

  // =====================HEARING FILTER KEY=====================
  HearingOfficerFilterKey = 'hearingOfficerId',
  HearingOfficerFromHearingFilterKey = 'hearing.hearingOfficerId',
  CurrentServicingLocationFilterKey = 'case.currentServicingLocation',
  HearingRequestDateFilterKey = 'hearing.hearingRequestDate',
  HearingDateFilterKey = 'hearing.hearingDate',
  HearingNumberFilterKey = 'hearingId',
  HearingNoticeMailFilterKey = 'hearing.hearingNoticeMailDate',

  // TDI Decision
  TDISFCaseNumber = 'tdiSFCaseNumber',
  DisabilityDate = 'disabilityDate',
  FirstDateOfDisabilityClaimed = 'firstDateOfDisabilityClaimed',
  TDIClaimantId = 'tdiClaimantId',
  TDICase = 'tdiCase',
  TDICaseId = 'tdiCaseId',
  TDICaseNumber = 'tdiCaseNumber',
  TDISFCase = 'tdiSFCase',
  TDISFCaseId = 'tdiSFCaseId',
  RefereeUserId = 'refereeId',
  RefereeUser = 'referee',
  RefereeUserName = 'refereeUserName',
  DecisionBenefits = 'decisionBenefits',
  AffirmModifyPreviousDecision = 'affirmOrModifyPreviousDecision',
  RefereeDecision = 'refereeDecision',
  CopyCertifiedBy = 'copyCertifiedUser',
  CopyCertifiedByName = 'copyCertifiedByName',
  CopyCertifiedId = 'copyCertifiedId',
  RefereeHearing = 'hearingReferee',
  RefereeHearingId = 'hearing.refereeId',
  AdditionalHOCertifiedDECApproval = 'additionalHOForCertifiedDECApprovalUser',
  AdditionalHOCertifiedDECApprovalId = 'additionalHOForCertifiedDECApprovalId',
  AdditionalHearingOfficerApprovalDate = 'additionalHearingOfficerApprovalDate',
}

export enum DecisionTextKey {
  Introduction = 'introduction',
  Issues = 'issues',
  PartiesPositions = 'partiesPositions',
  FindingsFact = 'findingsOfFact',
  PrinciplesLaw = 'principlesOfLaw',
  ConclusionsLaw = 'conclusionsOfLaw',
  DecisionAndOrder = 'decisionAndOrder',
  TreatmentDecisionText = 'treatmentDecisionText',
}
