export enum TDI62sReportKey {
  Reports = 'reports',
  StartDate = 'startDate',
  EndDate = 'endDate',
  ActionTaken = 'actionStatus',
  DateReceived = 'dateReceived',
  ActionAccepted = 'isAccepted',
  ActionRejected = 'isRejected',
  TotalAccepted = 'totalAccepted',
  TotalRejected = 'totalRejected',
}
