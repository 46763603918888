import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { ApiResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { toDCDCaseData } from './helpers';
import { DCDCaseDetail } from './types';

export function useGetDCDCase(
  options?: UseQueryOptions<ApiResponseType<{ case: DCDCaseDetail }>, Error, DCDCaseDetail> & {
    id: DCDCaseDetail['id'];
  }
) {
  const {
    data: dcdCase,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetWCById,
  } = useQuery<ApiResponseType<{ case: DCDCaseDetail }>, Error, DCDCaseDetail>(
    [API_QUERIES.DCDCases, { id: options.id }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<{ case: DCDCaseDetail }>>(
          apiClient.getDCDCase,
          params
        );
      },
      select: (data) => toDCDCaseData(data.data.case),
      enabled: !!options.id,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateDCDCase = () =>
    queryClient.invalidateQueries([API_QUERIES.DCDCases, { id: options.id }]);

  return {
    dcdCase,
    error,
    isError,
    isLoading,
    onGetWCById,
    handleInvalidateDCDCase,
  };
}
