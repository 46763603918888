import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { PaginationResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { GetPropertiesParams } from '../types';
import { RelatedCaseDependentsType } from './types';

export function useGetAllRelatedCaseDependents(
  options?: UseQueryOptions<PaginationResponseType<RelatedCaseDependentsType>, Error> & {
    id: RelatedCaseDependentsType['id'];
  }
) {
  const [params, setParams] = useState<GetPropertiesParams>({});

  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetAllCaseDependents,
  } = useQuery<PaginationResponseType<RelatedCaseDependentsType>, Error>(
    [API_QUERIES.RELATED_CASE_DEPENDENTS, { ...params, id: options.id }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return responseWrapper<PaginationResponseType<RelatedCaseDependentsType>>(() =>
          apiClient.getAllRelatedCaseDependents(options.id, params)
        );
      },
      notifyOnChangeProps: ['data', 'isFetching'],
      enabled: !!options.id,
      keepPreviousData: true,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateAllCaseDependents = () =>
    queryClient.invalidateQueries(API_QUERIES.RELATED_CASE_DEPENDENTS);

  const { data: relatedCaseDependents, hasNext, payloadSize, totalRecords } = data || {};

  return {
    relatedCaseDependents,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isError,
    isFetching,
    onGetAllCaseDependents,
    setParams,
    handleInvalidateAllCaseDependents,
  };
}
