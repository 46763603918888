import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { createResponseWrapper } from 'src/queries/helpers';
import { Toastify } from 'src/services';
import { WC5AFormPayload } from './types';

export function useCreateWC5AForm(options?: UseMutationOptions<any, Error, WC5AFormPayload>) {
  const { mutate: createWC5AForm, isLoading } = useMutation<any, Error, WC5AFormPayload>({
    mutationFn: (payload: WC5AFormPayload) =>
      createResponseWrapper(apiClient.createWC5AForm, [payload]),
    onError: ({ message }) => Toastify.error(message),
    ...options,
  });

  return {
    createWC5AForm,
    isLoading,
  };
}
