import { Skeleton } from '@mui/material';
import React, { Suspense } from 'react';
import { SearchableSelectType } from 'src/components/common/Table/helpers';

const SearchUserField = React.lazy(() => import('src/components/SearchUserField'));
const SearchTDI21Field = React.lazy(() => import('src/components/SearchTDI21Field'));
const SearchDecisionField = React.lazy(() => import('src/components/SearchDecisionField'));
const SearchTDIDecisionField = React.lazy(() => import('src/components/SearchTDIDecisionField'));
const SearchAwardWorksheetField = React.lazy(
  () => import('src/components/SearchAwardWorksheetField')
);
const SearchHearingField = React.lazy(() => import('src/components/SearchHearingField'));
const SearchTDI62Field = React.lazy(() => import('src/components/SearchTDI62Field'));
const SearchTDI46Field = React.lazy(() => import('src/components/SearchTDI46Field'));
const SearchTDI15Field = React.lazy(() => import('src/components/SearchTDI15Field'));
const SearchTDISFCaseField = React.lazy(() => import('src/components/SearchTDISFCaseField'));
const SearchTDI30Field = React.lazy(() => import('src/components/SearchTDI30Field'));
const SearchTDICaseField = React.lazy(() => import('src/components/SearchTDICaseField'));
const SearchTDIPlanField = React.lazy(() => import('src/components/SearchTDIPlanField'));
const SearchHealthCarePlanField = React.lazy(
  () => import('src/components/SearchHealthCarePlanField')
);
const SearchHC4Field = React.lazy(() => import('src/components/SearchHC4Field'));
const SearchHC15Field = React.lazy(() => import('src/components/SearchHC15Field'));
const SearchHC61Field = React.lazy(() => import('src/components/SearchHC61Field'));
const SearchRequest = React.lazy(() => import('src/components/SearchRequestField'));
const SearchProfileField = React.lazy(() => import('src/components/SearchProfileField'));
const SearchAccountField = React.lazy(() => import('src/components/SearchAccountField'));
const SearchDCDCaseField = React.lazy(() => import('src/components/SearchDCDCaseField'));
const SearchWC1Field = React.lazy(() => import('src/components/SearchWC1Field'));
const SearchClaimant = React.lazy(() => import('src/components/SearchClaimant'));
const SearchContact = React.lazy(() => import('src/components/SearchContact'));
const SearchPhysicianAccount = React.lazy(() => import('src/components/SearchPhysicianAccount'));
const SearchInsuranceCarrierField = React.lazy(
  () => import('src/components/SearchInsuranceCarrierField')
);
const SearchEmployerField = React.lazy(() => import('src/components/SearchEmployerField'));
const SearchEmployerAuditField = React.lazy(
  () => import('src/components/SearchEmployerAuditField')
);
const SearchRoom = React.lazy(() => import('src/components/SearchRoom'));
const SearchContactOfAdjustingCompany = React.lazy(
  () => import('src/components/SearchContactOfAdjustingCompany')
);
const SearchGLAccount = React.lazy(() => import('src/components/SearchGLAccount'));
const SearchAgentField = React.lazy(() => import('src/components/SearchAgentField'));
const SearchAssociationField = React.lazy(() => import('src/components/SearchAssociationField'));
const SearchVendorNameField = React.lazy(() => import('src/components/SearchVendorNameField'));

const SearchContactOfEmployer = React.lazy(() => import('src/components/SearchContactOfEmployer'));
const SearchAdjustingCompany = React.lazy(() => import('src/components/SearchAdjustingCompany'));
const SearchDisbursementField = React.lazy(() => import('src/components/SearchDisbursementField'));
const SearchComplainantAccount = React.lazy(() => import('src/components/SearchComplainantField'));
const SearchComplaint = React.lazy(() => import('src/components/SearchComplaint'));
const SearchWC77Field = React.lazy(() => import('src/components/SearchWC77Field'));

const SearchableField: React.FC<Props> = ({ selectedSearchableFieldType, ...props }) => {
  const searchableFieldMap = {
    [SearchableSelectType.Profile]: <SearchProfileField {...props} />,
    [SearchableSelectType.User]: <SearchUserField {...props} />,
    [SearchableSelectType.Account]: <SearchAccountField isInFilter {...props} />,
    [SearchableSelectType.DCDCase]: <SearchDCDCaseField isInFilter {...props} />,
    [SearchableSelectType.WC1]: <SearchWC1Field {...props} />,
    [SearchableSelectType.Physician]: <SearchPhysicianAccount isInFilter {...props} />,
    [SearchableSelectType.Employer]: <SearchEmployerField isInFilter {...props} />,
    [SearchableSelectType.EmployerAudit]: <SearchEmployerAuditField isInFilter {...props} />,
    [SearchableSelectType.AdjustingCompany]: <SearchAdjustingCompany isInFilter {...props} />,
    [SearchableSelectType.Carrier]: <SearchInsuranceCarrierField isInFilter {...props} />,
    [SearchableSelectType.Claimant]: <SearchClaimant isInFilter {...props} />,
    [SearchableSelectType.Association]: <SearchAssociationField isInFilter {...props} />,
    [SearchableSelectType.Agent]: <SearchAgentField isInFilter {...props} />,
    [SearchableSelectType.Contact]: <SearchContact isInFilter {...props} />,
    [SearchableSelectType.ContactOfEmployer]: <SearchContactOfEmployer isInFilter {...props} />,
    [SearchableSelectType.ContactOfAdjustingCompany]: (
      <SearchContactOfAdjustingCompany isInFilter {...props} />
    ),
    [SearchableSelectType.ComplainantAccount]: <SearchComplainantAccount isInFilter {...props} />,
    [SearchableSelectType.Hearing]: <SearchHearingField isInFilter {...props} />,
    [SearchableSelectType.TDI62]: <SearchTDI62Field isInFilter {...props} />,
    [SearchableSelectType.TDI46]: <SearchTDI46Field isInFilter {...props} />,
    [SearchableSelectType.TDI15]: <SearchTDI15Field isInFilter {...props} />,
    [SearchableSelectType.TDI30]: <SearchTDI30Field isInFilter {...props} />,
    [SearchableSelectType.TDISFCase]: <SearchTDISFCaseField isInFilter {...props} />,
    [SearchableSelectType.TDICase]: <SearchTDICaseField isInFilter {...props} />,
    [SearchableSelectType.TDIPlan]: <SearchTDIPlanField isInFilter {...props} />,
    [SearchableSelectType.HCP]: <SearchHealthCarePlanField isInFilter {...props} />,
    [SearchableSelectType.TDI21]: <SearchTDI21Field isInFilter {...props} />,
    [SearchableSelectType.Decision]: <SearchDecisionField isInFilter {...props} />,
    [SearchableSelectType.TDIDecision]: <SearchTDIDecisionField isInFilter {...props} />,
    [SearchableSelectType.AwardWorksheet]: <SearchAwardWorksheetField isInFilter {...props} />,
    [SearchableSelectType.Vendor]: <SearchVendorNameField isInFilter {...props} />,
    [SearchableSelectType.Room]: <SearchRoom isInFilter {...props} />,
    [SearchableSelectType.GLAccount]: <SearchGLAccount isInFilter {...props} />,
    [SearchableSelectType.HC4]: <SearchHC4Field isInFilter {...props} />,
    [SearchableSelectType.HC15]: <SearchHC15Field isInFilter {...props} />,
    [SearchableSelectType.HC61]: <SearchHC61Field isInFilter {...props} />,
    [SearchableSelectType.Request]: <SearchRequest isInFilter {...props} />,
    [SearchableSelectType.Disbursement]: <SearchDisbursementField isInFilter {...props} />,
    [SearchableSelectType.Complaint]: <SearchComplaint isInFilter {...props} />,
    [SearchableSelectType.WC77]: <SearchWC77Field isInFilter {...props} />,
  };

  return (
    // eslint-disable-next-line security/detect-object-injection
    <Suspense fallback={<Skeleton />}>{searchableFieldMap[selectedSearchableFieldType]}</Suspense>
  );
};

type Props = {
  name: string;
  onChange: (field: string, value: any, shouldValidate?: boolean) => void;
  errorMessage: string;
  onBlur: (field: string, touched?: boolean, shouldValidate?: boolean) => void;
  value: Array<string> | string;
  selectedSearchableFieldType: SearchableSelectType;
  isMulti: boolean;
  labelControl: string;
};

export default SearchableField;
