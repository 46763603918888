import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { ApiResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { getWC77 } from './helpers';
import { WC77 } from './types';

export function useGetWC77(
  options?: UseQueryOptions<ApiResponseType<{ form: WC77 }>, Error, WC77> & {
    id: WC77['id'];
  }
) {
  const {
    data: wc77,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetWC77ById,
  } = useQuery<ApiResponseType<{ form: WC77 }>, Error, WC77>([API_QUERIES.WC77s, { ...options }], {
    queryFn: (query) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...params] = query.queryKey;
      return responseWrapper<ApiResponseType<{ form: WC77 }>>(apiClient.getWC77, params);
    },
    select: ({ data }) => getWC77(data.form),
    enabled: !!options?.id,
    ...options,
  });

  const queryClient = useQueryClient();

  const handleInvalidateWC77 = () =>
    queryClient.invalidateQueries([API_QUERIES.WC77s, { ...options }]);

  return {
    wc77,
    error,
    isError,
    isLoading,
    onGetWC77ById,
    handleInvalidateWC77,
  };
}
