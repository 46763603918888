import { formatDateTime } from 'src/utils';
import { PaginationResponseType } from '../helpers';
import { CaseSettlementsKey } from './keys';
import { CaseSettlementsTypes } from './types';

export const toCaseSettlement = (
  data: PaginationResponseType<any>
): PaginationResponseType<CaseSettlementsTypes> => ({
  ...data,
  data: data.data.map((caseSettlement) => ({
    ...caseSettlement,
    [CaseSettlementsKey.AssignTo]: caseSettlement?.assignTo
      ? `${caseSettlement?.assignTo?.firstName} ${caseSettlement?.assignTo?.lastName}`
      : '',
    [CaseSettlementsKey.ApprovalReviewedBy]: caseSettlement?.approvalReviewedBy
      ? `${caseSettlement?.approvalReviewedBy?.firstName} ${caseSettlement?.approvalReviewedBy?.lastName}`
      : '',
    [CaseSettlementsKey.CreatedBy]: `${caseSettlement?.createdUser?.firstName} ${
      caseSettlement?.createdUser?.lastName
    }, ${formatDateTime(caseSettlement?.createdAt)}`,
    [CaseSettlementsKey.LastModifiedBy]:
      caseSettlement?.updatedUser &&
      `${caseSettlement?.updatedUser?.firstName} ${
        caseSettlement?.updatedUser?.lastName
      }, ${formatDateTime(caseSettlement?.updatedAt)}`,
    [CaseSettlementsKey.Type]: caseSettlement?.type ? caseSettlement?.type.split(',') : [],
  })),
});
