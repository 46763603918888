export enum HearingRelatedNotesKey {
  Id = 'id',
  Subject = 'subject',
  Body = 'body',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  CreatedUser = 'createdUser',
  UpdatedUser = 'updatedUser',
  RelatedHearings = 'relatedHearings',
  HearingId = 'hearingId',
  NoteId = 'noteId',
  UserGroup = 'userGroup',
  Permission = 'permission'
}
