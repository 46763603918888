import { TDICase } from 'src/queries/TDICases';
import { AccountDetail } from '../Accounts';
import { DCDCaseDetail } from '../DCDCases';
import { HearingKey, HearingScheduleKey, RoomKeys, ScheduleAvailabilityKey } from './keys';

export interface Hearing {
  [HearingKey.Id]?: string;
  [HearingKey.CaseNumber]?: string;
  [HearingKey.CaseId]?: string;
  [HearingKey.HearingNumber]?: string;
  [HearingKey.ClaimantName]?: string;
  [HearingKey.ClaimantId]?: string;
  [HearingKey.ClaimantSSN]?: string;
  [HearingKey.Parties]?: any;
  [HearingKey.EmployerId]?: string;
  [HearingKey.CanUpdateInvolvedSCF]?: boolean;

  [HearingKey.DateOfII]?: string;
  [HearingKey.DateOfHearingRequest]?: string;
  [HearingKey.DateReadyHearingCreated]?: string;
  [HearingKey.ReceivedWithDrawalLetter]?: string;
  [HearingKey.ReadyHearingDate]?: string;
  [HearingKey.HearingTypeForThisWorksheet]?: string;
  [HearingKey.HearingPriority]?: string;
  [HearingKey.CalendarDate]?: string;
  [HearingKey.Case]?: DCDCaseDetail;
  [HearingKey.IsInvolvesSCF]?: boolean;
  [HearingKey.ReadyHearingStatus]?: string;
  [HearingKey.RetireExpectedHearingDuration]?: Date | string;
  [HearingKey.HearingOfficerId]?: string;
  [HearingKey.HearingOfficer]?: string;
  [HearingKey.RefereeId]?: string;
  [HearingKey.MinimumOfficerPositionNeeded]?: string;
  [HearingKey.CancellationDate]?: string;
  [HearingKey.HearingReviewer]?: string;
  [HearingKey.HearingReviewerId]?: string;
  [HearingKey.OriginalLocation]?: string;
  [HearingKey.StandbyHearing]?: boolean;
  [HearingKey.HearingLocation]?: string;
  [HearingKey.Room]?: string;
  [HearingKey.RoomId]?: string;
  [HearingKey.HearingDateTime]?: string;
  [HearingKey.Duration]?: string;
  [HearingKey.Status]?: string;
  [HearingKey.ScheduleStartTime]?: string;
  [HearingKey.ScheduleEndTime]?: string;
  [HearingKey.RequiredDocuments]?: any[];
  [HearingKey.HearingEventStatus]?: string;
  [HearingKey.HearingNoticeMailDate]?: string;
  [HearingKey.DateOfHearing]?: string;
  [HearingKey.HearingScheduler]?: string;
  [HearingKey.HearingSchedulerId]?: string;
  [HearingKey.HearingScheduler]?: string;
  [HearingKey.InterpreterRequestedDate]?: string;
  [HearingKey.InterpreterConfirmedDate]?: string;
  [HearingKey.Comment]?: string;
  [HearingKey.PurposesTreatmentPlanDate]?: string;
  [HearingKey.PurposesTreatmentPlanPhysician]?: string;
  [HearingKey.OtherReason]?: string;
  [HearingKey.HearingLifecycle]?: string;
  [HearingKey.RefereeManager]?: string;
  [HearingKey.CancellationPostponementRequestStatus]?: string;
  [HearingKey.CancellationPostponementRequested]?: boolean;
  [HearingKey.CancellationPostponementReason]?: string;
  [HearingKey.WorksheetStatus]?: string;
  [HearingKey.CurrentServicingLocation]?: string;
  [HearingKey.ClericalServiceId]?: string;
  [HearingKey.ClericalService]?: string;
  [HearingKey.HearingFacilitatorId]?: string;
  [HearingKey.HearingFacilitator]?: string;
  [HearingKey.CreatedUser]?: string;
  [HearingKey.UpdatedUser]?: string;
  [HearingKey.UpdatedBy]?: string;
  [HearingKey.CreatedAt]?: string;
  [HearingKey.UpdatedAt]?: string;
  [HearingKey.FirstName]?: string;
  [HearingKey.LastName]?: string;
  [HearingKey.CreatedBy]?: string;

  [HearingKey.ClaimantId]?: string;

  // TDI Hearing
  [HearingKey.TDISFCaseNumber]?: string;
  [HearingKey.TDISFCaseId]?: string;
  [HearingKey.TDICaseNumber]?: string;
  [HearingKey.TDICaseId]?: string;
  [HearingKey.RefereeUser]?: string;
  [HearingKey.VideoConferenceURL]?: string;
  [HearingKey.LocationType]?: LocationType;
  [HearingKey.TelephoneHearingCode]?: string;
  [HearingKey.DisabilityDate]?: string;
  [HearingKey.TDICase]?: TDICase;
  [HearingKey.TDISFCase]?: { tdiSFCaseNumber: string; claimant: AccountDetail };
  [HearingKey.TDICaseId]?: string;
  [HearingKey.TDISFCaseId]?: string;
}

export interface RoomInterface {
  [RoomKeys.Id]?: string;
  [RoomKeys.RoomName]?: string;
}

export interface HearingScheduleInterface {
  [HearingScheduleKey.Id]?: string;
  [HearingScheduleKey.HearingId]?: string;
  [HearingScheduleKey.ScheduleDate]?: string;
  [HearingScheduleKey.ScheduleStartTime]?: string;
  [HearingScheduleKey.ScheduleEndTime]?: string;
  [HearingScheduleKey.RetireExpectedHearingDuration]?: number;
  [HearingScheduleKey.DurationHours]?: number;
  [HearingScheduleKey.HearingOfficerId]?: string;
  [HearingScheduleKey.RefereeUserId]?: string;
  [HearingScheduleKey.RoomId]?: string;
  [HearingScheduleKey.Representatives]?: string[];
  [HearingScheduleKey.RepresentativeIds]?: string[];
  [HearingScheduleKey.AppName]?: string;
}

export enum TimeSlotStatus {
  Available = 'available',
  Booked = 'booked',
}

export enum ParticipantType {
  Room = 'ROOM',
  HearingOfficer = 'HEARING OFFICER',
  Representative = 'REPRESENTATIVE',
  RefereeUser = 'REFEREE',
}

export interface TimeSlot {
  time: string;
  status: TimeSlotStatus;
}

export interface Participant {
  id: string;
  name: string;
  availability: TimeSlot[];
}

export interface ScheduleAvailabilityInterface {
  [ScheduleAvailabilityKey.Officer]?: Participant;
  [ScheduleAvailabilityKey.Room]?: Participant;
  [ScheduleAvailabilityKey.Representatives]?: Participant[];
}

export enum LocationType {
  VideoConferenceHearingAt = 'VIDEO',
  TelephoneHearing = 'TELEPHONE',
  InPersonAtDisabilityCompensationDivision = 'IN_PERSON',
}

export enum HearingRequestType {
  Reassignment = 'Officer Reassignment',
  RefereeAssignment = 'Referee Reassignment',
  Postponement = 'Postponement',
  Cancellation = 'Cancellation',
}
