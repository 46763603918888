export enum RelatedPartyNameAddressKey {
  Id = 'id',
  CaseId = 'caseId',
  TDICaseId = 'tdiCaseId',
  TDISFCaseId = 'tdiSFCaseId',
  CurrentlyActive = 'isCurrentlyActive',
  ActiveStartDate = 'activeStartDate',
  ActiveEndDate = 'activeEndDate',
  PartyType = 'partyType',
  PartyRole = 'partyRole',
  RepresentativeFor = 'representativeFor',
  Account = 'account',
  AccountName = 'accountName',
  AccountId = 'accountId',
  Contact = 'contact',
  ContactName = 'contactName',
  ContactId = 'contactId',
  PartyName = 'partyName',
  PartyAddress = 'partyAddress',
  CreatedBy = 'createdBy',
  UpdatedBy = 'updatedBy',
  CreatedUser = 'createdUser',
  UpdatedUser = 'updatedUser',
}

export interface RelatedPartyNamesAddressPayLoad {
  [RelatedPartyNameAddressKey.Id]?: string;
  [RelatedPartyNameAddressKey.CaseId]?: string;
  [RelatedPartyNameAddressKey.TDISFCaseId]?: string;
  [RelatedPartyNameAddressKey.TDICaseId]?: string;
  [RelatedPartyNameAddressKey.CurrentlyActive]?: boolean;
  [RelatedPartyNameAddressKey.ActiveStartDate]?: string;
  [RelatedPartyNameAddressKey.ActiveEndDate]?: string;
  [RelatedPartyNameAddressKey.PartyType]?: string;
  [RelatedPartyNameAddressKey.PartyRole]?: string;
  [RelatedPartyNameAddressKey.RepresentativeFor]?: string;
  [RelatedPartyNameAddressKey.Account]?: any;
  [RelatedPartyNameAddressKey.AccountName]?: string;
  [RelatedPartyNameAddressKey.AccountId]?: string;
  [RelatedPartyNameAddressKey.Contact]?: any;
  [RelatedPartyNameAddressKey.ContactName]?: string;
  [RelatedPartyNameAddressKey.ContactId]?: string;
  [RelatedPartyNameAddressKey.PartyName]?: string;
  [RelatedPartyNameAddressKey.PartyAddress]?: string;
  [RelatedPartyNameAddressKey.CreatedBy]?: string;
  [RelatedPartyNameAddressKey.UpdatedBy]?: string;
}
