import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { PaginationResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { GetPropertiesParams } from '../types';
import { PartyNameAndAddressType } from './types';
import { toRelatedPartyNamesAddressData } from './helpers';

export function useGetAllRelatedPartyNamesAddress(
  options?: UseQueryOptions<PaginationResponseType<PartyNameAndAddressType>, Error> & {
    hearingId: string;
  }
) {
  const [params, setParams] = useState<GetPropertiesParams>({});
  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetAllRelatedPartyNamesAddress,
  } = useQuery<PaginationResponseType<PartyNameAndAddressType>, Error>(
    [API_QUERIES.RELATED_PARTY_NAMES_ADDRESS, { ...params, hearingId: options?.hearingId }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<PaginationResponseType<PartyNameAndAddressType>>(
          apiClient.getAllRelatedPartyNamesAddress,
          params
        );
      },
      select: toRelatedPartyNamesAddressData,
      notifyOnChangeProps: ['data', 'isFetching'],
      keepPreviousData: true,
      enabled: !!options?.hearingId,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateAllRelatedPartyNamesAddress = () =>
    queryClient.invalidateQueries([API_QUERIES.RELATED_PARTY_NAMES_ADDRESS]);

  const { data: partyNamesAddress, hasNext, payloadSize, totalRecords } = data || {};

  return {
    partyNamesAddress,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isError,
    isFetching,
    onGetAllRelatedPartyNamesAddress,
    handleInvalidateAllRelatedPartyNamesAddress,
    setParams,
  };
}
