import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { RelatedVendorsType } from './types';

export function useEditRelatedVendor(options?: UseMutationOptions<any, Error, RelatedVendorsType>) {
  const {
    mutate: editRelatedVendor,
    isLoading: isUpdating,
    isSuccess,
    isError,
    error,
  } = useMutation<any, Error, RelatedVendorsType>({
    mutationFn: (payload: RelatedVendorsType) =>
      responseWrapper(apiClient.updateRelatedVendor, [payload]),
    onError: (error) => ErrorService.handler(error),
    ...options,
  });

  return {
    editRelatedVendor,
    isUpdating,
    isSuccess,
    isError,
    error,
  };
}
