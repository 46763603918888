import { User } from '../Users';

export enum WC5ARawKey {
  Id = 'id',
  FormNumber = 'formNumber',
  Claimant = 'claimant',
  ClaimantName = 'claimantName',
  ClaimantId = 'claimantId',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  UpdatedBy = 'updatedBy',
  CreatedBy = 'createdBy',
  CreatedUser = 'createdUser',
  UpdatedUser = 'updatedUser',

  //---Decedent's Information---//
  RawClaimantCaseNumber = 'rawClaimantCaseNumber',
  RawClaimantFirstName = 'rawClaimantFirstName',
  RawClaimantLastName = 'rawClaimantLastName',
  RawClaimantMiddleInitial = 'rawClaimantMiddleInitial',
  RawClaimantSuffix = 'rawClaimantSuffix',
  RawClaimantSocialSecurityNumber = 'rawClaimantSocialSecurityNumber',
  RawClaimantPassportNumber = 'rawClaimantPassportNumber',
  RawClaimantDateOfInjury = 'rawClaimantDateOfInjury',
  RawClaimantDeathCertificateNumber = 'rawClaimantDeathCertificateNumber',
  RawClaimantDeathDate = 'rawClaimantDeathDate',
  RawClaimantDeathPlace = 'rawClaimantDeathPlace',
  RawClaimantDeathNatureOfIi = 'rawClaimantDeathNatureOfIi',

  //----Employer---//
  RawEmployerName = 'rawEmployerName',
  RawEmployerDolNumber = 'rawEmployerDolNumber',
  RawEmployerAddress = 'rawEmployerAddress',
  RawEmployerCity = 'rawEmployerCity',
  RawEmployerZipcode = 'rawEmployerZipcode',
  RawEmployerState = 'rawEmployerState',
  RawEmployerEmail = 'rawEmployerEmail',
  RawEmployerPhone = 'rawEmployerPhone',
  RawEmployerPointOfContact = 'rawEmployerPointOfContact',
  RawInsuranceCarrierId = 'rawInsuranceCarrierId',

  //---Header and Signature---//
  RawNewAmend = 'rawNewAmend',
  RawHeaderCaseNumber = 'rawHeaderCaseNumber',
  RawDateReceived = 'rawDateReceived',
  RawDependentPrinterName = 'rawDependentPrinterName',
  RawDependentPrinterSignatureType = 'rawDependentPrinterSignatureType',
  RawDependentPrinterSignature = 'rawDependentPrinterSignature',
  RawDependentPrinterSignatureDate = 'rawDependentPrinterSignatureDate',
  RawDependentPrinterPhone = 'rawDependentPrinterPhone',
  RawDependentPrinterEmailAddress = 'rawDependentPrinterEmail',
  RawDependentPrinterAddress = 'rawDependentPrinterAddress',
  RawDependentPrinterRepresentedName = 'rawDependentPrinterRepresentedName',
  RawDependentPrinterRepresentedAddress = 'rawDependentPrinterRepresentedAddress',
  RawDependentPrinterRepresentedCity = 'rawDependentPrinterRepresentedCity',
  RawDependentPrinterRepresentedState = 'rawDependentPrinterRepresentedState',
  RawDependentPrinterRepresentedZipcode = 'rawDependentPrinterRepresentedZipcode',

  //---Dependent 1---//
  RawDependent1LastName = 'rawDependent1LastName',
  RawDependent1FirstName = 'rawDependent1FirstName',
  RawDependent1MiddleInitial = 'rawDependent1MiddleInitial',
  RawDependent1Suffix = 'rawDependent1Suffix',
  RawDependent1Birthday = 'rawDependent1Birthday',
  RawDependent1Address = 'rawDependent1Address',
  RawDependent1City = 'rawDependent1City',
  RawDependent1State = 'rawDependent1State',
  RawDependent1Zipcode = 'rawDependent1Zipcode',
  RawDependent1Phone = 'rawDependent1Phone',
  RawDependent1RelationToDeceased = 'rawDependent1RelationToDeceased',
  RawDependent1MarriageOrBirthCertificate = 'rawDependent1MarriageOrBirthCertificate',
  RawDependent1Email = 'rawDependent1Email',
  RawDependent1RepresentedName = 'rawDependent1RepresentedName',
  RawDependent1RepresentedPhone = 'rawDependent1RepresentedPhone',
  RawDependent1RepresentedAddress = 'rawDependent1RepresentedAddress',
  RawDependent1RepresentedCity = 'rawDependent1RepresentedCity',
  RawDependent1RepresentedState = 'rawDependent1RepresentedState',
  RawDependent1RepresentedZipcode = 'rawDependent1RepresentedZipcode',
  RawDependent1RepresentedEmail = 'rawDependent1RepresentedEmail',

  //---Dependent 2---//
  RawDependent2LastName = 'rawDependent2LastName',
  RawDependent2FirstName = 'rawDependent2FirstName',
  RawDependent2MiddleInitial = 'rawDependent2MiddleInitial',
  RawDependent2Suffix = 'rawDependent2Suffix',
  RawDependent2Birthday = 'rawDependent2Birthday',
  RawDependent2Address = 'rawDependent2Address',
  RawDependent2City = 'rawDependent2City',
  RawDependent2State = 'rawDependent2State',
  RawDependent2Zipcode = 'rawDependent2Zipcode',
  RawDependent2Phone = 'rawDependent2Phone',
  RawDependent2RelationToDeceased = 'rawDependent2RelationToDeceased',
  RawDependent2MarriageOrBirthCertificate = 'rawDependent2MarriageOrBirthCertificate',
  RawDependent2Email = 'rawDependent2Email',
  RawDependent2RepresentedName = 'rawDependent2RepresentedName',
  RawDependent2RepresentedPhone = 'rawDependent2RepresentedPhone',
  RawDependent2RepresentedAddress = 'rawDependent2RepresentedAddress',
  RawDependent2RepresentedCity = 'rawDependent2RepresentedCity',
  RawDependent2RepresentedState = 'rawDependent2RepresentedState',
  RawDependent2RepresentedZipcode = 'rawDependent2RepresentedZipcode',
  RawDependent2RepresentedEmail = 'rawDependent2RepresentedEmail',

  //---Dependent 3---//
  RawDependent3LastName = 'rawDependent3LastName',
  RawDependent3FirstName = 'rawDependent3FirstName',
  RawDependent3MiddleInitial = 'rawDependent3MiddleInitial',
  RawDependent3Suffix = 'rawDependent3Suffix',
  RawDependent3Birthday = 'rawDependent3Birthday',
  RawDependent3Address = 'rawDependent3Address',
  RawDependent3City = 'rawDependent3City',
  RawDependent3State = 'rawDependent3State',
  RawDependent3Zipcode = 'rawDependent3Zipcode',
  RawDependent3Phone = 'rawDependent3Phone',
  RawDependent3RelationToDeceased = 'rawDependent3RelationToDeceased',
  RawDependent3MarriageOrBirthCertificate = 'rawDependent3MarriageOrBirthCertificate',
  RawDependent3Email = 'rawDependent3Email',
  RawDependent3RepresentedName = 'rawDependent3RepresentedName',
  RawDependent3RepresentedPhone = 'rawDependent3RepresentedPhone',
  RawDependent3RepresentedAddress = 'rawDependent3RepresentedAddress',
  RawDependent3RepresentedCity = 'rawDependent3RepresentedCity',
  RawDependent3RepresentedState = 'rawDependent3RepresentedState',
  RawDependent3RepresentedZipcode = 'rawDependent3RepresentedZipcode',
  RawDependent3RepresentedEmail = 'rawDependent3RepresentedEmail',

  //---Dependent 4---//
  RawDependent4LastName = 'rawDependent4LastName',
  RawDependent4FirstName = 'rawDependent4FirstName',
  RawDependent4MiddleInitial = 'rawDependent4MiddleInitial',
  RawDependent4Suffix = 'rawDependent4Suffix',
  RawDependent4Birthday = 'rawDependent4Birthday',
  RawDependent4Address = 'rawDependent4Address',
  RawDependent4City = 'rawDependent4City',
  RawDependent4State = 'rawDependent4State',
  RawDependent4Zipcode = 'rawDependent4Zipcode',
  RawDependent4Phone = 'rawDependent4Phone',
  RawDependent4RelationToDeceased = 'rawDependent4RelationToDeceased',
  RawDependent4MarriageOrBirthCertificate = 'rawDependent4MarriageOrBirthCertificate',
  RawDependent4Email = 'rawDependent4Email',
  RawDependent4RepresentedName = 'rawDependent4RepresentedName',
  RawDependent4RepresentedPhone = 'rawDependent4RepresentedPhone',
  RawDependent4RepresentedAddress = 'rawDependent4RepresentedAddress',
  RawDependent4RepresentedCity = 'rawDependent4RepresentedCity',
  RawDependent4RepresentedState = 'rawDependent4RepresentedState',
  RawDependent4RepresentedZipcode = 'rawDependent4RepresentedZipcode',
  RawDependent4RepresentedEmail = 'rawDependent4RepresentedEmail',
}

export enum WC5ARelationshipKey {
  CaseNumber = 'caseNumber',
  CaseId = 'caseId',
  Case = 'case',
  ClaimantId = 'claimantId',
  Claimant = 'claimant',
  EmployerId = 'employerId',
  Employer = 'employer',
  PhysicianId = 'physicianId',
  Physician = 'physician',
  InsuranceCarrierId = 'insuranceCarrierId',
  InsuranceCarrier = 'insuranceCarrier',
  AdjustingCompanyId = 'adjustingCompanyId',
  AdjustingCompany = 'adjustingCompany',
  AdjusterId = 'adjusterId',
  Adjuster = 'adjuster',
  EmployerPointOfContactId = 'employerPointOfContactId',
  EmployerPointOfContact = 'employerPointOfContact',
}

export interface WC5ARawInterface {
  [WC5ARawKey.Id]?: string;
  [WC5ARawKey.FormNumber]?: string;
  [WC5ARawKey.ClaimantName]?: string;
  [WC5ARawKey.ClaimantId]?: string;
  [WC5ARawKey.CreatedAt]?: string;
  [WC5ARawKey.UpdatedAt]?: string;
  [WC5ARawKey.CreatedUser]?: string;
  [WC5ARawKey.UpdatedUser]?: User;
  [WC5ARawKey.RawNewAmend]?: string;
  [WC5ARawKey.RawHeaderCaseNumber]?: string;
  [WC5ARawKey.RawDateReceived]?: string;
  [WC5ARawKey.RawClaimantCaseNumber]?: string;
  [WC5ARawKey.RawClaimantFirstName]?: string;
  [WC5ARawKey.RawClaimantLastName]?: string;
  [WC5ARawKey.RawClaimantMiddleInitial]?: string;
  [WC5ARawKey.RawClaimantSuffix]?: string;
  [WC5ARawKey.RawClaimantSocialSecurityNumber]?: string;
  [WC5ARawKey.RawClaimantPassportNumber]?: string;
  [WC5ARawKey.RawClaimantDateOfInjury]?: string;
  [WC5ARawKey.RawClaimantDeathCertificateNumber]?: string;
  [WC5ARawKey.RawClaimantDeathDate]?: string;
  [WC5ARawKey.RawClaimantDeathPlace]?: string;
  [WC5ARawKey.RawClaimantDeathNatureOfIi]?: string;
  [WC5ARawKey.RawEmployerName]?: string;
  [WC5ARawKey.RawEmployerDolNumber]?: string;
  [WC5ARawKey.RawInsuranceCarrierId]?: string;
  [WC5ARawKey.RawEmployerAddress]?: string;
  [WC5ARawKey.RawEmployerCity]?: string;
  [WC5ARawKey.RawEmployerZipcode]?: string;
  [WC5ARawKey.RawEmployerState]?: string;
  [WC5ARawKey.RawEmployerEmail]?: string;
  [WC5ARawKey.RawEmployerPhone]?: string;
  [WC5ARawKey.RawEmployerPointOfContact]?: string;
  [WC5ARawKey.RawDependentPrinterName]?: string;
  [WC5ARawKey.RawDependentPrinterSignatureType]?: string;
  [WC5ARawKey.RawDependentPrinterSignature]?: string;
  [WC5ARawKey.RawDependentPrinterSignatureDate]?: string;
  [WC5ARawKey.RawDependentPrinterEmailAddress]?: string;
  [WC5ARawKey.RawDependentPrinterPhone]?: string;
  [WC5ARawKey.RawDependentPrinterAddress]?: string;
  [WC5ARawKey.RawDependentPrinterRepresentedName]?: string;
  [WC5ARawKey.RawDependentPrinterRepresentedAddress]?: string;
  [WC5ARawKey.RawDependentPrinterRepresentedCity]?: string;
  [WC5ARawKey.RawDependentPrinterRepresentedState]?: string;
  [WC5ARawKey.RawDependentPrinterRepresentedZipcode]?: string;
  [WC5ARawKey.RawDependent1LastName]?: string;
  [WC5ARawKey.RawDependent1FirstName]?: string;
  [WC5ARawKey.RawDependent1MiddleInitial]?: string;
  [WC5ARawKey.RawDependent1Suffix]?: string;
  [WC5ARawKey.RawDependent1Birthday]?: string;
  [WC5ARawKey.RawDependent1Address]?: string;
  [WC5ARawKey.RawDependent1City]?: string;
  [WC5ARawKey.RawDependent1State]?: string;
  [WC5ARawKey.RawDependent1Zipcode]?: string;
  [WC5ARawKey.RawDependent1Phone]?: string;
  [WC5ARawKey.RawDependent1RelationToDeceased]?: string;
  [WC5ARawKey.RawDependent1MarriageOrBirthCertificate]?: string;
  [WC5ARawKey.RawDependent1Email]?: string;
  [WC5ARawKey.RawDependent1RepresentedName]?: string;
  [WC5ARawKey.RawDependent1RepresentedPhone]?: string;
  [WC5ARawKey.RawDependent1RepresentedAddress]?: string;
  [WC5ARawKey.RawDependent1RepresentedCity]?: string;
  [WC5ARawKey.RawDependent1RepresentedState]?: string;
  [WC5ARawKey.RawDependent1RepresentedZipcode]?: string;
  [WC5ARawKey.RawDependent1RepresentedEmail]?: string;
  [WC5ARawKey.RawDependent2LastName]?: string;
  [WC5ARawKey.RawDependent2FirstName]?: string;
  [WC5ARawKey.RawDependent2MiddleInitial]?: string;
  [WC5ARawKey.RawDependent2Suffix]?: string;
  [WC5ARawKey.RawDependent2Birthday]?: string;
  [WC5ARawKey.RawDependent2Address]?: string;
  [WC5ARawKey.RawDependent2City]?: string;
  [WC5ARawKey.RawDependent2State]?: string;
  [WC5ARawKey.RawDependent2Zipcode]?: string;
  [WC5ARawKey.RawDependent2Phone]?: string;
  [WC5ARawKey.RawDependent2RelationToDeceased]?: string;
  [WC5ARawKey.RawDependent2MarriageOrBirthCertificate]?: string;
  [WC5ARawKey.RawDependent2Email]?: string;
  [WC5ARawKey.RawDependent2RepresentedName]?: string;
  [WC5ARawKey.RawDependent2RepresentedPhone]?: string;
  [WC5ARawKey.RawDependent2RepresentedAddress]?: string;
  [WC5ARawKey.RawDependent2RepresentedCity]?: string;
  [WC5ARawKey.RawDependent2RepresentedState]?: string;
  [WC5ARawKey.RawDependent2RepresentedZipcode]?: string;
  [WC5ARawKey.RawDependent2RepresentedEmail]?: string;
  [WC5ARawKey.RawDependent3LastName]?: string;
  [WC5ARawKey.RawDependent3FirstName]?: string;
  [WC5ARawKey.RawDependent3MiddleInitial]?: string;
  [WC5ARawKey.RawDependent3Suffix]?: string;
  [WC5ARawKey.RawDependent3Birthday]?: string;
  [WC5ARawKey.RawDependent3Address]?: string;
  [WC5ARawKey.RawDependent3City]?: string;
  [WC5ARawKey.RawDependent3State]?: string;
  [WC5ARawKey.RawDependent3Zipcode]?: string;
  [WC5ARawKey.RawDependent3Phone]?: string;
  [WC5ARawKey.RawDependent3RelationToDeceased]?: string;
  [WC5ARawKey.RawDependent3MarriageOrBirthCertificate]?: string;
  [WC5ARawKey.RawDependent3Email]?: string;
  [WC5ARawKey.RawDependent3RepresentedName]?: string;
  [WC5ARawKey.RawDependent3RepresentedPhone]?: string;
  [WC5ARawKey.RawDependent3RepresentedAddress]?: string;
  [WC5ARawKey.RawDependent3RepresentedCity]?: string;
  [WC5ARawKey.RawDependent3RepresentedState]?: string;
  [WC5ARawKey.RawDependent3RepresentedZipcode]?: string;
  [WC5ARawKey.RawDependent3RepresentedEmail]?: string;
  [WC5ARawKey.RawDependent4LastName]?: string;
  [WC5ARawKey.RawDependent4FirstName]?: string;
  [WC5ARawKey.RawDependent4MiddleInitial]?: string;
  [WC5ARawKey.RawDependent4Suffix]?: string;
  [WC5ARawKey.RawDependent4Birthday]?: string;
  [WC5ARawKey.RawDependent4Address]?: string;
  [WC5ARawKey.RawDependent4City]?: string;
  [WC5ARawKey.RawDependent4State]?: string;
  [WC5ARawKey.RawDependent4Zipcode]?: string;
  [WC5ARawKey.RawDependent4Phone]?: string;
  [WC5ARawKey.RawDependent4RelationToDeceased]?: string;
  [WC5ARawKey.RawDependent4MarriageOrBirthCertificate]?: string;
  [WC5ARawKey.RawDependent4Email]?: string;
  [WC5ARawKey.RawDependent4RepresentedName]?: string;
  [WC5ARawKey.RawDependent4RepresentedPhone]?: string;
  [WC5ARawKey.RawDependent4RepresentedAddress]?: string;
  [WC5ARawKey.RawDependent4RepresentedCity]?: string;
  [WC5ARawKey.RawDependent4RepresentedState]?: string;
  [WC5ARawKey.RawDependent4RepresentedZipcode]?: string;
  [WC5ARawKey.RawDependent4RepresentedEmail]?: string;
}

export interface WC5ARelationshipInterface {
  [WC5ARelationshipKey.CaseId]?: string;
  [WC5ARelationshipKey.Case]?: any;
  [WC5ARelationshipKey.CaseNumber]?: string;
  [WC5ARelationshipKey.ClaimantId]?: string;
  [WC5ARelationshipKey.Claimant]?: any;
  [WC5ARelationshipKey.EmployerId]?: string;
  [WC5ARelationshipKey.Employer]?: any;
  [WC5ARelationshipKey.PhysicianId]?: string;
  [WC5ARelationshipKey.Physician]?: any;
  [WC5ARelationshipKey.InsuranceCarrierId]?: string;
  [WC5ARelationshipKey.InsuranceCarrier]?: any;
  [WC5ARelationshipKey.AdjustingCompanyId]?: string;
  [WC5ARelationshipKey.AdjustingCompany]?: any;
  [WC5ARelationshipKey.AdjusterId]?: string;
  [WC5ARelationshipKey.Adjuster]?: any;
  [WC5ARelationshipKey.EmployerPointOfContactId]?: string;
  [WC5ARelationshipKey.EmployerPointOfContact]?: any;
}

export const WC5AKey = { ...WC5ARawKey, ...WC5ARelationshipKey };

export type WC5A = WC5ARawInterface & WC5ARelationshipInterface;
