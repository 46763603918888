import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { createResponseWrapper } from '../helpers';
import { EmployerAudit } from './types';

export function useCreateEmployerAudit(options?: UseMutationOptions<any, Error, EmployerAudit>) {
  const { mutate: createEmployerAudit, isLoading } = useMutation<any, Error, EmployerAudit>({
    mutationFn: (payload: EmployerAudit) =>
      createResponseWrapper(apiClient.createEmployerAudit, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    createEmployerAudit,
    isLoading,
  };
}
