export enum WC5FormKey {
  Id = 'id',
  DCDCaseId = 'caseId',
  WC5Number = 'formNumber',
  NewAmend = 'rawNewAmend',
  DateReceived = 'rawDateReceived',
  PrintedNameOfEmployee = 'rawEmployeePrinterName',
  RepresentedBy = 'rawEmployeeRepresentedBy',
  CreatedDate = 'createdAt',
}

export interface WC5Form {
  [WC5FormKey.Id]: string;
  [WC5FormKey.DCDCaseId]: string;
  [WC5FormKey.WC5Number]: string;
  [WC5FormKey.NewAmend]: string;
  [WC5FormKey.DateReceived]: string;
  [WC5FormKey.PrintedNameOfEmployee]: string;
  [WC5FormKey.RepresentedBy]: string;
  [WC5FormKey.CreatedDate]: string;
}

export interface WC5FormPayload {
  [WC5FormKey.DCDCaseId]: string;
}
