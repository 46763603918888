/* eslint-disable security/detect-object-injection */
import { CancelToken } from 'apisauce';
import cn from 'classnames';
import Compressor from 'compressorjs';
import dayjs from 'dayjs';
import _, { isNull } from 'lodash';
import { parse } from 'qs';
import { formatPhoneNumber as formatLocalPhoneNumber } from 'react-phone-number-input';
import shortid from 'shortid';
import appConfig from 'src/appConfig';
import { Option } from 'src/queries';
import { isEmpty } from 'src/validations';
import {
  DateFormatDisplay,
  DateFormatDisplayMinute,
  HalfTimeFormat,
  YEAR_FORMAT,
} from './momentUtils';

export const handleGetError = (touched, errors, prefix) =>
  _.get(touched, prefix) ? _.get(errors, prefix) : '';

export const waiter = (time: number = 100) =>
  new Promise<Array<any>>((res) => setTimeout(() => res([]), time));
// import vnLocale from 'dayjs/locale/vi';
// var updateLocale = require('dayjs/plugin/updateLocale');
// export const updateVnLocale = () => {
//   dayjs.extend(updateLocale);
//   const newLocale = {
//     ...vnLocale,
//     meridiem: (hour, minute, isLowercase) => {
//       return hour >= 12 ? 'CH' : 'SA';
//     },
//   };
//   dayjs?.['updateLocale']('vi', newLocale);
// };

export function newCancelToken(timeout = appConfig.CONNECTION_TIMEOUT) {
  const source = CancelToken.source();
  setTimeout(() => {
    source.cancel();
  }, timeout);

  return { cancelToken: source.token };
}

export const getRandomId = (): string => shortid.generate();

export const generateArray = (length: number, initial = '') => Array(length).fill(initial);

export const stringify = (params: { [key: string]: number | string | string[] }) => {
  let result = '';

  Object.keys(params).forEach((key) => {
    if (!isEmpty(params[`${key}`])) {
      if (Array.isArray(params[`${key}`])) {
        let array = params[`${key}`] as string[];
        array.forEach((param: string) => {
          result += `&${key}=${encodeURIComponent(param)}`;
        });
      } else {
        result += `&${key}=${encodeURIComponent(params[`${key}`].toString())}`;
      }
    }
  });

  result = result.replace(/^&/, '');

  return result;
};

export const getYesNo = (value: boolean, highLightValue = 'YES') => {
  if (isEmpty(value)) return '';
  const result = value ? 'YES' : 'NO';
  const isHighLight = highLightValue === result;
  return <span className={cn({ 'has-text-danger': isHighLight })}>{result}</span>;
};

export const formatPhoneNumber = (mobile: string) => {
  if (!mobile) return '';
  try {
    return formatLocalPhoneNumber(mobile);
  } catch (error) {
    return '';
  }
};

export const formatDate = (value: string, format: string = 'MM/DD/YYYY') => {
  if (!value) return '';

  return dayjs(value).format(format);
};

export const formatYear = (value: string, format: string = YEAR_FORMAT) => {
  if (!value) return '';

  return dayjs(value).format(format);
};

export const formatMonthDateYear = (value: string, format: string = DateFormatDisplay) => {
  if (!value) return '';

  return dayjs(value).format(format);
};

export const formatDateTime = (value: string, format: string = DateFormatDisplayMinute) => {
  if (!value) return '';
  return dayjs(value).format(format);
};

export const convertUTCToHST = (utcDateString: string) => {
  const utcDate = new Date(utcDateString);
  return new Intl.DateTimeFormat('en-US', {
    timeZone: 'Pacific/Honolulu',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  }).format(utcDate);
};

export const convertHSTtoUTC = (dateString: string, timeString: string) => {
  const [hours, minutes, period] = timeString.split(/[:\s]/);
  let hours24 = hours === '12' ? '00' : hours;
  hours24 = period === 'PM' ? (parseInt(hours24, 10) + 12).toString() : hours24;
  const dateTimeString = `${dateString} ${hours24}:${minutes}:00`;
  const dateTimeHST = new Date(`${dateTimeString} GMT-1000`);
  const dateTimeUTC = dateTimeHST.toISOString();

  return dateTimeUTC;
};

export const convertUTCDateTimeToHST = (utcDateString: string) => {
  if (utcDateString) {
    const utcDate = new Date(utcDateString);
    return new Intl.DateTimeFormat('en-US', {
      timeZone: 'Pacific/Honolulu',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    }).format(utcDate);
  }

  return '';
};

export const timeToMinutes = (time: string) => {
  if (!time) return 0;
  const [hours, minutes, period] = time.split(/[:\s]/);
  let totalMinutes = parseInt(hours) * 60 + parseInt(minutes);
  if (period === 'PM' && hours !== '12') totalMinutes += 12 * 60;
  if (period === 'AM' && hours === '12') totalMinutes -= 12 * 60;
  return totalMinutes;
};

export const formatDateWithTimeZone = (value: string, format: string = 'MM/DD/YYYY') => {
  var customDayjs = require('dayjs');
  var timezone = require('dayjs/plugin/timezone');
  customDayjs.extend(timezone);
  return customDayjs().tz('US/Hawaii').format(format);
};

export const formatTime = (value: string) => formatDate(value, HalfTimeFormat);

export const formatShowSSN = (value: string, from: number = 6) => {
  return `*******${value.slice(from)}`;
};

export const getWeekDay = (value: string) => {
  if (!value) return '';
  return dayjs(value).format('dddd');
};

export const getClassNameByStatus = (status) => {
  switch (status) {
    case 'Pending':
      return 'is-status-pending ';
    case 'Completed':
    case 'Approved':
    case 'Active':
      return 'is-status-active';
    case 'Rejected':
      return 'is-status-rejected';
    default:
      return '';
  }
};

export const getYesNoText = (value: boolean) => (isNull(value) ? '' : value ? 'Yes' : 'No');

export const formatMoney = (value: number, number: number = 2) => {
  if (isEmpty(value)) return '';
  const money = Number(value).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: number,
  });

  return money.slice(0, 1) + ' ' + money.slice(1);
};

export const formatMoneyInput = (value: number) => {
  if (!value) return '';
  return value.toLocaleString('en-US', {
    maximumFractionDigits: 0,
  });
};
export const hourDateFormat = 'h:mm:ss a, MMMM DD, YYYY';
export const dateTimeFormat = 'MM/DD/YYYY HH:MM:ss A';
export const monthFormat = 'MMMM DD, YYYY';

export const emptyFunction = () => {};

export const compressFile = (file: File): Promise<File> => {
  return new Promise((resolve, reject) => {
    const isImage = ['image/jpg', 'image/jpeg', 'image/png'].includes(file?.type);

    if (isImage) {
      new Compressor(file, {
        quality: 0.7,
        maxWidth: 900,
        maxHeight: 900,
        convertSize: 0,
        success(result: File) {
          resolve(result);
        },
        error(err: Error) {
          reject(err);
        },
      });
    } else {
      resolve(file);
    }
  });
};

export const trimUrlHasEndDate = (url: string) => {
  const trimUrl = url.split('?')[0];
  const items = trimUrl.split('_');
  return items.slice(0, items.length - 1).join('');
};

export const trimUrl = (url: string) => {
  if (!url) return null;
  return url.split('?')[0];
};

export const getFileType = (file: File) => {
  if (!!file.type) return file.type;
  if (file.name.includes('.rar')) return 'application/x-rar-compressed';
  if (file.name.includes('.7z')) return 'application/x-7z-compressed';
  return 'image/png';
};

export const getNavigateUrl = (url: string) => (url.includes('http') ? url : `https://${url}`);

export const isURLImage = (url: string) => {
  if (isEmpty(url)) return false;

  const hasExtensionImage = [
    '.png',
    '.jpeg',
    '.jpg',
    'image/png',
    'image/jpeg',
    'image/jpg',
    'image/svg',
  ].some((ext) => url?.includes(ext));

  if (hasExtensionImage) {
    return true;
  }

  const state = parse(url?.split('?')[1], { ignoreQueryPrefix: false });
  const contentType = state?.['Content-Type'];
  const isImage = ['image/jpg', 'image/jpeg', 'image/png'].includes(contentType as string);

  return isImage;
};

export const capitalizeWords = (string: string) => {
  return string.replace(/(?:^|\s)\S/g, function (a) {
    return a.toUpperCase();
  });
};

export const getStartCase = (value: string) => (value ? _.startCase(value.toLowerCase()) : '');

export const getFirstUpperCase = (value: string) => {
  if (!value) return '';
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const getTitleCase = (str: string): string => {
  if (!str) return '';
  return _.startCase(_.toLower(str));
};

export const handleClick = (callback) => (event: React.MouseEvent<any>) => {
  event.stopPropagation();
  event.preventDefault();
  if (callback) callback(event);
};

//link https://stackoverflow.com/questions/42674473/get-all-keys-of-a-deep-object-in-javascript
export const deepKeys = (t, path = []) => {
  const res =
    Object(t) === t
      ? Object.entries(t) // 1
          .flatMap(([k, v]) => deepKeys(v, [...path, k]))
      : [path.join('.')]; // 2
  return res?.filter((x) => !/\d$/.test(x));
};

export const scrollToTopError = (error: string[]) => {
  if (!isEmpty(error)) {
    const input = document?.querySelector(`[name='${error[0]}']`);
    input?.parentElement?.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'start',
    });
  }
};
export const moneyReg = /[\d,]+\.{0,1}\d{0,2}/;

export const MoneyInputDetect = (value) => `${value}`.match(moneyReg)?.[0] || '';

export const removeSpecialCharacterFromString = (value: string) => {
  return value.replace(/[^a-zA-Z0-9 ]/g, '');
};

export const formatStringToNumber = (value: string) => {
  if (isEmpty(value)) return null;
  return Number(value);
};

export const setListSelectedItems = (items: any, ids: string[], prev: string[], key = 'id') => {
  const idList = [...ids];
  const prevIdList = [...prev];
  if (isEmpty(idList)) {
    items.forEach((item) => {
      if (prevIdList.indexOf(item?.[key]) > -1) {
        prevIdList.splice(prevIdList.indexOf(item?.[key]), 1);
      }
    });
    return [...prevIdList];
  } else if (idList.some((element) => prevIdList.includes(element))) {
    prevIdList.splice(prevIdList.indexOf(idList?.[0]), 1);
    return [...prevIdList];
  }
  return [...prevIdList, ...idList];
};

export const getSelectedRowListView = <T,>(
  items: T[],
  selectedItems: T[],
  prev: T[],
  key = 'id'
) => {
  const selectedList = [...selectedItems];
  const selectedIdList = selectedList.map((item) => item?.[key]);
  const prevList = [...prev];
  const prevListId = prevList.map((item) => item?.[key]);
  if (isEmpty(selectedList)) {
    items.forEach((item) => {
      if (prevListId.indexOf(item?.[key]) > -1) {
        prevList.splice(prevListId.indexOf(item?.[key]), 1);
        prevListId.splice(prevListId.indexOf(item?.[key]), 1);
      }
    });
    return [...prevList];
  } else if (selectedIdList.some((element) => prevListId.includes(element))) {
    prevList.splice(prevListId.indexOf(selectedIdList?.[0]), 1);
    prevListId.splice(prevListId.indexOf(selectedIdList?.[0]), 1);
    return [...prevList];
  }
  return [...prevList, ...selectedList];
};

export const sortByName = (sortingArray: Array<any> = [], field: string): Array<any> => {
  const newSortingArray = [...sortingArray];
  return newSortingArray.sort((previousValue, currentValue) => {
    const previous = previousValue[field].toUpperCase();
    const current = currentValue[field].toUpperCase();
    return previous < current ? -1 : previous > current ? 1 : 0;
  });
};

export const formatSSN = (ssn: string) =>
  !ssn ? '' : `${ssn.slice(0, 3)}-${ssn.slice(3, 6)}-${ssn.slice(6)}`;

export const arraySortByAnotherArray = (
  sortingArray: Array<any>,
  baseArray: Array<string>,
  field: string
): Array<any> => {
  const newSortingArray = [...sortingArray];
  return newSortingArray.sort(
    (previous, current) => baseArray.indexOf(previous[field]) - baseArray.indexOf(current[field])
  );
};

export const getLabel = (options: Option[], value: string): string =>
  options.find((item) => item.value === value)?.label;

export const getMultiLabel = (options: Option[], value: string): string =>
  value
    ?.split(',')
    .map((value) => getLabel(options, value))
    ?.join(', ');

export const kFormatter = (number: number) =>
  Math.abs(number) > 999
    ? `${((Math.sign(number) * Math.abs(number)) / 1000).toFixed(1)}K`
    : Math.sign(number) * Math.abs(number);

export const formatRoundUpDecimal = (number, decimalPlaces: number = 4) => {
  if (isEmpty(number)) return '';
  return Number(number).toFixed(decimalPlaces);
};

export const maxDateDefault = new Date(2100, 11, 31);

export const formatCaseNumber = (caseNumber: string) => {
  if (caseNumber) {
    return `${caseNumber.toString().charAt(0)}-${caseNumber.toString().substring(1, 5)}-${caseNumber
      .toString()
      .substring(5, 10)}${
      caseNumber.toString().substring(10) ? `-${caseNumber.toString().substring(10)}` : ''
    }`;
  }
  return '';
};

export const formatTDICaseNumber = (tdiCaseNumber: string) => {
  if (tdiCaseNumber) {
    return `${tdiCaseNumber.toString().charAt(0)}-${tdiCaseNumber
      .toString()
      .substring(1, 2)}-${tdiCaseNumber.toString().substring(2, 6)}-${tdiCaseNumber
      .toString()
      .substring(6, 11)}`;
  }

  return '';
};

export const getFullName = (Object: any) => {
  return [
    Object?.salutation,
    Object?.firstName,
    Object?.middleInitial,
    Object?.lastName,
    Object?.suffix,
  ]
    .filter((item) => item)
    .join(' ');
};

export const getFirstLastName = (object: any) => {
  return [object?.firstName, object?.lastName].filter((item) => item).join(' ');
};

export const capitalizeFirstLetter = (string) => {
  return string?.charAt(0).toUpperCase() + string?.slice(1).toLowerCase();
};

export const calculateDays = (startDate: Date, endDate: Date) => {
  const oneDay = 24 * 60 * 60 * 1000; // hours * minutes * seconds * milliseconds
  const diffDays = Math.round(Math.abs((startDate.getTime() - endDate.getTime()) / oneDay));
  return diffDays + 1;
};

export const removeEmptyProperties = (data) => {
  return Object.fromEntries(Object.entries(data).filter(([key, value]) => !isEmpty(value)));
};

export const toFixedWithoutRounding = (number: Number, decimalPlaces = 4) => {
  if (isEmpty(number)) {
    return '';
  }

  const isInteger = Number.isInteger(number);

  if (isInteger || decimalPlaces === 0) {
    return `${number}.${'0'.repeat(decimalPlaces)}`;
  }

  const numberString = number?.toString();
  const [integerPart, decimalPart] = numberString.split('.');

  if (!decimalPart || decimalPart.length <= decimalPlaces) {
    const paddedDecimalPart = decimalPart
      ? decimalPart + '0'.repeat(decimalPlaces - decimalPart.length)
      : '0'.repeat(decimalPlaces);
    return `${integerPart}.${paddedDecimalPart}`;
  }

  const truncatedDecimalPart = decimalPart.slice(0, decimalPlaces);

  return `${integerPart}.${truncatedDecimalPart}`;
};

export const formatPercentage = (value: number) => {
  return isEmpty(value) ? null : `${value} %`;
};

export const calculateWeeksInPeriod = (startDate, endDate): any => {
  if (isEmpty(startDate) || isEmpty(endDate)) {
    return '';
  }

  const roundedWeeks = calculateDays(new Date(startDate), new Date(endDate)) / 7;
  return toFixedWithoutRounding(roundedWeeks);
};

export const limitIntegerPartNumber = (value: number, maxIntegerLength: number) => {
  if (isEmpty(value)) return null;

  const [integerPart, decimalPart = '0'] = value.toString().split('.');

  if (integerPart.length <= maxIntegerLength) {
    return value;
  }

  return Number(`${integerPart.slice(0, maxIntegerLength)}.${decimalPart}`);
};

export const separateNumberByComma = (
  value: number | string,
  minimumFractionDigits = 0,
  maximumFractionDigits = 2
) => {
  if (isEmpty(value)) return '';

  return Number(value).toLocaleString('en-US', {
    minimumFractionDigits,
    maximumFractionDigits,
  });
};

export const formatSocialSecurityNumber = (ssn: string) => {
  if (!ssn) return '';

  if (ssn.length <= 2) return ssn;

  if (ssn.length <= 4) return `${ssn.slice(0, 3)}-${ssn.slice(3)}`;

  return `${ssn.slice(0, 3)}-${ssn.slice(3, 5)}-${ssn.slice(5)}`;
};

export const formatDOLNumber = (dolNumber: string) => {
  if (isEmpty(dolNumber)) {
    return '';
  }

  if (dolNumber?.length !== 10) return dolNumber;

  return [dolNumber?.slice(0, 3), dolNumber?.slice(3, 6), dolNumber?.slice(6)].join('-');
};
