import { PaginationResponseType, toRelatedAuditData } from '../helpers';
import { PPDInjuredBodyPartsKey } from './keys';
import { PPDInjuredBodyPartsType } from './types';

export const toPPDInjuredBodyParts = (data): PaginationResponseType<PPDInjuredBodyPartsType> => {
  return {
    ...data,
    data: data?.data.map((item) => ({
      ...item,
      [PPDInjuredBodyPartsKey.AwardWorksheetNumber]:
        item?.[PPDInjuredBodyPartsKey.AwardWorksheet]?.worksheetNumber,
      ...toRelatedAuditData(item),
    })),
  };
};
