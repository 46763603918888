export enum ApprovalHistoryKey {
  Id = 'id',
  StepName = 'stepName',
  Date = 'decisionAt',
  CaseId = 'caseId',
  Status = 'status',
  AssignedToId = 'assignedToGroupId',
  AssignedTo = 'assignedToGroupName',
  ActualApprover = 'approver',
  ActualApproverId = 'approverId',
  Comments = 'comment',
  CreatedBy = 'createdBy',
  CreatedAt = 'createdAt',
  CreatedUser = 'createdUser',
  FullName = 'fullName',
  FirstName = 'firstName',
  LastName = 'lastName',
  ObjectId = 'objectId',
  ObjectType = 'objectType',
  IsBeingRequested = 'isBeingRequested',
  DecisionId = 'decisionId',
  HearingRequestType = 'hearingRequestType',
}
