import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { Toastify } from 'src/services';
import apiClient from '../apiClient';
import { ApiResponseType, getResponseData, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { Systems } from './types';

export function useGetSystemPermissions(
  options?: UseQueryOptions<ApiResponseType<Systems>, Error, Systems> & {
    id: string;
  }
) {
  const {
    data,
    error,
    isError,
    isFetching,
    isLoading,
    refetch: onGetSystemPermissions,
  } = useQuery<ApiResponseType<Systems>, Error, Systems>(
    [API_QUERIES.SYSTEM_PERMISSIONS, { id: options.id }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<Systems>>(apiClient.getSystemPermissions, params);
      },
      select: getResponseData,
      onError: ({ message }) => Toastify.error(message),
      enabled: !!options.id,
      notifyOnChangeProps: ['data', 'isFetching'],
      keepPreviousData: true,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateSystemPermissions = () =>
    queryClient.invalidateQueries([API_QUERIES.SYSTEM_PERMISSIONS, { id: options?.id }]);

  const { profile, systemPermissions } = data || {};

  return {
    profile,
    systemPermissions,
    error,
    isError,
    isFetching,
    isLoading,
    onGetSystemPermissions,
    handleInvalidateSystemPermissions,
  };
}
