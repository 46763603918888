import { MdOutlineEdit } from 'react-icons/md';
import { RiDeleteBinLine } from 'react-icons/ri';
import { COLOR_CODE } from 'src/appConfig/constants';
import { Grid, LoadingCommon, Text, View } from 'src/modules/shared-main/components';
import {
  Filter,
  FilterKey,
  View as ViewType,
  useGetViewDetail,
  useUpdateView,
  Service,
} from 'src/modules/shared-main/queries';
import { formatDate } from 'src/utils';
import { isEmpty } from 'src/validations';
import {
  checkboxOptions,
  convertFromLogic,
  convertToLogic,
  operatorOptions,
  removeFilter,
} from '../helpers';
import { TableColumn, TableColumnType } from '../../helpers';
import SearchableValue from 'src/components/common/Table/SearchableValue';
import {
  formatDateTime,
  formatTime,
  getLabel,
  getLabelDisplay,
} from 'src/modules/shared-main/common';

const CustomFilterView: React.FC<Props> = ({
  values,
  onSetEditMode,
  fieldOptions,
  view,
  service,
}) => {
  const { filters, id, logic, object } = view || {};

  const selectedFieldType =
    fieldOptions.find(
      ({ name, filterKey }) => name === values?.field || filterKey === values?.field
    )?.type || TableColumnType.Text;

  const selectedSearchableFieldType = fieldOptions.find(
    ({ filterKey, name }) => name === values?.field || filterKey === values?.field
  )?.searchableSelectType;

  const selectedFieldOptions =
    fieldOptions.find(
      ({ name, filterKey }) => name === values?.field || filterKey === values?.field
    )?.option ?? checkboxOptions;

  const { handleInvalidateAllViewDetail } = useGetViewDetail({
    id,
    object,
    service,
  });

  const { updateView, isLoading } = useUpdateView({
    onSuccess: () => {
      handleInvalidateAllViewDetail();
    },
  });

  const handleDeleteFilter = () => {
    const newFilters = filters.filter(({ name }) => name !== values.name);
    updateView({
      ...view,
      service,
      filters: newFilters,
      logic: isEmpty(newFilters)
        ? {}
        : newFilters.length === 1
        ? { AND: [newFilters[0].name] }
        : convertToLogic(removeFilter(convertFromLogic(logic), values[FilterKey.Name], newFilters)),
    });
  };

  return (
    <Grid.Wrap>
      <Grid.Item variant="is-full">
        <View isRowWrap justify="space-between">
          <Text className="text-color-grey-900">{values[FilterKey.Name]}</Text>
          <View align="center" isRowWrap>
            <MdOutlineEdit
              className="cursor-pointer "
              onClick={onSetEditMode}
              color={COLOR_CODE.PRIMARY_DARK}
              size={20}
            />
            {isLoading ? (
              <LoadingCommon />
            ) : (
              <RiDeleteBinLine
                onClick={handleDeleteFilter}
                className="ml-8 cursor-pointer "
                size={20}
                color={COLOR_CODE.DANGER}
              />
            )}
          </View>
        </View>
      </Grid.Item>
      <Grid.Item variant="is-full">
        <View className="cmp-filter__view">
          <Grid.Wrap>
            <Grid.Item className="text-color-grey-900" variant="is-one-third">
              <Text>Field</Text>
            </Grid.Item>
            <Grid.Item variant="is-two-thirds">
              <Text className="cmp-filter__value">
                {
                  fieldOptions.find(
                    ({ name, filterKey }) =>
                      name === values[FilterKey.Field] || filterKey === values?.[FilterKey.Field]
                  )?.label
                }
              </Text>
            </Grid.Item>
            <Grid.Item className="text-color-grey-900" variant="is-one-third">
              <Text>Operator</Text>
            </Grid.Item>
            <Grid.Item variant="is-two-thirds">
              <Text className="cmp-filter__value">
                {getLabel(operatorOptions, values[FilterKey.Operator])}
              </Text>
            </Grid.Item>
            <Grid.Item className="text-color-grey-900" variant="is-one-third">
              <Text>Value</Text>
            </Grid.Item>
            <Grid.Item
              renderIf={
                selectedFieldType === TableColumnType.Text ||
                selectedFieldType === TableColumnType.Currency ||
                selectedFieldType === TableColumnType.PhoneNumber ||
                selectedFieldType === TableColumnType.Number
              }
              variant="is-two-thirds"
            >
              <Text className="cmp-filter__value">{values[FilterKey.Value]}</Text>
            </Grid.Item>
            <Grid.Item
              renderIf={
                selectedFieldType === TableColumnType.Checkbox ||
                selectedFieldType === TableColumnType.Select
              }
              variant="is-two-thirds"
            >
              <Text className="cmp-filter__value">
                {getLabelDisplay(selectedFieldOptions, values[FilterKey.Value] as Array<string>)}
              </Text>
            </Grid.Item>
            <Grid.Item
              renderIf={selectedFieldType === TableColumnType.Time}
              variant="is-two-thirds"
            >
              <Text className="cmp-filter__value">
                {formatTime(values[FilterKey.Value] as string)}
              </Text>
            </Grid.Item>
            <Grid.Item
              renderIf={selectedFieldType === TableColumnType.Date}
              variant="is-two-thirds"
            >
              <Text className="cmp-filter__value">
                {formatDate(values[FilterKey.Value] as string)}
              </Text>
            </Grid.Item>
            <Grid.Item
              renderIf={selectedFieldType === TableColumnType.DateTime}
              variant="is-two-thirds"
            >
              <Text className="cmp-filter__value">
                {formatDateTime(values[FilterKey.Value] as string)}
              </Text>
            </Grid.Item>
            <Grid.Item
              renderIf={selectedFieldType === TableColumnType.SearchableSelect}
              variant="is-two-thirds"
            >
              <SearchableValue
                values={
                  Array.isArray(values[FilterKey.Value])
                    ? values[FilterKey.Value]
                    : values[FilterKey.Value].split(',')
                }
                type={selectedSearchableFieldType}
              />
            </Grid.Item>
          </Grid.Wrap>
        </View>
      </Grid.Item>
    </Grid.Wrap>
  );
};

type Props = {
  values: Filter;
  onSetEditMode: () => void;
  fieldOptions: TableColumn[];
  view: ViewType;
  service?: Service;
};

export default CustomFilterView;
