import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { ApiResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { toDependent } from './helpers';
import { AwwDependent } from './types';

export function useGetDependent(
  options?: UseQueryOptions<ApiResponseType<AwwDependent>, Error, AwwDependent> & {
    id: string;
  }
) {
  const {
    data: dependent,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetDependent,
  } = useQuery<ApiResponseType<any>, Error, AwwDependent>(
    [API_QUERIES.DEPENDENTS, { id: options?.id }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<AwwDependent>>(apiClient.getDependent, params);
      },
      select: ({ data }) => toDependent(data.awardWorksheetDeathDependent),
      enabled: !!options?.id,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateDependent = () =>
    queryClient.invalidateQueries([API_QUERIES.DEPENDENTS, { id: options?.id }]);

  return {
    dependent,
    error,
    isError,
    isLoading,
    onGetDependent,
    handleInvalidateDependent,
  };
}
