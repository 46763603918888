import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from '../apiClient';
import { authResponseWrapper } from '../helpers';
import { CloneProfilePayload } from './types';

export function useEditProfile(
  options?: UseMutationOptions<CloneProfilePayload, Error, CloneProfilePayload>
) {
  const { mutate: editProfile, isLoading: isLoadingEditProfile } = useMutation<
    CloneProfilePayload,
    Error,
    CloneProfilePayload
  >({
    mutationFn: (payload: CloneProfilePayload) =>
      authResponseWrapper(apiClient.editProfile, [payload]),
    ...options,
  });

  return {
    editProfile,
    isLoadingEditProfile,
  };
}
