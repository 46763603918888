import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { createResponseWrapper } from 'src/queries/helpers';
import { ErrorService } from 'src/services';
import { HistoricalFormValue } from './types';

export function useCreateHistoricalWC3(
  options?: UseMutationOptions<any, Error, HistoricalFormValue>
) {
  const { mutate: createHistoricalWC3, isLoading } = useMutation<any, Error, HistoricalFormValue>({
    mutationFn: (payload: HistoricalFormValue) =>
      createResponseWrapper(apiClient.createWC3Historical, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    createHistoricalWC3,
    isLoading,
  };
}
