import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { PaginationResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { GetPropertiesParams } from '../types';
import { toPortalUserAuditHistories } from './helpers';
import { PortalUserAuditHistory } from './types';

export function useGetAllPortalUserAuditHistories(
  options?: UseQueryOptions<PaginationResponseType<PortalUserAuditHistory>, Error> & {
    [key: string]: string | number | string[];
  }
) {
  const [params, setParams] = useState<GetPropertiesParams>({});

  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetAllPortalUserAuditHistories,
  } = useQuery<PaginationResponseType<PortalUserAuditHistory>, Error>(
    [API_QUERIES.PORTAL_USERS_AUDIT_HISTORY, { ...params, ...options }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<PaginationResponseType<PortalUserAuditHistory>>(
          apiClient.getAllPortalUserAuditHistories,
          params
        );
      },
      notifyOnChangeProps: ['data', 'isFetching'],
      keepPreviousData: true,
      select: toPortalUserAuditHistories,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateAllPortalUserAuditHistories = () =>
    queryClient.invalidateQueries(API_QUERIES.PORTAL_USERS_AUDIT_HISTORY);

  const { data: portalUserAuditHistories, hasNext, payloadSize, totalRecords } = data || {};

  return {
    portalUserAuditHistories,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isError,
    isFetching,
    onGetAllPortalUserAuditHistories,
    setParams,
    handleInvalidateAllPortalUserAuditHistories,
  };
}
