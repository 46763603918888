import { ReactNode } from 'react';

import { PATHS } from './paths';

import {
  BankFilled,
  CalculatorFilled,
  ContainerFilled,
  FileExclamationFilled,
  FileFilled,
  FileUnknownFilled,
  FlagFilled,
  FolderOpenFilled,
  HomeFilled,
  IdcardFilled,
  SafetyCertificateFilled,
  ScheduleFilled,
  SecurityScanFilled,
  SettingFilled,
  SignalFilled,
  WalletFilled,
} from '@ant-design/icons';

import { MdSwitchAccount } from 'react-icons/md';

import { ReactComponent as DollarCircleFilled } from 'src/assets/images/icons/ant-design/dollar-circle.svg';
import { ReactComponent as ForkCircleFilled } from 'src/assets/images/icons/ant-design/fork.svg';
import { ReactComponent as PeopleCircleFilled } from 'src/assets/images/icons/ant-design/people.svg';
import { AppName } from './constants';
import { AiFillDatabase } from 'react-icons/ai';

export const isActive = (href: string) => {
  return window.location.pathname === href;
};

export type MenuItem = {
  title: string;
  subTitle?: string;
  icon?: ReactNode;
  subMenu?: MenuItem[];
  href?: string;
  apps?: AppName[];
  isHidden?: boolean;
};

export const sidebarList: MenuItem[] = [
  {
    title: 'Administration',
    icon: <SettingFilled rev={undefined} />,
    apps: [AppName.Admin],
    subMenu: [
      {
        title: 'Users',
        href: PATHS.userManagements,
        apps: [AppName.Admin],
      },
      {
        title: 'Profiles',
        href: PATHS.profiles,
        apps: [AppName.Admin],
      },
      {
        title: 'Groups',
        href: PATHS.groups,
        apps: [AppName.Admin],
      },
    ],
  },
  {
    title: 'Custom Settings',
    icon: <SettingFilled rev={undefined} />,
    apps: [AppName.Admin],
    href: PATHS.customSettings,
  },
  {
    title: 'Home',
    icon: <HomeFilled rev={undefined} />,
    href: PATHS.dashboard,
    apps: [
      AppName.DCDEcms,
      AppName.TDI,
      AppName.WCCoverage,
      AppName.TDICoverage,
      AppName.PHCCoverage,
      AppName.Investigations,
    ],
  },
  {
    title: 'Accounts',
    icon: <PeopleCircleFilled />,
    href: PATHS.accounts,
    apps: [
      AppName.DCDEcms,
      AppName.TDI,
      AppName.WCCoverage,
      AppName.TDICoverage,
      AppName.PHCCoverage,
      AppName.Investigations,
    ],
  },
  {
    title: 'Contacts',
    icon: <IdcardFilled rev={undefined} />,
    href: PATHS.contacts,
    apps: [
      AppName.DCDEcms,
      AppName.TDI,
      AppName.WCCoverage,
      AppName.TDICoverage,
      AppName.PHCCoverage,
    ],
  },
  {
    title: 'Health Care Plans',
    icon: <ForkCircleFilled />,
    apps: [AppName.PHCCoverage],
    href: PATHS.healthCarePlans,
  },
  {
    title: 'WC Insurance Policies',
    icon: <SafetyCertificateFilled rev={undefined} />,
    href: PATHS.wcInsurancePolicies,
    apps: [AppName.WCCoverage],
  },
  {
    title: 'DCD Cases',
    icon: <FolderOpenFilled rev={undefined} />,
    href: PATHS.dcdCases,
    apps: [AppName.DCDEcms],
    subMenu: [
      {
        title: 'DCD Case Approval History',
        href: PATHS.relatedApprovalHistories,
        isHidden: true,
      },
      {
        title: 'Prehearings for Delinquent Employer',
        href: PATHS.prehearingForDelinquentDetail,
        isHidden: true,
      },
      {
        title: 'Vocational Rehabilitation Processes',
        href: PATHS.detailVocationalRehabilitation,
        isHidden: true,
      },
      {
        title: 'Case Settlement Approval History',
        href: PATHS.relatedCaseSettlementApprovalHistory,
        isHidden: true,
      },
      {
        title: 'Case Settlements',
        href: PATHS.caseSettlementDetails,
        isHidden: true,
      },
      {
        title: 'Orders',
        href: PATHS.orderDetail,
        isHidden: true,
      },
      {
        title: 'Order Approval History',
        href: PATHS.ordersApprovalHistoryDetail,
        isHidden: true,
      },
      {
        title: 'PHC Coverage',
        href: PATHS.phcCoverageDetail,
        isHidden: true,
      },
    ],
  },
  {
    title: 'TDI Cases',
    icon: <FolderOpenFilled rev={undefined} />,
    apps: [AppName.TDI],
    href: PATHS.tdiCases,
  },
  {
    title: 'TDI Plans',
    icon: <FlagFilled rev={undefined} />,
    apps: [AppName.TDICoverage],
    href: PATHS.tdiPlans,
  },
  {
    title: 'Hearings',
    icon: <BankFilled rev={undefined} />,
    href: PATHS.hearings,
    apps: [AppName.DCDEcms, AppName.TDI],
    subMenu: [
      {
        title: 'Hearing Approval History',
        href: PATHS.approvalHistoryHearingDetails,
        isHidden: true,
      },
    ],
  },
  {
    title: 'TDI Special Fund Cases',
    icon: <FolderOpenFilled rev={undefined} />,
    href: PATHS.tdiSFCases,
    apps: [AppName.TDI],
  },
  {
    title: 'Decisions',
    icon: <ScheduleFilled rev={undefined} />,
    href: PATHS.decisions,
    apps: [AppName.DCDEcms, AppName.TDI],
    subMenu: [
      {
        title: 'Decision Approval History',
        href: PATHS.decisionApprovalHistoryDetail,
        isHidden: true,
      },
    ],
  },
  {
    title: 'Award Worksheets',
    icon: <ContainerFilled rev={undefined} />,
    href: PATHS.awardWorksheets,
    apps: [AppName.DCDEcms],
  },
  {
    title: 'Requests',
    icon: <FileUnknownFilled rev={undefined} />,
    href: PATHS.requests,
    apps: [AppName.DCDEcms],
  },
  {
    title: 'Disbursements',
    icon: <DollarCircleFilled />,
    href: PATHS.disbursements,
    apps: [AppName.DCDEcms, AppName.TDI, AppName.PHCCoverage],
    subMenu: [
      {
        title: 'Disbursement Approval History',
        href: PATHS.disbursementApprovalHistoryDetail,
        isHidden: true,
      },
    ],
  },
  {
    title: 'Receipts',
    icon: <WalletFilled rev={undefined} />,
    href: PATHS.receipts,
    apps: [AppName.DCDEcms, AppName.TDI, AppName.PHCCoverage, AppName.Investigations],
    subMenu: [
      {
        title: 'Receipt Approval History',
        href: PATHS.receiptApprovalHistoryDetail,
        isHidden: true,
      },
    ],
  },
  {
    title: 'GL Accounts',
    icon: <CalculatorFilled rev={undefined} />,
    href: PATHS.glAccounts,
    apps: [AppName.DCDEcms],
  },
  {
    title: 'Employer Audits',
    href: PATHS.employerAudits,
    icon: <SecurityScanFilled rev={undefined} />,
    apps: [AppName.DCDEcms],
  },
  {
    title: 'Complaints',
    icon: <FileExclamationFilled rev={undefined} />,
    apps: [AppName.DCDEcms, AppName.Investigations],
    subMenu: [
      {
        title: 'Fraud Complaints',
        href: PATHS.fraudComplaints,
        apps: [AppName.DCDEcms],
      },
      {
        title: 'PHC/TDI Complaints',
        href: PATHS.phcTDIComplaints,
        apps: [AppName.DCDEcms, AppName.Investigations],
      },
    ],
  },
  {
    title: 'Prehearings',
    icon: <BankFilled rev={undefined} />,
    apps: [AppName.Investigations],
  },
  {
    title: 'Forms',
    icon: <FileFilled rev={undefined} />,
    apps: [AppName.DCDEcms, AppName.TDI, AppName.Forms, AppName.TDICoverage, AppName.PHCCoverage],
    subMenu: [
      {
        title: 'WC-1s',
        href: PATHS.wc1,
        apps: [AppName.DCDEcms, AppName.Forms],
      },
      {
        title: 'WC-2s',
        href: PATHS.wc2,
        apps: [AppName.DCDEcms, AppName.Forms],
      },
      {
        title: 'WC-3s',
        href: PATHS.wc3,
        apps: [AppName.DCDEcms, AppName.Forms],
      },
      {
        title: 'WC-3As',
        href: PATHS.wc3a,
        apps: [AppName.DCDEcms, AppName.Forms],
      },
      {
        title: 'WC-5s',
        href: PATHS.wc5,
        apps: [AppName.DCDEcms, AppName.Forms],
      },
      {
        title: 'WC-5As',
        href: PATHS.wc5a,
        apps: [AppName.DCDEcms, AppName.Forms],
      },
      {
        title: 'WC-14s',
        href: PATHS.wc14s,
        apps: [AppName.DCDEcms, AppName.Forms],
      },
      {
        title: 'WC-77s',
        href: PATHS.wc77s,
        apps: [AppName.DCDEcms, AppName.Forms],
      },
      {
        title: 'WC-21s',
        href: PATHS.wc21s,
        apps: [AppName.DCDEcms, AppName.Forms],
      },
      {
        title: 'TDI-62s',
        href: PATHS.tdi62,
        apps: [AppName.TDICoverage, AppName.Forms],
      },
      {
        title: 'TDI-46s',
        href: PATHS.tdi46s,
        apps: [AppName.TDI, AppName.Forms],
      },
      {
        title: 'TDI-15s',
        href: PATHS.tdi15,
        apps: [AppName.TDICoverage, AppName.Forms],
      },
      {
        title: 'TDI-30s',
        href: PATHS.tdi30s,
        apps: [AppName.TDI, AppName.Forms],
      },
      {
        title: 'TDI-21s',
        href: PATHS.tdi21s,
        apps: [AppName.TDICoverage, AppName.Forms],
      },
      {
        title: 'HC-4s',
        href: PATHS.hc4s,
        apps: [AppName.PHCCoverage, AppName.Forms],
      },
      {
        title: 'HC-15s',
        href: PATHS.hc15s,
        apps: [AppName.PHCCoverage, AppName.Forms],
      },
      {
        title: 'HC-61s',
        href: PATHS.hc61s,
        apps: [AppName.PHCCoverage, AppName.Forms],
      },
    ],
  },
  {
    title: 'Reports',
    icon: <SignalFilled rev={undefined} />,
    href: PATHS.reports,
    apps: [
      AppName.DCDEcms,
      AppName.TDI,
      AppName.WCCoverage,
      AppName.TDICoverage,
      AppName.PHCCoverage,
      AppName.Investigations,
    ],
  },
  {
    title: 'Portal User',
    icon: <MdSwitchAccount />,
    href: PATHS.portalUsers,
    apps: [
      AppName.DCDEcms,
      AppName.TDI,
      AppName.WCCoverage,
      AppName.TDICoverage,
      AppName.PHCCoverage,
    ],
  },
  {
    title: 'File Manager',
    icon: <AiFillDatabase />,
    href: PATHS.uploadFiles,
    apps: [
      AppName.DCDEcms,
      AppName.TDI,
      AppName.WCCoverage,
      AppName.TDICoverage,
      AppName.PHCCoverage,
    ],
  },
];

export type ModuleType = {
  label?: string;
  value: AppName;
};

export const appOptions: ModuleType[] = [
  {
    label: 'DCD eCMS',
    value: AppName.DCDEcms,
  },
  {
    label: 'TDI Cases',
    value: AppName.TDI,
  },
  {
    label: 'WC Coverage',
    value: AppName.WCCoverage,
  },
  {
    label: 'TDI Coverage',
    value: AppName.TDICoverage,
  },
  {
    label: 'PHC Coverage',
    value: AppName.PHCCoverage,
  },
  {
    label: 'Forms',
    value: AppName.Forms,
  },
  {
    label: 'Investigations',
    value: AppName.Investigations,
  },
];
