import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { PaginationResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { CoverageEquivalency } from '../TdiPlans';

export function useGetCoverageEquivalency(
  options?: UseQueryOptions<PaginationResponseType<Array<CoverageEquivalency>>, Error, any> & {
    tdi62Id: string;
    tdiPlanId?: string | null;
  }
) {
  const {
    data: coverageEquivalency,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetCoverageEquivalency,
  } = useQuery<PaginationResponseType<Array<CoverageEquivalency>>, Error, any>(
    [API_QUERIES.COVERAGE_EQUIVALENCY, { ...options }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<PaginationResponseType<Array<CoverageEquivalency>>>(
          apiClient.getCoverageEquivalency,
          params
        );
      },
      select: ({ data }) => data,
      enabled: !!options.tdi62Id,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateCoverageEquivalency = () =>
    queryClient.invalidateQueries([
      API_QUERIES.COVERAGE_EQUIVALENCY,
      { tdi62Id: options.tdi62Id, tdiPlanId: options.tdiPlanId },
    ]);

  return {
    coverageEquivalency,
    error,
    isError,
    isLoading,
    onGetCoverageEquivalency,
    handleInvalidateCoverageEquivalency,
  };
}
