import { getFirstLastName, getFullName } from 'src/utils';
import { getClaimantId, getClaimantInfo } from '../RelatedHearings/helpers';
import { PaginationResponseType, toAuditData } from '../helpers';
import { HearingKey, ScheduleAvailabilityKey } from './keys';
import { Hearing, ParticipantType, TimeSlotStatus } from './types';

export const toAllHearings = (data): PaginationResponseType<Hearing> => {
  return {
    ...data,
    data: data?.data.map((item) => ({
      ...item,
      [HearingKey.HearingOfficer]: item?.hearingOfficerId
        ? `${item?.hearingOfficer?.firstName} ${item?.hearingOfficer?.lastName}`
        : '',
      [HearingKey.HearingReviewer]: item?.hearingReviewerId
        ? `${item?.hearingReviewer?.firstName} ${item?.hearingReviewer?.lastName}`
        : '',
      [HearingKey.HearingScheduler]: item?.hearingSchedulerId
        ? `${item?.hearingScheduler?.firstName} ${item?.hearingScheduler?.lastName}`
        : '',
      [HearingKey.HearingFacilitator]: item?.hearingFacilitatorId
        ? `${item?.hearingFacilitator?.firstName} ${item?.hearingFacilitator?.lastName}`
        : '',
      [HearingKey.ClericalService]: item?.clericalService
        ? `${item?.clericalService?.firstName} ${item?.clericalService?.lastName}`
        : '',
      [HearingKey.RefereeManager]: item?.refereeManagerId
        ? `${item?.refereeManager?.firstName} ${item?.refereeManager?.lastName}`
        : null,
      [HearingKey.DateOfII]: item?.dateOfInjury ? item?.dateOfInjury : '',
      [HearingKey.CaseNumber]: item?.caseId ? item?.case?.caseNumber : '',
      [HearingKey.EmployerId]: item?.caseId ? item?.case?.employerId : '',
      [HearingKey.OriginalLocation]: item?.caseId ? item?.case?.originalLocation : '',
      [HearingKey.CurrentServicingLocation]: item?.caseId
        ? item?.case?.currentServicingLocation
        : '',
      [HearingKey.MinimumUserPosition]: item?.minimumOfficerPosition,
      [HearingKey.Room]: item?.roomId ? item?.room?.roomName : '',
      [HearingKey.ClaimantId]: getClaimantId(item),
      [HearingKey.ClaimantName]: getClaimantInfo(item, HearingKey.ClaimantName),
      [HearingKey.ClaimantSSN]: getClaimantInfo(item, HearingKey.ClaimantSSN),
      [HearingKey.RefereeUser]: item?.referee ? getFullName(item?.referee) : '',
      [HearingKey.TDICaseNumber]: item?.tdiCase?.tdiCaseNumber,
      [HearingKey.TDISFCaseNumber]: item?.tdiSFCase?.tdiSFCaseNumber,
    })),
    ...toAuditData(data),
  };
};

export const toHearing = (item) => {
  return {
    ...item,
    [HearingKey.HearingOfficer]: item?.hearingOfficerId
      ? `${item?.hearingOfficer?.firstName} ${item?.hearingOfficer?.lastName}`
      : '',
    [HearingKey.HearingReviewer]: item?.hearingReviewerId
      ? `${item?.hearingReviewer?.firstName} ${item?.hearingReviewer?.lastName}`
      : '',
    [HearingKey.HearingScheduler]: item?.hearingSchedulerId
      ? `${item?.hearingScheduler?.firstName} ${item?.hearingScheduler?.lastName}`
      : '',
    [HearingKey.HearingFacilitator]: item?.hearingFacilitatorId
      ? `${item?.hearingFacilitator?.firstName} ${item?.hearingFacilitator?.lastName}`
      : '',
    [HearingKey.ClericalService]: item?.clericalService
      ? `${item?.clericalService?.firstName} ${item?.clericalService?.lastName}`
      : '',
    [HearingKey.RefereeManager]: item?.refereeManagerId
      ? `${item?.refereeManager?.firstName} ${item?.refereeManager?.lastName}`
      : '',
    [HearingKey.CreatedUser]: item?.createdUser
      ? `${item?.createdUser?.firstName} ${item?.createdUser?.lastName}`
      : '',
    [HearingKey.UpdatedUser]: item?.updatedUser
      ? `${item?.updatedUser?.firstName || ''} ${item?.updatedUser?.lastName || ''}`
      : '',
    [HearingKey.DateOfII]: item?.dateOfInjury ? item?.dateOfInjury : '',
    [HearingKey.CaseNumber]: item?.caseId ? item?.case?.caseNumber : '',
    [HearingKey.EmployerId]: item?.caseId ? item?.case?.employerId : '',
    [HearingKey.OriginalLocation]: item?.caseId ? item?.case?.originalLocation : '',
    [HearingKey.CurrentServicingLocation]: item?.caseId ? item?.case?.currentServicingLocation : '',
    [HearingKey.Room]: item?.roomId ? item?.room?.roomName : '',
    [HearingKey.ClaimantId]: getClaimantId(item),
    [HearingKey.ClaimantName]: getClaimantInfo(item, HearingKey.ClaimantName),
    [HearingKey.ClaimantSSN]: getClaimantInfo(item, HearingKey.ClaimantSSN),
    [HearingKey.WC77FormNumber]: item?.wc77Obj?.formNumber,
    [HearingKey.WC77Id]: item?.wc77Obj?.id,

    // TDI Hearing
    [HearingKey.RefereeUser]: item?.refereeId ? getFirstLastName(item?.referee) : '',
    [HearingKey.TDICaseNumber]: item?.tdiCase?.tdiCaseNumber,
    [HearingKey.TDISFCaseNumber]: item?.tdiSFCase?.tdiSFCaseNumber,
    [HearingKey.MinimumUserPosition]: item?.minimumOfficerPosition,
    [HearingKey.DisabilityDate]: item?.tdiSFCase?.disabilityDate,
    [HearingKey.FirstDateOfDisabilityClaimed]: item?.tdiCase?.firstDateOfDisabilityClaimed,
    [HearingKey.CurrentReferee]: item?.referee ? getFullName(item.referee) : '',
  };
};

const formatParticipant = (participant) => ({
  id: participant.id,
  name: participant.name,
  availability: participant.availability.map((avail) => {
    return {
      time: avail.time,
      status: avail.isAvailable ? TimeSlotStatus.Available : TimeSlotStatus.Booked,
    };
  }),
});

export const toScheduleAvailability = (data) => {
  const officer = data?.find(
    (participant) =>
      participant.type === ParticipantType.HearingOfficer ||
      participant.type === ParticipantType.RefereeUser
  );
  const room = data?.find((participant) => participant.type === ParticipantType.Room);
  const representatives = data?.filter(
    (participant) => participant.type === ParticipantType.Representative
  );
  return {
    [ScheduleAvailabilityKey.Officer]: officer ? formatParticipant(officer) : null,
    [ScheduleAvailabilityKey.Room]: room ? formatParticipant(room) : null,
    [ScheduleAvailabilityKey.Representatives]: representatives.map((rep) => formatParticipant(rep)),
  };
};
