import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { createResponseWrapper } from '../helpers';
import { FinalReportPayload } from './types';

export function useCreateFinalReport(
  options?: UseMutationOptions<{ id: string }, Error, FinalReportPayload>
) {
  const { mutate: createFinalReport, isLoading } = useMutation<
    { id: string },
    Error,
    FinalReportPayload
  >({
    mutationFn: (payload: FinalReportPayload) =>
      createResponseWrapper(apiClient.createFinalReport, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    createFinalReport,
    isLoading,
  };
}
