import { PHCCoverageKey } from './keys';

export interface PHCCoverage {
  [PHCCoverageKey.Id]: string;
  [PHCCoverageKey.CoverageNumber]: string;
  [PHCCoverageKey.ActionTaken]: string;
  [PHCCoverageKey.EffectiveDate]: string;
  [PHCCoverageKey.HCPlanType]: string;
  [PHCCoverageKey.HCPlanName]: string;
  [PHCCoverageKey.HCPlanId]: string;
  [PHCCoverageKey.EmployerId]: string;
  [PHCCoverageKey.EmployerDOLNUmber]: string;
  [PHCCoverageKey.HealthCareContractor]: string;
  [PHCCoverageKey.HealthCareContractorId]: string;
  [PHCCoverageKey.EmployerName]: string;
  [PHCCoverageKey.DOLNumber]: string;
  [PHCCoverageKey.RawPlanGroupName]?: string;
  [PHCCoverageKey.StatusEffectiveDate]: string;
  [PHCCoverageKey.ContractorId]: string;
  [PHCCoverageKey.Status]: PHCCoverageStatus;
  [PHCCoverageKey.StatusDate]: string;
  [PHCCoverageKey.GroupNumber]: string;
  [PHCCoverageKey.EmployeeClass]: string;
  [PHCCoverageKey.NewAction]?: string;

  [PHCCoverageKey.CreatedBy]: string;
  [PHCCoverageKey.CreatedAt]: string;
  [PHCCoverageKey.CreatedUser]: string;
  [PHCCoverageKey.UpdatedAt]: string;
  [PHCCoverageKey.UpdatedBy]: string;
  [PHCCoverageKey.UpdatedUser]: string;
}

export enum PHCCoverageStatus {
  Active = 'ACTIVE',
  Canceled = 'CANCELED',
  Exemption = 'EXEMPTION',
  Expired = 'EXPIRED',
  Reinstate = 'REINSTATE',
}

export interface PHCCoveragePayload {
  [PHCCoverageKey.Id]: string;
  [PHCCoverageKey.HCPlanType]: string;
  [PHCCoverageKey.HCPlanId]: string;
  [PHCCoverageKey.EmployerId]: string;
  [PHCCoverageKey.HealthCareContractor]: string;
  [PHCCoverageKey.DOLNumber]: string;
  [PHCCoverageKey.ContractorId]: string;
  [PHCCoverageKey.Status]: PHCCoverageStatus;
  [PHCCoverageKey.StatusDate]: string;
  [PHCCoverageKey.GroupNumber]: string;
  [PHCCoverageKey.EmployeeClass]: string;
}

export enum PHCActionType {
  NEW_ACTION = 'NEW_ACTION',
  NEW_OTHER_ACTION = 'NEW_OTHER_ACTION',
}

export enum ActionTakenType {
  Canceled = 'Canceled',
  Intent = 'Intent to Cancel',
  NewlyEnrolled = 'Newly Enrolled',
}

export enum HCPType {
  EMPLOYER_SPONSORED = 'EMPLOYER_SPONSORED',
  INSURED_PLAN = 'INSURED_PLAN',
  SELF_INSURED = 'SELF_INSURED',
}

export const VALUE_CONTRACTOR_ID_SI = '0000';
