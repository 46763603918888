import { useCallback, useEffect, useRef, useState } from 'react';
import Link from '../Link';
import { Navigator } from 'src/services';
import View from '../View';

import './styles.scss';

const TruncateLink: React.FC<Props> = ({ value, to, isHighlight = false }) => {
  const viewRef = useRef(null);
  const viewWidth = useRef(0);

  const [contentState, setContentState] = useState<string>('');
  const [isShowMore, setIsShowMore] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const handleSubString = useCallback(() => {
    let width = viewWidth.current;
    isHighlight && (width += 20);
    const length = value.toString().length;

    if (length > width / 10) {
      const content = value.toString().substring(0, width / 10 - 5);
      setIsShowMore(true);
      setIsExpanded(false);
      setContentState(`${content}...`);
    } else {
      setIsShowMore(false);
      setIsExpanded(false);
      setContentState(value.toString());
    }
  }, [value, isHighlight]);

  useEffect(() => {
    const view = viewRef?.current;

    const observer = new ResizeObserver((entries) => {
      if (value && typeof value === 'string') {
        const width = entries[0]?.borderBoxSize?.[0].inlineSize;
        if (typeof width === 'number' && width !== viewWidth.current) {
          viewWidth.current = width;
          handleSubString();
        }
      }
    });

    if (view) {
      observer.observe(view);
    }

    return () => {
      observer.disconnect();
    };
  }, [value, handleSubString]);

  const handleShowMore = (e) => {
    e.preventDefault();
    setContentState('' + value);
    setIsExpanded(true);
  };

  const handleShowLess = (e) => {
    e.preventDefault();
    handleSubString();
    setIsExpanded(false);
  };

  const renderActionShow = () => {
    switch (isExpanded) {
      case true:
        return (
          <Link onClick={handleShowLess} className="cmp-view-item__value--actions text-italic">
            Show less
          </Link>
        );
      default:
        return (
          <Link onClick={handleShowMore} className="cmp-view-item__value--actions text-italic">
            Show more
          </Link>
        );
    }
  };

  const renderContent = () => {
    switch (isShowMore) {
      case true:
        return (
          <>
            <Link onClick={() => Navigator.navigate(to)}>{contentState || '--'}</Link>
            {renderActionShow()}
          </>
        );
      default:
        return value ? <Link onClick={() => Navigator.navigate(to)}>{value}</Link> : '--';
    }
  };

  return (
    <View className="cmp-truncate-link" forwardRef={viewRef} fullWidth>
      {renderContent()}
    </View>
  );
};

type Props = {
  value: string;
  to: string;
  isHighlight?: boolean;
};

export default TruncateLink;
