import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { AppName } from 'src/appConfig/constants';
import { Tag, ViewObject } from 'src/queries';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { ApiResponseType } from '../types';
import { toViewOptions } from './helpers';
import { Service, Views, ViewsOption } from './types';

export function useGetViews(
  options?: UseQueryOptions<ApiResponseType<{ views: Views[] }>, Error, ViewsOption[]> & {
    object: ViewObject;
    appName?: AppName;
    tags?: Tag;
    service?: Service;
  }
) {
  const {
    data: views,
    error,
    isError,
    isSuccess,
    isFetching: isLoading,
    refetch: onGetViews,
  } = useQuery<ApiResponseType<{ views: Views[] }>, Error, ViewsOption[]>(
    [
      API_QUERIES.Views,
      {
        object: options.object,
        service: options?.service || Service.CaseSvc,
        appName: options?.appName,
        tags: options?.tags,
      },
    ],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<{ views: Views[] }>>(apiClient.getViews, params);
      },
      enabled: !!options.object,
      select: ({ data }) => toViewOptions(data.views),
      keepPreviousData: true,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateViews = () =>
    queryClient.invalidateQueries([API_QUERIES.Views, { object: options.object }]);

  return {
    views,
    error,
    isError,
    isLoading,
    onGetViews,
    handleInvalidateViews,
    isSuccess,
  };
}
