import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { authResponseWrapper } from 'src/queries/helpers';
import { Toastify } from 'src/services';

export function useDeleteWC77(options?: UseMutationOptions<string, Error, string>) {
  const { mutate: onDeleteWC77, isLoading } = useMutation<string, Error, string>({
    mutationFn: (payload: string) => authResponseWrapper(apiClient.deleteWC77, [payload]),
    onError: ({ message }) => Toastify.error(message),
    ...options,
  });

  return {
    onDeleteWC77,
    isLoading,
  };
}
