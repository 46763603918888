import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { authResponseWrapper } from 'src/queries/helpers';
import { ErrorService } from 'src/services';
import { CaseSettlementsTypes } from './types';

export function useDeleteCaseSettlement(
  options?: UseMutationOptions<any, Error, CaseSettlementsTypes['id']>
) {
  const { mutate: deleteCaseSettlement, isLoading } = useMutation<
    any,
    Error,
    CaseSettlementsTypes['id']
  >({
    mutationFn: (payload: CaseSettlementsTypes['id']) =>
      authResponseWrapper(apiClient.deleteCaseSettlement, [payload]),
    ...options,
    onError: (error) => ErrorService.handler(error),
  });

  return {
    deleteCaseSettlement,
    isLoading,
  };
}
