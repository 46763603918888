import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { authResponseWrapper } from 'src/queries/helpers';
import { Toastify } from 'src/services';

export function useDeleteWC1Form(options?: UseMutationOptions<string, Error, string>) {
  const { mutate: deleteWC1Form, isLoading } = useMutation<string, Error, string>({
    mutationFn: (payload: string) => authResponseWrapper(apiClient.deleteWC1Form, [payload]),
    onError: ({ message }) => Toastify.error(message),
    ...options,
  });

  return {
    deleteWC1Form,
    isLoading,
  };
}
