export enum EmployerRegistrationActivitiesKey {
  StartDate = 'startDate',
  EndDate = 'endDate',
  CreatedDate = 'createdDate',
  AgricultureDOLNumber = 'agricultureDOLNumber',
  ChurchDOLNumber = 'churchDOLNumber',
  OtherDOLNumber = 'otherDOLNumber',
  TotalAgricultureDOLNumber = 'totalAgricultureDOLNumber',
  TotalChurchDOLNumber = 'totalChurchDOLNumber',
  TotalOtherDOLNumber = 'totalOtherDOLNumber',
  DOLType = 'dolType',

  // Detail
  AccountName = 'accountName',
  AccountRecordType = 'recordType',
  AccountType = 'accountType',
  AccountDOLNumber = 'laborNumber',
  AccountCreatedDate = 'createdAt',
  AccountCreatedBy = 'createdBy',
  AccountCreatedByName = 'createdByName',
}

export enum DOLTypeEmployerRegistrationActivities {
  Agriculture = 'Agriculture',
  Church = 'Church',
  Other = 'Other',
}
