import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { createResponseWrapper } from '../helpers';
import { PartyNameAndAddressType } from './types';

export function useCreatePartyNamesAddress(
  options?: UseMutationOptions<any, Error, PartyNameAndAddressType>
) {
  const { mutate: createRelatedPartyNamesAddress, isLoading } = useMutation<
    any,
    Error,
    PartyNameAndAddressType
  >({
    mutationFn: (payload: PartyNameAndAddressType) =>
      createResponseWrapper(apiClient.createRelatedPartyNamesAddress, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    createRelatedPartyNamesAddress,
    isLoading,
  };
}
