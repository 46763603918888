import { useMutation, UseMutationOptions } from 'react-query';
import { compressFile } from 'src/utils';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { GetPresignedAvatarPayload, UploadAvatarPayload } from './types';
import { useUploadFileToS3 } from './useUploadFileToS3';

export function useUploadAvatar(
  options?: UseMutationOptions<any, Error, File> & {
    onUploadSuccess: (data?: any, variables?: UploadAvatarPayload, context?: unknown) => void;
  }
) {
  let _compressFile: File = null;
  const {
    data: fileUrl,
    mutate: getPresignedUploadUrl,
    isSuccess,
    isLoading,
  } = useMutation<any, Error, File>({
    mutationFn: async (payload: File) => {
      _compressFile = await compressFile(payload);
      const formattedPayload: GetPresignedAvatarPayload = {
        fileName: _compressFile?.name,
        contentType: _compressFile?.type,
        type: 'avatars',
      };
      return responseWrapper<any>(apiClient.getPresignedUploadAvatarUrl, [formattedPayload]);
    },
    onSuccess: (data, variables, context) => {
      uploadFile({
        url: data?.data?.url,
        contentType: _compressFile?.type,
        fileData: _compressFile,
      });
    },
    ...options,
  });

  const { uploadFile, loading: isLoadingUploadFile } = useUploadFileToS3({
    onUploadFileToS3Success(data, variables, context) {
      options.onUploadSuccess(data, variables, context);
    },
  });

  return {
    fileUrl,
    loading: isLoading || isLoadingUploadFile,
    isSuccess,
    getPresignedUploadUrl,
  };
}
