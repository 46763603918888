import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { RelatedPartyNamesAddressPayLoad } from './types';

export function useEditWCCaseParty(
  options?: UseMutationOptions<
    RelatedPartyNamesAddressPayLoad,
    Error,
    RelatedPartyNamesAddressPayLoad
  >
) {
  const {
    mutate: onEditWCCaseParty,
    isLoading: isUpdating,
    isSuccess,
    isError,
    error,
  } = useMutation<RelatedPartyNamesAddressPayLoad, Error, RelatedPartyNamesAddressPayLoad>({
    mutationFn: (payload: RelatedPartyNamesAddressPayLoad) =>
      responseWrapper(apiClient.updateWCCaseParty, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    onEditWCCaseParty,
    isUpdating,
    isSuccess,
    isError,
    error,
  };
}
