import { PaginationResponseType, toAuditData, toRelatedAuditData } from '../helpers';
import { VocationalRehabilitationKey } from './keys';
import { VocationalRehabilitation } from './types';

export const toVrProcess = (data) => ({
  ...data,
  ...toRelatedAuditData(data),
  ...toAuditData(data),
  [VocationalRehabilitationKey.DateOfInjury]: data?.case?.dateOfInjury,
});

export const toVRProcesses = (
  data: PaginationResponseType<VocationalRehabilitation>
): PaginationResponseType<VocationalRehabilitation> => ({
  ...data,
  data: data?.data?.map((item) => toVrProcess(item)),
});
