export enum CustomSettingsKey {
  Tab = 'tab',
  Name = 'name',
  Title = 'title',
  Phone = 'phone',
  MaximumTaxableWage = 'maxTaxableWage',
  LastModifiedDate = 'lastModifiedDate',
  LastModifiedBy = 'lastModifiedBy',
  UpdatedAt = 'updatedAt',
  UpdatedUser = 'updatedUser',
}
