export enum InsuranceTypes {
  WCEmployee = 'WC_EMPLOYEE',
  WCNonEmployee = 'WC_NON_EMPLOYEE',
}

export const insuranceOptions = [
  {
    value: InsuranceTypes.WCEmployee,
    label: 'WC Employee',
  },
  {
    value: InsuranceTypes.WCNonEmployee,
    label: 'WC Non-Employee',
  },
];

export enum DocumentCategory {
  Checklist = 'CHECKLIST',
  Form = 'FORM',
  Letter = 'LETTER',
  Report = 'REPORT',
  Miscellaneous = 'MISCELLANEOUS',
}

export const documentCategoryOptions = (insuranceType) => {
  const optionsNoneEmployee = [
    {
      value: DocumentCategory.Form,
      label: 'Form',
    },
    {
      value: DocumentCategory.Letter,
      label: 'Letter',
    },
  ];

  if (insuranceType === InsuranceTypes.WCNonEmployee) {
    return optionsNoneEmployee;
  }

  if (insuranceType === InsuranceTypes.WCEmployee) {
    return [
      {
        value: DocumentCategory.Checklist,
        label: 'Checklist',
      },
      ...optionsNoneEmployee,
      {
        value: DocumentCategory.Miscellaneous,
        label: 'Miscellaneous',
      },
      {
        value: DocumentCategory.Report,
        label: 'Report',
      },
    ];
  }

  return [];
};

export enum FillingParty {
  AttorneyOrRepresentative = 'ATTORNEY_OR_REPRESENTATIVE',
  Claimant = 'CLAIMANT',
  DCDOrLIRAB = 'DCD_OR_LIRAB',
  Employer = 'EMPLOYER',
  InsuranceCarrierOrAdjuster = 'INSURANCE_CARRIER_OR_ADJUSTER',
  Other = 'OTHER',
}

export const fillingPartyOptions = [
  {
    value: FillingParty.AttorneyOrRepresentative,
    label: 'Attorney or Representative',
  },
  {
    value: FillingParty.Claimant,
    label: 'Claimant',
  },
  {
    value: FillingParty.DCDOrLIRAB,
    label: 'DCD or LIRAB',
  },
  {
    value: FillingParty.Employer,
    label: 'Employer',
  },
  {
    value: FillingParty.InsuranceCarrierOrAdjuster,
    label: 'Insurance Carrier or Adjuster',
  },
  {
    value: FillingParty.Other,
    label: 'Other',
  },
];

export enum DocumentType {
  //WC Non-Employee + Form
  A54VendorTableMaintenanceStateAccountingForm = 'A54VendorTableMaintenanceStateAccountingForm',
  ApplicationForCertificateOfCompliance = 'ApplicationForCertificateOfCompliance',
  B24StateAccountingFormOfficialReceipt = 'B24StateAccountingFormOfficialReceipt',
  ChecklistForZNumbers = 'ChecklistForZNumbers',
  DC53EnforcementFindingsInvestigativeReport = 'DC53EnforcementFindingsInvestigativeReport',
  DC54ComplaintForm = 'DC54ComplaintForm',
  DC90SpecialFundTransactionWithTreasuryDepositAndWarrantNumberDocument = 'DC90SpecialFundTransactionWithTreasuryDepositAndWarrantNumberDocument',
  MigrationEmployer = 'MigrationEmployer',
  WC14EmployeesWageReportForFiftyTwoWeeks = 'WC14EmployeesWageReportForFiftyTwoWeeks',
  WC17ApprovalOfAttorneyFees = 'WC17ApprovalOfAttorneyFees',
  WC21ApplicationForSelfInsurance = 'WC21ApplicationForSelfInsurance',
  WCRP19DeterminationOfRegistrationAndReregistration = 'WCRP19DeterminationOfRegistrationAndReregistration',

  //WC Non-Employee + Letter
  WC18WCClaimDenialLetter = 'WC18WCClaimDenialLetter',
  WC28EmployerCancellationOfExpirationNotice = 'WC28EmployerCancellationOfExpirationNotice',
  WC29EmployersWithoutCoverageNotice = 'WC29EmployersWithoutCoverageNotice',
  WC29EExemptEmployerExpirationNoticeLetter = 'WC29EExemptEmployerExpirationNoticeLetter',
  WC56NoticeToEmployersFoundNonCompliantInProvidingWCCoverage = 'WC56NoticeToEmployersFoundNonCompliantInProvidingWCCoverage',
  WC58DeterminationOfNonCompliantPenaltyAssessment = 'WC58DeterminationOfNonCompliantPenaltyAssessment',
  WC76RequestForReportsFromCarriers = 'WC76RequestForReportsFromCarriers',
  WCRP17DeterminationOfVRCertification = 'WCRP17DeterminationOfVRCertification',
  WCRP18DeterminationOfVRReCertification = 'WCRP18DeterminationOfVRReCertification',

  //WC Employee + Checklist
  WC9AChecklistForMailingOutDecisions = 'WC9AChecklistForMailingOutDecisions',
  WCRP24InitialEvaluationOfClaimantChecklist = 'WCRP24InitialEvaluationOfClaimantChecklist',

  //WC Employee + Form
  DC90bSpecialCompensationFundTransaction = 'DC90bSpecialCompensationFundTransaction',
  MigrationCase = 'MigrationCase',
  MigrationCaseLog = 'MigrationCaseLog',
  MigrationClaim = 'MigrationClaim',
  MigrationClaimant = 'MigrationClaimant',
  MigrationDecision = 'MigrationDecision',
  MigrationEntity = 'MigrationEntity',
  MigrationHearingEvent = 'MigrationHearingEvent',
  MigrationReadyHearing = 'MigrationReadyHearing',
  MigrationWC1Submittal = 'MigrationWC1Submittal',
  NoticeOfUIDecision = 'NoticeOfUIDecision',
  ReceiptForMoneyReceived = 'ReceiptForMoneyReceived',
  RequestForAttorneyFee = 'RequestForAttorneyFee',
  RequestForInformationOrPhotocopies = 'RequestForInformationOrPhotocopies',
  UC24512RequestForDisclosureOfUnemploymentInsuranceRecords = 'UC24512RequestForDisclosureOfUnemploymentInsuranceRecords',
  WC1EmployersReportOfIndustrialInjury = 'WC1EmployersReportOfIndustrialInjury',
  WC2PhysiciansReport = 'WC2PhysiciansReport',
  WC3CarriersCaseReport = 'WC3CarriersCaseReport',
  WC3ACarriersBenefitAdjustmentReport = 'WC3ACarriersBenefitAdjustmentReport',
  WC5EmployeesClaimForWorkersCompensationBenefits = 'WC5EmployeesClaimForWorkersCompensationBenefits',
  WC5ADependentsClaimForCompensation = 'WC5ADependentsClaimForCompensation',
  WCRP14VRFinalReport = 'WCRP14VRFinalReport',
  WCRP23EmployeeSelectionForm = 'WCRP23EmployeeSelectionForm',
  WCRP23eDeterminationOfVREligibility = 'WCRP23eDeterminationOfVREligibility',
  WCRP25dVocationalRehabilitationEnrollmentForm = 'WCRP25dVocationalRehabilitationEnrollmentForm',
  WCRP3aRequestDeterminationForApprovalOfRehabilitationPlan = 'WCRP3aRequestDeterminationForApprovalOfRehabilitationPlan',

  //WC Employee + letter
  VRLetterToUnit = 'VRLetterToUnit',
  WC100ADeterminationOfPenaltyAssessment = 'WC100ADeterminationOfPenaltyAssessment',
  WC10ABenefitAdjustmentDecision = 'WC10ABenefitAdjustmentDecision',
  WC10AChildSupportDecision = 'WC10AChildSupportDecision',
  WC10AIMEDecision = 'WC10AIMEDecision',
  WC10ALongDecision = 'WC-10A Long Decision',
  WC10AShortDecision = 'WC10AShortDecision',
  WC44RequestForWC1 = 'WC44RequestForWC1',
  WC79TransferringOfCaseFile = 'WC79TransferringOfCaseFile',
  WC80ChecklistToSubmitMissingReports = 'WC80ChecklistToSubmitMissingReports',
  WCRP10DeterminationOnObjectionToTheVRPlan = 'WCRP10DeterminationOnObjectionToTheVRPlan',
  WCRP25nDeterminationOnRequestForReconsiderationOfVRClosure = 'WCRP25nDeterminationOnRequestForReconsiderationOfVRClosure',
  WCRP25nRDeterminationForObjectionToVRClosureReconsideration = 'WCRP25nRDeterminationForObjectionToVRClosureReconsideration',
  WCRP30DeterminationOnRequestForObjectionToVRClosure = 'WCRP30DeterminationOnRequestForObjectionToVRClosure',
  WCRP46VRDetermination = 'WCRP46VRDetermination',
  WCRP5NotificationOfVRCaseClosure = 'WCRP5NotificationOfVRCaseClosure',

  //WC Employee + Miscellaneous
  ActiveOpenCases = 'ActiveOpenCases',
  ArchivedClosedCases = 'ArchivedClosedCases',
  PositionStatements = 'PositionStatements',
  SubpoenaMedicalRecords = 'SubpoenaMedicalRecords',

  //WC Employee + report
  DeceasedClaimantsReport = 'DeceasedClaimantsReport',
  InvestigativeSurveillanceVideos = 'InvestigativeSurveillanceVideos',
  PaymentsOnIndividualCasesPerEmployerReport = 'PaymentsOnIndividualCasesPerEmployerReport',
  RSAnnualErrorReport = 'RSAnnualErrorReport',
  RSWeeklyErrorReport = 'RSWeeklyErrorReport',
  WC3CarrierPaymentReport = 'WC3CarrierPaymentReport',
  WC3BCarrierYearEndReport = 'WC3BCarrierYearEndReport',
}

export const documentTypeOptions = (insuranceType, documentCategory) => {
  if (
    insuranceType === InsuranceTypes.WCNonEmployee &&
    documentCategory === DocumentCategory.Form
  ) {
    return [
      {
        value: DocumentType.A54VendorTableMaintenanceStateAccountingForm,
        label: 'A-54 Vendor Table Maintenance-State Accounting Form',
      },
      {
        value: DocumentType.ApplicationForCertificateOfCompliance,
        label: 'Application for Certificate of Compliance',
      },
      {
        value: DocumentType.B24StateAccountingFormOfficialReceipt,
        label: 'B-24 State Accounting Form Official Receipt',
      },
      {
        value: DocumentType.ChecklistForZNumbers,
        label: 'Checklist for Z Numbers',
      },
      {
        value: DocumentType.DC53EnforcementFindingsInvestigativeReport,
        label: 'DC-53 Enforcement Findings Investigative Report',
      },
      {
        value: DocumentType.DC54ComplaintForm,
        label: 'DC-54 Complaint Form',
      },
      {
        value: DocumentType.DC90SpecialFundTransactionWithTreasuryDepositAndWarrantNumberDocument,
        label: 'DC-90 Special Fund Transaction with Treasury Deposit and Warrant Number Document',
      },
      {
        value: DocumentType.MigrationEmployer,
        label: 'Migration Employer',
      },
      {
        value: DocumentType.WC14EmployeesWageReportForFiftyTwoWeeks,
        label: 'WC-14 Employees Wage Report for Fifty-Two Weeks',
      },
      {
        value: DocumentType.WC17ApprovalOfAttorneyFees,
        label: "WC-17 Approval of Attorney's Fees",
      },
      {
        value: DocumentType.WC21ApplicationForSelfInsurance,
        label: 'WC-21 Application for Self-Insurance',
      },
      {
        value: DocumentType.WCRP19DeterminationOfRegistrationAndReregistration,
        label: 'WCRP-19 – Determination of Registration and Re-registration',
      },
    ];
  }

  if (
    insuranceType === InsuranceTypes.WCNonEmployee &&
    documentCategory === DocumentCategory.Letter
  ) {
    return [
      {
        value: DocumentType.WC18WCClaimDenialLetter,
        label: 'WC-18 WC Claim Denial Letter',
      },
      {
        value: DocumentType.WC28EmployerCancellationOfExpirationNotice,
        label: 'WC-28 Employer Cancellation of Expiration Notice',
      },
      {
        value: DocumentType.WC29EmployersWithoutCoverageNotice,
        label: 'WC-29 Employers Without coverage Notice',
      },
      {
        value: DocumentType.WC29EExemptEmployerExpirationNoticeLetter,
        label: 'WC-29E Exempt Employer expiration notice letter',
      },
      {
        value: DocumentType.WC56NoticeToEmployersFoundNonCompliantInProvidingWCCoverage,
        label: 'WC-56 Notice to Employers Found Non-Compliant in providing WC coverage',
      },
      {
        value: DocumentType.WC58DeterminationOfNonCompliantPenaltyAssessment,
        label: 'WC-58 Determination of Non-Compliant Penalty Assessment',
      },
      {
        value: DocumentType.WC76RequestForReportsFromCarriers,
        label: 'WC-76 Request for Reports From Carriers',
      },
      {
        value: DocumentType.WCRP17DeterminationOfVRCertification,
        label: 'WCRP-17 Determination of VR Certification',
      },
      {
        value: DocumentType.WCRP18DeterminationOfVRReCertification,
        label: 'WCRP-18 - Determination of VR Re-certification',
      },
    ];
  }

  if (
    insuranceType === InsuranceTypes.WCEmployee &&
    documentCategory === DocumentCategory.Checklist
  ) {
    return [
      {
        value: DocumentType.WC9AChecklistForMailingOutDecisions,
        label: 'WC-9A Checklist for Mailing out Decisions',
      },
      {
        value: DocumentType.WCRP24InitialEvaluationOfClaimantChecklist,
        label: 'WCRP-24 Initial Evaluation of Claimant Checklist',
      },
    ];
  }

  if (insuranceType === InsuranceTypes.WCEmployee && documentCategory === DocumentCategory.Form) {
    return [
      {
        value: DocumentType.DC90bSpecialCompensationFundTransaction,
        label: 'DC-90b Special Compensation Fund Transaction',
      },
      {
        value: DocumentType.MigrationCase,
        label: 'Migration Case',
      },
      {
        value: DocumentType.MigrationCaseLog,
        label: 'Migration Case Log',
      },
      {
        value: DocumentType.MigrationClaim,
        label: 'Migration Claim',
      },
      {
        value: DocumentType.MigrationClaimant,
        label: 'Migration Claimant',
      },
      {
        value: DocumentType.MigrationDecision,
        label: 'Migration Decision',
      },
      {
        value: DocumentType.MigrationEntity,
        label: 'Migration Entity',
      },
      {
        value: DocumentType.MigrationHearingEvent,
        label: 'Migration Hearing Event',
      },
      {
        value: DocumentType.MigrationReadyHearing,
        label: 'Migration Ready Hearing',
      },
      {
        value: DocumentType.MigrationWC1Submittal,
        label: 'Migration WC-1 Submittal',
      },
      {
        value: DocumentType.NoticeOfUIDecision,
        label: 'Notice of UI Decision',
      },
      {
        value: DocumentType.ReceiptForMoneyReceived,
        label: 'Receipt for Money Received',
      },
      {
        value: DocumentType.RequestForAttorneyFee,
        label: 'Request for Attorney Fee',
      },
      {
        value: DocumentType.RequestForInformationOrPhotocopies,
        label: 'Request for Information or Photocopies',
      },
      {
        value: DocumentType.UC24512RequestForDisclosureOfUnemploymentInsuranceRecords,
        label: 'UC-245-12 Request for disclosure of Unemployment Insurance Records',
      },
      {
        value: DocumentType.WC1EmployersReportOfIndustrialInjury,
        label: 'WC-1 Employers Report of Industrial Injury',
      },
      {
        value: DocumentType.WC2PhysiciansReport,
        label: 'WC-2 Physicians Report',
      },
      {
        value: DocumentType.WC3CarriersCaseReport,
        label: 'WC-3 Carriers Case Report',
      },
      {
        value: DocumentType.WC3ACarriersBenefitAdjustmentReport,
        label: 'WC-3A Carriers Benefit Adjustment Report',
      },
      {
        value: DocumentType.WC5EmployeesClaimForWorkersCompensationBenefits,
        label: 'WC-5 Employees Claim for Workers Compensation Benefits',
      },
      {
        value: DocumentType.WC5ADependentsClaimForCompensation,
        label: 'WC-5A Dependents Claim for Compensation',
      },
      {
        value: DocumentType.WCRP14VRFinalReport,
        label: 'WCRP-14 VR Final Report',
      },
      {
        value: DocumentType.WCRP23EmployeeSelectionForm,
        label: 'WCRP-23 Employee Selection Form',
      },
      {
        value: DocumentType.WCRP23eDeterminationOfVREligibility,
        label: 'WCRP-23e Determination of VR Eligibility',
      },
      {
        value: DocumentType.WCRP25dVocationalRehabilitationEnrollmentForm,
        label: 'WCRP-25d Vocational Rehabilitation Enrollment Form',
      },
      {
        value: DocumentType.WCRP3aRequestDeterminationForApprovalOfRehabilitationPlan,
        label: 'WCRP-3a Request Determination for Approval of Rehabilitation Plan',
      },
    ];
  }

  if (insuranceType === InsuranceTypes.WCEmployee && documentCategory === DocumentCategory.Letter) {
    return [
      {
        value: DocumentType.VRLetterToUnit,
        label: 'VR Letter to Unit',
      },
      {
        value: DocumentType.WC100ADeterminationOfPenaltyAssessment,
        label: 'WC-100A Determination of Penalty Assessment',
      },
      {
        value: DocumentType.WC10ABenefitAdjustmentDecision,
        label: 'WC-10A Benefit Adjustment Decision',
      },
      {
        value: DocumentType.WC10AChildSupportDecision,
        label: 'WC-10A Child Support Decision',
      },
      {
        value: DocumentType.WC10AIMEDecision,
        label: 'WC-10A IME Decision',
      },
      {
        value: DocumentType.WC10ALongDecision,
        label: 'WC-10A Long Decision',
      },
      {
        value: DocumentType.WC10AShortDecision,
        label: 'WC-10A Short Decision',
      },
      {
        value: DocumentType.WC44RequestForWC1,
        label: 'WC-44 Request for WC-1',
      },
      {
        value: DocumentType.WC79TransferringOfCaseFile,
        label: 'WC-79 Transferring of Case File',
      },
      {
        value: DocumentType.WC80ChecklistToSubmitMissingReports,
        label: 'WC-80 Checklist to Submit Missing Reports',
      },
      {
        value: DocumentType.WCRP10DeterminationOnObjectionToTheVRPlan,
        label: 'WCRP-10 Determination on Objection to the VR Plan',
      },
      {
        value: DocumentType.WCRP25nDeterminationOnRequestForReconsiderationOfVRClosure,
        label: 'WCRP-25n Determination on Request for Reconsideration of VR Closure',
      },
      {
        value: DocumentType.WCRP25nRDeterminationForObjectionToVRClosureReconsideration,
        label: 'WCRP-25nR Determination for Objection to VR Closure Reconsideration',
      },
      {
        value: DocumentType.WCRP30DeterminationOnRequestForObjectionToVRClosure,
        label: 'WCRP-30 Determination on Request for Objection to VR Closure',
      },
      {
        value: DocumentType.WCRP46VRDetermination,
        label: 'WCRP-46 VR Determination',
      },
      {
        value: DocumentType.WCRP5NotificationOfVRCaseClosure,
        label: 'WCRP-5 Notification of VR Case Closure',
      },
    ];
  }

  if (
    insuranceType === InsuranceTypes.WCEmployee &&
    documentCategory === DocumentCategory.Miscellaneous
  ) {
    return [
      {
        value: DocumentType.ActiveOpenCases,
        label: 'Active Open Cases',
      },
      {
        value: DocumentType.ArchivedClosedCases,
        label: 'Archived Closed Cases',
      },
      {
        value: DocumentType.PositionStatements,
        label: 'Position Statements',
      },
      {
        value: DocumentType.SubpoenaMedicalRecords,
        label: 'Subpoena Medical Records',
      },
    ];
  }

  if (insuranceType === InsuranceTypes.WCEmployee && documentCategory === DocumentCategory.Report) {
    return [
      {
        value: DocumentType.DeceasedClaimantsReport,
        label: 'Deceased Claimants Report',
      },
      {
        value: DocumentType.InvestigativeSurveillanceVideos,
        label: 'Investigative surveillance/videos',
      },
      {
        value: DocumentType.PaymentsOnIndividualCasesPerEmployerReport,
        label: 'Payments on Individual Cases per Employer Report',
      },
      {
        value: DocumentType.RSAnnualErrorReport,
        label: 'R&S Annual Error Report',
      },
      {
        value: DocumentType.RSWeeklyErrorReport,
        label: 'R&S Weekly Error Report',
      },
      {
        value: DocumentType.WC3CarrierPaymentReport,
        label: 'WC-3 Carrier Payment Report',
      },
      {
        value: DocumentType.WC3BCarrierYearEndReport,
        label: "WC-3B Carrier's Year End Report",
      },
    ];
  }
};
