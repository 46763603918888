import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { Tag, ViewObject } from 'src/queries';
import { isEmpty } from 'src/validations';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { ApiResponseType } from '../types';
import { toViewDetail } from './helpers';
import { Service, View } from './types';

export function useGetViewDetail(
  options?: UseQueryOptions<ApiResponseType<View>, Error, View> & {
    id: string;
    object: ViewObject;
    tags?: Tag;
    service?: Service;
  }
) {
  const {
    data: view,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetView,
  } = useQuery<ApiResponseType<View>, Error, View>(
    [API_QUERIES.Views, { id: options.id, ...options }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<View>>(apiClient.getViewDetail, params);
      },
      keepPreviousData: true,
      select: ({ data }) => toViewDetail(data),
      enabled: !!options.id,
      notifyOnChangeProps: ['data', 'isFetching'],
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateAllViewDetail = () => {
    queryClient.invalidateQueries([API_QUERIES.Views, { id: options.id }]);
    queryClient.invalidateQueries(options?.object);
    if (!isEmpty(options?.tags)) {
      queryClient.invalidateQueries(options?.tags as any);
    }
  };

  return {
    view,
    error,
    isError,
    isLoading,
    onGetView,
    handleInvalidateAllViewDetail,
  };
}
