import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { GetPropertiesParams } from '../types';
import { toTDI46ReconsiderationAppealsReport } from './helpers';
import { TDI46ReconsiderationAppealsReportResponse } from './types';

export function useGetTDI46ReconsiderationAppealsReport(
  options?: UseQueryOptions<TDI46ReconsiderationAppealsReportResponse, Error>
) {
  const [params, setParams] = useState<GetPropertiesParams>({});

  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetTDI46ReconsiderationAppealsReport,
  } = useQuery<TDI46ReconsiderationAppealsReportResponse, Error>(
    [API_QUERIES.REPORT_TDI_46_RECONSIDERATION_APPEAL, { ...params, ...options }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<TDI46ReconsiderationAppealsReportResponse>(
          apiClient.getTDI46ReconsiderationAppealsReport,
          params
        );
      },
      onError: ErrorService.handler,
      notifyOnChangeProps: ['data', 'isFetching'],
      select: (data) => toTDI46ReconsiderationAppealsReport(data),
      keepPreviousData: true,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateTDI46ReconsiderationAppealsReport = () =>
    queryClient.invalidateQueries(API_QUERIES.REPORT_TDI_46_RECONSIDERATION_APPEAL);

  const { data: tdi46ReconsiderationAppealsReport, totalRecords } = data?.data?.report || {};

  const { totalAppeals, totalReconsideration } = data?.data || {};

  return {
    tdi46ReconsiderationAppealsReport,
    totalAppeals,
    totalReconsideration,
    totalRecords,
    error,
    isError,
    isFetching,
    onGetTDI46ReconsiderationAppealsReport,
    setParams,
    handleInvalidateTDI46ReconsiderationAppealsReport,
  };
}
