import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { authResponseWrapper } from '../helpers';

export function useDeleteRequestReconsideration(
  options?: UseMutationOptions<string, Error, string>
) {
  const { mutate: deleteRequestReconsideration, isLoading } = useMutation<string, Error, string>({
    mutationFn: (payload: string) =>
      authResponseWrapper(apiClient.deleteRequestForReconsideration, [payload]),
    onError: ErrorService.handler,
    ...options,
  });
  return { deleteRequestReconsideration, isLoading };
}
