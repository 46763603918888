import { toAuditData } from '../helpers';
import { HCPKey } from './keys';

export const toHCPs = (data) => ({
  ...data,
  data: data.data.map((hcp) => ({
    ...hcp,
    [HCPKey.Employer]: hcp?.employer?.accountName || '',
    [HCPKey.EmployerName]: hcp?.employer?.accountName || '',
    [HCPKey.EmployerId]: hcp?.employer?.id || '',
    [HCPKey.EmployerDOL]: hcp?.employer?.laborNumber || '',
    [HCPKey.EmployerDCDStatus]: hcp?.employer?.dcdStatus || '',
    [HCPKey.EmployerDCDStatusDate]: hcp?.employer?.dcdStatusDate || '',
    [HCPKey.HCContractor]: hcp?.hcContractor?.accountName || '',
    [HCPKey.HCContractorId]: hcp?.hcContractor?.carrierId || '',
    [HCPKey.HCContractorStatus]: hcp?.hcContractor?.dcdStatus || '',
    [HCPKey.HCContractorStatusDate]: hcp?.hcContractor?.dcdStatusDate || '',
    ...toAuditData(hcp),
  })),
});

export const toHCPDetail = (data) => ({
  ...data,
  [HCPKey.HCContractorName]: data?.hcContractor?.accountName || '',
  [HCPKey.HCContractorId]: data?.hcContractor?.id || '',
  [HCPKey.HCContractorNumber]: data?.hcContractor?.carrierId || '',
  [HCPKey.HCContractorStatus]: data?.hcContractor?.dcdStatus || '',
  [HCPKey.HCContractorStatusDate]: data?.hcContractor?.dcdStatusDate || '',
  [HCPKey.EmployerId]: data?.employer?.id || '',
  [HCPKey.Employer]: data?.employer || '',
  [HCPKey.EmployerName]: data?.employer?.accountName || '',
  [HCPKey.EmployerDOL]: data?.employer?.laborNumber || '',
  [HCPKey.EmployerDCDStatus]: data?.employer?.dcdStatus || '',
  [HCPKey.EmployerDCDStatusDate]: data?.employer?.dcdStatusDate || '',
  ...toAuditData(data),
});
