import { UseMutationOptions, useMutation } from 'react-query';
import { ErrorService } from 'src/services';
import { PortalUserPayload } from '.';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';

export function useUpdatePortalUser(options?: UseMutationOptions<any, Error, PortalUserPayload>) {
  const {
    mutate: onUpdatePortalUser,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useMutation<any, Error, PortalUserPayload>({
    mutationFn: (payload: PortalUserPayload) =>
      responseWrapper(apiClient.updatePortalUser, [payload]),
    onError(errors) {
      ErrorService.handler(errors);
    },
    ...options,
  });

  return {
    onUpdatePortalUser,
    isLoading,
    isSuccess,
    isError,
    error,
  };
}
