export enum PortalUserKey {
  Id = 'id',
  FirstName = 'firstName',
  LastName = 'lastName',
  PortalUserName = 'portalUserName',
  MiddleName = 'middleName',
  Account = 'account',
  AccountName = 'accountName',
  FullName = 'fullName',
  PortalUserClassification = 'portalUserClassification',
  ContactNumber = 'contactNumber',
  Email = 'email',
  Status = 'status',
  AccountId = 'accountId',

  PortalUserTypeId = 'portalUserTypeId',
  PortalUserProgramTypes = 'portalUserProgramTypes',
  ProgramTypes = 'programTypes',
  IsPermitted = 'isPermitted',
  IsSignedUpByUser = 'isSignedUpByUser',
  PortalUserType = 'portalUserType',
  UserId = 'userId',
  ProgramTypeId = 'programTypeId',
  PortalUserClassificationId = 'portalUserClassificationId',
  PortalReviewComments = 'portalReviewComments',

  OrgInfo = 'orgInfo',
  AdjusterId = 'adjusterId',
  InsuranceCarrierName = 'insuranceCarrierName',
  FederalIdNumber = 'federalIdNumber',
  Address = 'address',
  City = 'city',
  ZipCode = 'zipCode',
  IsThirdPartyAdmin = 'isThirdPartyAdmin',
  ThirdPartyAdminType = 'thirdPartyAdminType',
  EmployerName = 'employerName',
  CompanyPhoneNumber = 'companyPhoneNumber',
  NameOfParentCompany = 'nameOfParentCompany',
  MailingAddress = 'mailingAddress',
  MailingCity = 'mailingCity',
  MailingState = 'mailingState',
  MailingZipCode = 'mailingZipCode',
  NatureOfBusiness = 'natureOfBusiness',
  OrganizationType = 'organizationType',
  AdjustingServicesName = 'adjustingServicesName',
  AdjustingServices = 'adjustingServices',
  UserType = 'userType',
  Username = 'username',
  HasWC = 'hasWC',
  HasTDI = 'hasTDI',
  HasPHC = 'hasPHC',
  IsWC = 'isWC',
  IsTDI = 'isTDI',
  IsPHC = 'isPHC',
  ProgramTypeIds = 'programTypeIds',
  MapPermittedProgramTypes = 'mapPermittedProgramTypes',
  MapSignedUpByUserProgramTypes = 'mapSignedUpByUserProgramTypes',
  Comment = 'comments',
  ProgramType = 'programType',
  Title = 'title',

  Salutation = 'salutation',
  ClaimantFirstName = 'claimantFirstName',
  ClaimantLastName = 'claimantLastName',
  MiddleInitial = 'middleInitial',
  Suffix = 'suffix',
  Sex = 'sex',
  DateOfBirth = 'dateOfBirth',
  IsDeceased = 'isDeceased',
  DateOfDeath = 'dateOfDeath',
  DcisName = 'dcisName',
  Type = 'type',
  SSN = 'ssn',
  Passport = 'passport',
  Phone = 'phone',
  MaritalAddress = 'maritalAddress',
  IsQualifyStudent = 'isQualifyStudent',
  IsNonSelfSufficient = 'isNonSelfSufficient',
  IsWhollyDependent = 'isWhollyDependent',
  Street = 'street',
  State = 'state',
  Country = 'country',

  UserClassification = 'portalUserClassification',
  IdentificationType = 'identificationType',
  IdentificationNumber = 'identificationNumber',
  ClaimantAddress = 'claimantAddress',
  ClaimantCity = 'claimantCity',
  ClaimantState = 'claimantState',
  ClaimantZipCode = 'claimantZipCode',
  ClaimantEmail = 'claimantEmail',
  ClaimantPhone = 'claimantPhone',
  MailingAddressIfDifferent = 'mailingAddressIfDifferent',
  EmployerPhoneNumber = 'employerPhoneNumber',
  EmployerAddress = 'employerAddress',
  EmployerCity = 'employerCity',
  EmployerState = 'employerState',
  EmployerZipCode = 'employerZipCode',

  // ============= Filter Key =============
  OrgInfoIdFilterKey = 'orgInfo.id',
  AdjusterIdFilterKey = 'orgInfo.adjusterId',
  InsuranceCarrierNameFilterKey = 'orgInfo.insuranceCarrierName',
  FederalIdNumberFilterKey = 'orgInfo.federalIdNumber',
  AddressFilterKey = 'orgInfo.address',
  CityFilterKey = 'orgInfo.city',
  StateFilterKey = 'orgInfo.state',
  ZipCodeFilterKey = 'orgInfo.zipCode',
  ContactNumberFilterKey = 'orgInfo.contactNumber',
  IsThirdPartyAdminFilterKey = 'orgInfo.isThirdPartyAdmin',
  ThirdPartyAdminTypeFilterKey = 'orgInfo.thirdPartyAdminType',
  EmployerNameFilterKey = 'orgInfo.employerName',
  CompanyPhoneNumberFilterKey = 'orgInfo.companyPhoneNumber',
  AdjusterServicesNameFilterKey = 'orgInfo.adjustingServicesName',
  NameOfParentCompanyFilterKey = 'orgInfo.nameOfParentCompany',
  MailingAddressFilterKey = 'orgInfo.mailingAddress',
  MailingCityFilterKey = 'orgInfo.mailingCity',
  MailingStateFilterKey = 'orgInfo.mailingState',
  MailingZipCodeFilterKey = 'orgInfo.mailingZipCode',
  NatureOfBusinessFilterKey = 'orgInfo.natureOfBusiness',
  OrganizationTypeFilterKey = 'orgInfo.organizationType',
  AdjustingServicesFilterKey = 'orgInfo.adjustingServices',
}

export enum PortalUserAuditHistoryKey {
  CreatedAt = 'createdAt',
  CreatedUser = 'createdUser',
  UserProfile = 'userProfileName',
  NewValue = 'newValue',
  OriginalValue = 'originalValue',
}

export enum PortalUserStatus {
  New = 'New',
  InProgress = 'In Progress',
  Approved = 'Approved',
  Rejected = 'Rejected',
}

export enum OrganizationType {
  None = 'None',
  Conporation = 'Conporation',
  Partnership = 'Partnership',
  SoleProprietor = 'Sole Proprietor',
  Other = 'Other',
}

export enum PortalProgramType {
  WC = 'WC',
  TDI = 'TDI',
  PHC = 'PHC',
}

export enum PortalUserType {
  Employer = 'ER',
  SelfInsured = 'SI',
  Employee = 'EE',
  Dependent = 'DP',
  Spouse = 'SP',
  Carrier = 'CR',
  Adjuster = 'AJ',
  Other = 'OT',
  LegalGuardian = 'LG',
  AttorneyForClaimant = 'AC',
  AttorneyForEmployer = 'AE',
  AttorneyGeneralOffice = 'AT',
  HealthCareContractor = 'HC',
  Physical = 'PS',
  BillDispute = 'BD',
  VocationalRehabilitationCounselor = 'VRC',
}

export enum PortalUserClassification {
  Employer = 'ER',
  Employee = 'EE',
  Insurance = 'IR',
  Claimant = 'CL',
  HealthCareProvider = 'HC',
  Representative = 'RP',
  Legal = 'LG',
  Contractor = 'CT',
}
