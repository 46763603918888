import React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Text, View } from 'src/modules/shared-main/components';
import {
  Service,
  useDeleteView,
  useGetViews,
  View as ViewType,
} from 'src/modules/shared-main/queries';
import { hideDialog } from 'src/redux/dialog/dialogSlice';
import { Toastify } from 'src/services';

const DeleteViewDialog: React.FC<Props> = ({ view, service }) => {
  const { id, object } = view || {};

  const dispatch = useDispatch();

  const { handleInvalidateViews } = useGetViews({ object, enabled: false });

  const { deleteView, isLoading } = useDeleteView({
    onSuccess: () => {
      Toastify.success('List view deleted successfully.');
      dispatch(hideDialog());
      handleInvalidateViews();
    },
  });

  const handleDeleteView = () =>
    deleteView({ id: id.toString(), object: object, service: service });

  return (
    <View>
      <Text>
        Are you sure you want to delete this list view? It will be permanently removed for all users
        with access.
      </Text>
      <View className="pt-24" isRowWrap justify="flex-end">
        <Button disabled={isLoading} onClick={() => dispatch(hideDialog())} variant="outline">
          Cancel
        </Button>
        <Button isLoading={isLoading} onClick={handleDeleteView} className="ml-8">
          Delete
        </Button>
      </View>
    </View>
  );
};

type Props = {
  view: ViewType;
  service: Service;
};

export default DeleteViewDialog;
