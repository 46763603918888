import SettingsIcon from '@mui/icons-material/Settings';
import { Menu, MenuItem, Stack, Typography } from '@mui/material';
import React, { Fragment, useState } from 'react';
import './styles.scss';
import { Navigator } from 'src/services';
import { RiAdminFill } from 'react-icons/ri';
import { PATHS } from 'src/appConfig/paths';
import { urlAppMap } from '../Sidebar/helpers';
import { AppName } from 'src/appConfig/constants';
import { setActiveApp } from 'src/redux/common/commonSlice';
import { connect } from 'react-redux';
import { IRootState } from 'src/redux/store';
import { Text, View } from 'src/modules/shared-main/components';

const NavSetup: React.FC<Props> = ({ onSetActiveApp }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <Fragment>
      <Stack
        flexDirection={'row'}
        alignItems={'center'}
        onClick={handleClick}
        sx={{
          cursor: 'pointer',
          marginRight: '10px',
        }}
      >
        <Typography
          className="ctn-navbar__text"
          sx={{
            fontSize: '38px',
          }}
        >
          <SettingsIcon />
        </Typography>
      </Stack>
      <Menu
        id={'simple-Menu'}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{ mt: 1 }}
        elevation={1}
        disableScrollLock={true}
      >
        <MenuItem
          onClick={() => {
            onSetActiveApp(AppName.Admin);
            Navigator.navigate(PATHS.userManagements.replace(':app', urlAppMap[AppName.Admin]));
            handleClose();
          }}
        >
          <View isRow className="ctn-navbar__text">
            <RiAdminFill size={20} />
            <Text>Administration</Text>
          </View>
        </MenuItem>
      </Menu>
    </Fragment>
  );
};

type Props = ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps & {};

const mapStateToProps = (state: IRootState) => ({});

const mapDispatchToProps = {
  onSetActiveApp: setActiveApp,
};

export default connect(mapStateToProps, mapDispatchToProps)(NavSetup);
