import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { Toastify } from 'src/services';
import apiClient from '../apiClient';
import { PaginationResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { GetPropertiesParams } from '../types';
import { RelatedTypePurposesType } from './types';
import { toRelatedTypePurposes } from './helper';

export function useGetAllRelatedTypePurposes(
  options?: UseQueryOptions<PaginationResponseType<RelatedTypePurposesType>, Error> & {
    id: string;
  }
) {
  const [params, setParams] = useState<GetPropertiesParams>({});
  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetAllTypePurposes,
  } = useQuery<PaginationResponseType<RelatedTypePurposesType>>(
    [API_QUERIES.RELATED_TYPE_PURPOSES, { ...params, hearingId: options.id }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<PaginationResponseType<RelatedTypePurposesType>>(
          apiClient.getAllRelatedTypePurposes,
          params
        );
      },
      onError: ({ message }) => Toastify.error(message),
      notifyOnChangeProps: ['data', 'isFetching'],
      keepPreviousData: true,
      enabled: !!options.id,
      select: toRelatedTypePurposes,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateAllRelatedHearingTypePurposes = () =>
    queryClient.invalidateQueries(API_QUERIES.RELATED_TYPE_PURPOSES);

  const { data: typePurpose, hasNext, payloadSize, totalRecords } = data || {};

  return {
    typePurpose,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isError,
    isFetching,
    onGetAllTypePurposes,
    setParams,
    handleInvalidateAllRelatedHearingTypePurposes,
  };
}
