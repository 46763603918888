import { useMemo } from 'react';
import { UseInfiniteQueryOptions, useInfiniteQuery, useQueryClient } from 'react-query';
import { isEmpty } from 'src/validations';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { RelatedHearingNotes } from './types';

const pageParam = {
  take: 10,
  skip: 0,
};

export function useLazyRelatedNotes(
  options?: UseInfiniteQueryOptions<{ data: RelatedHearingNotes[] }, Error> & {
    hearingId: string;
  }
) {
  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetAllRelatedNotes,
    fetchNextPage,
  } = useInfiniteQuery<{ data: RelatedHearingNotes[] }, Error>(
    [API_QUERIES.RELATED_NOTES, pageParam, { id: options.hearingId }],
    (props): Promise<{ data: RelatedHearingNotes[] }> => {
      const { pageParam } = props;
      const searchOptions = { ...pageParam };

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      return responseWrapper<{ data: RelatedHearingNotes[] }>(apiClient.getAllHearingRelatedNotes, [
        { ...searchOptions, ...(options as Object) },
      ]);
    },
    {
      keepPreviousData: true,
      getNextPageParam(lastPage, allPages) {
        if (lastPage?.data?.length < 10) return undefined;
        return {
          take: 10,
          skip: allPages?.length * 10,
        };
      },
      notifyOnChangeProps: ['data', 'isFetching'],
      ...options,
    }
  );

  const notes = useMemo(() => {
    if (isEmpty(data)) return [];
    return data.pages.reduce((state, page) => [...state, ...page.data.map((note) => note)], []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const queryClient = useQueryClient();

  const handleInvalidateLazyNotes = () =>
    queryClient.invalidateQueries([API_QUERIES.RELATED_NOTES], options);

  return {
    notes,
    error,
    isError,
    loading: isFetching,
    onGetAllRelatedNotes,
    fetchNextPage,
    handleInvalidateLazyNotes,
  };
}
