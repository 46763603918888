import { useMutation, UseMutationOptions } from 'react-query';
import { Toastify } from 'src/services';
import apiClient from '../apiClient';
import { authResponseWrapper } from '../helpers';
import { RelatedAccountContactType } from './types';

export function useDeleteRelatedAccountContact(
  options?: UseMutationOptions<any, Error, RelatedAccountContactType['id']>
) {
  const { mutate: deleteRelatedAccountContact, isLoading } = useMutation<
    any,
    Error,
    RelatedAccountContactType['id']
  >({
    mutationFn: (payload: RelatedAccountContactType['id']) =>
      authResponseWrapper(apiClient.deleteRelatedAccountContact, [payload]),
    ...options,
    onError: (err) => Toastify.error(err.message),
  });

  return {
    deleteRelatedAccountContact,
    isLoading,
  };
}
