import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { toPortalUser } from 'src/queries/PortalUsers/helpers';
import { PortalUser } from 'src/queries/PortalUsers/types';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { PaginationResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { GetPropertiesParams } from '../types';

export function useGetAllPortalUsers(
  options?: UseQueryOptions<PaginationResponseType<PortalUser>, Error> & {
    [key: string]: string | number | string[] | boolean;
  }
) {
  const [params, setParams] = useState<GetPropertiesParams>({});
  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetAllPortalUsers,
  } = useQuery<PaginationResponseType<PortalUser>>(
    [API_QUERIES.PORTAL_USERS, { ...params, ...options }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<PaginationResponseType<PortalUser>>(
          apiClient.getAllPortalUser,
          params
        );
      },
      onError: ErrorService.handler,
      notifyOnChangeProps: ['data', 'isFetching'],
      keepPreviousData: true,
      select: (data) => toPortalUser(data),
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidatePortalUsers = () => queryClient.invalidateQueries(API_QUERIES.PORTAL_USERS);

  const { data: portalUsers, hasNext, payloadSize, totalRecords } = data || {};

  return {
    portalUsers,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isError,
    isFetching,
    onGetAllPortalUsers,
    setParams,
    handleInvalidatePortalUsers,
  };
}
