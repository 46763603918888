import { formatDateTime } from 'src/utils';
import { PaginationResponseType } from '../helpers';
import { AddressHistoryContact, AddressHistoryType } from './types';

export const toAddressHistories = (
  data: PaginationResponseType<any>
): PaginationResponseType<AddressHistoryType> => ({
  ...data,
  data: data.data.map((addressHistory) => ({
    ...addressHistory,
    contact: getContactDisplay(addressHistory.contact),
    createdBy: `${addressHistory.createdByName}, ${formatDateTime(addressHistory.createdAt)}`,
    lastModifiedBy: addressHistory?.updatedByName
      ? `${addressHistory.updatedByName}, ${formatDateTime(addressHistory.updatedAt)}`
      : '',
  })),
});

const getContactDisplay = (contact: AddressHistoryContact) => {
  const { firstName, lastName, middleInitial, salutation, suffix } = contact || {};
  if (!contact) return '';

  return `${salutation} ${firstName} ${lastName} ${middleInitial} ${suffix}`;
};
