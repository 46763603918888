import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { TDI62, toTDI62s } from 'src/queries';
import apiClient from 'src/queries/apiClient';
import { PaginationResponseType, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { GetPropertiesParams } from 'src/queries/types';
import { TDI62sReportKey } from './keys';

export function useGetTDI62sReportDetail(
  options?: UseQueryOptions<PaginationResponseType<TDI62>, Error> & {
    [TDI62sReportKey.ActionTaken]: string;
    [TDI62sReportKey.StartDate]: string;
    [TDI62sReportKey.EndDate]: string;
  }
) {
  const [params, setParams] = useState<GetPropertiesParams>({});

  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetTDI62sReportDetail,
  } = useQuery<PaginationResponseType<TDI62>, Error>(
    [API_QUERIES.REPORT_TDI_62S, { ...params, ...options }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<PaginationResponseType<TDI62>>(
          apiClient.getTDI62sReportDetail,
          params
        );
      },
      notifyOnChangeProps: ['data', 'isFetching'],
      keepPreviousData: true,
      enabled: !!options?.startDate && !!options?.endDate && !!options?.actionStatus,
      select: (data) => toTDI62s(data),
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateTDI62sReportDetail = () =>
    queryClient.invalidateQueries(API_QUERIES.REPORT_TDI_62S);

  const { data: tdi62sReportDetail, hasNext, payloadSize, totalRecords } = data || {};

  return {
    tdi62sReportDetail,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isError,
    isFetching,
    onGetTDI62sReportDetail,
    setParams,
    handleInvalidateTDI62sReportDetail,
  };
}
