import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { createResponseWrapper } from '../helpers';
import { SendDisfigurementEmail } from './types';

export function useSendDisfigurementEmail(
  options?: UseMutationOptions<SendDisfigurementEmail, Error, SendDisfigurementEmail>
) {
  const { mutate: sendEmail, isLoading } = useMutation<
    SendDisfigurementEmail,
    Error,
    SendDisfigurementEmail
  >({
    mutationFn: (payload: SendDisfigurementEmail) =>
      createResponseWrapper(apiClient.sendDisfigurementEmail, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    sendEmail,
    isLoading,
  };
}
