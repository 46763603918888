import { ThemeProvider } from '@emotion/react';
import { Tooltip } from '@mui/material';
import { IoInformationCircle } from 'react-icons/io5';
import { COLOR_CODE } from 'src/appConfig/constants';
import { theme } from 'src/appConfig/muiTheme';

/**
TooltipTable component displays a tooltip with a given title inside Table Cell.
@component
@param {string} title - The title to be displayed in the tooltip.
@returns {React.ReactElement} The rendered TooltipTable component. 
*/

const TooltipTable: React.FC<Props> = ({ message }) => (
  <ThemeProvider theme={theme}>
    <Tooltip arrow placement="right" title={message}>
      <i className="cursor-pointer ml-1">
        <IoInformationCircle
          size={16}
          color={COLOR_CODE.INFO}
          style={{
            transform: 'translateY(2px)',
          }}
        />
      </i>
    </Tooltip>
  </ThemeProvider>
);

type Props = {
  message: string;
};

export default TooltipTable;
