import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { ApiResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { toScheduleAvailability } from './helpers';
import { ScheduleAvailabilityInterface } from './types';

export function useGetScheduleAvailability(
  options?: UseQueryOptions<
    ApiResponseType<{ scheduleAvailability: ScheduleAvailabilityInterface }>,
    Error,
    ScheduleAvailabilityInterface
  > & {
    id: string;
    roomId: string;
    date: string;
    appName: string;
  }
) {
  const {
    data: scheduleAvailability,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetScheduleAvailability,
  } = useQuery<
    ApiResponseType<{ scheduleAvailability: ScheduleAvailabilityInterface }>,
    Error,
    ScheduleAvailabilityInterface
  >(
    [
      API_QUERIES.SCHEDULE_AVAILABILITY,
      { id: options?.id, roomId: options?.roomId, date: options?.date, appName: options?.appName },
    ],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<
          ApiResponseType<{ scheduleAvailability: ScheduleAvailabilityInterface }>
        >(apiClient.getScheduleAvailability, params);
      },
      select: ({ data }) => toScheduleAvailability(data.scheduleAvailability),
      enabled: !!options?.id,
      onError: ErrorService.handler,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateScheduleAvailability = () =>
    queryClient.invalidateQueries([
      API_QUERIES.SCHEDULE_AVAILABILITY,
      { id: options.id, roomId: options.roomId, date: options.date, appName: options.appName },
    ]);

  return {
    scheduleAvailability,
    error,
    isError,
    isLoading,
    onGetScheduleAvailability,
    handleInvalidateScheduleAvailability,
  };
}
