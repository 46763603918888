import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { ApiResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { toAwardWorksheetDetail } from './helpers';
import { AwardWorksheet } from './types';

export function useGetAwardWorksheet(
  options?: UseQueryOptions<
    ApiResponseType<{ awardWorksheet: AwardWorksheet }>,
    Error,
    AwardWorksheet
  > & {
    id: AwardWorksheet['id'];
  }
) {
  const {
    data: awardWorksheet,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetAwardWorksheetById,
  } = useQuery<ApiResponseType<{ awardWorksheet: AwardWorksheet }>, Error, AwardWorksheet>(
    [API_QUERIES.AwardWorksheets, { id: options.id }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<{ awardWorksheet: AwardWorksheet }>>(
          apiClient.getAwardWorksheet,
          params
        );
      },
      select: (data) => toAwardWorksheetDetail(data?.data.awardWorksheet),

      enabled: !!options.id,
      onError: ErrorService.handler,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateAwardWorksheet = () =>
    queryClient.invalidateQueries([API_QUERIES.AwardWorksheets, { id: options.id }]);

  return {
    awardWorksheet,
    error,
    isError,
    isLoading,
    onGetAwardWorksheetById,
    handleInvalidateAwardWorksheet,
  };
}
