import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { createResponseWrapper } from 'src/queries/helpers';
import { Toastify } from 'src/services';
import { BenefitPaymentsDetailType } from './types';

export function useCreateBenefitPayment(
  options?: UseMutationOptions<BenefitPaymentsDetailType, Error, BenefitPaymentsDetailType>
) {
  const { mutate: createBenefitPayment, isLoading } = useMutation<
    BenefitPaymentsDetailType,
    Error,
    BenefitPaymentsDetailType
  >({
    mutationFn: (payload: BenefitPaymentsDetailType) =>
      createResponseWrapper(apiClient.createBenefitPayment, [payload]),
    onError: ({ message }) => Toastify.error(message),
    ...options,
  });

  return {
    createBenefitPayment,
    isLoading,
  };
}
