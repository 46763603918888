import { AccountDetail, DCDStatus } from '../Accounts';
import { TDI46 } from '../TDI46s';
import { TDICaseKey } from './keys';

export enum TDICaseResolution {
  BenefitsPaid = 'Benefits Paid',
  Denied = 'Denied',
}

export enum TDICaseStatus {
  Denial = 'Denial',
  Appeal = 'Appeal',
  UnderReview = 'Under Review',
  HearingReview = 'Hearing Review',
  JudicialReview = 'Judicial Review',
  Ended = 'Ended',
}

export enum DenialStatus {
  Pending = 'PENDING',
  Remanded = 'REMANDED',
  Rescinded = 'RESCINDED',
  Accepted = 'ACCEPTED',
}

export enum DisabilityCause {
  Sickness = 'SICKNESS',
  Accident = 'ACCIDENT',
}

export enum EmployerPosition {
  Accepted = 'ACCEPTED',
  PendingInvestigate = 'PENDING_INVESTIGATE',
  Denied = 'DENIED',
}

export interface TDICase {
  [TDICaseKey.Id]?: string;
  [TDICaseKey.CreatedAt]?: string;
  [TDICaseKey.UpdatedAt]?: string;
  [TDICaseKey.CreatedBy]?: string;
  [TDICaseKey.UpdatedBy]?: string;
  [TDICaseKey.EmployerAccountName]?: string;
  [TDICaseKey.ClaimantName]?: string;
  [TDICaseKey.TDICaseNumber]?: string;
  [TDICaseKey.TDICaseFormattedNumber]?: string;
  [TDICaseKey.Claimant]?: AccountDetail;
  [TDICaseKey.Employer]?: AccountDetail;
  [TDICaseKey.InsuranceCarrier]?: AccountDetail;
  [TDICaseKey.AdjustingCompany]?: AccountDetail;
  [TDICaseKey.MostRecentTDI46]?: TDI46;
  [TDICaseKey.AdjustingCompanyId]?: string;
  [TDICaseKey.StatusDate]?: string;
  [TDICaseKey.DateReceived]?: string;
  [TDICaseKey.FirstDateOfDisabilityClaimed]?: string;
  [TDICaseKey.DateClaimFiled]?: string;
  [TDICaseKey.ClaimOrFileNumber]?: string;
  [TDICaseKey.Status]?: TDICaseStatus;
  [TDICaseKey.DenialStatus]?: DenialStatus;
  [TDICaseKey.DateNoticeSentDLIR]?: string;
  [TDICaseKey.MostRecentTDI46Id]?: string;
  [TDICaseKey.DateNoticeSentClaimant]?: string;
  [TDICaseKey.ReconsiderationRequestDate]?: string;
  [TDICaseKey.ClaimantId]?: string;
  [TDICaseKey.ClaimantSSN]?: string;
  [TDICaseKey.ClaimantPhone]?: string;
  [TDICaseKey.ClaimantEmail]?: string;
  [TDICaseKey.ClaimantDateOfDeath]?: string;
  [TDICaseKey.IsDenyFailureToMeetEligibility]?: boolean;
  [TDICaseKey.DidNotPerformRegularService]?: boolean;
  [TDICaseKey.NotDisabledBeyond7Days]?: boolean;
  [TDICaseKey.IsDenyReceived26WeeksOfBenefitPayment]?: boolean;
  [TDICaseKey.IsDelinquentEmployer]?: boolean;
  [TDICaseKey.EmployerPosition]?: EmployerPosition;
  [TDICaseKey.ClaimedFiledOn]?: string;
  [TDICaseKey.EmployerId]?: string;
  [TDICaseKey.EmployerDolNumber]?: string;
  [TDICaseKey.EmployerStatusDate]?: string;
  [TDICaseKey.EmployerStatus]?: string;
  [TDICaseKey.InsuranceCarrierId]?: string;
  [TDICaseKey.InsuranceCarrierCarrierId]?: string;
  [TDICaseKey.ClaimantDOB]?: string | Date;
  [TDICaseKey.ClaimantAddress]?: string;
  [TDICaseKey.InsuranceCarrierStatus]?: DCDStatus;
  [TDICaseKey.InsuranceCarrierStatusDate]?: string;
  [TDICaseKey.InsuranceCarrierAddress]?: string;
  [TDICaseKey.InsuranceCarrierPhone]?: string;
  [TDICaseKey.AdjustingCompanyPhone]?: string;
  [TDICaseKey.TDICaseId]?: string;
  [TDICaseKey.CreatedUser]?: string;
  [TDICaseKey.UpdatedUser]?: string;

  // Denial Information
  [TDICaseKey.IsDenyFailureToMeetEligibility]?: boolean;
  [TDICaseKey.IsDenyDidNotPerformRegularService]?: boolean;
  [TDICaseKey.IsDenyNotDisabledBeyond7Days]?: boolean;
  [TDICaseKey.IsDenyReceived26WeeksOfBenefitPayment]?: boolean;
  [TDICaseKey.IsDenyLateFillingAfter90Days]?: boolean;
  [TDICaseKey.DenialLateFillingClaimedFiledOn]?: string;
  [TDICaseKey.DenialLateFillingNoBenefitArePayable]?: boolean;
  [TDICaseKey.IsPaymentStart14DaysBeforeFileDate]?: boolean;
  [TDICaseKey.IsDenyClaimingBenefitUnderWcLaw]?: boolean;
  [TDICaseKey.IsDenyNotDisabledAccordingMedicalRecord]?: boolean;
  [TDICaseKey.AbleToPerformRegularWorkOnDate]?: string;
  [TDICaseKey.PaymentOfBenefitIsDeniedAfterDate]?: string;
  [TDICaseKey.IsDenyMedCertDoesntEstablishUnableToWork]?: boolean;
  [TDICaseKey.IsDenyNotUnderCareOfPhysician]?: boolean;
  [TDICaseKey.NotUnderCarePhysicianStartDate]?: string;
  [TDICaseKey.NotUnderCarePhysicianEndDate]?: string;
  [TDICaseKey.NotUnderCarePhysicianNoBenefitArePayable]?: boolean;
  [TDICaseKey.NotUnderCarePhysicianPaymentWillStart]?: boolean;
  [TDICaseKey.NotUnderCarePhysicianPaymentWillStartOn]?: string;
  [TDICaseKey.BenefitEntitledUnderUnionContract]?: boolean;
  [TDICaseKey.NotInsuranceCarrierForEmployer]?: boolean;
  [TDICaseKey.NotCarrierForEmplIsClaimForwarded]?: boolean;
  [TDICaseKey.NotCarrierForEmplClaimForwardedTo]?: string;
  [TDICaseKey.NotCarrierForEmplIsClaimReturned]?: boolean;
  [TDICaseKey.IsDenyOtherReason]?: boolean;
  [TDICaseKey.DenialOtherReasons]?: string;

  // Disability
  [TDICaseKey.CauseOfDisability]?: DisabilityCause;
  [TDICaseKey.FirstDateUnableToDoJob]?: string;
  [TDICaseKey.RecoveredDate]?: string;
  [TDICaseKey.ReturnToWorkDate]?: string;

  // Appeal
  [TDICaseKey.AppealFiledDate]?: string;
  [TDICaseKey.DisputedItem]?: string;
  [TDICaseKey.TDIApa5ASentDate]?: string;
  [TDICaseKey.ClaimantAppeal]?: string;
  [TDICaseKey.AppealWithdrawnDate]?: string;
  [TDICaseKey.ClaimantReceiveDenialNoticeDate]?: string;
  [TDICaseKey.NewspaperPublicDate]?: string;

  // Enforcement
  [TDICaseKey.Disposition]?: string;
  [TDICaseKey.DateForwardedToInsCarrier]?: string;
  [TDICaseKey.EligibilityDetermination]?: string;
  [TDICaseKey.IsBenefitPaid]?: boolean;
  [TDICaseKey.Resolution]?: TDICaseResolution;
  [TDICaseKey.ResolvedInvestigator]?: {
    id: string;
    fullName: string;
    firstName: string;
    lastName: string;
  };
}
