import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppName } from 'src/appConfig/constants';

export type IAppState = {
  app: AppName;
};
export const FIRST_STEP = 0;

const initialState: IAppState = {
  app: AppName.DCDEcms,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setAppData: (state, action: PayloadAction<AppName>) => {
      state.app = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setAppData } = appSlice.actions;

export const appState = appSlice.getInitialState();

export default appSlice.reducer;
