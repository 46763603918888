import { useMutation, UseMutationOptions } from 'react-query';
import { Toastify } from 'src/services';
import apiClient from '../apiClient';
import { createResponseWrapper } from '../helpers';
import { RelatedInjuryIllnessType } from './types';

export function useCreateRelatedInjuryIllness(
  options?: UseMutationOptions<RelatedInjuryIllnessType, Error, RelatedInjuryIllnessType>
) {
  const { mutate: createRelatedInjuryIllness, isLoading } = useMutation<
    RelatedInjuryIllnessType,
    Error,
    RelatedInjuryIllnessType
  >({
    mutationFn: (payload: RelatedInjuryIllnessType) =>
      createResponseWrapper(apiClient.createRelatedInjuryIllness, [payload]),
    onError: ({ message }) => Toastify.error(message),
    ...options,
  });

  return {
    createRelatedInjuryIllness,
    isLoading,
  };
}
