import { PotentialAssociatedKey } from 'src/queries/RelatedPotentialAssociated/keys';
import { PotentialAssociated } from 'src/queries/RelatedPotentialAssociated/types';
import { PaginationResponseType, toRelatedAuditData } from '../helpers';

export const toPotentialAssociated = (data): PaginationResponseType<PotentialAssociated> => ({
  ...data,
  data: data.data.map((item) => ({
    ...item,
    [PotentialAssociatedKey.CaseNumber]: item?.wcCase?.caseNumber,
    [PotentialAssociatedKey.ClaimantName]: item?.wcCase?.claimant?.accountName,
    [PotentialAssociatedKey.ClaimantSSN]: item?.wcCase?.claimant?.ssn,
    [PotentialAssociatedKey.DateOfInjury]: item?.wcCase?.dateOfInjury,
    [PotentialAssociatedKey.ClaimantId]: item?.wcCase?.claimant?.id,
    ...toRelatedAuditData(item),
  })),
});
