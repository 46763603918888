import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { PaginationResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { GetPropertiesParams } from '../types';
import { AssociatedCases } from './types';

export function useGetAllRelatedAssociatedCases(
  options?: UseQueryOptions<PaginationResponseType<AssociatedCases>, Error> & {
    caseId: string;
  }
) {
  const [params, setParams] = useState<GetPropertiesParams>({});
  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetAllRelatedAssociatedCases,
  } = useQuery<PaginationResponseType<AssociatedCases>, Error>(
    [API_QUERIES.RELATED_ASSOCIATED_CASES, { ...params, caseId: options?.caseId }],
    {
      queryFn: ({ queryKey }) => {
        const params = queryKey.at(-1);
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return responseWrapper<PaginationResponseType<AssociatedCases>>(
          apiClient.getAllRelatedAssociatedCases,
          [params]
        );
      },
      notifyOnChangeProps: ['data', 'isFetching'],
      keepPreviousData: true,
      enabled: !!options.caseId,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateAllRelatedAssociatedCases = () =>
    queryClient.invalidateQueries(API_QUERIES.RELATED_ASSOCIATED_CASES);

  const { data: associatedCases, hasNext, payloadSize, totalRecords } = data || {};

  return {
    associatedCases,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isError,
    isFetching,
    onGetAllRelatedAssociatedCases,
    handleInvalidateAllRelatedAssociatedCases,
    setParams,
  };
}
