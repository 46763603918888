import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { WC77 } from './types';

export function useEditWC77(options?: UseMutationOptions<WC77, Error, WC77>) {
  const {
    mutate: onEditWC77,
    isLoading: isUpdating,
    isSuccess,
    isError,
    error,
  } = useMutation<WC77, Error, WC77>({
    mutationFn: (payload: WC77) => responseWrapper(apiClient.updateWC77, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    onEditWC77,
    isUpdating,
    isSuccess,
    isError,
    error,
  };
}
