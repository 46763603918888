import React, { PropsWithChildren } from 'react';
import { Waypoint } from 'react-waypoint';
import { Callback } from 'src/redux/types';

const LazyCheckPoint: React.FC<PropsWithChildren<Props>> = ({
  children,
  onFirstEnter,
}) => {

  const handleEnterView = (args) => {
    onFirstEnter(args);
  };
  return <Waypoint onEnter={handleEnterView}>{children}</Waypoint>;
};

type Props = {
  onFirstEnter: Callback;
  refreshValue?: any;
};

export default LazyCheckPoint;
