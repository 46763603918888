/* eslint-disable security/detect-object-injection */
import { ApiResponseType } from 'src/queries/helpers';
import { BenefitPaymentsKey, BenefitPaymentsType } from './types';

const getBenefitPaymentData = (data: any, benefitType: BenefitPaymentsKey) => {
  const benefitPayments = data?.data?.benefitPayments?.[benefitType] || [];
  const grandTotals = data?.data?.grandTotals?.[benefitType] || {};

  return {
    [BenefitPaymentsKey.BenefitPayments]: benefitPayments,
    [BenefitPaymentsKey.GrandTotalDays]: grandTotals.days || null,
    [BenefitPaymentsKey.GrandTotalPaymentNotPrevReport]: grandTotals.paymentNotPrevReport || null,
    [BenefitPaymentsKey.GrandTotalPriorPayment]: grandTotals.priorPayment || null,
    [BenefitPaymentsKey.GrandTotalPaymentToDate]: grandTotals.totalPaymentToDate || null,
  };
};

export const toBenefitPaymentsTableData = (
  data: ApiResponseType<any>
): ApiResponseType<BenefitPaymentsType> => {
  const newData = {
    ...data,
    data: {
      [BenefitPaymentsKey.TTD]: getBenefitPaymentData(data, BenefitPaymentsKey.TTD),
      [BenefitPaymentsKey.TPD]: getBenefitPaymentData(data, BenefitPaymentsKey.TPD),
      [BenefitPaymentsKey.PPD]: getBenefitPaymentData(data, BenefitPaymentsKey.PPD),
      [BenefitPaymentsKey.PTD]: getBenefitPaymentData(data, BenefitPaymentsKey.PTD),
      [BenefitPaymentsKey.Death]: getBenefitPaymentData(data, BenefitPaymentsKey.Death),
    },
  };
  return newData;
};
