import { useMutation, UseMutationOptions } from 'react-query';
import { Toastify } from 'src/services';
import apiClient from '../apiClient';
import { createResponseWrapper } from '../helpers';
import { AddUserPayload } from './types';
export function useCreateUser(options?: UseMutationOptions<any, Error, AddUserPayload>) {
  const { mutate: createUser, isLoading } = useMutation<any, Error, AddUserPayload>({
    mutationFn: (payload: AddUserPayload) => createResponseWrapper(apiClient.createUser, [payload]),
    onError: ({ message }) => Toastify.error(message),
    ...options,
  });

  return {
    createUser,
    isLoading,
  };
}
