import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { RelatedHearingRequiredDocumentsType } from './types';

export function useEditRelatedHearingRequiredDocuments(
  options?: UseMutationOptions<any, Error, RelatedHearingRequiredDocumentsType>
) {
  const {
    mutate: editRelatedHearingRequiredDocuments,
    isLoading: isUpdating,
    isSuccess,
    isError,
    error,
  } = useMutation<any, Error, RelatedHearingRequiredDocumentsType>({
    mutationFn: (payload: RelatedHearingRequiredDocumentsType) =>
      responseWrapper(apiClient.updateRelatedHearingRequiredDocuments, [payload]),
    onError: (error) => ErrorService.handler(error),
    ...options,
  });

  return {
    editRelatedHearingRequiredDocuments,
    isUpdating,
    isSuccess,
    isError,
    error,
  };
}
