import { useMutation, UseMutationOptions } from 'react-query';
import { Toastify } from 'src/services';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { AccountDetail } from './types';

export function useEditAccount(options?: UseMutationOptions<AccountDetail, Error, AccountDetail>) {
  const {
    mutate: onEditAccount,
    isLoading: isUpdating,
    isSuccess,
    isError,
    error,
  } = useMutation<AccountDetail, Error, AccountDetail>({
    mutationFn: (payload: AccountDetail) => responseWrapper(apiClient.updateAccount, [payload]),
    onError: ({ message }) => Toastify.error(message),
    ...options,
  });

  return {
    onEditAccount,
    isUpdating,
    isSuccess,
    isError,
    error,
  };
}
