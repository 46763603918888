export enum PartyArchiveKey {
  LockingEvent = 'lockingEvent',
  CreatedAt = 'createdAt',
  PartyName = 'partyName',
  PartyAddress = 'partyAddress',
  PartyRole = 'partyRole',
  HearingId = 'hearingId',
  PartyId = 'partyId',
  PartyType = 'partyType',
}

export enum PartyArchiveType {
  Contact = 'Contact',
  Account = 'Account',
}
