import cn from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Skeleton, Tooltip } from '@mui/material';
import { IoInformationCircle } from 'react-icons/io5';
import { VscWarning } from 'react-icons/vsc';
import { COLOR_CODE } from 'src/appConfig/constants';
import { isEmpty } from 'src/validations';
import { View, Grid, Link } from '..';
import './styles.scss';

const ViewItem: React.FC<Props> = ({
  label,
  value,
  variant = 'is-half',
  infoTooltipMessage = '',
  infoTooltipPlacement = 'right',
  infoToolTipWithArrow = true,
  isLoading = false,
  isTypeLink = false,
  renderIf = true,
  isHighlight = false,
  containerClassName,
}) => {
  const isEmptyLine = isEmpty(label) && isEmpty(value);
  const viewRef = useRef(null);
  const viewWidth = useRef(0);

  const [contentState, setContentState] = useState<string>('');
  const [isShowMore, setIsShowMore] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const handleSubString = useCallback(() => {
    let width = viewWidth.current;
    if (isHighlight) width += 20;
    const length = value.toString().length;

    if (length > width / 10) {
      const content = value.toString().substring(0, width / 10 - 5);
      setIsShowMore(true);
      setIsExpanded(false);
      setContentState(`${content}...`);
    } else {
      setIsShowMore(false);
      setIsExpanded(false);
      setContentState(value.toString());
    }
  }, [value, isHighlight]);

  useEffect(() => {
    const view = viewRef?.current;

    const observer = new ResizeObserver((entries) => {
      if (value && typeof value === 'string') {
        const width = entries[0]?.borderBoxSize?.[0].inlineSize;
        if (typeof width === 'number' && width !== viewWidth.current) {
          viewWidth.current = width;
          handleSubString();
        }
      }
    });

    if (!isLoading && view) {
      observer.observe(view);
    }

    return () => {
      observer.disconnect();
    };
  }, [value, isLoading, handleSubString]);

  const handleShowMore = (e) => {
    e.preventDefault();
    setContentState('' + value);
    setIsExpanded(true);
  };

  const handleShowLess = (e) => {
    e.preventDefault();
    handleSubString();
    setIsExpanded(false);
  };

  const renderActionShow = () => {
    switch (isExpanded) {
      case true:
        return (
          <Link onClick={handleShowLess} className="cmp-view-item__value--actions">
            Show less
          </Link>
        );
      default:
        return (
          <Link onClick={handleShowMore} className="cmp-view-item__value--actions">
            Show more
          </Link>
        );
    }
  };

  const renderContent = () => {
    switch (isShowMore) {
      case true:
        return (
          <span className={cn({ 'cmp-view-item__value--highlight': isHighlight })}>
            {isHighlight && (
              <VscWarning
                color={COLOR_CODE.WARNING}
                className={cn('cmp-view-item__value--highlight__icon', {
                  'cmp-view-item__value--highlight__icon--show-more': isShowMore && isExpanded,
                })}
              />
            )}
            {isEmpty(contentState) ? '--' : contentState}
            {renderActionShow()}
          </span>
        );
      default:
        return (
          <span className={cn({ 'cmp-view-item__value--highlight': isHighlight })}>
            {isHighlight && (
              <VscWarning
                color={COLOR_CODE.WARNING}
                className={cn('cmp-view-item__value--highlight__icon', {
                  'cmp-view-item__value--highlight__icon--show-more': isShowMore && isExpanded,
                })}
              />
            )}
            {isEmpty(value) ? '--' : value}
          </span>
        );
    }
  };

  const getTitle = () => {
    if (typeof label === 'string') return label;
    return null;
  };

  if (isEmptyLine)
    return <Grid.Item variant={variant} className={cn('cmp-view-item__empty', variant)} />;

  return (
    <Grid.Item
      renderIf={renderIf}
      variant={variant}
      className={cn('cmp-view-item column', containerClassName)}
    >
      <View
        className={cn('cmp-view-item__container', {
          'cmp-view-item__container--loading': isLoading,
        })}
        forwardRef={viewRef}
      >
        <View isRow align="center" className="cmp-view-item__label">
          {infoTooltipMessage && (
            <span className="cmp-view-item__label__info-tooltip-icon">
              <Tooltip
                arrow={infoToolTipWithArrow}
                title={infoTooltipMessage}
                placement={infoTooltipPlacement}
              >
                <i className="cursor-pointer ml-1">
                  <IoInformationCircle
                    size={16}
                    color={COLOR_CODE.INFO}
                    style={{
                      transform: 'translateY(2px)',
                    }}
                  />
                </i>
              </Tooltip>
            </span>
          )}
          <span className="cmp-view-item__label__text" title={getTitle()}>
            {label}
          </span>
        </View>
        {isLoading ? (
          <Skeleton />
        ) : (
          <View className="cmp-view-item__value" isRow>
            {isTypeLink ? value : renderContent()}
          </View>
        )}
      </View>
    </Grid.Item>
  );
};

type Props = {
  label?: string | React.ReactElement;
  value?: any;
  variant?:
    | 'is-three-quarters'
    | 'is-two-thirds'
    | 'is-half'
    | 'is-one-third'
    | 'is-one-quarter'
    | 'is-one-fifth'
    | 'is-full';
  infoTooltipMessage?: string;
  infoTooltipPlacement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
  infoToolTipWithArrow?: boolean;
  isLoading?: boolean;
  isTypeLink?: boolean;
  renderIf?: boolean;
  isHighlight?: boolean;
  containerClassName?: string;
};

export default ViewItem;
