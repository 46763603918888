import { useEffect, useState } from 'react';
import { MdOutlineEdit } from 'react-icons/md';
import { COLOR_CODE } from 'src/appConfig/constants';
import { Button, Grid, Tag, Text, View } from 'src/modules/shared-main/components';
import { useGetViewDetail, useUpdateView } from 'src/modules/shared-main/queries';
import {
  LogicElementType,
  checkLastItem,
  checkOrder,
  checkUnique,
  conditionOptions,
  convertFromLogic,
  convertToLogic,
  getLogicLength,
} from '../helpers';
import { useTableProvider } from '../../TableProvider';

const AddLogic: React.FC<Props> = () => {
  const { view, service, object } = useTableProvider();

  const [isEdit, setIsEdit] = useState(false);
  const { filters, logic, id } = view || {};

  const { updateView, isLoading } = useUpdateView({
    onSuccess: () => {
      setIsEdit(false);
      handleInvalidateAllViewDetail();
    },
  });

  const [currentLogic, setCurrentLogic] = useState([]);

  useEffect(() => {
    setErrorMessage('');
    setCurrentLogic(convertFromLogic(logic || {}));
  }, [logic]);

  const [errorMessage, setErrorMessage] = useState('');

  const onClickLogic = (value: string, type: LogicElementType) => {
    setCurrentLogic([...currentLogic, { value: value, type: type }]);
    setErrorMessage('');
  };

  const onRemoveLogic = (index: number) => {
    const newLogic = currentLogic;
    newLogic.splice(index, 1);
    setErrorMessage('');
    setCurrentLogic([...newLogic]);
  };

  const filterLength = getLogicLength(currentLogic, LogicElementType.Filter);
  const conditionLength = getLogicLength(currentLogic, LogicElementType.Condition);

  const checkLogic = () => {
    if (!currentLogic.length) {
      setErrorMessage('Add logic when there are multiple filters');
    } else {
      const isCorrect =
        checkOrder(currentLogic) &&
        checkUnique(currentLogic, filterLength) &&
        checkLastItem(currentLogic) &&
        conditionLength;
      if (!isCorrect) {
        setErrorMessage('Check the correction in your filter logic.');
      } else {
        updateView({ ...view, logic: convertToLogic(currentLogic), service });
      }
    }
  };

  const { handleInvalidateAllViewDetail } = useGetViewDetail({
    id,
    object,
    service,
  });

  const handleClose = () => {
    setIsEdit(false);
    setErrorMessage('');
    setCurrentLogic(convertFromLogic(logic));
  };

  return (
    <Grid.Wrap renderIf={filters.length > 1}>
      <Grid.Item renderIf={isEdit} variant="is-full">
        <Text className="text-color-grey-900">Add Logic</Text>
      </Grid.Item>
      <Grid.Item renderIf={!isEdit && !!currentLogic.length} variant="is-full">
        <View isRowWrap justify="space-between">
          <Text className="text-color-grey-900">Logic</Text>
          <MdOutlineEdit
            className="cursor-pointer "
            onClick={() => setIsEdit(true)}
            color={COLOR_CODE.PRIMARY_DARK}
            size={20}
          />
        </View>
      </Grid.Item>
      <Grid.Item renderIf={!isEdit && !!currentLogic.length} variant="is-full">
        <View className="cmp-filter__view">
          <Grid.Item variant="is-full">
            <Grid.Wrap>
              {currentLogic.map(({ value, type }, index) => (
                <Tag
                  key={index}
                  className="mr-4px mt-4px cursor-pointer"
                  isLight
                  variant={
                    errorMessage
                      ? 'is-danger'
                      : type === LogicElementType.Filter
                      ? 'is-info'
                      : 'is-success'
                  }
                >
                  {value}
                </Tag>
              ))}
            </Grid.Wrap>
          </Grid.Item>
        </View>
      </Grid.Item>
      <Grid.Item renderIf={isEdit} variant="is-full">
        <View className="border-box">
          <Grid.Wrap>
            <Grid.Item variant="is-full">
              {currentLogic.map(({ value, type }, index) => (
                <Tag
                  key={index}
                  onClick={() => onRemoveLogic(index)}
                  className="mr-4px mt-4px cursor-pointer"
                  isLight
                  variant={
                    errorMessage
                      ? 'is-danger'
                      : type === LogicElementType.Filter
                      ? 'is-info'
                      : 'is-success'
                  }
                >
                  {value}
                </Tag>
              ))}
            </Grid.Item>
            <Grid.Item variant="is-full">
              {filters.map(({ name }, index) => (
                <Tag
                  key={index}
                  onClick={() => onClickLogic(name, LogicElementType.Filter)}
                  className="mr-4px mt-4px cursor-pointer"
                  isLight
                  variant="is-info"
                >
                  {name}
                </Tag>
              ))}
              {conditionOptions.map(({ label }, index) => (
                <Tag
                  key={index}
                  onClick={() => onClickLogic(label, LogicElementType.Condition)}
                  className="mr-4px mt-4px cursor-pointer"
                  isLight
                  variant="is-success"
                >
                  {label}
                </Tag>
              ))}
            </Grid.Item>
          </Grid.Wrap>
        </View>
        <Text size={14} className="has-text-danger mt-4px">
          {errorMessage}
        </Text>
      </Grid.Item>
      <Grid.Item renderIf={isEdit} variant="is-full">
        <View isRowWrap justify="space-between">
          <Button onClick={handleClose} variant="outline">
            Cancel
          </Button>
          <Button isLoading={isLoading} onClick={checkLogic}>
            Save
          </Button>
        </View>
      </Grid.Item>
    </Grid.Wrap>
  );
};

type Props = {};

export default AddLogic;
