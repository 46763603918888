import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { GetPropertiesParams } from '../types';
import { toRelatedApprovalHistoryData } from './helpers';
import { ApprovalHistoryResponse } from './types';

export function useGetAllRelatedApprovalHistory(
  options: UseQueryOptions<{ data: ApprovalHistoryResponse }, Error> & {
    objectId: string;
    objectType: string;
  }
) {
  const [params, setParams] = useState<GetPropertiesParams>({});
  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetAllRelatedApprovalHistory,
  } = useQuery<{ data: ApprovalHistoryResponse }, Error>(
    [
      API_QUERIES.RELATED_APPROVAL_HISTORY,
      { ...params, objectId: options.objectId, objectType: options.objectType },
    ],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<{ data: ApprovalHistoryResponse }>(
          apiClient.getAllRelatedApprovalHistory,
          params
        );
      },
      select: toRelatedApprovalHistoryData,
      notifyOnChangeProps: ['data', 'isFetching'],
      keepPreviousData: true,
      enabled: !!options.objectId,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const { totalRecords } = data?.data.approvalHistories || {};
  const { data: approvalHistories } = data || {};
  const { isBeingRequested } = data?.data || {};

  const handleInvalidateAllRelatedApprovalHistory = () =>
    queryClient.invalidateQueries(API_QUERIES.RELATED_APPROVAL_HISTORY);

  return {
    approvalHistories,
    error,
    isError,
    isBeingRequested,
    isFetching,
    totalRecords,
    onGetAllRelatedApprovalHistory,
    handleInvalidateAllRelatedApprovalHistory,
    setParams,
  };
}
