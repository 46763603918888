import { formatDateTime } from 'src/utils';
import { PaginationResponseType, toAddress, toContactName } from '../helpers';
import { RelatedContactKey } from '../types';
import { RelatedAccountContactType, userInfoKey } from './types';

export const toRelatedContactsData = (
  data: PaginationResponseType<RelatedAccountContactType>
): PaginationResponseType<RelatedAccountContactType> => {
  return {
    ...data,
    data: data?.data.map((relatedContact) => {
      const contactName = toContactName(relatedContact?.contact);
      const relatedContactCreated = formatDateTime(relatedContact?.[userInfoKey.CreatedAt]);
      const relatedContactUpdated = formatDateTime(relatedContact?.[userInfoKey.UpdatedAt]);
      const createdBy = `${
        relatedContact?.[RelatedContactKey.CreatedByName]
      }, ${relatedContactCreated}`;
      const lastModifiedBy = relatedContact?.[RelatedContactKey.UpdatedByName]
        ? `${relatedContact?.[RelatedContactKey.UpdatedByName]}, ${relatedContactUpdated}`
        : null;

      const address = toAddress(relatedContact?.contact);

      return {
        ...relatedContact,
        [RelatedContactKey.ContactName]: contactName,
        [RelatedContactKey.CreatedBy]: createdBy,
        [RelatedContactKey.LastModifiedBy]: lastModifiedBy,
        [RelatedContactKey.Address]: address,
      };
    }),
  };
};

export const toRelatedAccountContactDetail = (data) => {
  return {
    ...data,
    subType: data?.subType?.split(','),
  };
};
