import { AccountDetail } from '../Accounts';
import { DCDCase } from '../DCDCases';
import { User } from '../Users';
import { DisbursementKey, NotifyFiscalKey } from './keys';

export enum DisburseType {
  AttorneyServices = 'Attorney Services',
  BenefitAdjustment = 'Benefit Adjustment',
  BenefitReimbursement = 'Benefit Reimbursement',
  Death = 'Death',
  Disfigurement = 'Disfigurement',
  LessOverpayment = 'Less Overpayment',
  Medical = 'Medical',
  PPD = 'PPD',
  PTD = 'PTD',
  TPD = 'TPD',
  TTD = 'TTD',
  VR = 'VR',
  Waivers = 'Waivers',
}

export enum HRSSectionDisbursement {
  Other = 'OTHER',
  S39345 = '393_45_HRS',
  S39348 = '393_48_HRS',
  S39313 = '393_13_HRS',
  S39243 = '392-43_HRS',
  S39265 = '392-65_HRS',
}

export enum DisbursementReason {
  PayPremiumSupplementation = 'PAY_PREMIUM',
  ReimburseTheEmployee = 'REIMBURSE_EMPLOYEE_EMPLOYER_FAILED_TO_PAY',
  Disburse = 'REIMBURSE_EMPLOYEE_EMPLOYER_HAD_NO_COVERAGE',
  ReimburseEmployeeForHealth = 'DISBURSE_PAYMENTS',
  Other = 'OTHER',
  NoComplyingEmployer = 'NON_COMPLYING_EMPLOYER',
  InsolventEmployer = 'INSOLVENT_EMPLOYER',
  UITransfer = 'UI_TRANSFER',
  EmployeesLocated = 'EMPLOYEES_HAVE_BEEN_LOCATED',
}

export enum ExpenseType {
  Accrued = 'Accrued',
  AdminExpense = 'Admin Expense',
  AdminPayroll = 'Admin Payroll',
  AttorneyGeneralPayroll = 'Attorney General Payroll',
  BenefitAdjustment = 'Benefit Adjustment',
  ClaimPayment = 'Claim Payment',
  FixedMonthly = 'Fixed Monthly',
  LitigationExpenseAG = 'Litigation Expense - AG',
  Medical = 'Medical',
  Prepaid = 'Prepaid',
  PriorYearReimbursement = 'Prior Year Reimbursement',
  BalancePayment = 'Balance Payment',
  Other = 'other',
}

export enum DisbursementStatus {
  Pending = 'PENDING',
  PendingSentToFiscal = 'PENDING SENT TO FISCAL',
  Posted = 'POSTED',
  NewDisbursement = 'NEW DISBURSEMENT',
  ReviewInProgress = 'REVIEW IN PROGRESS',
  SentToFiscal = 'SENT TO FISCAL',
  Approved = 'APPROVED',
  DisbursementProcessed = 'DISBURSEMENT PROCESSED',
  DisbursementClosed = 'DISBURSEMENT CLOSED',
}

export enum VoucherType {
  AB1 = 'AB1',
  AB2 = 'AB2',
  AB3 = 'AB3',
  AB4 = 'AB4',
  AB_ = 'AB_',
  AJE = 'AJE',
  ALVER1 = 'ALVER1',
  ALVER2 = 'ALVER2',
  ASBOM = 'AS-BOM',
  ASEOM1 = 'AS-EOM1',
  ASEOM2 = 'AS-EOM2',
  FORGN1 = 'FORGN1',
  OT0 = 'OT0',
  OT2 = 'OT2',
  OT3 = 'OT3',
  OT_ = 'OT_',
  Payroll = 'PAYROLL',
  PP1 = 'PP1',
  PP2 = 'PP2',
  PT1 = 'PT1',
  PT2 = 'PT2',
  PT3 = 'PT3',
  PT4 = 'PT4',
  PT5 = 'PT5',
  PT6 = 'PT6',
  PTD = 'PTD',
  Purchase = 'PURCHASE',
  TITSWRT = 'TITSWRT',
  TT1 = 'TT1',
  TT2 = 'TT2',
  Miscellaneous = 'MISCELLANEOUS',
  JV = 'JV',
  PPDWAIVERS = 'PPD/WAIVERS',
  Greater1000 = '> $1000',
  Less1000 = '< $1000',
  InvoiceLessThan1000 = 'INVOICE < 1000',
  InvoiceGreaterThan1000 = 'INVOICE > 1000',
}

export enum FundType {
  PSF = 'Premium Supplementation Fund',
  TDISF = 'Temporary Disability Insurance Special Fund (TDI SF)',
  WCSCF = "Workers' Compensation Special Compensation Fund (WC SCF)",
}

export interface RelatedDisbursement {
  [DisbursementKey.Id]?: string;
  [DisbursementKey.CaseId]?: string;
  [DisbursementKey.Vendor]?: AccountDetail;
  [DisbursementKey.Claimant]?: AccountDetail;
  [DisbursementKey.CaseEmployer]?: AccountDetail;
  [DisbursementKey.VendorName]?: string;
  [DisbursementKey.VendorId]?: string;
  [DisbursementKey.VendorNumber]?: string;
  [DisbursementKey.DisbursementNumber]?: string;
  [DisbursementKey.Status]?: DisbursementStatus;
  [DisbursementKey.DisbursementType]?: DisburseType;
  [DisbursementKey.ExpenseType]?: ExpenseType;
  [DisbursementKey.DisbursementAmount]?: string;
  [DisbursementKey.PeriodStartDate]?: string;
  [DisbursementKey.PeriodEndDate]?: string;
  [DisbursementKey.DagsDate]?: string;
  [DisbursementKey.VoucherDate]?: string;
  [DisbursementKey.CreatedBy]?: string;
  [DisbursementKey.UpdatedBy]?: string;
  [DisbursementKey.TimeOfNotification]?: Date;
  [DisbursementKey.RecipientProfiles]?: string[];
  [DisbursementKey.RecipientUsers]?: string[];
  [DisbursementKey.PaymentReminderAt]?: string;
  [DisbursementKey.Case]?: DCDCase;
}

export interface Disbursement {
  id: string;
  [DisbursementKey.CaseId]?: string;
  //Case Information
  [DisbursementKey.CaseNumber]?: string;
  [DisbursementKey.DisbursementNumber]?: string;
  [DisbursementKey.Claimant]?: AccountDetail;
  [DisbursementKey.ClaimantId]?: string;
  [DisbursementKey.ClaimantName]?: string;
  [DisbursementKey.ClaimantSSN]?: string;
  [DisbursementKey.ClaimantAddress]?: string;
  [DisbursementKey.CaseEmployerId]?: string;
  [DisbursementKey.CaseEmployer]?: string;
  [DisbursementKey.CaseEmployerName]?: string;
  [DisbursementKey.DateOfInjury]?: string;
  [DisbursementKey.LaborNumber]?: string;

  //Disbursement Info
  [DisbursementKey.VendorId]?: string;
  [DisbursementKey.VendorName]?: string;
  [DisbursementKey.VendorNumber]?: string;
  [DisbursementKey.IsDelinquentEmployer]?: boolean;
  [DisbursementKey.ApprovedPaymentBy]?: string;
  [DisbursementKey.DisbursementComment]?: string;
  [DisbursementKey.ServiceDate]?: string;
  [DisbursementKey.Status]?: DisbursementStatus;
  [DisbursementKey.PostedDate]?: string;
  [DisbursementKey.CheckNumber]?: string;
  [DisbursementKey.CheckDate]?: string;
  [DisbursementKey.CheckMailTo]?: string;
  [DisbursementKey.CheckMailDate]?: string;
  [DisbursementKey.DisbursementType]?: DisburseType;
  [DisbursementKey.ClaimWeeklyBenefit]?: number | string;
  [DisbursementKey.PeriodStartDate]?: string;
  [DisbursementKey.PeriodEndDate]?: string;
  [DisbursementKey.WeeksInPeriod]?: number;
  [DisbursementKey.DisbursementAmount]?: number;

  //Voucher information
  [DisbursementKey.VoucherType]?: string;
  [DisbursementKey.VoucherDate]?: string;
  [DisbursementKey.DagsDate]?: string;
  [DisbursementKey.Object]?: string;
  [DisbursementKey.ControlNumber]?: string;
  [DisbursementKey.VoucherDescription]?: string;

  //Expense information
  [DisbursementKey.ExpenseType]?: ExpenseType;
  [DisbursementKey.ExpenseGeneralLedgerAccountNumber]?: string;
  [DisbursementKey.ExpenseGeneralLedgerDescription]?: string;

  //Additional information
  [DisbursementKey.DateFrom]?: string;
  [DisbursementKey.DateTo]?: string;
  [DisbursementKey.TC]?: string;
  [DisbursementKey.Comment]?: string;
  [DisbursementKey.PayMemoTo]?: string;
  [DisbursementKey.Title]?: string;
  [DisbursementKey.PayMemoAttentionTo]?: string;

  [DisbursementKey.FundType]?: FundType;
  [DisbursementKey.ExpenseGeneralLedgerAccountId]?: string;
  [DisbursementKey.EmployerId]?: string;
  [DisbursementKey.Employer]?: string;
  [DisbursementKey.EmployerDOL]?: string;

  [DisbursementKey.TDISFClaimantName]?: string;
  [DisbursementKey.TDISFClaimantId]?: string;
  [DisbursementKey.TDISFEmployerName]?: string;
  [DisbursementKey.TDISFEmployerId]?: string;
  //TDISF
  [DisbursementKey.TDISFCaseId]?: string;
  [DisbursementKey.WeeklyBenefitAmount]?: string;
  [DisbursementKey.RequestedPaymentAmount]?: string;
  [DisbursementKey.AmountBenefitsPaid]?: string;
  [DisbursementKey.Employees]?: string;
  [DisbursementKey.PaymentDate]?: string;
  [DisbursementKey.TDISFCaseNumber]?: string;
  [DisbursementKey.DisbursementReason]?: DisbursementReason;
  [DisbursementKey.Remarks]?: string;
}

export interface NotifyFiscalPayload {
  [NotifyFiscalKey.Id]?: string;
  [NotifyFiscalKey.FiscalRecipientUsers]?: User[];
  [NotifyFiscalKey.Message]?: string;
  [NotifyFiscalKey.IsAllFiscalUser]: boolean;
}
