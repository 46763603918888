export enum SearchableSelectType {
  Profile = 'profile',
  User = 'user',
  Account = 'account',
  DCDCase = 'dcdCase',
  Disbursement = 'disbursement',
  WC1 = 'wc1',
  Physician = 'physician',
  Claimant = 'claimant',
  Employer = 'employer',
  EmployerAudit = 'employerAudit',
  Vendor = 'vendor',
  Contact = 'contact',
  Carrier = 'carrier',
  AdjustingCompany = 'adjustingCompany',
  ContactOfEmployer = 'contactOfEmployer',
  Association = 'association',
  Agent = 'agent',
  Room = 'room',
  Hearing = 'hearing',
  AwardWorksheet = 'awardWorksheet',
  Decision = 'decision',
  TDIDecision = 'tdiDecision',
  TDIPlan = 'tdiPlan',
  TDI62 = 'tdi62',
  TDI46 = 'tdi46',
  TDI15 = 'tdi15',
  TDI30 = 'tdi30',
  TDI21 = 'tdi21',
  TDISFCase = 'tdiSFCase',
  DoctorAPRN = 'doctorAPRN',
  TDICase = 'tdiCase',
  ContactOfAdjustingCompany = 'contactOfAdjustingCompany',
  GLAccount = 'glAccount',
  GlAccountDescription = 'glAccountDescription',
  HC4 = 'hc4',
  HCP = 'healthCarePlan',
  HC15 = 'hc15',
  ComplainantAccount = 'complaintAccount',
  Request = 'request',
  HC61 = 'hc61',
  Complaint = 'complaint',
  PortalUser = 'portalUser',
  WC77 = 'wc77',
}
