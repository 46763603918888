import { HearingRequestType } from '../Hearings';
import { PaginationResponseType } from '../helpers';
import { ApprovalHistoryKey } from './keys';

export interface CreatedUserInfo {
  [ApprovalHistoryKey.Id]: string;
  [ApprovalHistoryKey.FullName]: string;
  [ApprovalHistoryKey.FirstName]: string;
  [ApprovalHistoryKey.LastName]: string;
  [ApprovalHistoryKey.CreatedAt]: string;
}

export interface ApproverUser {
  [ApprovalHistoryKey.Id]: string;
  [ApprovalHistoryKey.FullName]: string;
  [ApprovalHistoryKey.FirstName]: string;
  [ApprovalHistoryKey.LastName]: string;
  [ApprovalHistoryKey.LastName]: string;
  [ApprovalHistoryKey.Status]: string;
  [ApprovalHistoryKey.CaseId]: string;
}

export enum ApprovalHistoryStatus {
  Pending = 'Pending',
  Submitted = 'Submitted',
  Approved = 'Approved',
  Rejected = 'Rejected',
}

export interface ApprovalHistory {
  [ApprovalHistoryKey.Id]?: string;
  [ApprovalHistoryKey.StepName]?: string;
  [ApprovalHistoryKey.Date]?: string;
  [ApprovalHistoryKey.Status]?: string;
  [ApprovalHistoryKey.AssignedToId]?: string;
  [ApprovalHistoryKey.AssignedTo]?: string;
  [ApprovalHistoryKey.ActualApproverId]?: string;
  [ApprovalHistoryKey.ActualApprover]?: ApproverUser;
  [ApprovalHistoryKey.Comments]?: string;
  [ApprovalHistoryKey.CreatedBy]?: string;
  [ApprovalHistoryKey.CreatedAt]?: string;
  [ApprovalHistoryKey.CreatedUser]?: CreatedUserInfo;
  [ApprovalHistoryKey.ObjectId]?: string;
  [ApprovalHistoryKey.ObjectType]?: ApprovalObjectType;
  [ApprovalHistoryKey.IsBeingRequested]?: string;
  [ApprovalHistoryKey.HearingRequestType]?: HearingRequestType;
}

export interface ApprovalHistoryResponse {
  decisionId?: string;
  approvalHistories: PaginationResponseType<ApprovalHistory>;
  isBeingRequested: boolean;
}

export enum ApprovalObjectType {
  ObjectTypeTransferLocation = 'CASE_TRANSFER_LOCATION',
  ObjectTypeSettlement = 'CASE_SETTLEMENT',
  ObjectTypeOrder = 'CASE_ORDER',
  ObjectTypeDisbursement = 'CASE_DISBURSEMENT',
  ObjectTypeHearing = 'CASE_HEARING',
  ObjectTypeReceipt = 'CASE_RECEIPT',
  ObjectTypeDecision = 'DECISION',
  PSFDisbursement = 'PHC_DISBURSEMENT',
  TDISFDisbursement = 'TDI_SF_DISBURSEMENT',
}

export type UpdateHistoryStatusPayload = {
  comment?: string;
  status: string;
  objectType: string;
  id: string;
};

export const initialValues = (data) => ({
  [ApprovalHistoryKey.Comments]: data?.[ApprovalHistoryKey.Comments] || '',
});

export enum ApprovalHistoryStatusEnum {
  Pending = 'Pending',
  Approved = 'Approved',
  Rejected = 'Rejected',
  Submitted = 'Submitted',
}

export const approvalHistoryStatusOptions = [
  { label: 'Pending', value: ApprovalHistoryStatusEnum.Pending },

  { label: 'Approved', value: ApprovalHistoryStatusEnum.Approved },

  { label: 'Rejected', value: ApprovalHistoryStatusEnum.Rejected },

  { label: 'Submitted', value: ApprovalHistoryStatusEnum.Submitted },
];

export const statusTagMap = {
  [ApprovalHistoryStatusEnum.Pending]: {
    variant: 'is-light',
    label: 'Pending',
  },
  [ApprovalHistoryStatusEnum.Approved]: {
    variant: 'is-success',
    label: 'Approved',
  },
  [ApprovalHistoryStatusEnum.Submitted]: {
    variant: 'is-info',
    label: 'Submitted',
  },
  [ApprovalHistoryStatusEnum.Rejected]: {
    variant: 'is-danger',
    label: 'Rejected',
  },
};

export enum SubHeaderEnum {
  ApprovalRequestSubmitted = 'Approval Request Submitted',
  ApprovalRequest = 'Approval Request',
  CaseSettlementApproval = 'Case Settlement Approval',
}
