import { AccountDetail } from '../Accounts';
import { DCDCaseStatus } from '../DCDCases';
import { PlanType, TDIPlan } from '../TdiPlans';
import { ActivateUpdatePlanKey, TDI15Key } from './keys';

export interface TDI15 {
  [TDI15Key.Id]: string;
  [TDI15Key.RecordNum]?: number;
  [TDI15Key.TDI15Number]?: string;
  [TDI15Key.EmployerId]?: string;
  [TDI15Key.EmployerName]?: string;
  [TDI15Key.EmployerFax]?: string;
  [TDI15Key.EmployerPhone]?: string;
  [TDI15Key.Employer]?: string;
  [TDI15Key.EmployerStreet]?: string;
  [TDI15Key.EmployerCity]?: string;
  [TDI15Key.EmployerState]?: string;
  [TDI15Key.EmployerZip]?: string;
  [TDI15Key.EmployerMailingAddress]?: string;
  [TDI15Key.DOLNumber]?: string;
  [TDI15Key.EffectiveDate]?: string;
  [TDI15Key.NoOfCoveredEmployees]?: number;
  [TDI15Key.EmployerPointOfContact]?: AccountDetail;
  [TDI15Key.EmployerPointOfContactName]?: string;
  [TDI15Key.EmployerPointOfContactEmail]?: string;
  [TDI15Key.NewExistingPlan]?: NewExistingPlan;
  [TDI15Key.TDIPlanType]?: TDIPlan;
  [TDI15Key.TDIPlanDepositAmount]?: string;
  [TDI15Key.TDIPlanDepositDate]?: string;
  [TDI15Key.TDIPlanIsFinanciallySolvent]?: boolean;
  [TDI15Key.TDIPlanComment]?: string;
  [TDI15Key.TDIPlanNumber]?: string;
  [TDI15Key.TDIPlanIsBond]?: boolean;
  [TDI15Key.TDIPlanRequiredDeposit]?: string;
  [TDI15Key.TDIPlanAction]?: TDIPlanAction;
  [TDI15Key.RawEmployerPointOfContact]?: string;
  [TDI15Key.EmployerPointOfContactId]?: string;
  [TDI15Key.RawEmployerMailing]?: string;
  [TDI15Key.RawEmployerName]?: string;
  [TDI15Key.RawEmployerDolNumber]?: string;
  [TDI15Key.RawEmployerPhone]?: string;
  [TDI15Key.RawEmployerFax]?: string;
  [TDI15Key.ExcludedClassOfEmployees]?: string;
  [TDI15Key.NoOfEmployeesInExcludedClass]?: string;
  [TDI15Key.PlanCoveringExcludedClass]?: string;
  [TDI15Key.PercentageWeeklyBenefits]?: string;
  [TDI15Key.CalendarDayDisabilityBenefits]?: string;
  [TDI15Key.WeeksBenefitWillContinue]?: number;
  [TDI15Key.BenefitYearStartDate]?: string;
  [TDI15Key.BenefitYearEndDate]?: string;
  [TDI15Key.IsEEContributionsDeducted]?: boolean;
  [TDI15Key.NumberOfCoveredEmployeesInHawaii]?: number;
  [TDI15Key.SecurityPaymentBenefits]?: SecurityPaymentBenefits;
  [TDI15Key.NameOfClaims]?: string;
  [TDI15Key.ClaimsAdjustingPOC]?: string;
  [TDI15Key.AgreementMailing]?: string;
  [TDI15Key.AgreementEmail]?: string;
  [TDI15Key.AgreementFax]?: string;
  [TDI15Key.AgreementPhone]?: string;
  [TDI15Key.EmployerPrintedName]?: string;
  [TDI15Key.EmployerPrintedTitle]?: string;
  [TDI15Key.SignatureDate]?: string;
  [TDI15Key.TDIPlanId]?: string;
  [TDI15Key.IsFinancialStatement]?: boolean;
  [TDI15Key.IsCD]?: boolean;
  [TDI15Key.POCEmailAddress]?: string;
  [TDI15Key.TDI15ReceivedDate]?: string;
  [TDI15Key.PlanDocumentReceivedDate]?: string;
  [TDI15Key.DateSentToAudit]?: string;
  [TDI15Key.AssignedUserId]?: string;
  [TDI15Key.DateLogReceived]?: string;
  [TDI15Key.IsApprovedSent]?: boolean;
  [TDI15Key.ActionTaken]?: ActionTakenPlan;
  [TDI15Key.FinancialStatementYearEnding]?: string;
  [TDI15Key.IsBond]?: boolean;
  [TDI15Key.FinancialStatementYearEnding]?: string;
  [TDI15Key.DateAllDocsReceived]?: string;
  [TDI15Key.DateConfirmationLetterSent]?: string;
  [TDI15Key.IsAuditApproved]?: boolean;
  [TDI15Key.DatePlanReviewed]?: string;
  [TDI15Key.IsDeniedSent]?: boolean;
  [TDI15Key.DateOfAction]?: string;

  [TDI15Key.CreatedBy]?: string;
  [TDI15Key.CreatedAt]?: string;
  [TDI15Key.UpdatedUser]?: string;
  [TDI15Key.UpdatedAt]?: string;
  [TDI15Key.UpdatedBy]?: string;
  [TDI15Key.CreatedUser]?: string;
  [TDI15Key.AssignedUser]?: string;
}

export enum ActionTakenPlan {
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED',
  Withdrawn = 'WITHDRAWN',
}

export enum NewExistingPlan {
  NewPlan = 'NEW_PLAN',
  ExistingPlan = 'EXISTING_PLAN',
}

export enum SecurityPaymentBenefits {
  AuthorizedSecurities = 'AUTHORIZED_SECURITIES',
  FinancialStatement = 'FINANCIAL_STATEMENT',
  SuretyBond = 'SURETY_BOND',
}

export enum TDIPlanAction {
  Activated = 'ACTIVATED',
  Updated = 'UPDATED',
}

export interface ActivatePlanPayload {
  id: string;
  [ActivateUpdatePlanKey.TDIPlanAction]: TDIPlanAction;
  [ActivateUpdatePlanKey.PlanType]: PlanType;
  [ActivateUpdatePlanKey.PlanNameNumber]: string;
  [ActivateUpdatePlanKey.EmployerName]: string;
  [ActivateUpdatePlanKey.DOLNumber]: string;
  [ActivateUpdatePlanKey.DCDStatus]?: DCDCaseStatus;
  [ActivateUpdatePlanKey.DCDStatusDate]: string;
  [ActivateUpdatePlanKey.NumberOfEmployees]: string | number;
  [ActivateUpdatePlanKey.IsBond]: boolean;
  [ActivateUpdatePlanKey.RequiredDeposit]: string | number;
  [ActivateUpdatePlanKey.DepositAmount]: string | number;
  [ActivateUpdatePlanKey.DepositDate]: string;
  [ActivateUpdatePlanKey.IsFinanciallySolvent]: boolean;
  [ActivateUpdatePlanKey.FinancialStatementYearEnding]: string;
  [ActivateUpdatePlanKey.Comment]: string;
  [ActivateUpdatePlanKey.TDIPlanId]: string;
}
