import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { CreateViewPayload, Service } from './types';

export function useCreateView(
  options?: UseMutationOptions<CreateViewPayload, Error, CreateViewPayload>
) {
  const { mutate: createView, isLoading } = useMutation<
    CreateViewPayload,
    Error,
    CreateViewPayload
  >({
    mutationFn: (payload: CreateViewPayload) => {
      const body = { ...payload, service: payload?.service || Service.CaseSvc };
      return responseWrapper(apiClient.createView, [body]);
    },
    ...options,
  });

  return {
    createView,
    isLoading,
  };
}
