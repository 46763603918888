export enum ComplaintKey {
  Id = 'id',
  ComplaintNumber = 'complaintNumber',

  // COMPLAINANT INFORMATION
  ComplaintType = 'complaintType',
  ComplaintName = 'complainantName',
  Complainant = 'complainant',
  ComplaintAccountId = 'complaintAccountId',

  ComplaintAccountPhoneNumber = 'complainant.phone',
  ComplaintAccountEmail = 'complainant.email',
  ComplaintAccountAddress = 'complainant.address',
  ComplaintAccountCity = 'complainant.city',
  ComplaintAccountState = 'complainant.state',
  ComplaintAccountZipCode = 'complainant.zip',
  WorkPhoneNumber = 'complainantWorkPhone',
  CellPhoneNumber = 'complainantHomeCellPhone',
  ComplaintSSN = 'complainantSSN',
  DateOfBirth = 'complainantDateOfBirth',
  Occupation = 'complainantOccupation',
  RepresentativeName = 'complainantRepresentativeName',
  ComplainantRelationship = 'complainantRelationship',
  ComplaintAddress = 'complainantAddress',
  ComplaintCity = 'complainantCity',
  ComplaintState = 'complainantState',
  ComplaintZipCode = 'complainantZip',
  ComplaintEmail = 'complainantEmailAddress',

  // EMPLOYER INFORMATION
  EmployerName = 'employerName',
  EmployerId = 'employerId',
  DoingBusinessAs = 'employerDBA',
  EmployerLaborNumber = 'employerDOLNumber',
  EmployerEmail = 'employerEmailAddress',
  EmployerAddress = 'employerAddress',
  EmployerCity = 'employerCity',
  EmployerState = 'employerState',
  EmployerZipCode = 'employerZip',
  EmployerMailingAddress = 'employerMailingAddress',
  EmployerMailingCity = 'employerMailingCity',
  EmployerMailingState = 'employerMailingState',
  EmployerMailingZipCode = 'employerMailingZip',
  POCName = 'employerPOCName',
  POCTitle = 'employerPOCTitle',
  POCEmail = 'employerPOCEmailAddress',
  POCPhoneNumber = 'employerPOCPhoneNumber',

  // DISABILITY INFORMATION
  TDIInsuranceCarrier = 'tdiInsuranceCarrier',
  TDIInsuranceCarrierId = 'tdiInsuranceCarrierId',
  TDIInsuranceCarrierName = 'tdiInsuranceCarrierName',
  HealthCareContractorId = 'hcContractorId',
  HealthCareContractorName = 'healthCareContractorName',
  HealthCareContractor = 'hcContractor',
  ComplaintFilingDate = 'complaintFilingDate',
  FilingDate = 'filingDate',
  ClaimFiledBy = 'claimFilingBy',
  DisabilityDate = 'dateOfDisability',
  DisabilityNature = 'natureOfDisability',
  UnionName = 'unionName',
  LocalNumber = 'localNumber',
  WageBase = 'wageBase',

  // COMPLAINT INFORMATION
  ComplaintDetails = 'complaintDetails',
  ComplaintId = 'complainantId',
  ComplaintAgainstId = 'complaintAgainstId',
  ComplaintAgainst = 'complaintAgainst',
  ComplaintAgainstName = 'complaintAgainstName',
  ComplaintAgainstStreet = 'complaintAgainstStreet',
  ComplaintAgainstCity = 'complaintAgainstCity',
  ComplaintAgainstState = 'complaintAgainstState',
  ComplaintAgainstZip = 'complaintAgainstZip',

  // COMPLAINT ADMINISTRATION
  Status = 'complaintStatus',
  ReceivedBy = 'complaintReceivedBy',
  ReceivedUser = 'receivedUser',
  ReceivedById = 'complaintReceivedById',
  ReceivedByName = 'receivedByName',
  ReceivedDate = 'complaintReceivedDate',
  AssignedToId = 'complaintAssignedTo',
  AssignedToName = 'assignedToName',
  AssignedUser = 'assignedUser',
  AssignedDate = 'complaintAssignedDate',
  ActionTaken = 'actionTaken',
  ActionDate = 'actionDate',
  ClosedDate = 'dateClosed',
  ClosedBy = 'closedBy',
  ClosedUser = 'closedUser',
  ClosedByName = 'closeByName',
  FraudComplaintMailedCopyDate = 'fraudComplaintMailedCopyDate',
  ResponseDueDate = 'responseDueDate',
  ForwardedToHearingDate = 'forwardedHearingDate',

  // CASE INFORMATION
  CaseId = 'wcCaseId',
  CaseNumber = 'wcCase',
  ClaimantId = 'claimantId',
  ClaimantName = 'wcCaseClaimantName',
  IIDate = 'wcCaseDateOfInjury',
  CaseEmployerName = 'wcCaseEmployerName',
  CaseEmployerId = 'wcCaseEmployerId',
  CaseEmployerLaborNumber = 'wcCaseEmployerDOLNumber',

  // AUDIT INFORMATION
  CreatedBy = 'createdBy',
  UpdatedBy = 'updatedBy',
  UpdatedAt = 'updatedAt',
  CreatedAt = 'createdAt',
}

export enum ComplaintFilterKey {
  CaseId = 'wcCase.id',
  CaseIIDate = 'wcCase.dateOfInjury',
  ClaimantId = 'wcCase.claimantId',
  EmployerId = 'wcCase.employerId',
  CaseEmployerLaborNumber = 'wcCase.employer.laborNumber',
  ComplaintAgainstId = 'complaintAgainst.id',
  ComplainantId = 'complainant.id',
  TDIInsuranceCarrierId = 'tdiInsuranceCarrier.id',
  HealthCareContractorId = 'hcContractor.id',
}
