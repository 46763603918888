import React from 'react';
import CurrencyFormat from 'react-currency-format';
import { connect } from 'react-redux';
import { IRootState } from 'src/redux/rootReducer';
import { Callback } from 'src/redux/types';
import { MoneyInputDetect } from 'src/utils';
import { Input } from '..';
import { InputProps } from '../Input';

const customInput = ({ ...props }) => <Input {...props} />;

const InputPercentage: React.FC<Props> = ({
  unFixedDecimalScale = false,
  name,
  value,
  max,
  onChange,
  ...props
}) => {
  const handleChange = (values) => {
    const { floatValue } = values;
    const returnValue = floatValue ?? null;
    onChange(name, returnValue >= 0 ? returnValue : returnValue * -1);
  };

  const isAllow = ({ floatValue }) => !max || !floatValue || floatValue <= max;

  return (
    <CurrencyFormat
      customInput={customInput}
      thousandSeparator
      fixedDecimalScale={!unFixedDecimalScale}
      decimalScale={2}
      onValueChange={handleChange}
      suffix="%"
      isAllowed={isAllow}
      {...props}
      name={name}
      value={typeof value === 'string' ? value : MoneyInputDetect(value)}
    />
  );
};

type Props = Omit<CurrencyFormat.Props, 'InputProps'> & { InputProps: InputProps } & {
  unFixedDecimalScale?: boolean;
  value: string | number;
  name: string;
  max: number;
  onChange: Callback;
};

const mapStateToProps = (state: IRootState) => ({});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(InputPercentage);
