import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { authResponseWrapper } from '../helpers';

export function useDeleteHearingRelatedPartyArchive(options?: UseMutationOptions<string, Error, string>) {
  const { mutate: deleteRelatedPartyArchive, isLoading } = useMutation<string, Error, string>({
    mutationFn: (payload: string) =>
      authResponseWrapper(apiClient.deleteHearingRelatedPartyArchive, [payload]),
    onError: ErrorService.handler,
    ...options,
  });
  return { deleteRelatedPartyArchive, isLoading };
}
