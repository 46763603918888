import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { PaginationResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { GetPropertiesParams } from '../types';
import { NonCompliantWCEmployerInsuranceReport } from './types';

export function useGetNonCompliantWCEmployerInsuranceReport(
  options?: UseQueryOptions<PaginationResponseType<NonCompliantWCEmployerInsuranceReport>, Error>
) {
  const [params, setParams] = useState<GetPropertiesParams>({});

  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetNonCompliantWCEmployerInsuranceReport,
  } = useQuery<PaginationResponseType<NonCompliantWCEmployerInsuranceReport>, Error>(
    [API_QUERIES.NON_COMPLIANT_WC_EMPLOYER_INSURANCE, { ...params, ...options }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<PaginationResponseType<NonCompliantWCEmployerInsuranceReport>>(
          apiClient.getNonCompliantWCEmployerInsuranceReport,
          params
        );
      },
      onError: ErrorService.handler,
      notifyOnChangeProps: ['data', 'isFetching'],
      keepPreviousData: true,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateGetNonCompliantWCEmployerInsuranceReport = () =>
    queryClient.invalidateQueries(API_QUERIES.NON_COMPLIANT_WC_EMPLOYER_INSURANCE);

  const {
    data: nonCompliantWCEmployerInsuranceReport,
    hasNext,
    payloadSize,
    totalRecords,
  } = data || {};

  return {
    nonCompliantWCEmployerInsuranceReport,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isError,
    isFetching,
    onGetNonCompliantWCEmployerInsuranceReport,
    setParams,
    handleInvalidateGetNonCompliantWCEmployerInsuranceReport,
  };
}
