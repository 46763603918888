import { getFirstLastName } from 'src/utils';
import { AccountKey } from '../Accounts';
import { toAuditData, toRelatedAuditData } from '../helpers';
import { EmployerAuditKey } from './keys';

export const toEmployerAudits = (data) => {
  return {
    ...data,
    data: data.data.map((employerAudit) => ({
      ...employerAudit,
      [EmployerAuditKey.EmployerID]: employerAudit?.employer?.id,
      [EmployerAuditKey.EmployerName]: employerAudit?.employer?.accountName || '',
      [EmployerAuditKey.ParentCompany]: employerAudit?.parentCompany?.accountName || '',
      [EmployerAuditKey.ParentCompanyName]: employerAudit?.parentCompany?.accountName,
      [EmployerAuditKey.ParentCompanyId]: employerAudit?.parentCompany?.id,
      [EmployerAuditKey.AuditorId]:
        employerAudit?.auditor && getFirstLastName(employerAudit?.auditor),
      [EmployerAuditKey.DOLNumberListView]: employerAudit?.employer?.laborNumber,
      [EmployerAuditKey.City]: employerAudit?.employer?.city,
      [EmployerAuditKey.State]: employerAudit?.employer?.state,
      [EmployerAuditKey.Zip]: employerAudit?.employer?.zip,
      [EmployerAuditKey.PhoneNumber]: employerAudit?.employer?.phone,
      [EmployerAuditKey.KindOfBusiness]: employerAudit?.employer?.natureOfBusiness,
      [EmployerAuditKey.TotalNumberOfEmployeesListView]: employerAudit?.employer?.numOfEmployees,
      [EmployerAuditKey.CityListView]: employerAudit?.employer?.city,
      [EmployerAuditKey.StateListView]: employerAudit?.employer?.state,
      [EmployerAuditKey.ZipListView]: employerAudit?.employer?.zip,
      [EmployerAuditKey.PhoneNumberListView]: employerAudit?.employer?.phone,
      [EmployerAuditKey.KindOfBusinessListView]: employerAudit?.employer?.orgType,
      [EmployerAuditKey.TotalHawaiiEmployeesListView]:
        employerAudit?.employer?.numOfHawaiiEmployees,
      ...toAuditData(employerAudit),
      ...toRelatedAuditData(employerAudit),
    })),
  };
};

export const toEmployerAuditDetail = (data) => ({
  ...data,
  [EmployerAuditKey.EmployerName]: data?.[EmployerAuditKey.Employer]?.[AccountKey.Account],
  [EmployerAuditKey.ParentCompanyName]:
    data?.[EmployerAuditKey.ParentCompany]?.[AccountKey.Account],
  [EmployerAuditKey.AuditorName]:
    data?.[EmployerAuditKey.Auditor] && getFirstLastName(data?.[EmployerAuditKey.Auditor]),
  [EmployerAuditKey.DOLNumber]: data?.[EmployerAuditKey.Employer]?.[AccountKey.LaborNumber],
  [EmployerAuditKey.Address]: data?.[EmployerAuditKey.Employer]?.[AccountKey.Street],
  [EmployerAuditKey.City]: data?.[EmployerAuditKey.Employer]?.[AccountKey.City],
  [EmployerAuditKey.State]: data?.[EmployerAuditKey.Employer]?.[AccountKey.State],
  [EmployerAuditKey.Zip]: data?.[EmployerAuditKey.Employer]?.[AccountKey.ZipCode],
  [EmployerAuditKey.PhoneNumber]: data?.[EmployerAuditKey.Employer]?.[AccountKey.Phone],
  [EmployerAuditKey.KindOfBusiness]: data?.[EmployerAuditKey.Employer]?.[AccountKey.NatureBusiness],
  [EmployerAuditKey.TotalNumberOfEmployees]:
    data?.[EmployerAuditKey.Employer]?.[AccountKey.NumOfEmployees],
  [EmployerAuditKey.TotalHawaiiEmployees]:
    data?.[EmployerAuditKey.Employer]?.[AccountKey.NumOfHawaiiEmployees],
  ...toAuditData(data),
});
