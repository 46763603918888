export enum TDI62Key {
  Id = 'id',
  TDI62Number = 'tdi62Num',
  TDIForm = 'tdiForm',
  TDIFormFilterKey = 'tdiForm.tdiFormNumber',
  CreatedBy = 'createdBy',
  CreatedAt = 'createdAt',
  UpdatedBy = 'updatedBy',
  CreatedUser = 'createdUser',
  UpdatedUser = 'updatedUser',
  LastModifiedDate = 'updatedAt',
  TDIPlanId = 'tdiPlanId',
  TDIPlanNumber = 'tdiPlanType',
  ActionTaken = 'actionTaken',
  ActionTakenFilterKey = 'tdiCoverage.actionTaken',
  ActionDate = 'actionDate',
  IsAccepted = 'isAccepted',
  IsRejected = 'isRejected',
  EffectiveDate = 'effectiveDate',
  RejectionReason = 'rejectionReason',
  DateReceived = 'dateReceived',
  SignatureDate = 'signatureDate',
  ContactPersonName = 'contactPersonName',
  CompletedUser = 'completedUser',
  PhoneNumber = 'phoneNumber',
  EmailAddress = 'emailAddress',
  CompletedById = 'completedById',

  // Insurance Carrier
  InsuranceCarrierName = 'insuranceCarrier',
  RawInsuranceCarrierName = 'rawInsuranceCarrierName',
  RawInsuranceCarrierId = 'rawInsuranceCarrierId',
  InsuranceCarrierId = 'insuranceCarrierId',
  InsuranceCarrierAddress = 'rawInsuranceCarrierAddress',
  InsuranceCarrierState = 'rawInsuranceCarrierState',
  InsuranceCarrierCity = 'rawInsuranceCarrierCity',
  InsuranceCarrierZipCode = 'rawInsuranceCarrierZipCode',
  AuthorizedRepresentativeName = 'rawAuthorizedRepresentativeName',

  // Employer
  EmployerName = 'employer',
  RawEmployerName = 'rawEmployerName',
  EmployerId = 'employerId',
  DOLNumber = 'rawDolNumber',
  EmployeeClassCovered = 'rawEmployeeClassCovered',
  EmployeeClassExcluded = 'rawEmployeeClassExcluded',

  // Coverage
  CoverageAction = 'rawCoverageAction',
  RequestedEffectiveDate = 'rawRequestedEffectiveDate',
  WeeklyBenefit = 'rawWeeklyBenefit',
  DayBenefitsBeginForAccident = 'rawDayBenefitsBeginForAccident',
  AdditionalInformation = 'rawAdditionalInformation',
  MaximumBenefitPeriod = 'rawMaximumBenefitPeriod',
  DayBenefitsBeginForSickness = 'rawDayBenefitsBeginForSickness',
  ReasonForCancellation = 'rawReasonForCancellation',
  TypeOfAmend = 'rawTypeOfAmend',
  AmendmentInformation = 'rawAmendmentInformation',

  // Plan Type
  TdiPlanType = 'tdiPlanType',
  CarrierId = 'carrierId',
  TdiPlanTypeId = 'tdiPlanTypeId',
  PlanType = 'planType',
}

export enum AcceptActionKey {
  Id = 'id',

  // Accept Stat Action
  EmployerId = 'employerId',
  EmployerName = 'rawEmployerName',
  DepartmentOfLaborNumber = 'rawDolNumber',
  EffectiveDate = 'effectiveDate',
  RawEmployeeClassCovered = 'rawEmployeeClassCovered',
  EmployeeClass = 'employeeClass',
  ActionTaken = 'actionTaken',
  PlanType = 'planType',
  Plan = 'plan',
  TdiPlanId = 'tdiPlanId',
  CarrierId = 'carrierId',
  InsuranceCarrierName = 'rawInsuranceCarrierName',
  ActionStatus = 'actionStatus',

  // Email of Accept Action screen
  RecipientEmailAddress = 'recipientEmailAddress',
  Subject = 'subject',
  Body = 'body',
  RawCoverageAction = 'rawCoverageAction',
  TdiCoverageAction = 'tdiCoverageAction',
  TdiPlanTypeId = 'tdiPlanTypeId',

  // Audit
  CreatedBy = 'createdBy',
  UpdatedBy = 'updatedBy',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
}

export enum RejectActionKey {
  RejectionReason = 'rejectionReason',
}

export enum ActionTaken {
  NewlyEnrolled = 'Newly Enrolled',
  Reinstated = 'Reinstated',
  Canceled = 'Canceled',
  AmendedNewlyEnrolled = 'Amended - Newly Enrolled',
  AmendedCanceled = 'Amended - Canceled',
  FlatDelete = 'Flat Delete',
}

export enum ActionEmailKey {
  RecipientEmailAddress = 'recipientEmailAddress',
  Subject = 'subject',
  Content = 'content',
  Attachments = 'attachments',
}

export enum AcceptRejectAction {
  Accepted = 'ACCEPTED',
  Rejected = 'REJECTED',
}

export enum TDICoverageAction {
  NewStatAction = 'New Stat Action',
  NewOtherAction = 'New Other Action',
}

export enum CoverageAction {
  ProvideStatutoryCoverage = 'PROVIDE_STATUTORY_COVERAGE',
  ProvideCoverage = 'PROVIDE_COVERAGE',
  Cancel = 'CANCEL',
  Reinstate = 'REINSTATE',
  Amend = 'AMEND',
}

export enum TypeOfAmend {
  Cancellation = 'CANCELLATION',
  NewlyEnrolled = 'NEWLY_ENROLLED',
}

export enum EmailActionDecision {
  Accepted = 'ACCEPTED',
  Reinstated = 'REINSTATED',
  Canceled = 'CANCELLED',
}
