import cn from 'classnames';
import { useFormikContext } from 'formik';
import { useCallback } from 'react';
import { FaPlus } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import {
  Button,
  DatePicker,
  Grid,
  Input,
  Select,
  TimePicker,
  View,
} from 'src/modules/shared-main/components';
import { FilterKey } from 'src/modules/shared-main/queries';
import { hideDialog } from 'src/redux/dialog/dialogSlice';
import { Callback } from 'src/redux/types';
import { checkboxOptions, operatorOptions } from '../helpers';
import './styles.scss';
import { TableColumn, TableColumnType } from '../../helpers';
import SearchableField from 'src/components/common/Table/SearchableField';
import {
  DATE_PICKER_DATE_FORMAT,
  DATE_PICKER_DATE_TIME_FORMAT,
  DATE_PICKER_TIME_FORMAT,
} from 'src/modules/shared-main/common/utils/momentUtils';

const CustomFilterEdit: React.FC<Props> = ({
  prefix,
  fieldOptions,
  isLoading,
  isEdit,
  handleClose,
}) => {
  const {
    values,
    setFieldTouched,
    setFieldValue,
    getFieldProps,
    errors: formikErrors,
    touched: formikTouched,
  } = useFormikContext();

  // eslint-disable-next-line security/detect-object-injection
  const errors = formikErrors?.[prefix] ?? {};
  // eslint-disable-next-line security/detect-object-injection
  const touched = formikTouched?.[prefix] ?? {};

  // eslint-disable-next-line security/detect-object-injection
  const filterValue = values?.[prefix];
  const { field } = filterValue || {};

  const setValue = (value: String) => setFieldValue(`${prefix}`, value);

  const dispatch = useDispatch();

  const selectedFieldType =
    fieldOptions.find(({ name, filterKey }) => name === field || filterKey === field)?.type ||
    TableColumnType.Text;

  const selectedSearchableFieldType = fieldOptions.find(
    ({ filterKey, name }) => filterKey === field || name === field
  )?.searchableSelectType;

  const filterFieldOptions = fieldOptions.map((fieldOption) => ({
    ...fieldOption,
    value: fieldOption?.filterKey || fieldOption?.name,
    isHide: !(fieldOption.renderIf ?? true) && !fieldOption.filterable,
  }));

  const selectedFieldOptions =
    fieldOptions.find(({ name, filterKey }) => name === field || filterKey === field)?.option ??
    checkboxOptions;

  const operators = operatorOptions.filter(
    ({ notFieldTypeCondition }) => !notFieldTypeCondition?.includes(selectedFieldType)
  );

  const handleCancel = useCallback(() => {
    // eslint-disable-next-line security/detect-object-injection
    setValue(values?.[prefix]);
    dispatch(hideDialog());
    handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (name: string, value: string) => {
    const type = fieldOptions.find(
      (field) => field.name === value || field.filterKey === value
    )?.type;
    setFieldValue(
      `${prefix}.${FilterKey.Value}`,
      type === TableColumnType.Checkbox ||
        type === TableColumnType.Select ||
        type === TableColumnType.SearchableSelect
        ? []
        : ''
    );
    setFieldValue(`${prefix}.${FilterKey.FieldType}`, type);
    setFieldValue(name, value);
  };

  return (
    <Grid.Wrap>
      <Grid.Item variant="is-full">
        <Input
          iconComponent={<FaPlus size={16} />}
          {...getFieldProps(`${prefix}.${[FilterKey.Name]}`)}
          errorMessage={touched?.[FilterKey.Name] ? (errors?.[FilterKey.Name] as string) : ''}
        />
      </Grid.Item>
      <Grid.Item variant="is-full">
        <Select
          errorMessage={touched?.[FilterKey.Field] ? (errors?.[FilterKey.Field] as string) : ''}
          hideSearchIcon
          label=""
          options={filterFieldOptions}
          {...getFieldProps(`${prefix}.${FilterKey.Field}`)}
          onChange={handleChange}
          labelControl="Field"
        />
      </Grid.Item>
      <Grid.Item variant="is-full">
        <Select
          hideSearchIcon
          labelControl="Operator"
          errorMessage={
            touched?.[FilterKey.Operator] ? (errors?.[FilterKey.Operator] as string) : ''
          }
          label={''}
          {...getFieldProps(`${prefix}.${FilterKey.Operator}`)}
          options={operators}
          onChange={setFieldValue}
        />
      </Grid.Item>
      <Grid.Item
        renderIf={
          (!!selectedFieldType && selectedFieldType === TableColumnType.Text) ||
          selectedFieldType === TableColumnType.Currency ||
          selectedFieldType === TableColumnType.PhoneNumber ||
          selectedFieldType === TableColumnType.Number
        }
        variant="is-full"
      >
        <Input
          containerClassName="cmp-filter-input"
          customIcon={
            <div className="cmp-filter-input__label">
              <p className="px-12 text-color-grey-900">Value</p>
              <span className="cmp-filter-input__indicator-separator" />
            </div>
          }
          errorMessage={touched?.value ? (errors?.value as string) : ''}
          {...getFieldProps(`${prefix}.${FilterKey.Value}`)}
        />
      </Grid.Item>
      <Grid.Item
        renderIf={
          (selectedFieldType &&
            (selectedFieldType === TableColumnType.Checkbox ||
              selectedFieldType === TableColumnType.Select)) ||
          selectedFieldType === TableColumnType.Select
        }
        variant="is-full"
      >
        <Select
          labelControl="Value"
          errorMessage={touched?.value ? (errors?.value as string) : ''}
          hideSearchIcon
          label=""
          {...getFieldProps(`${prefix}.${FilterKey.Value}`)}
          options={selectedFieldOptions}
          onChange={setFieldValue}
          isMulti
          allowSelectAllOption={false}
        />
      </Grid.Item>
      <Grid.Item
        renderIf={!!selectedFieldType && selectedFieldType === TableColumnType.Time}
        variant="is-full"
      >
        <View className="cmp-filter-input">
          <TimePicker
            errorMessage={touched?.value ? (errors?.value as string) : ''}
            label=""
            {...getFieldProps(`${prefix}.${FilterKey.Value}`)}
            selected={
              filterValue?.[FilterKey.Value]
                ? new Date(filterValue?.[FilterKey.Value] as string)
                : null
            }
            onChange={(value: Date) => setFieldValue(`${prefix}.${FilterKey.Value}`, value)}
            value={
              (filterValue?.[FilterKey.Value]
                ? new Date(filterValue?.[FilterKey.Value] as string)
                : '') as string
            }
          />
          <div
            className={cn('cmp-filter-input__label', {
              'cmp-filter-input__label-error': touched?.value && !!errors?.value,
            })}
          >
            <p className="px-12 text-color-grey-900">Value</p>
            <span className="cmp-filter-input__indicator-separator" />
          </div>
        </View>
      </Grid.Item>
      <Grid.Item
        renderIf={
          !!selectedFieldType &&
          (selectedFieldType === TableColumnType.DateTime ||
            selectedFieldType === TableColumnType.Date)
        }
        variant="is-full"
      >
        <View className="cmp-filter-input">
          <DatePicker
            errorMessage={touched?.value ? (errors?.value as string) : ''}
            label=""
            {...getFieldProps(`${prefix}.${FilterKey.Value}`)}
            value={filterValue?.[FilterKey.Value]}
            dateFormat={
              selectedFieldType === TableColumnType.Date
                ? DATE_PICKER_DATE_FORMAT
                : DATE_PICKER_DATE_TIME_FORMAT
            }
            onChange={setFieldValue}
            showTimeSelect={selectedFieldType === TableColumnType.DateTime}
            timeFormat={DATE_PICKER_TIME_FORMAT}
            placeholder={
              selectedFieldType === TableColumnType.Date ? 'mm/dd/yyyy' : 'mm/dd/yyyy hh:mm AM/PM'
            }
          />
          <div
            className={cn('cmp-filter-input__label', {
              'cmp-filter-input__label-error': touched?.value && !!errors?.value,
            })}
          >
            <p className="px-12 text-color-grey-900">Value</p>
            <span className="cmp-filter-input__indicator-separator" />
          </div>
        </View>
      </Grid.Item>
      <Grid.Item
        variant="is-full"
        renderIf={!!selectedFieldType && selectedFieldType === TableColumnType.SearchableSelect}
      >
        <SearchableField
          selectedSearchableFieldType={selectedSearchableFieldType}
          {...getFieldProps(`${prefix}.${FilterKey.Value}`)}
          onChange={setFieldValue}
          errorMessage={touched?.value ? (errors?.value as string) : ''}
          onBlur={setFieldTouched}
          value={filterValue?.[FilterKey.Value]}
          isMulti
          labelControl="Value"
        />
      </Grid.Item>
      <Grid.Item variant="is-full">
        <View isRowWrap justify="flex-end">
          <Button disabled={isLoading} variant="outline" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            type="submit"
            label={isEdit ? 'Save' : 'Create'}
            isLoading={isLoading}
            className="ml-8"
          />
        </View>
      </Grid.Item>
    </Grid.Wrap>
  );
};

type Props = {
  prefix: number;
  fieldOptions: TableColumn[];
  isLoading: boolean;
  isEdit: boolean;
  handleClose: Callback;
};

export default CustomFilterEdit;
