import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { ApiResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { toRequestReconsideration } from './helpers';
import { RequestReconsideration } from './types';

export function useGetRequestReconsideration(
  options?: UseQueryOptions<
    ApiResponseType<{ requestReconsideration: RequestReconsideration }>,
    Error,
    RequestReconsideration
  > & {
    id: string;
  }
) {
  const {
    data: requestReconsideration,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetRequestForReconsiderationById,
  } = useQuery<
    ApiResponseType<{ requestReconsideration: RequestReconsideration }>,
    Error,
    RequestReconsideration
  >([API_QUERIES.DECISIONS_REQUEST_RECONSIDERATION_DETAIL, { id: options.id }], {
    queryFn: (query) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...params] = query.queryKey;
      return responseWrapper<ApiResponseType<{ requestReconsideration: RequestReconsideration }>>(
        apiClient.getRequestForReconsideration,
        params
      );
    },
    select: ({ data }) => toRequestReconsideration(data),
    enabled: !!options.id,
    ...options,
  });

  const queryClient = useQueryClient();

  const handleInvalidateRequestReconsideration = () =>
    queryClient.invalidateQueries([API_QUERIES.DECISIONS_REQUEST_RECONSIDERATION_DETAIL]);

  return {
    requestReconsideration,
    error,
    isError,
    isLoading,
    onGetRequestForReconsiderationById,
    handleInvalidateRequestReconsideration,
  };
}
