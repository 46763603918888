import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { createResponseWrapper } from '../helpers';
import { RelatedVendorsType } from './types';

export function useCreateRelatedVendor(
  options?: UseMutationOptions<RelatedVendorsType, Error, RelatedVendorsType>
) {
  const { mutate: createRelatedVendor, isLoading } = useMutation<
    RelatedVendorsType,
    Error,
    RelatedVendorsType
  >({
    mutationFn: (payload: any) => createResponseWrapper(apiClient.createRelatedVendor, [payload]),
    onError: (error) => ErrorService.handler(error),
    ...options,
  });

  return {
    createRelatedVendor,
    isLoading,
  };
}
