import { useMutation, UseMutationOptions } from 'react-query';
import { Toastify } from 'src/services';
import apiClient from '../apiClient';
import { authResponseWrapper } from '../helpers';
import { RelatedCaseDependentsType } from './types';

export function useDeleteRelatedCaseDependent(
  options?: UseMutationOptions<any, Error, RelatedCaseDependentsType['id']>
) {
  const { mutate: deleteRelatedCaseDependents, isLoading } = useMutation<
    any,
    Error,
    RelatedCaseDependentsType['id']
  >({
    mutationFn: (payload: RelatedCaseDependentsType['id']) =>
      authResponseWrapper(apiClient.deleteRelatedCaseDependent, [payload]),
    ...options,
    onError: (err) => Toastify.error(err.message),
  });

  return {
    deleteRelatedCaseDependents,
    isLoading,
  };
}
