import { PaginationResponseType } from '../helpers';
import { RequestReconsideration } from './types';

export const toRelatedRequestReconsideration = (
  data
): PaginationResponseType<RequestReconsideration> => ({
  ...data,
  data: data.data,
});

export const toRequestReconsideration = (data) => ({
  ...data,
});
