import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { PaginationResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { GetPropertiesParams } from '../types';
import { toTDICases } from './helpers';
import { TDICase } from './types';

export function useGetAllTDICases(
  options?: UseQueryOptions<PaginationResponseType<TDICase>, Error> & {
    [key: string]: string | number | string[];
  }
) {
  const [params, setParams] = useState<GetPropertiesParams>({});

  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetAllTDICase,
  } = useQuery<PaginationResponseType<TDICase>, Error>(
    [API_QUERIES.TDICases, { ...params, ...options }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<PaginationResponseType<TDICase>>(apiClient.getAllTDICases, params);
      },
      notifyOnChangeProps: ['data', 'isFetching'],
      keepPreviousData: true,
      select: (data) => toTDICases(data),
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateAllTDICase = () => queryClient.invalidateQueries(API_QUERIES.TDICases);

  const { data: tdiCases, hasNext, payloadSize, totalRecords } = data || {};

  return {
    tdiCases,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isError,
    isFetching,
    onGetAllTDICase,
    setParams,
    handleInvalidateAllTDICase,
  };
}
