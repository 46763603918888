export enum GLAccountKey {
  Id = 'id',
  GLNumber = 'glNumber',
  AccountType = 'accountType',
  AccountDescription = 'description',
  AccountNotes = 'note',
  UpdatedAt = 'updatedAt',
  CreatedAt = 'createdAt',
  CreatedBy = 'createdBy',
  UpdatedBy = 'updatedBy',
  RelationshipId = 'relationshipId',
  RelatedType = 'type',
  CreatedUser = 'createdUser',
  UpdatedUser = 'updatedUser',
}

export enum RelatedGLAccountKey {
  Id = 'id',
  GLAccountId = 'glAccountId',
  RelatedGLAccountId = 'relatedGLAccountId',
  AccountType = 'accountType',
  AccountDescription = 'accountDescription',
  RelatedType = 'relatedType',
  GLAccountRelationId = 'glAccountRelationId',
  AccountNumber = 'glAccountNumber',
}

export enum GLAccountRelatedType {
  Reconcile = 'RECONCILE',
  RollUp = 'ROLL_UP',
}
