import {
  PaginationResponseType,
  toAddress,
  toAuditData,
  toRelatedAuditData,
  toShortAddress,
} from '../helpers';
import { DisbursementKey } from './keys';
import { Disbursement } from './types';

export const toDisbursements = (data): PaginationResponseType<Disbursement> => ({
  ...data,
  data: data.data.map((disbursement) => ({
    ...disbursement,
    [DisbursementKey.CaseNumber]: disbursement?.case?.caseNumber,
    [DisbursementKey.VendorNumber]: disbursement?.vendor?.vendorNumber,
    [DisbursementKey.ClaimantName]: disbursement?.case?.claimant?.accountName,
    [DisbursementKey.TDISFClaimantName]: disbursement?.claimant?.accountName,
    [DisbursementKey.TDISFClaimantId]: disbursement?.claimant?.id,
    [DisbursementKey.TDISFEmployerName]: disbursement?.employer?.accountName,
    [DisbursementKey.TDISFEmployerId]: disbursement?.employer?.id,
    [DisbursementKey.CaseEmployer]: disbursement?.case?.employer?.accountName,
    [DisbursementKey.CaseEmployerId]: disbursement?.case?.employer?.id,
    [DisbursementKey.ClaimantId]: disbursement?.case?.claimantId || disbursement?.claimantId,
    [DisbursementKey.VendorId]: disbursement?.vendorId,
    [DisbursementKey.VendorName]: disbursement?.vendor?.accountName,
    [DisbursementKey.ControlNumber]: disbursement?.case?.scfControlNum,
    [DisbursementKey.Employer]: disbursement?.employer?.accountName,
    [DisbursementKey.EmployerMailingCity]: disbursement?.employer?.city,
    [DisbursementKey.EmployerMailingState]: disbursement?.employer?.state,
    [DisbursementKey.EmployerMailingStreet]: disbursement?.employer?.street,
    [DisbursementKey.EmployerMailingZipCode]: disbursement?.employer?.zip,
    [DisbursementKey.EmployerDOL]: disbursement?.employer?.laborNumber,
    [DisbursementKey.CaseEmployerLaborNumber]: disbursement?.case?.employer?.laborNumber,
    [DisbursementKey.ExpenseGeneralLedgerAccountNumber]: disbursement?.expenseGLAccount?.glNumber,
    [DisbursementKey.ExpenseGeneralLedgerDescription]: disbursement?.expenseGLAccount?.description,
    [DisbursementKey.TDISFCaseNumber]: disbursement?.tdiSFCase?.tdiSFCaseNumber,
    ...toAuditData(disbursement),
    ...toRelatedAuditData(disbursement),
  })),
});

export const toDisbursement = (data) => ({
  ...data,
  [DisbursementKey.CaseNumber]: data?.case?.caseNumber,
  [DisbursementKey.SSN]: data?.case?.claimant?.ssn || data?.claimant?.ssn,
  [DisbursementKey.ClaimantName]: data?.case?.claimant?.accountName || data?.claimant?.accountName,
  [DisbursementKey.ClaimantAddress]: toShortAddress(data?.claimant),
  [DisbursementKey.CaseEmployerName]: data?.case?.employer?.accountName,
  [DisbursementKey.InsuranceCarrierName]: data?.insuranceCarrier?.accountName,
  [DisbursementKey.DateOfInjury]: data?.case?.dateOfInjury,
  [DisbursementKey.LaborNumber]: data?.case?.employer?.laborNumber,
  [DisbursementKey.VendorName]: data?.vendor?.accountName,
  [DisbursementKey.ControlNumber]: data?.case?.scfControlNum,
  [DisbursementKey.VendorNumber]: data?.vendor?.vendorNumber,
  [DisbursementKey.ExpenseGeneralLedgerAccountNumber]: data?.expenseGLAccount?.glNumber,
  [DisbursementKey.ExpenseGeneralLedgerDescription]: data?.expenseGLAccount?.description,
  [DisbursementKey.ClaimantId]: data?.case?.claimantId || data?.claimantId,
  [DisbursementKey.EmployerName]: data?.employer?.accountName,
  [DisbursementKey.EmployerMailingAddress]: toAddress(data?.employer),
  [DisbursementKey.EmployerDOL]: data?.employer?.laborNumber,
  [DisbursementKey.TDISFCaseNumber]: data?.tdiSFCase?.tdiSFCaseNumber,
  ...toAuditData(data),
});

export const toValues = (values): Disbursement => ({
  ...values,
  [DisbursementKey.DisbursementAmount]: values?.[DisbursementKey.DisbursementAmount]
    ? +values?.[DisbursementKey.DisbursementAmount]
    : null,
  [DisbursementKey.WeeksInPeriod]: values?.[DisbursementKey.WeeksInPeriod]
    ? +values?.[DisbursementKey.WeeksInPeriod]
    : null,
  [DisbursementKey.ClaimWeeklyBenefit]: values?.[DisbursementKey.ClaimWeeklyBenefit]
    ? +values?.[DisbursementKey.ClaimWeeklyBenefit]
    : null,
  [DisbursementKey.NewWeeklyRate]: values?.[DisbursementKey.NewWeeklyRate]
    ? +values?.[DisbursementKey.NewWeeklyRate]
    : null,
});
