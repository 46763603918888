import { MenuItem } from '@mui/material';
import { formatDistance } from 'date-fns';
import { PATHS } from 'src/appConfig/paths';
import { LazyCheckPoint, Text, View } from 'src/modules/shared-main/components';
import { Navigator } from 'src/services';
import {
  Notification,
  NotificationKey,
  useLazyNotifications,
  useReadNotification,
} from '../../queries';
import LoadingSkeleton from './LoadingSkeleton';
import { ViewMode } from './helpers';
import { isEmpty } from '../../common';
import EmptyNotification from './EmptyNotification';

const NotificationItems: React.FC<Props> = ({ onClose, view }) => {
  const { notifications, handleInvalidateLazyNotification, fetchNextPage, loading } =
    useLazyNotifications({
      isUnread: view === ViewMode.UnRead || null,
    });

  const { readNotification } = useReadNotification({
    onSuccess() {
      handleInvalidateLazyNotification();
    },
  });

  const handleClickNotification = (notification: Notification) => {
    readNotification({ id: notification.id, [NotificationKey.IsRead]: true });
    Navigator.navigate(notification?.[NotificationKey.LinkTo] || PATHS.root);
    onClose && onClose();
  };

  const isAllUnread = notifications.every(({ isRead }) => !isRead);

  return (
    <>
      {((view === ViewMode.UnRead && isAllUnread && !loading) || view === ViewMode.Notification) &&
        notifications?.map((notification) => {
          const { id, content, owner, isRead, title, createdAt } = notification || {};
          return (
            <MenuItem
              key={id}
              onClick={() => handleClickNotification(notification)}
              className="notification-ctn__content__item"
              sx={{
                whiteSpace: 'break-spaces',
              }}
            >
              <View fullWidth>
                <View className="notification-ctn__content__item__title__wrapper">
                  <Text size={16} className="notification-ctn__content__item__title">
                    <span className="notification-ctn__content__item__title__user">
                      {owner?.firstName} {owner?.lastName}
                    </span>
                    <span>&nbsp;{title}</span>
                  </Text>
                  {!isRead && <span className="notification-ctn__content__item__unread-icon" />}
                </View>
                <View className="notification-ctn__content notification-ctn__content__item__description">
                  {content}
                </View>
                <View className="notification-ctn__content__item__time text-is-12">
                  {formatDistance(new Date(createdAt), new Date(), {
                    addSuffix: true,
                  })}
                </View>
              </View>
            </MenuItem>
          );
        })}
      <LazyCheckPoint onFirstEnter={fetchNextPage} />
      <LoadingSkeleton isLoading={(view === ViewMode.UnRead && !isAllUnread) || loading} />
      {!loading && isEmpty(notifications) && <EmptyNotification />}
    </>
  );
};

type Props = {
  onClose: Function;
  view: ViewMode;
};

export default NotificationItems;
