import { BenefitPaymentType } from './types';

export enum BenefitPaymentSummaryKey {
  Id = 'id',
  WC3Id = 'wc3Id',
  PaymentType = 'paymentType',
  Days = 'days',
  DateFrom = 'dateFrom',
  DateTo = 'dateTo',
  PaymentNotPrevReport = 'paymentNotPrevReport',
  PriorPayment = 'priorPayment',
  TotalPaymentToDate = 'totalPaymentToDate',
  CreatedAt = 'createdAt',
}

export type BenefitPaymentSummaryPayload = {
  [BenefitPaymentSummaryKey.Id]?: string;
  [BenefitPaymentSummaryKey.WC3Id]?: string;
  [BenefitPaymentSummaryKey.PaymentType]?: BenefitPaymentType;
  [BenefitPaymentSummaryKey.Days]?: number;
  [BenefitPaymentSummaryKey.DateFrom]?: string;
  [BenefitPaymentSummaryKey.DateTo]?: string;
  [BenefitPaymentSummaryKey.PaymentNotPrevReport]?: number;
  [BenefitPaymentSummaryKey.PriorPayment]?: number;
  [BenefitPaymentSummaryKey.TotalPaymentToDate]?: number;
};

export interface GrandTotal {
  [BenefitPaymentSummaryKey.Days]?: number;
  [BenefitPaymentSummaryKey.PaymentNotPrevReport]?: number;
  [BenefitPaymentSummaryKey.PriorPayment]?: number;
  [BenefitPaymentSummaryKey.TotalPaymentToDate]?: number;
}

export interface GrandTotalAndList {
  data: BenefitPaymentSummaryPayload[];
  grandTotal: GrandTotal;
}
