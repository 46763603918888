import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { PaginationResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { GetPropertiesParams } from '../types';
import { toRelatedPrehearingsData } from './helpers';
import { PrehearingForEmployer } from './types';

export function useGetAllRelatedPrehearingForEmployer(
  options?: UseQueryOptions<PaginationResponseType<PrehearingForEmployer>, Error> & {
    caseId: string;
  }
) {
  const [params, setParams] = useState<GetPropertiesParams>({});
  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetAllRelatedDisbursements,
  } = useQuery<PaginationResponseType<PrehearingForEmployer>, Error>(
    [API_QUERIES.RELATED_PREHEARING_FOR_EMPLOYER, { ...params, caseId: options?.caseId }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<PaginationResponseType<PrehearingForEmployer>>(
          apiClient.getAllRelatedPrehearingForEmployer,
          params
        );
      },
      select: toRelatedPrehearingsData,
      notifyOnChangeProps: ['data', 'isFetching'],
      keepPreviousData: true,
      enabled: !!options?.caseId,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateAllRelatedPrehearings = () =>
    queryClient.invalidateQueries(API_QUERIES.RELATED_PREHEARING_FOR_EMPLOYER);

  const { data: prehearings, hasNext, payloadSize, totalRecords } = data || {};

  return {
    prehearings,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isError,
    isFetching,
    onGetAllRelatedDisbursements,
    handleInvalidateAllRelatedPrehearings,
    setParams,
  };
}
