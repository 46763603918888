import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { createResponseWrapper } from '../helpers';
import { TypeAndPurposesPayload } from './types';

export function useCreateRelatedTypePurposes(
  options?: UseMutationOptions<any, Error, TypeAndPurposesPayload>
) {
  const { mutate: createRelatedTypePurposes, isLoading } = useMutation<
    any,
    Error,
    TypeAndPurposesPayload
  >({
    mutationFn: (payload: TypeAndPurposesPayload) =>
      createResponseWrapper(apiClient.createRelatedTypePurposes, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    createRelatedTypePurposes,
    isLoading,
  };
}
