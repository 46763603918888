import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { ApiResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { toGLAccount } from './helpers';
import { GLAccount } from './types';

export function useGetGLAccount(
  options?: UseQueryOptions<ApiResponseType<GLAccount>, Error, GLAccount> & {
    id: GLAccount['id'];
  }
) {
  const {
    data: glAccount,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetGLAccount,
  } = useQuery<ApiResponseType<GLAccount>, Error, GLAccount>(
    [API_QUERIES.GLAccounts, { id: options.id }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<GLAccount>>(apiClient.getGLAccount, params);
      },
      select: ({ data }) => toGLAccount(data),
      enabled: !!options.id,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateGLAccount = () =>
    queryClient.invalidateQueries([API_QUERIES.GLAccounts, { id: options.id }]);

  return {
    glAccount,
    error,
    isError,
    isLoading,
    onGetGLAccount,
    handleInvalidateGLAccount,
  };
}
