import { getFirstLastName } from 'src/utils';
import { PaginationResponseType, toAuditData } from '../helpers';
import { ComplaintKey } from './keys';
import { Complaint } from './types';

export const toComplaints = (data): PaginationResponseType<Complaint> => {
  return {
    ...data,
    data: data?.data?.map((item) => ({
      ...item,
      [ComplaintKey.Complainant]: item?.complainant?.accountName,
      [ComplaintKey.ComplaintAccountAddress]: item?.complainant?.street,
      [ComplaintKey.ComplaintAccountEmail]: item?.complainant?.email,
      [ComplaintKey.ComplaintAccountState]: item?.complainant?.state,
      [ComplaintKey.ComplaintAccountCity]: item?.complainant?.city,
      [ComplaintKey.ComplaintAccountZipCode]: item?.complainant?.zip,
      [ComplaintKey.ComplaintAccountPhoneNumber]: item?.complainant?.phone,
      [ComplaintKey.ComplaintAccountId]: item?.complainant?.id,
      [ComplaintKey.CaseNumber]: item?.wcCase?.caseNumber,
      [ComplaintKey.CaseId]: item?.wcCase?.id,
      [ComplaintKey.CaseEmployerName]: item?.wcCase?.employer?.accountName,
      [ComplaintKey.ClaimantName]: item?.wcCase?.claimant?.accountName,
      [ComplaintKey.IIDate]: item?.wcCase?.dateOfInjury,
      [ComplaintKey.CaseEmployerId]: item?.wcCase?.employer?.id,
      [ComplaintKey.CaseEmployerLaborNumber]: item?.wcCase?.employer?.laborNumber,
      [ComplaintKey.ComplaintAgainst]: item?.complaintAgainst?.accountName,
      [ComplaintKey.ComplaintAgainstId]: item?.complaintAgainst?.id,
      [ComplaintKey.TDIInsuranceCarrierName]: item?.tdiInsuranceCarrier?.accountName,
      [ComplaintKey.TDIInsuranceCarrierId]: item?.tdiInsuranceCarrier?.id,
      [ComplaintKey.HealthCareContractorName]: item?.hcContractor?.accountName,
      [ComplaintKey.HealthCareContractorId]: item?.hcContractor?.id,
      [ComplaintKey.ReceivedUser]: getFirstLastName(item?.receivedUser),
      [ComplaintKey.AssignedUser]: getFirstLastName(item?.assignedUser),
      [ComplaintKey.ClosedUser]: getFirstLastName(item?.closedUser),
      ...toAuditData(item),
    })),
  };
};

export const toComplaint = (data) => ({
  ...data,
  ...toAuditData(data),
  [ComplaintKey.CaseNumber]: data?.wcCase?.caseNumber,
  [ComplaintKey.ComplaintId]: data?.complainant?.id,
  [ComplaintKey.ComplaintAgainstName]: data?.complaintAgainst?.accountName,
  [ComplaintKey.ComplaintAgainstStreet]: data?.complaintAgainst?.street,
  [ComplaintKey.ComplaintAgainstCity]: data?.complaintAgainst?.city,
  [ComplaintKey.ComplaintAgainstState]: data?.complaintAgainst?.state,
  [ComplaintKey.ComplaintAgainstZip]: data?.complaintAgainst?.zip,

  // Complainant Account
  [ComplaintKey.Complainant]: data?.complainant?.accountName,
  [ComplaintKey.ComplaintAccountPhoneNumber]: data?.complainant?.phone,
  [ComplaintKey.ComplaintAccountAddress]: data?.complainant?.street,
  [ComplaintKey.ComplaintAccountCity]: data?.complainant?.city,
  [ComplaintKey.ComplaintAccountState]: data?.complainant?.state,
  [ComplaintKey.ComplaintAccountZipCode]: data?.complainant?.zip,
  [ComplaintKey.ComplaintAccountEmail]: data?.complainant?.email,

  // Case
  [ComplaintKey.ClaimantId]: data?.wcCase?.claimant?.id,
  [ComplaintKey.ClaimantName]: data?.wcCase?.claimant?.accountName,
  [ComplaintKey.IIDate]: data?.wcCase?.dateOfInjury,
  [ComplaintKey.CaseEmployerId]: data?.wcCase?.employer?.id,
  [ComplaintKey.CaseEmployerName]: data?.wcCase?.employer?.accountName,
  [ComplaintKey.CaseEmployerLaborNumber]: data?.wcCase?.employer?.laborNumber,

  // User
  [ComplaintKey.ReceivedUser]: getFirstLastName(data?.receivedUser),
  [ComplaintKey.AssignedUser]: getFirstLastName(data?.assignedUser),
  [ComplaintKey.ClosedUser]: getFirstLastName(data?.closedUser),

  // Disability
  [ComplaintKey.TDIInsuranceCarrierName]: data?.tdiInsuranceCarrier?.accountName,
  [ComplaintKey.HealthCareContractorName]: data?.hcContractor?.accountName,
});
