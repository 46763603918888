export enum AwardWorksheetKey {
  Id = 'id',
  Case = 'Case',
  RecordNum = 'recordNum',
  WorksheetNumber = 'worksheetNumber',
  DecisionId = 'decisionId',
  EmployerAWW = 'employerAWW',
  EmployerWCR = 'employerWCR',
  FulltimeAWW = 'fulltimeAWW',
  FulltimeWCR = 'fulltimeWCR',
  MinWCR = 'minWCR',
  MaxWCR = 'maxWCR',
  TotalAWW = 'totalAWW',
  TotalWCR = 'totalWCR',
  IsPartTimeEmployment = 'isPartTimeEmployment',
  ScfWCR = 'scfWCR',
  TTDEmployerTotalWeek = 'ttdEmployerTotalWeek',
  TTDEmployerTotalAwardAmount = 'ttdEmployerTotalAwardAmount',
  TTDWaitPeriod = 'ttdWaitPeriod',
  TTDStartDateText = 'ttdStartDateText',
  TTDEndDateText = 'ttdEndDateText',
  TtdScfWcr = 'ttdScfWcr',
  TtdScfTotalAwardAmount = 'ttdScfTotalAwardAmount',
  TtdConcurrentEmploymentNumber = 'ttdConcurrentEmploymentNumber',
  TtdScfTotalNumberOfWeek = 'ttdScfTotalNumberOfWeek',
  TtdScfWaitPeriod = 'ttdScfWaitPeriod',
  TpdWeeklyCompensationRate = 'tpdWeeklyCompensationRate',
  TpdCalculatedWcr = 'tpdCalculatedWcr',
  TpdEmployerTotalWeek = 'tpdEmployerTotalWeek',
  TpdEmployerTotalPeriodAmount = 'tpdEmployerTotalPeriodAmount',
  TpdEmployerTotalAwardAmount = 'tpdEmployerTotalAwardAmount',
  TpdStartDateShortDescription = 'tpdStartDateShortDescription',
  TpdStartDateLongDescription = 'tpdStartDateLongDescription',
  TpdEndDateText = 'tpdEndDateText',
  TpdScfStartDate = 'tpdScfStartDate',
  TpdScfTotalAwardAmount = 'tpdScfTotalAwardAmount',
  TpdScfTotalNumberOfWeek = 'tpdScfTotalNumberOfWeek',
  TpdScfTotalPeriodAmount = 'tpdScfTotalPeriodAmount',
  PtdEmployerNumberOfWeek = 'ptdEmployerNumberOfWeek',
  PtdEmployerTotalAwardAmount = 'ptdEmployerTotalAwardAmount',
  PTDEmployerStartDate = 'ptdEmployerStartDate',
  PTDEmployerEndDate = 'ptdEmployerEndDate',
  PtdScfStartDate = 'ptdScfStartDate',
  PpdTotalPart = 'ppdTotalPart',
  PpdTotalWholeBody = 'ppdTotalWholeBody',
  Decision = 'decision',
  PpdTotalAward = 'ppdTotalAward',
  PpdTotalPayoutWeek = 'ppdTotalPayoutWeek',
  PpdInjuryLongDescription = 'ppdInjuryLongDescription',
  PpdInjuryTextForWholePerson = 'ppdInjuryTextForWholePerson',
  PpdEmployerPaymentBeginDate = 'ppdEmployerPaymentBeginDate',
  PpdEmployerSharePaymentBegin = 'ppdEmployerSharePaymentBegin',
  PpdEmployerSharePayoutWeek = 'ppdEmployerSharePayoutWeek',
  PpdEmployerShareTotal = 'ppdEmployerShareTotal',
  PpdFundSharePaymentBeginDate = 'ppdFundSharePaymentBeginDate',
  PpdFundShareTotal = 'ppdFundShareTotal',
  PpdFundSharePayoutWeek = 'ppdFundSharePayoutWeek',
  PpdDisfigurementTotalAmount = 'ppdDisfigurementTotalAmount',
  PpdDisfigurementDescription = 'ppdDisfigurementDescription',
  DeathDate = 'deathDate',
  DeathAwwAmount = 'deathAwwAmount',
  DeathNonDependentParentName = 'deathNonDependentParentName',
  DeathBenefitLimitMinWcr = 'deathBenefitLimitMinWcr',
  DeathBenefitLimitMaxWcr = 'deathBenefitLimitMaxWcr',
  DeathDependentBenefitLimitMin = 'deathDependentBenefitLimitMin',
  DeathDependentBenefitLimitMax = 'deathDependentBenefitLimitMax',
  DeathFuneralAllowanceAmount = 'deathFuneralAllowanceAmount',
  DeathBurialAllowanceAmount = 'deathBurialAllowanceAmount',
  DeathSpouseRelationshipToDeceased = 'deathSpouseRelationshipToDeceased',
  CaseId = 'caseId',
  AwardWorksheetID = 'awardWorksheetId',
  AwardWorksheetNumber = 'worksheetNumber',
  CaseNumber = 'caseNumber',
  CaseAdministratorName = 'caseAdministratorName',
  DecisionNumber = 'decisionNumber',
  DecisionReviewerName = 'decisionReviewerName',
  ClaimantDeathDate = 'claimantDeathDate',
  Claimant = 'claimant',
  ClaimantId = 'claimantId',
  ClaimantName = 'claimantName',
  DateOfInjury = 'dateOfInjury',
  HearingOfficer = 'hearingOfficer',
  HearingOfficerId = 'hearingOfficerId',
  HearingOfficerName = 'hearingOfficerName',
  CaseAdministratorId = 'caseAdministratorId',
  DecisionReviewerId = 'decisionReviewerId',
  UpdatedAt = 'updatedAt',
  CreatedAt = 'createdAt',
  CreatedBy = 'createdBy',
  UpdatedBy = 'updatedBy',

  // Award Worksheet Periods Of TTD && TPD
  StartDate = 'startDate',
  EndDate = 'endDate',
  NumberOfWeeks = 'numberOfWeek',
  AwardAmount = 'awardAmount',
  PeriodNumber = 'periodNumber',
  Total = 'total',
  TotalNumberOfWeek = 'totalNumberOfWeeks',
  TotalPeriodAmount = 'totalPeriodAmount',
  TotalAwardAmount = 'totalAwardAmount',
  CreatedUser = 'createdUser',
  UpdatedUser = 'updatedUser',
  AverageWeeklyWage = 'wc1AvgWeeklyWage',
  CaseAdministrator = 'caseAdministrator',
  DecisionReviewer = 'decisionReviewer',
  PartTimeEmployment = 'isPartTimeEmployment',
  PeriodAmount = 'periodAmount',

  // =====================FILTER KEY=====================

  CaseNumberFilterKey = 'decision.case.caseNumber',
  DateOfInjuryFilterKey = 'decision.case.dateOfInjury',
  ClaimantNameFilterKey = 'decision.case.claimantId',
  AverageWeeklyWageFilterKey = 'decision.case.recentWc1.avgWeeklyWage',
  CaseAdministratorFilterKey = 'decision.decisionAdministratorId',
  DecisionReviewerFilterKey = 'decision.decisionReviewerId',
  HearingOfficerFilterKey = 'decision.hearingOfficerId',
}

export enum AwardCalculatorKey {
  Calculator = 'calculator',
  CalculationType = 'calculationType',
  TotalAWW = 'totalAWW',
  IIDate = 'dateOfInjury',
  WCR = 'wcr',
  WCRAmount = 'wcrAmount',
  EmployerNumberOfAwardWeeks = 'employerNumberOfAwardWeeks',
  EmployerStartDate = 'employerStartDate',
  EmployerEndDate = 'employerEndDate',
  WeeklyCompensateRate = 'weeklyCompensateRate',
  CalculatedWCR = 'calculatedWCR',
  MinimumWCRAmountForYear = 'minimumWCRAmountForYear',
  MaximumWCRAmountForYear = 'maximumWCRAmountForYear',
  JobType = 'jobType',
  NumberOfHours = 'numberOfHours',
  TotalEarnings = 'totalEarnings',
  AverageNumberOfHours = 'averageNumberOfHours',
  HourlyRateAmount = 'hourlyRateAmount',
  AWWAdjustmentAmount = 'awwAdjusterAmount',
  MinimumWCRAmountForYearOfDeath = 'minimumWcrAmountForYearOfDeath',
  MaximumWCRAmountForYearOfDeath = 'maximumWcrAmountForYearOfDeath',
  AWWAmount = 'awwAmount',

  ComputedWCRAmount = 'computedWCRAmount',
  ActualWCRAmount = 'actualWCRAmount',
  ComputedAWWAmount = 'computedAWWAmount',
  TotalWCRAmount = 'totalWCRAmount',
  TotalSCFWCRAmount = 'totalSCFWCRAmount',
  DependentDeathBenefitLimitsMinimumAmount = 'dependentDeathBenefitLimitsMinimumAmount',
  DependentDeathBenefitLimitsMaximumAmount = 'dependentDeathBenefitLimitsMaximumAmount',
  AWWAmountForDeathBenefits = 'awwAmountForDeathBenefits',
  EmployerTotalNumberOfWeeks = 'employerTotalNumberOfWeeks',
  EmployerTotalAwardAmount = 'employerTotalAwardAmount',
  MinimumWCRUsedIndicator = 'minimumWCRUsedIndicator',
  MaximumWCRUsedIndicator = 'maximumWCRUsedIndicator',

  SCFStartDate = 'scfStartDate',

  PeriodOfTPDs = 'periodOfTpds',
  PeriodsOfTPDDisabilityAmountTable = 'periodsOfTPDDisabilityAmountTable',
  PeriodsOfTPDDisabilityWCRTable = 'periodsOfTPDDisabilityWCRTable',
  PeriodsOfTTDDisabilityAmountTable = 'periodsOfTTDDisabilityAmountTable',
}

export enum PeriodOfTpdKey {
  StartDate = 'startDate',
  EndDate = 'endDate',
  NumberOfWeeks = 'numberOfWeeks',
  PeriodAmount = 'periodAmount',
  TotalNumberOfWeeks = 'totalNumberOfWeeks',
  TotalPeriodAmount = 'totalPeriodAmount',
}

export enum Calculator {
  WCR = 'WCR',
  TTD = 'TTD',
  TPD = 'TPD',
  PTD = 'PTD',
  DeathBenefit = 'DEATH_BENEFIT',
}

export enum CalculationType {
  WCRCompute = 'WCR Compute',
  WCRCalculator = 'WCR Calculator',
  WCRTotalAndSCFCompute = 'WCR Total and SCF Compute',
  TTDAmountCompute = 'TTD Amount Compute',
  PTDStartDateAwardCompute = 'PTD Start Date Award Compute',
  PTDAmountCompute = 'PTD Amount Compute',
  PTDSCFCompute = 'PTD SCF Compute',
  TPDWCRCompute = 'TPD WCR Compute',
  TPDAmountCompute = 'TPD Amount Compute',
  AWWDeath = 'AWW Death Compute',
}

export enum AwardEmploymentKey {
  Id = 'id',
  EmployerName = 'employerName',
  EmployerId = 'employerId',
  AwardWorksheetId = 'worksheetId',
  HourlyRate = 'hourlyRate',
  HRSWorked = 'hrsWorkedWeek',
  CreatedUser = 'createdUser',
  UpdatedUser = 'updatedUser',
}

export enum JobType {
  FullTime = 'Full Time',
  PartTime = 'Part Time',
}
