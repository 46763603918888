export enum PPDInjuredBodyPartsKey {
  Id = 'id',
  IIBodyPartInjured = 'partOfBody',
  PercentInjured = 'injuredPercent',
  TotalBenefitWeeks = 'totalBenefitWeeks',
  AdjustedBenefitWeeks = 'adjustedBenefitWeeks',
  AwardWorksheetNumber = 'awardWorksheetNumber',
  AmountAwarded = 'awardAmount',
  DisfigurementAmount = 'disfigurementAmount',
  CreatedUser = 'createdUser',
  UpdatedUser = 'updatedUser',
  AwardWorksheet = 'awardWorksheet',
  WorksheetId = 'worksheetId',
}
