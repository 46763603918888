import { formatDate } from 'src/utils';
import { ApiResponseType, PaginationResponseType } from '../helpers';
import { RejectedSelfInsuredHCPReportKey } from './keys';
import { RejectedSelfInsuredHCPReport } from './types';

export const toRejectedSelfInsuredHCPReport = (
  data
): ApiResponseType<{
  reports: PaginationResponseType<RejectedSelfInsuredHCPReport>;
  total;
}> => {
  const { reports } = data?.data || {};

  const formattedReports = reports?.data?.map((report) => ({
    ...report,
    [RejectedSelfInsuredHCPReportKey.DateOfDenialLetter]: `${formatDate(
      report?.[RejectedSelfInsuredHCPReportKey.StartDate]
    )} - ${formatDate(report?.[RejectedSelfInsuredHCPReportKey.EndDate])}`,
  }));

  return {
    ...data,
    data: {
      ...data?.data,
      reports: {
        ...reports,
        data: formattedReports,
      },
    },
  };
};
