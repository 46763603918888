import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';

export function useUpdateSelectedHRS(options?: UseMutationOptions<any, Error, any>) {
  const {
    mutate: onUpdateSelectedHRS,
    isLoading: isUpdating,
    isSuccess,
    isError,
    error,
  } = useMutation<any, Error, any>({
    mutationFn: (payload: any) => responseWrapper(apiClient.updateSelectedHRS, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    onUpdateSelectedHRS,
    isUpdating,
    isSuccess,
    isError,
    error,
  };
}
