export enum RequestKey {
  RequestId = 'id',
  RequestType = 'requestType',
  RequestNumber = 'requestNumber',

  // REQUEST INFORMATION
  NumberOfPhotocopiesRequested = 'noOfPhotocopiesRequested',
  RequestDate = 'requestDate',
  RequestedBy = 'requestedBy',
  Firm = 'firm',
  RequestPhone = 'phone',
  RequestStreet = 'street',
  RequestCity = 'city',
  RequestState = 'state',
  RequestZip = 'zip',
  PurposeOfRequest = 'requestPurpose',
  Comment = 'comments',
  AssignedTo = 'assignedUser',
  AssignedToId = 'assignedUserId',
  Status = 'status',
  Denied = 'isDenied',
  CompletedDate = 'completedDate',

  YearRangeStart = 'yearRangeStart',
  YearRangeEnd = 'yearRangeEnd',
  IsNotApproved = 'isNotApproved',
  ApprovedBy = 'approvedUser',
  ApprovedByUserId = 'approvedUserId',
  ApprovedDate = 'approvedDate',

  // CASE INFORMATION
  Case = 'wcCase',
  TDICase = 'tdiCase',
  TDISFCase = 'tdiSFCase',
  CaseNumber = 'caseNumber',
  CaseNumberId = 'wcCaseId',
  TDICaseNumber = 'tdiCaseNumber',
  TDICaseNumberId = 'tdiCaseId',
  TDISFCaseNumber = 'tdiSFCaseNumber',
  TDISFCaseNumberId = 'tdiSFCaseId',
  IIDate = 'iiDate',
  EmployerName = 'employer',
  EmployerNameDetails = 'employerName',

  ClaimantName = 'claimant',
  ClaimantNameDetails = 'claimantName',
  ClaimantStreet = 'claimant.street',
  ClaimantCity = 'claimant.city',
  ClaimantState = 'claimant.state',
  ClaimantZip = 'claimant.zip',
  SSN = 'claimant.ssn',
  SSNDetails = 'ssn',

  FirstDateOfDisabilityClaimed = 'firstDateOfDisabilityClaimed',
  DisabilityDate = 'disabilityDate',
  DateOfBirth = 'dateOfBirth',
  DateOfBirthListView = 'claimant.dateOfBirth',
  ClaimantPhone = 'claimant.phone',
  ClaimantPhoneDetails = 'claimantPhone',
  ClaimantAddress = 'claimantAddress',
  EmployerId = 'employerId',
  ClaimantId = 'claimantId',

  //FILTER KEY
  FilterEmployerName = 'employer.id',
  FilterClaimantName = 'claimant.id',
  FilterDateOfIICase = 'wcCase.dateOfInjury',
  FilterFirstDateOfDisabilityClaimed = 'tdiCase.firstDateOfDisabilityClaimed',
  FilterDisabilityDate = 'tdiSFCase.disabilityDate',

  // AUDIT INFORMATION
  CreatedBy = 'createdBy',
  UpdatedBy = 'updatedBy',
  UpdatedAt = 'updatedAt',
  CreatedAt = 'createdAt',
}
