import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { PaginationResponseType, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { GetPropertiesParams } from 'src/queries/types';
import { toTDI21s } from '../TDI21s/helpers';
import { TDI21 } from '../TDI21s/types';
import { TDI21sAnnualReportSummaryPlanTypeKey } from './keys';

export function useGetTDI21sAnnualDetail(
  options?: UseQueryOptions<PaginationResponseType<TDI21>, Error> & {
    [TDI21sAnnualReportSummaryPlanTypeKey.CalendarYear]: string;
    [TDI21sAnnualReportSummaryPlanTypeKey.TypeOfPlan]: string;
  }
) {
  const [params, setParams] = useState<GetPropertiesParams>({});

  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetTDI21sAnnualDetail,
  } = useQuery<PaginationResponseType<TDI21>, Error>(
    [API_QUERIES.TDI21S_ANNUAL_REPORT_SUMMARY_PLAN_TYPE, { ...params, ...options }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<PaginationResponseType<TDI21>>(
          apiClient.getTDI21sAnnualDetail,
          params
        );
      },
      notifyOnChangeProps: ['data', 'isFetching'],
      keepPreviousData: true,
      enabled: !!options?.calendarYear && !!options?.typeOfPlan,
      select: (data) => toTDI21s(data),
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateTDI21sAnnualDetail = () =>
    queryClient.invalidateQueries(API_QUERIES.TDI21S_ANNUAL_REPORT_SUMMARY_PLAN_TYPE);

  const { data: tdi21sAnnualDetail, hasNext, payloadSize, totalRecords } = data || {};

  return {
    tdi21sAnnualDetail,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isError,
    isFetching,
    onGetTDI21sAnnualDetail,
    setParams,
    handleInvalidateTDI21sAnnualDetail,
  };
}
