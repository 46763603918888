import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { ResetUsersPasswordPayload, User } from './types';

export function useResetUsersPassword(
  options?: UseMutationOptions<User, Error, ResetUsersPasswordPayload>
) {
  const {
    mutate: onResetUsersPassword,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useMutation<User, Error, ResetUsersPasswordPayload>({
    mutationFn: (payload: ResetUsersPasswordPayload) =>
      responseWrapper(apiClient.resetUsersPassword, [payload]),
    ...options,
  });

  return {
    onResetUsersPassword,
    isLoading,
    isSuccess,
    isError,
    error,
  };
}
