import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { createResponseWrapper } from 'src/queries/helpers';
import { ErrorService } from 'src/services';
import { RelatedHearing } from './types';

export function useCreateRelatedHearing(
  options?: UseMutationOptions<RelatedHearing, Error, RelatedHearing>
) {
  const { mutate: createRelatedHearing, isLoading } = useMutation<
    RelatedHearing,
    Error,
    RelatedHearing
  >({
    mutationFn: (payload: RelatedHearing) =>
      createResponseWrapper(apiClient.createRelatedHearing, [payload]),
    onError: (error) => ErrorService.handler(error),
    ...options,
  });

  return {
    createRelatedHearing,
    isLoading,
  };
}
