import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { createResponseWrapper } from '../helpers';
import { RelatedGLAccountPayload } from './types';

export function useCreateGLRelated(
  options?: UseMutationOptions<any, Error, RelatedGLAccountPayload>
) {
  const { mutate: createGLRelated, isLoading } = useMutation<any, Error, RelatedGLAccountPayload>({
    mutationFn: (payload: RelatedGLAccountPayload) =>
      createResponseWrapper(apiClient.createGLRelated, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    createGLRelated,
    isLoading,
  };
}
