import { useMutation, UseMutationOptions } from 'react-query';
import { Toastify } from 'src/services';
import apiClient from '../apiClient';
import { createResponseWrapper } from '../helpers';
import { RelatedCaseDependentsType } from './types';

export function useCreateRelatedCaseDependent(
  options?: UseMutationOptions<RelatedCaseDependentsType, Error, RelatedCaseDependentsType>
) {
  const { mutate: createRelatedCaseDependent, isLoading } = useMutation<
    RelatedCaseDependentsType,
    Error,
    RelatedCaseDependentsType
  >({
    mutationFn: (payload: RelatedCaseDependentsType) =>
      createResponseWrapper(apiClient.createRelatedCaseDependent, [payload]),
    onError: ({ message }) => Toastify.error(message),
    ...options,
  });

  return {
    createRelatedCaseDependent,
    isLoading,
  };
}
