export enum AssociatedCasesKey {
  Id = 'id',
  CaseId = 'caseId',
  CaseNumber = 'caseNumber',
  CaseOpenedDate = 'caseOpenedDate',
  DateOfInjuryIllness = 'dateOfInjury',
  Status = 'status',
  Claimant = 'claimant',
  Employer = 'employer',
}
