import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { authResponseWrapper } from '../helpers';

export function useDeletePpdInjuredBodyParts(options?: UseMutationOptions<string, Error, string>) {
  const { mutate: deletePpdInjuredBodyParts, isLoading } = useMutation<string, Error, string>({
    mutationFn: (payload: string) =>
      authResponseWrapper(apiClient.deletePPDInjuredBodyParts, [payload]),
    onError: (error) => ErrorService.handler(error),
    ...options,
  });

  return {
    deletePpdInjuredBodyParts,
    isLoading,
  };
}
