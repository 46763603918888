import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { PaginationResponseType, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { GetPropertiesParams } from 'src/queries/types';
import { TDI46, toTDI46 } from '../TDI46s';
import { TDI46ReconsiderationAppealsReportKey, TDI46ReconsiderationAppealsType } from './keys';

export function useGetTDI46ReconsiderationAppealsDetail(
  options?: UseQueryOptions<PaginationResponseType<TDI46>, Error> & {
    [TDI46ReconsiderationAppealsReportKey.StartDate]: string;
    [TDI46ReconsiderationAppealsReportKey.EndDate]: string;
    [TDI46ReconsiderationAppealsReportKey.TDI46ReconsiderationAppealType]: TDI46ReconsiderationAppealsType;
  }
) {
  const [params, setParams] = useState<GetPropertiesParams>({});

  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetTDI46ReconsiderationAppealsDetail,
  } = useQuery<PaginationResponseType<TDI46>, Error>(
    [API_QUERIES.REPORT_TDI_46_RECONSIDERATION_APPEAL, { ...params, ...options }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<PaginationResponseType<TDI46>>(
          apiClient.getTDI46ReconsiderationAppealsDetail,
          params
        );
      },
      notifyOnChangeProps: ['data', 'isFetching'],
      keepPreviousData: true,
      enabled:
        !!options?.startDate && !!options?.endDate && !!options?.tdi46ReconsiderationAppealType,
      select: (data) => toTDI46(data),
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateTDI46ReconsiderationAppealsDetail = () =>
    queryClient.invalidateQueries(API_QUERIES.REPORT_TDI_46_RECONSIDERATION_APPEAL);

  const {
    data: tdi46ReconsiderationAppealsDetail,
    hasNext,
    payloadSize,
    totalRecords,
  } = data || {};

  return {
    tdi46ReconsiderationAppealsDetail,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isError,
    isFetching,
    onGetTDI46ReconsiderationAppealsDetail,
    setParams,
    handleInvalidateTDI46ReconsiderationAppealsDetail,
  };
}
