import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { WC3SubmittalPayload } from './types';

export function useUpdateWC3(options?: UseMutationOptions<any, Error, WC3SubmittalPayload>) {
  const {
    mutate: onEditWC3,
    isLoading: isUpdating,
    isSuccess,
    isError,
    error,
  } = useMutation<any, Error, WC3SubmittalPayload>({
    mutationFn: (payload: any) => responseWrapper(apiClient.updateWC3, [payload]),
    onError: (error) => ErrorService.handler(error),
    ...options,
  });

  return {
    onEditWC3,
    isUpdating,
    isSuccess,
    isError,
    error,
  };
}
