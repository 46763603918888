import { getFirstLastName } from 'src/utils';
import { PaginationResponseType, toRelatedAuditData } from '../helpers';
import { PortalUserAuditHistoryKey, PortalUserKey } from './keys';
import { PortalUser, PortalUserAuditHistory, PortalUserProgramTypes } from './types';

const getProgramTypes = (data: PortalUserProgramTypes[]) =>
  data?.map((item) => item?.programTypeId).join(', ') || '';

export const toPortalUserDetail = (data) => ({
  ...data,
  [PortalUserKey.MapSignedUpByUserProgramTypes]: getProgramTypes(
    data?.[PortalUserKey.PortalUserProgramTypes].filter((item) => item.isSignedUpByUser) || []
  ),
  [PortalUserKey.MapPermittedProgramTypes]: getProgramTypes(
    data?.[PortalUserKey.PortalUserProgramTypes].filter((item) => item.isPermitted) || []
  ),
});

export const toPortalUserAuditHistories = (
  data
): PaginationResponseType<PortalUserAuditHistory> => ({
  ...data,
  data: data?.data?.map((item) => ({
    ...item,
    [PortalUserAuditHistoryKey.CreatedUser]: `${item?.createdUser?.firstName} ${item?.createdUser?.lastName}`,
  })),
});

export const toPortalUser = (data): PaginationResponseType<PortalUser> => ({
  ...data,
  data: data?.data?.map((portalUser) => ({
    ...portalUser,
    [PortalUserKey.Account]: portalUser?.[PortalUserKey.Account]?.[PortalUserKey.AccountName],
    [PortalUserKey.PortalUserType]: portalUser?.[PortalUserKey.PortalUserType]?.label,
    [PortalUserKey.PortalUserTypeId]: portalUser?.[PortalUserKey.PortalUserType]?.id,
    [PortalUserKey.PortalUserClassification]:
      portalUser?.[PortalUserKey.PortalUserClassification]?.label,
  })),
});

export const toAccountPortalUsers = (data): PaginationResponseType<PortalUser> => ({
  ...data,
  data: data?.data?.map((item) => ({
    ...item,
    [PortalUserKey.PortalUserName]: getFirstLastName(item),
    [PortalUserKey.ProgramTypes]:
      item?.[PortalUserKey.PortalUserProgramTypes]?.map((item) => item?.label)?.join(', ') || '',
    ...toRelatedAuditData(item),
  })),
});
