import { useMutation, UseMutationOptions } from 'react-query';
import { Toastify } from 'src/services';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { User } from '../Users/types';

export function useRemoveUsersFromGroup(options?: UseMutationOptions<[User, User[]], Error, any>) {
  const {
    mutate: onRemoveUsersFromGroup,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useMutation<[User, User[]], Error, any>({
    mutationFn: ([params, payload]) =>
      responseWrapper(apiClient.removeUsersFromGroup, [params, payload]),
    onError: ({ message }) => Toastify.error(message),
    ...options,
  });

  return {
    onRemoveUsersFromGroup,
    isLoading,
    isSuccess,
    isError,
    error,
  };
}
