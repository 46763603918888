export enum PartyNameAndAddressKey {
  Id = 'id',
  Ids = 'ids',
  CurrentlyActive = 'isCurrentlyActive',
  Representative = 'isRepresentative',
  ActiveStartDate = 'activeStartDate',
  ActiveEndDate = 'activeEndDate',
  PartyType = 'partyType',
  PartyName = 'partyName',
  PartyRole = 'partyRole',
  HearingId = 'hearingId',
  AccountId = 'accountId',
  AccountName = 'accountName',
  ContactName = 'contactName',
  ContactId = 'contactId',
  PartyAddress = 'partyAddress',
  RepresentativeFor = 'representativeFor',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  CreatedBy = 'createdBy',
  UpdatedBy = 'updatedBy',
  //Relations
  Account = 'account',
  Contact = 'contact',
  CreatedUser = 'createdUser',
  UpdatedUser = 'updatedUser',
  CaseHearing = 'caseHearing',
  CasePartyIds = 'casePartyIds',
}

export interface PartyNameAndAddressType {
  [PartyNameAndAddressKey.Id]?: string;
  [PartyNameAndAddressKey.CurrentlyActive]?: boolean;
  [PartyNameAndAddressKey.Representative]?: boolean;
  [PartyNameAndAddressKey.ActiveStartDate]?: string;
  [PartyNameAndAddressKey.ActiveEndDate]?: string;
  [PartyNameAndAddressKey.PartyName]?: string;
  [PartyNameAndAddressKey.PartyType]?: string;
  [PartyNameAndAddressKey.PartyAddress]?: string;
  [PartyNameAndAddressKey.PartyRole]?: string;
  [PartyNameAndAddressKey.AccountId]?: string;
  [PartyNameAndAddressKey.AccountName]?: string;
  [PartyNameAndAddressKey.ContactName]?: string;
  [PartyNameAndAddressKey.ContactId]?: string;
  [PartyNameAndAddressKey.RepresentativeFor]?: string;
  [PartyNameAndAddressKey.CreatedAt]?: string;
  [PartyNameAndAddressKey.UpdatedAt]?: string;
  [PartyNameAndAddressKey.CreatedBy]?: string;
  [PartyNameAndAddressKey.UpdatedBy]?: string;
  //Relations
  [PartyNameAndAddressKey.Account]?: string;
  [PartyNameAndAddressKey.Contact]?: string;

  [PartyNameAndAddressKey.CreatedUser]?: string;
  [PartyNameAndAddressKey.UpdatedUser]?: string;
  [PartyNameAndAddressKey.CaseHearing]?: string;
  [PartyNameAndAddressKey.HearingId]?: string;
}

export interface ExistedParties {
  [PartyNameAndAddressKey.HearingId]?: string;
  [PartyNameAndAddressKey.Ids]?: string[];
}

export interface ExistedPartiesHearing {
  [PartyNameAndAddressKey.CasePartyIds]?: string[];
}
