/* eslint-disable security/detect-object-injection */
import { Dropdown, View } from 'src/modules/shared-main/components';
import { useTableProvider } from '../TableProvider';
import PinView from '../PinVew';

const TableTitle = () => {
  const { view, views, handleChangeCurrentViewId } = useTableProvider();
  const { id } = view || {};

  return (
    <View isRowWrap align="center">
      <Dropdown isLoading={!id} options={views} onChange={handleChangeCurrentViewId} value={id} />
      <PinView />
    </View>
  );
};

export default TableTitle;
