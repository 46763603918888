import { useMutation, UseMutationOptions } from 'react-query';
import { Toastify } from 'src/services';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { DCDCaseEdit } from './types';

export function useEditDCDCase(options?: UseMutationOptions<DCDCaseEdit, Error, DCDCaseEdit>) {
  const {
    mutate: editWCCase,
    isLoading: isUpdating,
    isSuccess,
    isError,
    error,
  } = useMutation<DCDCaseEdit, Error, DCDCaseEdit>({
    mutationFn: (payload: DCDCaseEdit) => responseWrapper(apiClient.updateDCDCase, [payload]),
    onError: ({ message }) => Toastify.error(message),
    ...options,
  });

  return {
    editWCCase,
    isUpdating,
    isSuccess,
    isError,
    error,
  };
}
