import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { PaginationResponseType, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { GetPropertiesParams } from 'src/queries/types';
import { toCoverageData } from './helpers';
import { WCCoveragesTypes } from './types';

export function useGetAllWCCoverages(
  options?: UseQueryOptions<PaginationResponseType<WCCoveragesTypes>, Error> & {
    employerId?: string;
    insurancePolicyId?: string;
  }
) {
  const [params, setParams] = useState<GetPropertiesParams>({});

  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetAllWCCoverages,
  } = useQuery<PaginationResponseType<WCCoveragesTypes>, Error>(
    [
      API_QUERIES.WC_COVERAGES,
      { ...params, employerId: options?.employerId, insurancePolicyId: options?.insurancePolicyId },
    ],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<PaginationResponseType<WCCoveragesTypes>>(
          apiClient.getAllWCCoverages,
          params
        );
      },
      select: (data) => toCoverageData(data),
      notifyOnChangeProps: ['data', 'isFetching'],
      enabled: true,
      keepPreviousData: true,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateAllWCCoverages = () =>
    queryClient.invalidateQueries(API_QUERIES.WC_COVERAGES);

  const { data: coverages, hasNext, totalRecords, payloadSize } = data || {};

  return {
    coverages,
    error,
    hasNext,
    totalRecords,
    payloadSize,
    isError,
    isFetching,
    onGetAllWCCoverages,
    setParams,
    handleInvalidateAllWCCoverages,
  };
}
