import { PaginationResponseType } from '../helpers';
import { RelatedPartyNameAddressHistory, RelatedPartyNameAddressHistoryKey } from './types';

export const toRelatedPartyActiveHistory = (
  data
): PaginationResponseType<RelatedPartyNameAddressHistory> => ({
  ...data,
  data: data.data.map((item) => ({
    ...item,
    [RelatedPartyNameAddressHistoryKey.Account]: item?.caseParty?.account,
    [RelatedPartyNameAddressHistoryKey.Contact]: item?.caseParty?.contact,
    [RelatedPartyNameAddressHistoryKey.ContactId]: item?.caseParty?.contact?.id,
    [RelatedPartyNameAddressHistoryKey.AccountId]: item?.caseParty?.account?.id,
    [RelatedPartyNameAddressHistoryKey.PartyRole]: item?.caseParty?.partyRole,
    [RelatedPartyNameAddressHistoryKey.PartyType]: item?.caseParty?.partyType,
    [RelatedPartyNameAddressHistoryKey.PartyName]:
      item?.caseParty?.account?.accountName || item?.caseParty?.contact?.contactName,
    [RelatedPartyNameAddressHistoryKey.AccountName]: item?.caseParty?.account?.accountName,
    [RelatedPartyNameAddressHistoryKey.ContactName]: item?.caseParty?.contact?.contactName,
  })),
});
