import { TrackingLogKey } from './keys';

export interface TrackingLog {
  [TrackingLogKey.Id]?: string;
  [TrackingLogKey.TrackingLogNumber]?: string;
  [TrackingLogKey.CreatedDate]?: string;
  [TrackingLogKey.Type]?: TrackingLogType;
  [TrackingLogKey.Description]?: string;
  [TrackingLogKey.Comments]?: string;
  [TrackingLogKey.UserProfile]?: string;
  [TrackingLogKey.CreatedUser]?: string;
  [TrackingLogKey.UpdatedUser]?: string;
}

export enum TrackingLogType {
  Compliance = 'Compliance',
  FileUploaded = 'File Uploaded',
  Other = 'Other',
}
