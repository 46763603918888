import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { createResponseWrapper } from '../helpers';
import { NotifyBouncedCheck } from '../Receipts';

export function useNotifyBouncedCheckAccount(
  options?: UseMutationOptions<NotifyBouncedCheck, Error, NotifyBouncedCheck>
) {
  const { mutate: notifyBouncedCheckAccount, isLoading } = useMutation<
    NotifyBouncedCheck,
    Error,
    NotifyBouncedCheck
  >({
    mutationFn: (payload: NotifyBouncedCheck) =>
      createResponseWrapper(apiClient.notifyBouncedCheckAccount, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    notifyBouncedCheckAccount,
    isLoading,
  };
}
