import { isArray } from 'lodash';
import { Toastify } from '.';

const MESSAGES = {
  invalidEmail: 'Invalid email format.',
  invalidPhone: 'US phone numbers should be in this format: (999) 999-9999.',
  invalidSSN: 'SSN number is invalid format',
  unknown: 'An error has occurred',
  required: 'This field is required.',
  accountNotExist: 'Username does not exist',
  accountExist: 'An account with this email already exists.',
  userExistError: 'User is already existed.',
  incorrectAccount: 'Incorrect username or password',
  incorrectCredentials: 'Incorrect login credentials. Please try again.',
  incorrectPassword: 'Incorrect password.', // pragma: allowlist secret
  onlyLetter: 'Only alphabets are allowed for this field.',
  SSNMessage: 'SSN already exists, please enter again.',
  alphanumeric: 'Alphanumeric characters',
  businessIdLength: '3-25 characters',
  noSpaces: 'No spaces',
  noSpecialCharacters: 'No special characters',
  invalidRoutingNumber: 'Invalid routing number',
  onlyLetterAndNumber: 'Only alphabets or numeric are allowed for this field.',
  invalidInformation: 'The provided information is invalid. Please try again.',
  notTrimmable: 'This field must have no whitespace at the beginning and end.',
  pleaseUseEnglishAlphabetForInput: 'Please use English alphabet for input.',
  inValidUsername: 'Please use only letters, numbers (0-9), underscore (_), dot (.), hyphen (-).',
  invalidZipCode: 'Invalid format. Please input valid digits of zip code.',
};

const handler = (error: AuthError | Error) => {
  if (isArray(error?.message)) {
    error?.message?.map((message) => Toastify.error(message));
  } else {
    Toastify.error(error?.message || MESSAGES.unknown);
  }
};

export const TYPES = {
  NotAuthorizedException: 'NotAuthorizedException',
  UserNotFoundException: 'UserNotFoundException',
  UserNotConfirmedException: 'UserNotConfirmedException',
  CodeMismatchException: 'CodeMismatchException',
  ExpiredCodeException: 'ExpiredCodeException',
  LimitExceededException: 'LimitExceededException',
  InvalidPasswordException: 'InvalidPasswordException', // pragma: allowlist secret
  UsernameExistsException: 'UsernameExistsException',
  UserLambdaValidationException: 'UserLambdaValidationException',
  badRequest: 'BAD_REQUEST',
};

export default {
  handler,
  MESSAGES,
  TYPES,
};
