export enum CaseProcessingLogKey {
  Id = 'id',
  Type = 'type',
  CaseProcessingLogId = 'logNumber',
  Description = 'description',
  AppealBoardNumber = 'appealBoardNumber',
  Comment = 'comment',
  CreatedUser = 'createdUser',
  CreatedAt = 'createdAt',
  UpdatedAt = 'updatedAt',
  LastModifiedBy = 'updatedUser',
}

export enum CPLogTypeDCD {
  AGREEMENT_FOR_HEARING_CANCELLATION = 'Agreement for Hearing Cancellation',
  AGREEMENT_FOR_HEARING_POSTPONEMENT = 'Agreement for Hearing Postponement',
  APPEAL = 'Appeal',
  ATTORNEY_FEES_REQUEST_FOR_APPROVAL = 'Attorney Fees Request for Approval',
  CARRIER_CORRESPONDENCE = 'Carrier Correspondence',
  CASE_FILE_BEING_HELD = 'Case File Being Held',
  CASE_FILE_RECEIVED = 'Case File Received',
  CASE_FILE_RETURNED_TO_LEKTRIEVER = 'Case File Returned to Lektriever',
  CASE_LIFECYCLE_STATUS_UPDATED = 'Case Lifecycle (Status) Updated',
  CHILD_SUPPORT_ORDER_RECEIVED = 'Child Support Order Received',
  CLAIMANT_ACCEPT_ADJUSTER_BENEFIT = 'Claimant Accept Adjuster Benefit',
  CLAIMANT_DECEASED = 'Claimant Deceased',
  CLAIMANT_DISFIGUREMENT_INQUIRY = 'Claimant Disfigurement Inquiry',
  DATE_INTERPRETER_CONFIRMED = 'Date Interpreter Confirmed',
  DATE_OF_TRANSFER = 'Date of Transfer',
  DENIED_REQUEST_FOR_RECONSIDERATION_WITH_APPEAL_SENT = 'Denied Request for Reconsideration with Appeal Sent',
  EMPLOYER_DENIES_LIABILITY = 'Employer Denies Liability',
  EMPLOYER_RESPONSE = 'Employer Response',
  FORM_LETTER_SENT_TO_CLAIMANT = 'Form / Letter Sent to Claimant',
  HEARING_RECORD_CREATED = 'Hearing Record Created',
  HEARING_SCHEDULED = 'Hearing Scheduled',
  HEARING_WITHDRAWN_OR_DECISION_ACCEPTED = 'Hearing Withdrawn or Decision Accepted',
  HOLD_DISBURSEMENTS = 'Hold Disbursements',
  ICA = 'ICA',
  IME_ORDER = 'IME Order',
  IME_REPORT = 'IME Report',
  INTERPRETER_CONFIRMED = 'Interpreter Confirmed',
  INTERPRETER_REQUEST_SUBMITTED = 'Interpreter Request Submitted',
  LIRAB_TEMPORARY_RETURN_PROCESSING = 'LIRAB Temporary Return: Processing of Attorney Fees/Settlement/Stipulations',
  LIRAB_TEMPORARY_RETURN_REMAND_FOR_HEARING = 'LIRAB Temporary Return: Remand for Hearing',
  MOVING_CASE_FILE = 'Moving Case File',
  ORDER = 'Order',
  POSITION_STATEMENTS = 'Position Statements',
  RECEIPT_OF_FORM_DOCUMENTATION = 'Receipt of Form/Documentation',
  RECEIPT_OR_PROCESSING_OF_ATTORNEY_FEE = 'Receipt or Processing of Attorney Fee',
  RECEIVE_BILLING_DISPUTE_REQUEST = 'Receive Billing Dispute Request',
  RECEIVE_WC79_TO_TRANSFER_CASE = 'Receive WC-79 to Transfer Case',
  RELEASE_DISBURSEMENTS_HOLD = 'Release Disbursements Hold',
  REQUESTED_LETTER_TO_WITHDRAW = 'Requested Letter to Withdraw',
  REQUEST_FOR_HEARING = 'Request for Hearing',
  REQUEST_FOR_INFORMATION_PRIOR_AWARDS = 'Request for Information/Prior awards',
  REQUEST_FOR_MEDICAL_FILES = 'Request for Medical Files',
  REQUEST_FOR_POSITION_STATEMENT_SENT = 'Request for Position Statement Sent',
  SELF_NEGOTIATION_LETTER_SENT = 'Self-Negotiation Letter Sent',
  STIPS_PENDING_REVIEW_AND_APPROVAL = "STIP'S Pending Review and Approval",
  SUPREME_COURT = 'Supreme Court',
  TDI_INQUIRY = 'TDI Inquiry',
  TREATMENT_PLANS = 'Treatment Plans',
  VR_WCRP14_RECEIVED = 'VR: WCRP-14 Received',
  VR_WCRP23_RECEIVED = 'VR: WCRP-23 Received',
  VR_WCRP24_RECEIVED = 'VR: WCRP-24 Received',
  VR_WCRP3A_RECEIVED = 'VR: WCRP-3A Received',
  WC14_EMPLOYEE_WAGE_REPORT_FOR_FIFTY_TWO_WEEKS = 'WC-14 - Employee Wage Report for Fifty-Two Weeks',
  WC18 = 'WC-18',
  WC19 = 'WC-19',
  WC3_FINAL_REPORT = 'WC-3 Final Report',
  WC3_RETURN_TO_WORK = 'WC-3 Return to Work',
  WC5 = 'WC-5',
  WC77 = 'WC-77',
  OTHER = 'Other',
}

export enum CPLogTypeTDI {
  AGREEMENT_FOR_HEARING_CANCELLATION = 'Agreement for Hearing Cancellation',
  APPEAL_FILED = 'Appeal Filed',
  APPEAL_WITHDRAWN = 'Appeal Withdrawn',
  DATE_INTERPRETER_CONFIRMED = 'Date Interpreter Confirmed',
  FORM_LETTER_SENT_TO_CLAIMANT = 'Form / Letter Sent to Claimant',
  HEARING_WITHDRAWAL = 'Hearing Withdrawal',
  INTERPRETER_CONFIRMED = 'Interpreter Confirmed',
  INTERPRETER_REQUEST_SUBMITTED = 'Interpreter Request Submitted',
  JUDICIAL_REVIEW = 'Judicial Review',
  MOVING_CASE_FILE = 'Moving Case File',
  RECEIVE_BILLING_DISPUTE_REQUEST = 'Receive Billing Dispute Request',
  RECEIVE_WC79_TO_TRANSFER_CASE = 'Receive WC-79 to Transfer Case',
  RECEIPT_OF_FORM_DOCUMENTATION = 'Receipt of Form/Documentation',
  RECEIPT_OR_PROCESSING_OF_ATTORNEY_FEE = 'Receipt or Processing of Attorney Fee',
  REQUESTED_LETTER_TO_WITHDRAW = 'Requested Letter to Withdraw',
  REQUEST_FOR_MEDICAL_FILES = 'Request for Medical Files',
  REQUEST_FOR_POSITION_STATEMENT_SENT = 'Request for Position Statement Sent',
  SELF_NEGOTIATION_LETTER_SENT = 'Self-Negotiation Letter Sent',
  TDI_INQUIRY = 'TDI Inquiry',
  CASE_LIFECYCLE_STATUS_UPDATED = 'Case Lifecycle (Status) Updated',
  HEARING_RECORD_CREATED = 'Hearing Record Created',
  CARRIER_CORRESPONDENCE = 'Carrier Correspondence',
  OTHER = 'Other',
}
