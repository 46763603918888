export enum WC3FormKey {
  Id = 'id',
  DCDCaseId = 'caseId',
  WC3Number = 'formNumber',
  DateReceived = 'dateReceived',
  WC3Type = 'wc3Type',
  PaymentYear = 'paymentYear',
  TTD = 'PPD',
  TPD = 'TPD',
  PTD = 'PTD',
  PPD = 'TTD',
  Death = 'DEATH',
  Disfigurement = 'DISFIGUREMENT',
  Medical = 'MEDICAL',
  ServicesAttendant = 'SERVICE_OF_ATTENDANT',
  Rehabilitation = 'REHABILITATION',
  Other1 = 'OTHER_1',
  Other2 = 'OTHER_2',
  CityCounty = 'CITY_COUNTY',
  Material = 'MATERIAL',
  BenefitReimbursement = 'BENEFIT_REIMBURSEMENT',
  CreatedDate = 'createdAt',
  FormType = 'formType',
  Historical = 'historical',
  BenefitPaymentSummaries = 'benefitPaymentSummaries',
  WC3TypeValue = 'wc3TypeValue',
}

export interface BenefitPaymentSummaries {
  [WC3FormKey.TTD]: string;
  [WC3FormKey.TPD]: string;
  [WC3FormKey.PTD]: string;
  [WC3FormKey.PPD]: string;
  [WC3FormKey.Death]: string;
  [WC3FormKey.Disfigurement]: string;
  [WC3FormKey.Medical]: string;
  [WC3FormKey.ServicesAttendant]: string;
  [WC3FormKey.Rehabilitation]: string;
  [WC3FormKey.CityCounty]: string;
  [WC3FormKey.Material]: string;
  [WC3FormKey.BenefitReimbursement]: string;
  [WC3FormKey.Other1]: string;
  [WC3FormKey.Other2]: string;
}

export interface WC3Form {
  [WC3FormKey.Id]: string;
  [WC3FormKey.DCDCaseId]: string;
  [WC3FormKey.WC3Number]: string;
  [WC3FormKey.WC3Type]: string;
  [WC3FormKey.PaymentYear]: string;
  [WC3FormKey.DateReceived]: string;
  [WC3FormKey.CreatedDate]: string;
  [WC3FormKey.FormType]: string;
  [WC3FormKey.WC3TypeValue]: string;
  [WC3FormKey.BenefitPaymentSummaries]: BenefitPaymentSummaries;
}
