import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { ApiResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { toPrehearingForEmployer } from './helpers';
import { PrehearingForEmployer } from './types';

export function useGetPrehearingForEmployer(
  options?: UseQueryOptions<
    ApiResponseType<{ prehearingsForDelinquent: PrehearingForEmployer }>,
    Error,
    PrehearingForEmployer
  > & {
    id: string;
  }
) {
  const {
    data: prehearingsForDelinquent,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetPrehearingForEmployer,
  } = useQuery<
    ApiResponseType<{ prehearingsForDelinquent: PrehearingForEmployer }>,
    Error,
    PrehearingForEmployer
  >([API_QUERIES.RELATED_PREHEARING_FOR_EMPLOYER, { id: options.id }], {
    queryFn: (query) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...params] = query.queryKey;
      return responseWrapper<ApiResponseType<{ prehearingsForDelinquent: PrehearingForEmployer }>>(
        apiClient.getPrehearingForEmployer,
        params
      );
    },
    enabled: !!options.id,
    select: ({ data }) => toPrehearingForEmployer(data?.prehearingsForDelinquent),
    ...options,
  });

  const queryClient = useQueryClient();

  const handleInvalidatePrehearingForEmployer = () =>
    queryClient.invalidateQueries([
      API_QUERIES.RELATED_PREHEARING_FOR_EMPLOYER,
      { id: options.id },
    ]);

  return {
    prehearingsForDelinquent,
    error,
    isError,
    isLoading,
    onGetPrehearingForEmployer,
    handleInvalidatePrehearingForEmployer,
  };
}
