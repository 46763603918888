import { PaginationResponseType, toAuditData, toRelatedAuditData } from '../helpers';
import { GLAccount, GLAccountRelated } from './types';

export const toGLAccounts = (data): PaginationResponseType<GLAccount> => ({
  ...data,
  data: data.data.map((glAccount) => ({
    ...glAccount,
    ...toAuditData(glAccount),
  })),
});

export const toGLAccount = (data): GLAccount => ({
  ...data,
  ...toAuditData(data),
});

export const toRelatedGLAccount = (data): PaginationResponseType<GLAccountRelated> => ({
  ...data,
  data: data.data.map((glAccount) => ({
    ...glAccount,
    ...toRelatedAuditData(glAccount),
  })),
});
