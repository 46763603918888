import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { PaginationResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { GetPropertiesParams } from '../types';
import { RelatedHearingNotes } from './types';

export function useGetAllRelatedNotes(
  options?: UseQueryOptions<PaginationResponseType<RelatedHearingNotes>, Error> & {
    hearingId: string;
  }
) {
  const [params, setParams] = useState<GetPropertiesParams>({});
  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetAllRelatedHearingNotes,
  } = useQuery<PaginationResponseType<RelatedHearingNotes>, Error>(
    [API_QUERIES.RELATED_NOTES, { ...params, hearingId: options?.hearingId }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<PaginationResponseType<RelatedHearingNotes>>(
          apiClient.getAllHearingRelatedNotes,
          params
        );
      },
      notifyOnChangeProps: ['data', 'isFetching'],
      keepPreviousData: true,
      enabled: !!options.hearingId,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateAllRelatedHearingNotes = () =>
    queryClient.invalidateQueries([API_QUERIES.RELATED_NOTES, {hearingId: options?.hearingId}]);

  const { data: relatedHearingNotes, hasNext, payloadSize, totalRecords } = data || {};

  return {
    relatedHearingNotes,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isError,
    isFetching,
    onGetAllRelatedHearingNotes,
    setParams,
    handleInvalidateAllRelatedHearingNotes,
  };
}