import { IoCaretDownOutline } from 'react-icons/io5';
import { FiSearch } from 'react-icons/fi';
import { components } from 'react-select';

export const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <IoCaretDownOutline size={12} />
  </components.DropdownIndicator>
);

export const IndicatorSeparator = (props) => <components.IndicatorSeparator {...props} />;

export const Control = (props) => (
  <components.Control {...props}>
    {!props.isDisabled && <FiSearch className="ml-8" size={18} />}
    {props.children}
  </components.Control>
);

export const ControlWithLabel = (props) => {
  const { labelControl } = props.selectProps;
  return (
    <components.Control {...props}>
      <p className="px-12 text-color-grey-900">{labelControl}</p>
      <span className="cmp-select__indicator-separator" />
      {props.children}
    </components.Control>
  );
};

export const ControlNoSearchIcon = (props) => (
  <components.Control {...props}>{props.children}</components.Control>
);
