import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { createResponseWrapper } from '../helpers';
import { toValues } from './helpers';
import { HC61 } from './types';

export function useCreateHC61(options?: UseMutationOptions<HC61, Error, HC61>) {
  const { mutate: createHC61, isLoading } = useMutation<HC61, Error, HC61>({
    mutationFn: (payload: HC61) => createResponseWrapper(apiClient.createHC61, [toValues(payload)]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    createHC61,
    isLoading,
  };
}
