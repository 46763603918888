import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { Toastify } from 'src/services';
import apiClient from '../apiClient';
import { ApiResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { AppPermissionsResponse } from './types';

export function useGetAppPermission(
  options?: UseQueryOptions<
    ApiResponseType<{ appPermissions: AppPermissionsResponse }>,
    Error,
    AppPermissionsResponse
  > & {
    id: string;
  }
) {
  const {
    data: appPermission,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetAppPermission,
  } = useQuery<ApiResponseType<any>, Error, AppPermissionsResponse>(
    [API_QUERIES.APP_PERMISSION, { id: options.id }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<ApiResponseType<{ appPermissions: AppPermissionsResponse }>>(
          apiClient.getAppPermissions,
          params
        );
      },
      select(data) {
        return data.data;
      },
      enabled: !!options.id,
      onError: ({ message }) => Toastify.error(message),
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateAppPermission = () =>
    queryClient.invalidateQueries([API_QUERIES.APP_PERMISSION, { id: options.id }]);

  return {
    appPermission,
    error,
    isError,
    isLoading,
    onGetAppPermission,
    handleInvalidateAppPermission,
  };
}
