export enum NotificationKey {
  Id = 'id',
  Title = 'title',
  Content = 'content',
  LinkTo = 'linkTo',
  IsRead = 'isRead',
  Owner = 'owner',
  Date = 'createdAt',
  FullName = 'fullName',
}

export type ReadNotificationPayload = {
  [NotificationKey.Id]: string;
  [NotificationKey.IsRead]: boolean;
};

export interface Owner {
  [NotificationKey.Id]: string;
  [NotificationKey.FullName]: string;
}
export interface Notification {
  [NotificationKey.Id]: string;
  [NotificationKey.Title]: string;
  [NotificationKey.Content]: string;
  [NotificationKey.LinkTo]: string;
  [NotificationKey.IsRead]: boolean;
  [NotificationKey.Owner]: Owner;
  [NotificationKey.Date]: string;
}
