import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { CustomSettings } from './types';

export function useEditCustomSettings(
  options?: UseMutationOptions<CustomSettings, Error, CustomSettings>
) {
  const {
    mutate: onEditCustomSettings,
    isLoading: isUpdating,
    isSuccess,
    isError,
    error,
  } = useMutation<CustomSettings, Error, CustomSettings>({
    mutationFn: (payload: CustomSettings) =>
      responseWrapper(apiClient.updateCustomSettings, [payload]),
    ...options,
  });

  return {
    onEditCustomSettings,
    isUpdating,
    isSuccess,
    isError,
    error,
  };
}
