import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { PaginationResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { GetPropertiesParams } from '../types';
import { toGLAccounts } from './helpers';
import { GLAccount } from './types';

export function useGetAllGLAccounts(
  options?: UseQueryOptions<PaginationResponseType<GLAccount>, Error> & {}
) {
  const [params, setParams] = useState<GetPropertiesParams>({});

  const { data, error, isError, isFetching } = useQuery<PaginationResponseType<GLAccount>, Error>(
    [API_QUERIES.GLAccounts, { ...params }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<PaginationResponseType<GLAccount>>(
          apiClient.getAllGLAccounts,
          params
        );
      },
      notifyOnChangeProps: ['data', 'isFetching'],
      keepPreviousData: true,
      select: toGLAccounts,
      onError: ErrorService.handler,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateAllGLAccounts = () => queryClient.invalidateQueries(API_QUERIES.GLAccounts);

  const { data: glAccounts, hasNext, payloadSize, totalRecords } = data || {};

  return {
    glAccounts,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isError,
    isFetching,
    setParams,
    handleInvalidateAllGLAccounts,
  };
}
