import { UserStatus } from '../Users';

export enum ApprovalGroupsKey {
  Name = 'name',
  Type = 'type',
}

export interface MyProfile {
  id: string;
  roleName?: string;
  firstName: string;
  lastName: string;
  middleName: string;
  fullName: string;
  username: string;
  email: string;
  userType: string;
  status: UserStatus;
  delegatedApproverId: string;
  managerId: string;
  individualId: string;
  roles: [
    {
      userId: string;
      roleId: string;
      role: {
        id: string;
        name: string;
        displayName: string;
        description: string;
      };
    }
  ];
  delegatedApprover: {
    id: string;
    fullName: string;
    firstName: string;
    lastName: string;
  };
  manager: {
    id: string;
    fullName: string;
    firstName: string;
    lastName: string;
  };
  individual: {
    firstName: string;
    lastName: string;
    fullName: string;
  };
  permissionGroup: {
    id: number;
    resourceName: string;
  };
  profile: {
    id: string;
    suffix: string;
    alias: string;
    nickname: string;
    phone: string;
    isClassicOnly: boolean;
    isHighContrast: boolean;
    extension: string;
    fax: string;
    mobile: string;
    emailEncoding: string;
    userId: string;
    company: string;
    title: string;
    department: string;
    division: string;
    employeeNumber: string;
    startDate: string;
    endDate: string;
    roleName: string;
    street: string;
    city: string;
    state: string;
    zipCode: string;
    country: string;
    federationId: string;
    externalId: string;
    hoLevel: string;
    isAvailableForHearing: boolean;
    isIgnoreAutomation: boolean;
    isAbleToUnlockDecisions: boolean;
    timeZone: string;
    locale: string;
    language: string;
    receiveApprovalRequestEmails: string;
    avatar: string;
  };
  approvalGroups: [
    {
      id: string;
      name: string;
      type: string;
    }
  ];
}

export interface ApprovalGroups {
  id: string;
  name: string;
  type: string;
}

export interface ProfilePayload {
  phoneNumber: string;
  mailingAddress: string;
  country: string;
  zipCode: string;
  city: string;
  state: string;
  careOf: string;
}

export type UpdateProfilePayload = {
  id?: string;
  firstName: string;
  lastName: string;
  middleName: string;
  suffix: string;
  alias: string;
  nickname: string;
  email: string;
  phone: string;
  isClassicOnly: boolean;
  isHighContrast: boolean;
  extension: string;
  fax: string;
  mobile: string;
  emailEncoding: string;
  status: string;
  company: string;
  title: string;
  department: string;
  division: string;
  employeeNumber: string;
  individualId: string;
  startDate: Date | string;
  endDate: string | Date;
  roleName: string;
  profile: string | number;
  street: string;
  city: string;
  state: string;
  zipCode: string;
  country: string;
  federationId: string;
  externalId: string;
  hoLevel: string;
  isAvailableForHearing: boolean;
  isIgnoreAutomation: boolean;
  isAbleToUnlockDecisions: boolean;
  timeZone: string;
  locale: string;
  language: string;
  receiveApprovalRequestEmails: string;
  delegatedApproverId: string;
  managerId: string;
};

export interface Avatar {
  url: string;
}

export type GetPresignedAvatarPayload = {
  fileName: string;
  contentType: string;
  type: string;
};

export type UploadAvatarPayload = {
  url: string;
  fileData: File;
};

export type UpdateAvatarPayload = {
  avatar: string;
};
