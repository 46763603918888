import { useMemo, useState } from 'react';
import { UseInfiniteQueryOptions, useInfiniteQuery, useQueryClient } from 'react-query';
import { useDebounce } from 'src/hooks';
import { isEmpty } from 'src/validations';
import apiClient from '../apiClient';
import { responseWrapper, toAddress } from '../helpers';
import { API_QUERIES } from '../keys';
import { Option } from '../types';
import { Address, AddressKey } from './type';

const pageParam = {
  take: 10,
  skip: 0,
};

export function useLazyAddress(options?: UseInfiniteQueryOptions<{ data: Address }, Error>) {
  const [inputSearch, setInputSearch] = useState<string>('');
  const debounceSearch = useDebounce(inputSearch);
  const {
    data,
    error,
    isError,
    isFetching,
    refetch: getAddress,
    fetchNextPage,
  } = useInfiniteQuery<{ data: Address }, Error>(
    [API_QUERIES.ADDRESS, pageParam, debounceSearch],
    (props): Promise<{ data: Address }> => {
      const { pageParam, queryKey } = props;
      const search = queryKey.at(-1);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      return responseWrapper<{ data: Address }>(apiClient.getAllAddress, [
        { ...pageParam, search },
      ]);
    },
    {
      keepPreviousData: true,
      getNextPageParam(lastPage, allPages) {
        if (lastPage?.data?.suggestions?.length < 10) return undefined;
        return {
          take: 10,
          skip: allPages?.length * 10,
        };
      },
      notifyOnChangeProps: ['data', 'isFetching'],
      ...options,
    }
  );

  const addresses: Option[] = useMemo(() => {
    if (isEmpty(data)) return [];
    return data?.pages?.reduce((state, page, pageIdx) => {
      return [
        ...state,
        ...page?.data?.suggestions?.map(
          (address, idx): Option => ({
            label: toAddress(address),
            value: address[AddressKey.Entries],
            index: pageIdx * 10 + idx,
            ...address,
          })
        ),
      ];
    }, []) as Option[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const queryClient = useQueryClient();

  const handleInvalidateLazyContact = () => queryClient.invalidateQueries([API_QUERIES.ADDRESS]);

  return {
    addresses,
    error,
    isError,
    loading: isFetching,
    getAddress,
    fetchNextPage,
    setInputSearch,
    handleInvalidateLazyContact,
  };
}
