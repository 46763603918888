import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { RequestReconsiderationPayload } from './types';

export function useEditRequestRequestReconsideration(
  options?: UseMutationOptions<any, Error, RequestReconsiderationPayload>
) {
  const {
    mutate: editRequestRequestReconsideration,
    isLoading: isUpdating,
    isSuccess,
    isError,
    error,
  } = useMutation<any, Error, RequestReconsiderationPayload>({
    mutationFn: (payload: RequestReconsiderationPayload) =>
      responseWrapper(apiClient.updateRequestForReconsideration, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    editRequestRequestReconsideration,
    isUpdating,
    isSuccess,
    isError,
    error,
  };
}
