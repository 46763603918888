import cn from 'classnames';
import { FormikProps } from 'formik';
import { useRef, useState } from 'react';
import { FaFilter } from 'react-icons/fa';
import { COLOR_CODE } from 'src/appConfig/constants';
import MuiPopOverFilter from 'src/components/MuiPopOverFilter';
import { Grid, Text, View } from 'src/modules/shared-main/components';
import { View as ViewType, useGetViewDetail, useUpdateView } from 'src/modules/shared-main/queries';
import FilterForm from './FilterForm';
import { ReportGroupRangeDateFilterPayload, formatViewFilter } from './helpers';
import { Tag } from 'src/queries';

const ReportGroupRangeDateFilter: React.FC<Props> = ({ view, tags }) => {
  const { id, canBeUpdated, object, filters: viewFilters } = view || {};

  const [isShow, setIsShow] = useState<boolean>(false);

  const formRef = useRef<FormikProps<ReportGroupRangeDateFilterPayload>>(null);

  const { handleInvalidateAllViewDetail } = useGetViewDetail({
    id: id,
    object: object,
    tags: tags,
    enabled: false,
  });

  const { updateView, isLoading } = useUpdateView({
    onSuccess: () => {
      handleInvalidateAllViewDetail();
    },
  });

  const toggleShow = () => {
    setIsShow(!isShow);
  };

  const handleApplyFilter = (values: ReportGroupRangeDateFilterPayload) => {
    const updatedView = {
      ...view,
      filters: viewFilters?.map((filter) => formatViewFilter(filter, values)),
      applyFilter: true,
      logic: {
        AND: viewFilters?.map(({ name }) => name),
      },
    };

    updateView(updatedView);
  };

  return (
    <MuiPopOverFilter
      isShow={isShow}
      onShow={setIsShow}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      disabled={!view || !canBeUpdated}
      label={
        <View
          isRowWrap
          align="center"
          className={cn('cmp-filter-container__label', { 'is-focus': isShow })}
        >
          <FaFilter className="cmp-filter-icon__icon" color={COLOR_CODE.GRAY_600} />
        </View>
      }
      body={
        <Grid.Wrap className="cmp-filter-container__form pb-8">
          <Grid.Item variant="is-full">
            <Text size={20} className="fw-bold text-color-grey-900">
              Filter
            </Text>
          </Grid.Item>
          <Grid.Item variant="is-full">
            <FilterForm
              viewFilters={viewFilters}
              filterFormRef={formRef}
              isLoading={isLoading}
              toggleShow={toggleShow}
              handleApplyFilter={handleApplyFilter}
            />
          </Grid.Item>
        </Grid.Wrap>
      }
    />
  );
};

type Props = {
  view: ViewType;
  tags?: Tag;
};

export default ReportGroupRangeDateFilter;
