import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { ApiResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { toRelatedAccountContactDetail } from './helpers';
import { RelatedAccountContactPayload } from './types';

export function useGetRelatedAccountContacts(
  options?: UseQueryOptions<
    ApiResponseType<{ accountContact: RelatedAccountContactPayload }>,
    Error,
    RelatedAccountContactPayload
  > & {
    contactId: RelatedAccountContactPayload['contactId'];
  }
) {
  const {
    data: accountContact,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetUserById,
  } = useQuery<
    ApiResponseType<{ accountContact: RelatedAccountContactPayload }>,
    Error,
    RelatedAccountContactPayload
  >([API_QUERIES.RELATED_ACCOUNT_CONTACT_DETAIL, { contactId: options.contactId }], {
    queryFn: (query) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...params] = query.queryKey;
      return responseWrapper<ApiResponseType<{ accountContact: RelatedAccountContactPayload }>>(
        apiClient.getRelatedAccountContact,
        params
      );
    },
    select: (data) => toRelatedAccountContactDetail(data.data.accountContact),
    enabled: !!options.contactId,
    ...options,
  });

  const queryClient = useQueryClient();

  const handleInvalidateUser = () =>
    queryClient.invalidateQueries([
      API_QUERIES.RELATED_ACCOUNT_CONTACT_DETAIL,
      { contactId: options.contactId },
    ]);

  return {
    accountContact,
    error,
    isError,
    isLoading,
    onGetUserById,
    handleInvalidateUser,
  };
}
