import { calculateDays } from 'src/utils';
import { CaseSettlementDetailRelatedKey } from './key';

const calTimeTaken = (data) => {
  const receivedDate = new Date(data?.[CaseSettlementDetailRelatedKey.ReceiveDate]);

  const approvedDate = new Date(data?.[CaseSettlementDetailRelatedKey.ApprovedDate]);

  return calculateDays(receivedDate, approvedDate);
};

// custom data
export const toCaseSettlementData = (data) => ({
  ...data,
  [CaseSettlementDetailRelatedKey.CaseNumber]: data?.case?.caseNumber,
  [CaseSettlementDetailRelatedKey.AssignTo]: data?.assignTo
    ? `${data?.assignTo?.firstName} ${data?.assignTo?.lastName}`
    : '',
  [CaseSettlementDetailRelatedKey.ApprovalReviewedBy]: `${data?.createdUser?.firstName} ${data?.createdUser?.lastName}`,
  [CaseSettlementDetailRelatedKey.TimeTaken]: calTimeTaken(data),
});
