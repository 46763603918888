import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from 'src/queries/apiClient';
import { toHCPs } from 'src/queries/HealthCarePlans/helpers';
import { PaginationResponseType, responseWrapper } from 'src/queries/helpers';
import { API_QUERIES } from 'src/queries/keys';
import { GetPropertiesParams } from 'src/queries/types';
import { HealthCarePlan } from '../HealthCarePlans';
import { ApprovedHealthCarePlansReportKey } from './keys';

export function useGetApprovedHealthCarePlansDetail(
  options?: UseQueryOptions<PaginationResponseType<HealthCarePlan>, Error> & {
    [ApprovedHealthCarePlansReportKey.PlanType]: string;
    [ApprovedHealthCarePlansReportKey.StartDate]: string;
    [ApprovedHealthCarePlansReportKey.EndDate]: string;
  }
) {
  const [params, setParams] = useState<GetPropertiesParams>({});

  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetApprovedHealthCarePlansDetail,
  } = useQuery<PaginationResponseType<HealthCarePlan>, Error>(
    [API_QUERIES.APPROVED_HEALTH_CARE_PLANS, { ...params, ...options }],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<PaginationResponseType<HealthCarePlan>>(
          apiClient.getApprovedHealthCarePlansDetail,
          params
        );
      },
      notifyOnChangeProps: ['data', 'isFetching'],
      keepPreviousData: true,
      enabled: !!options?.startDate && !!options?.endDate && !!options?.planType,
      select: (data) => toHCPs(data),
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateApprovedHealthCarePlansDetail = () =>
    queryClient.invalidateQueries(API_QUERIES.APPROVED_HEALTH_CARE_PLANS);

  const { data: approvedHealthCarePlansDetail, hasNext, payloadSize, totalRecords } = data || {};

  return {
    approvedHealthCarePlansDetail,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isError,
    isFetching,
    onGetApprovedHealthCarePlansDetail,
    setParams,
    handleInvalidateApprovedHealthCarePlansDetail,
  };
}
