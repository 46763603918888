/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Popover, Skeleton, MenuItem, MenuList } from '@mui/material';
import React, { useState } from 'react';
import Button from '../Button';
import View from '../View';
import { COLOR_CODE } from 'src/modules/shared-main/common/appConfig/constants';
import { AiFillCaretDown } from 'react-icons/ai';
import cn from 'classnames';
import './styles.scss';

const Dropdown: React.FC<Props> = ({
  options,
  value,
  onChange,
  isLoading,
  isHideLabel = false,
  isColumnFilter = false,
}) => {
  const [showDrop, setShowDrop] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleChangeValue = (e, value) => {
    setShowDrop(false);
    e.stopPropagation();
    onChange && onChange(value);
  };

  const getLabel = () => {
    const item = options?.find((item) => item.value === value);

    return item?.label;
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setShowDrop(true);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
    setShowDrop(false);
  };

  return (
    <React.Fragment>
      <Button onClick={handleClick} variant="text">
        <View isRow className="cmp-dropdown--body">
          {!isHideLabel && <div className="label">{getLabel()}</div>}
          <View className={cn('dropdown-icon-container', { 'table-header': isColumnFilter })}>
            <View>
              <AiFillCaretDown size={24} color={COLOR_CODE.GRAY_600} className="dropdown-icon" />
            </View>
          </View>
        </View>
      </Button>
      <Popover
        id="dropdown-popover"
        open={showDrop}
        anchorEl={anchorEl}
        onClose={handleClose}
        className={cn('dropdown-popover', { 'view-render': !isColumnFilter })}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: isColumnFilter ? 'right' : 'left',
        }}
      >
        {isLoading && <Skeleton width={50} />}
        <MenuList>
          {options?.map((item) => (
            <MenuItem
              key={item.value}
              onClick={(e) => handleChangeValue(e, item.value)}
              className={cn('dropdown-item', { current: item.value === value })}
            >
              {item.label}
            </MenuItem>
          ))}
        </MenuList>
      </Popover>
    </React.Fragment>
  );
};

type Props = {
  options: Array<{ label: string; value: string }>;
  value: string;
  onChange: Function;
  isLoading?: boolean;
  isHideLabel?: boolean;
  isColumnFilter?: boolean;
};

export default Dropdown;
