import { useMemo, useState } from 'react';
import { UseInfiniteQueryOptions, useInfiniteQuery } from 'react-query';
import { useDebounce } from 'src/hooks';
import { isEmpty } from 'src/validations';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { Option } from '../types';
import { TDI46Key } from './keys';
import { TDI46 } from './types';

const pageParam = {
  take: 10,
  skip: 0,
};

export function useLazyTDI46s(
  options?: UseInfiniteQueryOptions<{ data: TDI46[] }, Error> & {
    [key: string]: string | number | string[];
  }
) {
  const [inputSearch, setInputSearch] = useState<string>('');
  const debounceSearch = useDebounce(inputSearch);
  const {
    data,
    error,
    isError,
    isFetching,
    refetch: getTDI46s,
    fetchNextPage,
  } = useInfiniteQuery<{ data: TDI46[] }, Error>(
    [API_QUERIES.TDI46s, pageParam, debounceSearch, { ...options }],
    (props): Promise<{ data: TDI46[] }> => {
      const { pageParam, queryKey } = props;
      const search = queryKey.at(-2);
      const options = queryKey.at(-1);

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      return responseWrapper<{ data: TDI46[] }>(apiClient.getAllTDI46, [
        { ...pageParam, search, ...(options as Object) },
      ]);
    },
    {
      keepPreviousData: true,
      getNextPageParam(lastPage, allPages) {
        if (lastPage.data?.length < 10) return undefined;
        return {
          take: 10,
          skip: allPages.length * 10,
        };
      },
      notifyOnChangeProps: ['data', 'isFetching'],
      ...options,
    }
  );

  const TDI46: Option[] = useMemo(() => {
    if (isEmpty(data)) return [];
    return data.pages.reduce(
      (state, page, pageIdx) => [
        ...state,
        ...page.data.map(
          (TDI46, idx): Option => ({
            label: TDI46[TDI46Key.TDI46Number],
            value: TDI46[TDI46Key.Id],
            index: pageIdx * 10 + idx,
          })
        ),
      ],
      []
    ) as Option[];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return {
    TDI46,
    error,
    isError,
    loading: isFetching,
    getTDI46s,
    fetchNextPage,
    setInputSearch,
  };
}
