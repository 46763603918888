import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { toPotentialAssociated } from 'src/queries/RelatedPotentialAssociated/helpers';
import { PotentialAssociated } from 'src/queries/RelatedPotentialAssociated/types';
import apiClient from '../apiClient';
import { PaginationResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { GetPropertiesParams } from '../types';

export function useGetAllPotentialAssociated(
  options?: UseQueryOptions<PaginationResponseType<PotentialAssociated>, Error> & {
    id: string;
  }
) {
  const [params, setParams] = useState<GetPropertiesParams>({});

  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetAllPotentialAssociated,
  } = useQuery<PaginationResponseType<PotentialAssociated>, Error>(
    [API_QUERIES.RELATED_POTENTIAL_ASSOCIATED, { id: options.id }, params],
    {
      queryFn: (query) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, ...params] = query.queryKey;
        return responseWrapper<PaginationResponseType<PotentialAssociated>>(
          apiClient.getAllPotentialAssociated,
          params
        );
      },
      notifyOnChangeProps: ['data', 'isFetching'],
      keepPreviousData: true,
      select: (data) => toPotentialAssociated(data),
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidatePotentialAssociated = () =>
    queryClient.invalidateQueries([API_QUERIES.RELATED_POTENTIAL_ASSOCIATED, { id: options.id }]);

  const { data: potentialAssociated, hasNext, payloadSize, totalRecords } = data || {};

  return {
    potentialAssociated,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isError,
    isFetching,
    onGetAllPotentialAssociated,
    setParams,
    handleInvalidatePotentialAssociated,
  };
}
