export enum NonCompliantEmployerInsuranceReportKeys {
  Id = 'id',
  EmployerId = 'employerId',
  DOLNumber = 'laborNumber',
  Days = 'days',
  ActualDays = 'actualDays',
  EmployerName = 'employerName',
  WCStatus = 'wcStatus',
  WCStatusDate = 'wcStatusDate',
  TDIStatus = 'tdiStatus',
  TDIStatusDate = 'tdiStatusDate',
  PHCStatus = 'phcStatus',
  PHCStatusDate = 'phcStatusDate',
}
