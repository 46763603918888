import { useState } from 'react';
import { UseQueryOptions, useQuery, useQueryClient } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { PaginationResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { GetPropertiesParams } from '../types';
import { toTDIPlans } from './helpers';
import { TDIPlan } from './types';

export function useGetAllTDIPlans(
  options?: UseQueryOptions<PaginationResponseType<TDIPlan>, Error> & {
    [key: string]: string | number | string[];
  }
) {
  const [params, setParams] = useState<GetPropertiesParams>({});
  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetAllTDIPlans,
  } = useQuery<PaginationResponseType<TDIPlan>>([API_QUERIES.TDIPlans, { ...params, ...options }], {
    queryFn: (query) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...params] = query.queryKey;

      return responseWrapper<PaginationResponseType<TDIPlan>>(apiClient.getAllTDIPlans, params);
    },
    onError: ErrorService.handler,
    notifyOnChangeProps: ['data', 'isFetching'],
    keepPreviousData: true,
    select: toTDIPlans,
    ...options,
  });

  const queryClient = useQueryClient();

  const handleInvalidateTDIPlans = () => queryClient.invalidateQueries(API_QUERIES.TDIPlans);

  const { data: tdiPlans, hasNext, payloadSize, totalRecords } = data || {};

  return {
    tdiPlans,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isError,
    isFetching,
    onGetAllTDIPlans,
    setParams,
    handleInvalidateTDIPlans,
  };
}
