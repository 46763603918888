import { AccountDetail } from '../Accounts';
import { HealthCarePlan, HealthCarePlanStatus, HealthCarePlanType } from '../HealthCarePlans';
import { HC61Key, NetProfitKey } from './keys';

export type NetProfit = {
  [NetProfitKey.Amount]: number | string;
  [NetProfitKey.Year]: string;
};

export type HC61 = {
  id: string;
  [HC61Key.HC61Number]?: string;
  [HC61Key.HC4Id]?: string;
  [HC61Key.HealthCarePlan]?: string;
  [HC61Key.HCPlan]?: HealthCarePlan;
  [HC61Key.HealthCarePlanId]?: string;
  [HC61Key.HealthCarePlanType]?: HealthCarePlanType;
  [HC61Key.FormActionTaken]?: ActionTakenHC61;
  [HC61Key.ReviewWith]?: ReviewWith;

  // Employer
  [HC61Key.EmployerId]?: string;
  [HC61Key.EmployerName]?: string;
  [HC61Key.DOLNumber]?: string;
  [HC61Key.Employer]?: AccountDetail;
  [HC61Key.EmployerType]?: EmployerType;
  [HC61Key.OtherEmployer]?: string;
  [HC61Key.MailingAddressInHawaii]?: string;
  [HC61Key.EmployerMailingState]?: string;
  [HC61Key.EmployerMailingCity]?: string;
  [HC61Key.EmployerMailingZipCode]?: string;
  [HC61Key.StreetAddressInHawaii]?: string;
  [HC61Key.EmployerHawaiiCity]?: string;
  [HC61Key.EmployerHawaiiZipCode]?: string;
  [HC61Key.EmployerHawaiiState]?: string;
  [HC61Key.EmployerHawaiiPhone]?: string;
  [HC61Key.FaxNumber]?: string;
  [HC61Key.NatureOfBusiness]?: string;
  [HC61Key.OtherBusinessLocationsInHawaii]?: string;
  [HC61Key.NumberOfCoveredHawaiiEmployees]?: string;
  [HC61Key.TotalNumberOfEmployees]?: string;
  [HC61Key.ParentCompany]?: AccountDetail;
  [HC61Key.ParentCompanyId]?: string;
  [HC61Key.ParentCompanyAddress]?: string;
  [HC61Key.ParentCompanyStreet]?: string;
  [HC61Key.ParentCompanyCity]?: string;
  [HC61Key.ParentCompanyState]?: string;
  [HC61Key.ParentCompanyZip]?: string;
  [HC61Key.ParentCompanyStockOwnershipPercent]?: number;
  [HC61Key.ConductingBusinessUnderAnotherName]?: boolean;
  [HC61Key.OtherBusinessName]?: string;
  [HC61Key.OtherBusinessAddress]?: string;
  [HC61Key.OtherBusinessCity]?: string;
  [HC61Key.OtherBusinessState]?: string;
  [HC61Key.OtherBusinessZip]?: string;
  [HC61Key.OtherBusinessNature]?: string;
  [HC61Key.DateBusinessCommencedInHawaii]?: string;
  [HC61Key.NetProfitOrLossAfterTaxes]?: Array<NetProfit>;
  [HC61Key.Year]?: string;
  [HC61Key.Amount]?: string;

  // Contact
  [HC61Key.IndividualResponsibleFinancials]?: string;
  [HC61Key.IndividualResponsibleFinancialsId]?: string;
  [HC61Key.IndividualResponsibleForSI]?: string;
  [HC61Key.IndividualResponsibleForSIId]?: string;
  [HC61Key.AddressForFinancials]?: string;
  [HC61Key.ContactPhoneNumberForFinancials]?: string;
  [HC61Key.ContactFaxForFinancials]?: string;
  [HC61Key.ContactTitleForFinancials]?: string;
  [HC61Key.AddressForSI]?: string;
  [HC61Key.ContactPhoneNumberForSI]?: string;
  [HC61Key.ContactFaxForSI]?: string;
  [HC61Key.ContactTitleForSI]?: string;

  // Health Care Insurance
  [HC61Key.CurrentHealthCareContractors]?: string;
  [HC61Key.RejectedOrPolicyCanceled]?: boolean;
  [HC61Key.HealthCareContractorId]?: string;
  [HC61Key.HealthCareContractorName]?: string;
  [HC61Key.DateOfPolicy]?: string;
  [HC61Key.ReasonForRejectionOrCancellation]?: string;

  // Claim
  [HC61Key.OwnOrganizationAdministrator]?: string;
  [HC61Key.OwnOrganizationAdministratorId]?: string;
  [HC61Key.OwnOrganizationAdministratorAddress]?: string;
  [HC61Key.NameOfAdministrator]?: string;
  [HC61Key.OwnOrganizationAdministratorPhoneNumber]?: string;
  [HC61Key.OwnOrganizationAdministratorFax]?: string;
  [HC61Key.OwnOrganizationAdministratorTitle]?: string;
  [HC61Key.OwnOrganizationAdministratorName]?: string;
  [HC61Key.NameOutsideOrganization]?: string;
  [HC61Key.OutsideOrganizationAddress]?: string;
  [HC61Key.OutsideOrganizationPhoneNumber]?: string;
  [HC61Key.OutsideOrganizationFax]?: string;
  [HC61Key.OutsideOrganizationTitle]?: string;
  [HC61Key.OutsideOrganizationZip]?: string;
  [HC61Key.OutsideOrganizationCity]?: string;
  [HC61Key.OutsideOrganizationState]?: string;
  [HC61Key.OtherOutsideOrganization]?: string;
  [HC61Key.AdminHasAuthorityToProvideBenefits]?: boolean;
  [HC61Key.ExplainLimitations]?: string;
  [HC61Key.AdminPerformedAtMultipleLocations]?: boolean;
  [HC61Key.SupplementedByStopLossPolicy]?: boolean;
  [HC61Key.LitigProceedingPendingThreatened]?: boolean;
  [HC61Key.ExplainLitigationProceeding]?: string;
  [HC61Key.CompanyUsedToDetermineFinSolvency]?: string;
  [HC61Key.RelationshipOfCompanyToApplicant]?: string;
  [HC61Key.PercentCompanyOwnership]?: number;

  // Signature
  [HC61Key.SignatureDate]?: string;
  [HC61Key.SignatureName]?: string;
  [HC61Key.SignatureTitle]?: string;
  [HC61Key.SignaturePhone]?: string;
  [HC61Key.SignatureFax]?: string;
  [HC61Key.SignatureEmail]?: string;

  //Activate
  [HC61Key.DCDStatus]?: string;
  [HC61Key.HealthCarePlanNumber]?: string;
  [HC61Key.PlanType]?: string;
  [HC61Key.PlanStatus]?: string;
  [HC61Key.PlanStatusEffectiveDate]?: string;
  [HC61Key.BenefitLevel]?: string;
  [HC61Key.FinancialStatementYearEnding]?: string;
  [HC61Key.LastAuditDate]?: string;

  // Audit
  [HC61Key.CreatedBy]?: string;
  [HC61Key.CreatedAt]?: string;
  [HC61Key.UpdatedAt]?: string;
  [HC61Key.UpdatedBy]?: string;

  [HC61Key.Funding]?: Funding;
  [HC61Key.NewOrExistingPlan]?: ExistingPlan;
  [HC61Key.IsFinancialsReceived]?: boolean;
  [HC61Key.IsStopLossRequested]?: boolean;
  [HC61Key.IsPlanDocumentReceived]?: boolean;
  [HC61Key.DCDReviewForm]?: string;
  [HC61Key.DateAllDocumentsReceived]?: string;
  [HC61Key.DateConfirmationLetterSentToER]?: string;
  [HC61Key.HRSSection]?: HC61HRSSection;
  [HC61Key.PlanEffectiveDate]?: string;
  [HC61Key.DateSentToAudit]?: string;
  [HC61Key.IsAuditApproved]?: boolean;
  [HC61Key.IsLOGReceived]?: boolean;
  [HC61Key.IsStopLossObtained]?: boolean;
  [HC61Key.DateOfCNXOrWByEmployer]?: string;
  [HC61Key.DateOfDenialLetter]?: string;
  [HC61Key.PlanReviewer]?: string;
  [HC61Key.PlanReviewerId]?: string;
  [HC61Key.DatePlanReviewed]?: string;
  [HC61Key.DateOfCouncilMeeting]?: string;
  [HC61Key.AdvisoryCouncilREC]?: string;
  [HC61Key.IsRECSignedByCouncilChair]?: boolean;
  [HC61Key.IsRECSignedByDirector]?: boolean;
  [HC61Key.IsAmendmentSentToEmployer]?: boolean;
  [HC61Key.IsAmendmentReceivedFromEmployer]?: boolean;
  [HC61Key.IsAmendmentReviewedAndAccepted]?: boolean;
  [HC61Key.IsDisapprovalSentToEmployer]?: boolean;
  [HC61Key.Funding]?: Funding;
};

export interface ActivatePlanHC61Payload {
  id: string;
  [HC61Key.FormActionTaken]?: ActionTakenHC61;
  [HC61Key.ReviewWith]?: string;
  [HC61Key.PlanStatus]: HealthCarePlanStatus;
  [HC61Key.EmployerId]: string;
  [HC61Key.NumberOfCoveredHawaiiEmployees]: string;
  [HC61Key.EmployerName]: string;
  [HC61Key.DOLNumber]: string;
  [HC61Key.DCDStatus]: string;
  [HC61Key.DCDStatusDate]: string;
  [HC61Key.HealthCarePlanName]: string;
  [HC61Key.HealthCarePlanNumber]: string;
  [HC61Key.PlanType]: HealthCarePlanType;
  [HC61Key.PlanStatusEffectiveDate]: string;
  [HC61Key.BenefitLevel]: string;
  [HC61Key.FinancialStatementYearEnding]: string;
  [HC61Key.LastAuditDate]: string;
  [HC61Key.HRSSection]: HC61HRSSection;
}

export enum EmployerType {
  Corporation = 'Corporation',
  SolePartnership = 'Sole Partnership',
  Partnership = 'Partnership',
  Other = 'Other',
}

export enum ExistingPlan {
  NewPlan = 'New Plan',
  ExistingPlan = 'Existing Plan',
}

export enum Funding {
  FI = 'FI',
  SelfInsured = 'SELF_INSURED',
}

export enum HC61HRSSection {
  A7 = '7(a)',
  B7 = '7(b)',
}

export enum ReviewWith {
  Plans = 'Plans',
  Audit = 'Audit',
  Employer = 'Employer',
  AdvisoryCouncil = 'Advisory Council',
}

export enum ActionTakenHC61 {
  Updated = 'Updated',
  Activated = 'Activated',
}
