import { useState } from 'react';
import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { Toastify } from 'src/services';
import apiClient from '../apiClient';
import { PaginationResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { GetPropertiesParams } from '../types';
import { Group } from './types';

export function useGetAllGroups(options?: UseQueryOptions<PaginationResponseType<Group>, Error>) {
  const [params, setParams] = useState<GetPropertiesParams>({});
  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetAllGroup,
  } = useQuery<PaginationResponseType<Group>, Error>([API_QUERIES.GROUPS, params], {
    queryFn: (query) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...params] = query.queryKey;
      return responseWrapper<PaginationResponseType<Group>>(apiClient.getAllGroup, params);
    },
    onError: ({ message }) => Toastify.error(message),
    notifyOnChangeProps: ['data', 'isFetching'],
    keepPreviousData: true,
    ...options,
  });

  const queryClient = useQueryClient();

  const handleInvalidateAllGroup = () => queryClient.invalidateQueries(API_QUERIES.GROUPS);

  const { data: groups, hasNext, payloadSize, totalRecords } = data || {};

  return {
    groups,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isError,
    isFetching,
    onGetAllGroup,
    setParams,
    handleInvalidateAllGroup,
  };
}
