import { MUIDataTableCustomHeadRenderer, MUISortOptions } from 'mui-datatables';
import React from 'react';
import { Dropdown, View } from 'src/modules/shared-main/components';
import { WrapClipText, wrapTextOptions } from '../helpers';
import '../styles.scss';
import { TableCell, TableSortLabel, Tooltip } from '@mui/material';
import cn from 'classnames';
import { useTableProvider } from '../TableProvider';

const TableHead: React.FC<Props> = ({ columnMeta, handleToggleColumn, sortOrder }) => {
  const { wrapClipColumns, handleChangeWrapClipColumns } = useTableProvider();
  const { index, name: columnName, label, sort } = columnMeta;
  const isWrapColumn = wrapClipColumns.includes(columnName);

  const handleChangeWrapClipText = (value: WrapClipText) => {
    if (value === WrapClipText.Clip) {
      handleChangeWrapClipColumns(wrapClipColumns.filter((column) => column !== columnName));
    } else {
      !isWrapColumn && handleChangeWrapClipColumns([...wrapClipColumns, columnName]);
    }
  };

  return (
    <th className="MuiTableCell-root MuiTableCell-head MuiTableCell-sizeMedium cmp-table-head">
      <View align="center" className={cn({ 'py-55': sort }, { 'py-145': !sort })} isRowWrap>
        {sort ? (
          <Tooltip placement="bottom" title={'Sort'}>
            <TableSortLabel
              className="cmp-table-head__sort-button"
              onClick={() => handleToggleColumn(index)}
              active={sortOrder?.name === columnName}
              direction={sortOrder.direction}
            >
              {label}
            </TableSortLabel>
          </Tooltip>
        ) : (
          <TableCell component={'div'}>{label}</TableCell>
        )}
        <View className="px-4">
          <Dropdown
            options={wrapTextOptions}
            value={isWrapColumn ? WrapClipText.Wrap : WrapClipText.Clip}
            isHideLabel
            onChange={handleChangeWrapClipText}
            isColumnFilter
          />
        </View>
      </View>
    </th>
  );
};

type Props = {
  columnMeta: MUIDataTableCustomHeadRenderer;
  handleToggleColumn: (columnIndex: number) => void;
  sortOrder: MUISortOptions;
};

export default TableHead;
