import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { createResponseWrapper } from '../helpers';
import { RequestReconsiderationPayload } from './types';

export function useCreateRequestReconsideration(
  options?: UseMutationOptions<any, Error, RequestReconsiderationPayload>
) {
  const { mutate: createRequestReconsideration, isLoading } = useMutation<
    any,
    Error,
    RequestReconsiderationPayload
  >({
    mutationFn: (payload: RequestReconsiderationPayload) =>
      createResponseWrapper(apiClient.createRequestForReconsideration, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    createRequestReconsideration,
    isLoading,
  };
}
