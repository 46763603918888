import { useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import apiClient from '../apiClient';
import { ApiResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { toWC3Detail } from './helpers';
import { WC3 } from './types';

export function useGetWC3(
  options?: UseQueryOptions<ApiResponseType<{ form: WC3 }>, Error, WC3> & {
    id: string;
  }
) {
  const {
    data: wc3,
    error,
    isError,
    isFetching: isLoading,
    refetch: onGetWC3ById,
  } = useQuery<ApiResponseType<{ form: WC3 }>, Error, WC3>([API_QUERIES.WC3s, { id: options.id }], {
    queryFn: (query) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...params] = query.queryKey;
      return responseWrapper<ApiResponseType<{ form: WC3 }>>(apiClient.getWC3, params);
    },
    select: ({ data }) => toWC3Detail(data.form),
    enabled: !!options.id,
    ...options,
  });

  const queryClient = useQueryClient();

  const handleInvalidateWC3 = () =>
    queryClient.invalidateQueries([API_QUERIES.WC3s, { id: options.id }]);

  return {
    wc3,
    error,
    isError,
    isLoading,
    onGetWC3ById,
    handleInvalidateWC3,
  };
}
