import { useMutation, UseMutationOptions } from 'react-query';
import { ViewObject } from 'src/queries';
import { Toastify } from 'src/services';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { Service } from './types';

export function useDeleteView(
  options?: UseMutationOptions<
    { id: string; object: ViewObject; service: Service },
    Error,
    { id: string; object: ViewObject; service: Service }
  >
) {
  const { mutate: deleteView, isLoading } = useMutation<
    { id: string; object: ViewObject; service: Service },
    Error,
    { id: string; object: ViewObject; service: Service }
  >({
    mutationFn: (payload: { id: string; object: ViewObject; service: Service }) => {
      const deleteViewPayload = { ...payload, service: payload?.service || Service.CaseSvc };
      return responseWrapper(apiClient.deleteView, [deleteViewPayload]);
    },
    onError: ({ message }) => Toastify.error(message),
    ...options,
  });

  return {
    deleteView,
    isLoading,
  };
}
