import { AccountDetail } from '../Accounts';
import { OrganizationType, PortalUserAuditHistoryKey, PortalUserKey, PortalUserType } from './keys';

export interface PortalUser {
  [PortalUserKey.Id]: string;
  [PortalUserKey.Status]: string;
  [PortalUserKey.FirstName]: string;
  [PortalUserKey.LastName]: string;
  [PortalUserKey.FullName]: string;
  [PortalUserKey.PortalUserName]: string;
  [PortalUserKey.PortalUserClassification]: {
    id: string;
    label: string;
  };
  [PortalUserKey.Account]: AccountDetail;
  [PortalUserKey.AccountId]: string;
  [PortalUserKey.PortalUserClassificationId]: string;
  [PortalUserKey.UserType]: string;
  [PortalUserKey.HasWC]: boolean;
  [PortalUserKey.HasTDI]: boolean;
  [PortalUserKey.HasPHC]: boolean;
  [PortalUserKey.ProgramType]: string;
  [PortalUserKey.UserType]: string;
  [PortalUserKey.IsWC]: boolean;
  [PortalUserKey.IsTDI]: boolean;
  [PortalUserKey.IsPHC]: boolean;
  [PortalUserKey.PortalReviewComments]: string;
  [PortalUserKey.Title]: string;
  [PortalUserKey.Salutation]: string;
  [PortalUserKey.ClaimantFirstName]: string;
  [PortalUserKey.ClaimantLastName]: string;
  [PortalUserKey.MiddleInitial]: string;
  [PortalUserKey.Suffix]: string;
  [PortalUserKey.Sex]: string;
  [PortalUserKey.DateOfBirth]: string;
  [PortalUserKey.IsDeceased]: string;
  [PortalUserKey.DateOfDeath]: string;
  [PortalUserKey.DcisName]: string;
  [PortalUserKey.Type]: string;
  [PortalUserKey.SSN]: string;
  [PortalUserKey.Passport]: string;
  [PortalUserKey.Email]: string;
  [PortalUserKey.Phone]: string;
  [PortalUserKey.MaritalAddress]: string;
  [PortalUserKey.IsQualifyStudent]: boolean;
  [PortalUserKey.IsNonSelfSufficient]: boolean;
  [PortalUserKey.IsWhollyDependent]: boolean;
  [PortalUserKey.Street]: string;
  [PortalUserKey.City]: string;
  [PortalUserKey.State]: string;
  [PortalUserKey.ZipCode]: string;
  [PortalUserKey.Country]: string;

  [PortalUserKey.IdentificationType]: string;
  [PortalUserKey.IdentificationNumber]: string;
  [PortalUserKey.ClaimantAddress]: string;
  [PortalUserKey.ClaimantCity]: string;
  [PortalUserKey.ClaimantState]: string;
  [PortalUserKey.ClaimantZipCode]: string;
  [PortalUserKey.ClaimantEmail]: string;
  [PortalUserKey.ClaimantPhone]: string;
  [PortalUserKey.MailingCity]: string;
  [PortalUserKey.MailingState]: string;
  [PortalUserKey.MailingZipCode]: string;
  [PortalUserKey.MailingAddressIfDifferent]: string;
  [PortalUserKey.EmployerName]: string;
  [PortalUserKey.EmployerPhoneNumber]: string;
  [PortalUserKey.EmployerState]: string;
  [PortalUserKey.EmployerAddress]: string;
  [PortalUserKey.EmployerCity]: string;
  [PortalUserKey.EmployerZipCode]: string;
  [PortalUserKey.PortalUserProgramTypes]: PortalUserProgramTypes[];
  [PortalUserKey.PortalUserTypeId]: PortalUserType;
  [PortalUserKey.PortalUserType]: {
    id: string;
    label: string;
  };
  [PortalUserKey.OrgInfo]: OrganizationInfo;
  [PortalUserKey.MapSignedUpByUserProgramTypes]: string;
  [PortalUserKey.MapPermittedProgramTypes]: string;
}

export interface PortalUserProgramTypes {
  [PortalUserKey.Id]: string;
  [PortalUserKey.UserId]: string;
  [PortalUserKey.ProgramTypeId]: string;
  [PortalUserKey.ProgramType]: {
    id: string;
    label: string;
  };
}

export interface OrganizationInfo {
  [PortalUserKey.Id]: string;
  [PortalUserKey.UserId]: string;
  [PortalUserKey.AdjusterId]: string;
  [PortalUserKey.InsuranceCarrierName]: string;
  [PortalUserKey.FederalIdNumber]: string;
  [PortalUserKey.Address]: string;
  [PortalUserKey.City]: string;
  [PortalUserKey.ZipCode]: string;
  [PortalUserKey.ContactNumber]: string;
  [PortalUserKey.IsThirdPartyAdmin]: boolean;
  [PortalUserKey.ThirdPartyAdminType]: string;
  [PortalUserKey.EmployerName]: string;
  [PortalUserKey.CompanyPhoneNumber]: string;
  [PortalUserKey.NameOfParentCompany]: string;
  [PortalUserKey.MaritalAddress]: string;
  [PortalUserKey.MailingCity]: string;
  [PortalUserKey.MailingState]: string;
  [PortalUserKey.MailingZipCode]: string;
  [PortalUserKey.OrganizationType]: string;
  [PortalUserKey.AdjustingServicesName]: string;
  [PortalUserKey.AdjustingServices]: string;
  [PortalUserKey.PortalUserProgramTypes]: PortalUserProgramTypes;
  [PortalUserKey.PortalUserType]: {
    id: string;
    label: string;
  };
  [PortalUserKey.OrgInfo]: OrganizationInfo;
}

export interface PortalUserProgramTypes {
  [PortalUserKey.Id]: string;
  [PortalUserKey.UserId]: string;
  [PortalUserKey.ProgramTypeId]: string;
  [PortalUserKey.IsPermitted]: boolean;
  [PortalUserKey.IsSignedUpByUser]: boolean;
  [PortalUserKey.ProgramType]: {
    id: string;
    label: string;
  };
}

export interface PortalUserAuditHistory {
  [PortalUserAuditHistoryKey.CreatedAt]: string;
  [PortalUserAuditHistoryKey.CreatedUser]: string;
  [PortalUserAuditHistoryKey.UserProfile]: string;
  [PortalUserAuditHistoryKey.NewValue]: string;
  [PortalUserAuditHistoryKey.OriginalValue]: string;
}

export interface OrganizationInfo {
  [PortalUserKey.Id]: string;
  [PortalUserKey.UserId]: string;
  [PortalUserKey.AdjusterId]: string;
  [PortalUserKey.InsuranceCarrierName]: string;
  [PortalUserKey.FederalIdNumber]: string;
  [PortalUserKey.Address]: string;
  [PortalUserKey.City]: string;
  [PortalUserKey.ZipCode]: string;
  [PortalUserKey.ContactNumber]: string;
  [PortalUserKey.IsThirdPartyAdmin]: boolean;
  [PortalUserKey.ThirdPartyAdminType]: string;
  [PortalUserKey.EmployerName]: string;
  [PortalUserKey.CompanyPhoneNumber]: string;
  [PortalUserKey.NameOfParentCompany]: string;
  [PortalUserKey.MaritalAddress]: string;
  [PortalUserKey.MailingCity]: string;
  [PortalUserKey.MailingState]: string;
  [PortalUserKey.MailingZipCode]: string;
  [PortalUserKey.OrganizationType]: string;
  [PortalUserKey.AdjustingServicesName]: string;
  [PortalUserKey.AdjustingServices]: string;
}

export const organizationOptions = [
  {
    label: 'None',
    value: OrganizationType.None,
  },
  {
    label: `Corporation`,
    value: OrganizationType.Conporation,
  },
  {
    label: `Partnership`,
    value: OrganizationType.Partnership,
  },
  {
    label: `Sole Proprietor`,
    value: OrganizationType.SoleProprietor,
  },
  {
    label: 'Other',
    value: OrganizationType.Other,
  },
];
export interface PortalUserPayload {
  [PortalUserKey.Id]: string;
  [PortalUserKey.ProgramTypeIds]: string[];
  [PortalUserKey.AccountId]: string;
  [PortalUserKey.Status]: string;
  [PortalUserKey.Comment]: string;
}
