import { useMutation, UseMutationOptions } from 'react-query';
import { Toastify } from 'src/services';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { RelatedAccountContactPayload } from './types';

export function useEditRelatedAccountContact(
  options?: UseMutationOptions<any, Error, RelatedAccountContactPayload>
) {
  const {
    mutate: onEditRelatedAccountContact,
    isLoading: isUpdating,
    isSuccess,
    isError,
    error,
  } = useMutation<any, Error, RelatedAccountContactPayload>({
    mutationFn: (payload: RelatedAccountContactPayload) =>
      responseWrapper(apiClient.editRelatedAccountContact, [payload]),
    onError: ({ message }) => Toastify.error(message),
    ...options,
  });

  return {
    onEditRelatedAccountContact,
    isUpdating,
    isSuccess,
    isError,
    error,
  };
}
