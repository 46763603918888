import { useState } from 'react';
import { UseQueryOptions, useQuery, useQueryClient } from 'react-query';
import apiClient from '../apiClient';
import { PaginationResponseType, responseWrapper } from '../helpers';
import { API_QUERIES } from '../keys';
import { GetPropertiesParams } from '../types';
import { EmploymentAtTimeOfII } from './types';

export function useGetAllEmploymentAtTimeOfII(
  options?: UseQueryOptions<PaginationResponseType<EmploymentAtTimeOfII>, Error> & {
    caseId?: string;
  }
) {
  const [params, setParams] = useState<GetPropertiesParams>({});

  const {
    data,
    error,
    isError,
    isFetching,
    refetch: onGetAllEmploymentAtTimeOfII,
  } = useQuery<PaginationResponseType<EmploymentAtTimeOfII>, Error>(
    [API_QUERIES.EMPLOYMENT_AT_TIME_OF_INJURY_ILLNESS, { ...params, caseId: options.caseId }],
    {
      queryFn: ({ queryKey }) => {
        const params = queryKey.at(-1);

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return responseWrapper<PaginationResponseType<EmploymentAtTimeOfII>>(
          apiClient.getEmploymentAtTimeOfII,
          [params]
        );
      },
      notifyOnChangeProps: ['data', 'isFetching'],
      keepPreviousData: true,
      enabled: !!options?.caseId,
      ...options,
    }
  );

  const queryClient = useQueryClient();

  const handleInvalidateEmploymentAtTimeOfII = () =>
    queryClient.invalidateQueries(API_QUERIES.EMPLOYMENT_AT_TIME_OF_INJURY_ILLNESS);

  const { data: employmentAtTimeOfII, hasNext, payloadSize, totalRecords } = data || {};

  return {
    employmentAtTimeOfII,
    hasNext,
    payloadSize,
    totalRecords,
    error,
    isError,
    isFetching,
    onGetAllEmploymentAtTimeOfII,
    setParams,
    handleInvalidateEmploymentAtTimeOfII,
  };
}
