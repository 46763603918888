import { useMutation, UseMutationOptions } from 'react-query';
import { ErrorService } from 'src/services';
import apiClient from '../apiClient';
import { responseWrapper } from '../helpers';
import { UpdateCaseStatus } from './types';

export function useUpdateDCDCaseStatus(options?: UseMutationOptions<any, Error, UpdateCaseStatus>) {
  const {
    mutate: onUpdateDCDCaseStatus,
    isLoading,
    isError,
    error,
  } = useMutation<any, Error, UpdateCaseStatus>({
    mutationFn: (payload: UpdateCaseStatus) =>
      responseWrapper(apiClient.updateDCDCaseStatus, [payload]),
    onError: ErrorService.handler,
    ...options,
  });

  return {
    onUpdateDCDCaseStatus,
    isLoading,
    isError,
    error,
  };
}
